import { useTranslation } from 'react-i18next';
import classNames from './AdminOnboarding.module.scss';
import classes from 'classnames';
import { useAppDispatch, useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import userThunks from '@/store/thunks/user';
import { appSettingsActions } from '@/store/reducers/settings';
import UseScreenSize from '@/hooks/UseScreenSize';
import { registerService } from '@/services/register';
import { RegisteredTeacherDetails } from '@/types/register';

import Detail from '../UserInfo/Detail/Detail';
import SectionSeperator from '../SectionSeperator/SectionSeperator';
import OnboardingInvitation from '../OnboardingInvitation/OnboardingInvitation';

import logoutIcon from '@/assets/svg/logout.svg';
import logo from '@/assets/svg/logo_60x60.svg';
import { teacherActions } from '@/store/reducers/teacher';

const AdminOnboarding = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(authSelector);
  const { isDesktop, isMobile } = UseScreenSize();

  const [registeredTeachers, setRegisteredTeachers] = useState<RegisteredTeacherDetails[]>([]);

  const [link, setLink] = useState('');

  useEffect(() => {
    const fetchAdminInfo = async () => {
      try {
        const { invitationLink, teachers } =
          (await registerService.getRegisteredTeachers(user))?.data.data ?? {};
        setLink(invitationLink ?? '');
        setRegisteredTeachers(teachers ?? []);
      } catch (error) {
        console.log(error);
      }
    };

    fetchAdminInfo();
  }, []);

  const onLogout = () => {
    dispatch(userThunks.signOut());
    dispatch(authActions.signOut());
    dispatch(appSettingsActions.clearAppSettings());
    dispatch(teacherActions.setStudents([]));
  };

  const hasRegisteredTeachers = Boolean(registeredTeachers.length);

  const onboardingInvitationComponent = (
    <OnboardingInvitation
      className={classes(classNames.onboardingInvitation, {
        [classNames.onboardingInvitationHasTeachers]: hasRegisteredTeachers,
      })}
      link={link}
      copyText={hasRegisteredTeachers ? t('copyInvitationLink') : t('copyLink')}
      isBtn={hasRegisteredTeachers}
    />
  );

  const body = registeredTeachers.length ? (
    <>
      <div className={classNames.registeredTeachers}>
        {registeredTeachers.map((teacher, index) => {
          const isLast = index === registeredTeachers.length - 1;
          const fullname = `${teacher.firstName} ${teacher.lastName}`;

          return (
            <React.Fragment key={index}>
              <div key={index} className={classNames.teacher}>
                <span className={classNames.fullname}>{fullname}</span>
                <div className={classNames.details}>
                  <Detail
                    className={classNames.detail}
                    topic={t('classes')}
                    value={teacher.groupsNumber}
                  />
                  <Detail
                    className={classNames.detail}
                    topic={t('students')}
                    value={teacher.totalStudents}
                  />
                </div>
              </div>
              {!isLast && <SectionSeperator className='my-6' />}
            </React.Fragment>
          );
        })}
      </div>
      {onboardingInvitationComponent}
    </>
  ) : (
    onboardingInvitationComponent
  );

  const logoutBtn = (
    <button className={classNames.logoutBtn} onClick={onLogout}>
      <SVG src={logoutIcon} />
      {isDesktop && <span>{t('logout')}</span>}
    </button>
  );

  return (
    <div
      className={classes(classNames.adminOnboarding, {
        [classNames.adminOnboardingRegistered]: registeredTeachers.length,
      })}
    >
      <div className={classNames.logoutBtnWrapper}>{logoutBtn}</div>
      <div className={classNames.content}>
        <div className={classNames.top}>
          {!hasRegisteredTeachers && (
            <div className={classNames.logo}>
              <SVG src={logo} />
            </div>
          )}
          <span>{t('yourSchoolHasBeenCreated')}</span>
          <h2>
            {registeredTeachers.length ? t('teachersThatHaveRegistered') : t('inviteYourTeachers')}
          </h2>
        </div>
        <div
          className={classes(classNames.body, {
            'gap-y-5': hasRegisteredTeachers,
          })}
        >
          {body}
        </div>
      </div>
    </div>
  );
};

export default AdminOnboarding;
