import config from '@/config';
import { SearchResultsResponse } from '@/types/response';
import { DictioanryService } from '@/types/services';
import { UserState } from '@/types/user';
import axiosInstance from '@/utils/api';
import UserUtils from '@/utils/user';

export const dictioanryService: DictioanryService = {
  fetchResults: async (query: string, user: UserState | null) => {
    const resp: SearchResultsResponse | null = await axiosInstance.get(
      `dictionary/${config.apiVersion}/dictionary/${UserUtils.fromToLanguage(user)}/${query}`
    );
    return resp;
  },
};
