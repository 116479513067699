import {
  OrganizationDetailsProps,
  PersonalDetailsProps,
  RegistrationStepType,
  RegistrationWelcomeProps,
  VerificationCodeProps,
  RegistrationStep,
  CognitoVerificationCodeProps,
} from '@/types/registration';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import UseCodeVerification from './UseCodeVerification';
import UsePersonalDetails from './UsePersonalDetails';
import UseGeneralDetails from '../../hooks/UseOrganizationEditor';
import { cognitoService } from '@/services/cognito';
import { useDispatch } from 'react-redux';
import { authActions, authSelector } from '@/store/reducers/auth';
import commonUtils from '@/utils/common';
import {
  AdminOrganizationDetails,
  GroupDetails,
  CreateUserDetails,
  RegistrationQueryData,
} from '@/types/register';
import { registerService } from '@/services/register';
import { useTranslation } from 'react-i18next';

import RegistrationWelcome from '@/components/Registration/Welcome/RegistrationWelcome';
import VerificationCode from '@/components/Registration/Code/VerificationCode';
import PersonalDetails from '@/components/Registration/PersonalDetails/PersonalDetails';
import GeneralDetails from '@/components/OrganizationDetails/OrganizationDetails';
import UseScreenSize from '@/hooks/UseScreenSize';
import CognitoVerify from '@/components/Registration/CognitoVerify/CognitoVerify';
import UseCognitoVerification from './UseCognitoVerification';
import { useAppDispatch, useAppSelector } from '@/store';
import userThunks from '@/store/thunks/user';
import { CognitoLoginData } from '@/types/user';

const initialRegistrationSteps: RegistrationStep[] = [
  {
    type: RegistrationStepType.REGISTRATION_WELCOME,
    order: 1,
    component: RegistrationWelcome,
  },
  {
    type: RegistrationStepType.VERIFY_CODE,
    roles: ['admin'],
    order: 2,
    component: VerificationCode,
  },
  {
    type: RegistrationStepType.PERSONAL_DETAILS,
    order: 3,
    component: PersonalDetails,
  },
  {
    type: RegistrationStepType.COGNITO_VERIFY_CODE,
    order: 4,
    component: CognitoVerify,
  },
  {
    type: RegistrationStepType.ORGANIZATION_DETAILS,
    roles: ['admin'],
    order: 5,
    component: GeneralDetails,
  },
];

const UseRegistration = () => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isMobile } = UseScreenSize();
  const { nativeLanguage, user, loggedIn } = useAppSelector(authSelector);

  const { verificationCodeField, onUpdateVerificationCodeField, onCodeVerificationSubmit } =
    UseCodeVerification();

  const { cognitoVerificationCodeField, onCognitoCodeVerificationSubmit, onCodeError } =
    UseCognitoVerification();

  const {
    personalDetailsFields,
    onEmailIsAlreadyTaken,
    onUpdatePersonalDetailsFields,
    onPersonalDetailsSubmit,
  } = UsePersonalDetails();

  const {
    organizationDetailsFields,
    onUpdateOrganizationDetailsFields,
    onOrganizationDetailsSubmit,
  } = UseGeneralDetails();

  const [role, setRole] = useState('');
  const [currentStep, setCurrentStep] = useState<RegistrationStepType>(
    RegistrationStepType.REGISTRATION_WELCOME
  );
  const [isLastStep, setIsLastStep] = useState<boolean>(false);
  const [registrationSteps, setRegistrationSteps] = useState<RegistrationStep[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [loginData, setLoginData] = useState<CognitoLoginData | null>(null);

  const [organizationDetails, setOrganizationDetails] = useState<{
    organizationName: string;
    fromToLanguage: string;
    nativeLanguage: string;
    isAllowedToRegister: boolean;
  } | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const isAdmin = role === 'admin';
  const isTeacher = role === 'teacher';

  const onNextStep = () => {
    const currentStepIndex = registrationSteps.findIndex(step => step.type === currentStep);

    if (currentStepIndex >= 0) {
      const nextStepIndex = currentStepIndex + 1;
      setCurrentStep(registrationSteps[nextStepIndex].type);
      setIsLastStep(nextStepIndex >= registrationSteps.length - 1);
    }
  };

  const onCognitoRegister = async () => {
    const firstName =
      personalDetailsFields.find(field => field.id === 'firstname')?.value?.trim() ?? '';

    const username = personalDetailsFields.find(field => field.id === 'email')?.value?.trim() ?? '';

    const password =
      personalDetailsFields.find(field => field.id === 'password')?.value?.trim() ?? '';

    return await cognitoService.createAccount(
      firstName,
      username,
      password,
      nativeLanguage ?? '',
      role
    );
  };

  const userDetails = (): CreateUserDetails => {
    const firstName =
      personalDetailsFields.find(field => field.id === 'firstname')?.value?.trim() ?? '';

    const lastName =
      personalDetailsFields.find(field => field.id === 'lastname')?.value?.trim() ?? '';

    const email = personalDetailsFields.find(field => field.id === 'email')?.value?.trim() ?? '';

    return {
      firstName,
      lastName,
      userId,
      email,
    };
  };

  const onNavigateToNotFound = (lng: string) => {
    const title = t('registrationDealLimitReachedMessageTitle', { lng });
    const description = t('registrationDealLimitReachedMessageDescription', {
      lng,
    });

    navigate('/not-found', {
      state: {
        title,
        description,
      },
    });
  };

  const onRegister = async () => {
    setIsLoading(true);

    try {
      if (isAdmin && userId) {
        const organizationName =
          organizationDetailsFields.find(field => field.id === 'school_name')?.value?.trim() ?? '';

        const cityOrDistrict =
          organizationDetailsFields.find(field => field.id === 'city')?.label?.trim() ?? '';

        const state =
          organizationDetailsFields.find(field => field.id === 'state')?.label?.trim() ?? '';

        const country =
          organizationDetailsFields.find(field => field.id === 'country')?.value?.trim() ?? '';

        const organization: AdminOrganizationDetails = {
          organizationName,
          cityOrDistrict,
          state,
          country,
        };

        const adminOrganizationResponse = (
          await registerService.createOrganization(userId, organization)
        )?.data.data;

        if (adminOrganizationResponse) {
          if (loggedIn) {
            await appDispatch(userThunks.userInfo(1));
          } else {
            await OnLogin();
          }
        }
      }
    } catch (error: any) {
      commonUtils.showToast(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onCognitoLogin = async () => {
    const email = personalDetailsFields.find(field => field.id === 'email')?.value?.trim() ?? '';
    const password =
      personalDetailsFields.find(field => field.id === 'password')?.value?.trim() ?? '';

    return await cognitoService.authenticate(email, password);
  };

  const OnLogin = async (_loginData?: CognitoLoginData | null) => {
    try {
      const userCognitoInfo = _loginData || loginData;

      const nativeLanguage = userCognitoInfo?.nativeLanguage?.Value.trim() ?? 'pt';

      localStorage.removeItem('register-token');
      localStorage.removeItem('register-registration_data');

      dispatch(authActions.setNativeLanguage(nativeLanguage));

      dispatch(
        authActions.signIn({
          token: userCognitoInfo?.token ?? '',
          userId: userCognitoInfo?.userId ?? '',
        })
      );
    } catch (error: any) {
      commonUtils.showToast(error as string);

      if (error.toLowerCase().includes('email')) {
        onEmailIsAlreadyTaken();
      }

      return;
    }
  };

  const onValidateAdminCode = async (verifyCode: string) => {
    setIsLoading(true);

    try {
      let response: any;

      try {
        response = (await registerService.validateAdminCode(verifyCode))?.data.data;
      } catch (error) {
        response = (error as any)?.response?.data?.data || {};

        if (!('isAllowedToRegister' in response)) {
          console.log(error);
          onUpdateVerificationCodeField(t('validationCodeInvalid'));
          setIsLoading(false);
        }
      }

      if (response) {
        const { country, nativeLanguageCode, isAllowedToRegister } = response;
        const lang = nativeLanguageCode || localStorage.getItem('native-language');

        setIsLoading(false);

        dispatch(authActions.setNativeLanguage(lang));
        dispatch(authActions.setInterfaceLanguage(lang));

        if (!isAllowedToRegister) {
          onNavigateToNotFound(lang);
          return;
        }

        if (!response.isCodeValid) {
          onUpdateVerificationCodeField(t('validationCodeInvalid'));
          return;
        }

        onUpdateOrganizationDetailsFields(country, lang);
        onNextStep();
      }
    } catch (error) {
      console.log(error);
      onUpdateVerificationCodeField(t('validationCodeInvalid'));
      setIsLoading(false);
    }
  };

  const onCodeVerificationFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    const { isValid, verifyCode } = onCodeVerificationSubmit(event);

    if (!verifyCode) return;

    if (isValid) {
      await onValidateAdminCode(verifyCode?.toString());
    }
  };

  const onResendCognitoVerificationCode = async () => {
    try {
      setIsLoading(true);

      const email = personalDetailsFields.find(field => field.id === 'email')?.value?.trim() ?? '';

      await cognitoService.resendVerificationCode(email);
    } catch (e) {
      commonUtils.showToast(t(e as string));
    } finally {
      setIsLoading(false);
    }
  };

  const onCognitoCodeVerificationSend = async (event: FormEvent<HTMLFormElement>) => {
    try {
      setIsLoading(true);
      const { isValid, verifyCode } = onCognitoCodeVerificationSubmit(event);
      const email = personalDetailsFields.find(field => field.id === 'email')?.value?.trim() ?? '';

      await cognitoService.verifyAccount(email, verifyCode ?? '');

      const _loginData = await onCognitoLogin();

      if (!_loginData) {
        return;
      }

      localStorage.setItem('token', _loginData?.token ?? '');
      setLoginData(_loginData);

      if (isAdmin) {
        const userRegister = (
          await registerService.createAdmin({
            ...userDetails(),
            userId: userId,
          })
        )?.data.data;

        if (userRegister) {
          localStorage.setItem('userId', userId);
        }

        onNextStep();
      } else if (isTeacher) {
        const userRegister =
          (
            await registerService.createTeacher({
              ...userDetails(),
              userId: userId,
            })
          )?.data.data ?? {};

        if (userRegister) {
          localStorage.setItem('userId', userId);
          await OnLogin(_loginData);
        }
      }
    } catch (error) {
      console.log(error);
      onCodeError();
    } finally {
      setIsLoading(false);
    }
  };

  const stepProps = ():
    | {}
    | RegistrationWelcomeProps
    | VerificationCodeProps
    | PersonalDetailsProps
    | CognitoVerificationCodeProps
    | OrganizationDetailsProps => {
    if (currentStep === RegistrationStepType.REGISTRATION_WELCOME) {
      return {
        role,
        isLastStep,
        organizationDetails,
        emitNextStep: onNextStep,
      };
    } else if (currentStep === RegistrationStepType.COGNITO_VERIFY_CODE) {
      return {
        verificationCodeField: cognitoVerificationCodeField,
        role,
        isLastStep,
        email: personalDetailsFields.find(field => field.id === 'email')?.value?.trim() ?? '',
        emitSubmit: onCognitoCodeVerificationSend,
        resendVerificationCode: onResendCognitoVerificationCode,
        // onUpdateCognitoVerificationCodeField,
      };
    } else if (currentStep === RegistrationStepType.VERIFY_CODE) {
      return {
        isLastStep,
        verificationCodeField,
        emitSubmit: onCodeVerificationFormSubmit,
      };
    } else if (currentStep === RegistrationStepType.PERSONAL_DETAILS) {
      return {
        fields: personalDetailsFields,
        role,
        isLastStep,
        emitSubmit: onPersonalDetailsSubmit,
      };
    } else if (currentStep === RegistrationStepType.ORGANIZATION_DETAILS) {
      return {
        isLastStep,
        fields: organizationDetailsFields,
        emitSubmit: onOrganizationDetailsSubmit,
      };
    }
    return {};
  };

  useEffect(() => {
    let userRole = params.role ?? '';
    const userToken = searchParams.get('token') ?? '';
    const registrationData: RegistrationQueryData = {
      dealId: searchParams.get('dealId') ?? '',
      organizationId: searchParams.get('organizationId') ?? '',
    };

    let userLanguage = searchParams.get('language');
    if (!userLanguage) {
      userLanguage = (searchParams.get('fromToLanguage') ?? '').split('-')[0];
    }
    if (!userLanguage) {
      userLanguage = localStorage.getItem('register-language');
    }

    if (userLanguage) {
      dispatch(authActions.setNativeLanguage(userLanguage));
      dispatch(authActions.setInterfaceLanguage(userLanguage));

      localStorage.setItem('register-language', userLanguage);
    }

    let startStep = RegistrationStepType.REGISTRATION_WELCOME;

    // Handling the case where an admin created a user for himself but did not complete the process of creating the school.
    let stepsFilter: RegistrationStepType[] | null = null;
    if (!userRole) {
      userRole = user?.metadata?.role ?? '';
      if (userRole) {
        setUserId(user?.metadata?.id ?? '');
      }
      if (
        userRole == 'admin' &&
        user &&
        user.metadata &&
        !user.metadata.organizationDetails?.adminSettings?.isCreateOrgComplete
      ) {
        registrationData.dealId =
          user.metadata.organizationDetails?.dealId ?? registrationData.dealId;
        if (
          user.metadata.organizationDetails?.country &&
          user.metadata.languagePreferences?.nativeLanguage
        ) {
          onUpdateOrganizationDetailsFields(
            user.metadata.organizationDetails?.country,
            user.metadata.languagePreferences?.nativeLanguage
          );
          stepsFilter = [RegistrationStepType.ORGANIZATION_DETAILS];
        } else {
          stepsFilter = [
            RegistrationStepType.VERIFY_CODE,
            RegistrationStepType.ORGANIZATION_DETAILS,
          ];
        }
        startStep = stepsFilter[0];
      }
    }
    setRole(userRole);
    setCurrentStep(startStep);

    const registrationSteps = initialRegistrationSteps
      .filter(
        step =>
          (step.roles ? step.roles.includes(userRole) : true) &&
          (stepsFilter ? stepsFilter.includes(step.type) : true)
      )
      .sort((curr, next) => curr.order - next.order);
    setRegistrationSteps(registrationSteps);

    const startStepIndex = registrationSteps.findIndex(step => step.type === startStep);
    if (startStepIndex >= 0) {
      setIsLastStep(startStepIndex >= registrationSteps.length - 1);
    }

    if (userToken) {
      localStorage.setItem('register-token', userToken);
    }
    if (registrationData.dealId) {
      localStorage.setItem('register-registration_data', JSON.stringify(registrationData));
    }

    const currentURL = window.location.href;
    const url = new URL(currentURL);

    url.search = '';

    const newURL = url.href;
    window.history.pushState({ path: newURL }, '', newURL);

    const fetchDetails = async () => {
      setIsLoading(true);

      try {
        let teacherOrganizationDetails: any;

        try {
          teacherOrganizationDetails = (await registerService.getOrganizationDetails())?.data.data;
        } catch (error) {
          teacherOrganizationDetails = (error as any)?.response?.data?.data || {};

          if (!('isAllowedToRegister' in teacherOrganizationDetails)) {
            window.localStorage.removeItem('register-token');
            window.localStorage.removeItem('register-registration_data');
            setIsLoading(false);
            console.log(error);
            window.location.href = '/login';
          }
        }

        setIsLoading(false);

        if (teacherOrganizationDetails) {
          const lang =
            teacherOrganizationDetails?.languagePreferences?.nativeLanguage ||
            localStorage.getItem('native-language');

          dispatch(authActions.setNativeLanguage(lang));
          dispatch(authActions.setInterfaceLanguage(lang));

          if (!teacherOrganizationDetails.isAllowedToRegister) {
            onNavigateToNotFound(lang);
            return;
          }

          setOrganizationDetails({
            organizationName: teacherOrganizationDetails.organizationName,
            isAllowedToRegister: teacherOrganizationDetails.isAllowedToRegister,
            nativeLanguage: lang,
            fromToLanguage:
              teacherOrganizationDetails.languagePreferences.fromToLanguageParams &&
              teacherOrganizationDetails.languagePreferences.fromToLanguageParams.length
                ? teacherOrganizationDetails.languagePreferences.fromToLanguageParams[0]
                : '',
          });
        }
      } catch (error) {
        window.localStorage.removeItem('register-token');
        window.localStorage.removeItem('register-registration_data');
        setIsLoading(false);
        console.log(error);
        window.location.href = '/login';
      }
    };

    if (userRole === 'teacher') {
      onUpdatePersonalDetailsFields();
      fetchDetails();
    }
  }, []);

  const RegistrationComponent = useMemo(
    () => registrationSteps.find(step => step.type === currentStep)?.component,
    [currentStep, registrationSteps]
  );

  useEffect(() => {
    const checkPersonalDetailsForm = async () => {
      const isValid = personalDetailsFields.every(field => field.isValid);

      if (isValid) {
        setIsLoading(true);

        try {
          const _userId = await onCognitoRegister();

          setUserId(_userId ?? '');

          onNextStep();
        } catch (e) {
          commonUtils.showToast(t(e as string));
        } finally {
          setIsLoading(false);
        }
      }
    };

    checkPersonalDetailsForm();
  }, [personalDetailsFields]);

  useEffect(() => {
    const checkGeneralDetailsForm = async () => {
      const isValid = organizationDetailsFields.every(field => field.isValid);

      if (isValid) {
        await onRegister();
        //TODO change to nextStep insted to COGNITO_VERIFY_CODE,after that ,login
      }
    };

    if (isAdmin) {
      checkGeneralDetailsForm();
    }
  }, [organizationDetailsFields]);

  return {
    role,
    currentStep,
    verificationCodeField,
    organizationDetails,
    stepProps,
    isLoading,
    RegistrationComponent,
    onNextStep,
  };
};

export default UseRegistration;
