import { useState, useEffect } from 'react';

const UseScreenSize = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const updateWindowDimensions = () => {
    const newWidth = window.innerWidth;
    const newHeight = window.innerHeight;

    setWindowWidth(newWidth);
    setWindowHeight(newHeight);
  };

  useEffect(() => {
    window?.addEventListener('resize', updateWindowDimensions);

    return () => window?.removeEventListener('resize', updateWindowDimensions);
  }, []);

  const breakpointDesktop = 1024;
  const mediumHeight = 700;
  const largeWidth = 1440;
  const breakpointMobileSmall = 769;

  const isMobile = windowWidth < breakpointDesktop;
  const isMobileSmall = windowWidth < breakpointMobileSmall;
  const isDesktop = windowWidth >= breakpointDesktop;

  return {
    windowWidth,
    windowHeight,
    isMobile,
    isMobileSmall,
    isDesktop,
    mediumHeight,
    largeWidth,
  };
};

export default UseScreenSize;
