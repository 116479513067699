import UseScreenSize from '@/hooks/UseScreenSize';
import classNames from './ListenPreparation.module.scss';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import commonUtils from '@/utils/common';
import { NewGameTypes, VocabularyPreparationProps } from '@/types/game';
import { quizDescriptionPart2 } from './helper';

import UseLocalLang from '@/hooks/UseLocalLang';

import TheButton from '@/components/TheButton/TheButton';
import GoBackBtn from '@/components/GoBackBtn/GoBackBtn';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { unitSelector } from '@/store/reducers/unit';
import UseUnitsAssets from '@/hooks/UseUnitsAssets';
import { UnitAssets } from '@/types/units';
import { GameDetailsBlock } from './components/GameDetailsBlock';

const ListenPreparation = ({
  selectedQuiz,
  selectedTopic,
  emitOnStartQuiz,
  emitGoBack,
  emitOnPractice,
}: VocabularyPreparationProps) => {
  const { t } = useTranslation();
  const { isMobile } = UseScreenSize();
  const localLang = UseLocalLang();
  const { gameQuestions, gameType } = useAppSelector(gameSelector);
  const { selectedUnit } = useAppSelector(unitSelector);
  const { unitsAssets } = UseUnitsAssets();
  const unitAssets: UnitAssets = unitsAssets[selectedUnit.order || 1];
  const TEMP_GAMETYPE = gameQuestions[0].gameType as any;

  const quizTopic = selectedTopic
    ? commonUtils.getName(selectedTopic, localLang)
    : t('notAvailable');

  const quizName = selectedQuiz ? commonUtils.getName(selectedQuiz) : t('notAvailable');

  const quizUnit = selectedUnit?.name;

  const titleBlock = (
    <>
      <p className={classNames.quizTitle} style={{ color: unitAssets.modalBorderColor }}>
        {quizUnit}
      </p>
      <p className={classNames.quizName}>{quizTopic}</p>
      <p className={classNames.quizSecondName}>{quizName}</p>
    </>
  );

  const skillImageBlock = (
    <SVG
      className={classNames.skillImage}
      src={commonUtils.activityImage(gameType, selectedUnit?.order || 1)}
    />
  );

  const content = isMobile ? (
    <>
      <GameDetailsBlock
        selectedTopic={selectedTopic}
        selectedQuiz={selectedQuiz}
        isPreparationScreen={true}
      />
      {skillImageBlock}
      <div style={{ width: '100%' }}>
        <p
          className={classNames.quizDescription}
          dangerouslySetInnerHTML={{ __html: t(quizDescriptionPart2(gameType)).toString() }}
        />
        <div className={classNames.bottom}>
          <TheButton
            className={classNames.button}
            text={t('letsGo')}
            emitOnClick={emitOnStartQuiz}
            shouldRecolor
          />
          {TEMP_GAMETYPE === NewGameTypes.Vocabulary && (
            <TheButton
              className={classNames.button}
              text={t('practice')}
              plain
              emitOnClick={emitOnPractice}
            />
          )}
        </div>
      </div>
    </>
  ) : (
    <>
      {skillImageBlock}
      <GameDetailsBlock
        selectedTopic={selectedTopic}
        selectedQuiz={selectedQuiz}
        isPreparationScreen={true}
      />
      <div className={classNames.bottom}>
        <TheButton
          className={classNames.button}
          text={TEMP_GAMETYPE !== NewGameTypes.Vocabulary ? t('illDoItLater') : t('practice')}
          plain
          emitOnClick={TEMP_GAMETYPE !== NewGameTypes.Vocabulary ? emitGoBack : emitOnPractice}
        />
        <TheButton
          className={classNames.button}
          text={t('letsGo')}
          emitOnClick={emitOnStartQuiz}
          shouldRecolor
        />
      </div>
    </>
  );

  return (
    <div className={classNames.listenPreparation}>
      {isMobile && (
        <div className={classNames.top}>
          <GoBackBtn
            className={classNames.goBackBtn}
            showText={false}
            emitClick={() => emitGoBack?.()}
          />
        </div>
      )}
      {content}
    </div>
  );
};

export default ListenPreparation;
