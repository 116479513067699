import { ChangeEvent, FormEvent, useState } from 'react';
import { AppInputProps } from '@/types/common';
import { useTranslation } from 'react-i18next';

const UseCognitoVerification = () => {
  const { t } = useTranslation();

  const onInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    setCognitoVerificationCodeField(prevState => {
      const updatedState = { ...prevState };

      updatedState.value = value.trim();
      updatedState.error = '';

      if (!updatedState.value || updatedState.value.length < 6 || updatedState.value.length > 6) {
        updatedState.error = t('sixDigitsAtLeast') ?? '';
      }

      return updatedState;
    });
  };

  const onCognitoCodeVerificationSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setCognitoVerificationCodeField(prevState => {
      const updatedState = { ...prevState };

      if (!updatedState.value || updatedState.value.length < 6 || updatedState.value.length > 6) {
        updatedState.error = t('sixDigitsAtLeast') ?? '';
      }

      return updatedState;
    });

    const isValid =
      !cognitoVerificationCodeField.error && cognitoVerificationCodeField.value?.trim();

    return { isValid, verifyCode: cognitoVerificationCodeField.value };
  };

  const onCodeError = () => {
    setCognitoVerificationCodeField(prevState => {
      const updatedState = { ...prevState };

      updatedState.error = t('invalidCode') ?? '';

      return updatedState;
    });

    return { isValid: false, verifyCode: cognitoVerificationCodeField.value };
  };

  const initialCognitoVerificationCodeField: AppInputProps = {
    id: 'cognito_verification_code',
    name: 'cognitoVerificationCode',
    type: 'password',
    label: 'enterCodeHere',
    value: '',
    error: '',
    placeholder: 'sixDigits',
    emitChange: onInputChange,
  };

  const [cognitoVerificationCodeField, setCognitoVerificationCodeField] = useState<AppInputProps>(
    initialCognitoVerificationCodeField
  );
  //TODO: Need to understand if it's needed
  // const onUpdateCognitoVerificationCodeField = (error: string) => {
  //   setCognitoVerificationCodeField((prevState) => {
  //     return {
  //       ...prevState,
  //       error,
  //     };
  //   });
  // };

  return {
    cognitoVerificationCodeField,
    //TODO: Need to understand if it's needed
    // onUpdateCognitoVerificationCodeField,
    onCognitoCodeVerificationSubmit,
    onCodeError,
  };
};

export default UseCognitoVerification;
