import React, { useRef, useState, useEffect, useCallback } from 'react';
import SVG from 'react-inlinesvg';
import classNames from './MyClassesView.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { UseMyClassesInterface } from './UseMyClasses';
import { registerService } from '@/services/register';

import TheButton from '@/components/TheButton/TheButton';
import StudentsTable from '@/components/StudentsTable/StudentsTable';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import YourClasses from '@/components/YourClasses/YourClasses';
import StudentsContent from '@/components/StudentsData/StudentsContent';
import ClassEditor from '@/components/ClassEditor/ClassEditor';
import NoClasses from '@/components/NoClasses/NoClasses';
import { Transition } from 'react-transition-group';
import AppBackdrop from '@/components/AppBackdrop';
import AppModal from '@/components/AppModal';
import CsvDropzone, { CsvDropzoneRef } from '@/components/CsvDropzone/CsvDropzone';
import UploadCsvErrorModal from '@/components/UploadCsvErrorModal/UploadCsvErrorModal';
import CommunicationProblemModal from '@/components/CommunicationProblemModal/CommunicationProblemModal';
import DealLimitReachedMessage from '@/components/DealLimitReachedMessage/DealLimitReachedMessage';
import LoaderModal from '@/components/LoaderModal/LoaderModal';

import closeIcon from '@/assets/svg/close.svg';
import download from '@/assets/svg/download.svg';

const MyClassesViewDesktop = ({ myClasses }: { myClasses: UseMyClassesInterface }) => {
  const { t } = useTranslation();
  const csvDropzoneRef = useRef<CsvDropzoneRef | null>(null);

  const [showDealLimitReachedModal, setShowDealLimitReachedModal] = useState<boolean>(false);
  const [isOrganizationDetailsLoading, setIsOrganizationDetailsLoading] = useState<boolean>(false);
  const isComponentActive = useRef<boolean>(false);

  const {
    selectedFilter,
    selectedFilterText,
    allClassrooms,
    filterdClassrooms,
    classConfig,
    onCreateClass,
    createOrSaveClass,
    onFilter,
    onSelectClass,
    showClassCreatedModal,
    onCloseClassCreatedModal,
    showCopyModal,
    setShowCopyModal,
    onCloseCopyModal,
    onCloseCancelModal,
    onShowCopyModal,
    showCancelModal,
    setShowCancelModal,
    onContinue,
    onShowCancelModal,
    user,
    loadingGroupDetails,
    isSavingGroup,
    isCsvLoading,
    isCsvSending,
    setIsCsvLoading,
    csvFilesAdded,
    setCsvFilesAdded,
    showUploadCsvModal,
    showStudentsTableModal,
    addStudentsToNewGroup,
    onShowUploadCsvModal,
    onCloseUploadCsvModal,
    onShowStudentsTableModal,
    onBackStudentsTableModal,
    studentsData,
    setStudentsData,
    onCsvStudentsCreate,
    disableSubmitButton,
    setDisableSubmitButton,
    showNewStudentInput,
    setShowNewStudentInput,
    currentClassroom,
    isLoading,
    fetchDataError,
    refreshStudentsData,
    studentsProgress,
    statistics,
    selectedStudent,
    isClassEdit,
    setIsClassEdit,
    onShowStudentModal,
    onHideStudentModal,
    isStudentDataLoading,
    showStudentDataModal,
    selectedStudentData,
    showStudentEditModal,
    onShowStudentEditModal,
    onCloseStudentEditModal,
    showUnitData,
    onShowUnitData,
    onHideUnitData,
    selectedUnit,
    editStudent,
    showCsvStudentsCreateError,
    setShowCsvStudentsCreateError,
    showExistingStudentsModal,
    onCloseExistingStudentsModal,
    onStudentDelete,
    isStudentDeleting,
    showStudentDeleteError,
    setShowStudentDeleteError,
    isClassDeleting,
    showClassDeleteError,
    setShowClassDeleteError,
    onClassDelete,
    classStudentsExtraDataResponse,
  } = myClasses;

  useEffect(() => {
    isComponentActive.current = true;

    return () => {
      isComponentActive.current = false;
    };
  }, []);

  const classUploadCsvModal = (
    <Transition
      in={showUploadCsvModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => {
        return (
          <>
            <AppBackdrop className={classes(classNames.backdrop, `backdrop-${state}`)} />
            <AppModal>
              <div
                className={classes(classNames.modal, classNames.fileUploadModal, `fade-${state}`)}
              >
                {(!csvFilesAdded || isCsvLoading) && (
                  <button
                    onClick={() => {
                      // TODO: remove this class, the class must be deleted because a teacher closed the modal without assigning students to the class
                      onCloseUploadCsvModal();
                    }}
                    className={classNames.close}
                    aria-label={t('close').toString()}
                  >
                    <SVG src={closeIcon} />
                  </button>
                )}
                <h2>
                  {addStudentsToNewGroup
                    ? t('settings.add_students.invite_students')
                    : t('settings.add_students.enroll_students')}
                </h2>
                <span className={classNames.description}>
                  {t('settings.add_students.upload_file')}
                </span>
                <div className={classNames.descriptionWrapper}>
                  <span className={classNames.sample}>
                    <a href='/csv/sample.xlsx' target='_blank' className={classNames.downloadLink}>
                      <SVG src={download} />
                      {t('settings.add_students.download')}
                    </a>{' '}
                    {t('settings.add_students.download_sample')}
                  </span>
                </div>
                <CsvDropzone
                  ref={csvDropzoneRef}
                  studentsData={studentsData}
                  setStudentsData={setStudentsData}
                  isCsvLoading={isCsvLoading}
                  setIsCsvLoading={setIsCsvLoading}
                  setCsvFilesAdded={setCsvFilesAdded}
                  studentsInClass={classConfig?.id ? currentClassroom?.totalStudents : 0}
                />
                <div className={`${classNames.buttons} ${classNames.buttonsUpload}`}>
                  {(!csvFilesAdded || isCsvLoading) && (
                    <TheButton
                      className={classNames.addManuallyBtn}
                      text={t('settings.add_students.add_students_manually')}
                      plain
                      emitOnClick={() => {
                        if (isCsvLoading) {
                          return;
                        }
                        setStudentsData([]);
                        setShowNewStudentInput(true);
                        onShowStudentsTableModal();
                      }}
                    />
                  )}
                  {csvFilesAdded && !isCsvLoading && (
                    <>
                      <TheButton
                        className={classNames.cancelBtn}
                        text={t('cancel')}
                        plain
                        emitOnClick={() => {
                          if (csvDropzoneRef.current) {
                            csvDropzoneRef.current.removeFile();
                          }
                        }}
                      />
                      <TheButton
                        className={classNames.sendBtn}
                        text={t('settings.add_students.button_next')}
                        type='submit'
                        disabled={!studentsData.length || isCsvLoading}
                        shouldRecolor={false}
                        emitOnClick={() => {
                          setShowNewStudentInput(false);
                          onShowStudentsTableModal();
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );

  const classUploadCsvErrorModal = (
    <UploadCsvErrorModal
      show={showCsvStudentsCreateError}
      onClose={() => {
        setShowCsvStudentsCreateError(false);
      }}
    />
  );

  const classStudentsTableModal = (
    <Transition
      in={showStudentsTableModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => {
        return (
          <>
            <AppBackdrop className={classes(classNames.backdrop, `backdrop-${state}`)} />
            <AppModal>
              <div
                className={classes(
                  classNames.modal,
                  classNames.studentsDataModal,
                  classNames.fileUploadModal,
                  `fade-${state}`
                )}
              >
                <div className={classNames.studentsTableModalHeader}>
                  <h2>
                    {addStudentsToNewGroup
                      ? t('settings.add_students.invite_students')
                      : t('settings.add_students.enroll_students')}
                  </h2>
                  <span className={classNames.description}>
                    {t('settings.add_students.create_students')}
                  </span>
                </div>
                <StudentsTable
                  studentsData={studentsData}
                  setStudentsData={setStudentsData}
                  allowActions={true}
                  allowNewRows={true}
                  disableSubmitButton={disableSubmitButton}
                  setDisableSubmitButton={setDisableSubmitButton}
                  showNewStudentInput={showNewStudentInput}
                  setShowNewStudentInput={setShowNewStudentInput}
                  isModal={true}
                  studentsInClass={classConfig?.id ? currentClassroom?.totalStudents : 0}
                />
                {isCsvSending ? (
                  <div className={classNames.loader}>
                    <AppLoaderCircle />
                  </div>
                ) : (
                  <div className={classNames.buttons}>
                    <TheButton
                      className={classNames.cancelBtn}
                      text={t('back')}
                      plain
                      emitOnClick={onBackStudentsTableModal}
                    />
                    <TheButton
                      className={classNames.sendBtn}
                      text={t('settings.add_students.enroll_students')}
                      type='submit'
                      shouldRecolor={false}
                      disabled={!studentsData.length || disableSubmitButton || showNewStudentInput}
                      emitOnClick={onCsvStudentsCreate}
                    />
                  </div>
                )}
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );

  const classExistingStudentsModal = (
    <Transition
      in={!!showExistingStudentsModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => {
        return (
          <>
            <AppBackdrop className={classes(classNames.backdrop, `backdrop-${state}`)} />
            <AppModal>
              <div
                className={classes(
                  classNames.modal,
                  classNames.existingStudentsModal,
                  classNames.fileUploadModal,
                  `fade-${state}`
                )}
              >
                <div className={classNames.existingStudentsModalHeader}>
                  <h2>
                    {showExistingStudentsModal?.addedStudents
                      ? t('settings.add_students.partial_success')
                      : t('settings.add_students.enrollment_unsuccessful')}
                  </h2>
                  {!!showExistingStudentsModal?.addedStudents && (
                    <div
                      className={classNames.description}
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'settings.add_students.partial_success_description',
                          showExistingStudentsModal
                        ).toString(),
                      }}
                    />
                  )}
                  <div className={classNames.description}>
                    {t('settings.add_students.enrollment_unsuccessful_description')}
                  </div>
                </div>

                <div className={classNames.existingStudentsList}>
                  <div className={classNames.existingStudentsHead}>
                    <div>{t('firstname')}</div>
                    <div>{t('lastname')}</div>
                    <div>{t('email')}</div>
                  </div>
                  <div className={classNames.existingStudentsBody}>
                    {showExistingStudentsModal?.existingStudentsList?.map((student, index) => (
                      <div
                        key={`existingStudentsListItem--${index}`}
                        className={classNames.existingStudentsListItem}
                      >
                        <div>{student.firstName}</div>
                        <div>{student.lastName}</div>
                        <div>{student.email}</div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className={classNames.buttons}>
                  <TheButton
                    className={classNames.btn}
                    text={t('ok')}
                    shouldRecolor={false}
                    emitOnClick={onCloseExistingStudentsModal}
                  />
                </div>
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );

  const classCreatedModal = (
    <Transition
      in={showClassCreatedModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => {
        return (
          <>
            <AppBackdrop className={classes(classNames.backdrop, `backdrop-${state}`)} />
            <AppModal>
              <div className={classes(classNames.modal, classNames.copyModal, `fade-${state}`)}>
                <h2>
                  {addStudentsToNewGroup ? t('classCreated') : t('studentsSuccessfullyEnrolled')}
                </h2>
                <span
                  className={classNames.description}
                  dangerouslySetInnerHTML={{ __html: t('classCreatedDescription').toString() }}
                />
                <TheButton
                  className={classNames.btn}
                  text={t('done')}
                  shouldRecolor={false}
                  emitOnClick={onCloseClassCreatedModal}
                />
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );

  const cancelModal = (
    <Transition
      in={showCancelModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => {
        return (
          <>
            <AppBackdrop className={classes(classNames.backdrop, `backdrop-${state}`)} />
            <AppModal>
              <div className={classes(classNames.modal, classNames.copyModal, `fade-${state}`)}>
                <h2>{t('areYouSure')}</h2>
                <span className={classNames.description}>{t('mustFirstCreateClass')}</span>
                <TheButton
                  className={classNames.btn}
                  text={t('continueCreating')}
                  shouldRecolor={false}
                  emitOnClick={onContinue}
                />
                <TheButton
                  className={classNames.cancelBtn}
                  text={t('cancelAnyway')}
                  shouldRecolor={false}
                  emitOnClick={() => {
                    onCloseCancelModal();
                    setIsClassEdit(false);
                  }}
                />
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );

  const linkCopiedModal = (
    <Transition
      in={showCopyModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => {
        return (
          <>
            <AppBackdrop className={classes(classNames.backdrop, `backdrop-${state}`)} />
            <AppModal>
              <div className={classes(classNames.modal, classNames.copyModal, `fade-${state}`)}>
                <h2>{t('linkCopied')}</h2>
                <span className={classNames.description}>
                  {t('goAheadAndShareThisLinkWithYourStudentsNow')}
                </span>
                <TheButton
                  className={classNames.btn}
                  text={t('done')}
                  shouldRecolor={false}
                  emitOnClick={onCloseCopyModal}
                />
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );

  const fetchDataErrorModal = (
    <CommunicationProblemModal show={!!fetchDataError} onRefresh={refreshStudentsData} />
  );

  const viewMode =
    (isLoading || loadingGroupDetails) && !showStudentDataModal
      ? 'lode'
      : isClassEdit
        ? 'edit'
        : allClassrooms.length === 0
          ? 'noClasses'
          : 'progress';

  const content =
    viewMode === 'lode' ? (
      <AppLoaderCircle className='mx-auto' />
    ) : viewMode === 'noClasses' ? (
      <NoClasses
        className={classNames.noClasses}
        emitCreateClass={onCreateClass}
        loading={loadingGroupDetails}
        setIsClassEdit={setIsClassEdit}
      />
    ) : viewMode === 'edit' ? (
      classConfig && (
        <ClassEditor
          classConfig={classConfig}
          currentClassroom={currentClassroom}
          createOrSaveClass={createOrSaveClass}
          loading={isSavingGroup}
          showCopyModal={showCopyModal}
          onShowCopyModal={onShowCopyModal}
          setShowCopyModal={setShowCopyModal}
          showCancelModal={showCancelModal}
          onShowCancelModal={onShowCancelModal}
          setShowCancelModal={setShowCancelModal}
          onShowUploadCsvModal={onShowUploadCsvModal}
          setIsClassEdit={setIsClassEdit}
          isClassDeleting={isClassDeleting}
          showClassDeleteError={showClassDeleteError}
          setShowClassDeleteError={setShowClassDeleteError}
          onClassDelete={onClassDelete}
        />
      )
    ) : (
      currentClassroom && (
        <StudentsContent
          interfaceLanguage={undefined}
          onHideDataModal={onHideStudentModal}
          onShowDataModal={onShowStudentModal}
          searchValue={''}
          selectedStudent={selectedStudent}
          selectedStudentData={selectedStudentData}
          setSearchValue={() => {}}
          showStudentDataModal={showStudentDataModal}
          showAssessments={false}
          statistics={statistics}
          students={studentsProgress}
          studentsExtraData={classStudentsExtraDataResponse}
          currentClassroom={currentClassroom}
          isStudentDataLoading={isStudentDataLoading}
          onCloseEdit={onCloseStudentEditModal}
          onHideUnitData={onHideUnitData}
          onSaveEdit={editStudent}
          onShowEdit={onShowStudentEditModal}
          onShowUnitData={onShowUnitData}
          onShowAssessments={() => {}}
          onHideAssessment={() => {}}
          selectedUnit={selectedUnit}
          showEdit={showStudentEditModal}
          showUnitData={showUnitData}
          setIsClassEdit={setIsClassEdit}
          onStudentDelete={onStudentDelete}
          isStudentDeleting={isStudentDeleting}
          showStudentDeleteError={showStudentDeleteError}
          setShowStudentDeleteError={setShowStudentDeleteError}
        />
      )
    );

  const createClassHandler = async () => {
    if (isOrganizationDetailsLoading) {
      return;
    }
    setIsOrganizationDetailsLoading(true);

    let isAllowedToRegister: boolean = false;

    try {
      const organizationDetails = (await registerService.getOrganizationDetails(user))?.data.data;
      isAllowedToRegister = !!organizationDetails?.isAllowedToRegister;
    } catch (error) {}

    if (!isComponentActive.current) {
      return;
    }

    setIsOrganizationDetailsLoading(false);

    if (!isAllowedToRegister) {
      setShowDealLimitReachedModal(true);
      return;
    }

    onCreateClass();

    if (setIsClassEdit) {
      setIsClassEdit(true);
    }
  };

  const onCloseDealLimitReachedModal = useCallback(() => {
    setShowDealLimitReachedModal(false);
  }, []);

  return (
    <div className={classNames.myClassesView}>
      {classUploadCsvModal}
      {classUploadCsvErrorModal}
      {classStudentsTableModal}
      {classExistingStudentsModal}
      {classCreatedModal}
      {cancelModal}
      {linkCopiedModal}
      {fetchDataErrorModal}
      <DealLimitReachedMessage
        show={showDealLimitReachedModal}
        onClose={onCloseDealLimitReachedModal}
      />
      {isOrganizationDetailsLoading && <LoaderModal show={true} />}
      <div id='my_classes_header' className={classNames.header}>
        <h2>{t('settings.my_classes')}</h2>
        <TheButton
          className={classNames.createNewClassBtn}
          text={t('createNewClass')}
          shouldRecolor={false}
          emitOnClick={createClassHandler}
        />
      </div>
      <div className={classNames.body}>
        <YourClasses
          className={classNames.yourClasses}
          classConfig={classConfig}
          filterdClassrooms={filterdClassrooms}
          selectedFilter={selectedFilter}
          onSelectClass={onSelectClass}
          setIsClassEdit={setIsClassEdit}
          selectedFilterText={selectedFilterText}
          onFilter={onFilter}
        />
        <div className={classes(classNames.viewBlock, classNames[`viewBlock_${viewMode}`])}>
          {content}
        </div>
      </div>
    </div>
  );
};

export default React.memo(MyClassesViewDesktop);
