import classNames from './Header.module.scss';

interface HeaderProps {
  items: {
    label: string;
    value?: string;
  }[];
}

const Header = ({ items }: HeaderProps) => {
  return (
    <div className={classNames.header}>
      {items.map((item, index) => {
        return (
          <div key={`headerItem--${index}`} className={classNames.headerItem}>
            <h3>
              <span className={classNames.text}>{item.label}</span>
            </h3>
            <h2>
              <span className={classNames.text}>{item.value}</span>
            </h2>
          </div>
        );
      })}
    </div>
  );
};

export default Header;
