import UseScreenSize from '@/hooks/UseScreenSize';
import classNames from './ReadQuestion.module.scss';
import { useTranslation } from 'react-i18next';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { Question } from '@/types/question';
import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';
import commonUtils from '@/utils/common';
import { GameStatus, PreparationTypeProps } from '@/types/game';
import UseLocalLang from '@/hooks/UseLocalLang';
import { interfaceLanguageSelector } from '@/store/reducers/auth';

import TheButton from '@/components/TheButton/TheButton';
import QuizPageControls from '@/components/QuizPage/QuizPageControls/QuizPageControls';
import QuizPageIndicators from '@/components/QuizPage/QuizPageIndicators/QuizPageIndicators';
import MultiChoiceOptions from '@/components/GameType/MultiChoice/MultiChoiceOptions/MultiChoiceOptions';
import QuizDetails from '@/components/QuizPage/QuizDetails/QuizDetails';

import close from '@/assets/svg/close.svg';
import DictionaryMobile from '@/components/Dictionary/DictionaryMobile/DictionaryMobile';
import { authSelector } from '@/store/reducers/auth';
import AppBanner from '@/components/AppBanner';
import QuizImageIndicator from '@/components/QuizPage/QuizImageIndicator/QuizImageIndicator';
import { levelTestSelector } from '@/store/reducers/level-test';
import { endOfSkillActions, endOfSkillSelector } from '@/store/reducers/end-of-skill';
import GameUtils from '@/utils/gameUtils';
import { Quiz } from '@/types/quiz';

interface ReadQuestionProps {
  currentQuestion: Question;
  currentQuestionIndex?: number;
  gameQuestions: Question[];
  loading?: boolean;
  coins?: number;
  currentTime?: number;
  currentCoins?: number;
  shouldStart: boolean;
  shouldViewAnswers: boolean;
  isPaused?: boolean;
  onPause?: () => void;
  onStartQuiz: () => void;
  onViewAnswers: () => void;
  onHideAnswers: () => void;
}

const ReadQuestion = ({
  currentQuestion,
  currentQuestionIndex,
  gameQuestions,
  loading,
  coins,
  currentTime,
  currentCoins,
  shouldStart,
  shouldViewAnswers,
  selectedQuiz,
  selectedSkill,
  selectedTopic,
  selectedType,
  isPaused,
  onPause,
  onStartQuiz,
  onViewAnswers,
  onHideAnswers,
  emitGoBack,
}: ReadQuestionProps & PreparationTypeProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const localLang = UseLocalLang();
  const { isMobile } = UseScreenSize();
  const { quizzes, currentQuizIndex } = useAppSelector(levelTestSelector);
  const {
    showAnswerInOptions,
    showPreQuiz,
    isLevelTest,
    isInEndOfSkillTest,
    isFinalAssessment,
    possibleAnswers,
  } = useAppSelector(gameSelector);

  const { currentReadQuizIndex, quiz } = useAppSelector(endOfSkillSelector);

  const [showPreScreen, setShowPreScreen] = useState(false);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const { displayDictionary } = useAppSelector(authSelector);

  const [shouldOpenDictioanry, setShouldOpenDictionary] = useState(false);

  const onOpenDictionary = () => {
    setShouldOpenDictionary(true);
    dispatch(gameActions.toggleGameStatus(GameStatus.SHOW_DICTIOANRY));
  };

  const onCloseDictionary = () => {
    setShouldOpenDictionary(false);
    dispatch(gameActions.toggleGameStatus(GameStatus.PLAYING));
  };

  const allAnswered = useMemo(
    () => gameQuestions.filter(question => question.pickedAnswer),
    [gameQuestions]
  );

  const isLast = useMemo(
    () => currentQuestionIndex === gameQuestions.length - 1,
    [currentQuestionIndex, gameQuestions]
  );

  const onCheckUnansweredQuestions = async () => {
    await commonUtils.sleep(1000);
    const firstUnanswered = gameQuestions.find(question => !question.pickedAnswer);

    const firstUnansweredIndex = gameQuestions.findIndex(
      question => question.questionId === firstUnanswered?.questionId
    );

    dispatch(gameActions.setHideAnswerInOptions());
    dispatch(gameActions.setQuestionIndex(firstUnansweredIndex));
  };

  const onSelect = async (answer: string) => {
    dispatch(
      gameActions.handlePickedAnswer({
        answer,
      })
    );

    await commonUtils.sleep(500);

    dispatch(gameActions.setShowAnswerInOptions());

    const isAnsweredAllQuestions = allAnswered.length === gameQuestions.length - 1;

    if (isLevelTest) {
      await commonUtils.sleep(1000);
      dispatch(gameActions.setHideAnswerInOptions());

      dispatch(
        gameActions.goToNextQuestion({
          quizzesLength: quizzes?.length ?? 0,
          levelTestQuizIndex: currentQuizIndex,
        })
      );
    }

    if (isInEndOfSkillTest) {
      const currentQuestion = gameQuestions[currentQuestionIndex ?? 0];
      const lastQuestion = gameQuestions[gameQuestions.length - 1];
      const isInLastQuestion =
        (currentQuestion.questionId === lastQuestion.questionId && isAnsweredAllQuestions) ||
        isAnsweredAllQuestions;

      if (isInLastQuestion && currentReadQuizIndex && quiz) {
        const quizzes = quiz.quiz as Quiz[];
        const endOfTheQuiz = currentReadQuizIndex >= quizzes.length;

        if (endOfTheQuiz) {
          dispatch(gameActions.endGame());
          return;
        }

        await commonUtils.sleep(1000);
        dispatch(gameActions.setHideAnswerInOptions());
        dispatch(gameActions.setQuestionIndex(0));
        dispatch(endOfSkillActions.updateCurrentReadQuizIndex());

        setShowPreScreen(true);

        if (isMobile) {
          dispatch(gameActions.setShowPreQuiz(true));
        }

        const nextQuiz = GameUtils.getQuizFromQuizzes(quizzes, currentReadQuizIndex);

        dispatch(gameActions.setSelectedQuiz(GameUtils.getQuizFromQuizzes(nextQuiz)));
        dispatch(
          gameActions.startGame({
            //FIXME: Need to use different correct gameType
            gameType: 10,
            questions: GameUtils.getQuestionsFromQuiz(quiz?.questions, nextQuiz.id),
          })
        );
      }
    }

    if (isAnsweredAllQuestions && !showPreQuiz && !isInEndOfSkillTest && !isLevelTest) {
      dispatch(gameActions.endGame());
      return;
    }

    if (isLast && !isAnsweredAllQuestions) {
      await onCheckUnansweredQuestions();
    }
  };
  const onNext = () => {
    dispatch(
      gameActions.goToNextQuestion({
        quizzesLength: quizzes?.length,
        isSimple: true,
      })
    );
  };

  const onPrevious = () => {
    dispatch(gameActions.goToPreviousQuestion({ isSimple: true }));
  };

  const total = gameQuestions.length;

  const quizPageControls = (
    <QuizPageControls
      className={classNames.quizPageControls}
      actual={(currentQuestionIndex ?? 0) + 1}
      total={total}
      progress={commonUtils.calcPercentage(loading ? 0 : allAnswered.length, total)}
      loading={loading}
      disableNext={isLast}
      disablePrevious={!currentQuestionIndex}
      isPaused={isPaused}
      allowDynamicDirection={false}
      onPause={onPause}
      onNext={onNext}
      onPrevious={onPrevious}
    />
  );

  const multiChoiceOptions = (
    <MultiChoiceOptions
      correctAnswer={currentQuestion?.answer}
      showAnswerInOptions={showAnswerInOptions}
      pickedAnswer={currentQuestion?.pickedAnswer ?? ''}
      coins={currentQuestion?.coins}
      // options={currentQuestion?.options}
      options={possibleAnswers ?? []}
      onSelect={onSelect}
      optionClassName={classNames.answerOption}
    />
  );

  const quizPageIndicators = (
    <QuizPageIndicators
      className={classNames.quizPageIndicators}
      selectedType={selectedType}
      earnedCoins={coins ?? 0}
      timesUp={!loading && (currentTime ?? 0) <= 5}
      coins={currentCoins}
      timer={currentTime ?? 0}
    />
  );

  const contentMobile = shouldStart ? (
    <>
      <div
        className={classes(classNames.question, {
          [classNames.questionOpen]: shouldViewAnswers,
        })}
      >
        <div className={classNames.content}>
          {shouldViewAnswers && (
            <div className={classNames.top}>
              <button className={classNames.closeBtn} onClick={onHideAnswers}>
                <SVG src={close} />
              </button>
            </div>
          )}
          <div className={classNames.body}>
            {quizPageControls}
            <div className={classNames.details}>
              <h2 className={classNames.title}>{currentQuestion?.question}</h2>
              {shouldViewAnswers ? (
                multiChoiceOptions
              ) : (
                <button className={classNames.viewBtn} onClick={onViewAnswers}>
                  {t('viewAnswerChoices')}
                </button>
              )}
            </div>
          </div>
        </div>
        {!isLevelTest && !isInEndOfSkillTest && quizPageIndicators}
      </div>
    </>
  ) : (
    <div className={classNames.startQuizBtn}>
      <TheButton text={t('letsGo') + '!'} emitOnClick={onStartQuiz} />
    </div>
  );

  const endOfSkillPreScreen = (
    <div className={classNames.endOfSkillPreScreen}>
      <div className={classNames.textContainer}>
        <h1 className={classNames.title}>{t('skills.read')}</h1>
        <h2 className={classNames.subTitle}>{selectedQuiz?.name}</h2>
      </div>
      <h2 className={classNames.description}>
        {commonUtils.getQuestionInstructions(currentQuestion, selectedQuiz)}
      </h2>
      <TheButton
        className={classNames.endOfSkillPreScreenButton}
        emitOnClick={() => setShowPreScreen(false)}
        text={t('continueToQuestions')}
        showArrow
      />
    </div>
  );

  const instructionsText = (
    <span
      className={classes(
        classNames.instructions,
        classNames[`instructions-${interfaceLanguage?.direction}`]
      )}
    >
      {selectedQuiz
        ? commonUtils.getQuestionInstructions(currentQuestion, selectedQuiz)
        : t('notAvailable')}
    </span>
  );

  const finalEnglishLevelTestText = (
    <span className={classNames.finalEnglishLevelTestText}>{t('finalEnglishLevelTest')}</span>
  );

  const contentDesktop = shouldStart ? (
    <div className={isLevelTest ? classNames.levelTestQuestion : classNames.question}>
      {!isLevelTest && !isFinalAssessment && quizPageControls}
      {!isLevelTest && !isInEndOfSkillTest && !isFinalAssessment && quizPageIndicators}

      <div className={classNames.content}>
        {showPreScreen && isInEndOfSkillTest ? (
          endOfSkillPreScreen
        ) : (
          <>
            <div className={classNames.details}>
              <QuizImageIndicator
                className={classNames.imageIndicator}
                starsClassName={classNames.stars}
                skillClassName={classNames.skill}
                shouldDisplayStars={!isLevelTest}
              />
              {isLevelTest && (
                <span className={classNames.testYourEnglishText}>{t('testYourEnglish')}</span>
              )}
              {isFinalAssessment ? finalEnglishLevelTestText : instructionsText}
              <h2 className={classNames.title}>{currentQuestion.question}</h2>
            </div>
            {multiChoiceOptions}
          </>
        )}
      </div>
    </div>
  ) : (
    <div className={classNames.startQuizLayout}>
      <QuizDetails
        className={classNames.quizDetails}
        headingClassName={classNames.quizHeading}
        shouldDisplayInstructions={true}
        selectedType={selectedType}
        selectedSkill={selectedSkill}
        selectedTopic={selectedTopic}
        selectedQuiz={selectedQuiz}
        splitDetails={true}
      />
      <div className={classNames.startQuizLayoutInstructions}>
        {t('readWithImageStartQuizInstructions')}
      </div>
      <div className={classNames.buttons}>
        <TheButton
          className={classNames.backBtn}
          plain
          text={t('backHome')}
          emitOnClick={emitGoBack}
        />
        <TheButton
          className={classNames.startQuizBtn}
          showArrow
          allowDynamicDirection={false}
          text={t('startActivity')}
          emitOnClick={onStartQuiz}
        />
      </div>
    </div>
  );

  const content = isMobile ? contentMobile : contentDesktop;

  return <div className={classNames.readQuestion}>{content}</div>;
};

export default ReadQuestion;
