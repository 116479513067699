import className from './ProgressSection.module.scss';
import classNames from 'classnames';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { unitSelector } from '@/store/reducers/unit';
import medalGold from '@/assets/svg/medal-gold.svg';
import star from '@/assets/svg/star.svg';
import trophy from '@/assets/svg/trophy.svg';
import commonUtils from '@/utils/common';
import { ProgressItem, ProgressInfo } from './components';
import UseScreenSize from '@/hooks/UseScreenSize';

const ProgressSection = () => {
  const { isMobileSmall } = UseScreenSize();
  const { user } = useAppSelector(authSelector);
  const { summary } = useAppSelector(unitSelector);

  const percentage = user?.progress
    ? commonUtils.roundNumber(
        (user.progress.completedActivities / summary.totalActivities) * 100,
        0
      )
    : 0;

  return (
    <>
      {summary?.level != 0 && (
        <div
          className={classNames(className.container, {
            [className.containerMobile]: isMobileSmall,
          })}
        >
          {!isMobileSmall && (
            <ProgressInfo level={summary.level} icon={medalGold} percentage={percentage} />
          )}
          <div className={className.statsSection}>
            {isMobileSmall && (
              <>
                <ProgressItem level={summary.level} title='level' />
                <div className={className.divider}></div>
              </>
            )}

            <ProgressItem
              icon={trophy}
              title='topics'
              completedValue={user?.progress?.completedTopics || 0}
              totalValue={summary?.totalTopics}
            />
            <div className={className.divider}></div>
            <ProgressItem
              icon={medalGold}
              title='activities'
              completedValue={user?.progress?.completedActivities || 0}
              totalValue={summary?.totalActivities}
            />
            <div className={className.divider}></div>
            <ProgressItem
              icon={star}
              title='stars'
              completedValue={user?.progress?.achievedStars || 0}
              totalValue={summary?.totalStars}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ProgressSection;
