import classes from 'classnames';
import UseCurrentRoute from '@/hooks/UseCurrentRoute';
import UseScreenSize from '@/hooks/UseScreenSize';
import { ROUTE_NAMES } from '@/router';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { SkillTypes } from '@/types/skill';

const DashboardLayout = () => {
  const currentRoute = UseCurrentRoute();
  const { isDesktop } = UseScreenSize();
  const { selectedType, showPreQuiz } = useAppSelector(gameSelector);

  const isQuizPage = currentRoute === ROUTE_NAMES.QUIZ;
  const isSpeak = selectedType === SkillTypes.Speak;

  return (
    <div
      className={classes('dashboard-wrapper h-full', {
        'flex flex-col': isDesktop && isQuizPage && showPreQuiz && isSpeak,
      })}
    >
      <Outlet />
    </div>
  );
};

export default DashboardLayout;
