import React, { useEffect, useState } from 'react';
import classNames from './DataControls.module.scss';
import { useTranslation } from 'react-i18next';
//TODO: Need to understand if it's needed
// import SVG from 'react-inlinesvg';
// import { useDrawer } from '@/context/DrawerContext';
import { TeacherGroup } from '@/types/user';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/store';
import { teacherDashboardActions } from '@/store/reducers/teacher-dashboard';
//TODO: Need to understand if it's needed
import ClassroomsSelect from '../ClassroomsSelect/ClassroomsSelect';
// import TheButton from '@/components/TheButton/TheButton';

// import filter from '@/assets/svg/filter.svg';

interface DataControlsProps {
  classrooms: TeacherGroup[];
  currentClassroom: TeacherGroup;
  currentOption: string;
  filterByOptions: string[];
  emitSetCurrentClassroom: (classroom: TeacherGroup) => void;
  emitFiltertBy: (option: string) => void;
}

const DataControls = ({
  classrooms,
  currentClassroom,
  currentOption,
  filterByOptions,
  emitSetCurrentClassroom,
  emitFiltertBy,
}: DataControlsProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [shouldDisplayDropdown, setShouldDisplayDropdown] = useState(false);
  //TODO: Need to understand if it's needed
  // const drawer = useDrawer();

  useEffect(() => {
    window.addEventListener('click', () => {
      if (!shouldDisplayDropdown) {
        return;
      }

      onCloseDropdown();
    });

    return () => {
      window.removeEventListener('click', () => {});
    };
  }, [shouldDisplayDropdown]);
  //TODO: Need to understand if it's needed
  // const onToggleDropdown = (e: React.MouseEvent<HTMLElement>) => {
  //   e.stopPropagation();
  //   setShouldDisplayDropdown((prevState) => !prevState);
  // };

  const onCloseDropdown = () => {
    setShouldDisplayDropdown(false);
  };

  const onSelectedOption = (e: React.MouseEvent<HTMLElement>, option: string) => {
    e.stopPropagation();
    onCloseDropdown();
    emitFiltertBy(option);
  };

  const onAddNewClass = () => {
    dispatch(teacherDashboardActions.onAdd());
    navigate('/dashboard/my-classes');
  };

  const filterByOptionsElements = filterByOptions.map(option => {
    return (
      <div
        key={option}
        className={classNames.sortByOption}
        onClick={$event => onSelectedOption($event, option)}
      >
        <span>{t(option)}</span>
      </div>
    );
  });
  //TODO: Need to understand if it's needed
  // const filterByDropdown = shouldDisplayDropdown && (
  //   <div className={classNames.sortByDropdown}>{filterByOptionsElements}</div>
  // );

  return (
    <div className={classNames.dataControls}>
      <ClassroomsSelect
        classrooms={classrooms}
        currentClassroom={currentClassroom}
        emitSetCurrentClassroom={emitSetCurrentClassroom}
        emitAddNewClass={onAddNewClass}
      />
      {
        //TODO: Need to understand if it's needed
        /* <TheButton
        text={t('addNewClass')}
        className={classNames.addNewClass}
        shouldRecolor={false}
        // emitOnClick={drawer.openAddClass}
        emitOnClick={onAddNewClass}
      /> */
      }
      {/* <button>Add New Class</button> */}
      {/* <div className={classNames.filterByContainer}>
        <div className={classNames.filterBySelect} onClick={onToggleDropdown}>
          <span className={classNames.text}>{t('filter')}</span>
          <span>{t(currentOption)}</span>
          <SVG className={classNames.icon} src={filter} />
        </div>
        {filterByDropdown}
      </div> */}
    </div>
  );
};

export default DataControls;
