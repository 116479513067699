import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '@/store';
import { SkillTypes } from '@/types/skill';
import commonUtils from '@/utils/common';
import { gameSelector } from '@/store/reducers/game';
import config from '@/config';
import { Topic, TopicTypes } from '@/types/topic';
import { appSettingsSelector } from '@/store/reducers/settings';

const UseSkill = (isCarousel = false) => {
  const params = useParams();
  const type = params.type as SkillTypes;
  const { selectedType, isLevelTest } = useAppSelector(gameSelector);
  const { topics: allTopics, topicsLoading } = useAppSelector(appSettingsSelector);

  const topics = useMemo<Topic[]>(() => {
    if (!isLevelTest) {
      const allTopicsCopy = Object.keys(allTopics).reduce((acc: any, key) => {
        acc[key] = allTopics[key].map((topic: any) => {
          if (topic.topicType === TopicTypes.EndOfSkillTest) {
            return {
              ...topic,
            };
          }
          return topic;
        });
        return acc;
      }, {});
      return allTopicsCopy[selectedType ?? SkillTypes.Grammar] ?? [];
    }

    return [];
  }, [allTopics, selectedType, topicsLoading]);

  const groupedTopics = useMemo<Topic[][]>(() => {
    return commonUtils.groupBySize(
      commonUtils.sortByKey([...(topics ?? [])], 'order'),
      isCarousel ? config.topicsPerSectionDesktop : config.topicsPerSection
    );
  }, [allTopics, selectedType]);

  return {
    topics,
    groupedTopics,
    isLoading: topicsLoading,
    type,
  };
};

export default UseSkill;
