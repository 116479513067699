import config from '@/config';

import { LevelTestQuestionsResponse } from '@/types/response';
import axiosInstanceNew from '@/utils/api-new';
import UserUtils from '@/utils/user';
import { UserState } from '@/types/user';
import { updateActivityResponseData } from './utils';

export const levelTestService: any = {
  fetchLevelTestQuestions: async (user: UserState) => {
    const resp: any | null = await axiosInstanceNew.get(
      `${config.apiVersion}/level-test/${UserUtils.fromToLanguage(user)}/level-test-questions/${user.id}`
    );
    return resp;
  },
  postLevelTestResult: async (user: UserState, result: any) => {
    const resp = await axiosInstanceNew.post(
      `${config.apiVersion}/level-test/${UserUtils.fromToLanguage(user)}/update-result/${user.id}`,
      result
    );
    return resp.data.data;
  },
};
