import classNames from './PopUpKeepGoing.module.scss';
import classes from 'classnames';
import { PopUpKeepGoingProps } from '@/types/game';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useState } from 'react';
import { useAppSelector } from '@/store';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';
import { gameSelector } from '@/store/reducers/game';
import { levelTestSelector } from '@/store/reducers/level-test';
import UserUtils from '@/utils/user';

import QuizTip from '../QuizTip/QuizTip';
import TheButton from '@/components/TheButton/TheButton';

import keepGoingCrown from '@/assets/svg/keepgoingpopup.svg';
import coinsRow from '@/assets/svg/coins-row.svg';
import crown from '@/assets/svg/crown.svg';
import levelTestCrown from '@/assets/svg/level-test-crown.svg';
import close from '@/assets/svg/close.svg';
import rightArrow from '@/assets/svg/right-arrow-large.svg';
import levelTestBG from '@/assets/svg/level-test-keep-going-bg.svg';

const PopUpKeepGoing = ({
  coins,
  questionsLeft,
  tip,
  className,
  emitOnClosePopUp,
}: PopUpKeepGoingProps) => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = UseScreenSize();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const { isLevelTest } = useAppSelector(gameSelector);
  const { currentOverAllQuizNumber } = useAppSelector(levelTestSelector);
  const [shouldExpand, setShouldExpand] = useState(false);

  const { user } = useAppSelector(authSelector);
  const isStudent = UserUtils.isStudent(user);

  const isInterfaceLanguageRtl = interfaceLanguage?.direction === 'rtl';
  const isInterfaceLanguageLtr = interfaceLanguage?.direction === 'ltr';
  let content;
  let title = t('keepGoing') + '!';
  let descriptionText = t('almostThere');

  if (isLevelTest) {
    if (isMobile) {
      descriptionText = t('youAreDoingGreatLetsKeepGoing');
    }

    if (currentOverAllQuizNumber === 10) {
      descriptionText = t('youAreOnYourWay');
    }
    if (currentOverAllQuizNumber === 20) {
      title = t('doingGreat');
      descriptionText = t('understandingYourEnglishLevel');
    }
    if (currentOverAllQuizNumber === 30) {
      title = t('almostFinish');
      descriptionText = t('customYourLearning');
    }
  }

  const onExpand = () => {
    setShouldExpand(true);
  };

  const onReduce = () => {
    setShouldExpand(false);
  };

  const titleElement = <h3 className={classNames.title}>{title}</h3>;

  const descriptionElement = (
    <div className={classNames.text}>
      <span dangerouslySetInnerHTML={{ __html: descriptionText }} />
      {!isLevelTest && (
        <span className={classNames.questionsLeft}>
          {t('questionsLeft')}: {questionsLeft}
        </span>
      )}
    </div>
  );

  const imgElement = (
    <div className={classNames.crown}>
      <SVG src={isLevelTest && isDesktop ? levelTestCrown : crown} />
    </div>
  );

  const coinsTextElement = (
    <div className={classNames.coins}>
      <SVG src={coinsRow} />
      <span>
        +{coins} {t('coins')}
      </span>
    </div>
  );

  const showCoins = !isLevelTest && isStudent && Number(coins) > 0;

  if (isLevelTest && isDesktop) {
    content = (
      <div className={classes('container', classNames.levelTest)}>
        <SVG
          className={classes(classNames.background, [
            classNames[`background-${interfaceLanguage?.direction}`],
          ])}
          src={levelTestBG}
        />
        {imgElement}
        <div className={classNames.content}>
          <div className={classNames.textContainer}>
            <h3>{title}</h3>
            <span>{descriptionText}</span>
          </div>
          <TheButton
            text={isLevelTest ? t('continue') : isDesktop ? t('continueQuiz') : t('okLetsGo')}
            emitOnClick={emitOnClosePopUp}
            shouldRecolor={false}
            showArrow={true}
          />
        </div>
      </div>
    );
  } else {
    content = (
      <div
        className={classes(classNames.popUpKeepGoing, className, {
          [classNames.popUpKeepGoingMobile]: isMobile,
          [classNames.levelTest]: isLevelTest,
        })}
      >
        {shouldExpand && isMobile && <div className={classNames.backdrop}></div>}
        {isDesktop && (
          <div className={classNames.desktopContent}>
            {imgElement}
            <div className={classNames.content}>
              {showCoins && coinsTextElement}
              {titleElement}
              {descriptionElement}
            </div>
          </div>
        )}
        {isMobile && (
          <>
            {showCoins && coinsTextElement}
            {titleElement}
            {imgElement}
            {descriptionElement}
          </>
        )}
        <div
          className={classes(classNames.bottom, {
            [classNames.expanded]: shouldExpand,
            [classNames.bottomNoTip]: !tip,
          })}
        >
          {shouldExpand && (
            <div className={classNames.close} onClick={onReduce}>
              <SVG src={close} />
            </div>
          )}
          <div
            className={classes(classNames.tipAndButton, {
              [classNames.tipAndButtonMobile]: isMobile,
            })}
          >
            {tip && (
              <QuizTip
                tip={tip ?? ''}
                className={classNames.tip}
                lampClassName={classes(
                  classNames.lamp,
                  classNames[`lamp-${interfaceLanguage?.direction}`]
                )}
                shouldExpand={shouldExpand}
                emitOnExpand={onExpand}
              />
            )}
            <TheButton
              className={classes(classNames.button, {
                [classNames.levelTestButtonColor]: isLevelTest,
              })}
              text={isLevelTest ? t('continue') : isDesktop ? t('continueQuiz') : t('okLetsGo')}
              emitOnClick={() => emitOnClosePopUp?.()}
              showArrow={true}
            />
          </div>
        </div>
      </div>
    );
  }

  return content;
};

export default PopUpKeepGoing;
