import { useMemo } from 'react';
import classes from 'classnames';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
//TODO: Need to understand if it's needed
import { authSelector } from '@/store/reducers/auth'; //interfaceLanguageSelector
import UserUtils from '@/utils/user';

import { GameTypeProps, MultiChoiceProps, GameTypes, NewGameTypes } from '@/types/game';
import { SkillTypes } from '@/types/skill';

import AnswerReward from '../../AnswerReward/AnswerReward';
import MultiChoiceOptionContent from './components/MultiChoiceOptionContent';

import classNames from './MultiChoiceOptions.module.scss';
import { levelTestSelector } from '@/store/reducers/level-test';

type MultiChoiceOptionsProps = {
  className?: string;
  optionClassName?: string;
  coins?: number;
  correctAnswer: GameTypeProps['correctAnswer'];
  pickedAnswer: MultiChoiceProps['pickedAnswer'];
  options: MultiChoiceProps['options'];
  showAnswerInOptions?: MultiChoiceProps['showAnswerInOptions'];
  isDictionary?: boolean;
  onSelect: (answer: string) => void;
};

const MultiChoiceOptions = ({
  className,
  optionClassName,
  coins,
  showAnswerInOptions,
  onSelect,
  pickedAnswer,
  correctAnswer,
  options,
  isDictionary = false,
}: MultiChoiceOptionsProps) => {
  const { selectedType, currentQuestionIndex, gameQuestions, isInEndOfSkillTest, isLevelTest } =
    useAppSelector(gameSelector);
  //FIXME: NEED TO REPLACE WITH CORRECT GAMETYPE
  const TEMP_GAMETYPE = (gameQuestions[0]?.gameType as any) || 8;

  //TODO: Need to understand if it's needed
  // const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  const { user } = useAppSelector(authSelector);
  const { activity, questionIndex } = useAppSelector(levelTestSelector);

  const isStudent = UserUtils.isStudent(user);

  const currentQuestion = useMemo(() => {
    return isLevelTest ? activity.questions[questionIndex] : gameQuestions[currentQuestionIndex];
  }, [currentQuestionIndex, gameQuestions, questionIndex]);

  const isRead = TEMP_GAMETYPE === NewGameTypes.Read;

  const optionsElements = options?.map((option, index) => {
    const isOptionSelected = option?.toLowerCase() === pickedAnswer?.toLowerCase();

    const isSelectedOptionCorrect = pickedAnswer?.toLowerCase() === correctAnswer?.toLowerCase();

    const isOptionCorrect = isOptionSelected && isSelectedOptionCorrect;
    const isOptionWrong = isOptionSelected && !isSelectedOptionCorrect;

    const optionClasses = classes(classNames.option, optionClassName, {
      'cursor-pointer': !pickedAnswer,
      [classNames.optionCorrect]: isOptionCorrect,
      [classNames.optionWrong]: isOptionWrong,
      // [classNames.leftJustify]:
      //   options.length === 3 &&
      //   index === 2 &&
      //   interfaceLanguage?.direction === 'rtl',
    });

    const alphabet = String.fromCharCode(index + 97);

    const isShowCoins =
      isStudent &&
      !currentQuestion?.userData?.achievedCoins &&
      !isInEndOfSkillTest &&
      isOptionCorrect &&
      !isLevelTest &&
      coins;

    const coinsTemplate =
      TEMP_GAMETYPE === NewGameTypes.AudioToImage || TEMP_GAMETYPE === NewGameTypes.TextToImage ? (
        <div className={classNames.coinsWrapper}>
          <AnswerReward className={classNames.answerReward} coins={coins || 0} />
        </div>
      ) : (
        <AnswerReward className={classNames.answerReward} coins={coins || 0} />
      );

    const imageCredit =
      TEMP_GAMETYPE === NewGameTypes.AudioToImage || TEMP_GAMETYPE === NewGameTypes.TextToImage
        ? currentQuestion.imagesCredits[option]
        : '';

    const imageAlt =
      TEMP_GAMETYPE === NewGameTypes.AudioToImage || TEMP_GAMETYPE === NewGameTypes.TextToImage
        ? currentQuestion.imagesAlt[option]
        : '';

    return (
      <div
        key={option}
        className={optionClasses}
        onClick={() => (pickedAnswer ? null : onSelect(option))}
      >
        <MultiChoiceOptionContent
          isOptionSelected={isOptionSelected}
          isOptionCorrect={isOptionCorrect}
          isDictionary={isDictionary}
          alphabet={alphabet}
          options={options}
          option={option}
          index={index}
          imageCredit={imageCredit}
          imageAlt={imageAlt}
        />
        {isShowCoins && coinsTemplate}
      </div>
    );
  });

  return (
    <div
      className={classes(
        classNames.multiChoiceOptions,
        {
          [classNames.audioToImageOptions]:
            TEMP_GAMETYPE === NewGameTypes.TextToImage ||
            TEMP_GAMETYPE === NewGameTypes.AudioToImage,
          [classNames.multiChoiceOptionsDictionary]: isDictionary,
          [classNames.multiChoiceOptionsRead]: isRead,
          [classNames.isInEndOfSkillTestRead]: isInEndOfSkillTest && isRead,
        },
        className
      )}
    >
      {optionsElements}
    </div>
  );
};

export default MultiChoiceOptions;
