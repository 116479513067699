import commonUtils from '@/utils/common';
import classNames from './QuizDetailsHeading.module.scss';
import classes from 'classnames';
import { SkillTypes } from '@/types/skill';
import { SkillSettingsItem } from '@/types/user';
import { Topic } from '@/types/topic';

import DetailSeperator from '@/components/DetailSeperator/DetailSeperator';
import UseLocalLang from '@/hooks/UseLocalLang';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { gameSelector } from '@/store/reducers/game';
import UseScreenSize from '@/hooks/UseScreenSize';
import { unitSelector } from '@/store/reducers/unit';
import UseUnitsAssets from '@/hooks/UseUnitsAssets';
import { UnitAssets } from '@/types/units';

interface QuizDetailsHeadingProps {
  selectedType?: SkillTypes | null;
  selectedSkill?: SkillSettingsItem | null;
  selectedTopic?: Topic | null;
  className?: string;

  isInGameWindow?: boolean;
}

const QuizDetailsHeading = ({
  selectedType,
  selectedSkill,
  selectedTopic,
  className,

  isInGameWindow = false,
}: QuizDetailsHeadingProps) => {
  const { isMobile } = UseScreenSize();
  const localLang = UseLocalLang();
  const { user } = useAppSelector(authSelector);
  const { t } = useTranslation();
  const { isInEndOfSkillTest, isFinalAssessment } = useAppSelector(gameSelector);
  const { selectedUnit } = useAppSelector(unitSelector);
  const { unitsAssets } = UseUnitsAssets();
  const unitAssets: UnitAssets = unitsAssets[selectedUnit.order || 1];

  let content = (
    <>
      <span style={{ color: unitAssets.modalBorderColor }}>{selectedUnit?.name}</span>
      {/* FIXME: Need to remove if not needed*/}
      {/* {isInEndOfSkillTest && (!isInGameWindow || isMobile) ? (
        <span className={classNames.endOfSkillTopic}>
          {t('level') + ' ' + user?.progress?.userLevel}
        </span>
      ) : (
        isInGameWindow &&
        selectedTopic &&
        !isInEndOfSkillTest && (
          <>
            {selectedTopic ? (
              <span className={classNames.topic}>
                {commonUtils.getName(selectedTopic, localLang)}
              </span>
            ) : (
              t('notAvailable')
            )}
          </>
        )
      )} */}
    </>
  );

  if (isFinalAssessment) {
    content = (
      <span className={classNames.finalEnglishLevelTestText}>{t('finalEnglishLevelTest')}</span>
    );
  }

  return <div className={classes(classNames.quizDetailsHeading, className)}>{content}</div>;
};

export default QuizDetailsHeading;
