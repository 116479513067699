import React, { useEffect } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';

import MyStudentsViewDesktop from '../my-students/MyStudentsViewDesktop';
import MyStudentsViewMobile from '../my-students/MyStudentsViewMobile';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { useNavigate } from 'react-router-dom';

const MyStudents = () => {
  const { isMobile } = UseScreenSize();
  const { user } = useAppSelector(authSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.metadata.role !== 'teacher') {
      navigate('/dashboard/my-classes');
    }
  }, []);

  if (isMobile) {
    return <MyStudentsViewMobile />;
  }

  return <MyStudentsViewDesktop />;
};

export default React.memo(MyStudents);
