import axiosInstance from '@/utils/api-new';
import config from '@/config';
import UserUtils from '@/utils/user';
import { UserState } from '@/types/user';
import { ClassReportReportResponse, StudentReportResponse } from '@/types/response/report';
import { ReportService } from '@/types/services/report';

export const reportService: ReportService = {
  fetchStudentReport: async (studentId: string | null, user: UserState | null) => {
    //todo need to check if this is the correct id of the student

    const fromToLanguage = UserUtils.fromToLanguage(user);
    const resp: StudentReportResponse | null = await axiosInstance.get(
      `${config.apiVersion}/teacher-view/reports/user-report/${fromToLanguage}/${user?.metadata?.id}/${studentId}`
    );
    return resp;
  },
  fetchClassReport: async (classId: string | null, user: UserState | null) => {
    const fromToLanguage = UserUtils.fromToLanguage(user);
    const resp: ClassReportReportResponse | null = await axiosInstance.get(
      `${config.apiVersion}/teacher-view/reports/group-report/${fromToLanguage}/${user?.metadata.id}/${classId}`
    );
    return resp;
  },
};
