import React, { useCallback } from 'react';
import SVG from 'react-inlinesvg';
import levelTestHat from '@/assets/svg/levelTestHat.svg';
import classNames from './GoToLevelTestDesktop.module.scss';
import { useTranslation } from 'react-i18next';
import TheButton from '../TheButton/TheButton';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/store';
import { levelTestActions } from '@/store/reducers/level-test';

const GoToLevelTestDesktop = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onEmitOnClick = useCallback(() => {
    dispatch(levelTestActions.setActivity(null));
    navigate('/dashboard/level-test');
  }, [navigate, dispatch]);

  return (
    <div className={classNames.goToLevelTest}>
      <div className={classNames.container}>
        <div className={classNames.hatAndText}>
          <SVG src={levelTestHat} />
          <h1>{t('goToLevelTest')}</h1>
        </div>
        <div>
          <TheButton
            emitOnClick={onEmitOnClick}
            text={t('startLevelTest')}
            className={classNames.button}
            showArrow
          />
        </div>
      </div>
    </div>
  );
};

export default GoToLevelTestDesktop;
