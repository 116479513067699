import config from '@/config';

import {
  TopicsResponse,
  QuizzesResponse,
  QuizProgressResponse,
  LevelTestQuestionsResponse,
} from '@/types/response';
import { SkillService } from '@/types/services';
import axiosInstance from '@/utils/api';
import axiosInstanceNew from '@/utils/api-new';
import { SkillTypes } from '@/types/skill';
import UserUtils from '@/utils/user';
import { UserState } from '@/types/user';
import { updateActivityResponseData } from './utils';

export const skillService: SkillService = {
  fetchTopics: async (skill: SkillTypes, user: UserState | null) => {
    const resp: TopicsResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/${skill.toLowerCase()}/${UserUtils.levelByLanguage(user, skill)}`
    );
    return resp;
  },
  fetchQuizzes: async (skill: SkillTypes, user: UserState, topicId: number) => {
    const resp: QuizzesResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/${skill.toLowerCase()}/${UserUtils.levelByLanguage(user, skill)}/${topicId}`
    );

    return resp;
  },
  //fetchQuizzes
  fetchGameTopic: async (
    user: UserState | null,
    level: number,
    unitId: number,
    topicId: number
  ) => {
    const resp: QuizzesResponse | null | any = await axiosInstanceNew.get(
      //FIXME: NEED TO USE REAL ID IN FEATURE
      `${config.apiVersion}/${UserUtils.fromToLanguage(user)}/game-content/${level}/${unitId}/${topicId}`
    );

    const mockResp = {
      // gameType: 0,
      nameLocal: '',
      name: 'Yes / No Questions',
      description: 'Interrogative Sentence Structure',
      descriptionLocal: '',
      urlName: 'Yes_NoQuestions',
    };

    const tempData = { ...resp };

    const tempContent = tempData.data.data;

    for (let index = 0; index < tempContent.length; index++) {
      tempContent[index] = {
        ...mockResp,
        ...resp.data.data[index],
        name: tempContent[index].title,
        order: +tempContent[index].order,
      };
    }

    return tempData;
  },
  //fetchQuestions
  fetchGameActivity: async (
    user: UserState | null,
    level: number,
    unitId: number,
    topicId: number,
    quizId: number
  ) => {
    const resp: QuizzesResponse | null | any = await axiosInstanceNew.get(
      `${config.apiVersion}/${UserUtils.fromToLanguage(user)}/game-content/${level}/${unitId}/${topicId}/${quizId}`
    );

    let tempContent = structuredClone(resp.data.data);

    //FIXME: New API response is () example:
    //   {
    //     "image_description": "An ear with sound waves coming in.",
    //     "image": "https://ell-dev.melingo.com/1/get-base64-image?imagePath=listen.jpg",
    //     "imageSource": null
    // }

    for (let index = 0; index < tempContent.length; index++) {
      //FIXME: Helper for make new responce structure to old one + add "newData" property. Need to be fixed in feature
      updateActivityResponseData(tempContent, index, resp.data.data);

      if (index + 1 >= tempContent.length) {
        tempContent = { quizId, questions: tempContent };

        return tempContent;
      }
    }
  },
  sendOpenWritingAnswer: async (user: UserState | null, payload: any) => {
    const resp: any | null = await axiosInstanceNew.post(
      `${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/grading/open-writing/${user?.metadata.id}`,
      payload
    );
    return resp;
  },
  fetchQuestions: async (skill: SkillTypes, user: UserState, topicId: number, quizId: number) => {
    const resp: QuizProgressResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/${skill.toLowerCase()}/${UserUtils.levelByLanguage(user, skill)}/${topicId}/${quizId}`
    );
    return resp;
  },
  fetchTestQuestions: async user => {
    const resp: LevelTestQuestionsResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(user)}/level-test-questions`
    );
    return resp;
  },
  fetchNewLevelTestQuestions: async user => {
    const resp = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/level-test/questions/${user?.metadata.id}`
    );
    return resp.data;
  },
  fetchEndOfSkillTest: async (user, skill, skillLevel) => {
    const resp = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/end-of-level-in-skill/${skill}/${skillLevel}`
    );

    return resp.data.data;
  },
  postEndOfSkillTest: async (user, skill, skillLevel, result) => {
    const resp = await axiosInstance.post(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/end-of-level-in-skill/${skill}/${skillLevel}/${user?.metadata.id}`,
      result
    );

    return resp.data.data;
  },
  userSkipLevelTest: async user => {
    await axiosInstance.post(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/level-test/user-skip-level-test/${user?.metadata.id}`
    );
  },
  postUserLevelTestResult: async (user, result) => {
    const resp = await axiosInstance.post(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/level-test/user-result/${user?.metadata.id}`,
      { levelTestBySkillUserResult: result }
    );
    return resp.data.data;
  },
  fetchFinalAssessment: async user => {
    const resp = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/final-assessment/questions/${user?.progress?.userLevel}`
    );

    return resp.data.data;
  },
  postFinalAssessment: async (user, result) => {
    const resp = await axiosInstance.post(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/final-assessment/${user?.progress?.userLevel}/${user?.metadata.id}`,
      result
    );

    return resp.data.data;
  },
};
