import classNames from './GenerateReport.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { StudentReportContent } from '@/types/generate-report';
import { GroupStudentProgress } from '@/types/progress';
import Header from './Header/Header';
import TopCard from './TopCard/TopCard';
import Card from './Card/Card';
import BarGraph from './BarGraph/BarGraph';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import * as helper from './helper';
import UseLevel from '@/hooks/UseLevel';
import common from '@/utils/common';
import { reportService } from '@/services/report';
import graphIcon from '@/assets/svg/graph.svg';
import learningIcon from '@/assets/svg/learning.svg';
import thumbRedIcon from '@/assets/svg/thumb-red.svg';

interface StudentReportProps {
  currentStudent: GroupStudentProgress;
  generateReportPdf: (element: HTMLElement | null) => void;
  onLoadReportError: () => void;
}

const StudentReport = ({
  currentStudent,
  generateReportPdf,
  onLoadReportError,
}: StudentReportProps) => {
  const [studentReport, setStudentReport] = useState<StudentReportContent | null | undefined>();
  const { t } = useTranslation();
  const { getLevelText } = UseLevel();
  const { user } = useAppSelector(authSelector);

  useEffect(() => {
    let isActive = true;

    const loadReport = async () => {
      if (!currentStudent) {
        return;
      }

      try {
        const studentReport = (await reportService.fetchStudentReport(currentStudent.id, user))
          ?.data?.data;

        if (isActive) {
          setStudentReport(studentReport);
        }
      } catch (error) {
        if (isActive) {
          setStudentReport(null);
          onLoadReportError();
        }
      }
    };
    loadReport();

    return () => {
      isActive = false;
    };
  }, [currentStudent, user, onLoadReportError]);

  const currentStudentData = useMemo(() => {
    const studentName = `${currentStudent?.firstName} ${currentStudent?.lastName}`;

    return {
      studentName,
    };
  }, [currentStudent]);

  const studentReportData = useMemo(() => {
    if (!studentReport) {
      return null;
    }

    const level = studentReport?.userLevel || 0;
    const lavelName = getLevelText(level);
    const overallProgressInLevel =
      common.roundNumber(studentReport?.overallProgressInLevel || 0, 0).toString() + '%';
    const passRate = common.roundNumber(studentReport?.passRate || 0, 0).toString() + '%';
    const failureRate = common.roundNumber(studentReport?.failureRate || 0, 0).toString() + '%';

    const engagementItems: {
      label: string;
      value?: string;
    }[] = [
      {
        label: t('reports.total'),
        value: helper.timeString(studentReport?.engagement?.total || 0),
      },
      {
        label: t('reports.totalInPrev7Days'),
        value: helper.timeString(studentReport?.engagement?.totalInPrev7Days || 0),
      },
      {
        label: t('reports.avgPerWeek'),
        value: helper.timeString(studentReport?.engagement?.averagePerWeek || 0),
      },
    ];

    const progressItems: {
      label: string;
      value?: string;
    }[] = [
      {
        label: t('reports.total'),
        value: common
          .roundNumber(studentReport?.progress?.totalActivitiesCompletedInLevel || 0, 2)
          .toString(),
      },
      {
        label: t('reports.previous7Days'),
        value: common
          .roundNumber(studentReport?.progress?.totalActivitiesCompletedInTheLast7Days || 0, 2)
          .toString(),
      },
      {
        label: t('reports.avgPerWeek'),
        value: common
          .roundNumber(studentReport?.progress?.averageActivitysPerWeek || 0, 2)
          .toString(),
      },
    ];

    const streakLengthItems: {
      label: string;
      value?: string;
    }[] = [
      {
        label: t('reports.longestStreak'),
        value: common.roundNumber(studentReport?.streakLength?.longestStreak || 0, 2).toString(),
      },
      {
        label: t('reports.streakLast7Days'),
        value: common.roundNumber(studentReport?.streakLength?.strakLast7Days || 0, 2).toString(),
      },
      {
        label: t('reports.avgStreakPerWeek'),
        value: common
          .roundNumber(studentReport?.streakLength?.averageStreakPerWeek || 0, 2)
          .toString(),
      },
    ];

    const attemptsAndHintsItems: {
      label: string;
      value?: string;
    }[] = [
      {
        label: t('reports.avgAttemptsPerActivity'),
        value: common
          .roundNumber(studentReport?.attemptsAndHints?.averageAttemptsPerActivity || 0, 2)
          .toString(),
      },
      {
        label: t('reports.totalHintsUsed'),
        value: common
          .roundNumber(studentReport?.attemptsAndHints?.totalHintsUsed || 0, 2)
          .toString(),
      },
      {
        label: t('reports.avgHintsPerActivity'),
        value: common
          .roundNumber(studentReport?.attemptsAndHints?.averageHintsPerActivity || 0, 2)
          .toString(),
      },
    ];

    return {
      level,
      lavelName,
      overallProgressInLevel,
      passRate,
      failureRate,
      engagementItems,
      progressItems,
      streakLengthItems,
      attemptsAndHintsItems,
    };
  }, [studentReport, getLevelText, t]);

  const reportSkillsData = useMemo(() => {
    if (!studentReport) {
      return null;
    }

    const avgSkillScoresMap = (studentReport?.avgSkillsScore || []).reduce((map, item) => {
      map.set(item.skillId, item.skillScore);
      return map;
    }, new Map<number, number>());

    const avgSkillScoresItems: {
      label: string;
      value: number;
    }[] = [];

    helper.reportSkillsList.forEach(skill => {
      const label = t(`skills.${skill.name?.toLowerCase()}`);

      avgSkillScoresItems.push({
        label,
        value: common.roundNumber(avgSkillScoresMap.get(skill.id || 0) || 0, 0),
      });
    });

    return {
      avgSkillScoresItems,
    };
  }, [studentReport, t]);

  return (
    studentReportData &&
    reportSkillsData && (
      <div ref={generateReportPdf} className={classNames.pdfContainer}>
        <Header
          items={[
            { label: t('studentName'), value: currentStudentData.studentName },
            {
              label: t('level'),
              value: `${studentReportData.level} | ${studentReportData.lavelName}`,
            },
            { label: t('date'), value: helper.dateString(new Date()) },
          ]}
        />
        <div className={classNames.contentContainer}>
          <TopCard
            items={[
              {
                icon: graphIcon,
                label: common.formatString(
                  t('reports.overallProgressInLevel'),
                  studentReportData.level
                ),
                value: studentReportData.overallProgressInLevel,
              },
              {
                icon: learningIcon,
                label: t('reports.passRate'),
                value: studentReportData.passRate,
              },
              {
                icon: thumbRedIcon,
                label: t('reports.failureRate'),
                value: studentReportData.failureRate,
                color: '#FF0000',
              },
            ]}
          />
          <div className={classNames.lineContainer}>
            <Card
              width='266px'
              height='154px'
              title={t('reports.engagement')}
              description={t('reports.timeSpentOnActivites')}
              items={studentReportData.engagementItems}
            />
            <Card
              width='266px'
              height='154px'
              title={t('reports.progress')}
              description={common.formatString(
                t('reports.activitiesCompletedInLevel'),
                studentReportData.level
              )}
              items={studentReportData.progressItems}
            />
          </div>
          <div className={classNames.lineContainer}>
            <Card
              width='266px'
              height='154px'
              title={t('reports.streakLength')}
              description={t('reports.continuousEngagement')}
              items={studentReportData.streakLengthItems}
            />
            <Card
              width='266px'
              height='154px'
              title={t('reports.attemptsAndHints')}
              description={t('reports.inCurrentLevel')}
              items={studentReportData.attemptsAndHintsItems}
            />
          </div>
          <BarGraph
            width='552px'
            height='216px'
            title={t('reports.avgSkillScores')}
            items={reportSkillsData.avgSkillScoresItems}
          />
        </div>
      </div>
    )
  );
};

export default StudentReport;
