import { useTranslation } from 'react-i18next';
import classes from 'classnames';
import classNames from './LoginForm.module.scss';
import { useEffect, useState } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';

//TODO: Need to understand if it's needed
// import QuickLogin from '../QuickLogin/QuickLogin';
import TheButton from '../TheButton/TheButton';
import { UseLoginInterface } from '@/pages/login/UseLogin';
import { LoginStep } from '@/types/login';

interface LoginFormProps {
  hideWelcome: () => void;
  useLoginData: UseLoginInterface;
  // isFirstTime: boolean;
  // loading: boolean;
  // LoginSchema: Yup.ObjectSchema<LoginValues>;
  // OnLoginSubmitted: (values: LoginValues) => Promise<void>;
  // InitialLoginValues: LoginValues;
}

const LoginForm = ({ hideWelcome, useLoginData }: LoginFormProps) => {
  const { t } = useTranslation();
  const { loading, isFirstTime, LoginComponent, currentLoginStep, stepProps } = useLoginData;

  useEffect(() => {
    if (currentLoginStep?.type === 4) {
      hideWelcome();
    }
  }, [currentLoginStep, hideWelcome]);

  const { isDesktop, isMobile } = UseScreenSize();

  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    if (isDesktop && !isFirstTime) {
      setShowForm(false);
    }
  }, [isFirstTime, isDesktop]);

  //TODO: Need to understand if it's needed
  // const onQuickLogin = () => {};

  // const name = 'Sarah Jonas';
  // const level = 'Foundation';
  // const grade = 9;

  const bottom = !showForm && (
    <TheButton
      className={classNames.loginFromDifferent}
      plain
      text={t('loginFromDifferent')}
      emitOnClick={() => setShowForm(true)}
    />
  );

  //TODO: Need to understand if it's needed
  // const content = showForm ? (
  //   <>old</>
  // ) : (
  //   <QuickLogin
  //     name={name}
  //     level={level}
  //     grade={grade}
  //     emitQuickLogin={onQuickLogin}
  //   />
  // );

  let title = currentLoginStep?.title;
  let subTitle = currentLoginStep?.subTitle;
  if (isMobile && currentLoginStep?.type === LoginStep.COGNITO_PASSWORD_CHANGE) {
    title = 'signIn';
    subTitle = 'pleaseChooseYourOwnPassword';
  }

  return (
    <div
      className={classes(classNames.loginForm, {
        [classNames.loginFormMobile]: isMobile,
      })}
    >
      {(title || subTitle) && (
        <div className={classNames.top}>
          {title && <h2>{t(title)}</h2>}
          {subTitle && <span>{t(subTitle)}</span>}
        </div>
      )}
      {/* {content} */}
      <div className={classNames.contentWrapper}>
        {LoginComponent && <LoginComponent styles={classNames} {...stepProps()} />}
      </div>
      {!loading && bottom}
    </div>
  );
};

export default LoginForm;
