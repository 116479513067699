import { createAsyncThunk } from '@reduxjs/toolkit';
import { unitService } from '@/services/unit';
import { RootState } from '@/types/common';

const unitThunks = {
  fetchUnits: createAsyncThunk(
    'units/fetchUnits',
    async ({ level }: { level: number }, { getState, rejectWithValue }) => {
      try {
        const state = getState() as RootState;
        const user = state.auth.user;
        const response = await unitService.fetchUnits(user, level);
        return { level, units: response.data.data };
      } catch (error: any) {
        console.error('Error fetching units:', error);
        return rejectWithValue(error.response?.data || 'Failed to fetch units');
      }
    }
  ),
};

export default unitThunks;
