import UseScreenSize from '@/hooks/UseScreenSize';
import classNames from './VocabularyPreparation.module.scss';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import commonUtils from '@/utils/common';
import { VocabularyPreparationProps } from '@/types/game';

import TheButton from '@/components/TheButton/TheButton';
import QuizDetailsHeading from '../../QuizDetailsHeading/QuizDetailsHeading';
import GoBackBtn from '@/components/GoBackBtn/GoBackBtn';

const VocabularyPreparation = ({
  selectedQuiz,
  selectedType,
  selectedSkill,
  selectedTopic,
  emitOnStartQuiz,
  emitGoBack,
  emitOnPractice,
}: VocabularyPreparationProps) => {
  const { t } = useTranslation();
  const { isMobile } = UseScreenSize();

  const mainBoldText = t('toImproveYourVocabulary');
  const description = t('youCanPractice');

  const goodLuckText = (
    <div className={classNames.vocabularyText}>
      <span>{t('letsSee')} </span>
      <span className={classNames.goodLuck}>{t('goodLuck')}</span>
    </div>
  );

  const quizName = selectedQuiz && <h2>{commonUtils.getName(selectedQuiz)}</h2>;

  const skillImage = (
    <SVG
      className={classNames.skillImage}
      src={selectedType ? commonUtils.skillImage(selectedType) : ''}
    />
  );

  const getDescription = () => {
    return isMobile
      ? `<p>
        <span style="font-weight: bold">${mainBoldText}</span><br/>
        <span>${description}</span>
        </p>`
      : `<p>
          <span style="font-weight: bold">${mainBoldText}</span>
          <span>${description}</span>
          <span>${t('letsSee')}</span>
          <span style="font-weight: bold">${t('goodLuck')}</span>
        </p>`;
  };

  const content = isMobile ? (
    <>
      <div className={classNames.details}>
        <QuizDetailsHeading
          selectedType={selectedType}
          selectedSkill={selectedSkill}
          selectedTopic={selectedTopic}
        />
        {quizName}
        <div
          className={classNames.description}
          dangerouslySetInnerHTML={{
            __html: getDescription(),
          }}
        ></div>
      </div>
      {skillImage}
      {goodLuckText}
    </>
  ) : (
    <>
      {skillImage}
      <div className={classNames.details}>
        <QuizDetailsHeading
          className={classNames.quizDetailsHeading}
          selectedType={selectedType}
          selectedSkill={selectedSkill}
          selectedTopic={selectedTopic}
        />
        {quizName}
        <div
          className={classNames.description}
          dangerouslySetInnerHTML={{
            __html: getDescription(),
          }}
        ></div>
      </div>
    </>
  );

  return (
    <div className={classNames.vocabularyPreparation}>
      {isMobile && (
        <div className={classNames.top}>
          <GoBackBtn
            className={classNames.goBackBtn}
            showText={false}
            emitClick={() => emitGoBack?.()}
          />
        </div>
      )}
      {content}
      <div className={classNames.bottom}>
        <div className={classNames.buttons}>
          <TheButton
            className={classNames.button}
            text={t('startQuiz')}
            emitOnClick={emitOnStartQuiz}
          />
          <TheButton
            className={classNames.button}
            text={t('practice')}
            plain
            emitOnClick={emitOnPractice}
          />
        </div>
      </div>
    </div>
  );
};

export default VocabularyPreparation;
