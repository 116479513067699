import UseScreenSize from '@/hooks/UseScreenSize';
import classNames from './ClassroomsSelect.module.scss';
import classes from 'classnames';
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { TeacherGroup } from '@/types/user';
//TODO: Need to understand if it's needed
// import { useDrawer } from '@/context/DrawerContext';

import upArrow from '@/assets/svg/up-arrow.svg';
import downArrow from '@/assets/svg/down-arrow.svg';
import pencil from '@/assets/svg/pencil.svg';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/store';
import { teacherDashboardActions } from '@/store/reducers/teacher-dashboard';
import TheButton from '@/components/TheButton/TheButton';

interface ClassroomsSelectProps {
  classrooms: TeacherGroup[];
  currentClassroom: TeacherGroup;
  emitSetCurrentClassroom: (classroom: TeacherGroup) => void;
  emitAddNewClass: () => void;
}

const ClassroomsSelect = ({
  classrooms,
  currentClassroom,
  emitSetCurrentClassroom,
  emitAddNewClass,
}: ClassroomsSelectProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  //TODO: Need to understand if it's needed
  // const drawer = useDrawer();
  const { isMobile } = UseScreenSize();
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    window.addEventListener('click', () => {
      if (!showDropdown) {
        return;
      }

      onCloseDropdown();
    });

    return () => {
      window.removeEventListener('click', () => {});
    };
  }, [showDropdown]);

  const onToggleDropdown = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowDropdown(prevState => !prevState);
  };

  const onCloseDropdown = () => {
    setShowDropdown(false);
  };

  const onSelectedOption = (e: React.MouseEvent<HTMLElement>, group: TeacherGroup) => {
    e.stopPropagation();
    onCloseDropdown();
    emitSetCurrentClassroom(group);
  };

  const onEditClass = (classId: string) => {
    dispatch(teacherDashboardActions.onEdit(classId));
    navigate('/dashboard/my-classes');
  };

  const classroomsOptions = classrooms.map((classroom, index) => {
    return (
      <div
        key={index}
        className={classNames.classroomOption}
        onClick={$event => onSelectedOption($event, classroom)}
      >
        <span>{classroom.name}</span>
      </div>
    );
  });

  const classroomsSelectDropdown = showDropdown && (
    <div className={classNames.classroomsDropdown}>{classroomsOptions}</div>
  );

  const classroomsSelect = (
    <div className={classNames.classroomsSelectContainer}>
      <div className={classNames.classroomsSelect} onClick={onToggleDropdown}>
        <span className={classNames.text}>{t('class')}:</span>
        {currentClassroom && (
          <span className={classNames.span}>{currentClassroom?.name.charAt(0)}...</span>
        )}
        <SVG src={showDropdown ? upArrow : downArrow} />
      </div>
      {classroomsSelectDropdown}
    </div>
  );

  const classroomsTabs = classrooms.map(classroom => {
    const isSelected = currentClassroom.id === classroom.id;

    return (
      <div
        key={classroom.id}
        className={classes(classNames.classroomTab, {
          [classNames.classroomTabSelected]: isSelected,
        })}
      >
        <button
          className={classNames.classRoomSelectBtn}
          onClick={() => emitSetCurrentClassroom(classroom)}
        >
          {classroom.name}
        </button>
        {isSelected && (
          <button
            className={classNames.classRoomEdittBtn}
            //TODO: Need to understand if it's needed
            // onClick={() => drawer.openEditClass(currentClassroom.id)}
            onClick={() => onEditClass(currentClassroom.id)}
          >
            <SVG src={pencil} />
          </button>
        )}
        <span className={classNames.bar}></span>
      </div>
    );
  });

  return (
    <div className={classNames.classroomsSelect}>
      {isMobile ? (
        classroomsSelect
      ) : (
        <div className={classNames.classroomsTabsWrapper}>
          <h2>{t('settings.my_students')}</h2>
          <div className={classNames.classroomsTabs}>
            {classroomsTabs}
            <TheButton
              text={`+ ${t('addNewClass')}`}
              className={classNames.addNewClass}
              plain
              //TODO: Need to understand if it's needed
              // emitOnClick={drawer.openAddClass}
              emitOnClick={emitAddNewClass}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassroomsSelect;
