import classes from 'classnames';
import { Road, Topic } from '@/types/topic';
import classNames from './Roadmap.module.scss';
import commonUtils from '@/utils/common';

import SkillTopic from '../SkillPage/SkillTopics/SkillTopic/SkillTopic';

import road1 from '@/assets/svg/road-1.svg';
import road2 from '@/assets/svg/road-2.svg';
import road3 from '@/assets/svg/road-3.svg';
import config from '@/config';

interface RoadmapProps {
  topics: Topic[];
  groupedTopics: Topic[][];
  emitOpenModal: (topic: Topic) => void;
}

const Roadmap = ({ topics, groupedTopics, emitOpenModal }: RoadmapProps) => {
  const roads = [
    {
      order: 1,
      img: road1,
    },
    {
      order: 2,
      img: road2,
    },
    {
      order: 3,
      img: road3,
    },
  ] as Road[];

  const getRoadByOrder = (order: number) => {
    return roads.find(item => item.order === order);
  };

  return (
    <div className={classNames.roadmap}>
      {groupedTopics.map((topicsGroup, topicsGroupIndex) => {
        return (
          <div
            key={topicsGroupIndex}
            className={classes(classNames.topicsGroup, {
              [classNames.topicsGroupBeyond]: topicsGroupIndex,
            })}
          >
            {topicsGroup.map((topic, topicIndex) => {
              let lockedBeforeFlag = false;
              if (topic.id === 0) {
                const lastGroupLength = groupedTopics[groupedTopics.length - 1].length;

                const lastValidGroup =
                  groupedTopics[
                    lastGroupLength > 1 ? groupedTopics.length - 1 : groupedTopics.length - 2
                  ];
                const topicBeforeFlag = lastValidGroup[lastValidGroup.length - 1];

                lockedBeforeFlag =
                  topic.id === 0 && commonUtils.isTopicLocked(topicBeforeFlag, topics);
              }
              const indexToDisplay = topicsGroupIndex * config.topicsPerSection + topicIndex;

              return (
                <SkillTopic
                  key={topic.id}
                  topic={topic}
                  isLocked={lockedBeforeFlag || commonUtils.isTopicLocked(topic, topics)}
                  isComplete={commonUtils.isTopicCompleted(topic)}
                  road={getRoadByOrder(topicIndex + 1)}
                  index={indexToDisplay}
                  emitOpenModal={() => emitOpenModal(topic)}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Roadmap;
