import { createAsyncThunk } from '@reduxjs/toolkit';
import { skillService } from '@/services';
import { levelTestService } from '@/services';
import { RootState } from '@/types/common';
import commonUtils from '@/utils/common';
import { LevelTestItem } from '@/types/quiz';
import { SkillTypes } from '@/types/skill';
import GameUtils from '@/utils/gameUtils';
import { gameActions } from '../reducers/game';

const levelTestThunks = {
  fetchLevelTestQuestions: createAsyncThunk('level-test/testQuestions', async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const questions = await levelTestService.fetchLevelTestQuestions(state.auth.user);
      return questions.data.data;
    } catch (error) {
      throw error;
    }
  }),
  postLevelTestResult: createAsyncThunk(
    'level-test/levelTestResult',
    async (_, { getState, dispatch, rejectWithValue }) => {
      try {
        const state = getState() as RootState;
        const levelTestResult = {
          questions: state.levelTest.answers,
          userId: state.auth.user?.id,
          activityId: state.levelTest.activity.activityId,
          topicId: 0,
          unitId: 0,
          correctAnswers: state.levelTest.correctAnswers,
          wrongAnswers: state.levelTest.wrongAnswers,
          isCompleted: true,
          questionsAnswered: 10,
          score: state.game.total_correctAnswers * 10,
          startTime: '2024-08-14T13:40:39.449',
          finishTime: '2024-08-14T13:41:48.745',
          firstName: state.auth.user?.metadata.firstName,
          lastName: state.auth.user?.metadata.lastName,
          groupId: state.auth.user?.metadata.organizationDetails.groupId || '',
          organizationId: state.auth.user?.metadata.organizationDetails.organizationId,
          email: state.auth.user?.metadata.email,
        };

        const result = await levelTestService.postLevelTestResult(state.auth.user, levelTestResult);

        return result;
      } catch (err: any) {
        console.log(err);
        return rejectWithValue(err.response?.data || err.message);
      }
    }
  ),
  fetchTestQuestions: createAsyncThunk('skill/testQuestions', async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const questions = await skillService.fetchTestQuestions(state.auth.user);
      return questions;
    } catch (error) {
      throw error;
    }
  }),
  postUserLevelTestResult: createAsyncThunk(
    'skill/levelTestResult',
    async (_, { getState, dispatch }) => {
      try {
        const state = getState() as RootState;
        const levelTestBySkillUserResult = {
          correctAnswers: state.game.total_correctAnswers,
          finishTime: GameUtils.formatDate(new Date(), '/', 'milliseconds'), //need to wait for moran to change the endpoint to work with this
          questionsResultBySkill: state.game.levelTestQuizResultData,
        };

        const response = await skillService.postUserLevelTestResult(
          state.auth.user,
          levelTestBySkillUserResult
        );

        const sortedArray: LevelTestItem[] = commonUtils.sortByKey(response?.questions, 'order');

        const RefactorLevelTestQuestions = sortedArray.map((item: LevelTestItem) => {
          return item;
        });
        const last = { ...response, questions: RefactorLevelTestQuestions };

        dispatch(gameActions.resetCurrentReadQuestionIndex());

        return last;
      } catch (err) {
        console.log(err);
      }
    }
  ),
  fetchNewLevelTestQuestions: createAsyncThunk(
    'skill/levelTestQuestions',
    async (_, { getState }) => {
      try {
        const state = getState() as RootState;
        const questions = await skillService.fetchNewLevelTestQuestions(state.auth.user);
        const sortedArray: LevelTestItem[] = commonUtils.sortByKey(questions?.data, 'order');

        const RefactorLevelTestQuestions = sortedArray.map((item: LevelTestItem) => {
          return item;
        });

        return RefactorLevelTestQuestions;
      } catch (error) {
        throw error;
      }
    }
  ),
};

export default levelTestThunks;
