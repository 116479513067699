import firstTopic from '@/assets/svg/topicIcons/firstTopic.svg';
import secondTopic from '@/assets/svg/topicIcons/secondTopic.svg';
import thirdTopic from '@/assets/svg/topicIcons/thirdTopic.svg';
import fourthTopic from '@/assets/svg/topicIcons/fourthTopic.svg';
import fifthTopic from '@/assets/svg/topicIcons/fifthTopic.svg';
import sixTopic from '@/assets/svg/topicIcons/sixTopic.svg';
import { TopicImagesKeyType, TopicImagesType } from './types';

const topicImages: TopicImagesType = {
  1: firstTopic,
  2: secondTopic,
  3: thirdTopic,
  4: fourthTopic,
  5: fifthTopic,
  6: sixTopic,
};

export const getTopicImage = (currentTopicOrder: number): string => {
  if (!topicImages || !currentTopicOrder) return firstTopic;

  const topicKeys = Object.keys(topicImages);

  if (!topicKeys.includes(String(currentTopicOrder))) return firstTopic;

  return topicImages[currentTopicOrder as TopicImagesKeyType];
};
