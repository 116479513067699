import { VerificationCodeProps } from '@/types/registration';
import classNames from './VerificationCode.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';

import AppInput from '@/components/AppInput/AppInput';
import TheButton from '@/components/TheButton/TheButton';

const VerificationCode = ({
  verificationCodeField,
  emitSubmit,
  isLastStep,
}: VerificationCodeProps) => {
  const { t } = useTranslation();

  return (
    <form className={classNames.verificationCode} onSubmit={emitSubmit}>
      <div className={classNames.fields}>
        {verificationCodeField && (
          <AppInput
            shouldTranslate
            key={verificationCodeField?.id}
            className={classes(classNames.field, {
              [classNames.fieldError]: verificationCodeField?.error,
            })}
            inputWrapperClassName={classNames.inputWrapperClassName}
            {...verificationCodeField}
            placeholder={`6 ${t('characters')}`}
            isRequired={true}
            helpText={t('enterVerificationCodeHelpText').toString()}
          />
        )}
      </div>
      <TheButton
        className={classNames.submitBtn}
        disabled={!verificationCodeField?.value}
        text={t(isLastStep ? 'done' : 'next')}
        type='submit'
        shouldRecolor={false}
      />
    </form>
  );
};

export default VerificationCode;
