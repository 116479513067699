import classNames from './GoBackBtn.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';

import leftArrow from '@/assets/svg/left-arrow.svg';

interface GoBackBtnProps {
  className?: string;
  showIcon?: boolean;
  showText?: boolean;
  emitClick: () => void;
}

const GoBackBtn = ({ className, showIcon = true, showText = true, emitClick }: GoBackBtnProps) => {
  const { t } = useTranslation();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  return (
    <button
      aria-label={t('go_back_btn').toString()}
      className={classes(classNames.goBackBtn, className, [
        classNames[`goBackBtn-${interfaceLanguage?.direction}`],
      ])}
      onClick={emitClick}
    >
      {showText && t('back')}
      {showIcon && <SVG src={leftArrow} />}
    </button>
  );
};

export default GoBackBtn;
