import classNames from './CommunicationProblemModal.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';

import TheButton from '@/components/TheButton/TheButton';
import AppBackdrop from '@/components/AppBackdrop';
import AppModal from '@/components/AppModal';

import oppsImage from '@/assets/images/opps.png';

interface CommunicationProblemModalProps {
  show: boolean;
  onRefresh: () => void;
}

const CommunicationProblemModal = ({ show, onRefresh }: CommunicationProblemModalProps) => {
  const { t } = useTranslation();

  return (
    <Transition
      in={show}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => {
        return (
          <>
            <AppBackdrop className={classes(classNames.backdrop, `backdrop-${state}`)} />
            <AppModal className={classNames.appModal}>
              <div className={classes(classNames.modal, `fade-${state}`)}>
                <img src={oppsImage} />
                <h2>{t('CommunicationProblemModalTitle')}</h2>
                <span
                  className={classNames.description}
                  dangerouslySetInnerHTML={{
                    __html: t('CommunicationProblemModalDescription').toString(),
                  }}
                />
                <TheButton
                  className={classNames.closeBtn}
                  text={t('refresh')}
                  shouldRecolor={false}
                  emitOnClick={onRefresh}
                />
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );
};

export default CommunicationProblemModal;
