// import TheButton from '@/components/TheButton/TheButton';
import { useAppSelector } from '@/store';
import { unitSelector } from '@/store/reducers/unit';
import { getTopicImage } from './helper';
import classNames from './TopicsDrawerHeader.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { TopicImagesKeyType } from './types';
import { useTranslation } from 'react-i18next';
import UseScreenSize from '@/hooks/UseScreenSize';

export const TopicsDrawerHeader = () => {
  const { t } = useTranslation();
  const { isMobileSmall } = UseScreenSize();
  const { selectedTopic, selectedUnit } = useAppSelector(unitSelector);

  const unitOrder = selectedUnit?.order || 1;

  const selectedTopicImage = getTopicImage(unitOrder as TopicImagesKeyType);

  return (
    <div
      className={classes(classNames.topicDrawerHeader, {
        [classNames.topicDrawerHeaderMobile]: isMobileSmall,
      })}
    >
      <div className={classNames.left}>
        <SVG src={selectedTopicImage} />
        <div className={classNames.title}>
          <h2>{t('thisTopicWillHelpMe')}</h2>
          <h1>{selectedTopic?.cEFRDescriptor}</h1>
        </div>
      </div>
      {/* FIXME: Need to ad it with functionality that switch Quizes*/}
      {/* <TheButton
        className={classNames.nextQuizBtn}
        text={'Next quiz'}
        showArrow={true}
        shouldRecolor={false}
        emitOnClick={() => {}}
      /> */}
    </div>
  );
};
