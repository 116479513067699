import React from 'react';
import classNames from './CognitoVerify.module.scss';
import classes from 'classnames';
import { CognitoVerificationCodeProps } from '@/types/registration';
import AppInput from '@/components/AppInput/AppInput';
import TheButton from '@/components/TheButton/TheButton';
import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';

const CognitoVerify = ({
  verificationCodeField,
  role,
  isLastStep,
  email,
  resendVerificationCode,
  emitSubmit,
}: CognitoVerificationCodeProps) => {
  const { t } = useTranslation();
  const { interfaceLanguage } = useAppSelector(authSelector);

  return (
    <form className={classNames.cognitoVerify} onSubmit={emitSubmit}>
      <div className={classNames.top}>
        <h2>
          {t('verificationCodeSent')}: {email}.
        </h2>
        <div className={classNames.fields}>
          {verificationCodeField && (
            <AppInput
              onChange={verificationCodeField?.emitChange}
              shouldTranslate
              maxLength={6}
              key={verificationCodeField?.id}
              className={classes(classNames.field, {
                [classNames.fieldError]: verificationCodeField?.error,
              })}
              inputWrapperClassName={classNames.inputWrapperClassName}
              {...verificationCodeField}
              label={verificationCodeField.label}
            />
          )}
        </div>
        <div className={classNames.description}>
          <div className={classNames.partOne}>
            <Trans i18nKey={'checkInSpamMessage'}>
              <span className='font-semibold'></span>
            </Trans>
          </div>
          <div className={classNames.partTwo}>
            <Trans i18nKey={'orClickHereToSendAgain'}>
              <a onClick={resendVerificationCode} className={classNames.resend}></a>
            </Trans>
          </div>
        </div>
      </div>
      <TheButton
        className={classNames.submitBtn}
        disabled={!verificationCodeField?.value || verificationCodeField?.value.length < 6}
        text={t(isLastStep ? 'done' : 'next')}
        type='submit'
        shouldRecolor={false}
      />
    </form>
  );
};

export default CognitoVerify;
