import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from './PracticeCard.module.scss';
import classes from 'classnames';
import TinderCard from 'react-tinder-card';
import { Question } from '@/types/question';
import SVG from 'react-inlinesvg';
import commonUtils from '@/utils/common';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';
import { gameSelector } from '@/store/reducers/game';
import { authSelector } from '@/store/reducers/auth';

import soundPlay from '@/assets/svg/speaker.svg';
import UseUnitsAssets from '@/hooks/UseUnitsAssets';
import { unitSelector } from '@/store/reducers/unit';
import { UnitAssets } from '@/types/units';

interface PracticeCardProps {
  question: Question;
  index: number;
  emitOnSwipe: (questionId: number) => void;
}

const PracticeCard = ({ question, index, emitOnSwipe }: PracticeCardProps) => {
  const { isDesktop, isMobile } = UseScreenSize();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const { selectedUnit } = useAppSelector(unitSelector);

  const { unitsAssets } = UseUnitsAssets();
  const unitAssets: UnitAssets = unitsAssets[selectedUnit?.order || 1];

  const [enableEvents, setEnableEvents] = useState(true);
  const [isFlipped, setIsFlipped] = useState(false);
  const [showSoundBtn, setShowSoundBtn] = useState(true);

  const [isRemoving, setIsRemoving] = useState(false);

  const { fromToLang } = useAppSelector(gameSelector);
  const { user } = useAppSelector(authSelector);

  const isOnNativeLanguage = useMemo(() => {
    return user?.metadata.languagePreferences.fromToLanguageParams?.[0] === fromToLang;
  }, [user, fromToLang]);

  useEffect(() => {
    setEnableEvents(() => !index);
  }, [index]);

  useEffect(() => {
    let delayDebounceFn = 0;

    if (isRemoving) {
      delayDebounceFn = setTimeout(() => {
        emitOnSwipe(question.questionId);
      }, 50) as any;
    }

    return () => clearTimeout(delayDebounceFn);
  }, [isRemoving]);

  const onFlip = useCallback(async () => {
    const practiceCard = document.querySelector(
      `.practiceCard-${question.questionId}`
    ) as HTMLDivElement;

    const matches = practiceCard?.style.transform.match(/translate3d\((.*?)\)/);

    if (!enableEvents) {
      return;
    }

    if (matches) {
      const points = matches[1];
      const pointsValues = points.split(', ').map(point => Math.abs(+point.replace('px', '')));

      const isMoved = pointsValues.some(point => point > 10);

      if (isMoved) {
        return;
      }
    }

    setIsFlipped(prevState => !prevState);
    setShowSoundBtn(false);
    await commonUtils.sleep(400);
    setShowSoundBtn(true);
  }, [question]);

  const onSwipe = useCallback((direction: string) => {
    if (!enableEvents) {
      return;
    }
    setIsRemoving(true);
  }, []);

  const onPlaySound = () => {
    if (question?.dictionaryDetails?.audio) {
      new Audio(question?.dictionaryDetails?.audio).play();
    }
  };

  const content = enableEvents ? (
    <>
      {showSoundBtn && (!isMobile || !isFlipped) && question?.dictionaryDetails?.audio && (
        <button className={classNames.soundBtn} onClick={onPlaySound}>
          <SVG src={soundPlay} />
        </button>
      )}
      <div
        className={classNames.front}
        onTouchEndCapture={() => (isMobile ? onFlip() : null)}
        onClick={() => (isDesktop ? onFlip() : null)}
      >
        {question?.question && (
          <span className={question?.question.length > 6 ? 'text-3xl' : ''}>
            {isOnNativeLanguage ? question?.question : question?.answer}
          </span>
        )}
        <span className={classNames.flipButton}></span>
      </div>
      <div
        className={classNames.back}
        onTouchEndCapture={() => (isMobile ? onFlip() : null)}
        onClick={() => (isDesktop ? onFlip() : null)}
      >
        <div className={classNames.top}>
          <span>{question?.question}</span>
          {question.dictionaryDetails?.partOfSpeech && (
            <div className={classNames.partOfSpeech}>
              <span>{question.dictionaryDetails?.partOfSpeech}</span>
            </div>
          )}
        </div>
        <div className={classNames.body}>
          <span
            className={classes(classNames.translation, {
              [classNames.translationRtl]: interfaceLanguage?.direction === 'rtl',
            })}
          >
            {question?.answer}
          </span>
          <div className={classNames.seperator}></div>
          <div className={classNames.exampleSentences}>
            <div key={index} className={classNames.exampleSentence}>
              {question.dictionaryDetails?.exampleSentences}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;

  const cardElement = (
    <div
      className={classes(classNames.card, [classNames[`card-${interfaceLanguage?.direction}`]], {
        [classNames.cardFlip]: isFlipped,
        [classNames[`card-${interfaceLanguage?.direction}`]]: isFlipped,
      })}
      style={{ backgroundColor: unitAssets?.modalBorderColor }}
    >
      {content}
    </div>
  );

  if (isDesktop) {
    return (
      <div
        className={classes(`practiceCard-${question.questionId}`, classNames.practiceCard, {
          'pointer-events-none': !enableEvents,
        })}
      >
        {cardElement}
      </div>
    );
  }

  return (
    <TinderCard
      className={classes(`practiceCard-${question.questionId}`, classNames.practiceCard, {
        'pointer-events-none': !enableEvents,
      })}
      onCardLeftScreen={direction => onSwipe(direction)}
    >
      {cardElement}
    </TinderCard>
  );
};

export default React.memo(PracticeCard);
