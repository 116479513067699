//TODO: Need to understand if it's needed
// import { SkillTypes } from '@/types/skill';
// import { Topic } from '@/types/topic';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { View } from '@/types/common';

import Navbar from '@/components/Navbar/Navbar';
//TODO: Need to understand if it's needed
// import BadgesSection from '@/components/BadgesSection/BadgesSection';
import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';
import QuizzesSection from '@/components/QuizzesSection/QuizzesSection';
import UserInfo from '@/components/UserInfo/UserInfo';
import ViewToggler from '@/components/ViewToggler/ViewToggler';
import UserStreak from '@/components/UserStreak/UserStreak';
import AchievementsSection from '../AchievementsSection/AchievementsSection';
import UseMyProfile from '@/hooks/UseMyProfile';
import { useTranslation } from 'react-i18next';
import UseCurrentRoute from '@/hooks/UseCurrentRoute';
import { ROUTE_NAMES } from '@/router';

interface SidebarProps {
  viewTabs: View[];
  emitSelectView: (view: View) => void;
}

const UserSidebar = ({ viewTabs, emitSelectView }: SidebarProps) => {
  const { user, levels } = useAppSelector(authSelector);
  const { t } = useTranslation();
  // const { bestOfTheWeek } = UseMyProfile();
  const currentRoute = UseCurrentRoute();
  //TODO: Need to understand if it's needed
  // const badges = [
  //   {
  //     skill: SkillTypes.Grammar,
  //     topic: { name: 'Verbs and stuff', nameLocal: 'Verbs and stuff' } as Topic,
  //   },
  //   {
  //     skill: SkillTypes.Listen,
  //     topic: { name: 'Syntax', nameLocal: 'Syntax' } as Topic,
  //   },
  //   {
  //     skill: SkillTypes.Spelling,
  //     topic: { name: 'Vowels', nameLocal: 'Vowels' } as Topic,
  //   },
  // ];

  const levelText = levels.find(level => level.id === user?.progress?.userLevel)?.name ?? '';

  return (
    <>
      <Navbar decreaseOpacity={false} />
      {user && (
        <UserInfo
          fullname={`${user.metadata.firstName} ${user.metadata.lastName}`}
          profileImage={user?.metadata?.userSettings?.avatar?.avatarName}
          backgroundColor={user?.metadata?.userSettings?.avatar?.avatarColor}
          level={levelText}
          grade={user?.metadata?.organizationDetails?.grade}
          // badges={user.achievements?.badges}
          coins={user?.progress?.coins}
        />
      )}
      <ViewToggler className='mt-10' viewTabs={viewTabs} emitSelectView={emitSelectView} />
      {/* {bestOfTheWeek !== null && (
        <AchievementsSection
          firstname={user?.metadata?.firstName ?? t('notAvailable')}
          achievement={bestOfTheWeek}
          // achievement={{
          //   skill: SkillTypes.Grammar,
          //   topic: {
          //     name: 'Verbs and stuff',
          //     nameLocal: 'Verbs and stuff',
          //   } as Topic,
          //   rate: 3,
          // }}
        />
      )} */}
      {
        //TODO: Need to understand if it's needed
        /* <SectionSeperator className="my-10" />
          <BadgesSection badges={badges} /> */
      }
      <SectionSeperator className='my-10' />
      <QuizzesSection />
      {currentRoute !== ROUTE_NAMES.PROFILE && <SectionSeperator className='my-10' />}
      {/* {user?.streak && currentRoute !== ROUTE_NAMES.PROFILE && (
        <UserStreak className='mt-9' streak={user.streak} title={t('userStreak.title') ?? ''} />
      )} */}
    </>
  );
};

export default UserSidebar;
