import { ThemeContext } from '@/context/ThemeContext';
import { useContext } from 'react';

const UseThemeColors = () => {
  const theme = useContext(ThemeContext);

  const color = (key: string) => {
    return theme?.computedStyle?.getPropertyValue(`--${key}`);
  };

  return { color };
};

export default UseThemeColors;
