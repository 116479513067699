import classNames from './ProgressBox.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import UseScreenSize from '@/hooks/UseScreenSize';

interface ProgressBoxProps {
  iconName: string;
  total: number;
  completed: number;
  title: string;
}

const ProgressBox = ({ iconName, total, completed, title }: ProgressBoxProps) => {
  const { isMobileSmall } = UseScreenSize();

  return (
    <div
      className={classes(classNames.progressBox, {
        [classNames.progressBoxMobile]: isMobileSmall,
      })}
    >
      <SVG src={iconName} />
      <div className={classNames.info}>
        <div className={classNames.title}>{title}</div>
        <div className={classNames.progress}>
          <span>{completed}</span>/{total}
        </div>
      </div>
    </div>
  );
};

export default ProgressBox;
