import React, { useEffect, useState } from 'react';
import classes from 'classnames';
import { useAppSelector } from '@/store';
import LevelDropdown from './ProfileProgress/LevelDropdown';
import UserUtils from '@/utils/user';
import { authSelector } from '@/store/reducers/auth';
import { useTranslation } from 'react-i18next';
import { LevelSettingItem } from '@/types/user';
import Dropdown from './Dropdown/Dropdown';
('../assets/styles/components/_welcome-banner.scss');

interface WelcomeBannerProps {
  selectedLevel: number;
  setSelectedLevel: (level: number) => void;
  dropdownOptions: {
    label: any;
    value: any;
  }[];
}

const WelcomeBanner = ({
  selectedLevel,
  setSelectedLevel,
  dropdownOptions,
}: WelcomeBannerProps) => {
  const handleLevelChange = (value: any) => {
    const level = Number(value);
    setSelectedLevel(level);
    if (level !== -1) {
      localStorage.setItem('selectedLevel', value.toString());
    }
  };
  const { t } = useTranslation();
  const [helloLabel, setHelloLabel] = useState('hello');
  const { user } = useAppSelector(authSelector);
  const isTeacher = UserUtils.isTeacher(user);
  const auth = useAppSelector(state => state.auth);
  const size = 'm';

  useEffect(() => {
    const now = new Date();
    const timeTemp = now.getHours() * 100 + now.getMinutes();
    let helloLabel = '';

    if (400 < timeTemp && timeTemp <= 1200) {
      helloLabel = 'goodMorning';
    } else if (1200 < timeTemp && timeTemp <= 1800) {
      helloLabel = 'goodAfternoon';
    } else if (1800 < timeTemp && timeTemp <= 2100) {
      helloLabel = 'goodEvening';
    } else {
      helloLabel = 'goodNight';
    }

    setHelloLabel(helloLabel);
  }, []);

  return (
    <div className={classes('welcome-banner')}>
      <div className='banner-left'>
        <span>
          {t(helloLabel)}, {auth.user?.metadata?.firstName}!
        </span>
      </div>
      {isTeacher && (
        <Dropdown
          label={`${t('level')}:`}
          options={dropdownOptions}
          value={selectedLevel}
          size={size}
          onChange={handleLevelChange}
          wrapperClassName='welcome-banner-level-dropdown-wrapper'
          dropdownClassName='welcome-banner-level-dropdown'
        />
      )}
    </div>
  );
};

export default WelcomeBanner;
