import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UseThemeColors from './UseThemeColors';

import { useAppDispatch, useAppSelector } from '@/store';
import { ThemeContext } from '@/context/ThemeContext';
import { authSelector } from '@/store/reducers/auth';

const UseSettings = () => {
  const theme = useContext(ThemeContext);
  const { color } = UseThemeColors();
  const { t } = useTranslation();
  const auth = useAppSelector(authSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return { color, t, auth, dispatch, navigate, theme };
};

export default UseSettings;
