import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UnitsState, Unit, Topic, Summary } from '@/types/units';
import unitThunks from '../thunks/units';
import unitTopicsThunks from '../thunks/unit-topics';
import { RootState } from '@/types/common';
import summaryThunks from '../thunks/summary';
import commonUtils from '@/utils/common';

const initialState: UnitsState = {
  selectedLevel: 1,
  units: [],
  topics: {},
  status: 'idle',
  error: null,
  selectedUnit: {
    id: 0,
    name: '',
    order: 1,
  },
  selectedTopic: {
    id: 0,
    name: '',
    cEFRDescriptor: '',
    canDoStatement: '',
  },
  summary: {
    level: 0,
    totalActivities: 0,
    totalTopics: 0,
    totalUnits: 0,
    totalStars: 0,
  },
};

const unit = createSlice({
  name: 'unit',
  initialState,
  reducers: {
    setSelectedUnit: (
      state,
      action: PayloadAction<{ id: number; name: string | undefined; order: number | undefined }>
    ) => {
      state.selectedUnit = {
        id: action.payload.id,
        name: action.payload.name,
        order: action.payload.order,
      };
    },
    setSelectedTopic: (
      state,
      action: PayloadAction<{
        id: number;
        name: string | undefined;
        cEFRDescriptor: string | undefined;
        canDoStatement: string | undefined;
      }>
    ) => {
      state.selectedTopic = {
        id: action.payload.id || 0,
        name: action.payload.name,
        cEFRDescriptor: action.payload.cEFRDescriptor,
        canDoStatement: action.payload.canDoStatement,
      };
    },
  },
  extraReducers: {
    [unitThunks.fetchUnits.pending.toString()]: state => {
      state.status = 'loading';
    },
    [unitThunks.fetchUnits.fulfilled.toString()]: (
      state,
      action: PayloadAction<{ level: number; units: Unit[] }>
    ) => {
      const { level, units } = action.payload;
      const sortedUnits = commonUtils.sortByKey([...units], 'order');
      state.status = 'succeeded';
      state.selectedLevel = level;
      state.units = sortedUnits;
      state.selectedUnit = {
        id: units[0]?.id,
        name: units[0]?.name,
        order: units[0]?.order,
      };
    },
    [unitThunks.fetchUnits.rejected.toString()]: (state, action: PayloadAction<string | null>) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [unitTopicsThunks.fetchUnitTopics.fulfilled.toString()]: (
      state,
      action: PayloadAction<{ unitId: number; topics: Topic[] }>
    ) => {
      const { unitId, topics } = action.payload;

      const sortedTopics = commonUtils.sortByKey([...topics], 'order');

      state.topics = {
        ...state.topics,
        [unitId]: sortedTopics,
      };
    },
    [summaryThunks.fetchSummary.pending.toString()]: state => {
      state.status = 'loading';
    },
    [summaryThunks.fetchSummary.fulfilled.toString()]: (state, action: PayloadAction<Summary>) => {
      state.status = 'succeeded';
      state.summary = action.payload;
    },
  },
});

export const unitActions = unit.actions;

export const unitSelector = (state: RootState) => state.units;

export default unit.reducer;
