import UseThemeColors from './UseThemeColors';

import medalGrammar from '@/assets/svg/medal-grammar.svg';
import medalSpeak from '@/assets/svg/medal-speak.svg';
import medalListen from '@/assets/svg/medal-listen.svg';
import medalRead from '@/assets/svg/medal-read.svg';
import medalSpelling from '@/assets/svg/medal-spelling.svg';
import medalVocabulary from '@/assets/svg/medal-vocabulary.svg';
import { UnitsAssets } from '@/types/units';
import { useAppSelector } from '@/store';
import { unitSelector } from '@/store/reducers/unit';
import { GameTypes, HintsType, NewGameTypes } from '@/types/game';

const UseUnitsAssets = () => {
  const { color } = UseThemeColors();
  const { units } = useAppSelector(unitSelector);

  const unitsAssets: UnitsAssets = {
    [units[0]?.order || 1]: {
      containerBackgroundColor: color('brown-light2'),
      modalBorderColor: color('orange-dark2'),
      modalInnerBorderColor: color('orange-dark'),
      medal: medalGrammar,
      backgroundImage: '/images/unitsMap/u1_bg_pc.svg',
      backgroundImageMobile: '/images/unitsMap/u1_bg_mobile.svg',
      pathImage: '/images/unitsMap/u1_path_pc.svg',
      pathImageMobile: '/images/unitsMap/u1_path_mobile.svg',
      pathOnImage: '/images/unitsMap/u1_path_on_pc.svg',
      pathOnImageMobile: '/images/unitsMap/u1_path_on_mobile.svg',
      topicsBackgroundImage: '/images/unitsMap/u1_topics_bg_pc.svg',
      topicsBackgroundImageMobile: '/images/unitsMap/u1_topics_bg_mobile.svg',
    },
    [units[1]?.order || 2]: {
      containerBackgroundColor: color('green-light2'),
      modalBorderColor: color('green-dark3'),
      modalInnerBorderColor: color('green-dark2'),
      medal: medalSpelling,
      backgroundImage: '/images/unitsMap/u2_bg_pc.svg',
      backgroundImageMobile: '/images/unitsMap/u2_bg_mobile.svg',
      pathImage: '/images/unitsMap/u2_path_pc.svg',
      pathImageMobile: '/images/unitsMap/u2_path_mobile.svg',
      pathOnImage: '/images/unitsMap/u2_path_on_pc.svg',
      pathOnImageMobile: '/images/unitsMap/u2_path_on_mobile.svg',
      topicsBackgroundImage: '/images/unitsMap/u2_topics_bg_pc.svg',
      topicsBackgroundImageMobile: '/images/unitsMap/u2_topics_bg_mobile.svg',
    },
    [units[2]?.order || 3]: {
      containerBackgroundColor: color('pink-light2'),
      modalBorderColor: color('pink-regular'),
      modalInnerBorderColor: color('pink-dark3'),
      medal: medalListen,
      backgroundImage: '/images/unitsMap/u3_bg_pc.svg',
      backgroundImageMobile: '/images/unitsMap/u3_bg_mobile.svg',
      pathImage: '/images/unitsMap/u3_path_pc.svg',
      pathImageMobile: '/images/unitsMap/u3_path_mobile.svg',
      pathOnImage: '/images/unitsMap/u3_path_on_pc.svg',
      pathOnImageMobile: '/images/unitsMap/u3_path_on_mobile.svg',
      topicsBackgroundImage: '/images/unitsMap/u3_topics_bg_pc.svg',
      topicsBackgroundImageMobile: '/images/unitsMap/u3_topics_bg_mobile.svg',
    },
    [units[3]?.order || 4]: {
      containerBackgroundColor: color('blue-light6'),
      modalBorderColor: color('blue-dark4'),
      modalInnerBorderColor: color('blue-dark'),
      medal: medalVocabulary,
      backgroundImage: '/images/unitsMap/u4_bg_pc.svg',
      backgroundImageMobile: '/images/unitsMap/u4_bg_mobile.svg',
      pathImage: '/images/unitsMap/u4_path_pc.svg',
      pathImageMobile: '/images/unitsMap/u4_path_mobile.svg',
      pathOnImage: '/images/unitsMap/u4_path_on_pc.svg',
      pathOnImageMobile: '/images/unitsMap/u4_path_on_mobile.svg',
      topicsBackgroundImage: '/images/unitsMap/u4_topics_bg_pc.svg',
      topicsBackgroundImageMobile: '/images/unitsMap/u4_topics_bg_mobile.svg',
    },
    [units[4]?.order || 5]: {
      containerBackgroundColor: color('purple-light2'),
      modalBorderColor: color('purple-regular3'),
      modalInnerBorderColor: color('purple-regular2'),
      medal: medalRead,
      backgroundImage: '/images/unitsMap/u5_bg_pc.svg',
      backgroundImageMobile: '/images/unitsMap/u5_bg_mobile.svg',
      pathImage: '/images/unitsMap/u5_path_pc.svg',
      pathImageMobile: '/images/unitsMap/u5_path_mobile.svg',
      pathOnImage: '/images/unitsMap/u5_path_on_pc.svg',
      pathOnImageMobile: '/images/unitsMap/u5_path_on_mobile.svg',
      topicsBackgroundImage: '/images/unitsMap/u5_topics_bg_pc.svg',
      topicsBackgroundImageMobile: '/images/unitsMap/u5_topics_bg_mobile.svg',
    },
    [units[5]?.order || 6]: {
      containerBackgroundColor: color('blue-light4'),
      modalBorderColor: color('blue-dark6'),
      modalInnerBorderColor: color('blue-dark5'),
      medal: medalSpeak,
      backgroundImage: '/images/unitsMap/u6_bg_pc.svg',
      backgroundImageMobile: '/images/unitsMap/u6_bg_mobile.svg',
      pathImage: '/images/unitsMap/u6_path_pc.svg',
      pathImageMobile: '/images/unitsMap/u6_path_mobile.svg',
      pathOnImage: '/images/unitsMap/u6_path_on_pc.svg',
      pathOnImageMobile: '/images/unitsMap/u6_path_on_mobile.svg',
      topicsBackgroundImage: '/images/unitsMap/u6_topics_bg_pc.svg',
      topicsBackgroundImageMobile: '/images/unitsMap/u6_topics_bg_mobile.svg',
    },
  };

  const hintsBygameType = (
    gameType: GameTypes | NewGameTypes | null,
    subGameType: GameTypes | NewGameTypes | null
  ): HintsType[] => {
    switch (gameType) {
      case GameTypes.ClosedSpelling:
        return [
          HintsType.SHOW_LETTER,
          HintsType.SEE_TRANSLATION,
          HintsType.REMOVE_LETTER,
          HintsType.HEAR_ENGLISH_WORD,
        ];

      case GameTypes.Pronunciation:
        return [HintsType.ADD_TIME];

      case NewGameTypes.OpenWriting:
        return [HintsType.ADD_TIME];

      case NewGameTypes.Listen:
        return [HintsType.ADD_TIME, HintsType.REMOVE_ONE_ANSWER];

      case NewGameTypes.AudioToImage:
        return [HintsType.REMOVE_ONE_ANSWER, HintsType.ADD_TIME, HintsType.SEE_ENGLISH_WORD];

      case NewGameTypes.NaturalConversation:
        if (subGameType === NewGameTypes.Listen) {
          return [HintsType.REMOVE_ONE_ANSWER, HintsType.ADD_TIME, HintsType.SEE_ENGLISH_WORD];
        } else {
          return [HintsType.REMOVE_ONE_ANSWER, HintsType.ADD_TIME];
        }

      case NewGameTypes.Memory:
        return [HintsType.REVEAL_A_PAIR, HintsType.ADD_TIME];

      default:
        return [HintsType.REMOVE_ONE_ANSWER, HintsType.ADD_TIME];
    }
  };

  return { unitsAssets, hintsBygameType };
};

export default UseUnitsAssets;
