import { createAsyncThunk } from '@reduxjs/toolkit';
import { unitService } from '@/services/unit';
import { RootState } from '@/types/common';

const summaryThunks = {
  fetchSummary: createAsyncThunk(
    'units/fetchSummary',
    async ({ level }: { level: number }, { getState }) => {
      try {
        const state = getState() as RootState;
        const user = state.auth.user;
        const response = await unitService.fetchSummary(user, level);
        return response.data.data;
      } catch (error: any) {
        console.log('error', error);
      }
    }
  ),
};

export default summaryThunks;
