import classNames from './DictionaryDesktopComponents.module.scss';
import SVG from 'react-inlinesvg';

import { useTranslation } from 'react-i18next';
import DictionaryCards from '../DictionaryCards/DictionaryCards';
import {
  DictioanryQuiz,
  DictionaryOptionsType,
  DictionaryQuestionType,
  PictureOfTheDayType,
} from '@/types/common';
import { DictionaryPhrase, DictionaryWord } from '@/types/dictionary';

import DictionaryPhrases from '../DictionaryPhrases/DictionaryPhrases';
import DictionaryQuestion from '../DictionaryQuestion/DictionaryQuestion';
import PictureOfTheDay from '../PictureOfTheDay/PictureOfTheDay';
import DictionaryOptions from '../DictionaryOptions/DictionaryOptions';
import DetailSeperator from '@/components/DetailSeperator/DetailSeperator';
import DictionaryVocabularyPractice from '../DictionaryVocabularyPractice/DictionaryVocabularyPractice';
import GoBackBtn from '@/components/GoBackBtn/GoBackBtn';

interface DictionaryDesktopComponentsProps {
  selectedWord: string;
  cards: DictionaryWord[];
  phrases: DictionaryPhrase[];
  question: DictionaryQuestionType;
  pictureOfTheDay: PictureOfTheDayType;
  options: DictionaryOptionsType;
  quizzes?: DictioanryQuiz[];
  emitBack: () => void;
  onValueSearch: (value: string) => void;
}

const DictionaryDesktopComponents = ({
  selectedWord,
  cards,
  phrases,
  question,
  pictureOfTheDay,
  options,
  quizzes,
  emitBack,
  onValueSearch,
}: DictionaryDesktopComponentsProps) => {
  const { t } = useTranslation();
  const partsOfSpeech = [...new Set(cards.map(card => card.partOfSpeech))];

  const onScrollToPartOfSpeech = (partOfSpeech: string) => {
    const selector = `div[id^="dictionary-card-${partOfSpeech}-"]`;
    const element = document.querySelector(selector) as HTMLDivElement;

    if (element) {
      const offsetTop = element.offsetTop - 32;
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
  };

  const partsOfSpeechElements = partsOfSpeech.map((partOfSpeech, index) => {
    const isLast = index === partsOfSpeech.length - 1;

    return (
      <div key={index} className={classNames.partOfSpeech}>
        <button onClick={() => onScrollToPartOfSpeech(partOfSpeech)}>{partOfSpeech}</button>
        {!isLast && <DetailSeperator className={classNames.seperator} />}
      </div>
    );
  });
  const isOneResult = cards.length === 1;
  return (
    <div className={classNames.dictionaryDesktopComponents}>
      <GoBackBtn className={classNames.goBackBtn} emitClick={emitBack} />
      <div className={classNames.top}>
        <div className={classNames.text}>
          <h2>{t('results')}</h2>
          <div>
            <span>{`${isOneResult ? t('thereIs') : t('thereAre')} ${cards.length} ${
              isOneResult ? t('resultForTheTranslation') : t('resultsForTheTranslation')
            } `}</span>
            <span className={classNames.selectedWord}>{selectedWord}</span>
          </div>
        </div>
        <div className={classNames.partsOfSpeech}>{partsOfSpeechElements}</div>
      </div>
      <div className={classNames.content}>
        <div className={classNames.partOne}>
          <DictionaryCards
            selectedWord={selectedWord}
            cards={cards}
            onValueSearch={onValueSearch}
          />
        </div>
        {phrases?.length > 0 && (
          <div className={classNames.partTwo}>
            <DictionaryPhrases
              onValueSearch={onValueSearch}
              selectedWord={selectedWord}
              phrases={phrases}
            />
            {/* <DictionaryVocabularyPractice quizzes={quizzes} />
          <DictionaryQuestion {...question} />
          <PictureOfTheDay {...pictureOfTheDay} />
        <DictionaryOptions options={options} /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default DictionaryDesktopComponents;
