import classNames from './StudentCard.module.scss';
import { useTranslation } from 'react-i18next';
import UseLevel from '@/hooks/UseLevel';
import SVG from 'react-inlinesvg';

import lavelIcon from '@/assets/svg/levelsBadges/lavel.svg';

interface StudentCardMobileProps {
  firstname: string;
  lastname: string;
  level?: number;
  userName?: string;
  activitiesProgress?: number;
  score?: number;
  emitShowDataModal: (studentId: string) => void;
}

const StudentCardMobile = ({
  level,
  firstname,
  lastname,
  userName,
  activitiesProgress,
  score,
  emitShowDataModal,
}: StudentCardMobileProps) => {
  const { t } = useTranslation();

  const { getLevelText } = UseLevel();
  const levelText = getLevelText(level);
  const isTestLevel = !level;
  const progressValue = isTestLevel ? 0 : activitiesProgress;

  return (
    <div className={classNames.studentCardMobile} onClick={() => emitShowDataModal(userName ?? '')}>
      <div>
        <div className={classNames.level}>
          <SVG src={lavelIcon} />
          <span>{level || 0}</span>
        </div>
      </div>
      <div className={classNames.name}>
        {firstname} {lastname}
      </div>
      <div className={classNames.progressText}>{`${progressValue}%`}</div>
      <div className={classNames.progress}>
        <div className={classNames.barWrapper}>
          <div className={classNames.bar} style={{ width: `${progressValue}%` }}></div>
        </div>
      </div>
    </div>
  );
};

export default StudentCardMobile;
