import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { nativeLanguageSelector } from '@/store/reducers/auth';
import { NewGameTypes } from '@/types/game';
import classes from 'classnames';

import MultiChoiceOptionStatus from './MultiChoiceOptionStatus';

import { MultiChoiceOptionContentProps } from './types';

import classNames from '../MultiChoiceOptions.module.scss';

const MultiChoiceOptionContent = (props: MultiChoiceOptionContentProps) => {
  const {
    isOptionSelected,
    isOptionCorrect,
    isDictionary,
    alphabet,
    option,
    index,
    imageCredit,
    imageAlt,
  } = props;

  const { gameType, gameQuestions } = useAppSelector(gameSelector);
  //FIXME: NEED TO REPLACE WITH CORRECT GAMETYPE
  const TEMP_GAMETYPE = (gameQuestions[0]?.gameType as any) || 8;
  const nativeLanguage = useAppSelector(nativeLanguageSelector);

  if (TEMP_GAMETYPE === NewGameTypes.AudioToImage || TEMP_GAMETYPE === NewGameTypes.TextToImage) {
    return (
      <div className={classNames.details}>
        <div className={classNames.answerStatusBlock}>
          <MultiChoiceOptionStatus
            isOptionSelected={isOptionSelected}
            isOptionCorrect={isOptionCorrect}
          />
        </div>
        <div key={`${option}-${index}`} className={classNames.closedSpellingImage}>
          <img src={option} alt={imageAlt} />
          {imageCredit && (
            <span className={classNames.copyright}>
              <span>{imageCredit}</span>
            </span>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={classNames.details}>
      <MultiChoiceOptionStatus
        isOptionSelected={isOptionSelected}
        isOptionCorrect={isOptionCorrect}
        isDictionary={isDictionary}
        alphabet={alphabet}
      />
      <span className={classes(classNames.text, [classNames[`text-${nativeLanguage?.direction}`]])}>
        {option}
      </span>
    </div>
  );
};

export default MultiChoiceOptionContent;
