import { RootState } from '@/types/common';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TeacherState } from '@/types/teacher-dashboard';
import { GroupStudentProgress } from '@/types/progress';
import { UserState } from '@/types/user';

const initialState: TeacherState = {
  students: [],
};

const teacher = createSlice({
  name: 'teacher',
  initialState,
  reducers: {
    setStudents: (state, { payload }: PayloadAction<GroupStudentProgress[]>) => {
      state.students = payload;
    },
    editStudentsGrade: (state, { payload }: PayloadAction<string>) => {
      // state.students = state.students.map(student => {
      //   student.metadata.organizationDetails.grade = payload;
      //
      //   return student;
      // });
    },
    updateStudent: (
      state,
      { payload }: PayloadAction<{ userId: string; newStudent: UserState }>
    ) => {
      // state.students = state.students.map(student => {
      //   if (student.progress.userId === payload.userId) {
      //     return {
      //       ...payload.newStudent,
      //     };
      //   } else {
      //     return student;
      //   }
      // });
    },
  },
});

export const teacherActions = teacher.actions;

export const teacherSelector = (state: RootState) => state.teacher;

export default teacher.reducer;
