import WordKnowledgeControls from '@/components/QuizPage/WordKnowledgeControls/WordKnowledgeControls';
import classNames from './WordsListItem.module.scss';
import { Question } from '@/types/question';
import SVG from 'react-inlinesvg';
import React, { useEffect, useRef, useState } from 'react';

import soundPlay from '@/assets/svg/speaker.svg';
import UseScreenSize from '@/hooks/UseScreenSize';
import GameUtils from '@/utils/gameUtils';

interface WordsListItemProps {
  question: Question | null;
  emitOnAknowledge?: (id: number, isKnown: boolean) => void;
}

const WordsListItem = ({ question, emitOnAknowledge }: WordsListItemProps) => {
  const { isMobile, isDesktop } = UseScreenSize();

  const onPlaySound = () => {
    if (question?.dictionaryDetails?.audio) {
      new Audio(question?.dictionaryDetails?.audio).play();
    }
  };

  const soundBtn = question?.dictionaryDetails?.audio && (
    <button className={classNames.soundBtn} onClick={onPlaySound}>
      <SVG src={soundPlay} />
    </button>
  );

  const partOfSpeach = (
    <span className={classNames.partOfSpeech}>{question?.dictionaryDetails?.partOfSpeech}</span>
  );

  return (
    <div className={classNames.wordsListItem}>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-2'>
          {isMobile && soundBtn}
          <span className={classNames.entry}>{question?.question}</span>
          {isDesktop && partOfSpeach}
        </div>
        <div className='flex items-center gap-2'>
          <WordKnowledgeControls
            className='flex-row-reverse'
            isSmall={true}
            question={question}
            emitOnAknowledge={emitOnAknowledge}
          />
          {isDesktop && soundBtn}
        </div>
      </div>
      {isMobile && partOfSpeach}
      <span className={classNames.translation}>{question?.answer}</span>
    </div>
  );
};

export default React.memo(WordsListItem);
