import React, { useCallback } from 'react';
import classNames from './DeleteClass.module.scss';
import classes from 'classnames';
import { Transition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import TheButton from '@/components/TheButton/TheButton';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import AppModal from '@/components/AppModal';
import SVG from 'react-inlinesvg';
import closeIcon from '@/assets/svg/close.svg';
import errorIcon from '@/assets/svg/error.svg';
import oopsIcon from '@/assets/svg/oops.svg';

interface DeleteClassProps {
  show: boolean;
  onClose: () => void;
  onDelete: () => void;
  isClassDeleting: boolean;
  showClassDeleteError: boolean;
  studentsAmount: number;
  setShowClassDeleteError: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteClass = ({
  show,
  onClose,
  onDelete,
  isClassDeleting,
  showClassDeleteError,
  studentsAmount,
  setShowClassDeleteError,
}: DeleteClassProps) => {
  const { t } = useTranslation();

  const onDeleteHandlebar = useCallback(() => {
    onDelete();
  }, []);

  const onTryAgainHandlebar = useCallback(() => {
    setShowClassDeleteError(false);
  }, [setShowClassDeleteError]);

  const modal =
    studentsAmount > 0 ? (
      <div className={classes(classNames.modal, classNames.error)}>
        <SVG src={errorIcon} className={classNames.errorIcon} />
        <h2>{t('settings.delete_class.classNotEmpty')}</h2>
        <div className={classNames.description}>
          {t('settings.delete_class.classNotEmptyDescription')}
        </div>
        <button className={classNames.closeErrorBtn} onClick={onClose}>
          <SVG src={closeIcon} />
        </button>
      </div>
    ) : showClassDeleteError ? (
      <div className={classes(classNames.modal, classNames.deletingFailed)}>
        <SVG src={oopsIcon} className={classNames.errorIcon} />
        <div className={classNames.oops}>{t('oops')}!</div>
        <h2>{t('settings.delete_class.deletingFailed')}</h2>
        <div
          className={classNames.description}
          dangerouslySetInnerHTML={{
            __html: t('settings.delete_class.deletingFailedDescription', {
              email: 'info@eb.com',
            }).toString(),
          }}
        />
        <div className={classNames.buttons}>
          <TheButton
            className={classNames.tryAgainBtn}
            text={t('settings.delete_student.tryAgain')}
            shouldRecolor={false}
            emitOnClick={onTryAgainHandlebar}
          />
        </div>
        <button className={classNames.closeErrorBtn} onClick={onClose}>
          <SVG src={closeIcon} />
        </button>
      </div>
    ) : (
      <div className={classNames.modal}>
        <h2>{t('settings.delete_class.areYouSure')}</h2>
        <div className={classNames.description}>{t('settings.delete_class.description')}</div>
        <div className={classNames.buttons}>
          <TheButton
            className={classNames.deleteBtn}
            text={t('settings.delete_class.delete')}
            shouldRecolor={false}
            emitOnClick={onDeleteHandlebar}
          />
          <TheButton
            className={classNames.cancelBtn}
            text={t('settings.delete_class.cancel')}
            plain
            emitOnClick={onClose}
          />
        </div>
        {isClassDeleting && (
          <div className={classNames.loader}>
            <AppLoaderCircle />
          </div>
        )}
      </div>
    );

  return (
    <Transition
      in={show}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => {
        return (
          <>
            <AppModal>
              <div className={classes(classNames.deleteClass, `fade-${state}`)}>{modal}</div>
            </AppModal>
          </>
        );
      }}
    />
  );
};

export default DeleteClass;
