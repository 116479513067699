import Navbar from '@/components/Navbar/Navbar';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import UserUtils from '@/utils/user';
import { View } from '@/types/common';

import ViewToggler from '@/components/ViewToggler/ViewToggler';
import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';
//TODO: Need to understand if it's needed
// import SkillsGrid from '@/components/SkillsGrid/SkillsGrid';
import UserInfo from '@/components/UserInfo/UserInfo';
import GettingStartedInstructions from '@/components/GettingStartedInstructions/GettingStartedInstructions';
import PremiumSection from '@/components/PremiumSection/PremiumSection';
import { useTranslation } from 'react-i18next';

interface TeacherSidebarProps {
  viewTabs: View[];
  emitSelectView: (view: View) => void;
}

const TeacherSidebar = ({ viewTabs, emitSelectView }: TeacherSidebarProps) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(authSelector);

  const fullname = `${user?.metadata?.firstName || t('notAvailable')} ${user?.metadata.lastName || t('notAvailable')}`;

  return (
    <>
      <Navbar decreaseOpacity={false} />
      {user && (
        <UserInfo
          isTeacher={UserUtils.isTeacher(user)}
          fullname={fullname}
          profileImage={user?.metadata.userSettings.avatar?.avatarName}
          backgroundColor={user?.metadata.userSettings.avatar?.avatarColor}
          classesAmount={user?.metadata?.organizationDetails?.teacher?.teacherGroups?.length || 0}
          schoolName={user.metadata.organizationDetails.organizationName}
          studentsAmount={user?.metadata?.organizationDetails?.teacher?.totalStudents || 0}
        />
      )}
      <ViewToggler className='mt-8' viewTabs={viewTabs} emitSelectView={emitSelectView} />
      {
        //TODO: Need to understand if it's needed
        /* <SectionSeperator className="my-6" />
      <SkillsGrid
        progress={userProgress}
        loading={loadingProgress}
        shouldHighlight={false}
        shouldExpand={false}
      /> */
      }
      <SectionSeperator className='my-6' />
      <GettingStartedInstructions />
      <PremiumSection className='mt-8' />
    </>
  );
};

export default TeacherSidebar;
