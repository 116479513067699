import classNames from './DictionaryMobile.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';

import bookIcon from '@/assets/svg/book.svg';
import dictionaryBtn from '@/assets/svg/dictionary-btn.svg';
import { Transition } from 'react-transition-group';
import AppBackdrop from '@/components/AppBackdrop';
import AppModal from '@/components/AppModal';
import DictionaryModal from '../DictionaryModal/DictionaryModal';
import UseCurrentRoute from '@/hooks/UseCurrentRoute';
import { ROUTE_NAMES } from '@/router';

type DictionaryMobileProps = {
  isFixed?: boolean;
  shouldOpen: boolean;
  emitOpen: () => void;
  emitClose: () => void;
};

const DictionaryMobile = ({
  isFixed = true,
  shouldOpen,
  emitOpen,
  emitClose,
}: DictionaryMobileProps) => {
  const { t } = useTranslation();

  const currentRoute = UseCurrentRoute();
  const isMyStudentsPage = currentRoute === ROUTE_NAMES.MY_STUDENTS;

  return (
    <div className={classNames.dictionaryMobile}>
      <div
        id='dictionary-btn'
        className={classes(classNames.dictionaryToggler, {
          [classNames.fixed]: isFixed,
        })}
      >
        <div className={classNames.title}>
          <SVG className={classNames.bookIcon} src={bookIcon} />
          <span>{t('dictionary')}</span>
        </div>
        <button onClick={emitOpen}>
          <SVG src={dictionaryBtn} />
        </button>
      </div>
      <Transition
        in={shouldOpen}
        timeout={400}
        mountOnEnter
        unmountOnExit
        children={state => {
          return (
            <>
              <AppBackdrop
                className={classes(classNames.backdrop, `backdrop-${state}`)}
                emitClose={emitClose}
              />
              <AppModal>
                <DictionaryModal
                  className={classes(classNames.modal, `slide-vertical-${state}`, {
                    [classNames.modalMyStudents]: isMyStudentsPage,
                  })}
                  emitClose={emitClose}
                />
              </AppModal>
            </>
          );
        }}
      />
    </div>
  );
};

export default DictionaryMobile;
