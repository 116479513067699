import { useEffect, useRef, useState } from 'react';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';

import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';
import { NewGameTypes, PopUpSummaryProps } from '@/types/game';
import { SkillTypes } from '@/types/skill';
import GameUtils from '@/utils/gameUtils';
import UserUtils from '@/utils/user';
import commonUtils from '@/utils/common';

import UseScreenSize from '@/hooks/UseScreenSize';
import UseLocalLang from '@/hooks/UseLocalLang';

import TheButton from '@/components/TheButton/TheButton';
import { getResultContent, getResultAfterFirstTry } from './helper';

import complete from '@/assets/svg/quiz-complete.svg';
import failed from '@/assets/svg/quiz-failed.svg';
import coinsRow from '@/assets/svg/coins-row.svg';
import coinsRowFailed from '@/assets/svg/coins-row-failed.svg';
import classNames from './QuizSummary.module.scss';
import QuizTip from '../../QuizTip/QuizTip';

const QuizSummary = ({
  selectedQuiz,
  selectedTopic,
  coins,
  tip,
  userData,
  correctAnswers,
  totalQuestions,
  hasNextQuiz,
  totalScore,
  isOpenWriting,
  noGrading,
  emitOnClosePopUp,
  emitOnEndOfSkillTest,
  emitOnExit,
  emitOnExitWithCanDo,
}: PopUpSummaryProps) => {
  const { t } = useTranslation();
  const { selectedType } = useAppSelector(gameSelector);
  const { user } = useAppSelector(authSelector);

  const { isDesktop, isMobile } = UseScreenSize();
  const [offsetFromBottom, setOffsetFromBottom] = useState(0);
  const summaryRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const { gameType } = useAppSelector(gameSelector);
  const isLastActivity = GameUtils.isLastActivity(
    selectedQuiz?.order,
    selectedTopic?.activitiesInTopic
  );

  const isStudent = UserUtils.isStudent(user);

  const localLang = UseLocalLang();

  const score = totalScore ?? +commonUtils.calcPercentage(correctAnswers ?? 0, totalQuestions ?? 0);
  const isSpeakActivity = gameType === NewGameTypes.Speak;
  //TODO: consolidate stars and score for different game types in one method
  const starsAmount = isSpeakActivity ? 3 : GameUtils.getStarsAmount(score);

  const isFailed = starsAmount === 0;
  const isQuizPassedBefore = userData?.isCompleted ?? false;

  const hasFailed = isFailed;

  const bottomHeight = bottomRef.current?.clientHeight;

  useEffect(() => {
    const offsetTop = summaryRef.current?.getBoundingClientRect().top;

    if (bottomHeight && offsetTop && isMobile) {
      setOffsetFromBottom(bottomHeight + offsetTop);
    }
  }, [bottomHeight, isMobile]);

  let descriptionText = t('unfortunately');

  const textData = isQuizPassedBefore
    ? getResultAfterFirstTry(
        correctAnswers,
        userData?.cumulativeCorrectAnswers,
        totalQuestions,
        totalScore
      )
    : getResultContent(totalQuestions, correctAnswers, totalScore);

  let titleText = textData.title;
  descriptionText = textData.content;

  if (isSpeakActivity) {
    titleText = t('wayToGo');
    descriptionText = t('youCompletedThisActivity');
  }

  const coinsText = !coins ? t('noExtraCoins') : `+${coins} ${t('extraCoins')}`;

  const setSummaryHeight = () => {
    if (selectedType !== SkillTypes.Spelling && tip) {
      return `calc(100% - ${offsetFromBottom}px)`;
    }
    return '60vh';
  };

  return (
    <div className={classNames.quizSummary}>
      <div className={classNames.images}>
        <img
          className={classNames.stars}
          src={commonUtils.getStarImage(starsAmount)?.img ?? ''}
          alt='stars'
        />
        <div
          className={classNames.result}
          style={{ backgroundImage: `url(${hasFailed ? failed : complete})` }}
        >
          <div className={classNames.text}>
            <p>{selectedQuiz ? commonUtils.getName(selectedQuiz, localLang) : t('notAvailable')}</p>
            <h3>{hasFailed ? `${t('oops')}` : t('complete')}</h3>
          </div>
        </div>
      </div>
      <div className={classNames.content}>
        <div
          ref={summaryRef}
          className={classes(classNames.summary)}
          style={{ height: setSummaryHeight() }}
        >
          {isOpenWriting && noGrading ? (
            <>
              <h2 className={classNames.submissionTitle}>{t('thanksForSubmission')}</h2>
              {isMobile && (
                <TheButton
                  className={classNames.button}
                  text={hasNextQuiz && !hasFailed ? t('nextQuiz') : t('tryAgain')}
                  showArrow={!hasFailed}
                  emitOnClick={() => emitOnClosePopUp?.(hasFailed)}
                />
              )}
            </>
          ) : (
            <>
              <h3 className={classNames.title}>{titleText}</h3>
              <div
                className={classNames.description}
                dangerouslySetInnerHTML={{ __html: descriptionText }}
              ></div>
              {isStudent && !isSpeakActivity && (
                <div className={classNames.reward}>
                  {isDesktop && !!coins && (
                    <span className={classNames.rewardText}>{t('reward')}</span>
                  )}
                  <SVG src={coins ? coinsRow : coinsRowFailed} />
                  <span className={classNames.extraCoins}>{coinsText}</span>
                </div>
              )}
              {tip && (
                <div className={classNames.quizTip}>
                  <QuizTip
                    tip={tip ?? ''}
                    className={classNames.tip}
                    lampClassName={classes(
                      classNames.lamp,
                      classNames[`lamp-${interfaceLanguage?.direction}`]
                    )}
                    shouldExpand={true}
                  />
                </div>
              )}
              {isMobile && (
                <TheButton
                  className={classNames.button}
                  text={hasNextQuiz && !hasFailed ? t('nextQuiz') : t('tryAgain')}
                  showArrow={!hasFailed}
                  emitOnClick={() => emitOnClosePopUp?.(hasFailed)}
                />
              )}
            </>
          )}
        </div>
        {isDesktop && (
          <div className={classNames.buttonsWrapper}>
            <div className={classNames.buttons}>
              {!isLastActivity && (
                <TheButton
                  className='flex-1'
                  plain
                  text={t('backHome')}
                  emitOnClick={hasFailed ? emitOnExit : emitOnExitWithCanDo}
                />
              )}
              {(hasNextQuiz || hasFailed) && (
                <TheButton
                  className={classNames.buttonTwo}
                  text={hasFailed ? t('tryAgain') : t('nextQuiz')}
                  showArrow={!hasFailed}
                  emitOnClick={() => emitOnClosePopUp?.(hasFailed)}
                />
              )}
              {!hasNextQuiz && !hasFailed && (
                <TheButton
                  className={classNames.buttonTwo}
                  text={isLastActivity ? t('rateYourself') : t('next')}
                  showArrow={isLastActivity ? false : true}
                  emitOnClick={emitOnExitWithCanDo}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizSummary;
