import classNames from './ProfileCard.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import commonUtils from '@/utils/common';
import UseLevel from '@/hooks/UseLevel';
import { Streak } from '@/types/user';

import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';
import UserStreak from '@/components/UserStreak/UserStreak';

import diamond from '@/assets/svg/diamond-large.svg';
import classroomImg from '@/assets/svg/classroom.svg';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';

import { COUNTRIES } from '@/utils/countries';
import { CircleFlag } from 'react-circle-flags';
import { useMemo } from 'react';

interface ProfileCardProps {
  className?: string;
  classroom?: string;
  level?: number;
  grade?: string;
  school?: string | null;
  country?: string | null;
  streak?: Streak[] | null;
}

const ProfileCard = ({
  className,
  classroom,
  level,
  grade,
  school,
  country,
  streak,
}: ProfileCardProps) => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = UseScreenSize();
  const { getLevelText } = UseLevel();
  const levelText = getLevelText(level);

  const userGrade = (grade ?? '').toLowerCase();
  const isUserGradeNumber = !!Number(userGrade);
  const gradeText = isUserGradeNumber
    ? t(`grade_${commonUtils.fixGradeSymbol(userGrade)}`)
    : userGrade
      ? t(userGrade)
      : '';

  const countryFlagImg = useMemo(() => {
    const countryObj = COUNTRIES.find(
      _country => _country.name.toLowerCase() === (country || '').toLowerCase()
    );
    return countryObj?.code ? (
      <CircleFlag countryCode={countryObj?.code.toLocaleLowerCase()} />
    ) : null;
  }, [country]);

  const infoLineClass = (
    <div className={classNames.line}>
      <span className={classNames.label}>{t('class')}</span>
      <span className={classNames.value}>{t(classroom ?? '')}</span>
    </div>
  );

  const infoLineLevel = (
    <div className={classNames.line}>
      <span className={classNames.label}>{t('level')}</span>
      <span className={classNames.value}>{t(levelText ?? '')}</span>
    </div>
  );

  const infoLineGrade = (
    <div className={classNames.line}>
      <span className={classNames.label}>{t('grade')}</span>
      <span className={classNames.value}>{gradeText}</span>
    </div>
  );

  const infoLineSchoolName = (
    <div className={classNames.line}>
      <span className={classNames.label}>{t('schoolName')}</span>
      <span className={classNames.value}>{school ?? t('notAvailable')}</span>
    </div>
  );

  const infoLineCountry = (
    <div className={classNames.line}>
      <span className={classNames.label}>{t('country')}</span>
      <span className={classNames.value}>
        <span className={classNames.country}>
          {country ?? t('notAvailable')}
          {countryFlagImg}
        </span>
      </span>
    </div>
  );

  const infoList = isMobile ? (
    <>
      {infoLineClass}
      {infoLineGrade}
      {infoLineLevel}
      {infoLineSchoolName}
      {infoLineCountry}
    </>
  ) : (
    <>
      {infoLineLevel}
      {infoLineGrade}
      {infoLineSchoolName}
      {infoLineCountry}
    </>
  );

  return (
    <div
      className={classes(classNames.profileCard, className, {
        [classNames.profileCardMobile]: isMobile,
      })}
    >
      {isDesktop && (
        <div className={classNames.top}>
          <SVG src={classroomImg} />
          <h2>{classroom}</h2>
        </div>
      )}
      <div className={classNames.info}>{infoList}</div>
      {isDesktop && <SectionSeperator className='my-6' />}
      {streak && (
        <div className={classNames.streakContainer}>
          <span className={classNames.title}>{t('yourStreak')}</span>
          <UserStreak showTitle={false} streak={streak} className={classNames.userStreak} />
        </div>
      )}
    </div>
  );
};

export default ProfileCard;
