import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from './ClosedSpelling.module.scss';
import { Block, ClosedSpellingProps, GameStatus } from '@/types/game';
import commonUtils from '@/utils/common';
import { useDispatch } from 'react-redux';
import UseScreenSize from '@/hooks/UseScreenSize';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { studyingLanguageSelector } from '@/store/reducers/auth';
import { useAppSelector } from '@/store';
import UseLocalLang from '@/hooks/UseLocalLang';

import ClosedSpellingImage from './ClosedSpellingImage/ClosedSpellingImage';
import ClosedSpellingOptions from './ClosedSpellingOptions/ClosedSpellingOptions';
import ClosedSpellingDictionary from './ClosedSpellingDictionary/ClosedSpellingDictionary';

type setQuestionTempDataFunc<T> = (prevData: T) => T;
type setQuestionTempDataType<T> = T | setQuestionTempDataFunc<T>;

const ClosedSpelling = ({
  currentQuestion,
  options,
  correctAnswer,
  pickedAnswer,
  allowRetry,
  emitOnRetry,
  emitOnAnswer,
  emitOnNextQuestion,
  currentQuestionTempData,
  setCurrentQuestionTempData,
}: ClosedSpellingProps) => {
  const localLang = UseLocalLang();
  const dispatch = useDispatch();
  const { isDesktop } = UseScreenSize();
  const {
    currentQuestionIndex,
    gameQuestions,
    selectedQuiz,
    showLetter,
    removeLetter,
    revealTranslation,
    gameStatus,
  } = useAppSelector(gameSelector);
  const studyingLanguage = useAppSelector(studyingLanguageSelector);
  const { isInEndOfSkillTest, isFinalAssessment } = useAppSelector(gameSelector);

  const {
    answerBlocks,
    refreshAnswerBlocks,
    optionBlocks,
    isFilled,
    showBackdrop,
    showFeedback,
    hideTranslation,
  }: {
    answerBlocks: Block[];
    refreshAnswerBlocks: Block[];
    optionBlocks: Block[];
    isFilled: boolean;
    showBackdrop: boolean;
    showFeedback: boolean;
    hideTranslation: boolean;
  } = useMemo(() => {
    return {
      answerBlocks: currentQuestionTempData?.answerBlocks || [],
      refreshAnswerBlocks: currentQuestionTempData?.refreshAnswerBlocks || [],
      optionBlocks: currentQuestionTempData?.optionBlocks || [],
      isFilled: currentQuestionTempData?.isFilled || false,
      showBackdrop: currentQuestionTempData?.showBackdrop || false,
      showFeedback: currentQuestionTempData?.showFeedback || false,
      hideTranslation: currentQuestionTempData?.hideTranslation || false,
    };
  }, [currentQuestionTempData]);

  const setQuestionTempData = useCallback(
    (prevData: any, newData: any, key: string) => {
      const data = typeof newData === 'function' ? newData(prevData) : newData;
      setCurrentQuestionTempData(data, key);
    },
    [setCurrentQuestionTempData]
  );

  const setAnswerBlocks = useCallback(
    (newData: setQuestionTempDataType<Block[]>) => {
      setQuestionTempData(answerBlocks, newData, 'answerBlocks');
    },
    [answerBlocks, setQuestionTempData]
  );

  const setOptionBlocks = useCallback(
    (newData: setQuestionTempDataType<Block[]>) => {
      setQuestionTempData(optionBlocks, newData, 'optionBlocks');
    },
    [optionBlocks, setQuestionTempData]
  );

  const setIsFilled = useCallback(
    (newData: setQuestionTempDataType<boolean>) => {
      setQuestionTempData(isFilled, newData, 'isFilled');
    },
    [isFilled, setQuestionTempData]
  );

  const setShowBackdrop = useCallback(
    (newData: setQuestionTempDataType<boolean>) => {
      setQuestionTempData(showBackdrop, newData, 'showBackdrop');
    },
    [showBackdrop, setQuestionTempData]
  );

  const setShowFeedback = useCallback(
    (newData: setQuestionTempDataType<boolean>) => {
      setQuestionTempData(showFeedback, newData, 'showFeedback');
    },
    [showFeedback, setQuestionTempData]
  );

  const setHideTranslation = useCallback(
    (newData: setQuestionTempDataType<boolean>) => {
      setQuestionTempData(hideTranslation, newData, 'hideTranslation');
    },
    [hideTranslation, setQuestionTempData]
  );

  // const splittedAnswer = correctAnswer?.split(',') ?? [];
  const splittedAnswer = correctAnswer ?? ([] as any);

  const correctWord = useMemo(() => splittedAnswer.join('') ?? '', [correctAnswer]);

  const isCorrect = useMemo(
    () => pickedAnswer === currentQuestion.answer.join(','),
    [pickedAnswer, currentQuestion]
  );

  useEffect(() => {
    if (gameStatus === GameStatus.TIME_ENDED) {
      const cleanBlocks = optionBlocks.reduce((cleanBlocks: boolean, block: Block) => {
        return cleanBlocks || block.isDisabled;
      }, false);

      if (cleanBlocks) {
        setOptionBlocks(prevState =>
          prevState.map(block => {
            return {
              ...block,
              isDisabled: false,
            };
          })
        );
      }
    }
  }, [gameStatus, optionBlocks, setOptionBlocks]);

  //Ignore "allowRetry useEffect" the first time a component is created, to avoid an error when switching between mobile and desktop views.
  const allowRetryIgnoreRef = useRef<boolean>(true);
  useEffect(() => {
    if (!allowRetry && !allowRetryIgnoreRef.current) {
      setAnswerBlocks(structuredClone(refreshAnswerBlocks));
    }
    allowRetryIgnoreRef.current = false;
  }, [allowRetry]);

  useEffect(() => {
    setHideTranslation(false);
  }, [currentQuestion]);

  useEffect(() => {
    if (revealTranslation) {
      setShowBackdrop(true);
    }
  }, [revealTranslation]);

  useEffect(() => {
    if (showLetter && !isFilled) {
      const firstEmptyIndex = answerBlocks.findIndex(block => !block || !block.value);

      const revealedLetter = splittedAnswer[firstEmptyIndex];

      const options = optionBlocks.filter(
        block => !block.isDisabled && block.value === revealedLetter
      );

      const randomOption = options[0];

      onSelect(randomOption, false);
      dispatch(gameActions.setShowLetter(false));
    }
  }, [showLetter, isFilled, optionBlocks]);

  const onUpdateOptionsBlocks = useCallback(
    (index: number, option: Block) => {
      setOptionBlocks(prevState => {
        const updatedState = [...prevState];

        updatedState[index] = option;

        return updatedState;
      });
    },
    [setOptionBlocks]
  );

  const onUpdateAnswerBlocks = useCallback(
    (index: number, option: Block) => {
      setAnswerBlocks(prevState => {
        const updatedState = [...prevState];

        updatedState[index] = option;

        return updatedState;
      });
    },
    [setAnswerBlocks]
  );

  useEffect(() => {
    if (removeLetter) {
      const availableOptions = optionBlocks.filter(option => {
        return !option.isDisabled && option.isRemovable;
      });

      if (availableOptions.length > 0) {
        const randomOption =
          availableOptions[commonUtils.getRandomInt(0, availableOptions.length - 1)];
        const randomOptionIndex = optionBlocks.findIndex(
          _option => _option.id === randomOption?.id
        );

        onUpdateOptionsBlocks(randomOptionIndex, {
          ...randomOption,
          isDisabled: true,
        });
      }

      dispatch(gameActions.setRemoveLetter(false));
    }
  }, [removeLetter, onUpdateOptionsBlocks, optionBlocks]);

  useEffect(() => {
    const removeLetterDisable =
      optionBlocks.filter(block => !block.isDisabled && block.isRemovable).length === 0;
    dispatch(gameActions.setRemoveLetterDisable(removeLetterDisable));
  }, [optionBlocks]);

  const initCorrectAnswerBlock = () => {
    setAnswerBlocks(structuredClone(refreshAnswerBlocks));
    setIsFilled(false);
  };

  const onRetry = () => {
    initCorrectAnswerBlock();
    setHideTranslation(false);

    setShowBackdrop(revealTranslation);
    setShowFeedback(false);
    setOptionBlocks(prevState =>
      prevState.map(block => {
        return {
          ...block,
          isDisabled: false,
        };
      })
    );
    emitOnRetry?.();
  };

  const onSelect = async (option: Block, isRemovable?: boolean) => {
    if (isFilled) {
      return;
    }

    const optionBlockIndex = optionBlocks.findIndex(block => block.id === option.id);
    const firstEmptyIndex = answerBlocks.findIndex(block => !block || !block.value);

    onUpdateOptionsBlocks(optionBlockIndex, { ...option, isDisabled: true });
    onUpdateAnswerBlocks(firstEmptyIndex, {
      ...option,
      isRemovable: isRemovable === undefined,
    });

    const filledBlocks = answerBlocks.filter(block => block && block.value);

    const isLast = filledBlocks.length === correctWord.length - 1;

    if (isLast) {
      setIsFilled(true);

      setHideTranslation(true);
      dispatch(gameActions.toggleGameStatus(GameStatus.QUESTION_ANSWERED));

      const answer = [...filledBlocks.map(block => block.value), option.value].join(',');

      dispatch(gameActions.setPickedAnswer({ answer }));

      if (!isFinalAssessment) {
        setShowFeedback(true);

        setShowBackdrop(true);
        await commonUtils.sleep(1000);
        setShowFeedback(false);
      }

      emitOnAnswer(answer);

      if (isFinalAssessment) {
        await commonUtils.sleep(1000);
        onNextQuestion();
      }

      return;
    }
  };

  const onRemove = (option: Block) => {
    if (isFilled || !option || option.isRemovable === false) {
      return;
    }

    const optionBlockIndex = optionBlocks.findIndex(block => block.id === option.id);

    onUpdateOptionsBlocks(optionBlockIndex, {
      ...optionBlocks[optionBlockIndex],
      isDisabled: false,
    });

    setAnswerBlocks(prevState => {
      const updatedState = [...prevState];

      let updatedStateCopy = Array.from(Array(correctWord?.length)).fill(null);

      updatedStateCopy = [...updatedState];

      const index = updatedState.findIndex(block => block && block.id == option.id);

      updatedStateCopy[index] = null;

      return updatedStateCopy;
    });
  };

  const onNextQuestion = () => {
    const isLast = gameQuestions.length - 1 === currentQuestionIndex;

    setShowBackdrop(false);
    setShowFeedback(false);
    setHideTranslation(false);

    if (!isLast) {
      setIsFilled(false);
    }

    emitOnNextQuestion?.();
  };

  const translation = currentQuestion?.newData?.data?.questionDataLocal?.answer || '';

  return (
    <div className={classNames.closedSpelling}>
      <div
        className={classNames.top}
        style={{
          direction: (studyingLanguage?.direction as React.CSSProperties['direction']) ?? 'ltr',
        }}
      >
        {selectedQuiz && (
          <span className={classNames.instructions}>
            {commonUtils.getQuestionInstructions(currentQuestion, selectedQuiz)}
          </span>
        )}
      </div>

      <div className={classNames.content}>
        <ClosedSpellingImage
          question={currentQuestion}
          allowRetry={isInEndOfSkillTest || isFinalAssessment ? false : allowRetry}
          showBackdrop={showBackdrop}
          revealTranslation={revealTranslation && !hideTranslation}
          showFeedback={showFeedback}
          isCorrect={isCorrect}
          emitOnRetry={onRetry}
          emitOnNextQuestion={onNextQuestion}
          translation={translation}
        />
        {isCorrect && !isFinalAssessment && !showFeedback ? (
          <ClosedSpellingDictionary
            answer={correctWord}
            partOfSpeech={currentQuestion.dictionaryDetails?.partOfSpeech ?? ''}
            translation={translation}
            exampleSentences={currentQuestion.dictionaryDetails?.exampleSentences ?? []}
            soundPath={currentQuestion.dictionaryDetails?.audio ?? ''}
          />
        ) : (
          <ClosedSpellingOptions
            answerBlocks={answerBlocks}
            optionBlocks={optionBlocks}
            isCorrect={isCorrect}
            isFilled={isFilled}
            emitOnSelect={onSelect}
            emitOnRemove={onRemove}
          />
        )}
      </div>
    </div>
  );
};

export default ClosedSpelling;
