import { ChangeEvent, FormEvent, useState } from 'react';
import { AppInputProps } from '@/types/common';
import { useTranslation } from 'react-i18next';

const UsePersonalDetails = () => {
  const { t } = useTranslation();

  const onInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = target;

    setPersonalDetailsFields(prevState => {
      const updatedState = [...prevState];

      const fieldIndex = updatedState.findIndex(field => field.name === name);

      if (name === 'termsAndConditions') {
        updatedState[fieldIndex].value =
          updatedState[fieldIndex].value === 'false' ? 'true' : 'false';
      } else {
        updatedState[fieldIndex].value = value;
      }

      updatedState[fieldIndex].isValid = false;
      updatedState[fieldIndex].error = '';

      return updatedState;
    });
  };

  const onPersonalDetailsSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setPersonalDetailsFields(prevState => {
      const updatedState = [...prevState];

      // const onlyLettersRegex = /^[a-zA-Z\u0590-\u05FF -]+$/i;
      const onlyLettersRegex = /^\D*$/;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.{}\[\]()?\-\"!@#%&/\\,><':;|_~`+=])[A-Za-z\d\^$*.{}\[\]()?\-\"!@#%&/\\,><':;|_~`+=]{8,}$/;

      for (let i = 0; i < updatedState.length; i++) {
        const { id, value } = updatedState[i];

        if (!value) continue;

        const isFirstnameField = id === 'firstname';
        const isLastnameField = id === 'lastname';
        const isPasswordField = id === 'password';
        const isConfirmPasswordField = id === 'confirm_password';

        const passwordIndex = updatedState.findIndex(field => field.id === 'password');

        const passwordValue = updatedState[passwordIndex].value;

        if (isFirstnameField || isLastnameField) {
          const isNameValid = onlyLettersRegex.test(value);

          updatedState[i].isValid = isNameValid;

          if (!isNameValid) {
            updatedState[i].error = isFirstnameField ? t('invalidFirstname') : t('invalidLastname');
          }
        } else if (id === 'email') {
          const isEmailValid = emailRegex.test(value);
          updatedState[i].isValid = isEmailValid;

          if (!isEmailValid) {
            updatedState[i].error = t('invalidEmail');
          }
        } else if (isPasswordField || isConfirmPasswordField) {
          const isPasswordValid = passwordRegex.test(value);

          updatedState[i].isValid = isPasswordValid;

          if (!isPasswordValid) {
            updatedState[i].error = t('invalidPassword');
          } else if (isConfirmPasswordField) {
            const isIdentical = updatedState[passwordIndex].isValid && value === passwordValue;

            if (!isIdentical) {
              updatedState[passwordIndex].isValid = false;
              updatedState[passwordIndex].error = '';

              updatedState[i].isValid = false;
              updatedState[i].error = t('passwordsDontMatch');
            }
          }
        } else if (id === 'terms_and_conditions') {
          const isTermsAndConditionsValid = value === 'true';

          updatedState[i].isValid = isTermsAndConditionsValid;

          if (!isTermsAndConditionsValid) {
            updatedState[i].error = t('fieldIsMandatory');
          }
        }
      }

      return updatedState;
    });
  };

  const onEmailIsAlreadyTaken = () => {
    setPersonalDetailsFields(prevState => {
      const updatedState = [...prevState];

      const emailFieldIndex = updatedState.findIndex(field => field.id === 'email');

      updatedState[emailFieldIndex].isValid = false;
      updatedState[emailFieldIndex].error = ' ';

      return updatedState;
    });
  };

  const initialPersonalDetailsFields: AppInputProps[] = [
    {
      id: 'firstname',
      name: 'firstname',
      label: 'firstname',
      placeholder: 'firstname',
      value: '',
      error: '',
      isRequired: true,
      isValid: false,
      emitChange: onInputChange,
    },
    {
      id: 'lastname',
      name: 'lastname',
      label: 'lastname',
      placeholder: 'lastname',
      value: '',
      error: '',
      isRequired: true,
      isValid: false,
      emitChange: onInputChange,
    },
    {
      id: 'email',
      name: 'email',
      type: 'email',
      label: 'email',
      placeholder: 'emailExample',
      value: '',
      error: '',
      isRequired: true,
      isValid: false,
      emitChange: onInputChange,
    },
    {
      id: 'password',
      name: 'password',
      type: 'password',
      label: 'password',
      placeholder: 'yourPasswordHere',
      value: '',
      error: '',
      isRequired: true,
      isValid: false,
      showPasswordRequirements: true,
      emitChange: onInputChange,
    },
    {
      id: 'confirm_password',
      name: 'confirmPassword',
      type: 'password',
      label: 'confirmPassword',
      placeholder: 'yourPasswordHere',
      value: '',
      error: '',
      isRequired: true,
      isValid: false,
      emitChange: onInputChange,
    },
  ];

  const [personalDetailsFields, setPersonalDetailsFields] = useState<AppInputProps[]>(
    initialPersonalDetailsFields
  );

  const onUpdatePersonalDetailsFields = () => {
    setPersonalDetailsFields(prevState => {
      const termsAndConditionsField = {
        id: 'terms_and_conditions',
        name: 'termsAndConditions',
        type: 'checkbox',
        value: 'false',
        error: '',
        isRequired: true,
        isValid: false,
        emitChange: onInputChange,
      };

      return [...prevState, termsAndConditionsField];
    });
  };

  return {
    personalDetailsFields,
    onEmailIsAlreadyTaken,
    onUpdatePersonalDetailsFields,
    onPersonalDetailsSubmit,
  };
};

export default UsePersonalDetails;
