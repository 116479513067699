import { useTranslation } from 'react-i18next';
import classNames from './MyProfile.module.scss';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { Transition } from 'react-transition-group';
import AppBackdrop from '../AppBackdrop';
import AppModal from '../AppModal';
import classes from 'classnames';
import SVG from 'react-inlinesvg';

//TODO: Need to understand if it's needed
// import AchievementsCarousel from './AchievementsCarousel/AchievementsCarousel';
import ProfileCard from './ProfileCard/ProfileCard';
//TODO: Need to understand if it's needed
// import MyProfileProgress from './MyProfileProgress/MyProfileProgress';
import MyProgress from './MyProgress/MyProgress';
import GoBackBtn from '../GoBackBtn/GoBackBtn';
import UseMyProfile from '@/hooks/UseMyProfile';
import AppLoaderCircle from '../AppLoaderCircle';
import StudentDataModal from '../StudentData/StudentDataModal';
import { useState } from 'react';
import { unitSelector } from '@/store/reducers/unit';

interface MyProfileDesktopProps {
  emitHideProfile: () => void;
}

const MyProfileDesktop = ({ emitHideProfile }: MyProfileDesktopProps) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(authSelector);
  const { units, topics } = useAppSelector(unitSelector);
  const [showModal, setShowModal] = useState(false);
  const [unitId, setUnitId] = useState(0);

  const handleProgressCardClick = (id: number) => {
    setUnitId(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const { isLoading, unitsProgress, streak } = UseMyProfile();

  const content = isLoading ? (
    <div className='h-full w-full flex justify-center'>
      <AppLoaderCircle />
    </div>
  ) : (
    <>
      <div className='flex items-center justify-between'>
        <h2 className={classNames.title}>{t('myProfile')}</h2>
        <GoBackBtn className={classNames.goBackBtn} showIcon={false} emitClick={emitHideProfile} />
      </div>
      <div className={classNames.partOne}>
        <ProfileCard
          className={classNames.profileCard}
          classroom={user?.metadata.organizationDetails?.groupName}
          level={user?.progress?.userLevel}
          grade={user?.metadata.organizationDetails.grade}
          school={user?.metadata.organizationDetails.organizationName}
          country={user?.metadata.organizationDetails.country}
          streak={streak}
        />
        <MyProgress
          className={classNames.myProgress}
          unitsProgress={unitsProgress}
          onProgressCardClick={handleProgressCardClick}
        />
        <StudentDataModal
          student={user}
          unitsProgress={unitsProgress}
          unitId={unitId}
          units={units}
          topics={topics}
          showModal={showModal}
          onHideDataModal={handleCloseModal}
          isMobile={false}
        />
      </div>
    </>
  );

  return <div className={classNames.myProfileDesktop}>{content}</div>;
};

export default MyProfileDesktop;
