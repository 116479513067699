import classNames from './PopUpOpenWrittingError.module.scss';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import lamp from '@/assets/svg/wrong-circle.svg';

type PopUpOpenWrittingErrorProps = {
  isVulgar?: boolean;
  isGibberish?: boolean;
  isNotEnglish?: boolean;
};

//FIXME: Need to change any for needed type
const PopUpOpenWrittingError = ({
  isVulgar,
  isGibberish,
  isNotEnglish,
}: PopUpOpenWrittingErrorProps) => {
  const { t } = useTranslation();

  const showContent = ({ isVulgar, isGibberish, isNotEnglish }: PopUpOpenWrittingErrorProps) => {
    const data = {
      title: '',
      content: '',
    };

    if (isVulgar) {
      data.title = t('answerNotAccepted');
      data.content = t('pleaseTryAgain');
    } else if (isGibberish) {
      data.title = t('oopsDots');
      data.content = t('iDidntUnderstand');
    } else if (isNotEnglish) {
      data.title = t('oopsDots');
      data.content = t('isNotEnglish');
    }

    return data;
  };

  return (
    <div className={classNames.popUpOpenWrittingError}>
      <div className={classNames.title}>
        <SVG src={lamp} />
        <p>{showContent({ isVulgar, isGibberish, isNotEnglish }).title}</p>
      </div>
      <p className={classNames.content}>
        {showContent({ isVulgar, isGibberish, isNotEnglish }).content}
      </p>
    </div>
  );
};

export default PopUpOpenWrittingError;
