import classNames from './PopUpPause.module.scss';
import classes from 'classnames';
import { PopUpPauseProps } from '@/types/game';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import UserUtils from '@/utils/user';

import TheButton from '@/components/TheButton/TheButton';

import coinBroken from '@/assets/svg/coin-broken.svg';
import close from '@/assets/svg/close.svg';

const PopUpPause = ({
  className,
  currentQuestion,
  totalQuestions,
  coins,
  emitOnClosePopUp,
  emitOnQuit,
}: PopUpPauseProps) => {
  const { t } = useTranslation();
  const { isDesktop } = UseScreenSize();
  const { user } = useAppSelector(authSelector);
  const isTeacher = UserUtils.isTeacher(user);

  return (
    <div className={classes('container', className, classNames.popUpPause)}>
      <div className={classNames.close} onClick={() => emitOnClosePopUp?.()}>
        <SVG src={close} />
      </div>
      <div className={classNames.title}>
        <span>
          {t('question')} {(currentQuestion ?? 0) + 1}/{totalQuestions}
        </span>
        <h3>{t('quiz')}</h3>
        <h3>{t('paussed')}</h3>
      </div>
      <div className={classNames.details}>
        <SVG src={coinBroken} />
        {!isTeacher && (
          <>
            <span className={classNames.keepPlaying}>{t('keepPlaying')}</span>

            <span>
              {t('youWillLose')} {coins} {t('coins')}
            </span>
          </>
        )}
      </div>
      <div className={classNames.buttons}>
        <TheButton
          className={isDesktop ? 'flex-1' : 'w-full'}
          text={t('resume')}
          emitOnClick={() => emitOnClosePopUp?.()}
          shouldRecolor
        />
        <TheButton
          text={t('backHome')}
          className={isDesktop ? 'flex-1' : 'w-full'}
          plain
          emitOnClick={() => emitOnQuit?.()}
        />
      </div>
    </div>
  );
};

export default PopUpPause;
