import {
  DictioanryQuiz,
  DictionaryOptionsType,
  DictionaryQuestionType,
  PictureOfTheDayType,
} from '@/types/common';
//TODO: Need to understand if it's needed
import { useState } from 'react'; //useEffect

import { dictioanryService } from '@/services/dictionary';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { DictionaryPhrase, DictionaryWord } from '@/types/dictionary';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { GameStatus } from '@/types/game';
import { useDispatch } from 'react-redux';
import commonUtils from '@/utils/common';
import { t } from 'i18next';

const suggestionsDemo = ['snake', 'snore', 'sleep'];

const pictureOfTheDayDemo = {
  title: 'Chunk',
  description: 'Chunk of pineapple',
  image:
    'https://www.marijuanatimes.org/wp-content/uploads/2016/05/rsz-strain-review-pineapple-chunk.jpg',
};

const dictionaryOptionsDemo = {
  phraseOfTheDay: {
    title: 'Phrase of the day title',
    description: 'Phrase of the day description',
  },
  wordOfTheDay: {
    title: 'Word of the day title',
    description: 'Word of the day description',
  },
  todayQuestion: {
    title: "Today's question title",
    description: "Today's question description",
  },
};

const questionDemo = {
  answer: 'סוכנות',
  distractors: 'מומחיות,מוכנות,אדישות',
  soundPath:
    'https://services.morfix.co.il/BritannicaAppSettings/Settings/GetSoundByMelingoID/1683',
};

const quizzesDemo = [
  { topic: 'Topic #1', name: 'Quiz #1' },
  { topic: 'Topic #2', name: 'Quiz #2' },
  { topic: 'Topic #3', name: 'Quiz #3' },
];

const UseDictionary = () => {
  const { user } = useAppSelector(authSelector);
  const { gameStatus } = useAppSelector(gameSelector);
  const [isSearching, setIsSearching] = useState(false);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [recentTranslations, setRecentTranslations] = useState<string[]>([]);
  //TODO: Need to fix, because setAutoCompleteResults is not used here
  //eslint-disable-next-line
  const [autoCompleteResults, setAutoCompleteResults] = useState<string[]>([]);
  //TODO: Need to fix, because setSuggestions is not used here
  //eslint-disable-next-line
  const [suggestions, setSuggestions] = useState<string[]>(suggestionsDemo);
  const [selectedWord, setSelectedWord] = useState('');
  const [cards, setCards] = useState<DictionaryWord[]>([]);
  const [phrases, setPhrases] = useState<DictionaryPhrase[]>([]);
  //TODO: Need to fix, because setPictureOfTheDay is not used here
  //eslint-disable-next-line
  const [pictureOfTheDay, setPictureOfTheDay] = useState<PictureOfTheDayType>(pictureOfTheDayDemo);
  //TODO: Need to fix, because setOptions is not used here
  //eslint-disable-next-line
  const [options, setOptions] = useState<DictionaryOptionsType>(dictionaryOptionsDemo);
  //TODO: Need to fix, because setQuestion is not used here
  //eslint-disable-next-line
  const [question, setQuestion] = useState<DictionaryQuestionType>(questionDemo);
  //TODO: Need to fix, because setQuizzes is not used here
  //eslint-disable-next-line
  const [quizzes, setQuizzes] = useState<DictioanryQuiz[]>(quizzesDemo);
  const [gameStateBeforeOpen, setGameStateBeforeOpen] = useState<GameStatus | null>(null);
  //TODO: Need to understand if it's needed
  // const autoCompleteResultsDemo = [
  //   'cat',
  //   'dog',
  //   'home',
  //   'computer',
  //   'bird',
  //   'bat',
  //   'phone',
  //   'chair',
  // ];

  // useEffect(() => {
  //   const debounceTimer = setTimeout(() => {
  //     setAutoCompleteResults(() =>
  //       autoCompleteResultsDemo.filter((word) => word.startsWith(searchValue))
  //     );
  //   }, 500);

  //   return () => {
  //     clearTimeout(debounceTimer);
  //   };
  // }, [searchValue]);

  const onInputChange = (value: string) => {
    setSearchValue(value.replace(/\./g, ' '));
  };

  const onClear = () => {
    dispatch(gameActions.toggleGameStatus(gameStateBeforeOpen!));
    setSearchValue('');
    setSelectedWord('');
  };

  const onBack = () => {
    const prevTranslation = recentTranslations[recentTranslations.length - 2];
    if (prevTranslation === selectedWord || recentTranslations.length < 2) {
      onClear();
      setRecentTranslations([]);
      return;
    }
    onValueSearch(prevTranslation);
    setRecentTranslations(prevState => prevState.slice(0, -1));
  };
  //TODO: Need to understand if it's needed. This function do nothing
  //eslint-disable-next-line
  const onSelectOption = (option: string) => {
    // setSearchValue(option.replace(/\./g, ' '));
    // const isExist = recentTranslations.find(
    //   (translation) => translation === option
    // );
    // if (!isExist) {
    //   setRecentTranslations((prevState) => [...prevState, option]);
    // }
  };

  const onSearch = async (value: string) => {
    const regex = /^[\p{L}\s]+$/u;
    const trimmedWord = selectedWord.toLowerCase().trim();
    const trimmedSearchValue = value.toLowerCase().trim();

    const isValid = RegExp(regex).test(trimmedSearchValue);
    const isEqual = trimmedWord === trimmedSearchValue;

    if (!isValid || isEqual) {
      if (!isValid) {
        commonUtils.showToast(t('pleaseWriteOnlyLetters'));
      }

      return;
    }

    setSelectedWord('');
    if (gameStatus == GameStatus.PLAYING) {
      setGameStateBeforeOpen(gameStatus);
      dispatch(gameActions.toggleGameStatus(GameStatus.DICTIONARY_OPEN));
    }
    setIsSearching(true);
    try {
      const response = (await dictioanryService.fetchResults(trimmedSearchValue, user))?.data;
      if (response) {
        if (!response.words) {
          commonUtils.showToast(t('noResultsFor', { word: trimmedSearchValue }));
          setSearchValue('');
          setSelectedWord('');
          setIsSearching(false);
          return;
        }

        setSelectedWord(response.query);

        const isExist = recentTranslations.find(translation => translation === response.query);
        if (!isExist) {
          setRecentTranslations(prevState => [...prevState, response.query]);
        }
        setCards(response.words);
        setPhrases(response.queryRelatedCollocationsObject);
      }
    } catch (error) {
      console.log(error);
      setSelectedWord(value);
    }

    setIsSearching(false);
  };

  const onValueSearch = (value: string) => {
    onInputChange(value);
    onSearch(value);
  };

  return {
    searchValue,
    selectedWord,
    recentTranslations,
    autoCompleteResults,
    suggestions,
    cards,
    phrases,
    pictureOfTheDay,
    options,
    question,
    quizzes,
    isSearching,
    onInputChange,
    onSearch,
    onClear,
    onBack,
    onSelectOption,
    onValueSearch,
  };
};

export default UseDictionary;
