export const levelColors = ['#FF9F4C', '#E11560', '#2BAE19', '#973D9D', '#05ABF4'];

export const timeString = (time: number) => {
  let seconds = time && time > 0 ? time : 0;

  const days = Math.floor(seconds / (60 * 60 * 24));
  const daysStr = `${days}`;
  seconds = seconds % (60 * 60 * 24);

  const hours = Math.floor(seconds / (60 * 60));
  const hoursStr = `0${hours}`.slice(-2);
  seconds = seconds % (60 * 60);

  const minutes = Math.floor(seconds / 60);
  const minutesStr = `0${minutes}`.slice(-2);
  seconds = seconds % 60;

  const secondsStr = `0${minutes}`.slice(-2);

  return days
    ? `${daysStr}d ${hoursStr}h ${minutes}m ${secondsStr}s`
    : hours
      ? `${hoursStr}h ${minutes}m ${secondsStr}s`
      : minutes
        ? `${minutes}m ${secondsStr}s`
        : `${seconds}s`;
};

export const dateString = (_date: Date | string | number) => {
  const date = new Date(_date);
  const year = date.getFullYear();
  const yearStr = `${year}`;
  const month = date.getMonth() + 1;
  const monthStr = `0${month}`.slice(-2);
  const day = date.getDate();
  const dayStr = `0${day}`.slice(-2);

  return `${yearStr}-${monthStr}-${dayStr}`;
};

export const reportSkillsList = [
  { id: 2, name: 'grammar' },
  { id: 3, name: 'speak' },
  { id: 1, name: 'listen' },
  { id: 7, name: 'read' },
  { id: 4, name: 'spelling' },
  { id: 5, name: 'vocabulary' },
];
