import classNames from './UnitData.module.scss';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { unitDataService } from '@/services/unit-data';
import { Progress } from '@/types/user';
import { UnitsTopics, CanDoAnswer } from '@/types/unit-data';
import commonUtils from '@/utils/common';

import DetailSeperator from '@/components/DetailSeperator/DetailSeperator';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import TopicAccordion, {
  TopicAccordionProps,
  ActivityProps,
} from './TopicAccordion/TopicAccordion';

import trophy from '@/assets/svg/trophy.svg';
import medal from '@/assets/svg/medal-gold.svg';
import star from '@/assets/svg/star.svg';
import { useEffect, useState } from 'react';

interface UnitDataProps {
  unitId: number;
  studentId: string;
  unitName: string;
  completionPercentage: number;
  studentName: string;
  level: number;
  completedTopics: number;
  completedActivities: number;
  achievedStars: number;
  totalTopics: number;
  totalActivities: number;
  totalStars: number;
}

const UnitData = ({
  unitId,
  studentId,
  unitName,
  completionPercentage,
  studentName,
  level,
  completedTopics,
  completedActivities,
  achievedStars,
  totalTopics,
  totalActivities,
  totalStars,
}: UnitDataProps) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(authSelector);
  const [topics, setTopics] = useState<TopicAccordionProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let isActive = true;

    const init = async () => {
      if (unitId && user?.metadata?.id) {
        setIsLoading(true);
        const isTeacher = user.metadata.role === 'teacher';

        const fetchs = [
          new Promise(resolve => {
            const getCanDoMapper = async () => {
              let canDoMapper = new Map();
              try {
                const response = (await unitDataService.fetchCanDoAnswers(user))?.data?.data || [];
                canDoMapper = response.reduce((mapper, canDo) => {
                  mapper.set(canDo.id, canDo);
                  return mapper;
                }, new Map());
              } catch (ex) {}
              resolve(canDoMapper);
            };
            getCanDoMapper();
          }),
          new Promise(resolve => {
            const getTopics = async () => {
              let topics: UnitsTopics = [];
              try {
                const response =
                  (await unitDataService.fetchUnitTopics(user, level, unitId))?.data?.data || [];
                topics = response
                  .filter(topic => topic.activitiesInTopic > 0)
                  .sort((topic1, topic2) =>
                    topic1.order < topic2.order ? -1 : topic1.order < topic2.order ? 1 : 0
                  );
              } catch (ex) {}
              resolve(topics);
            };
            getTopics();
          }),
          new Promise(resolve => {
            const getProgress = async () => {
              if (!isTeacher && user?.progress) {
                resolve(user?.progress);
                return;
              }

              try {
                const response = (
                  await unitDataService.fetchUserUnits(
                    studentId,
                    isTeacher ? user.metadata.id : undefined
                  )
                )?.data?.data;
                resolve(response);
              } catch (ex) {}

              resolve(null);
            };
            getProgress();
          }),
        ];

        const initData = await Promise.all(fetchs);

        if (!isActive) {
          return;
        }

        const canDoMapper = initData[0] as Map<number, CanDoAnswer>;
        const topicsList = initData[1] as UnitsTopics;
        const progress = initData[2] as Progress | null;
        const unitProgress = progress?.unit?.topicsOverviewPerUnit?.[unitId] || null;

        const topics: TopicAccordionProps[] = topicsList.map(topic => {
          const topicProgress = unitProgress?.[topic.id] || null;

          const topicId: number = topic.id;
          const topicName: string = topic.name;
          const totalActivities: number = topic.activitiesInTopic;
          const completedActivities: number = topicProgress?.completedActivitiesCount || 0;
          const completionPercentage: number = commonUtils.calcPercentage(
            completedActivities,
            totalActivities
          );
          const activitiesProgress =
            progress?.topic?.activitiesOverviewPerTopic?.[topic.id] || null;

          const canDoAnswerId: number = topicProgress?.canDo?.userAnswered || 0;
          const canDoAnswer: CanDoAnswer | null = canDoMapper.get(canDoAnswerId) || null;
          const canDoStatement: string = topic.canDoStatement;

          return {
            unitId,
            topicId,
            topicName,
            totalActivities,
            completedActivities,
            completionPercentage,
            activitiesProgress,
            canDoAnswerId,
            canDoAnswer,
            canDoStatement,
            user,
            level,
          };
        });

        setTopics(topics);
        setIsLoading(false);
      }
    };

    init();

    return () => {
      isActive = false;
    };
  }, [unitId, user, level]);

  const accordionItems = topics.map((topic, index) => {
    return <TopicAccordion key={index} {...topic} />;
  });

  if (isLoading) {
    return (
      <div className={classNames.loader}>
        <AppLoaderCircle />
      </div>
    );
  }

  return (
    <div className={classNames.unitData}>
      <div className={classNames.header}>
        <div className={classNames.texts}>
          <div className={classNames.nameAndPercentage}>
            <span className={classNames.name}>{unitName}</span>
            <span className={classNames.percentage}>{`${completionPercentage}%`}</span>
          </div>
          <div className={classNames.user}>
            <span>{studentName}</span>
            <DetailSeperator />
            <span>{`${t('level')} ${level}`}</span>
          </div>
        </div>
      </div>
      <div className={classNames.details}>
        <div className={classNames.box}>
          <SVG src={trophy} />
          <span>{`${completedTopics}/${totalTopics} ${t('topics')}`}</span>
        </div>
        <div className={classNames.box}>
          <SVG src={medal} />
          <span>{`${completedActivities}/${totalActivities} ${t('activities')}`}</span>
        </div>
        <div className={classNames.box}>
          <SVG src={star} />
          <span>{`${achievedStars}/${totalStars} ${t('stars')}`}</span>
        </div>
      </div>
      <div className={classNames.accordion}>{accordionItems}</div>
    </div>
  );
};

export default UnitData;
