//FIXME: Temporary solution for using all new game types
import { GameTypes, NewGameTypes } from '@/types/game';
const MOCK_DATA = {
  [NewGameTypes.AudioToImage]: {
    question: 'audio',
    answer: 'image',
    distractorProperty: 'image',
  },
  [NewGameTypes.TextToImage]: {
    question: 'text',
    answer: 'image',
    distractorProperty: 'image',
  },
  [NewGameTypes.TextToText]: {
    question: 'text',
    answer: 'text',
    distractorProperty: 'text',
  },
  [NewGameTypes.ImageToText]: {
    question: 'image',
    answer: 'text',
    distractorProperty: 'text',
  },
  [NewGameTypes.Vocabulary]: {
    question: 'text',
    answer: 'text',
    distractorProperty: 'text',
  },
  [NewGameTypes.Listen]: {
    answer: 'text',
    question: 'audio',
    distractorProperty: 'text',
  },
  [NewGameTypes.Grammar]: {
    question: 'text',
    answer: 'text',
    distractorProperty: 'text',
  },
  [GameTypes.ClosedSpelling]: {
    question: 'text',
    answer: 'chars',
  },
  [GameTypes.Pronunciation]: {
    question: 'text',
    answer: 'audio',
  },
  [NewGameTypes.Read]: {
    question: 'text',
    answer: 'text',
    distractorProperty: 'text',
  },
} as any;

const getImagesData = (questionData: any) => {
  const imagesCredits: any = {};
  const imagesAlt: any = {};

  if (
    questionData.gameType === NewGameTypes.AudioToImage ||
    questionData.gameType === NewGameTypes.TextToImage
  ) {
    if (questionData?.newData?.data?.questionData?.answer?.image) {
      imagesCredits[questionData.newData.data.questionData.answer.image] =
        questionData.newData.data.questionData.answer.imageCredit || '';
      imagesAlt[questionData.newData.data.questionData.answer.image] =
        questionData.newData.data.questionData.answer.image_description ||
        questionData.newData.data.questionData.answer.imageDescription ||
        '';
    }
    if (questionData?.newData?.data?.questionData?.distractors) {
      questionData.newData.data.questionData.distractors.forEach((distractor: any) => {
        if (distractor.image) {
          imagesCredits[distractor.image] = distractor.imageCredit || '';
          imagesAlt[distractor.image] =
            distractor.image_description || distractor.imageDescription || '';
        }
      });
    }
  }

  if (questionData.gameType === NewGameTypes.ImageToText) {
    if (questionData?.newData?.data?.questionData?.question?.image) {
      imagesCredits[questionData.newData.data.questionData.question.image] =
        questionData.newData.data.questionData.question.imageCredit || '';
      imagesAlt[questionData.newData.data.questionData.question.image] =
        questionData.newData.data.questionData.question.imageDescription || '';
    }
  }

  if (questionData.gameType === NewGameTypes.Read) {
    if (questionData?.newData?.data?.metadata?.image) {
      imagesCredits[questionData.newData.data.metadata.image] =
        questionData.newData.data.metadata.imageCredit || '';
      imagesAlt[questionData.newData.data.metadata.image] =
        questionData.newData.data.metadata.imageDescription || '';
    }
  }

  if (questionData.gameType === GameTypes.ClosedSpelling) {
    if (questionData.dictionaryDetails?.image) {
      imagesCredits[questionData.dictionaryDetails.image] =
        questionData.dictionaryDetails.imageCredit || '';
      imagesAlt[questionData.dictionaryDetails.image] =
        questionData.dictionaryDetails.imageDescription || '';
    }
  }

  if (questionData.gameType === NewGameTypes.Memory) {
    if (questionData.newData?.data?.questionData?.pairs) {
      questionData.newData.data.questionData.pairs.forEach((pair: any) => {
        if (pair.image) {
          imagesCredits[pair.image] = pair.imageCredit || '';
          imagesAlt[pair.image] = pair.imageDescription || '';
        }
      });
    }
  }

  if (questionData.gameType === NewGameTypes.OpenWriting) {
    if (questionData.newData?.data?.questionData?.image) {
      imagesCredits[questionData.newData.data.questionData.image] =
        questionData.newData.data.questionData.imageCredit;
      imagesAlt[questionData.newData.data.questionData.image] =
        questionData.newData.data.questionData.imageDescription;
    }
  }

  return {
    imagesCredits,
    imagesAlt,
  };
};

export const updateActivityResponseData = (responseData: any, index: number, realData: any) => {
  if (
    responseData[index].gameTypeId === NewGameTypes.OpenWriting ||
    responseData[index].gameTypeId === NewGameTypes.Memory
  ) {
    responseData[index] = {
      gameType: responseData[index].gameTypeId,
      questionId: responseData[index].questionId,
      question: '',
      answer: '',
      distractors: '',
      order: responseData[index].order,
      coins: +responseData[index].data?.metadata?.coins,
      soundPath: '',
      dictionaryDetails: '',
      newData: realData[index],
    };
  } else {
    if (responseData[index].gameTypeId === NewGameTypes.NaturalConversation) {
      MOCK_DATA[NewGameTypes.NaturalConversation] =
        MOCK_DATA[realData[index].data.metadata.skillId as NewGameTypes];
    }
    responseData[index] = {
      gameType: responseData[index].gameTypeId,
      questionId: responseData[index].questionId,
      //FIXME: Need to find a solutio how to use it correctly in feature
      question:
        responseData[index].data[
          responseData[index].gameTypeId !== NewGameTypes.Read ? 'questionData' : 'questionsData'
        ].question[MOCK_DATA[responseData[index].gameTypeId].question],
      //FIXME: Need to find a solutio how to use it correctly in feature
      answer:
        responseData[index].gameTypeId === NewGameTypes.Vocabulary
          ? responseData[index].data.questionDataLocal.answer.text
          : responseData[index].data[
              responseData[index].gameTypeId !== NewGameTypes.Read
                ? 'questionData'
                : 'questionsData'
            ].answer[MOCK_DATA[responseData[index].gameTypeId].answer],
      //FIXME: Need to find a solutio how to use it correctly in feature
      distractors:
        responseData[index].gameTypeId === GameTypes.ClosedSpelling ||
        responseData[index].gameTypeId === GameTypes.Pronunciation ||
        (responseData[index].gameTypeId === NewGameTypes.NaturalConversation &&
          realData[index].data.metadata.skillId === GameTypes.Pronunciation)
          ? ''
          : responseData[index].data[
              responseData[index].gameTypeId === NewGameTypes.Read
                ? 'questionsData'
                : responseData[index].gameTypeId === NewGameTypes.Vocabulary
                  ? 'questionDataLocal'
                  : 'questionData'
            ].distractors.map(
              (item: any) => item[MOCK_DATA[responseData[index].gameTypeId].distractorProperty]
            ),
      order: responseData[index].order,
      coins: 3,
      //FIXME: it will work only for 'audio-to-image' and 'listen', NEED TO FIX
      soundPath:
        responseData[index].data[
          responseData[index].gameTypeId !== NewGameTypes.Read ? 'questionData' : 'questionsData'
        ].question[MOCK_DATA[responseData[index].gameTypeId].question],
      dictionaryDetails:
        responseData[index].data[
          responseData[index].gameTypeId !== NewGameTypes.Read ? 'questionData' : 'questionsData'
        ].dictionaryDetails,

      newData: realData[index],
    };
  }

  if (typeof realData[index]?.data?.metadata?.isInstructionsInLocalLanguage === 'boolean') {
    const questionData = realData[index]?.data.questionData || realData[index]?.data.questionsData;
    const questionDataLocal =
      realData[index]?.data.questionDataLocal || realData[index]?.data.questionsDataLocal;
    responseData[index].instructions = realData[index].data.metadata.isInstructionsInLocalLanguage
      ? questionDataLocal?.instructions?.text
      : questionData?.instructions?.text;
    responseData[index].instructions = responseData[index].instructions || '';
  }

  const imagesData = getImagesData(responseData[index]);
  responseData[index].imagesCredits = imagesData.imagesCredits;
  responseData[index].imagesAlt = imagesData.imagesAlt;
};
