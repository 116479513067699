import React from 'react';
import { TFunction } from 'i18next';

import { NavigateFunction } from 'react-router-dom';
import { AppDispatch } from '@/store';
import { AuthState } from '@/types/user';
import UseSettings from '@/hooks/UseSettings';
import { ThemeContextType } from '@/context/ThemeContext';

export type WithSettingsProps = {
  color: (key: string) => string;
  t: TFunction;
  auth: AuthState;
  dispatch: AppDispatch;
  navigate: NavigateFunction;
  theme: ThemeContextType;
};

export default function WithSettings<T>(Component: React.ComponentType<T>) {
  return (props: Omit<T, 'theme' | 'color' | 't' | 'auth' | 'dispatch' | 'navigate'>) => {
    const { theme, color, t, auth, dispatch, navigate } = UseSettings();

    return (
      <Component
        {...(props as T)}
        auth={auth}
        color={color}
        navigate={navigate}
        dispatch={dispatch}
        t={t}
        theme={theme}
      />
    );
  };
}
