import React from 'react';
import classNames from './Card.module.scss';

interface CardProps {
  width: string;
  height: string;
  title: string;
  description: string;
  items: {
    label: string;
    value?: string;
  }[];
}

const Card = ({ width, height, title, description, items }: CardProps) => {
  return (
    <div className={classNames.card} style={{ width: width, height: height }}>
      <div className={classNames.header}>
        <div className={classNames.title}>
          <span className={classNames.text}>{title}</span>
        </div>
        <div className={classNames.description}>
          <span className={classNames.text}>{description}</span>
        </div>
      </div>
      <div className={classNames.list}>
        {items.map((item, index) => {
          return (
            <React.Fragment key={`item--${index}`}>
              <div className={classNames.item}>
                <div className={classNames.label}>
                  <span className={classNames.text}>{item.label}</span>
                </div>
                {!!item.value && (
                  <div className={classNames.value}>
                    <span className={classNames.text}>{item.value}</span>
                  </div>
                )}
              </div>
              {index < items.length - 1 && <div className={classNames.seperator}></div>}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Card;
