import React, { useState } from 'react';
import classes from 'classnames';
import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import classNames from './LoginForm.module.scss';
import UseScreenSize from '@/hooks/UseScreenSize';
import { LoginValues } from '@/types/login';

import AppInput from '../AppInput/AppInput';
import TheButton from '../TheButton/TheButton';

import logo from '@/assets/svg/logo_60x60.svg';
import { CognitoPasswordChangeProps } from '@/types/login';
import AppLoaderCircle from '../AppLoaderCircle';

type FormSubmitValues = {
  password: string;
  confirmPassword: string;
};

const CognitoPasswordChange = ({ loading, styles, emitSubmit }: CognitoPasswordChangeProps) => {
  const { t } = useTranslation();
  const { isMobile } = UseScreenSize();
  const [showInputErrors, setShowInputErrors] = useState<boolean>(false);

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.{}\[\]()?\-\"!@#%&/\\,><':;|_~`+=])[A-Za-z\d\^$*.{}\[\]()?\-\"!@#%&/\\,><':;|_~`+=]{8,}$/;

  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('validation.password_required').toString())
      .matches(passwordRegex),
    confirmPassword: Yup.string()
      .required(t('validation.confirm_password_required').toString())
      .oneOf([Yup.ref('password')], t('passwordsDontMatch').toString()),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values: FormSubmitValues, formikHelpers: FormikHelpers<FormSubmitValues>) => {
      setShowInputErrors(false);

      if (emitSubmit) {
        emitSubmit(values, formikHelpers as FormikHelpers<LoginValues>);
      }
    },
  });

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setShowInputErrors(true);
    formik.handleSubmit(event);
  };

  const onClear = (event: React.MouseEvent<SVGElement, MouseEvent>, name: string) => {
    formik.setFieldValue(name, '');
  };

  return (
    <div className={classNames.cognitoPasswordChange}>
      <div className={styles.header}>
        <SVG src={logo} />
        <h2>{t('helloBritannica')}</h2>
        <span>{t('pleaseChooseYourOwnPassword')}</span>
      </div>
      <form onSubmit={onFormSubmit}>
        <AppInput
          className={styles.formField}
          inputWrapperClassName={classes(styles.appInputWrapper, {
            [styles.appInputWrapperError]: !!formik.errors.password && showInputErrors,
          })}
          passwordRequirementsClassName={styles.passwordRequirements}
          id='password'
          name='password'
          type='password'
          label={`*${t('password')}`}
          placeholder={t('password') ?? ''}
          value={formik.values?.password?.trim()}
          required
          error={showInputErrors ? formik.errors.password : ''}
          allowClear={true}
          emitChange={formik.handleChange}
          emitBlur={formik.handleBlur}
          emitClear={onClear}
          showPasswordRequirements={true}
          helperPosition={isMobile ? 'label' : 'input'}
        />
        <AppInput
          className={styles.formField}
          inputWrapperClassName={classes(styles.appInputWrapper, {
            [styles.appInputWrapperError]: !!formik.errors.confirmPassword && showInputErrors,
          })}
          id='confirmPassword'
          name='confirmPassword'
          type='password'
          label={`*${t('confirmPassword')}`}
          placeholder={t('password') ?? ''}
          value={formik.values?.confirmPassword?.trim()}
          required
          error={showInputErrors ? formik.errors.confirmPassword : ''}
          allowClear={true}
          emitChange={formik.handleChange}
          emitBlur={formik.handleBlur}
          emitClear={onClear}
        />
        {!loading && (
          <TheButton
            className={styles.submit}
            text={t('done')}
            type='submit'
            disabled={formik.isSubmitting}
            shouldRecolor={false}
          />
        )}
        {loading && (
          <div className={styles.loadingWrapper}>
            <AppLoaderCircle />
          </div>
        )}
      </form>
    </div>
  );
};

export default CognitoPasswordChange;
