import classes from 'classnames';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { unitSelector } from '@/store/reducers/unit';
import UserUtils from '@/utils/user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import badge from '@/assets/svg/badge.svg';
import logo from '@/assets/svg/logo_60x60.svg';
import classNames from './FinishUnit.module.scss';

const FinishLevel = () => {
  const { t } = useTranslation();
  const { selectedLevel } = useAppSelector(unitSelector);
  const { user, levels } = useAppSelector(authSelector);
  const isTeacher = UserUtils.isTeacher(user);

  const updatedLevels = levels.filter((item: any) => {
    if (item.name !== 'Level Test') return item;
  });

  const currentLevel = updatedLevels.find(level => level.order === selectedLevel);
  const nextLevel = updatedLevels.find(level => level.order === selectedLevel + 1);

  const isNotLastLevel = Boolean(updatedLevels.find((_, idx: number) => idx === selectedLevel));

  const descriptionText = isNotLastLevel
    ? t('lastUnitCongratulation', { currentLevel, nextLevel })
    : t('finishGameText');

  const [hidePage, setHidePage] = useState(false);

  useEffect(() => {
    const fadeOutTimeout = window.setTimeout(() => {
      setHidePage(true);
      if (isTeacher) {
        localStorage.setItem(
          'selectedLevel',
          String(isNotLastLevel ? selectedLevel + 1 : selectedLevel)
        );
      }
      window.location.reload();
    }, 3000);

    return () => {
      window.clearTimeout(fadeOutTimeout);
    };
  }, []);

  return (
    <div className={`welcomeConfetti ${hidePage ? 'fadeOut' : 'fadeIn'}`}>
      <div>{`${t('wellDone')}`}</div>
      <div
        className={classNames.congretulationText}
        dangerouslySetInnerHTML={{ __html: t(descriptionText).toString() }}
      />
      <div className={classNames.iconWrapper}>
        <SVG height={160} width={140} src={isNotLastLevel ? badge : logo} />
        {isNotLastLevel && <span className={classNames.levelText}>{selectedLevel + 1}</span>}
      </div>
    </div>
  );
};

export default FinishLevel;
