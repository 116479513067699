import React from 'react';
import classNames from './BarGraph.module.scss';

interface BarGraphProps {
  width: string;
  height: string;
  title: string;
  items: {
    label: string;
    value: number;
  }[];
}

const BarGraph = ({ width, height, title, items }: BarGraphProps) => {
  const axisY = (
    <div className={classNames.axisY}>
      {[100, 80, 60, 40, 20, 0].map(axisYItem => {
        return (
          <div key={`axisYItem--${axisYItem}`} className={classNames.axisYItem}>
            <div className={classNames.axisYItemLabel}>
              <span className={classNames.text}>{axisYItem}</span>
            </div>
          </div>
        );
      })}
    </div>
  );

  const axisX = (
    <div className={classNames.axisX}>
      {items.map((item, index) => {
        return (
          <div key={`axisYItem--${index}`} className={classNames.axisXItem}>
            <span className={classNames.text}>{item.label}</span>
          </div>
        );
      })}
    </div>
  );

  const gridVerticalLines = items.map(() => '<td></td>').join('');
  const grid = (
    <table className={classNames.grid}>
      {[...Array(5)].map((_, index) => {
        return (
          <tr
            key={`grid-HorizontalLine--${index}`}
            dangerouslySetInnerHTML={{ __html: gridVerticalLines }}
          />
        );
      })}
    </table>
  );

  const bars = (
    <div className={classNames.bars}>
      {items.map((item, index) => {
        const value = item.value;
        const color = value < 60 ? '#E11515' : value < 80 ? '#FF9F4C' : '#2BAE19';

        return (
          <div key={`bar--${index}`} className={classNames.barContainer}>
            <div className={classNames.progress}>
              {value > 0 && (
                <>
                  <div
                    className={classNames.progressBat}
                    style={{ height: `${value}%`, background: color }}
                  ></div>
                  <div className={classNames.value} style={{ bottom: `${value}%` }}>
                    <span className={classNames.text}>{value}</span>
                  </div>
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={classNames.barGraph} style={{ width: width, height: height }}>
      <div className={classNames.header}>
        <div className={classNames.title}>
          <span className={classNames.text}>{title}</span>
        </div>
      </div>
      <div className={classNames.graph}>
        {axisY}
        {axisX}
        <div className={classNames.main}>
          {grid}
          {bars}
        </div>
      </div>
    </div>
  );
};

export default BarGraph;
