import classNames from './PopUpHints.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { HintsType, NewGameTypes, PopUpHintsProps } from '@/types/game';
import React, { useCallback, useMemo } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';

import flashlight from '@/assets/svg/flashlight.svg';
import coin from '@/assets/svg/coin.svg';
import coinsRow from '@/assets/svg/coins-row.svg';
import coinsRow2 from '@/assets/svg/coins-row-2.svg';
import coinsRow5 from '@/assets/svg/coins-row-5.svg';
import close from '@/assets/svg/close.svg';
import { authSelector } from '@/store/reducers/auth';
import UserUtils from '@/utils/user';
import UseUnitsAssets from '@/hooks/UseUnitsAssets';

const PopUpHints = ({ className, coins, emitOnSelectHint, emitOnClosePopUp }: PopUpHintsProps) => {
  const { t } = useTranslation();
  const { isMobile } = UseScreenSize();
  const {
    selectedHint,
    gameQuestions,
    currentQuestionIndex,
    possibleAnswers,
    canUseRemoveOneAnswerHint,
    gameType,
    removeLetterDisable,
    revealAPairDisable,
    revealWord,
    revealTranslation,
  } = useAppSelector(gameSelector);

  const { user } = useAppSelector(authSelector);
  const { hintsBygameType } = UseUnitsAssets();

  const isTeacher = UserUtils.isTeacher(user);
  const isStudent = UserUtils.isStudent(user);

  const currentQuestion = useMemo(
    () => gameQuestions[currentQuestionIndex],
    [currentQuestionIndex]
  );

  const subGameType = useMemo(() => {
    if (gameType === NewGameTypes.NaturalConversation) {
      if (currentQuestion?.newData?.data?.metadata?.skillId) {
        return currentQuestion.newData.data.metadata.skillId;
      }
    }

    return null;
  }, [currentQuestion, gameType]);

  const coinsAmount = user?.progress?.coins;

  const hints: HintsType[] = hintsBygameType(gameType, subGameType);

  const totalCoins = useMemo(() => {
    return coins ? coinsAmount : 0;
  }, [coins, selectedHint]);

  const isSelected = useCallback(
    (hint: string) => {
      return Boolean(selectedHint === hint);
    },
    [selectedHint]
  );

  const onSelectHint = (hint: HintsType) => {
    if (selectedHint !== null) {
      return;
    }

    emitOnSelectHint?.(hint);
  };

  const canUseHint = (hint: HintsType) => {
    if (hint === HintsType.REMOVE_ONE_ANSWER) {
      return canUseRemoveOneAnswerHint;
    }

    return true;
  };

  const isDisabled = (hint: HintsType) => {
    const isRead = gameType === NewGameTypes.Read;

    if (isStudent && user?.progress?.coins! < 5) {
      return true;
    }

    if (hint === HintsType.REMOVE_LETTER) {
      return removeLetterDisable;
    }

    if (hint === HintsType.REVEAL_A_PAIR) {
      return revealAPairDisable;
    }

    if (hint === HintsType.SEE_ENGLISH_WORD) {
      return revealWord;
    }

    if (hint === HintsType.SEE_TRANSLATION) {
      return revealTranslation;
    }

    if (hint === HintsType.REMOVE_ONE_ANSWER) {
      return (
        (isRead ? (currentQuestion.distractors ?? []).length + 1 : possibleAnswers.length) <= 2
      );
    }

    return false;
  };

  const hintsElements = hints?.map((hint, index) => {
    const hintCoins = 5;

    const coinsText = isSelected(hint) ? (
      <span>-{hintCoins}</span>
    ) : (
      <span>
        {hintCoins} {t('coins')}
      </span>
    );

    if (!canUseHint(hint)) {
      return;
    }

    const isHintDisabled = isDisabled(hint);

    return (
      <div
        className={classes(classNames.hint, {
          [classNames.hintSelected]: isSelected(hint),
          [classNames.centerHint]: isTeacher,
          [classNames.extendedHint]: hints.length === 3 && index === 2,
          [classNames.hintDisabled]: isHintDisabled,
        })}
        onClick={() => {
          if (isHintDisabled) {
            return;
          }
          onSelectHint(hint);
        }}
        key={index}
      >
        <span className={classNames.text}>{t(`hints_options.${hint}`)}</span>
        {!isTeacher && (
          <div className={classNames.hintCoins}>
            <SVG src={isSelected(hint) ? coinsRow5 : coin} />
            {coinsText}
          </div>
        )}
      </div>
    );
  });

  return (
    <div className={classes('container', className, classNames.popUpHints)}>
      <div className={classNames.close} onClick={() => emitOnClosePopUp?.()}>
        <SVG src={close} />
      </div>
      <div className={classNames.flashlight}>
        <SVG src={flashlight} />
      </div>
      <div className={classes('container', classNames.content)}>
        <h3 className={classNames.title}>{t('help')}</h3>
        {isStudent && <p>{t('useTheCoins')}</p>}
        {isStudent && (
          <div className={classNames.coins}>
            <SVG src={isMobile ? coinsRow2 : coinsRow} />
            <h3>{totalCoins}</h3>
            <span>{t('totalCoins')}</span>
          </div>
        )}
        <div className='flex-1'></div>
        <div className={classNames.hints}>{hintsElements}</div>
      </div>
    </div>
  );
};

export default React.memo(PopUpHints);
