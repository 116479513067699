import classNames from './QuizTip.module.scss';
import classes from 'classnames';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';

import lamp from '@/assets/svg/lamp.svg';
import UseScreenSize from '@/hooks/UseScreenSize';

interface QuizTipProps {
  tip: string;
  plain?: boolean;
  className?: string;
  lampClassName?: string;
  textClassName?: string;
  shouldExpand?: boolean;
  isSummary?: boolean;
  emitOnExpand?: () => void;
}

const QuizTip = ({
  tip,
  plain = false,
  className,
  lampClassName,
  shouldExpand,
  isSummary = false,
  emitOnExpand,
}: QuizTipProps) => {
  const { t } = useTranslation();
  const { selectedType } = useAppSelector(gameSelector);
  const [showReadMore, setShowReadMore] = useState(true);
  const { isDesktop, isMobile, windowHeight } = UseScreenSize();

  const contentRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      const lineHeight = parseInt(getComputedStyle(contentElement).lineHeight);
      //TODO: Need to understand if it's needed
      // const maxHeight = lineHeight * (isSummary ? 0.8 : 2.8);
      // setShowReadMore(contentElement.clientHeight > maxHeight);
    }
  }, [tip, windowHeight]);

  const lampElement = (
    <div className={classes(classNames.lamp, lampClassName)}>
      <SVG src={lamp} />
    </div>
  );

  return (
    <div
      className={classes(
        classNames.quizTip,
        {
          [classNames.quizTipPlain]: plain,
          [classNames.quizTipSummary]: isSummary,
        },
        className
      )}
    >
      {isSummary && isDesktop ? null : lampElement}
      <div
        className={classes(classNames.details, {
          [classNames.expandDetails]: shouldExpand,
        })}
      >
        <div className={classNames.top}>
          {isSummary && isDesktop && lampElement}
          {selectedType && <span className={classNames.skillTip}>{t(`${selectedType}_tip`)}</span>}
        </div>
        {shouldExpand || isDesktop ? (
          <p className={classNames.text}>{tip}</p>
        ) : (
          <p
            className={classes(classNames.text, classNames.textReduced)}
            onClick={() => (isSummary ? emitOnExpand?.() : null)}
            ref={contentRef}
          >
            {tip}
          </p>
        )}
        {showReadMore && !shouldExpand && !isSummary && isMobile && (
          <button className={classNames.readMore} onClick={emitOnExpand}>
            {t('readMore')}
          </button>
        )}
      </div>
    </div>
  );
};

export default QuizTip;
