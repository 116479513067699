import classes from 'classnames';
import classNames from './KnowledgeIcon.module.scss';
import React from 'react';

interface KnowledgeIconProps {
  className?: string;
  isKnown: boolean | null;
  shouldDisable?: boolean;
  shouldHighlight: boolean;
}

const KnowledgeIcon = ({
  className,
  isKnown,
  shouldDisable,
  shouldHighlight,
}: KnowledgeIconProps) => {
  return (
    <span
      className={classes(
        classNames.knowledgeIcon,
        isKnown ? classNames.know : classNames.dontKnow,
        {
          [classNames.knowDisabled]: isKnown && shouldDisable,
          [classNames.dontKnowDisabled]: !isKnown && shouldDisable,
          [classNames.knowHighlight]: isKnown && shouldHighlight,
          [classNames.dontKnowHighlight]: !isKnown && shouldHighlight,
        },
        className
      )}
    ></span>
  );
};

export default React.memo(KnowledgeIcon);
