import classNames from './StudentCard.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import UseLevel from '@/hooks/UseLevel';
import SVG from 'react-inlinesvg';

import leftArrow from '@/assets/svg/left-arrow.svg';
import rightArrow from '@/assets/svg/right-arrow-large.svg';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';
import { UserStatusEnum } from '@/types/register';

interface StudentCardDesktopProps {
  firstname: string;
  lastname: string;
  level?: number;
  userName?: string;
  activitiesProgress?: number;
  score?: number;
  emitShowDataModal: (studentId: string) => void;
  userStatus: UserStatusEnum | null;
}
const StudentCardDesktop = ({
  level,
  firstname,
  lastname,
  userName,
  activitiesProgress,
  score,
  emitShowDataModal,
  userStatus,
}: StudentCardDesktopProps) => {
  const { t } = useTranslation();

  const { getLevelText } = UseLevel();
  const levelText = getLevelText(level);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const isTestLevel = !level;
  const progressValue = isTestLevel ? 0 : activitiesProgress;
  const scoreValue = isTestLevel ? '-' : score;

  const onOpenUserProfile = () => {
    if (userName) {
      emitShowDataModal(userName);
    }
  };

  return (
    <div className={classNames.studentCardDesktop}>
      <div className={classNames.name}>
        {firstname} {lastname}
      </div>
      <div className={classNames.level}>
        {level ? (
          <span className={classNames.levelText}>{`${level}-${levelText}`}</span>
        ) : (
          <>
            {userStatus === UserStatusEnum.INACTIVE && (
              <span className={classes(classNames.levelText, classNames.inactive)}>
                <span>{t('inactive')}</span>
              </span>
            )}
            {userStatus === UserStatusEnum.LEVEL_TEST_INCOMPLETE && (
              <span className={classes(classNames.levelText, classNames.levelTest)}>
                {t('levelTestIncomplete')}
              </span>
            )}
          </>
        )}
      </div>
      <div className={classNames.progress}>
        <div className={classNames.barWrapper}>
          <div className={classNames.bar} style={{ width: `${progressValue}%` }}></div>
        </div>
        <span className={classNames.progressText}>{`${progressValue}%`}</span>
      </div>
      <div className={classNames.score}>{scoreValue}</div>
      <div className={classNames.button}>
        <button onClick={onOpenUserProfile}>
          <SVG src={interfaceLanguage?.direction === 'rtl' ? leftArrow : rightArrow} />
        </button>
      </div>
    </div>
  );
};

export default StudentCardDesktop;
