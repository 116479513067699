import classNames from './GenerateReport.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { ClassReportContent } from '@/types/generate-report';
import { TeacherGroup } from '@/types/user';
import Header from './Header/Header';
import TopCard from './TopCard/TopCard';
import Card from './Card/Card';
import ProgressCart from './ProgressCart/ProgressCart';
import BarGraph from './BarGraph/BarGraph';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import * as helper from './helper';
import common from '@/utils/common';
import { reportService } from '@/services/report';
import graphIcon from '@/assets/svg/graph.svg';
import learningIcon from '@/assets/svg/learning.svg';
import thumbRedIcon from '@/assets/svg/thumb-red.svg';

interface ClassReportProps {
  currentClassroom?: TeacherGroup;
  generateReportPdf: (element: HTMLElement | null) => void;
  onLoadReportError: () => void;
}

const ClassReport = ({
  currentClassroom,
  generateReportPdf,
  onLoadReportError,
}: ClassReportProps) => {
  const [classReport, setClassReport] = useState<ClassReportContent | null | undefined>();
  const { t } = useTranslation();
  const { levels, user } = useAppSelector(authSelector);

  useEffect(() => {
    let isActive = true;

    const loadReport = async () => {
      if (!currentClassroom) {
        return;
      }

      try {
        const classReport = (await reportService.fetchClassReport(currentClassroom.id, user))?.data
          ?.data;

        if (isActive) {
          setClassReport(classReport);
        }
      } catch (error) {
        if (isActive) {
          setClassReport(null);
          onLoadReportError();
        }
      }
    };
    loadReport();

    return () => {
      isActive = false;
    };
  }, [currentClassroom, user, onLoadReportError]);

  const currentClassroomData = useMemo(() => {
    const name = currentClassroom?.name || '-';

    const grade = !currentClassroom?.grade
      ? '-'
      : currentClassroom.grade.toLowerCase() === 'university' ||
          currentClassroom.grade.toLowerCase() === 'none'
        ? t(currentClassroom.grade)
        : t(`grade_${currentClassroom.grade}`);

    const totalStudents = currentClassroom?.totalStudents?.toString() || '-';

    return {
      name,
      grade,
      totalStudents,
    };
  }, [currentClassroom, t]);

  const reportData = useMemo(() => {
    if (!classReport) {
      return null;
    }

    const avgActivitiesCompleted = `${common.roundNumber(classReport?.averageActivitiesCompleted || 0, 2)}`;
    const avgPassRate = `${common.roundNumber(classReport?.passRate || 0, 0)}%`;
    const avgFailureRate = `${common.roundNumber(classReport?.failureRate || 0, 0)}%`;
    const activeStudents = classReport?.numberOfStudents || 0;

    return {
      avgActivitiesCompleted,
      avgPassRate,
      avgFailureRate,
      activeStudents,
    };
  }, [classReport]);

  const reportLevelData = useMemo(() => {
    if (!classReport || !levels) {
      return null;
    }

    const levelsSort = [...(levels || [])]
      .filter(level => level.id < 100 && (level.name || '').toLowerCase() !== 'level test')
      .sort((level1, level2) => level1.id - level2.id);

    const cardHeight = `${85 + 24 * levelsSort.length - 13}px`;

    const avgStudentProgressMap = (classReport?.averageSudentsPrograssPerLevel || []).reduce(
      (map, item) => {
        map.set(item.level, item.averageSudentsPrograss);
        return map;
      },
      new Map<number, number>()
    );

    const avgStudentProgressItems: {
      label: string;
      value: number;
      color: string;
    }[] = [];

    const avgTimeSpentMap = (classReport?.averageTimeSpentPerLevels || []).reduce((map, item) => {
      map.set(item.level, item.averageTimeSpent);
      return map;
    }, new Map<number, number>());

    const avgTimeSpentItems: {
      label: string;
      value?: string;
    }[] = [];

    const avgStreakLengthMap = (classReport?.averageStreakLengthPerLevel || []).reduce(
      (map, item) => {
        map.set(item.level, item.averageStreakLength);
        return map;
      },
      new Map<number, number>()
    );

    const avgStreakLengthItems: {
      label: string;
      value?: string;
    }[] = [];

    const avgAttemptsMap = (classReport?.averageActivityAttemptsPerLevel || []).reduce(
      (map, item) => {
        map.set(item.level, item.averageActivityAttempts);
        return map;
      },
      new Map<number, number>()
    );

    const avgAttemptsItems: {
      label: string;
      value?: string;
    }[] = [];

    levelsSort.forEach((level, levelIndex) => {
      const label = `${t('level')} ${levelIndex + 1}`;

      avgStudentProgressItems.push({
        label,
        value: avgStudentProgressMap.get(level.id) || 0,
        color: helper.levelColors[(level.id - 1) % helper.levelColors.length],
      });

      avgTimeSpentItems.push({
        label,
        value: helper.timeString(avgTimeSpentMap.get(level.id) || 0),
      });

      avgStreakLengthItems.push({
        label,
        value: common.formatString(
          t('reports.daysPerWeek'),
          common.roundNumber(avgStreakLengthMap.get(level.id) || 0, 2)
        ),
      });

      avgAttemptsItems.push({
        label,
        value: common.roundNumber(avgAttemptsMap.get(level.id) || 0, 2).toString(),
      });
    });

    return {
      cardHeight,
      avgStudentProgressItems,
      avgTimeSpentItems,
      avgStreakLengthItems,
      avgAttemptsItems,
    };
  }, [levels, classReport, t]);

  const reportSkillsData = useMemo(() => {
    if (!classReport) {
      return null;
    }

    const avgSkillScoresMap = (classReport?.averageSkillScore || []).reduce((map, item) => {
      map.set(item.skillId, item.averageScore);
      return map;
    }, new Map<number, number>());

    const avgSkillScoresItems: {
      label: string;
      value: number;
    }[] = [];

    helper.reportSkillsList.forEach(skill => {
      const label = t(`skills.${skill.name?.toLowerCase()}`);

      avgSkillScoresItems.push({
        label,
        value: common.roundNumber(avgSkillScoresMap.get(skill.id) || 0, 0),
      });
    });

    return {
      avgSkillScoresItems,
    };
  }, [classReport, t]);

  return (
    reportData &&
    reportLevelData &&
    reportSkillsData && (
      <div ref={generateReportPdf} className={classNames.pdfContainer}>
        <Header
          items={[
            { label: t('className'), value: currentClassroomData.name },
            { label: t('grade'), value: currentClassroomData.grade },
            { label: t('reports.totalStudents'), value: currentClassroomData.totalStudents },
            { label: t('date'), value: helper.dateString(new Date()) },
          ]}
        />
        <div className={classNames.contentContainer}>
          <TopCard
            items={[
              {
                icon: graphIcon,
                label: t('reports.avgActivitiesCompleted'),
                value: reportData.avgActivitiesCompleted,
              },
              {
                icon: learningIcon,
                label: t('reports.avgPassRate'),
                value: reportData.avgPassRate,
              },
              {
                icon: thumbRedIcon,
                label: t('reports.avgFailureRate'),
                value: reportData.avgFailureRate,
                color: '#FF0000',
              },
            ]}
          />
          <div className={classNames.lineContainer}>
            <ProgressCart
              width='356px'
              height={reportLevelData.cardHeight}
              title={t('reports.avgStudentProgress')}
              description={t('reports.perLevel')}
              items={reportLevelData.avgStudentProgressItems}
            />
            <Card
              width='176px'
              height={reportLevelData.cardHeight}
              title={t('reports.avgTimeSpent')}
              description={t('reports.onActivities')}
              items={reportLevelData.avgTimeSpentItems}
            />
          </div>
          <div className={classNames.lineContainer}>
            <Card
              width='266px'
              height={reportLevelData.cardHeight}
              title={t('reports.avgStreakLength')}
              description={t('reports.continuousEngagement')}
              items={reportLevelData.avgStreakLengthItems}
            />
            <Card
              width='266px'
              height={reportLevelData.cardHeight}
              title={t('reports.avgAttempts')}
              description={t('reports.perActivity')}
              items={reportLevelData.avgAttemptsItems}
            />
          </div>
          <BarGraph
            width='552px'
            height='204px'
            title={t('reports.avgSkillScores')}
            items={reportSkillsData.avgSkillScoresItems}
          />
          <div className={classNames.remark}>
            <samp
              className={classNames.text}
              dangerouslySetInnerHTML={{
                __html: common.formatString(
                  t('reports.remark'),
                  reportData.activeStudents,
                  currentClassroomData.totalStudents
                ),
              }}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default ClassReport;
