import React, { MutableRefObject, useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from './UnitsRoadmapSection.module.scss';
import { UnitsState, Unit, Topic } from '@/types/units';
import commonUtils from '@/utils/common';
import UnitsRoadmapCarousel from '@/components/UnitsRoadmapCarousel/UnitsRoadmapCarousel';
import { unitActions, unitSelector } from '@/store/reducers/unit';
import { useAppSelector } from '@/store';
import UseScreenSize from '@/hooks/UseScreenSize';
import Tabs from './Tabs';

interface RoadmapSectionProps {
  units: UnitsState;
  loading: boolean;
  mapRef?: MutableRefObject<HTMLDivElement | null>;
}

const UnitsRoadmapSection: React.FC<RoadmapSectionProps> = ({ units, mapRef, loading }) => {
  const dispatch = useDispatch();
  const { isMobileSmall } = UseScreenSize();
  const { selectedUnit } = useAppSelector(unitSelector);
  const [selectedTab, setSelectedTab] = useState<number>(selectedUnit.id);

  useEffect(() => {
    setSelectedTab(selectedUnit.id);
  }, [selectedUnit]);

  const getCurrentUnit = (units: Unit[], id: number): Unit | undefined =>
    units.find((element: any) => element.id === id);

  const onSelectTab = useCallback(
    (tabId: number) => {
      setSelectedTab(tabId);
      dispatch(
        unitActions.setSelectedUnit({
          id: tabId,
          name: getCurrentUnit(units.units, tabId)?.name,
          order: getCurrentUnit(units.units, tabId)?.order,
        })
      );
    },
    [units]
  );

  const selectedTopics = units.topics[selectedTab] ?? [];

  const sortedTopics: Topic[] = useMemo(() => {
    return commonUtils.sortByKey([...selectedTopics], 'order');
  }, [selectedTopics, isMobileSmall]);

  const groupedTopics: Topic[][] = useMemo(() => {
    const itemsPerPage = isMobileSmall ? 3 : 5;
    return commonUtils.groupBySize(sortedTopics, itemsPerPage);
  }, [sortedTopics, isMobileSmall]);

  const onOpenModal = (topicId: number) => {
    console.log(`Open modal for topic ${topicId}`);
  };

  const unitOrder = units.units.find(unit => unit.id === selectedTab)?.order;

  return (
    <div className={classNames.roadmapSection} ref={mapRef ?? null}>
      <div className={classNames.header}>
        <Tabs selectedTab={selectedTab} units={units?.units || []} onSelectTab={onSelectTab} />
      </div>
      <UnitsRoadmapCarousel
        unitId={selectedTab}
        unitOrder={unitOrder || 1}
        topics={sortedTopics}
        loading={loading}
        groupedTopics={groupedTopics}
        emitOpenModal={onOpenModal}
      />
    </div>
  );
};

export default React.memo(UnitsRoadmapSection);
