import classNames from './SectionHeader.module.scss';

interface SectionHeaderProps {
  title: string;
  img?: string;
  subTitle?: string;
}

const SectionHeader = ({ title, img, subTitle }: SectionHeaderProps) => {
  const imgElement = img && <img src={img} alt='section-header-img' />;

  const subTitleElement = subTitle && <span className={classNames.subTitle}>{subTitle}</span>;

  return (
    <div className={classNames.sectionHeader}>
      <div className={classNames.title}>
        {imgElement}
        <h2>{title}</h2>
      </div>
      {subTitleElement}
    </div>
  );
};

export default SectionHeader;
