import React from 'react';
import AppInput from '@/components/AppInput/AppInput';
import classNames from './SearchStudents.module.scss';
import SVG from 'react-inlinesvg';
import searchIcon from '@/assets/svg/search-icon.svg';
import { useTranslation } from 'react-i18next';

type SearchStudentsProps = {
  onInputChange: (event: React.FormEvent<HTMLInputElement>) => void;
  searchValue: string;
};

const SearchStudents = ({ searchValue, onInputChange }: SearchStudentsProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames.searchInput}>
      <SVG className={classNames.icon} src={searchIcon} />
      <AppInput
        className={classNames.input}
        value={searchValue}
        placeholder={t('searchForStudent') ?? ''}
        emitChange={onInputChange}
      />
    </div>
  );
};

export default SearchStudents;
