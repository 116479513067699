import { FinalAssessmentQuestionResult } from './../types/final-assessment';
import {
  GameState,
  GameStatus,
  GameTypes,
  NewGameTypes,
  MultiChoiceQuestionPart,
  QuizQuestionResult,
} from '@/types/game';
import { Question } from '@/types/question';
import { Quiz } from '@/types/quiz';
import commonUtils from './common';
import { SkillTypes } from '@/types/skill';
import apiSource from './apiSource';

const GameUtils = {
  // Returns true if the timer should be running based on game status
  shouldHandleTimer: (game: GameState): boolean =>
    !game.showPreQuiz &&
    game.gameType !== GameTypes.Practice &&
    game.gameStatus !== GameStatus.PAUSED &&
    game.soundWaveIsReady !== false &&
    game.gameStatus !== GameStatus.PAUSED_SIMPLE &&
    game.gameStatus !== GameStatus.CHECK_ANSWER &&
    game.gameStatus !== GameStatus.QUESTION_ANSWERED &&
    game.gameStatus !== GameStatus.SHOW_HINTS &&
    game.gameStatus !== GameStatus.SHOW_DICTIOANRY &&
    game.gameStatus !== GameStatus.TIME_ENDED &&
    game.gameStatus !== GameStatus.ENDED &&
    game.gameStatus !== GameStatus.DICTIONARY_OPEN &&
    game.gameStatus !== GameStatus.OPEN_WRITING_AI_REVIEW &&
    !game.isLevelTest &&
    !game.isFinalAssessment &&
    !game.showOverlay &&
    !game.pauseTimer,

  // Returns the amount of remaining questions
  questionsRemaining: (currentIndex: number, gameQuestions: Question[]): number =>
    GameUtils.totalQuestions(gameQuestions) - currentIndex,
  getSecondsBetweenDates: (startDate: number, endDate: number): number => {
    return Math.trunc((endDate - startDate) / 1000);
  },
  formatDate(
    date: Date,
    separateDateType?: '/' | '-' | '.',
    depth?: 'milliseconds' | 'seconds'
  ): string | any {
    const separate = separateDateType ?? '-';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
    const formattedTime =
      depth === 'seconds'
        ? `${hours}:${minutes}:${seconds}`
        : depth === 'milliseconds'
          ? `${hours}:${minutes}:${seconds}.${milliseconds}`
          : `${hours}:${minutes}`;

    // return `${day}${separate}${month}${separate}${year} ${formattedTime}`;
    return date;
  },
  // Returns the amount of total questions
  totalQuestions: (gameQuestions: Question[]): number => gameQuestions.length - 1,

  // Returns the current question by index
  currentQuestion: (game: GameState): Question => game.gameQuestions[game.currentQuestionIndex],

  // Returns a formatted string of the current remaining time
  gameTimeString: (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  },

  // Verify selected answer by game type, each game type might have different logic
  verifyAnswerByGameType: (game: GameState): boolean => {
    let levelTestReadCurrentReadQuestion;
    const isLevelTest = game.isLevelTest;
    if (isLevelTest) {
      levelTestReadCurrentReadQuestion = game.gameQuestions[game.currentReadQuestionIndex];
    }

    if (
      game.gameType === GameTypes.Pronunciation ||
      (game.gameType === NewGameTypes.NaturalConversation &&
        game.gameQuestions[game.currentQuestionIndex]?.newData?.data?.metadata?.skillId ===
          GameTypes.Pronunciation)
    ) {
      const currentQuestion = game.gameQuestions[game.currentQuestionIndex];

      if (
        (game.pickedAnswer && game.gameType === GameTypes.Pronunciation) ||
        (game.gameType === NewGameTypes.NaturalConversation &&
          game.gameQuestions[game.currentQuestionIndex]?.newData?.data?.metadata?.skillId ===
            GameTypes.Pronunciation)
      ) {
        // Hide this logic, because Speaking game should not be calculated - https://melingo.atlassian.net/browse/ELL-3183
        // const lowerCaseQuestion = currentQuestion.question?.toLowerCase();
        // const lowerCasePickedAnswer = game.pickedAnswer?.toLowerCase();

        // const isSimilar = commonUtils.isSimilarPhrases(lowerCaseQuestion, lowerCasePickedAnswer);

        //
        // return isSimilar;
        return true;
      }

      return currentQuestion.question?.toLowerCase() === game.pickedAnswer?.toLowerCase();
    } else {
      return isLevelTest
        ? levelTestReadCurrentReadQuestion?.answer === game.pickedAnswer
        : //FIXME: Need to use correct values for ClosedSpelling answer game type
          game.gameType !== GameTypes.ClosedSpelling
          ? game.gameQuestions[game.currentQuestionIndex].answer === game.pickedAnswer
          : game.gameQuestions[game.currentQuestionIndex].answer.join(',') === game.pickedAnswer;
    }
  },

  getQuizFromQuizzes: (quizzes: Quiz[] | Quiz, index?: number): Quiz => {
    if (Array.isArray(quizzes)) {
      return quizzes[index ?? 0] as Quiz;
    }

    return quizzes as Quiz;
  },

  getQuestionsFromQuiz: (
    questions: Question[] | Record<number, Question[]>,
    quizId?: number | null
  ): Question[] => {
    if (typeof questions === 'object' && quizId) {
      return questions[quizId] as Question[];
    }

    return questions as Question[];
  },

  skillTypeToGameType: (skillType: SkillTypes): GameTypes => {
    switch (skillType) {
      case SkillTypes.Grammar:
        return GameTypes.Multichoice;
      case SkillTypes.Vocabulary:
        return GameTypes.Multichoice;
      case SkillTypes.Listen:
        //FIXME: NEED to revert back Multichoice
        // return GameTypes.Multichoice;
        return GameTypes.OpenSpelling;
      case SkillTypes.Read:
        return GameTypes.Multichoice;
      case SkillTypes.Spelling:
        return GameTypes.ClosedSpelling;
      case SkillTypes.Speak:
        return GameTypes.Pronunciation;
      default:
        return GameTypes.DefinedByClient;
    }
  },

  // How many stars should the user receive based on completeness of the quiz
  starsShouldAcquire: (game: GameState): number => {
    const percent = GameUtils.getPercentOfCorrectAnswers(game);
    if (percent == 100) {
      return 3;
    }
    if (percent >= 80) {
      return 2;
    }
    if (percent >= 60) {
      return 1;
    }

    return 0;
  },

  // Returns the percentagee amount of correctly answered
  getPercentOfCorrectAnswers: (game: GameState): number => {
    return (game.total_correctAnswers / game.gameQuestions.length) * 100;
  },

  // Returns true if there is one question left
  isOneQuestionLeft: (currentIndex: number, gameQuestions: Question[]) => {
    return GameUtils.totalQuestions(gameQuestions) - currentIndex <= 1;
  },

  // Returns a number if the display focus CARD after X per quiz is not null
  displayFocusAfterX: (quiz: Quiz | Question): number | null => {
    return quiz?.cardsLogic?.displayFocusCardAfterXQuestions ?? null;
  },

  // Returns a number if the display keep going CARD after X per quiz is not null
  displayKeepGoingAfterX: (quiz: Quiz | Question): number | null => {
    return quiz?.cardsLogic?.displayKeepGoingCardAfterXQuestions ?? null;
  },

  // Play sound from base 64
  getSoundBase64FromUrl: async (soundUrl: string) => {
    const response = await apiSource.get(soundUrl);
    return response.data as string;
  },

  // Play sound from base 64
  playSoundFromUrl: async (soundUrl: string) => {
    const base64String = await GameUtils.getSoundBase64FromUrl(soundUrl);
    new Audio(base64String).play();
    return base64String;
  },

  // Suffle the distractors along with the answer
  getPossibleAnswers: (question: Question | null): string[] => {
    if (!question) {
      return [];
    }
    const gameType = question.gameType as NewGameTypes | GameTypes;
    const answer = question.answer;

    const answers: string[] = [...question.distractors];

    let possibleAnswers: string[] = [];
    if (gameType === GameTypes.ClosedSpelling) {
      answers.push(...answer);
      possibleAnswers = answers;
    } else {
      answers.push(answer as string);
      possibleAnswers = answers;
    }

    return commonUtils.shuffleArray(possibleAnswers);
  },

  // Split question by reply
  splitQuestionFromReply: (question: Question): string[] => {
    const splitQuestion = (question.question?.text ?? question.question ?? '')
      .replace(/_+/g, '~')
      .split('#|#');

    return (splitQuestion[0] ?? []).split(' ').filter((item: any) => item.length > 0);
  },

  // Split question by reply
  getMultiChoiceQuestionParts: (question: Question): MultiChoiceQuestionPart[] => {
    let blankIndex = 0;
    const answer = question.answer;

    const splittedAnswer = answer.text ? answer.text.split(/[\/\s]+/) : answer.split(/[\/\s]+/);
    const splittedQuestion = (question.question?.text ?? question.question ?? '')
      .replace(/_+/g, '~')
      .split('#|#')[0]
      .split(' ')
      .map((part: any) => {
        const isBlank = part.startsWith('~');

        const questionPart: MultiChoiceQuestionPart = {
          isBlank,
          value: isBlank ? '' : part,
        };

        if (isBlank) {
          questionPart.correctAnswerPart = part.replace('~', splittedAnswer[blankIndex]);

          blankIndex++;
        }

        return questionPart;
      });

    return splittedQuestion;
  },

  // Get question reply
  questionReply: (question: Question): string | undefined => {
    const splitQuestion = (question.question?.text ?? question.question ?? '').split('#|#');

    return splitQuestion[1];
  },
  //TODO: Need to understand does buildEndQuizData is needed
  // buildEndQuizData: async (quizQuestionResultData: QuizQuestionResult[]) => {},
  getStarsAmount: (score: number): number => {
    const lowestScore = 60;
    const mediumScore = 80;
    const highestScore = 100;

    let stars = 0;

    if (score >= highestScore) {
      stars = 3;
    } else if (score >= mediumScore) {
      stars = 2;
    } else if (score >= lowestScore) {
      stars = 1;
    } else {
      stars = 0;
    }

    return stars;
  },
  shouldDisplayActionModals: (gameQuestions: Question[]) => {
    return gameQuestions.length > 6;
  },

  isLastQuestion: (gameQuestions: Question[], currentQuestionIndex: number) => {
    return gameQuestions.length - 1 - currentQuestionIndex <= 0;
  },

  isLastActivity: (activityOrder: number | undefined, totalActivities: number | undefined) => {
    if (activityOrder === undefined || totalActivities === undefined) {
      console.log('isLastActivity function has broken argument');
      return false;
    }

    return activityOrder === totalActivities;
  },

  generateFinalAssessmentQuestionsResults: (
    skill: SkillTypes,
    level: number,
    results: QuizQuestionResult[]
  ) => {
    const finalAssessmentQuestionsResults = results.map(result => {
      const { questionId, isCorrect, wrongAnswer, responseTime } = result;

      return {
        questionId,
        isCorrect,
        wrongAnswer,
        responseTime,
        skill,
        level,
      };
    }) as FinalAssessmentQuestionResult[];

    return finalAssessmentQuestionsResults;
  },
};

export default GameUtils;
