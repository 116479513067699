import { useNavigate } from 'react-router-dom';
import { progressService, skillService } from '@/services';
import { useAppDispatch, useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import { gameActions, gameSelector } from '@/store/reducers/game';
import commonUtils from '@/utils/common';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UseQuizTimer from '@/hooks/UseQuizTimer';

import {
  GameStatus,
  GameTypes,
  HintsType,
  NewGameTypes,
  PopUpEndOfSkillSummaryProps,
  PopUpFinalAssessmentSummaryProps,
  PopUpFocusProps,
  PopUpHintsProps,
  PopUpKeepGoingProps,
  PopUpPauseProps,
  PopUpSummaryProps,
  PopUpTimeIsUpProps,
  PopUpTypes,
  PopUpWordsListProps,
  QuizQuestionResult,
} from '@/types/game';
import GameUtils from '@/utils/gameUtils';
import UseTopicQuizzes from '@/hooks/UseTopicQuizzes';
import { SkillTypes } from '@/types/skill';
import UseScreenSize from '@/hooks/UseScreenSize';
import UseSkill from '../skill/UseSkill';
import levelTest, { levelTestActions, levelTestSelector } from '@/store/reducers/level-test';
import { Quiz } from '@/types/quiz';
import { Question } from '@/types/question';
import { Block } from '@/types/game';
import { appSettingsActions, appSettingsSelector } from '@/store/reducers/settings';
import questionsThunk from '@/store/thunks/questions';
import topicsThunks from '@/store/thunks/topics';
import quizzesThunks from '@/store/thunks/quizzes';
import UseUserProgress from '@/hooks/UseUserProgress';
import UseLevelTestGame from '@/hooks/UseLevelTestGame';
import UseInitialLevelTestGame from '@/hooks/UseInitialLevelTest';
import userThunks from '@/store/thunks/user';
import { TopicTypes, Topic } from '@/types/topic';
import { SkillSettingsItem } from '@/types/user';
import { endOfSkillActions } from '@/store/reducers/end-of-skill';
import useEndOfSkillTest from '@/hooks/UseEndOfSkillTest';
import { finalAssessmentActions, finalAssessmentSelector } from '@/store/reducers/final-assessment';
import { unitActions, unitSelector } from '@/store/reducers/unit';
import UserUtils from '@/utils/user';

export interface UseQuizInterface {
  loadError: boolean;
  savingQuizProgressData: boolean;
  loadQuizProgress: boolean;
  currentQuestion: any;
  selectedType: SkillTypes | null;
  selectedTopic: Topic | null;
  selectedSkill: SkillSettingsItem | null | undefined;
  selectedQuiz: Quiz | null;
  gameStatus: GameStatus;
  timesUpShown: boolean;
  focusShown: boolean;
  correctAnswers: number;
  inCorrectAnswers: number;
  currentQuestionIndex: number;
  gameQuestions: Question[];
  gameTypeReal: GameTypes | NewGameTypes | null;
  coins: number;
  selectedHint: HintsType | null;
  pickedAnswer: string | null;
  currentTime: number;
  shouldDisplayPopUp: boolean;
  popUpType: PopUpTypes | null;
  popUpProps:
    | PopUpKeepGoingProps
    | PopUpFocusProps
    | PopUpTimeIsUpProps
    | PopUpPauseProps
    | PopUpSummaryProps
    | PopUpHintsProps
    | PopUpWordsListProps
    | PopUpEndOfSkillSummaryProps
    | PopUpFinalAssessmentSummaryProps
    | null;
  isMuted: boolean;
  currentCoins: number | undefined;
  allowRetry: boolean;
  showPreQuiz: boolean;
  showAnswerInOptions: boolean;
  onClosePopUp: () => Promise<void>;
  onPause: () => Promise<void>;
  onMute: () => void;
  onStartQuiz: () => void;
  onPractice: () => void;
  onExit: () => Promise<void>;
  onDisableRetry: () => Promise<void>;
  onAknowledge: (id: number, isKnown: boolean) => void;
  onSwipe: (questionId: number) => void;
  goToNextQuestion: (force?: boolean) => void;
  toggleCheckAnswer: (answer: string) => Promise<void>;
  currentReadQuestionIndex: number;
  setCurrentQuestionTempData: (data: any, key?: string) => void;
  currentQuestionTempData: any;
}

const UseQuiz = (): UseQuizInterface => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user, skillSettings } = useAppSelector(authSelector);
  const isTeacher = UserUtils.isTeacher(user);
  const {
    selectedUnit,
    topics,
    selectedTopic: selectedTopicNew,
    units,
  } = useAppSelector(unitSelector);
  const { loadUserProgress } = UseUserProgress();
  const {
    selectedType,
    selectedTopic,
    selectedSkill,
    selectedQuiz,
    gameStatus,
    timesUpShown,
    focusShown,
    keepGoingShown,
    correctAnswers,
    total_correctAnswers,
    inCorrectAnswers,
    currentQuestionIndex,
    currentReadQuestionIndex,
    gameQuestions,
    coins,
    selectedHint,
    pickedAnswer,
    currentTime,
    isMuted,
    allowRetry,
    gameType: gameTypeReal,
    showPreQuiz,
    showAnswerInOptions,
    quizQuestionResultData,
    total_inCorrectAnswers,
    totalQuizTime,
    visitFocus,
    visitKeepGoing,
    hintsData,
  } = useAppSelector(gameSelector);
  //FIXME: NEED TO REPLACE WITH CORRECT GAMETYPE
  const gameType = gameQuestions[0]?.gameType as any;

  const { questions, quizzes } = useAppSelector(appSettingsSelector);

  const {
    quizzes: levelTestQuizzes,
    progress: levelTestProgress,
    currentQuizIndex: levelTestQuizIndex,
    currentOverAllQuizNumber,
    correctAnswers: levelTestCorrectAnswers,
    activity,
    questionIndex,
  } = useAppSelector(levelTestSelector);

  const {
    quizzes: finalAssessmentQuizzes,
    progress: finalAssessmentProgress,
    currentQuizIndex: finalAssessmentQuizIndex,
  } = useAppSelector(finalAssessmentSelector);

  const { startLevelTestGame } = UseLevelTestGame();
  const { startInitialLevelTestGame } = UseInitialLevelTestGame();
  const { handleStartEndOfSkillTest } = useEndOfSkillTest();

  UseQuizTimer();

  const { sortedQuizzes } = UseTopicQuizzes();

  const navigate = useNavigate();
  const { isDesktop } = UseScreenSize();
  const [savingQuizProgressData, setSavingQuizProgressData] = useState(false);
  const [loadQuizProgress, setLoadQuizProgress] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [shouldDisplayPopUp, setShouldDisplayPopUp] = useState(false);
  const [popUpType, setPopUpType] = useState<PopUpTypes | null>(null);
  const [popUpProps, setPopUpProps] = useState<
    | PopUpKeepGoingProps
    | PopUpFocusProps
    | PopUpTimeIsUpProps
    | PopUpPauseProps
    | PopUpSummaryProps
    | PopUpHintsProps
    | PopUpWordsListProps
    | PopUpEndOfSkillSummaryProps
    | PopUpFinalAssessmentSummaryProps
    | null
  >(null);

  const [isFinalAssessmentFinished, setIsFinalAssessmentFinished] = useState(false);

  const [currentQuestionTempData, setCurrentQuestionTempDataState] = useState<any>({});
  const currentQuestionTempDataRef = useRef<any>({});

  const currentCoins = user?.progress?.coins;

  // const { topics } = UseSkill();

  const { isLevelTest, isFinalAssessment } = useAppSelector(gameSelector);

  const isInEndOfSkillTest = selectedTopic?.topicType === TopicTypes.EndOfSkillTest;

  const isRead = gameType === NewGameTypes.Read;
  const isSpeak = selectedType === SkillTypes.Speak;

  const ignoreFocusTypes = [
    GameTypes.ClosedSpelling,
    GameTypes.Pronunciation,
    NewGameTypes.NaturalConversation,
  ];

  //if the current quiz number is 10, 20, 30, show keep going modal
  const levelTestShowKeepGoing = [10, 20, 30].includes(currentOverAllQuizNumber);

  //Check the current quiz number and show keep going modal
  useEffect(() => {
    if (levelTestShowKeepGoing) {
      showKeepGoing();
    }
  }, [currentOverAllQuizNumber]);

  const getNextTopic = () => {
    const currentTopics = topics[selectedUnit?.id || 1];

    const currentTopic = currentTopics.find(topic => topic.id === selectedTopic?.id);

    if (!currentTopic) return null;

    if (currentTopic?.order >= currentTopics.length) {
      return null;
    }

    const nextTopic = currentTopics.find(topic => topic.order === currentTopic.order + 1);

    //FIXME: Temporary solution for setSelectedTopic structure
    const mockNextTopic = {
      id: nextTopic?.id || 0,
      nameLocal: null,
      name: nextTopic?.name,
      order: nextTopic?.order,
      quizzesCount: 1,
      topicType: 1,
      activitiesInTopic: nextTopic?.activitiesInTopic,
      userData: {
        receivedStars: 8,
        quizzesCompleted: 3,
      },
    };

    //FIXME: Need to use "nextTopic" instead
    return { oldData: mockNextTopic, newData: nextTopic };
  };

  const getTopicQuizzes = async (topicId: number): Promise<Quiz[]> => {
    const topicQuizzes =
      quizzes[topicId] ?? (await dispatch(quizzesThunks.refreshTopicQuizzes(topicId)).unwrap());

    const _sortedQuizzes = commonUtils.sortByKey([...topicQuizzes], 'order');

    return _sortedQuizzes;
  };

  const getNextTopicQuiz = async () => {
    const currentQuizIndex = sortedQuizzes.findIndex(quiz => quiz.id === selectedQuiz?.id);

    const nextQuiz = sortedQuizzes[currentQuizIndex + 1];

    //FIXME: Need to remove. Old functionality
    // // Go to next topic and first quiz
    // if (currentQuizIndex >= sortedQuizzes.length - 1) {
    //   const nextTopic = getNextTopic();

    //   if (!nextTopic) {
    //     //|| !nextTopic.id || nextTopic.topicType === TopicTypes.EndOfSkillTest
    //     return null;
    //   }

    //   const nextTopicQuizzes = await getTopicQuizzes(nextTopic?.newData?.id || 0);

    //   //FIXME: Need to use "newTopic" instead of "topic"
    //   return { topic: nextTopic.oldData, quiz: nextTopicQuizzes[0], newTopic: nextTopic.newData };
    // }
    //FIXME: Need to use "newTopic" instead of "topic"
    return { topic: selectedTopic, quiz: nextQuiz, newTopic: selectedTopicNew };
  };

  const showFocusModal = () => {
    dispatch(gameActions.setFocusShown());
    dispatch(gameActions.toggleShowFocus(false));

    const props = {
      totalQuestions: gameQuestions.length,
      inCorrectAnswers,
      canClickOutside: false,
      emitOnClosePopUp: async () => {
        onClosePopUp();
        await commonUtils.sleep(400);

        goToNextQuestion(true);
      },
    } as PopUpFocusProps;

    displayPopUp(PopUpTypes.Focus, props);

    dispatch(gameActions.resetCorrectAnswers());
    dispatch(gameActions.resetIncorrectAnswers());
  };

  const showKeepGoing = () => {
    dispatch(gameActions.setKeepGoingShown());
    dispatch(gameActions.toggleShowKeepGoing(false));

    const questionsLeft = gameQuestions.slice(currentQuestionIndex + 1).length;

    const props = {
      questionsLeft,
      coins,
      canClickOutside: false,
      emitOnClosePopUp: async () => {
        onClosePopUp();
        await commonUtils.sleep(400);
        !isLevelTest && goToNextQuestion(true);
      },
    } as PopUpKeepGoingProps;

    displayPopUp(PopUpTypes.KeepGoing, props);
    if (!isLevelTest) {
      dispatch(gameActions.resetCorrectAnswers());
      dispatch(gameActions.resetIncorrectAnswers());
    }
  };

  const fetchQuizProgress = async (topicId: number, quizId: number) => {
    if (
      selectedType &&
      !isLevelTest &&
      selectedQuiz &&
      user &&
      selectedTopic &&
      !isInEndOfSkillTest &&
      !isFinalAssessment &&
      quizId
    ) {
      try {
        setLoadQuizProgress(true);

        let _questions =
          questions[`${topicId}-${quizId}`] ??
          (await dispatch(questionsThunk.refreshTopicQuizQuestions()).unwrap());

        if (isRead) {
          dispatch(gameActions.resetGame());

          _questions = _questions.map(question => {
            return {
              ...question,
              pickedAnswer: '',
              options: GameUtils.getPossibleAnswers(question),
            };
          });
        }

        //FIXME: Need to understand what should we use in this whitelist. Now only SkillTypes.Grammar is available, because it goes by default
        const whitelist = [
          SkillTypes.Vocabulary,
          SkillTypes.Read,
          SkillTypes.Speak,
          SkillTypes.Listen,
          SkillTypes.Grammar,
        ];

        dispatch(gameActions.resetGame());
        dispatch(gameActions.setShowPreQuiz(whitelist.includes(selectedType)));

        dispatch(
          gameActions.startGame({
            gameType: selectedQuiz.gameType ?? 1,
            questions: _questions,
          })
        );

        setLoadQuizProgress(false);
      } catch (error) {
        console.log(error);
        setLoadQuizProgress(false);
        setLoadError(true);
        commonUtils.showToast(t('anErrorOccurred'));
      }
    }
  };

  const onReset = () => {
    dispatch(gameActions.setInEndOfSkillTest(false));
    dispatch(endOfSkillActions.resetCurrentReadQuizIndex());
    dispatch(gameActions.setSelectedQuiz(null));
    dispatch(gameActions.resetGame());
  };

  const currentQuestion = useMemo(() => {
    return isLevelTest && activity
      ? activity.questions[questionIndex]
      : gameQuestions[currentQuestionIndex];
  }, [currentQuestionIndex, gameQuestions, questionIndex]);

  const setCurrentQuestionTempData = useCallback((data: any, key?: string) => {
    if (key) {
      currentQuestionTempDataRef.current[key] = data;
    } else {
      currentQuestionTempDataRef.current = data;
    }
    setCurrentQuestionTempDataState({ ...currentQuestionTempDataRef.current });
  }, []);

  useEffect(() => {
    if (!showPreQuiz && currentQuestion) {
      if (currentQuestion?.gameType === GameTypes.ClosedSpelling) {
        let options: Block[] = [...currentQuestion.answer].map(chart => {
          return {
            id: commonUtils.generateRandomId(21),
            value: chart,
            isDisabled: false,
            isRemovable: false,
          };
        });
        const answerBlocks: Block[] = Array.from(Array(options.length)).fill(null);
        const optionBlocks: Block[] = [];
        let abcArray = [...Array(26)].map((_, i) => String.fromCharCode(i + 97));

        options.forEach((option, index) => {
          abcArray = abcArray.filter(abcChart => abcChart !== option.value);
          if (option.value === ' ') {
            answerBlocks[index] = { ...option, isDisabled: true };
          }
        });

        options = options.filter(option => option.value !== ' ');

        while (options.length < 12) {
          options.push({
            id: commonUtils.generateRandomId(21),
            value: commonUtils.getAndRemoveRandomItem(abcArray),
            isDisabled: false,
            isRemovable: true,
          });
        }

        while (options.length > 0) {
          optionBlocks.push(commonUtils.getAndRemoveRandomItem(options));
        }

        const refreshAnswerBlocks = structuredClone(answerBlocks);

        setCurrentQuestionTempData({
          refreshAnswerBlocks,
          answerBlocks,
          optionBlocks,
        });

        return;
      }

      if (currentQuestion?.gameType === NewGameTypes.Memory) {
        const gameCards = currentQuestion.newData?.data?.questionData?.pairs || [];
        const imagesAlt = currentQuestion.imagesAlt || {};

        const result: any[] = [];
        gameCards.forEach((element: any, idx: number) => {
          result.push({
            data: element.text,
            index: idx,
            isMatched: false,
            isSelected: false,
          });
          result.push({
            data: element.image,
            imageText: element.imageSource || element.imageCredit,
            imageAlt: imagesAlt[element.image] || '',
            index: idx,
            isMatched: false,
            isSelected: false,
          });
        });

        const quizData: any[] = commonUtils.shuffleArray(result);

        setCurrentQuestionTempData({ quizData });

        return;
      }

      if (currentQuestion?.gameType === NewGameTypes.Read) {
        return;
      }
    }

    setCurrentQuestionTempData({});
  }, [currentQuestion, showPreQuiz]);

  const checkAnswer = async () => {
    const currentAnswer = isLevelTest
      ? currentQuestion.data.questionData.answer.text
      : currentQuestion.answer;
    let isAnswerCorrect = pickedAnswer == currentAnswer;
    const isListen = selectedType == SkillTypes.Listen;

    if (
      pickedAnswer &&
      (gameType === GameTypes.Pronunciation ||
        (gameTypeReal === NewGameTypes.NaturalConversation &&
          gameQuestions[currentQuestionIndex]?.newData?.data?.metadata?.skillId ===
            GameTypes.Pronunciation))
    ) {
      const lowerCaseQuestion = currentQuestion.question?.toLowerCase();
      const lowerCasePickedAnswer = pickedAnswer.toLocaleLowerCase();

      const isSimilar = commonUtils.isSimilar(lowerCaseQuestion, lowerCasePickedAnswer);

      isAnswerCorrect = isSimilar;
    }

    if (
      (gameType !== GameTypes.ClosedSpelling &&
        gameType !== GameTypes.Pronunciation &&
        (!isListen || isAnswerCorrect || !allowRetry)) ||
      (isListen && (isLevelTest || isFinalAssessment))
    ) {
      await commonUtils.sleep(1500); // Wait before checking
    }

    if (!selectedQuiz && !isInEndOfSkillTest && !isLevelTest) {
      return;
    }

    let visitFocus = false;
    let visitKeepGoing = false;

    const displayFocusAfterX = GameUtils.displayFocusAfterX(selectedQuiz as Quiz);
    const displayKeepGoingAfterX = GameUtils.displayKeepGoingAfterX(selectedQuiz as Quiz);

    const displayActionModals = GameUtils.shouldDisplayActionModals(gameQuestions ?? []);

    if (
      displayActionModals &&
      displayFocusAfterX !== null &&
      displayFocusAfterX > 0 &&
      inCorrectAnswers >= displayFocusAfterX
    ) {
      visitFocus = true;
    }

    if (
      displayActionModals &&
      displayKeepGoingAfterX != null &&
      displayKeepGoingAfterX > 0 &&
      correctAnswers >= displayKeepGoingAfterX
    ) {
      visitKeepGoing = true;
    }

    if (visitKeepGoing && visitFocus) {
      visitKeepGoing = false;
    }
    if (
      visitFocus &&
      !focusShown &&
      !GameUtils.isOneQuestionLeft(currentQuestionIndex, gameQuestions)
    ) {
      if (ignoreFocusTypes.includes(gameType) || (isListen && allowRetry)) {
        dispatch(gameActions.toggleShowFocus(true));
      } else {
        showFocusModal();
        return;
      }
    } else if (focusShown && total_inCorrectAnswers > (displayFocusAfterX ?? 0)) {
      dispatch(gameActions.endGame());
      return;
    }

    if (
      visitKeepGoing &&
      !keepGoingShown &&
      !GameUtils.isOneQuestionLeft(currentQuestionIndex, gameQuestions)
    ) {
      if (ignoreFocusTypes.includes(gameType) || (isListen && allowRetry)) {
        dispatch(gameActions.toggleShowKeepGoing(true));
      } else {
        showKeepGoing();
        return;
      }
    }

    if (isListen && !isAnswerCorrect && (isInEndOfSkillTest || isFinalAssessment)) {
      await commonUtils.sleep(1500);
    }

    if (
      isListen &&
      !isAnswerCorrect &&
      !isLevelTest &&
      !isInEndOfSkillTest &&
      !isFinalAssessment &&
      allowRetry
    ) {
      dispatch(gameActions.setPictureOverlay(true));
      return;
    }

    if (isLevelTest) {
      const answer = {
        questionId: currentQuestion.questionId,
        isCorrect: isAnswerCorrect,
        wrongAnswer: isAnswerCorrect ? null : pickedAnswer,
        isTimesUp: false,
        responseTime: 0,
        gameTypeId: currentQuestion.gameTypeId,
      };
      dispatch(levelTestActions.setAnswer(answer));
    }

    dispatch(gameActions.setCheckAnswerCompleted(true));

    //FIXME: Need to remove, because we don't use SkillTypes
    // if (selectedType == SkillTypes.Spelling || selectedType == SkillTypes.Speak) {
    //   return;
    // }
    if (
      gameType == GameTypes.ClosedSpelling ||
      gameType == GameTypes.Pronunciation ||
      (gameTypeReal === NewGameTypes.NaturalConversation &&
        gameQuestions[currentQuestionIndex]?.newData?.data?.metadata?.skillId ===
          GameTypes.Pronunciation)
    ) {
      return;
    }

    const isLast = currentQuestionIndex === gameQuestions.length - 1;

    if (isLevelTest && isRead) {
      return;
    } else if (isRead && isLast) {
      return;
    }
    if (isLevelTest) {
      startInitialLevelTestGame(questionIndex + 1);
    }

    goToNextQuestion();
  };

  const displayPopUp = (
    type: PopUpTypes,
    props: PopUpKeepGoingProps | PopUpFocusProps | PopUpTimeIsUpProps | PopUpSummaryProps
  ) => {
    setPopUpType(type);
    setPopUpProps(props);
    setShouldDisplayPopUp(true);
  };

  const onMute = () => {
    dispatch(gameActions.toggleGameMute());
  };

  const onPause = async () => {
    if (
      gameStatus === GameStatus.ENDED ||
      gameStatus === GameStatus.TIME_ENDED ||
      gameStatus === GameStatus.SHOW_HINTS ||
      gameStatus === GameStatus.SHOW_DICTIOANRY ||
      gameStatus === GameStatus.PREPARING
    ) {
      return;
    }

    if (
      gameStatus === GameStatus.PLAYING ||
      gameStatus === GameStatus.QUESTION_ANSWERED ||
      gameStatus === GameStatus.CHECK_ANSWER
    ) {
      dispatch(
        gameActions.onPause({
          simple:
            gameStatus === GameStatus.QUESTION_ANSWERED || gameStatus === GameStatus.CHECK_ANSWER,
        })
      );
    } else {
      dispatch(gameActions.onPlay());
    }
  };

  const onClosePopUp = async () => {
    setShouldDisplayPopUp(false);
    setPopUpType(null);
    setPopUpProps(null);

    if (
      popUpType === PopUpTypes.Pause ||
      popUpType === PopUpTypes.Hints ||
      popUpType === PopUpTypes.WordsList
    ) {
      await commonUtils.sleep(400);
      dispatch(gameActions.onPlay());
    }
  };

  const closeHintPopup = async () => {
    onClosePopUp();
    dispatch(gameActions.setSelectedHint({ hint: null, soundPath: '' }));
    dispatch(gameActions.onPlay());
  };

  const addCoins = (coins: number) => {
    const currentCoins = user?.progress?.coins ?? 0;

    dispatch(authActions.setUserCoins(currentCoins + coins));
  };

  const onHintClick = async (hint: HintsType) => {
    const soundPath = gameQuestions[currentQuestionIndex].dictionaryDetails?.audio || '';

    addCoins(user?.metadata.role === 'teacher' ? -0 : -5);

    dispatch(gameActions.setSelectedHint({ hint, soundPath }));

    closeHintPopup();
  };
  const goToNextQuestion = (force = false) => {
    if (!force && gameType !== NewGameTypes.NaturalConversation) {
      if (visitFocus) {
        showFocusModal();
        return;
      }
      if (visitKeepGoing && !visitFocus) {
        showKeepGoing();
        return;
      }
    }

    dispatch(
      gameActions.goToNextQuestion({
        quizzesLength: levelTestQuizzes?.length ?? 0,
        levelTestQuizIndex: levelTestQuizIndex,
      })
    );
  };

  const toggleCheckAnswer = async (answer: string) => {
    dispatch(
      gameActions.handlePickedAnswer({
        answer,
      })
    );

    let showAnswer = true;

    if (
      selectedType === SkillTypes.Listen &&
      allowRetry &&
      !isLevelTest &&
      !isInEndOfSkillTest &&
      !isFinalAssessment
    ) {
      showAnswer = false;
    }

    if (showAnswer && isLevelTest) {
      await commonUtils.sleep(500);
      dispatch(gameActions.setShowAnswerInOptions());
    }
    if (
      isLevelTest &&
      questionIndex === 9 &&
      activity.title === 'INITIAL_LEVEL_TEST_LEVEL_4' &&
      levelTestCorrectAnswers >= 6
    ) {
      const modalProps = {
        emitOnClosePopUp: async () => {
          setShouldDisplayPopUp(false);
          setPopUpType(null);
          setPopUpProps(null);
        },
      } as PopUpSummaryProps;
      displayPopUp(PopUpTypes.KeepGoing, modalProps);
    }
  };

  const buildEndGameBody = () => {
    const finishTime = new Date();

    if (gameTypeReal === GameTypes.Practice) {
      return buildPracticeEndGameBody(finishTime);
    }

    let scoreEarned = 0;
    let noGrading = false;
    let achievedCoins = coins;
    const isCompletedBefore = selectedQuiz?.userData?.isCompleted ?? false;

    if (gameTypeReal === NewGameTypes.OpenWriting) {
      achievedCoins = isCompletedBefore ? 0 : quizQuestionResultData.length * 3;
      const gradedResults = quizQuestionResultData.filter(
        result => result.openWritingResult?.scoreResponse?.noGrading !== true
      );
      const scores: number[] = gradedResults
        .map(result => result.openWritingResult?.scoreResponse?.overallScore)
        .filter((score): score is number => typeof score === 'number');
      const totalScores = scores.reduce((sum, score) => sum + score, 0);
      scoreEarned = scores.length > 0 ? commonUtils.roundNumber(totalScores / scores.length, 0) : 0;
      if (gradedResults === undefined || gradedResults.length == 0) {
        noGrading = true;
      }
    } else {
      scoreEarned = +commonUtils.calcPercentage(
        total_correctAnswers ?? 0,
        gameQuestions.length ?? 0
      );
    }

    const isSpeak = gameTypeReal === NewGameTypes.OpenWriting;

    const stars = noGrading || isSpeak ? 3 : GameUtils.getStarsAmount(scoreEarned);
    const isCompletedNow = noGrading ? true : scoreEarned >= 60;
    const isCompleted = isCompletedBefore || isCompletedNow;
    const quizQuestionResultDataIds = quizQuestionResultData.map(question => question.questionId);

    const remainingQuestionBuild: QuizQuestionResult[] = [];

    const isLastQuizInTopic = GameUtils.isLastActivity(
      selectedQuiz?.order,
      selectedTopic?.activitiesInTopic
    );

    const topicsLength = topics[selectedUnit?.id || 1].length;
    const isLastTopic = selectedTopic?.id === topics[selectedUnit?.id || 1][topicsLength - 1].id;

    const isLastQuizInTheLastTopic = isLastTopic && isLastQuizInTopic;
    const isLastUnit = selectedUnit.order === units.length;
    const IsLastActivityInLevel = isLastQuizInTheLastTopic && isLastUnit;
    const remainingQuestion: Question[] = gameQuestions.filter(
      question => !quizQuestionResultDataIds.includes(question.questionId)
    );

    if (remainingQuestion.length > 0) {
      remainingQuestion.forEach((question, index) => {
        remainingQuestionBuild.push({
          questionId: question.questionId,
          isCorrect: false,
          isTimesUp: true,
          wrongAnswer: null,
          responseTime: 0,
          achievedCoins: 0,
          coinsUsed: hintsData[index].coinsUsed,
          hintUsageCount: hintsData[index].hintUsageCount,
          gameType: question.gameType,
          isQuestionPresented: false,
        });
      });
    }

    const quizQuestionResults = quizQuestionResultData.map(question => {
      return {
        ...question,
        isQuestionPresented: true,
      };
    });

    const content = {
      questions: [...quizQuestionResults, ...remainingQuestionBuild],
      activityId: selectedQuiz?.id,
      topicId: selectedTopic?.id,
      unitId: selectedUnit?.id,
      correctAnswers: total_correctAnswers,
      wrongAnswers: total_inCorrectAnswers,
      isCompleted: isCompleted,
      achievedStars: stars,
      achievedCoins: achievedCoins,
      coinsUsed: hintsData
        .map(data => data.coinsUsed)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
      hintUsageCount: hintsData
        .map(data => data.hintUsageCount)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
      questionsAnswered: total_correctAnswers + total_inCorrectAnswers,
      score: scoreEarned,
      startTime: totalQuizTime,
      finishTime: finishTime.toISOString(),
      isLastActivityInTopic: isLastQuizInTopic,
      isLastActivityInUnit: isLastQuizInTheLastTopic,
      IsLastActivityInLevel: IsLastActivityInLevel,
      //FIXME: Need to fix any type
      isPracticeMode: gameTypeReal === (GameTypes.Practice as any),
      user: {
        groupId: user?.metadata?.organizationDetails?.groupId,
        organizationId: user?.metadata?.organizationDetails?.organizationId,
        userLevel: user?.progress?.userLevel,
        userNativeLanguage: user?.metadata?.languagePreferences?.nativeLanguage,
        userInterfaceLanguage: user?.metadata?.languagePreferences?.interfaceLanguage,
      },
    };

    return content;
  };

  const buildPracticeEndGameBody = (finishTime: Date) => {
    const practiceQuestions: any = [];

    gameQuestions.map(question => {
      practiceQuestions.push({
        questionId: question.questionId,
        achievedCoins: 0,
        isCorrect: true,
        wrongAnswer: null,
        isTimesUp: true,
        hintUsageCount: 0,
        coinsUsed: 0,
        responseTime: 0,
        gameType: question.gameType,
        isQuestionPresented: true,
        practice: question.userData?.practice?.isKnown
          ? {
              isKnown: question.userData?.practice?.isKnown,
            }
          : null,
      });
    });

    const practiceContent = {
      questions: practiceQuestions,
      activityId: selectedQuiz?.id,
      topicId: selectedTopic?.id,
      unitId: selectedUnit?.id,
      correctAnswers: 0,
      wrongAnswers: 0,
      isCompleted: false,
      achievedStars: 0,
      achievedCoins: 0,
      coinsUsed: 0,
      hintUsageCount: 0,
      questionsAnswered: 0,
      score: 0,
      startTime: totalQuizTime,
      finishTime: finishTime.toISOString(),
      isLastActivityInTopic: false,
      isLastActivityInUnit: false,
      IsLastActivityInLevel: false,
      //FIXME: Need to fix any type
      isPracticeMode: gameTypeReal === (GameTypes.Practice as any),
      user: {
        groupId: user?.metadata?.organizationDetails?.groupId,
        organizationId: user?.metadata?.organizationDetails?.organizationId,
        userLevel: user?.progress?.userLevel,
        userNativeLanguage: user?.metadata?.languagePreferences?.nativeLanguage,
        userInterfaceLanguage: user?.metadata?.languagePreferences?.interfaceLanguage,
      },
    };

    return practiceContent;
  };

  //FIXME: NEED TO SPLIT THIS FUNCTION TO SMALL ONE. IT'S TOO COMPLICATED
  const reactToGameStatus = async () => {
    if (gameStatus === GameStatus.CHECK_ANSWER) {
      await checkAnswer();
    } else if (gameStatus === GameStatus.ENDED) {
      if (isLevelTest && selectedQuiz) {
        startLevelTestGame(levelTestQuizIndex + 1);
        return;
      }
      if (isInEndOfSkillTest) {
        const percentage = commonUtils.calcPercentage(
          total_correctAnswers,
          quizQuestionResultData.length
        );

        const result = {
          questions: quizQuestionResultData.map(question => {
            return {
              questionId: question.questionId,
              isCorrect: question.isCorrect,
              wrongAnswer: question.wrongAnswer,
              responseTime: question.responseTime,
            };
          }),
          level: 1,
          skill: selectedType,
          correctAnswers: total_correctAnswers,
          wrongAnswers: total_inCorrectAnswers,
          isFailed: percentage < 80,
          questionAnswered: quizQuestionResultData.length,
          score: percentage,
          startTime: GameUtils.formatDate(totalQuizTime),
          finishTime: GameUtils.formatDate(new Date()),
        };

        let finalAssessmentResults = null;

        if (selectedType) {
          finalAssessmentResults = await skillService.postEndOfSkillTest(
            user,
            selectedType,
            // TODO: User Level
            1,
            result
          );
        }

        const _selectedSkill = selectedSkill;
        const _selectedTopic = selectedTopic;
        const _selectedQuiz = selectedQuiz;

        const props: PopUpEndOfSkillSummaryProps = {
          selectedSkill,
          selectedTopic,
          selectedQuiz,
          correctAnswers: total_correctAnswers,
          totalQuestions: quizQuestionResultData.length,
          coins,
          userData: _selectedQuiz?.userData,
          assessmentResult: finalAssessmentResults,
          emitOnClosePopUp: async (hasFailed: boolean) => {
            onClosePopUp();
            await commonUtils.sleep(400);

            if (hasFailed && _selectedQuiz && _selectedTopic) {
              handleStartEndOfSkillTest(_selectedTopic);
            }
          },
          emitOnExit: onExitSummaryTest,
          emitOnQuit: onQuit,
        } as PopUpEndOfSkillSummaryProps;

        displayPopUp(PopUpTypes.EndOfSkillSummary, props);
        return;
      } else if (isFinalAssessment) {
        // TODO: User Level
        const currentLevel = 1;

        const newProgress = {
          questions: GameUtils.generateFinalAssessmentQuestionsResults(
            selectedType!,
            currentLevel,
            quizQuestionResultData
          ),
          correctAnswers: total_correctAnswers,
          wrongAnswers: total_inCorrectAnswers,
          questionAnswered: quizQuestionResultData.length,
        };

        const nextFinalAssessmentQuiz = finalAssessmentQuizzes?.[finalAssessmentQuizIndex + 1];

        // SAVE QUIZ RESULTS:
        dispatch(finalAssessmentActions.updateProgress(newProgress));

        if (nextFinalAssessmentQuiz) {
          dispatch(finalAssessmentActions.increaseCurrentQuizIndex());

          if (!isSpeak) {
            await commonUtils.sleep(1000);
          }

          dispatch(gameActions.resetGame());

          const type = nextFinalAssessmentQuiz.quiz.skill as SkillTypes;
          const skill = skillSettings.find(i => i.name?.toLocaleLowerCase() === SkillTypes.Grammar);

          dispatch(gameActions.setSelectedSkill({ type, skill }));
          dispatch(gameActions.setSelectedQuiz(nextFinalAssessmentQuiz.quiz));
          dispatch(
            gameActions.startGame({
              gameType: nextFinalAssessmentQuiz.quiz.gameType,
              questions: nextFinalAssessmentQuiz.questions,
            })
          );

          return;
        }

        dispatch(finalAssessmentActions.finishProgress());
        setIsFinalAssessmentFinished(true);

        return;
      }

      const _selectedSkill = selectedSkill;
      const _selectedTopic = selectedTopic;
      const _selectedQuiz = selectedQuiz;

      dispatch(authActions.refreshUserProgress());

      dispatch(
        appSettingsActions.clearTopicQuizzes({
          topicId: Number(selectedTopic?.id),
        })
      );

      dispatch(
        appSettingsActions.clearQuestionsProgress({
          topicId: Number(selectedTopic?.id),
          quizId: Number(selectedQuiz?.id),
        })
      );

      if (!isLevelTest && !isTeacher) {
        setSavingQuizProgressData(true);
        try {
          const quizData = buildEndGameBody();

          //FIXME: NEED TO REPLACE WITH CORRECT GAMETYPE
          const gameType = gameQuestions[0]?.gameType as any;

          const progressData = await progressService.sendQuizProgressData(
            user,
            _selectedSkill?.name ?? '',
            _selectedTopic?.id!,
            _selectedQuiz!.id,
            gameType!,
            quizData
          );

          const streak = progressData?.data.data.streak ?? [];

          // Update new streak response
          dispatch(authActions.setStreak(streak));

          await commonUtils.sleep(1000);
          await loadUserProgress();

          //FIXME: Old functionality, need to be removed. + Meed to remove all services and thunks
          // Refresh skill topics
          // await dispatch(topicsThunks.refreshSkillTopics()).unwrap();

          // Refresh skill quizzes
          await dispatch(quizzesThunks.refreshTopicQuizzes()).unwrap();
        } catch (error) {
          console.log(error);
        }
        setSavingQuizProgressData(false);
      }

      const nextTopicQuiz = await getNextTopicQuiz();

      const isLastActivity = GameUtils.isLastActivity(
        selectedQuiz?.order,
        selectedTopic?.activitiesInTopic
      );

      const props = {
        selectedSkill,
        selectedTopic,
        selectedQuiz,
        correctAnswers: total_correctAnswers,
        totalQuestions: gameQuestions.length,
        coins,
        hasNextQuiz: !isLastActivity,
        userData: _selectedQuiz?.userData,
        emitOnClosePopUp: async (hasFailed: boolean) => {
          onClosePopUp();
          if (hasFailed && _selectedQuiz && _selectedTopic) {
            dispatch(gameActions.resetGame());

            //FIXME: Need to be removed
            // if (isRead) {
            //   dispatch(gameActions.setShowPreQuiz(true));
            // }

            fetchQuizProgress(_selectedTopic.id, _selectedQuiz.id);
          } else if (nextTopicQuiz) {
            onReset();
            dispatch(gameActions.setSelectedQuiz(nextTopicQuiz.quiz));
            dispatch(gameActions.setSelectedTopic(nextTopicQuiz.topic as any));
            dispatch(
              unitActions.setSelectedTopic({
                id: nextTopicQuiz.newTopic?.id || 0,
                name: nextTopicQuiz.newTopic?.name,
                cEFRDescriptor: nextTopicQuiz.newTopic?.cEFRDescriptor,
                canDoStatement: nextTopicQuiz.newTopic?.canDoStatement,
              })
            );

            fetchQuizProgress(nextTopicQuiz?.topic?.id ?? 0, nextTopicQuiz.quiz.id);
            await commonUtils.sleep(1000);
          } else {
            await commonUtils.sleep(400);
            navigate('/dashboard/games');
          }
        },
        emitOnExit: onExit,
        emitOnExitWithCanDo: () => {
          if (isLastActivity) {
            const props = {
              emitOnExit: onExit,
              selectedTopic,
              selectedQuiz,
              quizzes,
            };
            displayPopUp(PopUpTypes.CanDoStatement, props);
          } else onExit();
        },
        emitOnQuit: onQuit,
        emitOnAknowledge: onAknowledge,
        emitOnEndOfSkillTest: async () => {
          const nextTopic = getNextTopic();

          if (nextTopic) {
            await handleStartEndOfSkillTest(nextTopic.oldData as any, false);

            onClosePopUp();
          }
        },
        emitStartQuizFromPractice: () => {
          onClosePopUp();
          dispatch(gameActions.resetGame());
          if (_selectedQuiz) {
            dispatch(
              gameActions.startGame({
                gameType: _selectedQuiz.gameType,
                questions: gameQuestions,
              })
            );
          }
        },
        emitPracticeAgain: () => {
          onClosePopUp();
          dispatch(gameActions.resetGame());

          if (_selectedQuiz) {
            dispatch(
              gameActions.startGame({
                gameType: +selectedQuiz.gameType,
                questions: gameQuestions,
              })
            );
          }
          dispatch(gameActions.setPracticeGame());
        },
      } as PopUpSummaryProps;

      addCoins(coins);
      if (!isLevelTest) {
        displayPopUp(PopUpTypes.Summary, props);
      }
    } else if (gameStatus === GameStatus.TIME_ENDED) {
      dispatch(gameActions.setRevealTranslation(false));
      // if (selectedType === SkillTypes.Listen && allowRetry) {
      //   dispatch(gameActions.setPictureOverlay(true));
      //   return;
      // }

      // here
      if (!timesUpShown) {
        dispatch(gameActions.setTimesUpShown());
      }

      // Show times up modal

      const props = {
        emitOnClosePopUp: async () => {
          onClosePopUp();
          onDisableRetry();
        },
        isLastQuestion: GameUtils.isLastQuestion(gameQuestions, currentQuestionIndex),
        allowRetry: allowRetry,
        emitOnNextQuestion: async () => {
          console.log('next question');
          // dispatch(gameActions.incrementIncorrectAnswers());
          onClosePopUp();
          await commonUtils.sleep(400);

          goToNextQuestion();
        },
      } as PopUpTimeIsUpProps;

      displayPopUp(PopUpTypes.TimeIsUp, props);
    } else if (gameStatus === GameStatus.PAUSED || gameStatus === GameStatus.PAUSED_SIMPLE) {
      const props = {
        currentQuestion: currentQuestionIndex,
        totalQuestions: gameQuestions.length,
        coins,
        emitOnClosePopUp: async () => {
          onClosePopUp();
          await commonUtils.sleep(400);

          dispatch(gameActions.onPlay());
        },
        emitOnQuit: onExit,
      } as PopUpPauseProps;
      displayPopUp(PopUpTypes.Pause, props);
    } else if (gameStatus === GameStatus.SHOW_HINTS) {
      const props = {
        coins: currentCoins,
        emitOnSelectHint: onHintClick,
        emitOnClosePopUp: async () => {
          onClosePopUp();
          await commonUtils.sleep(400);
          dispatch(gameActions.onPlay());
        },
      } as PopUpHintsProps;

      displayPopUp(PopUpTypes.Hints, props);
    } else if (gameStatus === GameStatus.SHOW_WORDS_LIST) {
      const props = {
        emitOnClosePopUp: async () => {
          onClosePopUp();
          await commonUtils.sleep(400);
          dispatch(gameActions.onPlay());
        },
        emitOnAknowledge: onAknowledge,
      } as PopUpWordsListProps;

      displayPopUp(PopUpTypes.WordsList, props);
    } else if (gameStatus === GameStatus.OPEN_WRITING_AI_REVIEW) {
      const props = {
        selectedTopic,
        emitOnNextQuestion: async () => {
          onClosePopUp();
          await commonUtils.sleep(400);
          // dispatch(gameActions.onPlay());
          goToNextQuestion(true);
        },
        emitOnRetry: async () => {
          onClosePopUp();
          await commonUtils.sleep(400);
          dispatch(gameActions.onPlay());
        },
        emitOnQuit: onExit,
        //FIXME: Need to change any for needed type
      } as any;

      displayPopUp(PopUpTypes.OpenWrittingAIReview, props);
    } else if (gameStatus === GameStatus.FINISH_UNIT) {
      const props = {
        emitOnQuit: onExit,
      } as any;

      displayPopUp(PopUpTypes.FinishUnit, props);
    }
  };

  const onDisableRetry = async () => {
    dispatch(gameActions.disableRetry());
    await commonUtils.sleep(400);
    dispatch(
      //FIXME: NEED TO REPLACE when newData will be changed
      gameActions.resetTimer(+gameQuestions[currentQuestionIndex].newData.data.metadata.timer)
    );

    // Deduct total incorrect answers and incorrect answers
    // Remove question result data from array
    // Because they are retrying.
    if (
      //FIXME: Need to remove SkillTypes, because we don't use it
      // selectedType === SkillTypes.Spelling ||
      // selectedType === SkillTypes.Listen ||
      // selectedType === SkillTypes.Speak
      selectedType === SkillTypes.Listen ||
      gameType === GameTypes.ClosedSpelling ||
      gameType === GameTypes.Pronunciation ||
      (gameTypeReal === NewGameTypes.NaturalConversation &&
        gameQuestions[currentQuestionIndex]?.newData?.data?.metadata?.skillId ===
          GameTypes.Pronunciation)
    ) {
      dispatch(gameActions.resetCurrentQuestionState());
    }
  };

  const onQuit = async () => {
    onClosePopUp();
    dispatch(gameActions.resetAllSelected());
    onReset();
    navigate('/dashboard/games');
  };

  const onExit = async () => {
    onClosePopUp();
    onReset();
    navigate('/dashboard/games');
  };

  const onExitSummaryTest = async () => {
    window.location.href = `/dashboard/skill/${selectedType}`;
  };

  const onStartQuiz = () => {
    dispatch(gameActions.setShowPreQuiz(false));
  };

  const onPractice = () => {
    onStartQuiz();

    if (user?.metadata.languagePreferences.fromToLanguageParams) {
      dispatch(
        gameActions.setFromToLang(user?.metadata.languagePreferences.fromToLanguageParams[0])
      );
    }

    if (!loadQuizProgress) {
      dispatch(gameActions.setPracticeGame());
    }
  };

  const onAknowledge = (id: number, isKnown: boolean) => {
    dispatch(gameActions.setWordKnowledge({ id, isKnown }));
  };

  const onSwipe = (questionId: number) => {
    dispatch(gameActions.onSwipe(questionId));
  };
  //TODO: Need to understand if it's needed
  // const onLoadLevelTestQuiz = (quiz: Quiz) => {
  //   if (quiz.questions) {
  //     dispatch(gameActions.resetGame());
  //     dispatch(gameActions.setSelectedQuiz(quiz));
  //     dispatch(
  //       gameActions.startGame({
  //         gameType: quiz.gameType,
  //         questions: quiz.questions,
  //       })
  //     );
  //   }
  // };

  useEffect(() => {
    reactToGameStatus();
  }, [gameStatus]);

  useEffect(() => {
    return () => {
      if (!isLevelTest) {
        dispatch(gameActions.resetGame());
      }
    };
  }, []);

  useEffect(() => {
    if (!selectedQuiz) {
      if (isLevelTest) {
        //TODO: Need to understand if it's needed
        // const firstUnfinished = levelTestQuizzes.find(
        //   (quiz) => !quiz.isLocked && !quiz.isFinished
        // );

        // if (firstUnfinished) {
        // onLoadLevelTestQuiz(firstUnfinished);
        // }

        return;
      }

      return navigate('/dashboard/games');
    }

    fetchQuizProgress(selectedTopic?.id ?? 0, selectedQuiz.id);
  }, []);

  useEffect(() => {
    if (
      gameStatus === GameStatus.PLAYING &&
      !currentTime &&
      !isLevelTest &&
      !isInEndOfSkillTest &&
      !isFinalAssessment
    ) {
      if (isRead) {
        dispatch(gameActions.endGame());
        return;
      }

      dispatch(gameActions.timeOutGame());
    }
  }, [gameStatus, currentTime]);

  useEffect(() => {
    const postFinalAssessment = async () => {
      const response = await skillService.postFinalAssessment(user, finalAssessmentProgress!);
      setIsFinalAssessmentFinished(false);

      if (response) {
        const props: PopUpFinalAssessmentSummaryProps = {
          emitOnClosePopUp: async () => {
            onClosePopUp();
            dispatch(gameActions.resetGame());
            dispatch(gameActions.setIsFinalAssessmentTest(false));
            dispatch(finalAssessmentActions.reset());
            dispatch(authActions.setIsUserCompletedFinalAssessment(true));

            navigate('/dashboard/games');
            return;
          },
        };

        displayPopUp(PopUpTypes.FinalAssessmentSummary, props);
      }
    };

    if (isFinalAssessment && isFinalAssessmentFinished) {
      postFinalAssessment();
    }
  }, [isFinalAssessment, isFinalAssessmentFinished]);

  useEffect(() => {
    if (
      gameQuestions?.length &&
      gameQuestions[currentQuestionIndex]?.newData?.data?.metadata?.timer
    ) {
      dispatch(
        gameActions.setCurrentTimer(
          //FIXME: NEED TO REPLACE when newData will be changed
          +gameQuestions[currentQuestionIndex]?.newData?.data?.metadata?.timer
        )
      );
    }
  }, [currentQuestionIndex, gameQuestions]);

  return {
    loadError,
    savingQuizProgressData,
    loadQuizProgress,
    currentQuestion,
    selectedType,
    selectedTopic,
    selectedSkill,
    selectedQuiz,
    gameStatus,
    timesUpShown,
    focusShown,
    correctAnswers,
    inCorrectAnswers,
    currentQuestionIndex,
    gameQuestions,
    gameTypeReal,
    coins,
    selectedHint,
    pickedAnswer,
    currentTime,
    shouldDisplayPopUp,
    popUpType,
    popUpProps,
    isMuted,
    currentCoins,
    allowRetry,
    showPreQuiz,
    showAnswerInOptions,
    onClosePopUp,
    onPause,
    onMute,
    onStartQuiz,
    onPractice,
    onExit,
    onDisableRetry,
    onAknowledge,
    onSwipe,
    goToNextQuestion,
    toggleCheckAnswer,
    currentReadQuestionIndex,
    currentQuestionTempData,
    setCurrentQuestionTempData,
  };
};

export default UseQuiz;
