import LoginViewContent from '@/components/LoginViewContent/LoginViewContent';

const LoginView = () => {
  return (
    <div className='login-view w-full h-full'>
      <LoginViewContent />
    </div>
  );
};

export default LoginView;
