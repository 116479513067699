import { OrganizationDetailsProps } from '@/types/registration';
import classNames from './OrganizationDetails.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import commonUtils from '@/utils/common';
import { commonService } from '@/services/common';
import { CityByState, StateByCountry } from '@/types/common';
import { COUNTRIES } from '@/utils/countries';

import AppInput from '@/components/AppInput/AppInput';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import TheButton from '@/components/TheButton/TheButton';

import upArrow from '@/assets/svg/up-arrow.svg';
import downArrow from '@/assets/svg/down-arrow.svg';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';

const OrganizationDetails = ({ isLastStep, fields, emitSubmit }: OrganizationDetailsProps) => {
  const { t } = useTranslation();
  const { user, nativeLanguage } = useAppSelector(authSelector);

  //TODO: Need to fix, because setIsLoading is not used here
  //eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingStates, setIsLoadingStates] = useState(false);
  const [isLoadingCities, setIsLoadingCities] = useState(false);

  const [showStatesDropdown, setShowStatesDropdown] = useState(false);
  const [showCitiesDropdown, setShowCitiesDropdown] = useState(false);

  const [states, setStates] = useState<StateByCountry[]>([]);
  const [cities, setCities] = useState<CityByState[]>([]);

  const countryFieldIndex = fields?.findIndex(field => field.id === 'country') ?? 0;
  const languageFieldIndex = fields?.findIndex(field => field.id === 'native_language_code') ?? 0;

  const countryField = useMemo(() => fields?.find(field => field.id === 'country'), [fields]);

  const countryCode = useMemo(() => {
    // return COUNTRIES.find(_country => _country.name === (user?.country ?? countryField?.value))
    return COUNTRIES.find(_country => _country.name === countryField?.value)?.code;
  }, [countryField]);

  const stateField = useMemo(() => fields?.find(field => field.id === 'state'), [fields]);

  const cityField = useMemo(() => fields?.find(field => field.id === 'city'), [fields]);

  useEffect(() => {
    window.addEventListener('click', () => {
      if (!showStatesDropdown) {
        return;
      }

      onCloseStatesDropdown();
    });

    return () => {
      window.removeEventListener('click', () => {});
    };
  }, [showStatesDropdown]);

  useEffect(() => {
    window.addEventListener('click', () => {
      if (!showCitiesDropdown) {
        return;
      }

      onCloseCitiesDropdown();
    });

    return () => {
      window.removeEventListener('click', () => {});
    };
  }, [showCitiesDropdown]);

  const fetchStatesByCountry = useCallback(async () => {
    if (!countryCode) {
      return;
    }

    setIsLoadingStates(true);

    try {
      const response = (await commonService.getStatesByCountry(countryCode)).data;
      const states = (response || []).sort((s1: StateByCountry, s2: StateByCountry) =>
        s1.name < s2.name ? -1 : s1.name < s2.name ? 1 : 0
      );
      setStates(states);
      setIsLoadingStates(false);
    } catch (error) {
      commonUtils.showToast(error as string);
      setIsLoadingStates(false);
    }
  }, [countryCode]);

  useEffect(() => {
    fetchStatesByCountry();
  }, []);

  const fetchCitiesByState = async () => {
    if (!stateField?.value || !countryCode) {
      return;
    }

    setIsLoadingCities(true);

    try {
      const response = (await commonService.getCitiessByState(countryCode, stateField.value)).data;
      const cities = (response || []).sort((c1: CityByState, c2: CityByState) =>
        c1.name < c2.name ? -1 : c1.name < c2.name ? 1 : 0
      );
      setCities(cities);
      setIsLoadingCities(false);
    } catch (error) {
      commonUtils.showToast(error as string);
      setIsLoadingCities(false);
    }
  };

  useEffect(() => {
    fetchCitiesByState();
  }, [stateField?.value]);

  const onToggleStatesDropdown = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onCloseCitiesDropdown();
    setShowStatesDropdown(prevState => !prevState);
  };

  const onToggleCitiesDropdown = (e: React.MouseEvent<HTMLElement>) => {
    if (!stateField?.value) {
      return;
    }

    e.stopPropagation();
    onCloseStatesDropdown();
    setShowCitiesDropdown(prevState => !prevState);
  };

  const onCloseStatesDropdown = () => {
    setShowStatesDropdown(false);
  };

  const onCloseCitiesDropdown = () => {
    setShowCitiesDropdown(false);
  };

  const stateOptions = states.map((state: StateByCountry) => {
    return (
      <div
        key={state.name}
        className={classNames.option}
        onClick={e => {
          e.stopPropagation();
          stateField?.emitSelect?.('state', state.iso2, state.name);
          onCloseStatesDropdown();
        }}
      >
        {state.name}
      </div>
    );
  });

  const statesDropdown = showStatesDropdown && (
    <div className={classNames.dropdown}>{stateOptions}</div>
  );

  let statesSelectImg = null;
  if (isLoadingStates) {
    statesSelectImg = <AppLoaderCircle className={classNames.loader} />;
  } else if (states.length) {
    statesSelectImg = <SVG src={showStatesDropdown ? upArrow : downArrow} />;
  }

  const statesSelect = (
    <div className={classNames.selectContainer}>
      <span className={classNames.label}>{`*${t('state')}`}</span>
      <div className={classNames.select} onClick={onToggleStatesDropdown}>
        <span className={!stateField?.value ? 'opacity-20' : ''}>
          {stateField?.value ? t(stateField?.label ?? '') : t(stateField?.placeholder ?? '')}
        </span>
        {statesSelectImg}
      </div>
      {statesDropdown}
    </div>
  );

  const cityOptions = cities.map((city: CityByState) => {
    return (
      <div
        key={city.name}
        className={classNames.option}
        onClick={e => {
          e.stopPropagation();
          cityField?.emitSelect?.('city', city.name, city.name);
          onCloseCitiesDropdown();
        }}
      >
        {city.name}
      </div>
    );
  });

  const citiesDropdown = showCitiesDropdown && cityOptions.length > 0 && (
    <div className={classNames.dropdown}>{cityOptions}</div>
  );

  let citiesSelectImg = null;
  if (isLoadingCities) {
    citiesSelectImg = <AppLoaderCircle className={classNames.loader} />;
  } else if (cities.length) {
    citiesSelectImg = <SVG src={showCitiesDropdown ? upArrow : downArrow} />;
  }

  const citiesSelect = (
    <div
      className={classes(classNames.selectContainer, {
        [classNames.selectContainerDisable]: cityOptions.length === 0 && !isLoadingCities,
      })}
    >
      <span className={classNames.label}>{`*${t('city')}`}</span>
      <div className={classNames.select} onClick={onToggleCitiesDropdown}>
        <span className={!cityField?.value ? 'opacity-20' : ''}>
          {cityField?.value ? t(cityField?.label ?? '') : t(cityField?.placeholder ?? '')}
        </span>
        {citiesSelectImg}
      </div>
      {citiesDropdown}
    </div>
  );

  const countryAndLanguageFields = fields
    ?.slice(countryFieldIndex, languageFieldIndex + 1)
    .map(field => (
      <AppInput
        shouldTranslate
        key={field.id}
        className={classes(classNames.field, classNames.fieldDisabled, {
          [classNames.fieldError]: field.error,
        })}
        inputWrapperClassName={classNames.inputWrapperClassName}
        {...field}
      />
    ));

  const termsAndConditions = fields?.find(field => field.id === 'terms_and_conditions');

  const termsAndConditionsField = termsAndConditions && (
    <div className={classNames.termsAndConditions}>
      <AppInput
        shouldTranslate
        className={classNames.field}
        inputWrapperClassName={classNames.inputWrapperClassName}
        {...termsAndConditions}
      />
      <div
        className={classes(classNames.label, {
          [classNames.labelError]: termsAndConditions.error,
        })}
      >
        <span>{`${t('iAgreeTo')} `}</span>
        <a href='https://corporate.britannica.com/termsofuse.html' target='_blank' rel='noreferrer'>
          {`${t('termsAndConditions')} `}
        </a>
        <span>{t('ofThisSite')}</span>
      </div>
    </div>
  );

  const remainedFields = fields?.slice(0, countryFieldIndex).map(field => {
    if (field.id === 'state') {
      return <React.Fragment key={field.id}>{statesSelect}</React.Fragment>;
    } else if (field.id === 'city') {
      return <React.Fragment key={field.id}>{citiesSelect}</React.Fragment>;
    }

    return (
      <AppInput
        shouldTranslate
        key={field.id}
        className={classes(classNames.field, {
          [classNames.fieldError]: field.error,
        })}
        inputWrapperClassName={classNames.inputWrapperClassName}
        {...field}
      />
    );
  });

  const shouldDisable = fields?.some(field => {
    if (field.id === 'city') {
      return isLoadingCities || (cityOptions.length > 0 && !field.value);
    }
    return !field.value || (field.id === 'terms_and_conditions' && field.value === 'false');
  });

  const form = (
    <form onSubmit={emitSubmit}>
      <div className={classNames.fields}>
        {remainedFields}
        <div className={classNames.countryAndLanguageFields}>{countryAndLanguageFields}</div>
        {termsAndConditionsField}
      </div>
      <TheButton
        className={classNames.submitBtn}
        disabled={shouldDisable}
        text={t(isLastStep ? 'submit' : 'next')}
        type='submit'
        shouldRecolor={false}
      />
    </form>
  );

  return form;
};

export default React.memo(OrganizationDetails);
