import { SkillTypes } from '@/types/skill';
import classNames from './ProgressCard.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import SVG from 'react-inlinesvg';

import trophy from '@/assets/svg/trophy.svg';
import medal from '@/assets/svg/medal-gold.svg';
import UseSkills from '@/hooks/UseSkills';
import UseCurrentRoute from '@/hooks/UseCurrentRoute';
import { ROUTE_NAMES } from '@/router';
import { UserState } from '@/types/user';
import { Dispatch, SetStateAction } from 'react';

interface ProgressCardProps {
  unitId: number;
  title: string;
  user?: UserState | null;
  pathColor: string;
  trailColor: string;
  percentage: number;
  topics: {
    completed: number;
    total: number;
  } | null;
  activities: {
    completed: number;
    total: number;
  } | null;
  showNextGameBtn?: boolean;
  showViewPerformanceBtn?: boolean;
  onClick: (id: number) => void;
  className?: string;
  topicsClassName?: string;
}

const ProgressCard = ({
  unitId,
  title,
  percentage,
  pathColor,
  trailColor,
  topics,
  activities,
  showNextGameBtn = true,
  showViewPerformanceBtn = true,
  onClick,
  user,
  className,
  topicsClassName,
}: ProgressCardProps) => {
  const { t } = useTranslation();
  const { getSkillName } = UseSkills();
  const currentRoute = UseCurrentRoute();
  const dividers = Array.from(Array(4)).map((divider, index) => {
    const left = `${20 * (index + 1)}%`;

    return <div key={index} className={classNames.divider} style={{ left }}></div>;
  });
  const isOnProfile = currentRoute === ROUTE_NAMES.PROFILE;

  return (
    <div
      className={classes(classNames.progressCard, className, {
        [classNames.progressCardOnProfile]: isOnProfile,
      })}
    >
      <div className='flex items-center justify-between'>
        <div className={classNames.text}>
          <div className='flex gap-1'>
            <span className='font-bold opacity-80'>{title}</span>
            <span className='font-medium'>{`${percentage}%`}</span>
          </div>
        </div>
      </div>
      <div className={classNames.details}>
        <div>
          {topics && (
            <div className={classes(classNames.bottomTextContainer, topicsClassName)}>
              <div className={classNames.textContainer}>
                <SVG src={trophy} />
                <span>{`${topics.completed}/${topics.total} ${t('topics')}`}</span>
              </div>
              {activities && (
                <div className={classNames.textContainer}>
                  <SVG src={medal} />
                  <span>{`${activities.completed}/${activities.total} ${t('activities')}`}</span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={classNames.progressBar} style={{ backgroundColor: trailColor }}>
          <div className={classNames.dividers}>{dividers}</div>
          <div
            className={classNames.bar}
            style={{ width: `${percentage}%`, backgroundColor: pathColor }}
          ></div>
        </div>

        {showViewPerformanceBtn && (
          <button
            className={classNames.button}
            style={{
              color: pathColor,
              backgroundColor: trailColor,
              ...(activities?.completed === 0 && { opacity: 0.3 }),
            }}
            disabled={activities?.completed === 0 && true}
            onClick={() => onClick(unitId)}
          >
            {t('viewPerformance')}
          </button>
        )}
      </div>
    </div>
  );
};

export default ProgressCard;
