import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from './RevertUser.module.scss';
import { useTranslation } from 'react-i18next';
import AppLoaderCircle from '@/components/AppLoaderCircle';

export interface RevertsData {
  firstName: string;
  lastName: string;
  interfaceLanguage: string;
}

interface RevertUserProps {
  revertsData: RevertsData | null;
  onClose: () => void;
  onReverts?: (firstName: string, lastName: string, language: string) => Promise<void>;
}

const RevertUser = ({ revertsData, onClose, onReverts }: RevertUserProps) => {
  const { t } = useTranslation();
  const [revertsDataState, setRevertsDataState] = useState<RevertsData | null>(null);
  const [revertsError, setRevertsError] = useState<string>('');
  const [isReverting, setIsReverting] = useState<boolean>(false);
  const revertsTimer = useRef<any>();

  const startTimer = () => {
    clearTimeout(revertsTimer.current);
    revertsTimer.current = setTimeout(() => {
      onClose();
    }, 10 * 1000);
  };

  useEffect(() => {
    setRevertsDataState(revertsData);
    setRevertsError('');
    setIsReverting(false);
    if (revertsTimer.current) {
      clearTimeout(revertsTimer.current);
    }

    if (revertsData) {
      startTimer();
    }
  }, [revertsData, onClose]);

  const onCancelHandler = useCallback(() => {
    if (revertsTimer.current) {
      clearTimeout(revertsTimer.current);
    }

    if (revertsData && onReverts) {
      setIsReverting(true);
      onReverts(revertsData.firstName, revertsData.lastName, revertsData.interfaceLanguage)
        .then(() => {
          onClose();
        })
        .catch(() => {
          startTimer();
          setRevertsError('anErrorOccurred');
          setIsReverting(false);
        });
    }
  }, [revertsData, onReverts, onClose]);

  return (
    revertsDataState && (
      <div className={classNames.revertsPopup}>
        <span>{revertsError ? t(revertsError) : t('userDataSuccessfullyUpdated')}</span>
        {isReverting ? (
          <AppLoaderCircle className={classNames.loader} />
        ) : (
          <button onClick={onCancelHandler}>{t('cancel')}</button>
        )}
      </div>
    )
  );
};

export default RevertUser;
