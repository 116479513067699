import { RootState } from '@/types/common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { reportService } from '@/services/report';

const reportThunks = {
  getStudentReport: createAsyncThunk(
    'getStudentReport',
    async (studentId: string | null, { getState }) => {
      try {
        const state = getState() as RootState;
        const { user } = state.auth;
        const studentReport = await reportService.fetchStudentReport(studentId, user);
        return studentReport?.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    }
  ),
  getClassReport: createAsyncThunk(
    'getClassReport',
    async (classId: string | null, { getState }) => {
      try {
        const state = getState() as RootState;
        const { user } = state.auth;

        const classReport = await reportService.fetchClassReport(classId, user);
        return classReport?.data;
      } catch (error) {
        console.log(error);

        throw error;
      }
    }
  ),
};

export default reportThunks;
