import config from '@/config';
import { cognitoService } from '@/services/cognito';
import axios, { type AxiosError } from 'axios';
import commonUtils from './common';
import store from '@/store';
import { authActions } from '@/store/reducers/auth';

const axiosInstanceNew = axios.create({
  baseURL: config.apiUrlNew,
  withCredentials: false,
});

axiosInstanceNew.interceptors.request.use(async config => {
  try {
    if (
      !!localStorage.getItem('token') &&
      commonUtils.shouldTokenRefresh(localStorage.getItem('token') ?? '')
    ) {
      const newToken = await cognitoService.refreshUserSession();
      store.dispatch(authActions.setToken(newToken));
    }

    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  } catch (error) {
    console.error('Error in request interceptor:', error);
    return Promise.reject(error);
  }
});

axiosInstanceNew.interceptors.response.use(
  response => {
    // Check if the response status code is 204
    if (response.status === 204) {
      throw new Error('No Content');
    }

    return response;
  },
  async (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default axiosInstanceNew;
