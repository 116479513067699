import classes from 'classnames';
import { TopicTypes } from '@/types/topic';
import { Topic, UnitAssets } from '@/types/units';
import classNames from './TopicInfo.module.scss';
import commonUtils from '@/utils/common';
import SVG from 'react-inlinesvg';
import { useAppSelector } from '@/store';
import UseUnitsAssets from '@/hooks/UseUnitsAssets';

import QuizBadge from '@/components/QuizBadge/QuizBadge';
import finalAssessment from '@/assets/svg/finalAssessment.svg';
import trophy from '@/assets/svg/trophy.svg';
import lock from '@/assets/svg/lock.svg';
import star from '@/assets/svg/star.svg';
import React from 'react';
import { unitSelector } from '@/store/reducers/unit';

interface TopicInfoProps {
  className?: string;
  isLocked: boolean;
  isComplete: boolean;
  topic: Topic;
  index: number;
  unitId: number;
  emitOpenModal: (topicId: number) => void;
}

const TopicInfo = ({
  className,
  isLocked,
  isComplete,
  topic,
  index,
  unitId,
  emitOpenModal,
}: TopicInfoProps) => {
  const { selectedUnit } = useAppSelector(unitSelector);
  const { unitsAssets } = UseUnitsAssets();

  const unitAssets: UnitAssets = unitsAssets[selectedUnit?.order || 1];
  const { EndOfSkillTest } = TopicTypes;
  const setStatusSrc = () => {
    if (!isLocked) {
      return isComplete ? trophy : unitAssets?.medal;
    }

    return lock;
  };

  const handleOpenTopic = () => {
    if (isLocked) {
      return;
    }

    emitOpenModal(topic.id);
  };

  return (
    <div
      className={classes(
        classNames.topicInfo,
        { [classNames.topicInfoLocked]: isLocked },
        className
      )}
      onClick={handleOpenTopic}
    >
      <QuizBadge
        img={setStatusSrc() ?? ''}
        isComplete={isComplete}
        isLocked={isLocked}
        isEndOfSkillTest={false}
        index={index}
      />
      <span className={classNames.title}>
        {topic.name && topic.name.includes(' ') && false
          ? topic.name.split(' ').map((word, index) => (
              <React.Fragment key={index}>
                {word}
                <br />
              </React.Fragment>
            ))
          : topic.name}
      </span>
      {/* {!isLocked && topic.topicType !== EndOfSkillTest && (
        <div className={classNames.progress}>
          <SVG src={star} />
          <span>
            {topic.userData?.receivedStars ?? 0}/{commonUtils.totalTopicStars(topic)}
          </span>
        </div>
      )} */}
    </div>
  );
};

export default TopicInfo;
