import React from 'react';
import classes from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { ConfirmForgotPasswordProps } from '@/types/login';
import AppInput from '../AppInput/AppInput';
import TheButton from '../TheButton/TheButton';
import AppLoaderCircle from '../AppLoaderCircle';
import UseScreenSize from '@/hooks/UseScreenSize';

const ConfirmForgotPassword = ({
  emitSubmit,
  styles,
  resendForgotPassword,
  loading,
}: ConfirmForgotPasswordProps) => {
  const { t } = useTranslation();
  const { isMobile } = UseScreenSize();

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.{}\[\]()?\-\"!@#%&/\\,><':;|_~`+=])[A-Za-z\d\^$*.{}\[\]()?\-\"!@#%&/\\,><':;|_~`+=]{8,}$/;

  const LoginSchema = Yup.object().shape({
    code: Yup.string()
      .min(6, t('sixDigitsAtLeast').toString())
      .required(t('validation.code_required').toString()),
    password: Yup.string()
      .required(t('validation.password_required').toString())
      .matches(passwordRegex),
    confirmPassword: Yup.string()
      .required(t('validation.confirm_password_required').toString())
      .oneOf([Yup.ref('password')], t('passwordsDontMatch').toString()),
  });

  const formik = useFormik({
    initialValues: {
      code: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: LoginSchema,
    onSubmit: emitSubmit ?? (() => {}),
  });

  const onClear = (event: React.MouseEvent<SVGElement, MouseEvent>, name: string) => {
    formik.setFieldValue(name, '');
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <AppInput
          className={styles.formField}
          inputWrapperClassName={classes(styles.appInputWrapper, {
            [styles.appInputWrapperError]: !!formik.errors.code,
          })}
          id='code'
          name='code'
          label={t('verificationCode') ?? ''}
          maxLength={6}
          type='password'
          placeholder={t('sixDigits') ?? ''}
          value={formik.values?.code?.trim()}
          error={formik.errors.code}
          allowClear={true}
          emitChange={formik.handleChange}
          emitBlur={formik.handleBlur}
          emitClear={onClear}
        />

        <label onClick={resendForgotPassword} className={styles.resendVerification} htmlFor='email'>
          {t('resendVerificationCode')}
        </label>

        <AppInput
          className={styles.formField}
          inputWrapperClassName={classes(styles.appInputWrapper, {
            [styles.appInputWrapperError]: !!formik.errors.password,
          })}
          passwordRequirementsClassName={styles.passwordRequirements}
          id='password'
          name='password'
          type='password'
          label={t('new_password') ?? ''}
          placeholder={t('password') ?? ''}
          value={formik.values?.password?.trim()}
          error={formik.errors.password}
          allowClear={true}
          emitChange={formik.handleChange}
          emitBlur={formik.handleBlur}
          emitClear={onClear}
          showPasswordRequirements={true}
          helperPosition={isMobile ? 'label' : 'input'}
        />

        <AppInput
          className={styles.formField}
          inputWrapperClassName={classes(styles.appInputWrapper, {
            [styles.appInputWrapperError]: !!formik.errors.confirmPassword,
          })}
          id='confirmPassword'
          name='confirmPassword'
          type='password'
          label={t('new_password_verification') ?? ''}
          placeholder={t('password') ?? ''}
          value={formik.values?.confirmPassword?.trim()}
          error={formik.errors.confirmPassword}
          allowClear={true}
          emitChange={formik.handleChange}
          emitBlur={formik.handleBlur}
          emitClear={onClear}
        />

        {!loading && (
          <TheButton
            type='submit'
            disabled={formik.isSubmitting || !formik.isValid}
            className={styles.submit}
            text={t('submit')}
            shouldRecolor={false}
          />
        )}
        {loading && (
          <div className={styles.loadingWrapper}>
            <AppLoaderCircle />
          </div>
        )}
      </form>
    </>
  );
};

export default ConfirmForgotPassword;
