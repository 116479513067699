import { useTranslation } from 'react-i18next';
import classNames from './LevelTestSummary.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { gameActions } from '@/store/reducers/game';

import TheButton from '@/components/TheButton/TheButton';

import badge from '@/assets/svg/badge.svg';
import coinsRow from '@/assets/svg/coins-row.svg';
import { levelTestActions } from '@/store/reducers/level-test';
import { authSelector } from '@/store/reducers/auth';
import { useAppSelector } from '@/store';
import UserUtils from '@/utils/user';
import UseScreenSize from '@/hooks/UseScreenSize';

interface LevelTestSummaryProps {
  firstname?: string;
  level?: number;
  coins?: number;
}

const LevelTestSummary = ({ firstname, level, coins }: LevelTestSummaryProps) => {
  const { t } = useTranslation();
  const { isMobile } = UseScreenSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector(authSelector);
  const isTeacher = UserUtils.isTeacher(user);

  const onConfirm = async () => {
    dispatch(gameActions.resetGame());
    dispatch(levelTestActions.reset());
    dispatch(levelTestActions.setIsFinishLevelTest(false));
    navigate('/dashboard/games');
  };

  return (
    <div
      className={classes(classNames.levelTestSummary, {
        [classNames.levelTestSummaryMobile]: isMobile,
      })}
    >
      <div className={classNames.top}>
        <div className={classNames.badge}>
          <SVG src={badge} />
          <span className={classNames.level}>{level}</span>
        </div>
      </div>
      <div className={classNames.body}>
        <h2>
          {t('niceEffort')} {firstname}!
        </h2>
        <div className={classNames.reward}>
          <span className={classNames.yourLevel}>
            {level === 5
              ? t('itLooksLikeYourLevelMax', { level: level })
              : t('itLooksLikeYourLevel', { level: level })}
          </span>
          {isTeacher ? (
            <></>
          ) : (
            <>
              <span className={classNames.startingReward}>{t('startingReward')}</span>
              <SVG src={coinsRow} />
              <span className={classNames.coins}>{`${coins} ${t('coins')}`}</span>
              <span className={classNames.coinInstructions}>{t('coinsInstructions')}</span>
            </>
          )}
        </div>
        <TheButton
          className={classNames.button}
          text={t('okLetsGo')}
          emitOnClick={onConfirm}
          shouldRecolor={false}
          showArrow={true}
        />
      </div>
    </div>
  );
};

export default LevelTestSummary;
