import classNames from './QuizDetails.module.scss';
import classes from 'classnames';
import commonUtils from '@/utils/common';
import React from 'react';
import { SkillSettingsItem } from '@/types/user';
import { Topic, TopicTypes } from '@/types/topic';
import UseScreenSize from '@/hooks/UseScreenSize';

import QuizDetailsHeading from '../QuizDetailsHeading/QuizDetailsHeading';
import QuizImageIndicator from '../QuizImageIndicator/QuizImageIndicator';
import DetailSeperator from '@/components/DetailSeperator/DetailSeperator';
import { SkillTypes } from '@/types/skill';
import { useTranslation } from 'react-i18next';
import UseLocalLang from '@/hooks/UseLocalLang';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { Quiz } from '@/types/quiz';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';
import { NewGameTypes } from '@/types/game';

type QuizDetailsProps = {
  className?: string;
  headingClassName?: string;
  selectedType?: SkillTypes | null;
  selectedSkill?: SkillSettingsItem | null;
  selectedTopic: Topic | null;
  selectedQuiz: Quiz | null;
  shouldDisplayImage?: boolean;
  shouldDisplayInstructions?: boolean;
  splitDetails?: boolean;
};

const QuizDetails = ({
  className,
  headingClassName,
  selectedType,
  selectedSkill,
  selectedTopic,
  selectedQuiz,
  shouldDisplayImage = false,
  shouldDisplayInstructions = false,
  splitDetails = false,
}: QuizDetailsProps) => {
  const { t } = useTranslation();
  const { isDesktop, isMobile, windowHeight, mediumHeight } = UseScreenSize();
  const localLang = UseLocalLang();
  const { user } = useAppSelector(authSelector);
  const { isLevelTest, isFinalAssessment, gameQuestions } = useAppSelector(gameSelector);
  //FIXME: NEED TO REPLACE WITH CORRECT GAMETYPE
  const TEMP_GAMETYPE = gameQuestions[0]?.gameType as any;

  const isInEndOfSkillTest = selectedTopic?.topicType === TopicTypes.EndOfSkillTest;
  const isRead = TEMP_GAMETYPE === NewGameTypes.Read;
  let name = t('notAvailable');

  if (selectedQuiz) {
    name = isLevelTest
      ? t(`skills.${selectedQuiz.skill ?? t('notAvailable')}`)
      : commonUtils.getName(selectedQuiz, localLang) || t('notAvailable');
  }

  let title = name;

  if (isMobile) {
    if (isInEndOfSkillTest) {
      title = selectedQuiz?.name ?? '';
    } else if (isLevelTest && isRead) {
      title = `${name} | ${selectedQuiz?.contentTitle}`;
    } else if (isFinalAssessment) {
      title = selectedType ?? '';
    }
  }

  if (isDesktop) {
    if (isInEndOfSkillTest) {
      const currentLevel = user?.progress?.userLevel;

      title = `${t('level')} ${currentLevel} ${t('assessment')}`;
    }
  }

  return (
    <div
      className={classes(classNames.quizDetails, className, {
        [classNames.quizDetailsRead]: isRead,
        [classNames.quizDetailsDesktop]: isDesktop,
      })}
    >
      {shouldDisplayImage && (
        <QuizImageIndicator
          className={classNames.imageIndicator}
          starsClassName={classNames.stars}
          skillClassName={classNames.skill}
          shouldDisplayStars={!isLevelTest && !isInEndOfSkillTest}
        />
      )}
      <div className={classNames.content}>
        {isLevelTest ? (
          <div className={classNames.levelTest}>{t('howsYourEnglish')}</div>
        ) : (
          <QuizDetailsHeading
            className={classes(
              {
                [classNames.quizDetailsHeadingMobile]: isMobile && windowHeight > mediumHeight,
                [classNames.quizDetailsHeadingDesktop]: isDesktop,
              },
              headingClassName
            )}
            selectedType={selectedType}
            selectedSkill={selectedSkill}
            selectedTopic={selectedTopic}
            isInGameWindow={true}
          />
        )}
        {splitDetails && (
          <>
            <div className={classes(classNames.details, classNames.splitDetails)}>
              <div>
                {selectedTopic ? (
                  <h3 className={classNames.topic}>
                    {commonUtils.getName(selectedTopic, localLang)}
                  </h3>
                ) : (
                  t('notAvailable')
                )}
              </div>
              <div>
                <span>{title}</span>
              </div>
            </div>
          </>
        )}
        {!splitDetails && (
          <div className={classNames.details}>
            {selectedTopic ? (
              <h3 className={classNames.topic}>{commonUtils.getName(selectedTopic, localLang)}</h3>
            ) : (
              t('notAvailable')
            )}
            <DetailSeperator className={classNames.seperator} />
            <span>{title}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(QuizDetails);
