import { useEffect, useState } from 'react';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { GameStatus } from '@/types/game';
import UseScreenSize from '@/hooks/UseScreenSize';

import AppLoaderCircle from '@/components/AppLoaderCircle';

import classNames from './PopUpCanDoStatement.module.scss';

import complete from '@/assets/svg/quiz-complete.svg';
import { useAppSelector } from '@/store';
import { unitSelector } from '@/store/reducers/unit';
import { authSelector, authActions } from '@/store/reducers/auth';
import { progressService, userService } from '@/services';
import { useDispatch } from 'react-redux';
import { gameActions } from '@/store/reducers/game';
import GameUtils from '@/utils/gameUtils';

type CanDoDataType = {
  id: number;
  answer: string;
  iconSrc: string;
};

const PopUpCanDoStatement = ({
  className,
  selectedTopic,
  selectedQuiz,
  quizzes,
  emitOnExit,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = UseScreenSize();
  const { selectedTopic: selectedTopicNew, selectedUnit, topics } = useAppSelector(unitSelector);
  const { user } = useAppSelector(authSelector);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [canDoButtons, setCanDoButtons] = useState<CanDoDataType[]>([]);

  const answerTranslations: { [key: number]: string } = {
    1: 'iAgree',
    2: 'somewhatAgree',
    3: 'iDoNotAgree',
  };

  useEffect(() => {
    if (!canDoButtons?.length) {
      userService.fetchCanDoData(user).then((result: any) => {
        const translatedData = result.data.data.map((item: CanDoDataType) => ({
          ...item,
          answer: answerTranslations[item.id] ? t(answerTranslations[item.id]) : item.answer,
        }));
        setCanDoButtons(translatedData);
        setIsLoading(false);
      });
    }
  }, []);

  const isLastQuizInTopic = GameUtils.isLastActivity(
    selectedQuiz?.order,
    selectedTopic?.activitiesInTopic
  );

  const topicsLength = topics[selectedUnit?.id || 1].length;
  const isLastTopic = selectedTopic?.id === topics[selectedUnit?.id || 1][topicsLength - 1].id;

  const isLastQuizInTheLastTopic = isLastTopic && isLastQuizInTopic;

  const title = selectedTopic?.name || t('notAvailable');

  const canDoStatementText = selectedTopicNew?.canDoStatement || t('notAvailable');

  const descriptionTextFirs = t('youHaveCompleted');
  const descriptionTextSecond = t('howDidYouDo');

  const sendAnswer = (answerId: number) => {
    const unitId = selectedUnit?.id || 1;
    const topicId = selectedTopicNew?.id || 1;

    progressService.sendCanDoAnswer(user, {
      unitId: unitId,
      topicId: topicId,
      userAnswer: answerId,
    });

    dispatch(authActions.setUserCanDoAnswer({ unitId, topicId, answerId }));
  };

  return (
    <div
      className={classes(
        {
          container: isMobile,
        },
        className,
        classNames.popUpSummary
      )}
    >
      <div className={classNames.quizSummary}>
        <div className={classNames.images}>
          <div className={classNames.result} style={{ backgroundImage: `url(${complete})` }}>
            <div className={classNames.text}>
              <p>{title}</p>
              <h3>{t('complete')}</h3>
            </div>
          </div>
        </div>
        <div className={classNames.content}>
          <div className={classes(classNames.summary)}>
            <p className={classNames.description}>
              <span>{descriptionTextFirs}</span>
              <span>{descriptionTextSecond}</span>
            </p>
            <h3 className={classNames.title}>{canDoStatementText}</h3>
            <div className={classNames.canDoWrapper}>
              {isLoading ? (
                <AppLoaderCircle />
              ) : (
                canDoButtons.map((item: CanDoDataType, key: number) => (
                  <div
                    key={`${key}-${item.answer}`}
                    onClick={() => {
                      sendAnswer(item.id);
                      !isLastQuizInTheLastTopic
                        ? emitOnExit?.()
                        : dispatch(gameActions.toggleGameStatus(GameStatus.FINISH_UNIT));
                    }}
                    className={classNames.caDoItem}
                  >
                    <div
                      style={{ backgroundImage: `url(${item.iconSrc})` }}
                      className={classNames.caDoIcon}
                    ></div>
                    <p>{item.answer}</p>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpCanDoStatement;
