import classNames from './AddNewClass.module.scss';
import { useTranslation } from 'react-i18next';
import TeacherOnboarding from '../TeacherOnboarding/TeacherOnboarding';
import { useDrawer } from '@/context/DrawerContext';
import SectionSeperator from '../SectionSeperator/SectionSeperator';

const AddNewClass = () => {
  const { t } = useTranslation();
  const drawer = useDrawer();

  return (
    <div className={classNames.addNewClassDrawer}>
      <div className={classNames.top}>
        <h1>{t('addNewClass')}</h1>
        <h2>{t('createYourClass')}</h2>
      </div>
      <SectionSeperator className={classNames.seperator} />
      <div className={classNames.body}>
        <TeacherOnboarding
          className={classNames.teacherOnboarding}
          showTop={false}
          showCopyBtn={false}
          hasMultipleControls
          isDrawer
          emitCancel={drawer.closeAddClass}
        />
      </div>
    </div>
  );
};

export default AddNewClass;
