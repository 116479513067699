import { Question } from './question';
import { Quiz } from './quiz';
import { Topic } from './topic';
import { SkillSettingsItem, UserData } from './user';
import { SkillTypes } from './skill';
import { FinalAssessmentsResultResponse } from './final-assessment';

export enum HintsType {
  SEE_TRANSLATION = 'see_the_translation',
  SEE_ENGLISH_WORD = 'see_the_word',
  REMOVE_ONE_ANSWER = 'remove_one_answer',
  REMOVE_LETTER = 'remove_a_letter',
  HEAR_ENGLISH_WORD = 'hear_the_english_word',
  SHOW_LETTER = 'show_a_letter',
  ADD_TIME = 'add_more_time',
  REVEAL_A_PAIR = 'reveal_a_pair',
}

export enum GameTypes {
  Multichoice = 8,
  Matching = 222,
  ClosedSpelling = 2,
  Pronunciation = 3,
  DefinedByClient = 5,
  OpenSpelling = 10,
  Practice = 700,
}

export enum NewGameTypes {
  Listen = 1,
  Grammar = 4, // same as TextToText
  Memory = 5,
  Read = 6,
  NaturalConversation = 7,
  TextToText = 8,
  Vocabulary = 9,
  TextToImage = 10,
  ImageToText = 11,
  OpenWriting = 12,
  AudioToImage = 13,
  Speak = 3,
}

export enum GameStatus {
  PREPARING,
  PLAYING,
  PAUSED,
  PAUSED_SIMPLE,
  QUESTION_ANSWERED,
  SHOW_HINTS,
  SHOW_WORDS_LIST,
  SHOW_DICTIOANRY,
  CHECK_ANSWER,
  TIME_ENDED,
  ENDED,
  DICTIONARY_OPEN,
  OPEN_WRITING_AI_REVIEW,
  FINISH_UNIT,
}

export type PreparationTypeProps = {
  selectedType?: SkillTypes | null;
  selectedSkill?: SkillSettingsItem | null;
  selectedTopic: Topic | null;
  selectedQuiz: Quiz | null;
  loading?: boolean;
  emitGoBack?: () => void;
  emitOnStartQuiz?: () => void;
};

export type HintsResponseData = {
  hintUsageCount: number;
  coinsUsed: number;
};

export type VocabularyPreparationProps = PreparationTypeProps & {
  emitOnPractice?: () => void;
};

export type ReadPreparationProps = PreparationTypeProps & {
  currentQuestion?: Question;
  currentQuestionIndex?: number;
  gameQuestions?: Question[];
  coins?: number;
  currentCoins?: number;
  currentTime?: number;
  isPaused?: boolean;
  emitPause?: () => void;
};

export type SpeakPreparationProps = PreparationTypeProps & {
  tip?: string;
};

export type GameState = {
  fromToLang: string | null;
  selectedSkill?: SkillSettingsItem | null;
  selectedType: SkillTypes | null;
  selectedTopic: Topic | null;
  selectedQuiz: Quiz | null;
  gameStatus: GameStatus;
  prevStatus: GameStatus | null;
  currentTime: number;
  pauseTimer: boolean;
  responseTimer: number;
  totalQuizTime: Date;
  checkAnswerCompleted: boolean;
  hasTimer: boolean;
  isMuted: boolean;
  gameType: GameTypes | NewGameTypes | null;
  currentQuestionIndex: number;
  currentReadQuestionIndex: number;
  correctAnswers: number;
  total_correctAnswers: number;
  total_inCorrectAnswers: number;
  inCorrectAnswers: number;
  gameQuestions: Question[];
  pickedAnswer: string | null;
  canUseRemoveOneAnswerHint: boolean;
  coins: number;
  achievedStars: number;
  focusShown: boolean;
  keepGoingShown: boolean;
  timesUpShown: boolean;
  selectedHint: HintsType | null;
  allowRetry: boolean;
  showPreQuiz: boolean;
  showStartQuizPage: boolean;
  showAnswerInOptions: boolean;
  practiceCards: Question[];
  questionResultData: LevelTestQuestionResult[];
  quizQuestionResultData: QuizQuestionResult[];
  levelTestQuizResultData: levelTestQuizResultData | {};
  showOverlay: boolean;
  visitFocus: boolean;
  visitKeepGoing: boolean;
  maxSpellingLetters: number;
  possibleAnswers: string[];
  showLetter: boolean;
  revealAPair: boolean;
  revealAPairDisable: boolean;
  removeLetter: boolean;
  removeLetterDisable: boolean;
  revealTranslation: boolean;
  revealWord: boolean;
  hintsData: HintsResponseData[];
  soundWaveIsReady: boolean | null;
  isLevelTest: boolean;
  isInEndOfSkillTest: boolean;
  isFinalAssessment: boolean;
  isOpenWritingAnswerInEnglish: boolean;
};

export type StartGamePayload = {
  gameType: GameTypes;
  questions: Question[];
};

export type PickAnswerPayload = {
  answer?: string;
  index?: number;
};

export type PickOpenWritingAnswerPayload = {
  answer: string;
  aIGradingReview: string;
  containsGibberish: boolean;
  containsVulgarLanguage: boolean;
  grammarScore: number;
  vocabularyScore: number;
  spellingScore: number;
  overallScore: number;
  noGrading: boolean;
  isEnglish: boolean;
};

export type AknowledgeWordPayload = {
  isKnown: boolean;
  id: number;
};

export type GameTypeProps = {
  type: GameTypes | NewGameTypes;
  correctAnswer?: string;
  currentQuestion: Question;
  pickedAnswer?: string;
  isLevelTest?: boolean;
  emitOnAnswer: (option: string) => void;
  emitOnNextQuestion?: () => void;
  setCurrentQuestionTempData: (data: any, key?: string) => void;
  currentQuestionTempData: any;
};

export type MultiChoiceProps = GameTypeProps & {
  options?: string[];
  showAnswerInOptions?: boolean;
  emitOnRetry?: () => void;
};

export type ClosedSpellingProps = GameTypeProps & {
  options?: string[];
  allowRetry?: boolean;
  emitOnRetry?: () => void;
};

export type MemoryProps = GameTypeProps;

export type PracticeProps = GameTypeProps & {
  practiceCards?: Question[];
  emitOnSwipe?: (questionId: number) => void;
  emitOnAknowledge?: (id: number, isKnown: boolean) => void;
};

export type PronunciationProps = GameTypeProps & {
  allowRetry?: boolean;
  emitOnRetry?: () => void;
};

export enum PopUpTypes {
  KeepGoing = 'keepGoing',
  Focus = 'focus',
  TimeIsUp = 'timeIsUp',
  Pause = 'pause',
  Summary = 'summary',
  Hints = 'hints',
  WordsList = 'wordsList',
  EndOfSkillSummary = 'endOfSkillSummary',
  FinalAssessmentSummary = 'finalAssessmentSummary',
  OpenWrittingAIReview = 'OpenWrittingAIReview',
  CanDoStatement = 'CanDoStatement',
  FinishUnit = 'FinishUnit',
}

type PopUpProps = {
  tip?: string;
  coins?: number;
  className?: string;
  canClickOutside?: boolean;
  emitOnClosePopUp?: (hasFailed?: boolean) => void;
};

export type PopUpKeepGoingProps = PopUpProps & {
  questionsLeft?: number;
};

export type PopUpFocusProps = PopUpProps & {
  inCorrectAnswers?: number;
  totalQuestions?: number;
};

export type PopUpTimeIsUpProps = PopUpProps & {
  emitOnNextQuestion?: () => void;
  allowRetry?: boolean;
  isLastQuestion?: boolean;
};

export type PopUpPauseProps = PopUpProps & {
  currentQuestion?: number;
  totalQuestions?: number;
  emitOnQuit?: () => void;
};

export type PopUpSummaryProps = PopUpProps & {
  selectedSkill?: SkillSettingsItem | null;
  selectedTopic?: Topic | null;
  selectedQuiz?: Quiz | null;
  hasNextQuiz?: boolean;
  correctAnswers?: number;
  totalQuestions?: number;
  userData?: UserData | null;
  totalScore?: number | null;
  isOpenWriting?: boolean;
  noGrading?: boolean;
  emitOnExit?: () => void;
  emitOnQuit?: () => void;
  emitOnAknowledge?: (id: number, isKnown: boolean) => void;
  emitStartQuizFromPractice?: () => void;
  emitPracticeAgain?: () => void;
  emitOnEndOfSkillTest?: () => void;
  emitOnExitWithCanDo?: () => void;
};

export type PopUpEndOfSkillSummaryProps = PopUpProps & {
  selectedSkill?: SkillSettingsItem | null;
  selectedTopic?: Topic | null;
  selectedQuiz?: Quiz | null;
  correctAnswers?: number;
  totalQuestions?: number;
  assessmentResult?: FinalAssessmentsResultResponse;
  emitOnExit?: () => void;
  emitOnQuit?: () => void;
};

export type PopUpFinalAssessmentSummaryProps = PopUpProps & {};

export type PopUpHintsProps = PopUpProps & {
  coins?: number;
  emitOnSelectHint?: (hint: HintsType | null) => void;
};

export type PopUpWordsListProps = PopUpProps & {
  emitOnAknowledge?: (id: number, isKnown: boolean) => void;
};

export type LevelTestQuestionResult = {
  questionId: number;
  isCorrect: boolean;
  wrongAnswer: string | null;
  isTimesUp: boolean;
  responseTime: number;
};

export type levelTestQuizResultData = Record<SkillTypes, LevelTestQuestionResult[]>;

// export type levelTestQuizResultData = {
//   [key in SkillTypes]
// };

export type QuizQuestionResult = {
  questionId: number;
  achievedCoins: number;
  isCorrect: boolean;
  wrongAnswer: string | null;
  isTimesUp: boolean;
  hintUsageCount: number;
  coinsUsed: number;
  responseTime: number;
  gameType?: NewGameTypes | GameTypes;
  isQuestionPresented?: boolean;
  practice?: {
    isKnown: boolean;
  };
  //For OpenWriting game type
  openWritingResult?: {
    userResponse: string;
    scoreResponse: {
      grammarScore: number;
      vocabularyScore: number;
      spellingScore: number;
      overallScore: number;
      aIGradingReview: string;
      containsVulgarLanguage: boolean;
      containsGibberish: boolean;
      noGrading: boolean;
    };
  };
};

export interface Block {
  id: string;
  value: string;
  isDisabled: boolean;
  isRemovable?: boolean;
}
export interface EndQuizBody {
  levelTestQuestionsResults: LevelTestQuestionResult[];
  correctAnswers: number;
  wrongAnswers: number;
  startTime: string;
  finishTime: string;
}

export type MultiChoiceQuestionPart = {
  isBlank: boolean;
  value: string;
  correctAnswerPart?: string;
};
