import { MeResponse } from '@/types/response';
import { AuthService } from '@/types/services';
import axiosInstance from '@/utils/api';

import config from '@/config';
import axiosInstanceNew from '@/utils/api-new';

export const authService: AuthService = {
  me: async userId => {
    const isLogIn = (window as any).userDataIsLogIn ? 'true' : 'false';
    (window as any).userDataIsLogIn = false;

    const resp: MeResponse | null = await axiosInstanceNew.get(
      `${config.apiVersion}/user-data/${userId}?isLogin=${isLogIn}`
    );
    return resp;
  },
};
