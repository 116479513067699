import { PopUpSummaryProps } from '@/types/game';
import { useAppSelector } from '@/store';
import { unitSelector } from '@/store/reducers/unit';
import FinishLevel from './components/FinishLevel';
import FinishUnit from './components/FinishUnit';

const PopUpFinishUnit = ({ className, emitOnQuit }: PopUpSummaryProps) => {
  const { selectedUnit, units, topics } = useAppSelector(unitSelector);

  const isLastUnit = selectedUnit.order === units.length;

  return isLastUnit ? (
    <FinishLevel />
  ) : (
    <FinishUnit
      selectedUnit={selectedUnit}
      units={units}
      topics={topics}
      className={className}
      emitOnQuit={emitOnQuit}
    />
  );
};

export default PopUpFinishUnit;
