import classNames from 'classnames';
import badgeClasses from './Badge.module.scss';

interface BadgeItemProps {
  image: string;
  amount?: string | number;
  type: 'coins' | 'stars';
}

const Badge = ({ type, image, amount }: BadgeItemProps) => {
  return (
    <div className={classNames([badgeClasses.badge, badgeClasses[type]])}>
      <img src={image} />
      <span>{amount}</span>
    </div>
  );
};

export default Badge;
