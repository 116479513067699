import classNames from './UploadCsvErrorModal.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';

import SVG from 'react-inlinesvg';
import AppBackdrop from '@/components/AppBackdrop';
import AppModal from '@/components/AppModal';

import closeIcon from '@/assets/svg/closeFill.svg';
import errorIcon from '@/assets/svg/error.svg';

interface UploadCsvErrorModalProps {
  show: boolean;
  onClose: () => void;
}

const UploadCsvErrorModal = ({ show, onClose }: UploadCsvErrorModalProps) => {
  const { t } = useTranslation();

  return (
    <Transition
      in={show}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => {
        return (
          <>
            <AppBackdrop
              className={classes(classNames.backdrop, `backdrop-${state}`)}
              emitClose={onClose}
            />
            <AppModal className={classNames.appModal}>
              <div className={classes(classNames.modal, `fade-${state}`)}>
                <button className={classNames.closeBtn} onClick={onClose}>
                  <SVG src={closeIcon} />
                </button>
                <SVG src={errorIcon} className={classNames.icon} />
                <h2>{t('uploadCsvErrorModalTitle')}</h2>
                <span
                  className={classNames.description}
                  dangerouslySetInnerHTML={{
                    __html: t('uploadCsvErrorModalDescription').toString(),
                  }}
                />
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );
};

export default UploadCsvErrorModal;
