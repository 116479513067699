import classNames from './ClosedSpellingImage.module.scss';
import classes from 'classnames';
import { Question } from '@/types/question';
import { ClosedSpellingProps } from '@/types/game';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { gameSelector } from '@/store/reducers/game';
import { useAppSelector } from '@/store';
import GameUtils from '@/utils/gameUtils';

import AnswerReward from '../../AnswerReward/AnswerReward';

import check from '@/assets/svg/check.svg';
import wrong from '@/assets/svg/wrong.svg';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';
import UserUtils from '@/utils/user';

interface ClosedSpellingImageProps {
  question: Question;
  isCorrect: boolean;
  allowRetry: ClosedSpellingProps['allowRetry'];
  showBackdrop: boolean;
  showFeedback: boolean;
  emitOnRetry: ClosedSpellingProps['emitOnRetry'];
  emitOnNextQuestion: () => void;
  revealTranslation: boolean;
  translation: string;
}

const ClosedSpellingImage = ({
  question,
  isCorrect,
  showBackdrop,
  showFeedback,
  allowRetry,
  emitOnRetry,
  emitOnNextQuestion,
  revealTranslation,
  translation,
}: ClosedSpellingImageProps) => {
  const { t } = useTranslation();
  const { currentQuestionIndex, gameQuestions, isInEndOfSkillTest, isFinalAssessment } =
    useAppSelector(gameSelector);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const { user } = useAppSelector(authSelector);

  const isStudent = UserUtils.isStudent(user);

  const [backdropContentTtle, setBackdropContenTitle] = useState('');

  const currentQuestion = useMemo(() => {
    return gameQuestions[currentQuestionIndex];
  }, [currentQuestionIndex, gameQuestions]);

  useEffect(() => {
    setBackdropContenTitle(() => {
      if (showBackdrop) {
        if (revealTranslation) {
          return translation;
        }
        if (showFeedback) {
          return isCorrect ? t('greatJob') : t('wrongAnswer');
        }

        return allowRetry && !isCorrect
          ? t('tryAgainOrKeepGoing')
          : GameUtils.isLastQuestion(gameQuestions, currentQuestionIndex)
            ? t('finishActivity')
            : t('nextQuestion');
      }

      return '';
    });
  }, [isCorrect, showBackdrop, showFeedback, allowRetry, revealTranslation]);

  const answerRewardElement = isStudent &&
    question.coins &&
    !currentQuestion.userData?.achievedCoins &&
    isCorrect &&
    showFeedback && <AnswerReward className={classNames.answerReward} coins={question.coins} />;

  const feedbackContent = (
    <div
      className={classes(
        classNames.circle,
        isCorrect ? classNames.feedbackCorrect : classNames.feedbackWrong
      )}
    >
      <SVG src={isCorrect ? check : wrong} />
    </div>
  );

  const controlsElement = (
    <div className={classNames.controls}>
      {!isCorrect && allowRetry ? (
        <button className={classes(classNames.circle, classNames.retry)} onClick={emitOnRetry}>
          <span>1</span>
        </button>
      ) : null}
      <button
        className={classes(classNames.circle, classNames.nextQuestion, [
          classNames[`nextQuestion-${interfaceLanguage?.direction}`],
        ])}
        onClick={emitOnNextQuestion}
      >
        <span></span>
      </button>
    </div>
  );

  const backdropContentBody = showFeedback ? feedbackContent : controlsElement;

  const backdropContent = (
    <div className={classNames.backdropContent}>
      <span className={classNames.title}>{backdropContentTtle}</span>
      {revealTranslation === false && backdropContentBody}
      {!isInEndOfSkillTest && answerRewardElement}
    </div>
  );

  const backdropElement = showBackdrop && (
    <div className={classNames.backdrop}>{backdropContent}</div>
  );

  const image = question?.dictionaryDetails?.image;
  const imageAlt = (image && question?.imagesAlt?.[image]) || '';

  const copyrightElement = !showBackdrop && image && question?.imagesCredits?.[image] && (
    <span className={classNames.copyright}>
      <span>{question.imagesCredits[image]}</span>
    </span>
  );

  return (
    <div className={classNames.closedSpellingImage}>
      <img className={classNames.image} src={question?.dictionaryDetails?.image} alt={imageAlt} />
      {backdropElement}
      {copyrightElement}
    </div>
  );
};

export default ClosedSpellingImage;
