import NotFoundContent from '@/components/NotFoundContent/NotFoundContent';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const NotFound = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const title = location.state?.title ?? t('anUnauthorizedRequestErrorOccurred');
  const description = location.state?.description ?? t('pleaseTryAgainLaterWeApologize');

  return (
    <div className='not-found h-full'>
      <NotFoundContent title={title} description={description} />
    </div>
  );
};

export default NotFound;
