import classNames from './AppDownloadRecommandation.module.scss';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/store';
import { useNavigate } from 'react-router-dom';
import { authActions } from '@/store/reducers/auth';
import commonUtils from '@/utils/common';

import TheButton from '../TheButton/TheButton';

import backgroundImg from '@/assets/svg/app-recommandation-bg.svg';

const AppDownloadRecommandation = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onGetApp = () => {
    window.location.href = commonUtils.isIOS()
      ? 'itms-apps://apps.apple.com/us/app/britannica-english/6472614240'
      : 'market://details?id=com.melingo.app';
  };

  const onContinueOnWeb = () => {
    dispatch(authActions.setShowAppRecommandationScreen(false));
    navigate('/dashboard/level-test');
  };

  return (
    <div className={classNames.appDownloadRecommandation}>
      <SVG className={classNames.background} src={backgroundImg} />
      <div className={classNames.content}>
        <h2>{t('getFullExperience')}</h2>
        <div className={classNames.bottom}>
          <TheButton
            className={classNames.getAppBtn}
            shouldRecolor={false}
            text={t('getApp')}
            emitOnClick={onGetApp}
          />
          <TheButton
            className={classNames.continueBtn}
            plain
            text={t('continueOnWeb')}
            emitOnClick={onContinueOnWeb}
          />
        </div>
      </div>
    </div>
  );
};

export default AppDownloadRecommandation;
