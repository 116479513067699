import { GameTypes, NewGameTypes } from '@/types/game';

export const quizDescription = (gameType: number | null) => {
  const defaultData = 'preparationDescriptionFirstPart';
  switch (gameType) {
    case NewGameTypes.OpenWriting:
      return 'preparationOpenWriting';
    case NewGameTypes.Memory:
      return 'preparationMemory';
    case NewGameTypes.AudioToImage:
      return 'preparationAudioToImage';
    case NewGameTypes.NaturalConversation:
      return 'preparationNaturalConversation';
    case NewGameTypes.ImageToText:
      return 'preparationImageToText';
    case NewGameTypes.TextToImage:
      return 'preparationTextToImage';
    case NewGameTypes.TextToText:
      return 'preparationTextToText';
    case NewGameTypes.Read:
      return 'preparationRead';
    case NewGameTypes.Grammar:
      return 'preparationGrammar';
    case NewGameTypes.Vocabulary:
      return 'preparationVocabulary';
    case GameTypes.ClosedSpelling:
      return 'preparationClosedSpelling';
    case NewGameTypes.Listen:
      return 'preparationListen';
    case NewGameTypes.Speak:
      return 'preparationSpeaking';

    default:
      return defaultData;
  }
};

export const quizDescriptionPart2 = (gameType: number | null) => {
  const defaultData = 'preparationDescriptionSecondtPart';
  switch (gameType) {
    case NewGameTypes.Grammar:
      return 'preparationSecondtPartGrammar';
    case NewGameTypes.TextToImage:
      return 'preparationSecondtPartTextToImage';
    case NewGameTypes.Vocabulary:
      return 'preparationSecondtPartVocabulary';
    case NewGameTypes.Speak:
      return 'preparationSecondtPartSpeak';
    case NewGameTypes.ImageToText:
      return 'preparationSecondtPartImageToText';

    default:
      return defaultData;
  }
};
