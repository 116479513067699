import React, { useState, useEffect } from 'react';
import UseLevelTest from './UseLevelTest';
import LevelTestIntro from '@/components/LevelTest/LevelTestIntro/LevelTestIntro';
import LevelTestSummary from '@/components/LevelTest/LevelTestSummary/LevelTestSummary';
import LevelTestLoading from '@/components/LevelTest/LevelTestLoading/LevelTestLoading';
import classNames from './LevelTestView.module.scss';
import classes from 'classnames';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { useLocation } from 'react-router-dom';
import { levelTestSelector } from '@/store/reducers/level-test';
import UseScreenSize from '@/hooks/UseScreenSize';

const LevelTestView = () => {
  const { isMobile } = UseScreenSize();
  const { user } = useAppSelector(authSelector);
  const location = useLocation();
  const { onBegin, isLoading } = UseLevelTest();
  const reVisited = location.state;

  const { isFinishLevelTest } = useAppSelector(levelTestSelector);

  const [showLoading, setShowLoading] = useState(false);
  const [showSummary, setShowSummary] = useState(false);

  useEffect(() => {
    if (isFinishLevelTest) {
      setShowLoading(true);

      const timer = setTimeout(() => {
        setShowLoading(false);
        setShowSummary(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isFinishLevelTest]);

  let view = 'LevelTestIntro';
  let content = <LevelTestIntro reVisited={reVisited} emitBegin={onBegin} isLoading={isLoading} />;

  if (showLoading) {
    content = <LevelTestLoading />;
    view = 'LevelTestLoading';
  } else if (showSummary) {
    content = (
      <LevelTestSummary
        firstname={user?.metadata?.firstName}
        level={user?.progress?.userLevel}
        coins={user?.progress?.coins || 100}
      />
    );
    view = 'LevelTestSummary';
  }

  return (
    <div
      className={classes(classNames[`container-${view}`], {
        [classNames.containerMobile]: isMobile,
      })}
    >
      {content}
    </div>
  );
};

export default LevelTestView;
