import { GameTypeProps, NewGameTypes, MultiChoiceProps } from '@/types/game';
import UseScreenSize from '@/hooks/UseScreenSize';
import classNames from './MultiChoice.module.scss';
import classes from 'classnames';
import commonUtils from '@/utils/common';
import { useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';
import MultiChoiceQuestion from './MultiChoiceQuestion/MultiChoiceQuestion';
import MultiChoiceOptions from './MultiChoiceOptions/MultiChoiceOptions';
import SVG from 'react-inlinesvg';

import hat from '@/assets/svg/hat.svg';

import MultiChoiceQuestionContent from './MultiChoiceQuestion/components/MultiChoiceQuestionContent';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { levelTestSelector } from '@/store/reducers/level-test';
import AppLoaderCircle from '@/components/AppLoaderCircle';

const MultiChoice = ({
  currentQuestion,
  options,
  correctAnswer,
  pickedAnswer,
  showAnswerInOptions,
  emitOnAnswer,
  emitOnNextQuestion,
  emitOnRetry,
}: GameTypeProps & MultiChoiceProps) => {
  const { isDesktop, isMobile } = UseScreenSize();
  const { t } = useTranslation();

  const { isLevelTestLoading } = useAppSelector(levelTestSelector);

  const dispatch = useDispatch();
  const {
    selectedType,
    selectedQuiz,
    showOverlay,
    revealWord,
    isLevelTest,
    isFinalAssessment,
    gameQuestions,
  } = useAppSelector(gameSelector);
  //FIXME: NEED TO REPLACE WITH CORRECT GAMETYPE
  const TEMP_GAMETYPE = isLevelTest ? 8 : (gameQuestions[0]?.gameType as any);

  const audioTextQuestion = currentQuestion?.newData?.data?.questionData?.question?.text || '';

  const onPickOption = (option: string) => {
    if (showOverlay === true) {
      return;
    }

    emitOnAnswer(option);
  };

  const handleRetry = () => {
    dispatch(gameActions.setPictureOverlay(false));
    dispatch(gameActions.resetPickedAnswer());

    emitOnRetry?.();
  };

  const instructionComponent = (
    <>
      {isDesktop && (selectedQuiz || isLevelTest) && (
        <div className={classNames.instructionsContainer}>
          {isLevelTest ? (
            <>
              <span className={classNames.hatImage}>
                <SVG src={hat} />
              </span>
              <h2 className={classNames.testYourEnglishText}>{t('testYourEnglish')}</h2>
              <h2>{t('chooseTheCorrectAnswer')}</h2>
            </>
          ) : (
            <h2
              className={classes({
                [classNames['instructions-ltr']]: isFinalAssessment,
              })}
            >
              {commonUtils.getQuestionInstructions(currentQuestion, selectedQuiz)}
            </h2>
          )}
        </div>
      )}
      {revealWord && (
        <div
          className={classes(classNames.revealWord, {
            [classNames.revealWordNaturalConversation]:
              TEMP_GAMETYPE === NewGameTypes.NaturalConversation,
          })}
        >
          {TEMP_GAMETYPE === NewGameTypes.NaturalConversation
            ? audioTextQuestion
            : currentQuestion.question}
        </div>
      )}
    </>
  );

  const multiChoiceQuestionComponent = (
    <MultiChoiceQuestion
      question={currentQuestion}
      handleRetry={handleRetry}
      emitOnNextQuestion={emitOnNextQuestion}
    />
  );

  function isDistractorObject(distractor: any): distractor is { text: string } {
    return (
      typeof distractor === 'object' && distractor !== null && typeof distractor.text === 'string'
    );
  }

  const levelTestOptions = useMemo(() => {
    if (Array.isArray(currentQuestion?.distractors) && currentQuestion?.distractors.length > 0) {
      return commonUtils.shuffleArray([
        currentQuestion.answer.text,
        ...currentQuestion.distractors.map((distractor: string | { text: string }) =>
          isDistractorObject(distractor) ? distractor.text : distractor
        ),
      ]);
    } else {
      return [currentQuestion.answer.text];
    }
  }, [currentQuestion]);

  const multiChoiceOptionsComponent = (
    <MultiChoiceOptions
      pickedAnswer={pickedAnswer ?? ''}
      showAnswerInOptions={showAnswerInOptions}
      correctAnswer={isLevelTest ? currentQuestion.answer.text : correctAnswer}
      coins={isLevelTest ? 0 : currentQuestion.coins}
      options={isLevelTest ? levelTestOptions : options}
      onSelect={onPickOption}
      className={classNames.MultiChoiceOptions}
    />
  );

  switch (TEMP_GAMETYPE) {
    case NewGameTypes.AudioToImage:
      return (
        <div className={classNames.audioToImageWrapper}>
          <div className={classes(classNames.audioToImageBlock, classNames.audioToImageVoice)}>
            <div className={classNames.audioToImageQuestionWrapper}>
              {revealWord && (
                <div className={classes(classNames.revealWord, classNames.revealWordAudioToImage)}>
                  {audioTextQuestion}
                </div>
              )}
              <div className={classNames.audioToImageQuestion}>{multiChoiceQuestionComponent}</div>
            </div>
          </div>
          <div className={classNames.audioToImageBlock}>
            {isDesktop && (
              <div className={classNames.imagesSideBlockInstructions}>
                {commonUtils.getQuestionInstructions(currentQuestion, selectedQuiz)}
              </div>
            )}
            {multiChoiceOptionsComponent}
          </div>
        </div>
      );

    case NewGameTypes.TextToImage:
      return (
        <div className={classNames.audioToImageWrapper}>
          <div className={classNames.audioToImageVoice} style={isDesktop ? { width: '50%' } : {}}>
            <div className={classNames.audioToImageQuestion}>{multiChoiceQuestionComponent}</div>
          </div>
          <div className={classNames.audioToImageBlock}>
            {isDesktop && (
              <div className={classNames.imagesSideBlockInstructions}>
                {commonUtils.getQuestionInstructions(currentQuestion, selectedQuiz)}
              </div>
            )}
            {multiChoiceOptionsComponent}
          </div>
        </div>
      );

    case NewGameTypes.TextToText:
      return (
        <div
          className={classes(classNames.multiChoice, {
            [classNames.multiChoiceFinalAssessment]: isFinalAssessment,
          })}
        >
          {instructionComponent}
          {isLevelTest && isLevelTestLoading ? (
            <AppLoaderCircle className='m-auto' />
          ) : (
            <>
              {multiChoiceQuestionComponent}
              {multiChoiceOptionsComponent}
            </>
          )}
        </div>
      );

    case NewGameTypes.Grammar:
      return (
        <div
          className={classes(classNames.multiChoice, {
            [classNames.multiChoiceFinalAssessment]: isFinalAssessment,
          })}
        >
          {instructionComponent}

          {multiChoiceQuestionComponent}

          {multiChoiceOptionsComponent}
        </div>
      );

    case NewGameTypes.ImageToText:
      return (
        <div className={classes(classNames.audioToImageWrapper, classNames.imageToTextWrapper)}>
          <div
            className={classes(
              classNames.audioToImageBlock,
              classNames.audioToImageVoice,
              classNames.imageToTextQuestion
            )}
          >
            <div className={classNames.audioToImageQuestion}>
              <MultiChoiceQuestionContent currentQuestion={currentQuestion} />
            </div>
          </div>
          <div className={classNames.audioToImageBlock} style={isMobile ? { padding: 0 } : {}}>
            {isDesktop && (
              <div className={classNames.imageToTextAnswerTitle}>
                {commonUtils.getQuestionInstructions(currentQuestion, selectedQuiz)}
              </div>
            )}
            {multiChoiceOptionsComponent}
          </div>
        </div>
      );

    default:
      return (
        <div
          className={classes(classNames.multiChoice, {
            [classNames.multiChoiceFinalAssessment]: isFinalAssessment,
            [classNames.multiChoiceVocabulary]: TEMP_GAMETYPE === NewGameTypes.Vocabulary,
          })}
        >
          {instructionComponent}

          {multiChoiceQuestionComponent}

          {multiChoiceOptionsComponent}
        </div>
      );
  }
};

export default MultiChoice;
