import classNames from './DictionaryCard.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import commonUtils from '@/utils/common';
import UseScreenSize from '@/hooks/UseScreenSize';
import { DictionaryWord } from '@/types/dictionary';

import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';
import AppModal from '@/components/AppModal';
import AppBackdrop from '@/components/AppBackdrop';

import add from '@/assets/svg/add.svg';
import close from '@/assets/svg/close.svg';
import soundPlay from '@/assets/svg/speaker.svg';
import list from '@/assets/svg/list.svg';
import shuffle from '@/assets/svg/shuffle.svg';
import rightArrow from '@/assets/svg/right-arrow.svg';
import upArrow from '@/assets/svg/up-arrow.svg';
import downArrow from '@/assets/svg/down-arrow.svg';
import connect from '@/assets/svg/connect.svg';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';
import GameUtils from '@/utils/gameUtils';

interface DictionaryCardProps {
  onValueSearch: (value: string) => void;
}

const DictionaryCard = ({
  id,
  inflections,
  inputLanguageMeanings,
  outputLanguageMeaningsString,
  sampleSentences,
  synonymsList,
  wotDimageURL,
  partOfSpeech,
  onValueSearch,
}: DictionaryWord & DictionaryCardProps) => {
  const { t } = useTranslation();
  const { isMobile } = UseScreenSize();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');

  const displayText = inputLanguageMeanings?.map(group => group.map(i => i.displayText)).flat()[0];

  const soundPath = inputLanguageMeanings?.map(group => group.map(i => i.soundURL)).flat()[0];

  const inflectionsText = inflections?.map(inflection => inflection.text).join(', ');

  const spacing = 24;

  const exampleSentencesTopRef = useRef<HTMLButtonElement>(null);
  const exampleSentencesBodyRef = useRef<HTMLDivElement>(null);

  const [shouldExpandExampleSentences, setShouldExpandExampleSentences] = useState(false);
  const [exampleSentencesMaxHeight, setExampleSentencesMaxHeight] = useState<number | string>(50);

  const synonymsTopRef = useRef<HTMLButtonElement>(null);
  const synonymsBodyRef = useRef<HTMLDivElement>(null);

  const [shouldExpandSynonyms, setShouldExpandSynonyms] = useState(false);
  const [synonymsMaxHeight, setSynonymsMaxHeight] = useState<number | string>(50);

  useEffect(() => {
    const exampleSentencesTopRefHeight = exampleSentencesTopRef.current?.clientHeight;
    const exampleSentencesBodyRefHeight = exampleSentencesBodyRef.current?.clientHeight;

    if (exampleSentencesTopRefHeight && exampleSentencesBodyRefHeight) {
      setExampleSentencesMaxHeight(() =>
        shouldExpandExampleSentences
          ? exampleSentencesTopRefHeight + exampleSentencesBodyRefHeight + spacing
          : exampleSentencesTopRefHeight
      );
    }
  }, [shouldExpandExampleSentences]);

  useEffect(() => {
    const synonymsTopRefHeight = synonymsTopRef.current?.clientHeight;
    const synonymsBodyRefHeight = synonymsBodyRef.current?.clientHeight;

    if (synonymsTopRefHeight && synonymsBodyRefHeight) {
      setSynonymsMaxHeight(() =>
        shouldExpandSynonyms
          ? synonymsTopRefHeight + synonymsBodyRefHeight + spacing
          : synonymsTopRefHeight
      );
    }
  }, [shouldExpandSynonyms]);

  const onExpandexampleSentences = () => {
    setShouldExpandExampleSentences(prevState => !prevState);
  };

  const onExpandSynonyms = () => {
    setShouldExpandSynonyms(prevState => !prevState);
  };

  const onPlaySound = () => {
    if (soundPath) {
      new Audio(soundPath).play();
    }
  };

  const onAdd = () => {};

  const copyrightElement = null;
  // <span className={classNames.copyright}>&copy;{` ${imageSource}`}</span>

  const onDisplaySlider = (type: string) => {
    setModalType(type);
    setShowModal(true);
  };

  const onClose = () => {
    setModalType('');
    setShowModal(false);
  };

  const exampleSentencesElements = sampleSentences?.map((exampleSentence, index) => {
    return (
      <div key={index} className={classNames.exampleSentence}>
        <span>&#x25CF;</span>
        <div
          key={index}
          className={classNames.text}
          dangerouslySetInnerHTML={{
            __html: commonUtils.formatText(exampleSentence),
          }}
        ></div>
      </div>
    );
  });

  const synonymsElements = synonymsList?.map((synonym, index) => (
    <button key={index} className={classNames.synonym} onClick={() => onValueSearch(synonym)}>
      <span>{synonym}</span>
      <SVG src={rightArrow} />
    </button>
  ));

  const isSynonyms = modalType === 'synonyms';

  const bottom = isMobile ? (
    <>
      {sampleSentences?.length ? (
        <button onClick={() => onDisplaySlider('exampleSentences')}>
          <SVG src={list} />
          <span>{t('exampleSentences')}</span>
          <SVG src={rightArrow} />
        </button>
      ) : null}
      {synonymsList?.length ? (
        <button onClick={() => onDisplaySlider('synonyms')}>
          <SVG src={shuffle} />
          <span>{t('synonyms')}</span>
          <SVG src={rightArrow} />
        </button>
      ) : null}
    </>
  ) : (
    <>
      {sampleSentences?.length ? (
        <div
          className={classNames.exampleSentencesContainer}
          style={{ maxHeight: exampleSentencesMaxHeight }}
        >
          <button
            ref={exampleSentencesTopRef}
            className={classNames.top}
            onClick={onExpandexampleSentences}
          >
            <SVG src={list} />
            <span className='flex-1'>{t('exampleSentences')}</span>
            <SVG
              className={classes(classNames.arrow, [
                classNames[`arrow-${interfaceLanguage?.direction}`],
              ])}
              src={shouldExpandExampleSentences ? upArrow : downArrow}
            />
          </button>
          <div ref={exampleSentencesBodyRef} className={classNames.body}>
            {exampleSentencesElements}
          </div>
        </div>
      ) : null}
      {synonymsList?.length ? (
        <>
          <SectionSeperator className='my-8' />
          <div className={classNames.synonymsContainer} style={{ maxHeight: synonymsMaxHeight }}>
            <button ref={synonymsTopRef} className={classNames.top} onClick={onExpandSynonyms}>
              <SVG src={shuffle} />
              <span className='flex-1'>{t('synonyms')}</span>
              <SVG
                className={classes(classNames.arrow, [
                  classNames[`arrow-${interfaceLanguage?.direction}`],
                ])}
                src={shouldExpandSynonyms ? upArrow : downArrow}
              />
            </button>
            <div ref={synonymsBodyRef} className={classNames.body}>
              {synonymsElements}
            </div>
          </div>
        </>
      ) : null}
    </>
  );

  return (
    <div id={`dictionary-card-${partOfSpeech}-${id}`} className={classNames.dictionaryCard}>
      <div className={classNames.content}>
        <div className={classNames.heading}>
          <div className={classNames.partOne}>
            <div className={classNames.details}>
              {soundPath && (
                <button aria-label={t('play_sound').toString()} onClick={onPlaySound}>
                  <SVG src={soundPlay} />
                </button>
              )}
              <h2>{displayText}</h2>
              {partOfSpeech && <span>{partOfSpeech}</span>}
            </div>
            {/* <button onClick={onAdd}>
              <SVG src={add} />
            </button> */}
          </div>
          {inflectionsText && <span className={classNames.inflectionsText}>{inflectionsText}</span>}
          {outputLanguageMeaningsString && (
            <span className={classNames.translation}>{outputLanguageMeaningsString}</span>
          )}
        </div>
        {wotDimageURL && (
          <div className={classNames.img} style={{ backgroundImage: `url(${wotDimageURL})` }}>
            {/* {copyrightElement} */}
          </div>
        )}
      </div>
      {sampleSentences?.length || synonymsList?.length ? (
        <SectionSeperator className={isMobile ? 'my-6' : 'my-8'} />
      ) : null}
      <div className={classNames.bottom}>{bottom}</div>
      <Transition
        in={showModal}
        timeout={400}
        mountOnEnter
        unmountOnExit
        children={state => {
          return (
            <>
              <AppBackdrop
                className={classes(classNames.backdrop, `backdrop-${state}`)}
                emitClose={onClose}
              />
              <AppModal>
                <div className={classes(classNames.modal, `slide-vertical-${state}`)}>
                  <button className={classNames.close} onClick={onClose}>
                    <SVG src={close} />
                  </button>
                  <div className={classNames.top}>
                    <div className={classNames.partOne}>
                      <SVG src={isSynonyms ? shuffle : connect} />
                      <h2>{isSynonyms ? t('synonyms') : t('exampleSentences')}</h2>
                    </div>
                    <div className={classNames.results}>
                      <span>{`${t('thereAre')} ${
                        isSynonyms ? synonymsList?.length : sampleSentences?.length
                      } ${
                        isSynonyms ? t('synonyms').toLowerCase() : t('examples')
                      } ${t('for')} `}</span>
                      <span className={classNames.entry}>{displayText}</span>
                    </div>
                  </div>
                  <SectionSeperator className='mt-4 mb-6' />
                  <div className={classNames.body}>
                    {isSynonyms ? synonymsElements : exampleSentencesElements}
                  </div>
                </div>
              </AppModal>
            </>
          );
        }}
      />
    </div>
  );
};

export default DictionaryCard;
