import classNames from './SectionSeperator.module.scss';
import classes from 'classnames';

interface SectionSeperatorProps {
  className?: string;
}

const SectionSeperator = ({ className }: SectionSeperatorProps) => {
  return (
    <div className={classes(classNames.sectionSeperator, className)}>
      <div className={classNames.seperator}></div>
    </div>
  );
};

export default SectionSeperator;
