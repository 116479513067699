import speak1 from '@/assets/svg/skills/speak.svg';
import speak2 from '@/assets/svg/skills/speak2.svg';
import speak3 from '@/assets/svg/skills/speak3.svg';
import speak4 from '@/assets/svg/skills/speak4.svg';
import speak5 from '@/assets/svg/skills/speak5.svg';
import speak6 from '@/assets/svg/skills/speak6.svg';
import listen1 from '@/assets/svg/skills/listen.svg';
import listen2 from '@/assets/svg/skills/listen2.svg';
import listen3 from '@/assets/svg/skills/listen3.svg';
import listen4 from '@/assets/svg/skills/listen4.svg';
import listen5 from '@/assets/svg/skills/listen5.svg';
import listen6 from '@/assets/svg/skills/listen6.svg';
import read1 from '@/assets/svg/skills/read.svg';
import read2 from '@/assets/svg/skills/read2.svg';
import read3 from '@/assets/svg/skills/read3.svg';
import read4 from '@/assets/svg/skills/read4.svg';
import read5 from '@/assets/svg/skills/read5.svg';
import read6 from '@/assets/svg/skills/read6.svg';
import grammar1 from '@/assets/svg/skills/grammar.svg';
import grammar2 from '@/assets/svg/skills/grammar2.svg';
import grammar3 from '@/assets/svg/skills/grammar3.svg';
import grammar4 from '@/assets/svg/skills/grammar4.svg';
import grammar5 from '@/assets/svg/skills/grammar5.svg';
import grammar6 from '@/assets/svg/skills/grammar6.svg';
import spelling1 from '@/assets/svg/skills/spelling.svg';
import spelling2 from '@/assets/svg/skills/spelling2.svg';
import spelling3 from '@/assets/svg/skills/spelling3.svg';
import spelling4 from '@/assets/svg/skills/spelling4.svg';
import spelling5 from '@/assets/svg/skills/spelling5.svg';
import spelling6 from '@/assets/svg/skills/spelling6.svg';
import vocabulary1 from '@/assets/svg/skills/vocabulary.svg';
import vocabulary2 from '@/assets/svg/skills/vocabulary2.svg';
import vocabulary3 from '@/assets/svg/skills/vocabulary3.svg';
import vocabulary4 from '@/assets/svg/skills/vocabulary4.svg';
import vocabulary5 from '@/assets/svg/skills/vocabulary5.svg';
import vocabulary6 from '@/assets/svg/skills/vocabulary6.svg';
import naturalConversation1 from '@/assets/svg/skills/naturalConversation.svg';
import naturalConversation2 from '@/assets/svg/skills/naturalConversation2.svg';
import naturalConversation3 from '@/assets/svg/skills/naturalConversation3.svg';
import naturalConversation4 from '@/assets/svg/skills/naturalConversation4.svg';
import naturalConversation5 from '@/assets/svg/skills/naturalConversation5.svg';
import naturalConversation6 from '@/assets/svg/skills/naturalConversation6.svg';
import openWriting1 from '@/assets/svg/skills/openWriting.svg';
import openWriting2 from '@/assets/svg/skills/openWriting2.svg';
import openWriting3 from '@/assets/svg/skills/openWriting3.svg';
import openWriting4 from '@/assets/svg/skills/openWriting4.svg';
import openWriting5 from '@/assets/svg/skills/openWriting5.svg';
import openWriting6 from '@/assets/svg/skills/openWriting6.svg';
import memory1 from '@/assets/svg/skills/memory.svg';
import memory2 from '@/assets/svg/skills/memory2.svg';
import memory3 from '@/assets/svg/skills/memory3.svg';
import memory4 from '@/assets/svg/skills/memory4.svg';
import memory5 from '@/assets/svg/skills/memory5.svg';
import memory6 from '@/assets/svg/skills/memory6.svg';
import imageToText1 from '@/assets/svg/skills/imageToText.svg';
import imageToText2 from '@/assets/svg/skills/imageToText2.svg';
import imageToText3 from '@/assets/svg/skills/imageToText3.svg';
import imageToText4 from '@/assets/svg/skills/imageToText4.svg';
import imageToText5 from '@/assets/svg/skills/imageToText5.svg';
import imageToText6 from '@/assets/svg/skills/imageToText6.svg';
import textToImage1 from '@/assets/svg/skills/textToImage.svg';
import textToImage2 from '@/assets/svg/skills/textToImage2.svg';
import textToImage3 from '@/assets/svg/skills/textToImage3.svg';
import textToImage4 from '@/assets/svg/skills/textToImage4.svg';
import textToImage5 from '@/assets/svg/skills/textToImage5.svg';
import textToImage6 from '@/assets/svg/skills/textToImage6.svg';
import textToText1 from '@/assets/svg/skills/textToText.svg';
import textToText2 from '@/assets/svg/skills/textToText2.svg';
import textToText3 from '@/assets/svg/skills/textToText3.svg';
import textToText4 from '@/assets/svg/skills/textToText4.svg';
import textToText5 from '@/assets/svg/skills/textToText5.svg';
import textToText6 from '@/assets/svg/skills/textToText6.svg';

const unitImages: any = {
  speak1,
  speak2,
  speak3,
  speak4,
  speak5,
  speak6,
  listen1,
  listen2,
  listen3,
  listen4,
  listen5,
  listen6,
  read1,
  read2,
  read3,
  read4,
  read5,
  read6,
  grammar1,
  grammar2,
  grammar3,
  grammar4,
  grammar5,
  grammar6,
  spelling1,
  spelling2,
  spelling3,
  spelling4,
  spelling5,
  spelling6,
  vocabulary1,
  vocabulary2,
  vocabulary3,
  vocabulary4,
  vocabulary5,
  vocabulary6,
  naturalConversation1,
  naturalConversation2,
  naturalConversation3,
  naturalConversation4,
  naturalConversation5,
  naturalConversation6,
  openWriting1,
  openWriting2,
  openWriting3,
  openWriting4,
  openWriting5,
  openWriting6,
  memory1,
  memory2,
  memory3,
  memory4,
  memory5,
  memory6,
  imageToText1,
  imageToText2,
  imageToText3,
  imageToText4,
  imageToText5,
  imageToText6,
  textToImage1,
  textToImage2,
  textToImage3,
  textToImage4,
  textToImage5,
  textToImage6,
  textToText1,
  textToText2,
  textToText3,
  textToText4,
  textToText5,
  textToText6,
};
export default unitImages;
