import { useTranslation } from 'react-i18next';
import classNames from './StudentDataModal.module.scss';
import { Transition } from 'react-transition-group';
import AppBackdrop from '../AppBackdrop';
import AppModal from '../AppModal';
import UnitData from '@/components/StudentsData/UserData/UnitData/UnitData';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import close from '@/assets/svg/close.svg';
import trophy from '@/assets/svg/trophy.svg';
import medal from '@/assets/svg/medal-gold.svg';
import star from '@/assets/svg/star.svg';
import { Unit, Topic } from '@/types/units';
import { UserState } from '@/types/user';
import { UnitsProgress } from '@/types/progress';
import { image } from 'html2canvas/dist/types/css/types/image';

interface StudentDataModalProps {
  showModal: boolean;
  onHideDataModal: () => void;
  isMobile: boolean;
  unitId: number;
  units: Unit[];
  topics: { [key: number]: Topic[] };
  student: UserState | null;
  unitsProgress: UnitsProgress | null;
}

const StudentDataModal = ({
  showModal,
  onHideDataModal,
  isMobile,
  unitId,
  units,
  topics,
  student,
  unitsProgress,
}: StudentDataModalProps) => {
  const { t } = useTranslation();

  const unit = units.find(obj => obj.id === unitId);
  const unitProgress = unitsProgress?.find(obj => obj.unitId === unitId);

  return (
    <Transition
      in={showModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => (
        <>
          <AppBackdrop
            className={classes(classNames.backdrop, `backdrop-${state}`)}
            emitClose={onHideDataModal}
          />
          <AppModal>
            {isMobile ? (
              <div className={classes(classNames.viewBlocker, `fade-${state}`)}>
                <button className={classNames.close} onClick={onHideDataModal}>
                  <SVG src={close} />
                </button>
                <button className={classNames.text} onClick={onHideDataModal}>
                  {t('fullVersionAvilableOnlyOnDesktop')}
                </button>
              </div>
            ) : (
              <div
                className={classes(
                  classNames.modal
                  // `slide-horizontal-${interfaceLanguage?.direction}-${state}`
                )}
              >
                <button className={classNames.close} onClick={onHideDataModal}>
                  <SVG src={close} />
                </button>
                <div className={classNames.unitData}>
                  {unitProgress && (
                    <UnitData
                      unitId={unitProgress.unitId}
                      studentId={student?.metadata?.id || ''}
                      unitName={unitProgress.unitName}
                      completionPercentage={unitProgress.progress}
                      studentName={`${student?.metadata?.firstName} ${student?.metadata?.lastName}`}
                      level={student?.progress?.userLevel || 0}
                      completedTopics={unitProgress.completedTopics}
                      totalTopics={unitProgress.totalTopics}
                      completedActivities={unitProgress.completedActivities}
                      totalActivities={unitProgress.totalActivites}
                      achievedStars={unitProgress.achievedStars}
                      totalStars={unitProgress.totalStars}
                    />
                  )}
                </div>
              </div>
            )}
          </AppModal>
        </>
      )}
    />
  );
};

export default StudentDataModal;
