import React, { useEffect } from 'react';
import classNames from './QuizPageIndicators.module.scss';
import { useTranslation } from 'react-i18next';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import GameUtils from '@/utils/gameUtils';
import { useDispatch } from 'react-redux';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { GameStatus, NewGameTypes } from '@/types/game';

import coin from '@/assets/svg/coin.svg';
import clock from '@/assets/svg/clock.svg';
import hint from '@/assets/svg/hint.svg';
import { SkillTypes } from '@/types/skill';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import UserUtils from '@/utils/user';

interface QuizPageIndicatorsProps {
  className?: string;
  earnedCoins: number;
  coins?: number;
  timer: number;
  timesUp: boolean;
  selectedType?: SkillTypes | null;
}

const QuizPageIndicators = ({
  className,
  earnedCoins,
  coins,
  timer,
  timesUp,
  selectedType,
}: QuizPageIndicatorsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { gameStatus, gameQuestions, isLevelTest } = useAppSelector(gameSelector);
  //FIXME: NEED TO REPLACE WITH CORRECT GAMETYPE
  const TEMP_GAMETYPE = gameQuestions[0]?.gameType as any;
  const { user } = useAppSelector(authSelector);

  const isStudent = UserUtils.isStudent(user);

  const shouldDisableHints =
    (isStudent && (coins ?? 0) < 5) ||
    gameStatus === GameStatus.QUESTION_ANSWERED ||
    gameStatus === GameStatus.CHECK_ANSWER ||
    gameStatus === GameStatus.TIME_ENDED;

  const isRead = TEMP_GAMETYPE === NewGameTypes.Read;

  const onOpenHintsModal = () => {
    if (shouldDisableHints) {
      return;
    }

    dispatch(gameActions.toggleGameStatus(GameStatus.SHOW_HINTS));
  };

  return isLevelTest ? (
    <></>
  ) : (
    <div
      className={classes(classNames.quizPageIndicators, className, {
        [classNames.quizPageIndicatorsRead]: isRead,
      })}
    >
      {isStudent && (
        <div className={classNames.container}>
          <SVG src={coin} />
          <div className={classNames.coins}>
            <span>{coins}</span>
            {earnedCoins > 0 && <div className={classNames.earnedCoins}>+{earnedCoins}</div>}
          </div>
        </div>
      )}
      <div
        className={classes(classNames.container, {
          [classNames.clockHighlight]: timesUp && gameStatus != GameStatus.ENDED,
        })}
      >
        <SVG src={clock} />
        <span>{GameUtils.gameTimeString(timer)}</span>
      </div>
      <button
        onClick={onOpenHintsModal}
        className={classes(classNames.container, {
          [classNames.containerDisabled]: shouldDisableHints,
        })}
      >
        <SVG src={hint} />
        <span>{t('help')}</span>
      </button>
    </div>
  );
};

export default React.memo(QuizPageIndicators);
