import { useTranslation } from 'react-i18next';
import classNames from './MyProgress.module.scss';
import classes from 'classnames';
import UseThemeColors from '@/hooks/UseThemeColors';
import { SkillTypes } from '@/types/skill';
import commonUtils from '@/utils/common';
import { UnitsProgress } from '@/types/progress';
import { authSelector } from '@/store/reducers/auth';
import { appSettingsSelector } from '@/store/reducers/settings';
import { gameActions } from '@/store/reducers/game';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store';

import ProgressCard from '@/components/ProgressCard/ProgressCard';
import quizzesThunks from '@/store/thunks/quizzes';
import UseScreenSize from '@/hooks/UseScreenSize';

interface MyProgressProps {
  className?: string;
  unitsProgress: UnitsProgress | null;
  onProgressCardClick: (id: number) => void;
}

const MyProgress = ({ className, unitsProgress, onProgressCardClick }: MyProgressProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { color } = UseThemeColors();
  const { isMobile } = UseScreenSize();

  const { comingUpQuizzes, skillSettings, user } = useAppSelector(authSelector);
  const { topics, quizzes } = useAppSelector(appSettingsSelector);

  const progressCards = unitsProgress
    ? Object.entries(unitsProgress).map(([key, value]) => {
        const { trailColor, pathColor } = commonUtils.getNewProgressColors(value.order) ?? {};
        const percentage = value.progress;

        const topics = {
          completed: value.completedTopics,
          total: value.totalTopics,
        };

        const activities = {
          completed: value.completedActivities,
          total: value.totalActivites,
        };

        return (
          <ProgressCard
            key={key}
            unitId={value.unitId}
            title={value.unitName}
            percentage={percentage}
            topics={topics}
            activities={activities}
            user={user}
            trailColor={color(trailColor ?? '') ?? ''}
            pathColor={color(pathColor ?? '') ?? ''}
            onClick={onProgressCardClick}
            className={isMobile ? classNames.progressCardMobile : ''}
            topicsClassName={classNames.progressCardTopics}
            showViewPerformanceBtn={!isMobile}
          />
        );
      })
    : null;

  return (
    <div className={classes(classNames.myProgress, className)}>
      {!isMobile && <h2>{t('myProgress')}</h2>}
      <div className={classNames.progressCards}>{progressCards}</div>
    </div>
  );
};

export default MyProgress;
