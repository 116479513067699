import React from 'react';
import classNames from './ProgressCart.module.scss';

interface ProgressCartProps {
  width: string;
  height: string;
  title: string;
  description: string;
  items: {
    label: string;
    value: number;
    color: string;
  }[];
}

const ProgressCart = ({ width, height, title, description, items }: ProgressCartProps) => {
  return (
    <div className={classNames.card} style={{ width: width, height: height }}>
      <div className={classNames.header}>
        <div className={classNames.title}>
          <span className={classNames.text}>{title}</span>
        </div>
        <div className={classNames.description}>
          <span className={classNames.text}>{description}</span>
        </div>
      </div>
      <div className={classNames.list}>
        {items.map((item, index) => {
          const value = `${item.value}%`;

          return (
            <React.Fragment key={`item--${index}`}>
              <div className={classNames.item}>
                <div className={classNames.label}>
                  <span className={classNames.text}>{item.label}</span>
                </div>
                <div className={classNames.progress}>
                  {item.value > 0 && (
                    <>
                      <div
                        className={classNames.progressBat}
                        style={{ width: value, background: item.color }}
                      ></div>
                      <div className={classNames.value} style={{ insetInlineStart: value }}>
                        <span className={classNames.text}>{value}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressCart;
