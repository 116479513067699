import classNames from './MultiChoiceQuestionContent.module.scss';

const MultiChoiceQuestionContent = (props: any) => {
  const { currentQuestion } = props;

  const image = currentQuestion?.question || '';
  const copyright = image && currentQuestion?.imagesCredits?.[image];
  const imageAlt = image && currentQuestion?.imagesAlt?.[image];

  return (
    <div className={classNames.multiChoiceQuestionContent}>
      <img src={image} alt={imageAlt} />
      {copyright && (
        <span className={classNames.copyright}>
          <span>{copyright}</span>
        </span>
      )}
    </div>
  );
};

export default MultiChoiceQuestionContent;
