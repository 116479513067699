import { createAsyncThunk } from '@reduxjs/toolkit';
import { unitService } from '@/services/unit';
import { RootState } from '@/types/common';

const unitTopicsThunks = {
  fetchUnitTopics: createAsyncThunk(
    'units/fetchUnitTopics',
    async ({ level, unitId }: { level: number; unitId: number }, { getState }) => {
      try {
        const state = getState() as RootState;
        const user = state.auth.user;
        const response = await unitService.fetchUnitTopics(user, level, unitId);
        return { unitId, topics: response.data.data };
      } catch (error: any) {
        console.log('error', error);
        throw error;
      }
    }
  ),
};

export default unitTopicsThunks;
