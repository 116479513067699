import React from 'react';
import {
  GameTypeProps,
  NewGameTypes,
  GameTypes,
  MultiChoiceProps,
  ClosedSpellingProps,
} from '@/types/game';

import MultiChoice from './MultiChoice/MultiChoice';
import ClosedSpelling from './ClosedSpelling/ClosedSpelling';
import Pronunciation from './Pronunciation/Pronunciation';
import Practice from './Practice/Practice';
import OpenWriting from './OpenWriting/OpenWriting';
import Memory from './Memory/Memory';

const GameTypeComponents = [
  {
    type: NewGameTypes.Vocabulary,
    component: MultiChoice,
  },
  {
    type: NewGameTypes.TextToText,
    component: MultiChoice,
  },
  {
    type: NewGameTypes.Grammar,
    component: MultiChoice,
  },
  {
    type: NewGameTypes.ImageToText,
    component: MultiChoice,
  },
  {
    type: NewGameTypes.AudioToImage,
    component: MultiChoice,
  },
  {
    type: NewGameTypes.TextToImage,
    component: MultiChoice,
  },
  {
    type: NewGameTypes.Listen,
    component: MultiChoice,
  },
  {
    type: GameTypes.ClosedSpelling,
    component: ClosedSpelling,
  },
  {
    type: GameTypes.Pronunciation,
    component: Pronunciation,
  },
  {
    type: GameTypes.Practice,
    component: Practice,
  },
  {
    type: NewGameTypes.OpenWriting,
    component: OpenWriting,
  },
  {
    type: NewGameTypes.Memory,
    component: Memory,
  },
];

const GameType = (props: GameTypeProps & (MultiChoiceProps | ClosedSpellingProps)) => {
  if (props.isLevelTest) {
    return <MultiChoice {...props} />;
  }
  const gameTypeComponent = GameTypeComponents.find(component => {
    if (props.type === GameTypes.Practice) {
      return component.type === props.type;
    } else if (props.currentQuestion?.gameType === (NewGameTypes.NaturalConversation as any)) {
      return component.type === props.currentQuestion?.newData?.data?.metadata?.skillId;
    } else return component.type === props.currentQuestion?.gameType;
  });

  const ComponentToRender = gameTypeComponent && gameTypeComponent.component;

  return ComponentToRender ? <ComponentToRender {...props} /> : null;
};

export default React.memo(GameType);
