import classNames from './PopUpOpenWrittingSuccess.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import success from '@/assets/svg/check.svg';

//FIXME: Need to change any for needed type
const PopUpOpenWrittingSuccess = () => {
  const { t } = useTranslation();

  return (
    <div className={classNames.popUpOpenWrittingSuccess}>
      <div className={classNames.title}>
        <SVG src={success} />
        <p>{t('thanksForSubmission')}</p>
      </div>
    </div>
  );
};

export default PopUpOpenWrittingSuccess;
