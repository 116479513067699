import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import userThunks from '@/store/thunks/user';
import { useTranslation } from 'react-i18next';
import topicsThunks from '@/store/thunks/topics';
import UserUtils from '@/utils/user';
import { cognitoService } from '@/services/cognito';
import { CognitoSession } from '@/types/user';

const UseCheckAuth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loggedIn, user, appLoaded, interfaceLanguage, loggedUserId } =
    useAppSelector(authSelector);
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [fetchingProgress, setFetchingProgress] = useState(false);
  const checkAuthTimer = useRef<any>();

  const fetchProgressAndSkills = async () => {
    await Promise.all([
      dispatch(userThunks.fetchLevels()).unwrap(),
      dispatch(userThunks.fetchLanguages()).unwrap(),

      //FIXME: Old functionality, need to be removed. + Meed to remove all services and thunks
      // dispatch(topicsThunks.prefetchSkillTopics()).unwrap(),
    ]);
  };

  const checkAuthStatus = async () => {
    // If the loggedIn state is set to false, it means the localStorage has no item called 'loggedIn'
    // We are going to use cookies in the future, so that will be our only indicator for whether the user is logged in or not
    if (!localStorage.getItem('token')) {
      dispatch(authActions.appLoaded());

      const isRegistrationPage = window.location.pathname.startsWith('/registration');

      if (isRegistrationPage) {
        return;
      }

      return navigate('/login');
    }

    // Since the cookie is going to be HTTP only which means accessed only on backend,
    // We will an authentication request and if the server verifies the user, they will remain logged in

    try {
      setFetchingProgress(true);
      const user = await dispatch(userThunks.userInfo(1)).unwrap();
      const isTeacher = UserUtils.isTeacher(user?.data);
      const isAdmin = UserUtils.isAdmin(user?.data);

      const cognitoSession = (await cognitoService.checkCognitoSession()) as CognitoSession;
      if (cognitoSession && isTeacher) {
        localStorage.setItem('teacher-level', cognitoSession.teacherLevel.toString());
        dispatch(authActions.setUserLevel(parseInt(cognitoSession.teacherLevel.toString())));
      }

      if ((!isAdmin && user?.data.progress?.userLevel) || isTeacher) {
        // TODO: remove try catch, after fetchProgressAndSkills fix and removed old functionality
        try {
          await fetchProgressAndSkills();
        } catch (ex) {}
      } else {
        await dispatch(userThunks.fetchLanguages()).unwrap();
      }

      setFetchingProgress(false);

      dispatch(authActions.appLoaded());
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (user?.progress?.userLevel && !fetchingProgress) {
      fetchProgressAndSkills();
    }
  }, [user?.progress?.userLevel]);

  useEffect(() => {
    if (interfaceLanguage) {
      i18n.changeLanguage(interfaceLanguage);

      const interdeal = (window as any).interdeal;
      if (interdeal) {
        interdeal.Menulang = interfaceLanguage;
        if (interdeal.SetLocale) {
          try {
            interdeal.SetLocale(interfaceLanguage);
          } catch (error) {}
        }
      }
    }
  }, [interfaceLanguage]);

  useEffect(() => {
    const lastUrl = window.location.href
      .split('/')
      .filter(url => url.length)
      .pop();

    const isTeacher = UserUtils.isTeacher(user);
    const isAdmin = UserUtils.isAdmin(user);

    if (appLoaded && loggedIn) {
      if (isAdmin) {
        return navigate('/onboarding');
      } else if (isTeacher) {
        if (lastUrl === 'games' && user?.metadata.role === 'teacher') {
          return navigate('/dashboard/games');
        } else if (user?.metadata.role !== 'teacher') {
          return navigate('/dashboard/my-classes');
        }

        if (lastUrl !== 'level-test') {
          return navigate('/dashboard/my-classes');
        }
      }

      if (user?.progress?.userLevel !== undefined && !isTeacher) {
        return navigate('/dashboard/games');
      }

      return navigate('/dashboard/level-test');
    }
  }, [appLoaded, loggedIn]);

  useEffect(() => {
    const clearCheckAuthTimer = () => {
      if (checkAuthTimer.current) {
        clearTimeout(checkAuthTimer.current);
        checkAuthTimer.current = null;
      }
    };

    const checkAuth = () => {
      clearCheckAuthTimer();
      checkAuthStatus();
    };

    let checkLoggedUserIdTimer: any = null;

    const clearCheckLoggedUserIdTimer = () => {
      if (checkLoggedUserIdTimer) {
        clearInterval(checkLoggedUserIdTimer);
        checkLoggedUserIdTimer = null;
      }
    };

    const checkLoggedUserId = () => {
      if (!checkLoggedUserIdTimer) {
        return;
      }

      const localStorageLoggedUserId = localStorage.getItem('loggedUserId') || '';
      const storeLoggedUserId = loggedUserId || '';

      if (localStorageLoggedUserId !== storeLoggedUserId && !localStorageLoggedUserId) {
        dispatch(authActions.setLoggedUserId(localStorageLoggedUserId));
      }
    };

    checkAuthTimer.current = setTimeout(checkAuth, 100);
    checkLoggedUserIdTimer = setInterval(checkLoggedUserId, 1000);

    return () => {
      clearCheckAuthTimer();
      clearCheckLoggedUserIdTimer();
    };
  }, [loggedUserId]);
};

export default UseCheckAuth;
