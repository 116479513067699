import classNames from './ClosedSpellingDictionary.module.scss';
import SVG from 'react-inlinesvg';

import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';
import classes from 'classnames';
import soundPlay from '@/assets/svg/speaker.svg';
import UseScreenSize from '@/hooks/UseScreenSize';
import commonUtils from '@/utils/common';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';

interface ClosedSpellingDictionaryProps {
  answer: string;
  partOfSpeech: string;
  translation: string;
  exampleSentences: string[];
  soundPath: string;
}

const ClosedSpellingDictionary = ({
  answer,
  partOfSpeech,
  translation,
  exampleSentences,
  soundPath,
}: ClosedSpellingDictionaryProps) => {
  const { isMobile } = UseScreenSize();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const onPlaySound = () => {
    new Audio(soundPath).play();
  };
  const exampleSentencesElements = exampleSentences.map((exampleSentence, index) => {
    return (
      <div
        key={index}
        className={classNames.exampleSentence}
        dangerouslySetInnerHTML={{
          __html: commonUtils.formatText(exampleSentence),
        }}
      ></div>
    );
  });

  return (
    <div className={classNames.closedSpellingDictionary}>
      <div
        className={classes(classNames.top, {
          [classNames.isRtl]: interfaceLanguage?.direction === 'rtl',
        })}
      >
        <div className={classNames.text}>
          <span className={classNames.answer}>{answer}</span>
          <div className={classNames.partOfSpeech}>
            <span>{partOfSpeech.toLowerCase()}</span>
          </div>
        </div>
        {soundPath && (
          <button className={classNames.soundBtn} onClick={onPlaySound}>
            <SVG src={soundPlay} />
          </button>
        )}
      </div>
      <div className={classNames.content}>
        <span className={classNames.translation}>{translation}</span>
        <SectionSeperator className={isMobile ? 'my-3' : 'my-6'} />
        <div className={classNames.exampleSentences}>{exampleSentencesElements}</div>
      </div>
    </div>
  );
};

export default ClosedSpellingDictionary;
