import AppBackdrop from '@/components/AppBackdrop';
import AppModal from '@/components/AppModal';
import classes from 'classnames';
import { Transition } from 'react-transition-group';
import classNames from './LevelTestLogOut.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store';
import userThunks from '@/store/thunks/user';
import { authActions, authSelector } from '@/store/reducers/auth';
import { appSettingsActions } from '@/store/reducers/settings';
import { useNavigate } from 'react-router-dom';
import { levelTestActions } from '@/store/reducers/level-test';
import { gameActions } from '@/store/reducers/game';
import UseScreenSize from '@/hooks/UseScreenSize';

type LevelTestLogOutProps = {
  showLogoutModal: boolean;
  setShowLogoutModal: (show: boolean) => void;
};

const LevelTestLogOut = ({ showLogoutModal, setShowLogoutModal }: LevelTestLogOutProps) => {
  const { t, i18n } = useTranslation();
  const { isMobile } = UseScreenSize();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(authSelector);
  const isTeacher = user?.metadata.role === 'teacher';
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.removeItem('userEmail');
    dispatch(userThunks.signOut());
    dispatch(authActions.signOut());
    dispatch(levelTestActions.isLogOut());
    dispatch(appSettingsActions.clearAppSettings());
  };

  return (
    <Transition
      in={showLogoutModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => {
        return (
          <>
            <AppBackdrop
              className={classes(classNames.backdrop, `backdrop-${state}`)}
              emitClose={() => {
                setShowLogoutModal(false);
              }}
            />
            <AppModal>
              <div
                className={classes(classNames.logoutModal, {
                  [`fade-${state}`]: !isMobile,
                  [`slide-vertical-${state}`]: isMobile,
                  [classNames.logoutModalMobile]: isMobile,
                })}
              >
                <div className={classNames.logoutModalTitle}>
                  {t('leave_level_test_and_sign_out')}
                </div>
                <div className={classNames.logoutBtnWrapper}>
                  {isTeacher && (
                    <button
                      onClick={async () => {
                        dispatch(gameActions.setIsLevelTest(false));
                        dispatch(levelTestActions.reset());
                        dispatch(levelTestActions.setIsFinishLevelTest(false));
                        return navigate('/dashboard/games');
                      }}
                    >
                      {t('back_home')}
                    </button>
                  )}
                  {!isTeacher && (
                    <button
                      onClick={() => {
                        logOut();
                      }}
                    >
                      {t('sign_out')}
                    </button>
                  )}

                  <button
                    onClick={() => {
                      setShowLogoutModal(false);
                    }}
                  >
                    {t('continue_level_test')}
                  </button>
                </div>
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );
};

export default LevelTestLogOut;
