import React, { useEffect, useRef, useState } from 'react';
import classNames from './TopicsProgress.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import ProgressBox from './ProgressBox/ProgressBox';
import medalGold from '@/assets/svg/medal-gold.svg';
import star from '@/assets/svg/star.svg';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useTranslation } from 'react-i18next';

interface TopicsProgressProps {
  percentage: number;
  totalActivities: number;
  totalStars: number;
  completedActivities: number;
  completedStars: number;
}

export const TopicsProgress = ({
  percentage,
  totalActivities,
  totalStars,
  completedActivities,
  completedStars,
}: TopicsProgressProps) => {
  const { t } = useTranslation();
  const { isMobileSmall } = UseScreenSize();
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    if (ref.current === null) return;
    setWidth(ref.current.offsetWidth);
  }, []);
  return (
    <div
      className={classes(classNames.topicsProgress, {
        [classNames.topicsProgressMobile]: isMobileSmall,
      })}
    >
      <div className={classNames.progressBarWrapper}>
        {!isMobileSmall && (
          <>
            <SVG src={medalGold} />
            <div className={classNames.topicProgressText}>{t('topicProgress')}</div>
            <div className={classNames.divider} />
            <div className={classNames.finishLevelText}>{t('finishToLevelUp')}</div>
          </>
        )}
        <div className={classNames.progressBarContainer}>
          <div ref={ref} className={classNames.progressBar}></div>
          <div className={classNames.progressBarFull} style={{ width: `${percentage}%` }}></div>
        </div>
        <span className={classNames.percentage}>{percentage}%</span>
      </div>
      <div className={classNames.progressBoxsWrapper}>
        <ProgressBox
          iconName={medalGold}
          title={t('activities')}
          total={totalActivities}
          completed={completedActivities}
        />
        {isMobileSmall && <div className={classNames.divider} />}
        <ProgressBox
          iconName={star}
          title={t('stars')}
          total={totalStars}
          completed={completedStars}
        />
      </div>
    </div>
  );
};
