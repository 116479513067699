import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import classNames from './FinishUnit.module.scss';
import { unitActions } from '@/store/reducers/unit';
import TheButton from '@/components/TheButton/TheButton';
import SVG from 'react-inlinesvg';
import trophy from '@/assets/svg/trophy.svg';
import { useDispatch } from 'react-redux';
import { gameActions } from '@/store/reducers/game';

//FIXME: Need to improve types
type FinishUnitProps = {
  className: any;
  emitOnQuit: (() => void) | undefined;
  selectedUnit: any;
  units: any[];
  topics: any;
};

const FinishUnit = ({ className, emitOnQuit, selectedUnit, units, topics }: FinishUnitProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentUnitOrder = selectedUnit?.order || 1;
  const nextUnitId = units[currentUnitOrder]?.id;
  const nextTopic = topics?.[nextUnitId]?.[0];

  const nextUnitName = units[currentUnitOrder]?.name;

  const goToNextUnit = () => {
    //FIXME: Temporary solution for setSelectedTopic structure
    const mockNextTopic = {
      id: nextTopic?.id || 0,
      nameLocal: null,
      name: nextTopic?.name,
      order: nextTopic?.order,
      quizzesCount: 1,
      topicType: 1,
      activitiesInTopic: nextTopic?.activitiesInTopic,
      userData: {
        receivedStars: 8,
        quizzesCompleted: 3,
      },
    };
    dispatch(gameActions.setSelectedTopic(mockNextTopic));
    dispatch(
      unitActions.setSelectedTopic({
        id: nextTopic?.id || 0,
        name: nextTopic?.name,
        cEFRDescriptor: nextTopic?.cEFRDescriptor,
        canDoStatement: nextTopic?.canDoStatement,
      })
    );
    dispatch(
      unitActions.setSelectedUnit({
        id: units[currentUnitOrder].id,
        name: units[currentUnitOrder]?.name,
        order: units[currentUnitOrder]?.order,
      })
    );
  };

  return (
    <div className={classes(className, classNames.popUpFinishUnit)}>
      <div className={classNames.quizSummary}>
        <div className={classNames.content}>
          <div className={classes(classNames.summary)}>
            <SVG src={trophy} />
            <h3 className={classNames.title}>{t('greatJob')}</h3>
            <p className={classNames.description}>
              <span>
                {t('finishUnitSuccessText', {
                  completedUnitName: selectedUnit?.name,
                  nextUnitName: nextUnitName,
                })}
              </span>
            </p>

            <TheButton
              text={t('iAmReady')}
              shouldRecolor={true}
              emitOnClick={() => {
                goToNextUnit();
                emitOnQuit?.();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinishUnit;
