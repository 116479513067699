import React from 'react';
import ReactDOM from 'react-dom';
import classes from 'classnames';

export interface ModalProps {
  className?: string;
  children?: React.ReactNode;
}

const AppModalPortal = ({ className, children }: ModalProps) => {
  return <div className={classes('app-modal', className)}>{children}</div>;
};

const AppModal = ({ className, children }: ModalProps) => {
  const modalPortalElement = document.getElementById('modal_portal') as Element;

  const modalPortal = ReactDOM.createPortal(
    <AppModalPortal className={className} children={children} />,
    modalPortalElement
  );

  return <>{modalPortal}</>;
};

export default AppModal;
