import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import MainViewDesktop from './MainViewDesktop';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { SkillTypes } from '@/types/skill';

const Games = () => {
  const dispatch = useAppDispatch();
  const { selectedType } = useAppSelector(gameSelector);
  const { skillSettings } = useAppSelector(authSelector);

  useEffect(() => {
    const initialSkillType = (localStorage.getItem('selected-skill') ||
      SkillTypes.Grammar) as SkillTypes;

    if (!selectedType) {
      const skill =
        skillSettings.find(i => i.name?.toLocaleLowerCase() === initialSkillType) ?? null;

      dispatch(gameActions.setSelectedSkill({ type: initialSkillType, skill: skill }));
      localStorage.removeItem('selected-skill');
    }
  }, []);

  return <MainViewDesktop />;
};

export default React.memo(Games);
