import { StudentsSkillsProgress } from '@/types/progress';
import { SkillTypes } from '@/types/skill';
import { UserState } from '@/types/user';

const UserUtils = {
  fromToLanguage: (user: UserState | null) => {
    const fromToLanguageParams = user?.metadata?.languagePreferences?.fromToLanguageParams || [];
    return fromToLanguageParams.length ? fromToLanguageParams[0] : 'pt-en';
  },
  levelByLanguage: (user: UserState | null, skill?: SkillTypes | string) => {
    if (user?.progress?.userLevel && skill) {
      return user.progress.userLevel as keyof StudentsSkillsProgress;
    } else {
      return user?.progress?.userLevel ?? 1;
    }
  },
  getFirstName: (user: UserState | null) => {
    return user?.metadata?.firstName.split(' ')[0];
  },
  isTeacher: (user?: UserState | null) => {
    return user?.metadata?.role === 'teacher';
  },
  isAdmin: (user?: UserState | null) => {
    return user?.metadata?.role === 'admin';
  },
  isStudent: (user?: UserState | null) => {
    return user?.metadata?.role === 'student';
  },
};

export default UserUtils;
