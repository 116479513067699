import classNames from './GettingStartedInstructions.module.scss';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import classes from 'classnames';
import chat from '@/assets/svg/chat.svg';
import rightArrow from '@/assets/svg/right-arrow.svg';
import { useDrawer } from '@/context/DrawerContext';
import { useAppSelector } from '@/store';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';
import download from '@/assets/svg/download.svg';
import UseScreenSize from '@/hooks/UseScreenSize';
import UserUtils from '@/utils/user';

const GettingStartedInstructions = () => {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const { nativeLanguage, user } = useAppSelector(authSelector);
  const { isDesktop } = UseScreenSize();

  const isTeacher = UserUtils.isTeacher(user);
  const isInterfaceLangHE = interfaceLanguage?.code === 'he';

  const isNativeLanguageHEAndInterfaceEN =
    nativeLanguage === 'he' && interfaceLanguage?.code === 'en';

  const supportedLanguages = ['en', 'es', 'pt'];

  const userLanguage = user?.metadata.languagePreferences.nativeLanguage || 'en';

  const pdfLanguage = supportedLanguages.includes(userLanguage) ? userLanguage : 'en';

  const instructions = [
    {
      title: t('teacherInstructions'),
      description: t('downloadAndView'),
      pdf: `/pdf/${pdfLanguage}_guide_teacher.pdf`,
    },
    {
      title: t('studentInstructions'),
      description: t('downloadAndView'),
      pdf: `/pdf/${pdfLanguage}_guide_student.pdf`,
    },
  ];

  const isRtl = interfaceLanguage?.direction === 'rtl';

  const instructionsElements = instructions.map((instruction, index) => (
    <a
      href={instruction.pdf}
      target='_blank'
      key={index}
      className={classNames.instruction}
      rel='noreferrer'
    >
      <SVG src={download} />
      <span>{instruction.description + ' '}</span>
      <span className='font-bold'>{instruction.title}</span>
    </a>
  ));
  return (
    <div className={classNames.gettingStartedInstructions}>
      {isDesktop && <h2>{t('gettingStarted')}</h2>}
      {isDesktop && <div className={classNames.instructions}>{instructionsElements}</div>}
      {isTeacher && (
        <div className={classNames.help}>
          <SVG
            className={classes(classNames.chat, {
              [classNames.chatSvgRtl]: isRtl,
            })}
            src={chat}
          />
          <h2>{t('needHelp')}</h2>
          <p>
            {isNativeLanguageHEAndInterfaceEN ? (
              <span>{t('contactOurTeamWithQuestions')}</span>
            ) : (
              <>
                <span>{t('contactOurTeam')}</span>
                <span>{t('britannicaEducationWith')}</span>
                {!isInterfaceLangHE && <span>{t('questionsAndConcerns')}</span>}
              </>
            )}
          </p>
          <button className={classNames.feedbackBtn} onClick={drawer.openContactUsDrawer}>
            {t('giveFeedback')}
            <SVG
              className={classes(classNames.arrow, {
                [classNames.arrowSvgRtl]: isRtl,
              })}
              src={rightArrow}
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default GettingStartedInstructions;
