import React from 'react';
import classNames from './TopicsSlider.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { SkillTypes } from '@/types/skill';
import { useAppDispatch, useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Grid } from 'swiper';
import commonUtils from '@/utils/common';
import UseTopicQuizzes from '@/hooks/UseTopicQuizzes';
import { Quiz } from '@/types/quiz';
import lock from '@/assets/svg/lock.svg';
import medalGold from '@/assets/svg/medal-gold.svg';
import Skeleton from 'react-loading-skeleton';
import QuizBadge from '@/components/QuizBadge/QuizBadge';
import QuizScore from '@/components/QuizScore/QuizScore';
import { useNavigate } from 'react-router-dom';
import UseLocalLang from '@/hooks/UseLocalLang';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import TheButton from '@/components/TheButton/TheButton';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import { useDrawer } from '@/context/DrawerContext';
import UseUnitsAssets from '@/hooks/UseUnitsAssets';
import { UnitAssets } from '@/types/units';
import { unitSelector } from '@/store/reducers/unit';
import { authSelector } from '@/store/reducers/auth';
import UserUtils from '@/utils/user';
import UseScreenSize from '@/hooks/UseScreenSize';

interface TopicsSliderProps {
  skill: SkillTypes;
}

export const TopicsSlider = ({ skill }: TopicsSliderProps) => {
  const { t } = useTranslation();
  const { isMobileSmall } = UseScreenSize();
  const drawer = useDrawer();
  const { unitsAssets } = UseUnitsAssets();
  const { selectedUnit, selectedTopic: selectedTopicNew } = useAppSelector(unitSelector);
  const { user } = useAppSelector(authSelector);
  const unitAssets: UnitAssets = unitsAssets[selectedUnit?.order || 1];
  const { quizzesGroups, loadingQuizzes, quizzesPerPage, sortedQuizzes } = UseTopicQuizzes();
  const { selectedTopic } = useAppSelector(gameSelector);
  const isTeacher = UserUtils.isTeacher(user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const localLang = UseLocalLang();

  const getQuizzesGroup = () => {
    if (loadingQuizzes) {
      const quizzes = commonUtils.groupBySize(
        Array(selectedTopic?.quizzesCount ?? 0).fill({}),
        quizzesPerPage
      );

      return quizzes;
    }

    return quizzesGroups;
  };

  const goToQuizPage = async (quiz: Quiz) => {
    dispatch(gameActions.setSelectedQuiz(quiz));
    navigate('/dashboard/quiz');
    drawer.closeTopicsDrawer();
  };

  const allTopicProgress = user?.progress?.unit?.topicsOverviewPerUnit[selectedUnit?.id || 1];

  const quizzesGroupsElements = getQuizzesGroup().map((quizzesGroup, groupIndex) => {
    const quizzesElements = quizzesGroup.map((quiz: Quiz, quizIndex: number) => {
      const index = groupIndex * quizzesPerPage + quizIndex;

      const isCompleted = commonUtils.isActivityComplited(
        allTopicProgress,
        selectedTopicNew?.id,
        index
      );

      const isLocked = !isTeacher && !isCompleted;

      const achievedStars = (!isLocked && quiz.userData?.cumulativeAchievedStars) || 0;

      let badgeImgSrc = lock;

      if (!isLocked) {
        // badgeImgSrc = isCompleted ? unitAssets?.medal ?? '' : medalGold;
        badgeImgSrc = unitAssets?.medal;
      }

      return (
        <div
          key={quizIndex}
          className={classes(
            classNames.quiz,
            {
              [classNames.quizLocked]: isLocked,
            },
            !isLocked ? 'cursor-pointer' : ''
          )}
          onClick={() => !isLocked && goToQuizPage(quiz)}
        >
          {loadingQuizzes ? (
            <Skeleton width='45px' height='45px' circle />
          ) : (
            <QuizBadge
              img={badgeImgSrc}
              isLocked={isLocked}
              index={index}
              isSmall={true}
              source={isMobileSmall ? '' : 'topicsDrawer'}
            />
          )}
          {loadingQuizzes ? (
            <Skeleton width='70px' style={{ position: 'relative', top: '-10px' }} />
          ) : (
            <>
              <div style={{ marginTop: isMobileSmall ? 0 : 5 }}>
                <QuizScore
                  isLocked={isLocked}
                  achievedStars={achievedStars}
                  source='topicsDrawer'
                />
              </div>
              <span className={classNames.name}>{commonUtils.getName(quiz, localLang)}</span>
            </>
          )}
        </div>
      );
    });

    return (
      <SwiperSlide key={groupIndex} className={classNames.quizzesGrid}>
        {quizzesElements}
      </SwiperSlide>
    );
  });

  return (
    <div
      className={classes(classNames.topicsSlider, {
        [classNames.topicsSliderMobile]: isMobileSmall,
      })}
      style={{
        backgroundImage: `url(${isMobileSmall ? unitAssets?.topicsBackgroundImageMobile : unitAssets?.topicsBackgroundImage})`,
      }}
    >
      <div className={classes(classNames.modal, 'activities-modal')}>
        <div
          className={classNames.modalContentInner}
          style={
            {
              '--modalBorderColor': unitAssets.modalBorderColor,
            } as React.CSSProperties
          }
        >
          {loadingQuizzes ? (
            <>
              <div className={classNames.loading}>
                <AppLoaderCircle />
              </div>
            </>
          ) : selectedTopic?.activitiesInTopic && selectedTopic?.activitiesInTopic > 0 ? (
            <>
              <div className={classNames.modalBody}>
                {!isMobileSmall && (
                  <div
                    className={classes(
                      classNames.swiperButton,
                      'swiper-button image-swiper-button-next'
                    )}
                  >
                    <IoIosArrowForward />
                  </div>
                )}
                {!isMobileSmall && (
                  <div
                    className={classes(
                      classNames.swiperButton,
                      'swiper-button image-swiper-button-prev'
                    )}
                  >
                    <IoIosArrowBack />
                  </div>
                )}
                <Swiper
                  modules={isMobileSmall ? [Navigation, Pagination] : [Navigation]}
                  className={classNames.quizzesSwiper}
                  spaceBetween={10}
                  slidesPerView={1}
                  // loop={true}
                  navigation={{
                    nextEl: '.image-swiper-button-next',
                    prevEl: '.image-swiper-button-prev',
                    disabledClass: 'swiper-button-disabled',
                  }}
                  init={false}
                  draggable={true}
                  injectStyles={[
                    `
                      .swiper-wrapper{
                        align-items: center;
                      }
                      `,
                  ]}
                  pagination={{
                    clickable: true,
                    horizontalClass: 'swiperPagination',
                    bulletClass: 'swiperPaginationBullet',
                    bulletActiveClass: 'swiperPaginationBulletActive',
                  }}
                >
                  {quizzesGroupsElements}
                </Swiper>
              </div>
              {/* <button>Back</button> */}
              {!isMobileSmall && (
                <TheButton
                  text='Back'
                  className={classNames.backBtn}
                  emitOnClick={drawer.closeTopicsDrawer}
                  shouldRecolor
                />
              )}
            </>
          ) : (
            <>
              <div className={classNames.emptyTopic}>
                <div className={classNames.emptyTopicName}>{selectedTopic?.name}</div>
                <div
                  className={classNames.emptyTopicText}
                  dangerouslySetInnerHTML={{ __html: t('comingSoon').toString() }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
