import classNames from './Memory.module.scss';
import { useAppDispatch, useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { useEffect, useMemo, useCallback } from 'react';
import MemoryCard from './MemoryCard/MemoryCard';
import commonUtils from '@/utils/common';
import { useTranslation } from 'react-i18next';
import UseScreenSize from '@/hooks/UseScreenSize';
import { MemoryProps } from '@/types/game';

type setQuestionTempDataFunc<T> = (prevData: T) => T;
type setQuestionTempDataType<T> = T | setQuestionTempDataFunc<T>;

const Memory = ({ currentQuestionTempData, setCurrentQuestionTempData }: MemoryProps) => {
  const { t } = useTranslation();
  const { revealAPair } = useAppSelector(gameSelector);
  const dispatch = useAppDispatch();
  const { isDesktop } = UseScreenSize();

  const {
    quizData,
  }: {
    quizData: any[];
  } = useMemo(() => {
    return {
      quizData: currentQuestionTempData?.quizData || [],
    };
  }, [currentQuestionTempData]);

  const setQuestionTempData = useCallback(
    (prevData: any, newData: any, key: string) => {
      const data = typeof newData === 'function' ? newData(prevData) : newData;
      setCurrentQuestionTempData(data, key);
    },
    [setCurrentQuestionTempData]
  );

  const setQuizData = useCallback(
    (newData: setQuestionTempDataType<any[]>) => {
      setQuestionTempData(quizData, newData, 'quizData');
    },
    [quizData, setQuestionTempData]
  );

  useEffect(() => {
    if (quizData && quizData.length) {
      const newQuizData = structuredClone(quizData);
      const selectedCards = newQuizData.filter(cardData => cardData.isSelected);

      if (selectedCards.length >= 2) {
        if (selectedCards[0].index === selectedCards[1].index) {
          selectedCards[0].isMatched = true;
          selectedCards[1].isMatched = true;
          selectedCards.forEach(cardData => (cardData.isSelected = false));
          setQuizData(newQuizData);
          return;
        }

        let resetSelectedTimer: any = null;

        const clearresetSelectedTimer = () => {
          if (resetSelectedTimer) {
            clearTimeout(resetSelectedTimer);
            resetSelectedTimer = null;
          }
        };

        resetSelectedTimer = setTimeout(() => {
          if (resetSelectedTimer) {
            clearresetSelectedTimer();
            selectedCards.forEach(cardData => (cardData.isSelected = false));
            setQuizData(newQuizData);
          }
        }, 1000);

        return () => {
          clearresetSelectedTimer();
        };
      }

      const matchedCards = newQuizData.filter(cardData => cardData.isMatched);
      if (newQuizData.length === matchedCards.length) {
        dispatch(gameActions.handlePickedAnswerMemoryGame());
      }
    }
  }, [quizData, setQuizData]);

  useEffect(() => {
    if (revealAPair) {
      if (quizData && quizData.length) {
        let pairIndex: any = null;

        const newQuizData = structuredClone(quizData);
        const selectedCards = newQuizData.filter(cardData => cardData.isSelected);

        if (selectedCards.length === 0) {
          const notMatched = newQuizData.filter(data => !data.isMatched);
          pairIndex = notMatched[commonUtils.getRandomInt(0, notMatched.length - 1)].index;
        } else if (selectedCards.length === 1) {
          pairIndex = selectedCards[0].index;
        }

        if (pairIndex !== null) {
          newQuizData
            .filter(data => data.index === pairIndex)
            .forEach(data => {
              data.isMatched = true;
              data.isSelected = false;
            });

          setQuizData(newQuizData);
        }
      }

      dispatch(gameActions.setRevealAPair(false));
    }
  }, [revealAPair, quizData, setQuizData]);

  const onAnswerSelect = useCallback(
    (data: any) => {
      if (quizData && quizData.length) {
        const selectedCards = quizData.filter(cardData => cardData.isSelected);
        if (selectedCards.length < 2) {
          const newQuizData = structuredClone(quizData);
          const cardIndex = newQuizData.findIndex(cardData => cardData.data === data.data);
          newQuizData[cardIndex].isSelected = true;
          setQuizData(newQuizData);
        }
      }
    },
    [quizData]
  );

  return (
    <div className={classNames.memory}>
      {isDesktop && <p className={classNames.memoryTitle}>{t('memoryGameTitle')}</p>}
      <div className={classNames.memoryBlock}>
        {quizData.map((item: any, idx: number) => (
          <div key={idx} className={classNames.memoryCardWrapper}>
            <MemoryCard data={item} onSelect={onAnswerSelect} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Memory;
