import LevelTestLogOut from '@/components/LevelTest/LevelTestLogOut/LevelTestLogOut';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UseScreenSize from '@/hooks/UseScreenSize';
import SVG from 'react-inlinesvg';
import logoutIcon from '@/assets/svg/logout.svg';

const LevelTestLogoutComponent = ({ className }: { className: string }) => {
  const { t } = useTranslation();
  const { isMobile } = UseScreenSize();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  return (
    <>
      <LevelTestLogOut showLogoutModal={showLogoutModal} setShowLogoutModal={setShowLogoutModal} />
      <div className={className}>
        <button
          onClick={() => {
            setShowLogoutModal(true);
          }}
        >
          {isMobile ? <SVG src={logoutIcon} /> : t('exit')}
        </button>
      </div>
    </>
  );
};

export default LevelTestLogoutComponent;
