import { createAsyncThunk } from '@reduxjs/toolkit';
import { authService, userService, progressService, skillService } from '@/services';
import { RootState } from '@/types/common';
import { LevelSettingsResponse } from '@/types/response/user';

const userThunks = {
  userInfo: createAsyncThunk('user/me', async (retryCount: number, { getState }) => {
    try {
      const state = getState() as RootState;
      let error;

      for (let i = 0; i < retryCount; i++) {
        try {
          const user = await authService.me(state.auth?.userId);
          return user?.data;
        } catch (ex) {
          error = ex;
        }
      }

      throw error;
    } catch (error) {
      throw error;
    }
  }),
  fetchSkills: createAsyncThunk('skill/settings', async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const settings = await userService.fetchSkillSettings(state.auth.user);

      return settings;
    } catch (error) {
      throw error;
    }
  }),
  // userSkipLevelTest: createAsyncThunk('skill/skip-level-test', async (_, { getState }) => {
  //   try {
  //     const state = getState() as RootState;
  //     await skillService.userSkipLevelTest(state.auth.user);
  //   } catch (error) {
  //     throw error;
  //   }
  // }),
  fetchLevels: createAsyncThunk('user/level', async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const levelsResponse: LevelSettingsResponse | null = await userService.fetchLevelSettings(
        state.auth.user
      );
      return levelsResponse?.data;
    } catch (e) {
      console.log(e);
    }
  }),
  fetchLanguages: createAsyncThunk('user/language', async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const languages = await userService.fetchLanguageSettings(state.auth.user);
      return languages?.data;
    } catch (e) {
      console.log(e);
    }
  }),
  setLanguageSettings: createAsyncThunk(
    'user/languageSettings',
    async (interfaceLanguage: string, { getState }) => {
      try {
        const state = getState() as RootState;

        await userService.setUserLanguageSettings(state.auth.user, interfaceLanguage);
      } catch (e) {
        console.log(e);
      }
    }
  ),
  changeTeacherSkillLevel: createAsyncThunk(
    'user/teacherSkillLevel',
    async (requestBody: { skill: string; level: number }, { getState }) => {
      const { skill, level } = requestBody;
      try {
        const state = getState() as RootState;
        await userService.changeTeacherSkillLevel(state.auth.user, skill, level);
      } catch (e) {
        console.log(e);
      }
    }
  ),
  signOut: createAsyncThunk('user/signout', async (_, { getState }) => {
    try {
      const state = getState() as RootState;

      await userService.setUserLogOut(state.auth.user);
    } catch (e) {
      console.log(e);
    }
  }),
};

export default userThunks;
