import { useTranslation } from 'react-i18next';
import classNames from './WordKnowledgeControls.module.scss';
import classes from 'classnames';
import KnowledgeIcon from './KnowledgeIcon/KnowledgeIcon';
import { Question } from '@/types/question';
import React, { useMemo } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';

interface WordKnowledgeControlsProps {
  className?: string;
  isSmall?: boolean;
  question: Question | null;
  emitOnAknowledge?: (id: number, isKnown: boolean) => void;
  onNextWord?: () => void;
}

const WordKnowledgeControls = ({
  className,
  isSmall = false,
  question,
  emitOnAknowledge,
  onNextWord,
}: WordKnowledgeControlsProps) => {
  const { t } = useTranslation();
  const { isMobile } = UseScreenSize();

  const isKnown = useMemo(() => question?.userData?.practice?.isKnown, [question]);

  const handleOnClick = (
    questionId: number | undefined,
    isKnownWord: boolean,
    isMobile: boolean
  ): void => {
    if (!questionId) return;
    emitOnAknowledge?.(questionId, isKnownWord);
    if (isMobile) {
      setTimeout(() => {
        onNextWord?.();
      }, 1000);
    }
  };

  return (
    <div
      className={classes(classNames.wordKnowledgeControls, className, {
        [classNames.wordKnowledgeControlsSmall]: isSmall,
      })}
    >
      <button
        className={classes(classNames.dontKnowBtn, {
          [classNames.small]: isSmall,
          [classNames.dontKnowBtnDisabled]: !isSmall && isKnown === true,
          [classNames.dontKnowBtnHighlight]: isKnown === false,
        })}
        onClick={() => handleOnClick(question?.questionId, false, isMobile)}
      >
        <KnowledgeIcon
          className={classNames.icon}
          isKnown={false}
          shouldDisable={!isSmall && isKnown === true}
          shouldHighlight={isKnown === false}
        />
        {!isSmall && <span className={classNames.text}>{t('dontKnow')}</span>}
      </button>
      <button
        className={classes(classNames.knowBtn, {
          [classNames.small]: isSmall,
          [classNames.knowBtnDisabled]: !isSmall && isKnown === false,
          [classNames.knowBtnHighlight]: isKnown === true,
        })}
        onClick={() => handleOnClick(question?.questionId, true, isMobile)}
      >
        <KnowledgeIcon
          className={classNames.icon}
          isKnown={true}
          shouldDisable={!isSmall && isKnown === false}
          shouldHighlight={isKnown === true}
        />
        {!isSmall && <span className={classNames.text}>{t('know')}</span>}
      </button>
    </div>
  );
};

export default React.memo(WordKnowledgeControls);
