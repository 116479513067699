import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import commonUtils from '@/utils/common';
import Dropdown, { DropdownOption, DropdownSize } from '@/components/Dropdown/Dropdown';

interface GradesFilterProps {
  selectedFilter: string;
  selectedFilterText: string;
  size?: DropdownSize;
  emitFilter: (option: string) => void;
}

const GradesFilter = ({
  selectedFilter,
  selectedFilterText,
  size = 'm',
  emitFilter,
}: GradesFilterProps) => {
  const { t } = useTranslation();
  const [filterOptions, setFilterOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    setFilterOptions([
      { value: '*', label: t('all') },
      ...Array.from({ length: 12 }, (_, i) => {
        const value = String(i + 1);
        return {
          value: value,
          label: t(`grade_${commonUtils.fixGradeSymbol(value)}`),
        };
      }),
      { value: 'university', label: t('university') },
      { value: 'none', label: t('none') },
    ]);
  }, [t]);

  const onSelectedOption = (value: any, option: DropdownOption) => {
    emitFilter(value as string);
  };

  return (
    <Dropdown
      label={`${t('grade')}:`}
      options={filterOptions}
      value={selectedFilter}
      size={size}
      onChange={onSelectedOption}
    />
  );
};

export default GradesFilter;
