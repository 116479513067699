import {
  PreparationTypeProps,
  ReadPreparationProps,
  SpeakPreparationProps,
  VocabularyPreparationProps,
} from '@/types/game';
import { SkillTypes } from '@/types/skill';

import VocabularyPreparation from './VocabularyPreparation/VocabularyPreparation';
import ReadPreparation from './ReadPreparation/ReadPreparation';
import SpeakPreparation from './SpeakPreparation/SpeakPreparation';
import ListenPreparation from './ListenPreparation/ListenPreparation';

const PreparationTypeComponents = [
  {
    type: SkillTypes.Vocabulary,
    component: VocabularyPreparation,
  },
  //FIXME: Need to use correct condition for this case. Now ListenPreparation component uses for all greeting pages
  {
    type: SkillTypes.Grammar,
    component: ListenPreparation,
  },
  {
    type: SkillTypes.Read,
    component: ReadPreparation,
  },
  {
    type: SkillTypes.Speak,
    component: SpeakPreparation,
  },
];

const QuizPreparation = (
  props: PreparationTypeProps &
    (VocabularyPreparationProps | ReadPreparationProps | SpeakPreparationProps)
) => {
  const preparationTypeComponent = PreparationTypeComponents.find(
    component => component.type === props.selectedType
  );

  const ComponentToRender = preparationTypeComponent ? preparationTypeComponent.component : null;

  return ComponentToRender ? <ComponentToRender {...props} /> : null;
};

export default QuizPreparation;
