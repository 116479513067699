import { useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const WelcomeConfetti = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, isNewlyRegistered } = useAppSelector(authSelector);

  const [hidePage, setHidePage] = useState(false);

  useEffect(() => {
    const fadeOutTimeout = window.setTimeout(() => {
      setHidePage(true);
    }, 2000);

    const dispatchTimeout = window.setTimeout(() => {
      dispatch(authActions.setShowWelcomeScreen(false));

      if (isNewlyRegistered) {
        dispatch(authActions.setShowAppRecommandationScreen(true));
      }
    }, 2600);

    return () => {
      window.clearTimeout(fadeOutTimeout);
      window.clearTimeout(dispatchTimeout);
    };
  }, [isNewlyRegistered]);

  return (
    <div className={`welcomeConfetti ${hidePage ? 'fadeOut' : 'fadeIn'}`}>
      <div className='username'>{`${t('hello')}, ${user?.metadata?.firstName}!`}</div>
      <div>{`${t('welcomeConfetti')}!`}</div>
    </div>
  );
};

export default WelcomeConfetti;
