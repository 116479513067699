import className from '../ProgressSection.module.scss';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import badge from '@/assets/svg/badge.svg';
import UseScreenSize from '@/hooks/UseScreenSize';

type ProgressIconProps = {
  level: number;
};

export const ProgressIcon = ({ level }: ProgressIconProps) => {
  const { isMobileSmall } = UseScreenSize();
  return (
    <div className={className.iconWrapper}>
      <SVG src={badge} className={classNames({ [className.badgeMedium]: isMobileSmall })} />
      <span
        className={classNames(className.levelText, { [className.levelTextSmall]: isMobileSmall })}
      >
        {level}
      </span>
    </div>
  );
};
