import { RootState } from '@/types/common';
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import rootReducer from './reducers';

const store = configureStore({
  devTools: true,
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      createStateSyncMiddleware({
        predicate: action => {
          const actionType = action?.type?.toString() || '';

          if (actionType.startsWith('Auth/') || actionType.startsWith('user/')) {
            return true;
          }

          const userRole = localStorage.getItem('userRole');

          if (
            userRole === 'student' &&
            (actionType.startsWith('units/') ||
              actionType.startsWith('appSettings/') ||
              actionType.startsWith('refreshTopicQuizzes/'))
          ) {
            return true;
          }

          return false;
        },
      })
    ),
});

store.subscribe(() => {});

initMessageListener(store);

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
