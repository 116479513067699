import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import UserUtils from '@/utils/user';
import classNames from './TheSidebar.module.scss';
import classes from 'classnames';
import { View } from '@/types/common';

import TeacherSidebar from './TeacherSidebar';
import UserSidebar from './UserSidebar';

interface TheSidebarProps {
  viewTabs: View[];
  emitSelectView: (view: View) => void;
}

const TheSidebar = ({ viewTabs, emitSelectView }: TheSidebarProps) => {
  const { user } = useAppSelector(authSelector);
  const isTeacher = UserUtils.isTeacher(user);

  return (
    <div
      className={classes(classNames.theSidebar, {
        [classNames.theSidebarTeacher]: isTeacher,
      })}
    >
      {isTeacher ? (
        <TeacherSidebar viewTabs={viewTabs} emitSelectView={emitSelectView} />
      ) : (
        <UserSidebar viewTabs={viewTabs} emitSelectView={emitSelectView} />
      )}
    </div>
  );
};

export default TheSidebar;
