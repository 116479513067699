import classNames from './AnswerReward.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';

import coinsImg from '@/assets/svg/3-coins.svg';

interface AnswerRewardProps {
  className?: string;
  coins: number;
}

const AnswerReward = ({ className, coins }: AnswerRewardProps) => {
  return (
    <div className={classes(classNames.answerReward, className)}>
      <SVG src={coinsImg} />
      <span>+{coins}</span>
    </div>
  );
};

export default AnswerReward;
