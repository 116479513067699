import React, { useState } from 'react';
import UseMyStudents from './UseMyStudents';
import classNames from './MyStudentsView.module.scss';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';

import StudentsData from '@/components/StudentsData/StudentsData';
import Navbar from '@/components/Navbar/Navbar';
import UserInfo from '@/components/UserInfo/UserInfo';
import DictionaryMobile from '@/components/Dictionary/DictionaryMobile/DictionaryMobile';
import AppBanner from '@/components/AppBanner';

const MyStudentsViewMobile = () => {
  const { user, displayDictionary } = useAppSelector(authSelector);

  const {
    isLoading,
    students,
    classrooms,
    statistics,
    selectedStudent,
    showModal,
    currentClassroom,
    onShowModal,
    onHideModal,
    onSetCurrentClassroom,
    searchValue,
    setSearchValue,
  } = UseMyStudents();

  const [shouldOpenDictioanry, setShouldOpenDictionary] = useState(false);

  const onOpenDictionary = () => {
    setShouldOpenDictionary(true);
  };

  const onCloseDictionary = () => {
    setShouldOpenDictionary(false);
  };
  return (
    <div className='pt-16 h-full'>
      <Navbar className={classNames.navbar} />

      <div className='container'>
        {user && (
          <UserInfo
            fullname={`${user?.metadata.firstName} ${user?.metadata.lastName}`}
            classesAmount={user?.metadata?.organizationDetails?.teacher?.teacherGroups?.length || 0}
            studentsAmount={user?.metadata?.organizationDetails?.teacher?.totalStudents || 0}
            profileImage={user?.metadata.userSettings.avatar?.avatarName}
            backgroundColor={user?.metadata.userSettings.avatar?.avatarColor}
            schoolName={user?.metadata.organizationDetails.organizationId}
            notificationsAmount={3}
            isTeacher
            isMobile
          />
        )}
      </div>
      <StudentsData
        students={students}
        classrooms={classrooms}
        isLoading={isLoading}
        statistics={statistics}
        selectedStudent={selectedStudent}
        showStudentDataModal={showModal}
        currentClassroom={currentClassroom}
        onShowStudentModal={onShowModal}
        onHideStudentModal={onHideModal}
        onSetCurrentClassroom={onSetCurrentClassroom}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onHideAssessment={() => {}}
        onShowAssessments={() => {}}
        showAssessments={false}
      />
      {displayDictionary ? (
        <DictionaryMobile
          shouldOpen={shouldOpenDictioanry}
          emitOpen={onOpenDictionary}
          emitClose={onCloseDictionary}
        />
      ) : (
        <AppBanner />
      )}
    </div>
  );
};

export default React.memo(MyStudentsViewMobile);
