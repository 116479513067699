import { SpeakPreparationProps } from '@/types/game';
import { useEffect, useRef, useState } from 'react';
import classNames from './SpeakPreparation.module.scss';
import classes from 'classnames';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import commonUtils from '@/utils/common';

import TheButton from '@/components/TheButton/TheButton';
import QuizDetailsHeading from '../../QuizDetailsHeading/QuizDetailsHeading';
import QuizTip from '../../QuizPopUp/QuizTip/QuizTip';
import GoBackBtn from '@/components/GoBackBtn/GoBackBtn';

import close from '@/assets/svg/close.svg';

const SpeakPreparation = ({
  selectedQuiz,
  selectedType,
  selectedSkill,
  selectedTopic,
  tip,
  emitOnStartQuiz,
  emitGoBack,
}: SpeakPreparationProps) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = UseScreenSize();
  const [shouldExpand, setShouldExpand] = useState(false);

  const bodyRef = useRef<HTMLDivElement>(null);
  const [offsetFromTop, setOffsetFromTop] = useState(0);

  useEffect(() => {
    const spacing = 16;

    const bottomHeight = document.querySelector('#speak-preparation-bottom')?.clientHeight ?? 0;

    const offset = (bodyRef.current?.getBoundingClientRect().top ?? 0) + bottomHeight + spacing;

    setOffsetFromTop(offset);
  }, []);

  const onExpand = () => {
    setShouldExpand(true);
  };

  const onReduce = () => {
    setShouldExpand(false);
  };

  const descriptionContent = isMobile ? (
    <>
      <span className='font-bold'>{t('practiceProperPronunciation')}</span>
      <div>
        <span>{t('usingYourMicrophone') + ' '}</span>
        <span className='font-bold'>{t('goodLuck')}</span>
      </div>
    </>
  ) : (
    <>
      <span className='font-bold'>{t('practiceProperPronunciation') + ' '}</span>
      <span>{t('usingYourMicrophone') + ' '}</span>
      <span className='font-bold'>{t('goodLuck')}</span>
    </>
  );

  const description = <div className={classNames.description}>{descriptionContent}</div>;
  return (
    <div className={classNames.speakPreparation}>
      {shouldExpand && isMobile && <div className={classNames.backdrop}></div>}
      <div className={classNames.content}>
        {isMobile && (
          <div className={classNames.top}>
            <GoBackBtn
              className={classNames.goBackBtn}
              showText={false}
              emitClick={() => emitGoBack?.()}
            />
          </div>
        )}
        <div
          ref={bodyRef}
          className={classNames.body}
          style={{
            maxHeight: isMobile ? `calc(100vh - ${offsetFromTop}px` : '',
          }}
        >
          <QuizDetailsHeading
            className={classNames.quizDetailsHeading}
            selectedType={selectedType}
            selectedSkill={selectedSkill}
            selectedTopic={selectedTopic}
          />
          {selectedQuiz && <h2>{commonUtils.getName(selectedQuiz)}</h2>}
          <div className={classNames.details}>
            {description}
            <SVG src={selectedType ? commonUtils.skillImage(selectedType) : ''} />
          </div>
        </div>
        <div
          id='speak-preparation-bottom'
          className={classes(classNames.bottom, {
            [classNames.expanded]: shouldExpand,
            [classNames.bottomNoTip]: !tip,
          })}
        >
          {shouldExpand && (
            <div className={classNames.close} onClick={onReduce}>
              <SVG src={close} />
            </div>
          )}
          <div className={classNames.tipAndButtons}>
            {/* {tip && (
              <QuizTip
                tip={tip ?? ''}
                className={classNames.tip}
                lampClassName={classNames.lamp}
                shouldExpand={shouldExpand}
                emitOnExpand={onExpand}
              />
            )} */}
            <div className={classNames.buttons}>
              {isDesktop && (
                <TheButton
                  className={classNames.backBtn}
                  plain
                  text={t('backHome')}
                  emitOnClick={emitGoBack}
                />
              )}
              <TheButton
                className={classNames.button}
                text={t('startQuiz')}
                showArrow={isDesktop}
                emitOnClick={emitOnStartQuiz}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpeakPreparation;
