import React, { useState, useRef, useCallback, useEffect } from 'react';
import classNames from './UnitsRoadmapSection.module.scss';
import classes from 'classnames';
import { Unit, UnitAssets } from '@/types/units';
import { unitSelector } from '@/store/reducers/unit';
import UserUtils from '@/utils/user';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import lock from '@/assets/svg/lock.svg';
import previousArrow from '@/assets/svg/carousel-previous-arrow.svg';
import nextArrow from '@/assets/svg/carousel-next-arrow.svg';
import SVG from 'react-inlinesvg';
import UseUnitsAssets from '@/hooks/UseUnitsAssets';

const scrollTemp = 5;

interface TabsProps {
  selectedTab: number;
  units: Unit[];
  onSelectTab: (tabId: number) => void;
}

const Tabs: React.FC<TabsProps> = ({ selectedTab, units, onSelectTab }) => {
  const { user } = useAppSelector(authSelector);
  const { selectedUnit } = useAppSelector(unitSelector);
  const { unitsAssets } = UseUnitsAssets();
  const isTeacher = UserUtils.isTeacher(user);
  const unitAssets: UnitAssets = unitsAssets[selectedUnit?.order || 1];
  const [containerWidth, setContainerWidth] = useState(0);
  const [scrollStep, setScrollStep] = useState(0);
  const [showPreviousButton, setShowPreviousButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const tabsRef = useRef<HTMLDivElement>();
  const contresizeObserverRef = useRef<ResizeObserver>();

  const isUnitLocked = (completedUnits: number, unitIndex: number): boolean =>
    unitIndex > completedUnits;

  useEffect(() => {
    if (tabsRef.current) {
      setNavigationButtons();
      tabsRef.current.scrollTo({
        left: 0,
        behavior: 'auto',
      });
    }
  }, [units]);

  useEffect(() => {
    let canScroll = scrollStep !== 0;

    const scroll = () => {
      if (!canScroll) {
        return;
      }

      if (tabsRef.current) {
        tabsRef.current.scrollLeft += scrollStep;
      }

      requestAnimationFrame(scroll);
    };

    const clearScrolling = () => {
      setScrollStep(0);
    };

    scroll();

    if (canScroll) {
      document.addEventListener('mouseup', clearScrolling);
      document.addEventListener('mouseout', clearScrolling);
    }

    return () => {
      if (canScroll) {
        document.removeEventListener('mouseup', clearScrolling);
        document.removeEventListener('mouseout', clearScrolling);
      }
      canScroll = false;
    };
  }, [scrollStep]);

  const setNavigationButtons = useCallback(() => {
    if (!tabsRef.current) {
      return;
    }

    setShowPreviousButton(tabsRef.current.scrollLeft > 0);
    setShowNextButton(
      tabsRef.current.scrollLeft < tabsRef.current.scrollWidth - tabsRef.current.clientWidth
    );
  }, []);

  const setTabsRef = useCallback((ref: any) => {
    if (contresizeObserverRef.current && tabsRef.current) {
      try {
        contresizeObserverRef.current.unobserve(tabsRef.current);
      } catch {}
    }

    if (!contresizeObserverRef.current) {
      contresizeObserverRef.current = new ResizeObserver(entries => {
        for (const entry of entries) {
          setContainerWidth(Math.floor(entry.contentRect.width));
          setNavigationButtons();
        }
      });
    }

    tabsRef.current = ref;

    if (ref) {
      setNavigationButtons();
      contresizeObserverRef.current.observe(ref);
    }
  }, []);

  return (
    <div className={classNames.tabsContainer}>
      <div
        className={classNames.tabs}
        ref={setTabsRef}
        onScroll={setNavigationButtons}
        style={
          {
            '--selectedColor': unitAssets?.modalBorderColor,
          } as any
        }
      >
        {units.map((unit, idx) => {
          const isActive = selectedTab === unit.id;
          const isLocked = isUnitLocked(user?.progress?.completedUnits || 0, idx) && !isTeacher;

          return (
            <div
              key={unit.id}
              className={`${classNames.tab} ${isActive ? classNames.tabActive : ''}`}
              onClick={() => !isLocked && onSelectTab(unit.id)}
              style={{ cursor: isLocked ? 'not-allowed' : 'pointer', opacity: isLocked ? 0.5 : 1 }}
            >
              <span className={classNames.tabText}>
                {isLocked && <SVG src={lock} width='20px' height='20px' />}
                <span data-text={unit.name}></span>
              </span>
              {isActive && <div className={classNames.stripe}></div>}
            </div>
          );
        })}
      </div>
      {showPreviousButton && (
        <button
          className={classes(classNames.tabsScrollButton, classNames.tabsScrollStart)}
          onMouseDown={() => setScrollStep(-scrollTemp)}
        >
          <SVG src={previousArrow} />
        </button>
      )}
      {showNextButton && (
        <button
          className={classes(classNames.tabsScrollButton, classNames.tabsScrollEnd)}
          onMouseDown={() => setScrollStep(scrollTemp)}
        >
          <SVG src={nextArrow} />
        </button>
      )}
    </div>
  );
};

export default React.memo(Tabs);
