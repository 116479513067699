import React from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';

import UseMyClasses from '../my-classes/UseMyClasses';
import MyClassesViewDesktop from '../my-classes/MyClassesViewDesktop';
import MyClassesViewMobile from '../my-classes/MyClassesViewMobile';

const MyClasses = () => {
  const { isMobile } = UseScreenSize();
  const myClasses = UseMyClasses();

  return isMobile ? (
    <MyClassesViewMobile myClasses={myClasses} />
  ) : (
    <MyClassesViewDesktop myClasses={myClasses} />
  );
};

export default React.memo(MyClasses);
