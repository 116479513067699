import React from 'react';
import classNames from './MultiChoiceQuestion.module.scss';
import classes from 'classnames';
import { Question } from '@/types/question';
import GameUtils from '@/utils/gameUtils';
import SVG from 'react-inlinesvg';
import { gameSelector } from '@/store/reducers/game';
import { useAppSelector } from '@/store';
import { useTranslation } from 'react-i18next';
import { interfaceLanguageSelector, studyingLanguageSelector } from '@/store/reducers/auth';

import WaveForm from '@/components/AudioWave/Waveform';

import questionIcon from '@/assets/svg/question.svg';
import replyIcon from '@/assets/svg/reply.svg';
import soundPlay from '@/assets/svg/speaker.svg';
import { NewGameTypes } from '@/types/game';

type MultiChoiceQuestionProps = {
  question: Question;
  handleRetry?: () => void;
  emitOnNextQuestion?: () => void;
};

const MultiChoiceQuestion = ({
  question,
  handleRetry,
  emitOnNextQuestion,
}: MultiChoiceQuestionProps) => {
  const { t } = useTranslation();
  const {
    showOverlay,
    isLevelTest,
    isInEndOfSkillTest,
    isFinalAssessment,
    pickedAnswer,
    gameQuestions,
  } = useAppSelector(gameSelector);
  //FIXME: NEED TO REPLACE WITH CORRECT GAMETYPE
  const TEMP_GAMETYPE = (gameQuestions[0]?.gameType as any) || 8;
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const studyingLanguage = useAppSelector(studyingLanguageSelector);

  const questionParts = GameUtils.getMultiChoiceQuestionParts(question);
  const reply = GameUtils.questionReply(question);

  const isVocabulary = TEMP_GAMETYPE === NewGameTypes.Vocabulary;
  //FIXME: NEED TO REPLACE WITH CORRECT GAMETYPE
  const isListen =
    TEMP_GAMETYPE === NewGameTypes.AudioToImage ||
    TEMP_GAMETYPE === NewGameTypes.Listen ||
    TEMP_GAMETYPE === NewGameTypes.NaturalConversation;

  //FIXME: Temporary solution just for 'audio-to-image' game type. Need to fix it.
  let soundPath = '';

  if (TEMP_GAMETYPE === NewGameTypes.AudioToImage) {
    soundPath = question.soundPath ?? '';
  } else {
    soundPath =
      isLevelTest || isFinalAssessment
        ? question.soundPath
        : (question.dictionaryDetails?.audio as any);
  }

  const onPlaySound = () => {
    new Audio(soundPath).play();
  };

  const blanksAmount = questionParts.filter(part => part.isBlank).length;

  const buildContent = () => {
    if (isListen) {
      return (
        <div className={classNames.listenContent}>
          {showOverlay && (
            <div className={classNames.overlay}>
              <span>{t('tryAgainOrKeepGoing')}</span>
              <div
                className={classNames.buttons}
                style={{
                  direction: interfaceLanguage?.direction as React.CSSProperties['direction'],
                }}
              >
                {!isInEndOfSkillTest && (
                  <button
                    className={classNames.buttonWrapper}
                    onClick={() => {
                      if (handleRetry !== undefined) {
                        handleRetry();
                      }
                    }}
                  >
                    <div className={classes(classNames.buttonContent, classNames.retry)}>
                      <span>1</span>
                    </div>
                  </button>
                )}
                <button
                  className={classNames.buttonWrapper}
                  onClick={() => {
                    if (emitOnNextQuestion) {
                      emitOnNextQuestion();
                    }
                  }}
                >
                  <div
                    className={classes(classNames.buttonContent, classNames.next, [
                      classNames[`next-${interfaceLanguage?.direction}`],
                    ])}
                  ></div>
                </button>
              </div>
            </div>
          )}
          {<WaveForm audio={question.soundPath ?? ''} />}
        </div>
      );
    }

    return (
      <>
        <div
          className={classNames.questionItem}
          style={{
            direction: (studyingLanguage?.direction as React.CSSProperties['direction']) ?? 'ltr',
          }}
        >
          {TEMP_GAMETYPE !== NewGameTypes.TextToText && <SVG src={questionIcon} />}
          <div className={classNames.text}>
            {TEMP_GAMETYPE === NewGameTypes.TextToText || TEMP_GAMETYPE === NewGameTypes.Grammar ? (
              questionParts.map((part, index) => {
                const { isBlank, value, correctAnswerPart } = part;

                let content = <span className={classNames.textPart}>{value}</span>;

                let blankFill = value;

                if (!isInEndOfSkillTest && isBlank && pickedAnswer) {
                  const answerText = isLevelTest ? question?.answer.text : question?.answer;
                  const showOnlyCorrectAnswer = pickedAnswer === answerText;
                  if (isLevelTest || showOnlyCorrectAnswer) {
                    blankFill = blanksAmount === 1 ? answerText : correctAnswerPart ?? '';
                  } else blankFill = '';
                }

                if (isBlank) {
                  content = (
                    <span
                      className={classes(classNames.specialFill, {
                        [classNames.specialFillFilled]: isBlank,
                      })}
                    >
                      {blankFill}
                    </span>
                  );
                }

                return <React.Fragment key={index}>{content} </React.Fragment>;
              })
            ) : (
              <span>{question?.question}</span>
            )}
          </div>
        </div>

        {reply && (
          <div
            className={classNames.questionItem}
            style={{
              direction: (studyingLanguage?.direction as React.CSSProperties['direction']) ?? 'ltr',
            }}
          >
            <img src={replyIcon} alt='question' />
            <div className={classNames.text}>
              <span>{reply}</span>
            </div>
          </div>
        )}
      </>
    );
  };

  const content = isVocabulary ? (
    <div className={classNames.vocabularyContent}>
      {soundPath && (
        <button className={classNames.soundBtn} onClick={onPlaySound}>
          <SVG src={soundPlay} />
        </button>
      )}
      <span className={classes([classNames[`question-${studyingLanguage?.direction}`]])}>
        {question.question}
      </span>
    </div>
  ) : (
    buildContent()
  );

  return <div className={classes(classNames.multiChoiceQuestion)}>{content}</div>;
};

export default MultiChoiceQuestion;
