import { DefaultTFuncReturn } from 'i18next';
import classNames from './Detail.module.scss';
import classes from 'classnames';

interface DetailItemProp {
  className?: string;
  topic: string;
  value?: string | number | DefaultTFuncReturn;
}

const Detail = ({ className, topic, value }: DetailItemProp) => {
  return (
    <div className={classes(classNames.detail, className)}>
      <span className={classNames.topic}>{topic}:</span>
      <span className={classNames.value}>{value}</span>
    </div>
  );
};

export default Detail;
