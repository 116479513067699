import { combineReducers } from 'redux';
import auth from './reducers/auth';
import units from './reducers/unit';
import game from './reducers/game';
import levelTest from './reducers/level-test';
import settings from './reducers/settings';
import teacher from './reducers/teacher';
import report from './reducers/report';
import endOfSkill from './reducers/end-of-skill';
import finalAssessment from './reducers/final-assessment';
import teacherDashboard from './reducers/teacher-dashboard';

const rootReducer = combineReducers({
  auth,
  game,
  levelTest,
  settings,
  teacher,
  report,
  endOfSkill,
  finalAssessment,
  teacherDashboard,
  units,
});

export default rootReducer;
