import classNames from './SkillBadge.module.scss';
import WithSettings, { WithSettingsProps } from '@/hocs/WithSettings';
import UseSkills from '@/hooks/UseSkills';
import { SkillTypes } from '@/types/skill';

interface SkillBadgeProps {
  skill: SkillTypes;
  textColor: string;
  backgroundColor: string;
}

const SkillBadge = ({
  t,
  skill,
  textColor,
  backgroundColor,
}: SkillBadgeProps & WithSettingsProps) => {
  const { getSkillName } = UseSkills();

  return (
    <div className={classNames.skillBadge} style={{ color: textColor, backgroundColor }}>
      {getSkillName(skill)}
    </div>
  );
};

export default WithSettings(SkillBadge);
