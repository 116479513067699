import React from 'react';
import classNames from './QuizBadge.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';

interface QuizBadgeProps {
  children?: React.ReactNode;
  img: string;
  isLocked?: boolean;
  isComplete?: boolean;
  index: number;
  isSmall?: boolean;
  isExtraSmall?: boolean;
  isEndOfSkillTest?: boolean;
  source?: '' | 'topicsDrawer';
}

const QuizBadge = ({
  img,
  isLocked,
  isComplete = false,
  index,
  isSmall,
  isExtraSmall,
  isEndOfSkillTest,
  source = '',
}: QuizBadgeProps) => {
  return (
    <div
      className={classes(classNames.quizBadge, classNames[`quizBadgeSource-${source}`], {
        [classNames.quizBadgeLocked]: isLocked,
        [classNames.quizBadgeSmall]: isSmall,
        [classNames.quizBadgeExtraSmall]: isExtraSmall,
      })}
    >
      <div className={classNames.badge}>
        <SVG src={img} />
        {!isLocked && !isComplete && !isEndOfSkillTest && <span>{index + 1}</span>}
      </div>
    </div>
  );
};

export default QuizBadge;
