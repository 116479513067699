import React, { useMemo, useState, useRef, useEffect } from 'react';
import classNames from './MemoryCard.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import correct from '@/assets/svg/correct-circle.svg';

const MemoryCard = ({ data, onSelect }: any) => {
  const [renderBack, setRenderBack] = useState(false);
  const renderBackTimer = useRef<any>();

  const isCardFlipped = useMemo(() => {
    return data && (data.isSelected || data.isMatched);
  }, [data]);

  useEffect(() => {
    let isActive = true;

    const clearRenderBackTimer = () => {
      if (renderBackTimer.current) {
        clearTimeout(renderBackTimer.current);
      }
    };

    const setShowBack = () => {
      clearRenderBackTimer();

      if (!isActive) {
        return;
      }

      setRenderBack(isCardFlipped);
    };

    clearRenderBackTimer();

    if (isCardFlipped) {
      setShowBack();
    } else {
      renderBackTimer.current = setTimeout(setShowBack, 700);
    }

    return () => {
      isActive = false;
      clearRenderBackTimer();
    };
  }, [isCardFlipped]);

  const content = (
    <>
      <div
        className={classNames.front}
        // onTouchEndCapture={() => (isMobile ? onSelect(data) : null)}
        onClick={() => onSelect(data)}
      ></div>
      {renderBack && (
        <div
          className={classNames.back}
          style={{ background: !data.imageText ? '#f6f6f6' : 'transparent' }}
          // onTouchEndCapture={() => (isMobile ? onSelect(data) : null)}
          onClick={() => onSelect(data)}
        >
          {!data.imageText ? (
            <p className={classNames.text}>{data.data}</p>
          ) : (
            <div className={classNames.closedSpellingImage}>
              <img src={data.data} alt={data.imageAlt} />
              <p>{data.imageText}</p>
            </div>
          )}
          {data.isMatched && <SVG src={correct} className={classNames.answerStatusImg} />}
        </div>
      )}
    </>
  );
  const cardElement = (
    <div
      className={classes(classNames.card, `card-${data.index}`, {
        [classNames.cardFlip]: isCardFlipped,
      })}
      style={{
        pointerEvents: data.isMatched || data.isSelected ? 'none' : 'inherit',
      }}
    >
      {content}
    </div>
  );

  return <div className={classes(classNames.memoryCard)}>{cardElement}</div>;
};

export default React.memo(MemoryCard);
