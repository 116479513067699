import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import unitThunks from '@/store/thunks/units';
import unitTopicsThunks from '@/store/thunks/unit-topics';
import { unitSelector } from '@/store/reducers/unit';
import summaryThunks from '@/store/thunks/summary';

const useFetchUnitsWithTopics = (level: number) => {
  const dispatch = useAppDispatch();
  const units = useAppSelector(unitSelector);
  const { user } = useAppSelector(authSelector);

  const [hasFetchedUnits, setHasFetchedUnits] = useState(false);
  const [hasFetchedTopics, setHasFetchedTopics] = useState(false);
  const [isLoadingUnits, setIsLoadingUnits] = useState(false);
  const [isLoadingTopics, setIsLoadingTopics] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFetchedSummary, setHasFetchedSummary] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchSummaryData = useMemo(() => {
    return async () => {
      if (!hasFetchedSummary && user) {
        try {
          await dispatch(summaryThunks.fetchSummary({ level })).unwrap();
          setHasFetchedSummary(true);
        } catch (error) {
          const err = error as { response?: { data?: string } };
          setError(err.response?.data || 'Failed to fetch summary');
          console.error('Error fetching summary:', error);
        }
      }
    };
  }, [dispatch, user, hasFetchedSummary, level]);

  useEffect(() => {
    fetchSummaryData();
  }, [fetchSummaryData]);

  useEffect(() => {
    const fetchUnitsData = async () => {
      if (units.selectedLevel === level && units.units.length > 0) {
        // Units are already fetched for this level
        setHasFetchedUnits(true);
        return;
      }

      setIsLoadingUnits(true);
      try {
        await dispatch(unitThunks.fetchUnits({ level })).unwrap();
        setHasFetchedUnits(true);
        setHasFetchedTopics(false); // Reset topics fetch flag when units are refetched
      } catch (error: any) {
        setError(error.response?.data || 'Failed to fetch units');
        console.error('Error fetching units:', error);
      } finally {
        setIsLoadingUnits(false);
      }
    };

    fetchUnitsData();
  }, [dispatch, level, units.selectedLevel, units.units.length]);

  useEffect(() => {
    const fetchTopicsData = async () => {
      if (units?.units?.length > 0 && !isLoadingTopics && hasFetchedUnits && !hasFetchedTopics) {
        if (units.selectedLevel === level) {
          // Check if topics are already fetched
          const allTopicsFetched = units.units.every(unit => unit.topics && unit.topics.length > 0);
          if (allTopicsFetched) {
            setHasFetchedTopics(true);
            return;
          }
        }

        setIsLoadingTopics(true);
        try {
          const fetchTopicsPromises = units.units.map(unit =>
            dispatch(unitTopicsThunks.fetchUnitTopics({ level, unitId: unit.id })).unwrap()
          );

          await Promise.all(fetchTopicsPromises);
          setHasFetchedTopics(true);
        } catch (error: any) {
          setError(error.response?.data || 'Failed to fetch topics');
          console.error('Error fetching topics:', error);
        } finally {
          setIsLoadingTopics(false);
        }
      }
    };

    fetchTopicsData();
  }, [dispatch, units, level, isLoadingTopics, hasFetchedUnits, hasFetchedTopics]);

  useEffect(() => {
    let timer: any = null;

    const clearTimer = () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    };

    const loadingOff = () => {
      if (timer) {
        setIsLoading(false);
      }
      clearTimer();
    };

    if (isLoadingUnits || isLoadingTopics) {
      setIsLoading(true);
    } else {
      timer = setTimeout(loadingOff, 100);
    }

    return () => {
      clearTimer();
    };
  }, [isLoadingUnits, isLoadingTopics]);

  return { units, isLoading, error };
};

export default useFetchUnitsWithTopics;
