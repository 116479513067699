import React from 'react';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import UserUtils from '@/utils/user';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({
  isAdminAllowed = false,
  children,
}: {
  isAdminAllowed?: boolean;
  children: React.ReactNode;
}) => {
  const { user, loggedIn } = useAppSelector(authSelector);
  const isAdmin = UserUtils.isAdmin(user);

  if (!loggedIn || (loggedIn && isAdmin && !isAdminAllowed)) {
    const to = isAdmin ? '/onboarding' : '/login';

    return <Navigate to={to} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
