import { useEffect, useState } from 'react';

let recognition: any = null;

if ('webkitSpeechRecognition' in window) {
  //TODO: Need to fix, because webkitSpeechRecognition is not defined
  //eslint-disable-next-line
  recognition = new webkitSpeechRecognition();
  recognition.continuous = false;
  recognition.lang = 'en-US';
  recognition.interimResults = false;
  recognition.maxAlternatives = 10;
}

const UseSpeechRecognition = () => {
  const [text, setText] = useState('');
  const [results, setResults] = useState<string[]>([]);
  const [isListening, setIsListening] = useState(false);
  const [disableToggle, setDisableToggle] = useState(false);
  const [isUserSpeechEmpty, setIsUserSpeechEmpty] = useState(false);
  useEffect(() => {
    if (!recognition) {
      return;
    }

    let speechDetected = false;

    recognition.onstart = () => {
      speechDetected = false;
      setIsUserSpeechEmpty(false);
    };

    recognition.onspeechstart = () => {
      speechDetected = true;
    };

    //eslint-disable-next-line
    recognition.onresult = (event: SpeechRecognitionEvent) => {
      console.log('SpeechRecognition:', event.results);

      speechDetected = true;

      const results: string[] = [];
      for (let resultIndex = 0; resultIndex < event.results.length; resultIndex++) {
        const result = event.results[resultIndex];
        if (result.isFinal) {
          for (let optionIndex = 0; optionIndex < result.length; optionIndex++) {
            results.push(result[optionIndex].transcript);
          }
          resultIndex = event.results.length;
        }
      }
      if (results.length === 0) {
        results.push('');
      }

      setResults(results);
      setText(results[0]);
    };

    recognition.onend = () => {
      if (!speechDetected) {
        setIsUserSpeechEmpty(true);
      }
      setIsListening(false);
    };

    //eslint-disable-next-line
    recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
      console.error('Speech recognition error detected: ' + event.error);
      setIsListening(false);
    };
  }, []);

  useEffect(() => {
    if (!recognition) return;

    try {
      if (isListening) {
        recognition.start();
      } else {
        recognition.stop();
      }
    } catch (e) {
      console.error('Error starting/stopping recognition:', e);
    }
  }, [isListening]);

  const onToggle = () => {
    if (disableToggle) {
      return;
    }

    if (!isListening) {
      setDisableToggle(true);

      setTimeout(() => {
        setDisableToggle(false);
      }, 1000);
    }

    setIsListening(prevState => !prevState);
  };

  const onReset = () => {
    setText('');
    setResults([]);
    recognition.abort();
    setIsListening(false);
    setIsUserSpeechEmpty(false);
  };

  return {
    text,
    results,
    isListening,
    onToggle,
    onReset,
    hasRecognitionSupport: !!recognition,
    isUserSpeechEmpty,
    setIsUserSpeechEmpty,
    setIsListening,
  };
};

export default UseSpeechRecognition;
