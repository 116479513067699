import UseSpeechRecognition from '@/hooks/UseSpeechRecognition';
import classNames from './PronunciationMicrophone.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Question } from '@/types/question';
import commonUtils from '@/utils/common';
import { useDispatch } from 'react-redux';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { GameStatus, NewGameTypes } from '@/types/game';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';

import AppLoaderCircle from '@/components/AppLoaderCircle';
import AnswerReward from '../../AnswerReward/AnswerReward';

import microphone from '@/assets/svg/microphone.svg';
import check from '@/assets/svg/check.svg';
import wrong from '@/assets/svg/wrong.svg';
import arrow from '@/assets/svg/right-arrow-medium.svg';
import microphoneGray from '@/assets/svg/microphoneGray.svg';
import wrongmic from '@/assets/svg/wrongmic.svg';
import UserUtils from '@/utils/user';
interface PronunciationMicrophoneProps {
  currentQuestion: Question;
  pickedAnswer?: string;
  emitOnRetry?: () => void;
  emitOnAnswer: (option: string) => void;
  emitOnNextQuestion?: () => void;
  checkIfIsListening: (isListening: boolean) => void;
}

const PronunciationMicrophone = ({
  currentQuestion,
  pickedAnswer,
  emitOnRetry,
  emitOnAnswer,
  emitOnNextQuestion,
  checkIfIsListening,
}: PronunciationMicrophoneProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useAppSelector(authSelector);

  const isStudent = UserUtils.isStudent(user);

  const {
    text,
    results,
    isListening,
    onToggle,
    onReset,
    isUserSpeechEmpty,
    setIsUserSpeechEmpty,
    setIsListening,
  } = UseSpeechRecognition();

  const [showFeedback, setShowFeedback] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [userSpeechEmptyCounter, setUserSpeechEmptyCounter] = useState(0);

  const retryValue = useRef(0);
  const allowRetry = retryValue.current < 2 && userSpeechEmptyCounter < 3;

  const questionTitle = currentQuestion.question?.toLowerCase();

  const { checkAnswerCompleted, isFinalAssessment, gameType } = useAppSelector(gameSelector);

  const isNaturalConversationType = gameType === NewGameTypes.NaturalConversation;

  const onAnswer = () => {
    if (!pickedAnswer) {
      dispatch(gameActions.toggleGameStatus(GameStatus.QUESTION_ANSWERED));
      dispatch(gameActions.setPickedAnswer({ answer: '' }));
      emitOnAnswer('');
    }

    emitOnNextQuestion?.();
    setShowSubmitBtn(false);
    setShowOverlay(false);
    retryValue.current = 0;
  };

  const onRetry = () => {
    retryValue.current += 1;
    emitOnRetry?.();
    dispatch(gameActions.onPlay());
    setShowSubmitBtn(false);
    setShowOverlay(false);
  };

  const postCheckAnswer = async () => {
    if (isCorrect) {
      retryValue.current = 0;
      await commonUtils.sleep(1500);
      setShowFeedback(false);
      setShowSubmitBtn(false);
      emitOnNextQuestion?.();
      return;
    }

    setShowFeedback(false);
    setShowSubmitBtn(false);

    if (!isFinalAssessment) {
      setShowOverlay(true);
    }
  };

  useEffect(() => {
    if (isUserSpeechEmpty) {
      setUserSpeechEmptyCounter(pref => pref + 1);
    }
  }, [isUserSpeechEmpty]);

  useEffect(() => {
    setUserSpeechEmptyCounter(0);
    onReset();
  }, [currentQuestion]);

  useEffect(() => {
    if (checkAnswerCompleted) {
      postCheckAnswer();
    }
  }, [checkAnswerCompleted]);

  const displayOverlay = async () => {
    if (pickedAnswer) {
      emitOnAnswer(pickedAnswer);
    }
  };

  useEffect(() => {
    if (showFeedback) {
      displayOverlay();
    }
  }, [showFeedback]);

  const setPickedAnswer = async () => {
    setIsProcessing(true);
    await commonUtils.sleep(1000);

    let isSimilar = false;
    let resultText = '';

    for (let resultIndex = 0; resultIndex < results.length; resultIndex++) {
      const lowerCaseText = results[resultIndex].toLocaleLowerCase();
      isSimilar = commonUtils.isSimilarPhrases(questionTitle, lowerCaseText);
      if (isSimilar) {
        resultText = results[resultIndex];
        resultIndex = results.length;
      }
    }
    if (!isSimilar) {
      resultText = text;
    }

    dispatch(gameActions.toggleGameStatus(GameStatus.QUESTION_ANSWERED));
    dispatch(gameActions.setPickedAnswer({ answer: resultText }));

    setIsCorrect(isSimilar);
    onReset();
    setIsProcessing(false);
    setShowFeedback(true);
  };

  useEffect(() => {
    if (text && !isListening) {
      setPickedAnswer();
    }
    checkIfIsListening(isListening);
  }, [isListening, text, results]);

  const feedback = (
    <div
      className={classes(classNames.feedback, {
        [classNames.feedbackCorrect]: isCorrect,
      })}
    >
      {isNaturalConversationType ? (
        <>
          <span>{t('goodJob')}</span>
          <div className={classNames.circle}>
            <SVG src={check} />
          </div>
        </>
      ) : (
        <>
          <span>{isCorrect ? t('goodJob') : t('incorrect')}</span>
          <div className={classNames.circle}>
            <SVG src={isCorrect ? check : wrong} />
          </div>
        </>
      )}
    </div>
  );

  const errorMessage = (
    <>
      <h2>{t('keepPracticing')}</h2>
      <span>{allowRetry ? t('uncorrectPronounce') : t('lastUncorrectPronounce')}</span>
    </>
  );

  const overlay = (
    <div className={classNames.overlay}>
      <div className={classNames.text}>{errorMessage}</div>

      <div className={classNames.buttons}>
        {allowRetry && (
          <button className={classNames.retry} onClick={onRetry}>
            <span>1</span>
          </button>
        )}
        <button className={classNames.nextQuestion} onClick={onAnswer}>
          <span></span>
        </button>
      </div>
    </div>
  );

  const submitBtn = (
    <button className={classNames.submitBtn} onClick={setPickedAnswer}>
      <SVG src={arrow} />
    </button>
  );

  let body = (
    <button
      className={classes(classNames.speakButton, {
        [classNames.speakPulse]: isListening,
      })}
      onClick={onToggle}
    >
      <SVG src={microphone} />
    </button>
  );

  let bottom: ReactElement<any, any> | null = !showOverlay ? (
    <span className={classNames.text}>
      {isListening ? t('listeningTapToFinish') : t('tapToSpeak')}
    </span>
  ) : null;

  if (isProcessing || (showFeedback && !isCorrect)) {
    body = <AppLoaderCircle className={classNames.loader} />;
    bottom = <span className={classNames.text}>{t('processing')}...</span>;
  } else if (showFeedback) {
    body = feedback;
    bottom =
      isStudent &&
      isCorrect &&
      currentQuestion.coins &&
      !currentQuestion.userData?.achievedCoins ? (
        <AnswerReward className={classNames.answerReward} coins={currentQuestion.coins} />
      ) : null;
  } else if (showOverlay) {
    body = overlay;
  } else if (showSubmitBtn) {
    body = submitBtn;
    bottom = <span className={classNames.text}>{t('sendNow')}</span>;
  }
  if (isUserSpeechEmpty) {
    if (userSpeechEmptyCounter >= 3) {
      body = overlay;
      bottom = null;
    } else {
      body = (
        <div className={classNames.pronunciationEmpty}>
          <div className={classNames.errorMessageContainer}>
            <SVG src={wrongmic} />
            <span>{t('didntQuiteHearThat')}</span>
          </div>

          <div className={classNames.overlay}>
            <div className={classNames.buttons}>
              {allowRetry && (
                <button
                  className={classNames.retry}
                  onClick={() => {
                    setIsUserSpeechEmpty(false);
                    setIsListening(true);
                  }}
                >
                  <span></span>
                </button>
              )}
              <button className={classNames.nextQuestion} onClick={onAnswer}>
                <span></span>
              </button>
            </div>
          </div>
        </div>
      );
      bottom = null;
    }
  }
  return (
    <div className={classNames.pronunciationMicrophone}>
      <div className={classNames.body}>{body}</div>
      <div className={classNames.bottom}>{bottom}</div>
    </div>
  );
};

export default React.memo(PronunciationMicrophone);
