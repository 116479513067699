import { LanguagePreferences } from './user';

export type ValidateAdminCode = {
  isCodeValid: boolean;
  isAllowedToRegister: boolean;
  fromToLanguage: string;
  country: string;
  nativeLanguageName: string;
  nativeLanguageCode: string;
};

export type OrganizationDetails = {
  id: string;
  dealId: string;
  creatorId: string;
  organizationName: string;
  state: string;
  cityOrDistrict: string;
  country: string;
  isAllowedToRegister: boolean;
  languagePreferences: LanguagePreferences;
};

export type GroupDetails = {
  isAllowedToRegister: boolean;
  groupName: string;
  teacherName: string;
  fromToLanguage: string;
  nativeLanguage: string;
};

export type GroupStudents = StudentsRegisteredResponse[];

export type AdminOrganizationDetails = {
  organizationName: string;
  state: string;
  cityOrDistrict: string;
  country: string;
};

export type OrganizationInviteDetails = {
  organizationId: string;
  organizationName: string;
  invitationLinkForTeachers: string;
};

export type GroupCreationDetails = {
  invitationLink: string;
};

export type RegisteredTeachersData = {
  teachers: RegisteredTeacherDetails[];
  invitationLink: string;
};

export type RegisteredTeacherDetails = {
  firstName: string;
  lastName: string;
  groupsNumber: number;
  totalStudents: number;
};

export type BaseGroupDetails = {
  groupName: string;
  grade: string;
  allowIndividualAdvancement?: boolean;
  initalLevelTest?: boolean;
  finalAssessment?: {
    date?: string;
    isActivated: boolean;
  };
};

export type CreateGroupDetails = BaseGroupDetails & {};

export type EditGroupDetails = BaseGroupDetails & {
  groupId: string;
};

export type CreateUserDetails = {
  firstName: string;
  lastName: string;
  userId: string; // USER ID FROM COGNITO
  email: string;
};

export type CreateStudentData = {
  firstName: string;
  lastName: string;
  email: string;
};

export type CreateCsvStudentsData = {
  registeredStudents: StudentsResponse[];
  unregisteredStudents: StudentsResponse[];
};

export type StudentsResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  error: string;
};

export enum UserStatusEnum {
  INACTIVE = 'INACTIVE',
  LEVEL_TEST_INCOMPLETE = 'LEVEL_TEST_INCOMPLETE',
  ACTIVE = 'ACTIVE',
}

export type StudentsRegisteredResponse = {
  studentId: string;
  firstName: string;
  lastName: string;
  email: string;
  isRegistered: boolean;
  userStatus: UserStatusEnum;
};

export type RegistrationQueryData = {
  dealId: string;
  organizationId: string;
};
