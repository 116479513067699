import config from '@/config';
import {
  CanDoAnswersResponse,
  UnitsTopicsResponse,
  TopicsActivitiesResponse,
  UserUnitsResponse,
} from '@/types/response/unit-data';
import { UnitDataService } from '@/types/services';
import axiosInstanceNew from '@/utils/api-new';
import UserUtils from '@/utils/user';
import { UserState } from '@/types/user';

export const unitDataService: UnitDataService = {
  fetchCanDoAnswers: async (user: UserState | null) => {
    const fromToLanguage = UserUtils.fromToLanguage(user);
    const resp: CanDoAnswersResponse = await axiosInstanceNew.get(
      `${config.apiVersion}/${fromToLanguage}/settings/can-do-answers`
    );
    return resp;
  },
  fetchUnitTopics: async (user: UserState | null, level: number | null, unitId: number | null) => {
    const fromToLanguage = UserUtils.fromToLanguage(user);
    const resp: UnitsTopicsResponse = await axiosInstanceNew.get(
      `${config.apiVersion}/${fromToLanguage}/game-content/${level ?? 1}/${unitId ?? 1}`
    );
    return resp;
  },
  fetchTopicActivities: async (
    user: UserState | null,
    level: number | null,
    unitId: number | null,
    topicId: number | null
  ) => {
    const fromToLanguage = UserUtils.fromToLanguage(user);
    const resp: TopicsActivitiesResponse = await axiosInstanceNew.get(
      `${config.apiVersion}/${fromToLanguage}/game-content/${level ?? 1}/${unitId ?? 1}/${topicId ?? 1}`
    );
    return resp;
  },
  fetchUserUnits: async (studentId: string, teacherId?: string | null) => {
    const fetchUrl = teacherId
      ? `${config.apiVersion}/teacher-view/student/progress/${teacherId}/${studentId}`
      : `${config.apiVersion}/user-progress/${studentId}`;
    const resp: UserUnitsResponse = await axiosInstanceNew.get(fetchUrl);
    return resp;
  },
};
