import { StudentData, StudentValidationError } from '@/types/student-data';

const validateEmail = (email: string): boolean => {
  const pattern = /\S+@\S+\.\S+/;
  return pattern.test(email);
};

const validateName = (name: string): boolean => {
  const pattern = /^[-A-Za-z\s'\u0590-\u05FF\u00C0-\u00FF\u0100-\u01FF\u0200-\u02AF]+$/;
  return pattern.test(name);
};

export const validateFirstName = (firstName: string): StudentValidationError | null => {
  if (!firstName) {
    return { field: 'firstName', message: 'first_name_missing' };
  } else if (!validateName(firstName)) {
    return { field: 'firstName', message: 'first_name_incorrect' };
  }
  return null;
};

export const validateLastName = (lastName: string): StudentValidationError | null => {
  if (!lastName) {
    return { field: 'lastName', message: 'last_name_missing' };
  } else if (!validateName(lastName)) {
    return { field: 'lastName', message: 'last_name_incorrect' };
  }
  return null;
};

const ValidateStudent = (student: StudentData): StudentValidationError[] => {
  const errors: StudentValidationError[] = [];

  const firstNameValidation = validateFirstName(student.firstName);
  if (firstNameValidation) {
    errors.push(firstNameValidation);
  }

  const lastNameValidation = validateLastName(student.lastName);
  if (lastNameValidation) {
    errors.push(lastNameValidation);
  }

  if (!student.email || !validateEmail(student.email)) {
    errors.push({ field: 'email', message: 'email_incorrect' });
  }

  return errors;
};

export default ValidateStudent;
