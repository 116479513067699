import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
//TODO: Need to understand if it's needed
// import commonUtils from '@/utils/common';
// import UseLocalLang from './UseLocalLang';
import { useTranslation } from 'react-i18next';

const UseLevel = () => {
  const { t } = useTranslation();
  const { levels } = useAppSelector(authSelector);
  //TODO: Need to understand if it's needed
  // const localLang = UseLocalLang();

  const getLevelText = (level?: number) => {
    const levelObj = levels.find(i => i.id === level);
    const text = levelObj ? t(levelObj.name?.toLowerCase() ?? '') : '';

    return text ? text.charAt(0).toUpperCase() + text.slice(1) : '';
  };

  return { getLevelText };
};

export default UseLevel;
