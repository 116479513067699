import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';
import NoStudents from '@/components/StudentsData/NoStudents';
import StudentCard from './StudentCard/StudentCard';
import Detail from '../../UserInfo/Detail/Detail';
import DetailSeperator from '../../DetailSeperator/DetailSeperator';
import Dropdown, { DropdownOption } from '@/components/Dropdown/Dropdown';
import classes from 'classnames';
import classNames from './StudentsCards.module.scss';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { SortByOption } from '@/types/teacher-dashboard';
import { TeacherGroup, UserState } from '@/types/user';
import { GroupStudentProgress, StudentsSkillsProgress } from '@/types/progress';
import { StudentsRegisteredResponse, UserStatusEnum } from '@/types/register';
import UseScreenSize from '@/hooks/UseScreenSize';
import UseLevel from '@/hooks/UseLevel';

import SearchStudents from '../../SearchStudents/SearchStudents';

import upArrow from '@/assets/svg/up-arrow.svg';
import downArrow from '@/assets/svg/down-arrow.svg';
import upArrowLong from '@/assets/svg/up-arrow-long.svg';
import downArrowLong from '@/assets/svg/down-arrow-long.svg';
import pencilBlank from '@/assets/svg/pencilBlank.svg';
import avgLevelIcon from '@/assets/svg/avgLevel.svg';
import avgScoreIcon from '@/assets/svg/avgScore.svg';
import avgProgressIcon from '@/assets/svg/avgProgress.svg';
import reportIcon from '@/assets/svg/report.svg';
import GenerateReport from '../UserData/GenerateReport/GenerateReport';
import { ReportTypes } from '../../../types/generate-report';

interface AvgViewData {
  type: string;
  icon: string;
  value: string;
  label: string;
  button?: any;
}

interface StudentsCardsProps {
  students: GroupStudentProgress[];
  studentsExtraData: Map<string, StudentsRegisteredResponse>;
  statistics: StudentsSkillsProgress | null;
  searchValue: string;
  currentClassroom: TeacherGroup;
  emitShowDataModal: (studentId: string) => void;
  setSearchValue: (value: string) => void;
  setIsClassEdit: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

const StudentsCards = ({
  students,
  studentsExtraData,
  statistics,
  searchValue,
  emitShowDataModal,
  setSearchValue,
  currentClassroom,
  setIsClassEdit,
}: StudentsCardsProps) => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = UseScreenSize();
  const { getLevelText } = UseLevel();

  const sortByOptions: SortByOption[] = [
    {
      key: 'studentName',
      value: 'asc',
      icon: downArrowLong,
    },
    {
      key: 'studentName',
      value: 'desc',
      icon: upArrowLong,
    },
    {
      key: 'level',
      value: 'asc',
      icon: downArrowLong,
    },
    {
      key: 'level',
      value: 'desc',
      icon: upArrowLong,
    },
    {
      key: 'progress',
      value: 'asc',
      icon: downArrowLong,
    },
    {
      key: 'progress',
      value: 'desc',
      icon: upArrowLong,
    },
    {
      key: 'score',
      value: 'asc',
      icon: downArrowLong,
    },
    {
      key: 'score',
      value: 'desc',
      icon: upArrowLong,
    },
  ];

  const [currentOption, setCurrentOption] = useState(sortByOptions[0]);
  const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  const onSelectedOption = (option: SortByOption, e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.stopPropagation();
    }
    setCurrentOption(option);
  };

  const onSortByDropdownChange = (value: any, option: DropdownOption) => {
    onSelectedOption(option.data);
  };

  const sortByOptionsElements = isMobile
    ? (() => {
        const optionsGroups = sortByOptions.reduce<{ options: any; order: string[] }>(
          (sortByOptions, option) => {
            if (!sortByOptions.options[option.key]) {
              sortByOptions.options[option.key] = {
                key: option.key,
              };
              sortByOptions.order.push(option.key);
            }
            sortByOptions.options[option.key][option.value] = option;
            return sortByOptions;
          },
          { options: {}, order: [] }
        );

        return optionsGroups.order.map<DropdownOption>(key => {
          const option = optionsGroups.options[key];

          return {
            label: t(option.key === 'level' ? 'status' : option.key),
            value: `${option.key}_${option.asc.value}`,
            data: option.asc,
          };
        });
      })()
    : (() => {
        const optionsGroups = sortByOptions.reduce<{ options: any; order: string[] }>(
          (sortByOptions, option) => {
            if (!sortByOptions.options[option.key]) {
              sortByOptions.options[option.key] = {
                key: option.key,
              };
              sortByOptions.order.push(option.key);
            }
            sortByOptions.options[option.key][option.value] = option;
            return sortByOptions;
          },
          { options: {}, order: [] }
        );

        return optionsGroups.order.map(key => {
          const option = optionsGroups.options[key];
          const isSelected = currentOption.key === option.key;

          return (
            <div key={`${option.key}`} className={classNames.columnHeader}>
              <button>
                <span
                  onClick={() =>
                    onSelectedOption(
                      !isSelected || currentOption.value === 'desc' ? option.asc : option.desc
                    )
                  }
                  className={classes(classNames.columnPart, {
                    [classNames.columnPartOn]: isSelected,
                  })}
                >
                  {t(option.key === 'level' ? 'status' : option.key)}
                </span>
                <span className={classNames.columnIcons}>
                  {option.asc && (
                    <SVG
                      src={option.asc.icon}
                      onClick={() => onSelectedOption(option.asc)}
                      className={classes(classNames.columnPart, {
                        [classNames.columnPartOn]: isSelected && currentOption.value === 'asc',
                      })}
                    />
                  )}
                  {option.desc && (
                    <SVG
                      src={option.desc.icon}
                      onClick={() => onSelectedOption(option.desc)}
                      className={classes(classNames.columnPart, {
                        [classNames.columnPartOn]: isSelected && currentOption.value === 'desc',
                      })}
                    />
                  )}
                </span>
              </button>
            </div>
          );
        });
      })();

  const onSortStudents = (curr: GroupStudentProgress, next: GroupStudentProgress) => {
    const sortValueMapper: { [key: string]: any } = {
      studentName: (item: GroupStudentProgress) =>
        `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()}`,
      level: (item: GroupStudentProgress) => {
        const userStatus =
          (item.id &&
            studentsExtraData.has(item.id) &&
            studentsExtraData.get(item.id)?.userStatus) ||
          null;

        if (userStatus === UserStatusEnum.INACTIVE) {
          return -2;
        }
        if (userStatus === UserStatusEnum.LEVEL_TEST_INCOMPLETE) {
          return -1;
        }

        return item.level ?? 0;
      },
      progress: (item: GroupStudentProgress) => (item.level ? item.progress.activitiesProgress : 0),
      score: (item: GroupStudentProgress) => (item.level ? item.progress.aVGScore : -1),
    };

    const sortFunctionMapper: { [key: string]: any } = {
      asc: (item1: number | string, item2: number | string) =>
        item1 < item2 ? -1 : item1 > item2 ? 1 : 0,
      desc: (item1: number | string, item2: number | string) =>
        item1 < item2 ? 1 : item1 > item2 ? -1 : 0,
    };

    const { key, value } = currentOption;
    let sortFunction = sortFunctionMapper[value];
    const getValue = sortValueMapper[key];

    if (key === 'studentName') {
      const hasSpecialCharacters = (item1: string, item2: string) => {
        const regexes = /[\u0590-\u05FF\u00C0-\u00FF\u0100-\u01FF\u0200-\u02AF]/;
        return regexes.test(item1) || regexes.test(item2);
      };

      sortFunction =
        value === 'asc'
          ? (item1: string, item2: string) =>
              hasSpecialCharacters(item1, item2)
                ? item1.localeCompare(item2)
                : sortFunctionMapper.asc(item1, item2)
          : (item1: string, item2: string) =>
              hasSpecialCharacters(item1, item2)
                ? item2.localeCompare(item1)
                : sortFunctionMapper.desc(item1, item2);
    }

    return sortFunction(getValue(curr), getValue(next));
  };

  const studentsCards = students
    .filter(
      student =>
        student?.firstName?.toLowerCase().startsWith(searchValue.toLowerCase()) ||
        student?.lastName?.toLowerCase().startsWith(searchValue.toLowerCase())
    )
    .sort(onSortStudents)
    .map((student, index) => {
      const isLast = index === students.length - 1;
      const userStatus =
        (student.id &&
          studentsExtraData.has(student.id) &&
          studentsExtraData.get(student.id)?.userStatus) ||
        null;

      return isDesktop ? (
        <div className={classNames.studentCard} key={student?.id}>
          <StudentCard
            student={student}
            emitShowDataModal={emitShowDataModal}
            userStatus={userStatus}
          />
          {!isLast && (
            <SectionSeperator className={classes('my-5', classNames.studentCardSeperator)} />
          )}
        </div>
      ) : (
        <StudentCard
          key={student?.id}
          student={student}
          emitShowDataModal={emitShowDataModal}
          userStatus={userStatus}
        />
      );
    });

  const createAvgView = (viewData: AvgViewData | null) => {
    return (
      viewData && (
        <div key={viewData.type} className={classNames.avgData}>
          <div className={classNames.avgDataIcon}>
            <SVG src={viewData.icon} />
          </div>
          <div className={classNames.avgDataValue}>{viewData.value}</div>
          <div
            className={classes(classNames.avgDataLabel, {
              [classNames.avgDataButton]: !!viewData.button,
            })}
          >
            {viewData.button || t(isMobile ? `${viewData.label}_mobile` : viewData.label)}
          </div>
        </div>
      )
    );
  };

  const reporButton =
    !isMobile &&
    createAvgView({
      type: 'report',
      icon: reportIcon,
      value: t('classReport'),
      label: '',
      button: (
        <GenerateReport currentClassroom={currentClassroom} reportType={ReportTypes.class}>
          {t('downloadPDF')}
        </GenerateReport>
      ),
    });

  const avgData = useMemo(() => {
    return [
      {
        type: 'level',
        icon: avgLevelIcon,
        label: 'avgLevel',
        value: (value: number) => (isMobile ? `${value}` : `${value} | ${getLevelText(value)}`),
      },
      {
        type: 'score',
        icon: avgScoreIcon,
        label: 'avgScore',
        value: (value: number) => `${value}`,
      },
      {
        type: 'progress',
        icon: avgProgressIcon,
        label: 'avgProgress',
        value: (value: number) => `${value}%`,
      },
    ].map(avg => {
      const avgValue = statistics ? statistics[avg.type] || 0 : -1;
      const viewData = {
        ...avg,
        value: avgValue >= 0 ? avg.value(avgValue) : '-',
      };
      return createAvgView(viewData);
    });
  }, [statistics, getLevelText, isMobile]);

  const usersStatusData = useMemo(() => {
    return (students || []).reduce(
      (status, student) => {
        const extraData = studentsExtraData.get(student.id);

        if (extraData) {
          if (extraData.userStatus === UserStatusEnum.INACTIVE) {
            status.inactive += 1;
          }
          if (extraData.userStatus === UserStatusEnum.ACTIVE) {
            status.active += 1;
          }
        }

        return status;
      },
      {
        active: 0,
        inactive: 0,
      }
    );
  }, [students, studentsExtraData]);

  const hasStudents = students && students.length > 0;

  return (
    <div
      className={classes(classNames.studentsCards, {
        [classNames.isMobile]: isMobile,
      })}
    >
      <div className={classNames.header}>
        <div className={classNames.texts}>
          <h2>{currentClassroom?.name}</h2>
          <div className={classNames.details}>
            {currentClassroom.grade && (
              <Detail
                className={classNames.detail}
                topic={t('grade')}
                value={
                  currentClassroom.grade.toLowerCase() === 'university' ||
                  currentClassroom.grade.toLowerCase() === 'none'
                    ? t(currentClassroom.grade)
                    : t(`grade_${currentClassroom.grade}`)
                }
              />
            )}

            {currentClassroom && (
              <>
                <DetailSeperator />
                <Detail
                  className={classNames.detail}
                  topic={t('students')}
                  value={`${currentClassroom.totalStudents || 0}/50`}
                />
              </>
            )}

            {usersStatusData && (
              <>
                <DetailSeperator />
                <Detail
                  className={classNames.detail}
                  topic={t('active')}
                  value={usersStatusData.active || '-'}
                />

                <DetailSeperator />
                <Detail
                  className={classNames.detail}
                  topic={t('inactive')}
                  value={usersStatusData.inactive || '-'}
                />
              </>
            )}
          </div>
        </div>
        {isDesktop && currentClassroom.id && (
          <button
            onClick={() => {
              if (setIsClassEdit) {
                setIsClassEdit(true);
              }
            }}
            className={classNames.editIcon}
          >
            <SVG src={pencilBlank} />
          </button>
        )}
      </div>
      <div className={classNames.studentsStatistics}>
        {avgData}
        {reporButton}
      </div>
      {isDesktop && (
        <SectionSeperator className={classes('my-7', classNames.studentCardSeperator)} />
      )}
      {isMobile && hasStudents && (
        <div className={classNames.topBar}>
          <SearchStudents searchValue={searchValue} onInputChange={onInputChange} />
          <Dropdown
            options={sortByOptionsElements as DropdownOption[]}
            value={`${currentOption.key}_${currentOption.value}`}
            onChange={onSortByDropdownChange}
            label={<u>{t('sortBy')}</u>}
            size='s'
            type='text'
          />
        </div>
      )}
      {hasStudents && (
        <div className={classNames.studentsCardsContainer}>
          {isDesktop && <>{sortByOptionsElements}</>}
          {studentsCards}
        </div>
      )}
      {!hasStudents && <NoStudents currentClassroom={currentClassroom} />}
    </div>
  );
};

export default React.memo(StudentsCards);
