import { useEffect, useState } from 'react';
import { gameActions, gameSelector } from '@/store/reducers/game';
import levelTestThunks from '@/store/thunks/level-test';
import { useAppDispatch, useAppSelector } from '@/store';
import { levelTestSelector } from '@/store/reducers/level-test';
import { useNavigate } from 'react-router-dom';
import UseInitialLevelTestGame from '@/hooks/UseInitialLevelTest';

const UseLevelTest = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activity, questionIndex } = useAppSelector(levelTestSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { startInitialLevelTestGame } = UseInitialLevelTestGame();

  const onBegin = async () => {
    setIsLoading(true);

    dispatch(gameActions.setIsLevelTest(true));
    dispatch(levelTestThunks.fetchLevelTestQuestions());
  };
  // START LEVEL TEST, QUIZZES ARE LOADED
  useEffect(() => {
    if (activity?.questions) {
      startInitialLevelTestGame(0);

      setIsLoading(false);
      navigate('/dashboard/quiz');
    }
  }, [activity, questionIndex]);

  const onSubmit = () => {
    setIsSubmitted(true);
  };

  return {
    isLoading,
    showSummary,
    isSubmitted,
    onSubmit,
    onBegin,
    setShowSummary,
  };
};

export default UseLevelTest;
