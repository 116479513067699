import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from './Dropdown.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';

import upArrow from '@/assets/svg/up-arrow.svg';
import downArrow from '@/assets/svg/down-arrow.svg';

export interface DropdownOption {
  label: any;
  value: any;
  data?: any;
}

export type DropdownSize = 'm' | 's';
export type DropdownType = 'button' | 'text' | 'field';
export type selectedOptionType = DropdownOption | null | undefined;

interface DropdownProps {
  options: DropdownOption[];
  value: any;
  label?: any;
  size?: DropdownSize;
  type?: DropdownType;
  wrapperClassName?: string;
  dropdownClassName?: string;
  onChange?: (value: any, option: DropdownOption) => void;
  selectedOptionView?(selectedOption: selectedOptionType): any;
}

const Dropdown = ({
  options,
  value,
  label,
  size = 'm',
  type = 'button',
  wrapperClassName,
  dropdownClassName,
  onChange,
  selectedOptionView,
}: DropdownProps) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<selectedOptionType>(null);

  useEffect(() => {
    const option = options.find(option => option.value === value);
    setSelectedOption(option);
  }, [value, options]);

  const onCloseDropdown = useCallback(() => {
    setShowDropdown(false);
  }, []);

  useEffect(() => {
    if (!showDropdown) {
      return () => {};
    }

    const closeDropdown = () => {
      onCloseDropdown();
    };

    window.addEventListener('click', closeDropdown);

    return () => {
      window.removeEventListener('click', closeDropdown);
    };
  }, [showDropdown, onCloseDropdown]);

  const onToggleDropdown = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowDropdown(prevState => !prevState);
  }, []);

  const onSelectedOption = useCallback(
    (e: React.MouseEvent<HTMLElement>, option: DropdownOption) => {
      e.stopPropagation();
      onCloseDropdown();
      setSelectedOption(option);
      if (onChange) {
        onChange(option.value, option);
      }
    },
    [onCloseDropdown, onChange]
  );

  const dropdownOptions = useMemo(() => {
    return options.map((option, index) => (
      <div
        key={index}
        className={classNames.option}
        onClick={event => onSelectedOption(event, option)}
      >
        <span>{option.label}</span>
      </div>
    ));
  }, [options, onSelectedOption]);

  const dropdown = useMemo(() => {
    return (
      showDropdown && (
        <div className={classes(classNames.dropdown, dropdownClassName)}>{dropdownOptions}</div>
      )
    );
  }, [showDropdown, dropdownOptions]);

  return (
    <div
      className={classes(
        classNames.dropdonWrapper,
        wrapperClassName,
        classNames[`dropdonSize_${size}`],
        classNames[`dropdonType_${type}`]
      )}
    >
      <div className={classNames.select} onClick={onToggleDropdown}>
        <div className={classNames.texts}>
          {label && <span className={classNames.label}>{label}</span>}
          <span className={classNames.selectedValue}>
            {selectedOptionView && selectedOptionView(selectedOption)}
            {!selectedOptionView && selectedOption && selectedOption.label}
          </span>
        </div>
        <SVG src={showDropdown ? upArrow : downArrow} />
      </div>
      {dropdown}
    </div>
  );
};

export default Dropdown;
