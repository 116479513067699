import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/types/common';
import { EndOfSkillState, EndOfSkillTestQuestionsResponse } from '@/types/end-of-skill';
import endOfSkillThunks from '../thunks/end-of-skill';
import { Topic } from '@/types/topic';

const defaultEndOfSkillState: EndOfSkillState = {
  quiz: null,
  prevTopic: null,
  currentReadQuizIndex: 1,
};
const endOfSkill = createSlice({
  name: 'EndOfSkill',
  initialState: defaultEndOfSkillState,
  reducers: {
    updateCurrentReadQuizIndex: state => {
      state.currentReadQuizIndex = (state?.currentReadQuizIndex ?? 0) + 1;
    },
    resetCurrentReadQuizIndex: state => {
      state.currentReadQuizIndex = 1;
    },
  },
  extraReducers: {
    [endOfSkillThunks.fetchEndOfSkillTest.fulfilled.toString()]: (
      state,
      {
        payload,
      }: PayloadAction<{
        questions: EndOfSkillTestQuestionsResponse;
        topic: Topic;
      }>
    ) => {
      state.quiz = payload.questions;
      state.prevTopic = payload.topic;
    },
  },
});

export const endOfSkillActions = endOfSkill.actions;

export const endOfSkillSelector = (state: RootState) => state.endOfSkill;

export default endOfSkill.reducer;
