import React, { useState, useMemo, useCallback } from 'react';
import classNames from './DeleteStudent.module.scss';
import classes from 'classnames';
import { Transition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import Field from '../EditContainer/field';
import TheButton from '@/components/TheButton/TheButton';
import Dropdown, { DropdownOption, selectedOptionType } from '@/components/Dropdown/Dropdown';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import SVG from 'react-inlinesvg';
import closeIcon from '@/assets/svg/close.svg';
import oopsIcon from '@/assets/svg/oops.svg';

interface DeleteStudentProps {
  show: boolean;
  onClose: () => void;
  onDelete: (reason: string) => void;
  isStudentDeleting: boolean;
  showStudentDeleteError: boolean;
  setShowStudentDeleteError: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteStudent = ({
  show,
  onClose,
  onDelete,
  isStudentDeleting,
  showStudentDeleteError,
  setShowStudentDeleteError,
}: DeleteStudentProps) => {
  const { t } = useTranslation();
  const [selectedReason, setSelectedReason] = useState('');
  const [otherReason, setOtherReason] = useState('');

  const reasonsDropdownOptions: DropdownOption[] = useMemo(() => {
    return ['requestedToBeRemoved', 'aClericalError', 'other'].map(reason => {
      return {
        value: reason,
        label: (
          <span className={classNames.peasonOption}>
            {t(`settings.delete_student.reasons.${reason}`)}
          </span>
        ),
      };
    });
  }, [t]);

  const selectedOptionView = useCallback(
    (selectedOption: selectedOptionType) => {
      return (
        <span className={classNames.selectedReason}>
          {selectedOption ? selectedOption.label : t('settings.delete_student.choose')}
        </span>
      );
    },
    [t]
  );

  const onReasonChange = useCallback((reason: any) => {
    setSelectedReason(reason as string);
  }, []);

  const onOtherTextChange = useCallback((event: any) => {
    setOtherReason(event?.target?.value || '');
  }, []);

  const onDeleteHandlebar = useCallback(() => {
    onDelete(
      selectedReason === 'other'
        ? otherReason
        : t(`settings.delete_student.reasons.${selectedReason}`)
    );
  }, [selectedReason, otherReason]);

  const onTryAgainHandlebar = useCallback(() => {
    setShowStudentDeleteError(false);
  }, [setShowStudentDeleteError]);

  const modal = showStudentDeleteError ? (
    <div className={classes(classNames.modal, classNames.deletingFailed)}>
      <SVG src={oopsIcon} className={classNames.errorIcon} />
      <div className={classNames.oops}>{t('oops')}!</div>
      <h2>{t('settings.delete_student.deletingFailed')}</h2>
      <div
        className={classNames.description}
        dangerouslySetInnerHTML={{
          __html: t('settings.delete_student.deletingFailedDescription', {
            email: 'info@eb.com',
          }).toString(),
        }}
      />
      <div className={classNames.buttons}>
        <TheButton
          className={classNames.tryAgainBtn}
          text={t('settings.delete_student.tryAgain')}
          shouldRecolor={false}
          emitOnClick={onTryAgainHandlebar}
        />
      </div>
      <button className={classNames.closeErrorBtn} onClick={onClose}>
        <SVG src={closeIcon} />
      </button>
    </div>
  ) : (
    <div className={classNames.modal}>
      <h2>{t('settings.delete_student.areYouSure')}</h2>
      <div className={classNames.description}>{t('settings.delete_student.description')}</div>
      <div className={classNames.form}>
        <Field label={t('settings.delete_student.reason').toString()}>
          <Dropdown
            value={selectedReason || t('settings.delete_student.choose')}
            options={reasonsDropdownOptions}
            onChange={onReasonChange}
            selectedOptionView={selectedOptionView}
            type='field'
          />
        </Field>
        {selectedReason === 'other' && (
          <Field label={t('settings.delete_student.why').toString()}>
            <textarea value={otherReason} onChange={onOtherTextChange} />
          </Field>
        )}
      </div>
      <div className={classNames.buttons}>
        <TheButton
          className={classNames.deleteBtn}
          text={t('settings.delete_student.delete')}
          shouldRecolor={false}
          disabled={!selectedReason || (selectedReason === 'other' && !otherReason)}
          emitOnClick={onDeleteHandlebar}
        />
        <TheButton
          className={classNames.cancelBtn}
          text={t('settings.delete_student.cancel')}
          plain
          emitOnClick={onClose}
        />
      </div>
      {isStudentDeleting && (
        <div className={classNames.loader}>
          <AppLoaderCircle />
        </div>
      )}
    </div>
  );

  return (
    <Transition
      in={show}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => {
        return <div className={classes(classNames.deleteStudent, `fade-${state}`)}>{modal}</div>;
      }}
    />
  );
};

export default DeleteStudent;
