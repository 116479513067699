import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LevelTestState } from '@/types/level-test';
import { LevelTestResultResponse, UserLevelTestResultResponse } from '@/types/response';
import { RootState } from '@/types/common';
import levelTestThunks from '../thunks/level-test';
import { LevelTestQuestionResult } from '@/types/game';
import { LevelTestItem } from '@/types/quiz';

const defaultLevelTestState: LevelTestState = {
  quizzes: null,
  progress: null,
  currentQuizIndex: -1,
  isLevelTestLoading: false,
  isFinishLevelTest: false,
  currentOverAllQuizNumber: -1,
  activity: null,
  answers: [],
  correctAnswers: 0,
  wrongAnswers: 0,
  questionIndex: 0,
};
const levelTest = createSlice({
  name: 'LevelTest',
  initialState: defaultLevelTestState,
  reducers: {
    setActivity: (state, { payload }) => {
      state.activity = payload;
    },
    updateCurrentQuizIndex: (state, { payload }: PayloadAction<number>) => {
      state.currentQuizIndex = payload;
      state.currentOverAllQuizNumber = state.currentOverAllQuizNumber + 1;
    },
    updateLevelTestQuestionsResults: (
      state,
      { payload }: PayloadAction<{ levelTestQuestionsResults: LevelTestQuestionResult[] }>
    ) => {
      state.progress?.levelTestQuestionsResults.push(...payload.levelTestQuestionsResults);
    },
    setQuizzes: (state, { payload }) => {
      state.quizzes = payload;
    },
    setIsLevelTestLoading: (state, { payload }) => {
      state.isLevelTestLoading = payload;
    },
    startLevelTestProgress: (state, { payload }: PayloadAction<string>) => {
      state.progress = {
        correctAnswers: 0,
        levelTestQuestionsResults: [],
        wrongAnswers: 0,
        startTime: payload,
        finishTime: '',
      };
    },
    finishLevelTestProgress: (state, { payload }: PayloadAction<string>) => {
      if (state.progress) {
        state.progress.finishTime = payload;
      }
    },
    updateLevelTestAnswers: (
      state,
      {
        payload,
      }: PayloadAction<{
        correctAnswers: number;
        wrongAnswers: number;
      }>
    ) => {
      if (state.progress) {
        const { correctAnswers, wrongAnswers } = payload;

        state.progress.correctAnswers += correctAnswers;
        state.progress.wrongAnswers += wrongAnswers;
      }
    },
    setIsFinishLevelTest: (state, { payload }: PayloadAction<boolean>) => {
      state.isFinishLevelTest = payload;
    },
    setAnswer: (state, { payload }: PayloadAction<any>) => {
      if (payload.isCorrect) {
        state.correctAnswers = state.correctAnswers + 1;
      } else {
        state.wrongAnswers = state.wrongAnswers + 1;
      }
      state.answers = [...state.answers, payload];
    },
    setQuestionIndex: (state, { payload }: PayloadAction<any>) => {
      state.questionIndex = payload;
    },
    reset: state => {
      state.quizzes = null;
      state.progress = null;
      state.currentQuizIndex = -1;
      state.isLevelTestLoading = false;
      state.isFinishLevelTest = true;
      state.currentOverAllQuizNumber = -1;
      state.answers = [];
      state.correctAnswers = 0;
      state.wrongAnswers = 0;
      state.questionIndex = 0;
    },
    isLogOut: state => {
      state.quizzes = null;
      state.progress = null;
      state.currentQuizIndex = -1;
      state.isLevelTestLoading = false;
      state.isFinishLevelTest = false;
      state.currentOverAllQuizNumber = -1;
      state.activity = null;
      state.answers = [];
      state.correctAnswers = 0;
      state.wrongAnswers = 0;
      state.questionIndex = 0;
    },
  },
  extraReducers: {
    [levelTestThunks.postUserLevelTestResult.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<LevelTestResultResponse>
    ) => {
      if (payload.getMoreQuestions) {
        state.quizzes = payload.questions;
      } else {
        state.isFinishLevelTest = true;
      }
      state.isLevelTestLoading = false;
    },
    [levelTestThunks.postUserLevelTestResult.pending.toString()]: (
      state,
      { payload }: PayloadAction<UserLevelTestResultResponse>
    ) => {
      state.isLevelTestLoading = true;
    },
    [levelTestThunks.fetchNewLevelTestQuestions.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<LevelTestItem[]>
    ) => {
      state.quizzes = payload;
      state.isLevelTestLoading = false;
    },
    [levelTestThunks.fetchNewLevelTestQuestions.rejected.toString()]: state => {
      state.quizzes = null;
    },
    [levelTestThunks.fetchLevelTestQuestions.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<LevelTestItem[]>
    ) => {
      state.activity = payload;
      state.isLevelTestLoading = false;
    },
    [levelTestThunks.fetchLevelTestQuestions.rejected.toString()]: state => {
      state.activity = null;
    },
    [levelTestThunks.postLevelTestResult.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.activity = payload?.levelTestQuestionsByActivityResponse;
      state.isLevelTestLoading = false;
    },
    [levelTestThunks.postLevelTestResult.pending.toString()]: (
      state,
      { payload }: PayloadAction<UserLevelTestResultResponse>
    ) => {
      state.isLevelTestLoading = true;
    },
  },
});

export const levelTestActions = levelTest.actions;

export const levelTestSelector = (state: RootState) => state.levelTest;

export default levelTest.reducer;
