import React from 'react';
import classNames from './DictionarySearch.module.scss';
import { useTranslation } from 'react-i18next';
import classes from 'classnames';

import TranslationsSearch from '@/components/Dictionary/TranslationsSearch/TranslationsSearch';
import SVG from 'react-inlinesvg';
import dictionaryBg from '@/assets/images/logo_light.png';

import britanicaEnglish from '@/assets/svg/britannica_english.svg';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
interface DictionarySearchProps {
  className?: string;
  showHeading?: boolean;
  showMagnifyingGlass?: boolean;
  shouldExpand?: boolean;
  value: string;
  emitChange: (value: string) => void;
  emitKeyPress?: (value: string) => void;
  emitClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  emitClear?: () => void;
  inGame: boolean;
}

const DictionarySearch = ({
  className,
  showHeading = true,
  showMagnifyingGlass,
  shouldExpand = false,
  value,
  emitChange,
  emitKeyPress,
  emitClear,
  emitClick,
  inGame,
}: DictionarySearchProps) => {
  const { t } = useTranslation();
  const { nativeLanguage } = useAppSelector(authSelector);
  const showMorfixLogo = nativeLanguage === 'he';
  return (
    <div className={classes(classNames.dictionarySearch, className)}>
      {!inGame && (
        <>
          <div className={classNames.dictionaryBg}>
            <img src={dictionaryBg} />
          </div>
          <div className={classNames.dictionaryBgSmall}>
            <img src={dictionaryBg} />
          </div>
        </>
      )}
      {showHeading && (
        <div className={classNames.heading}>
          {showMorfixLogo ? (
            <>
              <p className={classNames.words}>{t('wordsDictionary')}</p>
              <p>{t('powerFulWords')}</p>
            </>
          ) : (
            <SVG src={britanicaEnglish} />
          )}
        </div>
      )}
      <TranslationsSearch
        className={classNames.translationsSearch}
        value={value}
        showMagnifyingGlass={showMagnifyingGlass}
        // shouldExpand={shouldExpand}
        emitChange={emitChange}
        emitKeyPress={emitKeyPress}
        emitClear={emitClear}
        emitClick={emitClick}
      />
    </div>
  );
};

export default DictionarySearch;
