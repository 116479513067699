import React from 'react';
import classNames from './QuizQuestion.module.scss';
import classes from 'classnames';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import {
  GameTypes,
  MultiChoiceProps,
  ClosedSpellingProps,
  PracticeProps,
  PronunciationProps,
  NewGameTypes,
} from '@/types/game';
import { Question } from '@/types/question';

import GameType from '@/components/GameType/GameType';
import QuizContent from '../QuizContent/QuizContent';
import { SkillTypes } from '@/types/skill';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useTranslation } from 'react-i18next';
import { levelTestSelector } from '@/store/reducers/level-test';

interface QuizQuestionProps {
  currentQuestion: Question | null;
  loading: boolean;
  allowRetry: boolean;
  emitOnAnswer: (option: string) => void;
  emitOnRetry: () => void;
  emitOnNextQuestion: () => void;
  emitOnSwipe: (questionId: number) => void;
  emitOnAknowledge?: (id: number, isKnown: boolean) => void;
  setCurrentQuestionTempData: (data: any, key?: string) => void;
  currentQuestionTempData: any;
}

const QuizQuestion = ({
  currentQuestion,
  loading,
  allowRetry,
  emitOnAnswer,
  emitOnRetry,
  emitOnNextQuestion,
  emitOnSwipe,
  emitOnAknowledge,
  setCurrentQuestionTempData,
  currentQuestionTempData,
}: QuizQuestionProps) => {
  const { t } = useTranslation();
  const {
    selectedType,
    pickedAnswer,
    gameType: gameTypeReal,
    showAnswerInOptions,
    practiceCards,
    possibleAnswers: options,
    isLevelTest,
    isInEndOfSkillTest,
    isFinalAssessment,
    gameQuestions,
  } = useAppSelector(gameSelector);

  const gameType = isLevelTest ? 4 : (gameQuestions[0]?.gameType as any);

  const isListen = selectedType === SkillTypes.Listen;
  const isPractice = gameTypeReal === GameTypes.Practice;

  const { isMobile, isDesktop } = UseScreenSize();

  const getGameProps = ():
    | {}
    | MultiChoiceProps
    | ClosedSpellingProps
    | PronunciationProps
    | PracticeProps => {
    if (
      gameType === NewGameTypes.ImageToText ||
      gameType === NewGameTypes.TextToText ||
      gameType === NewGameTypes.AudioToImage ||
      gameType === NewGameTypes.TextToImage ||
      gameType === NewGameTypes.Grammar ||
      gameType === NewGameTypes.Listen ||
      (gameType === NewGameTypes.NaturalConversation &&
        currentQuestion?.newData?.data?.metadata?.skillId === NewGameTypes.Listen) ||
      (gameType === NewGameTypes.Vocabulary && gameTypeReal !== GameTypes.Practice)
    ) {
      return {
        options,
        pickedAnswer,
        showAnswerInOptions,
        emitOnRetry,
        emitOnNextQuestion,
      } as MultiChoiceProps;
    } else if (gameType === GameTypes.ClosedSpelling) {
      return {
        options,
        allowRetry,
        pickedAnswer,
        emitOnRetry,
        emitOnNextQuestion,
      } as ClosedSpellingProps;
    } else if (
      gameType === GameTypes.Pronunciation ||
      (gameType === NewGameTypes.NaturalConversation &&
        currentQuestion?.newData?.data?.metadata?.skillId === GameTypes.Pronunciation)
    ) {
      return {
        pickedAnswer,
        allowRetry,
        emitOnRetry,
        emitOnNextQuestion,
      } as PronunciationProps;
    } else if (gameTypeReal === GameTypes.Practice) {
      return {
        practiceCards,
        emitOnNextQuestion,
        emitOnSwipe,
        emitOnAknowledge,
      } as PracticeProps;
    }
    return {};
  };

  return (
    <QuizContent
      className={classes(classNames.quizQuestion, {
        [classNames.quizQuestionListen]: isListen,
        [classNames.quizPractice]: isPractice,
        [classNames.quizLevelTest]: isLevelTest,
        [classNames.quizMobile]: isMobile,
      })}
      shouldDisplayImageIndicator={!isInEndOfSkillTest || (isMobile && isInEndOfSkillTest)}
      shouldDisplayStars={!isPractice}
    >
      {isFinalAssessment && isDesktop && (
        <span className={classNames.finalEnglishLevelTestText}>{t('finalEnglishLevelTest')}</span>
      )}
      {isLevelTest && (
        <GameType
          type={currentQuestion?.gameTypeId as number}
          {...getGameProps()}
          currentQuestion={currentQuestion?.data?.questionData}
          correctAnswer={currentQuestion?.data?.questionData?.answer?.text}
          isLevelTest={true}
          emitOnAnswer={emitOnAnswer}
          currentQuestionTempData={currentQuestionTempData}
          setCurrentQuestionTempData={setCurrentQuestionTempData}
        />
      )}
      {currentQuestion && !isLevelTest && (
        <GameType
          //Need Only for "Practice"
          type={gameTypeReal as any}
          {...getGameProps()}
          currentQuestion={currentQuestion}
          correctAnswer={currentQuestion?.answer}
          emitOnAnswer={emitOnAnswer}
          currentQuestionTempData={currentQuestionTempData}
          setCurrentQuestionTempData={setCurrentQuestionTempData}
        />
      )}
    </QuizContent>
  );
};

export default React.memo(QuizQuestion);
