import { RootState } from '@/types/common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { progressService, skillService } from '@/services';
import { appSettingsActions } from '../reducers/settings';

const quizzesThunks = {
  refreshTopicQuizzes: createAsyncThunk(
    'refreshTopicQuizzes',
    async (topicId: number | undefined, { getState, dispatch }) => {
      try {
        const state = getState() as RootState;
        const { selectedType, selectedTopic } = state.game;
        const { selectedUnit } = state.units;

        const user = state.auth.user;
        const isTeacher = state.auth.user?.metadata.role === 'teacher';

        const savedLevel = localStorage.getItem('selectedLevel');
        const level =
          isTeacher && savedLevel ? parseInt(savedLevel, 10) : user?.progress?.userLevel || 1;

        if (!user || !selectedTopic || !selectedType) {
          return;
        }

        //FIXME: NEED to remove selectedType === SkillTypes.Listen!!! Solution for DEMO
        const quizzesResponse = await skillService.fetchGameTopic(
          user,
          level,
          selectedUnit.id || 1,
          topicId ?? selectedTopic.id
        );
        // selectedType === SkillTypes.Listen
        //   ? await skillService.fetchGameTopic()
        //   : await skillService.fetchQuizzes(selectedType, user, topicId ?? selectedTopic.id);

        //FIXME: NEED to remove selectedType === SkillTypes.Listen!!! Solution for DEMO
        const quizzes = quizzesResponse?.data?.data;
        // (selectedType === SkillTypes.Listen
        //   ? quizzesResponse?.data?.data
        //   : quizzesResponse?.data) ?? [];

        if (!isTeacher) {
          const progress =
            (
              await progressService.fetchQuizProgress(
                selectedType,
                user,
                topicId ?? selectedTopic.id
              )
            )?.data.data ?? ({} as any);

          for (const quiz of quizzes) {
            const quizProgress = progress.topic.activitiesOverviewPerTopic[
              topicId ?? selectedTopic.id
            ]
              ? progress.topic.activitiesOverviewPerTopic[topicId ?? selectedTopic.id][quiz.id]
              : {};

            if (quizProgress) {
              quiz.userData = quizProgress;
            }
          }

          dispatch(
            appSettingsActions.appendQuizzesWithData({
              topicId: topicId ?? selectedTopic.id,
              quizzes: quizzes,
            })
          );
        }

        return quizzes;
      } catch (error) {
        console.log(error);

        throw error;
      }
    }
  ),
};

export default quizzesThunks;
