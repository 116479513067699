import { useTranslation } from 'react-i18next';
import classNames from '../ProgressSection.module.scss';
import SVG from 'react-inlinesvg';
import { ProgressIcon } from './ProgressIcon';
import star from '@/assets/svg/star.svg';

type ProgressItemProps = {
  title: string;
  completedValue?: number;
  totalValue?: number;
  icon?: string;
  level?: number;
};

export const ProgressItem = ({
  icon,
  title,
  completedValue,
  totalValue,
  level,
}: ProgressItemProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames.stat}>
      {level !== undefined ? (
        <ProgressIcon level={level} />
      ) : (
        <SVG className={classNames.badgeMedium} src={icon || star} />
      )}

      <div className={classNames.statsTitle}>{t(title)}</div>
      <div className={classNames.statsNumbers}>
        {level ? level : `${completedValue}/${totalValue}`}
      </div>
    </div>
  );
};
