import classNames from './TopCard.module.scss';
import SVG from 'react-inlinesvg';

interface TopCardProps {
  items: {
    icon: string;
    label: string;
    value: string;
    color?: string;
  }[];
}

const TopCard = ({ items }: TopCardProps) => {
  return (
    <div className={classNames.topCard}>
      {items.map((item, index) => {
        return (
          <div key={`topCardItem--${index}`} className={classNames.topCardItem}>
            <SVG src={item.icon} />
            <h2 style={{ color: item.color }}>
              <span className={classNames.text}>{item.value}</span>
            </h2>
            <h5>
              <span className={classNames.text}>{item.label}</span>
            </h5>
          </div>
        );
      })}
    </div>
  );
};

export default TopCard;
