import SVG from 'react-inlinesvg';
import classNames from './GenerateReport.module.scss';
import downLoadSvg from '@/assets/svg/download.svg';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { GenerateReportProps, ReportTypes } from '@/types/generate-report';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ClassReport from './ClassReport';
import StudentReport from './StudentReport';
import AppModal from '@/components/AppModal';
import AppLoaderCircle from '@/components/AppLoaderCircle';

const GenerateReport = ({
  reportType,
  currentClassroom,
  currentStudent,
  children,
}: GenerateReportProps & { children?: any }) => {
  const { t } = useTranslation();

  const [loadingReport, setLoadingReport] = useState(false);
  const [isErrorDownloading, setIsErrorDownloading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);

  const isStudentReport = reportType === ReportTypes.student && currentStudent;
  const isClassReport = reportType === ReportTypes.class && currentClassroom;

  const wait = (time: number) => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(1);
      }, time);
    });
  };

  const generateReportPdf = async (element: HTMLElement | null) => {
    if (!element) return;

    element.style.display = 'flex';
    element.dataset.reportrendering = '1';

    // wait one second for the icons to be drawn
    await wait(1000);

    html2canvas(element, { scale: 5 })
      .then(canvas => {
        element.style.display = 'none';
        element.dataset.reportrendering = '0';

        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', [297, 210]);

        pdf.setFillColor(251, 251, 251);
        pdf.rect(0, 0, 210, 297, 'F');

        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        const hRatio = imgHeight / imgWidth;

        const width = pdf.internal.pageSize.getWidth();
        const height = width * hRatio;

        pdf.addImage(imgData, 'PNG', width * 0, 0, width * 1, height * 0.95);

        pdf.save(isStudentReport ? `${t('studentReport')}.pdf` : `${t('classReport')}.pdf`);
        setLoadingReport(false);
      })
      .catch(() => {
        setIsErrorDownloading(true);
        setLoadingReport(false);
      });
  };

  const onLoadReportError = () => {
    setIsServerError(true);
    setLoadingReport(false);
  };

  const closeErrorModal = () => {
    setIsServerError(false);
    setIsErrorDownloading(false);
    setLoadingReport(false);
  };

  return (
    <>
      {loadingReport && (
        <AppModal>
          <div className={classNames.appModal}>
            <AppLoaderCircle />
          </div>
        </AppModal>
      )}

      {(isErrorDownloading || isServerError) && (
        <AppModal>
          <div className={classNames.errorModal}>
            <div className={classNames.card}>
              <h1>{t('weCouldNotProduce')}</h1>
              <div>
                <h2>{t('weStillDontHaveAll')}</h2>
                <h3>{t('tryAgainLater')}</h3>
              </div>
              <button onClick={closeErrorModal}>{t('done')}</button>
            </div>
          </div>
        </AppModal>
      )}

      <button
        className={classNames.button}
        onClick={() => {
          setLoadingReport(true);
        }}
      >
        {children || (
          <>
            <SVG src={downLoadSvg} />
            <h2>{isStudentReport ? t('generateReport') : t('generateClassReport')}</h2>
          </>
        )}
      </button>

      {loadingReport && isStudentReport && (
        <StudentReport
          currentStudent={currentStudent}
          generateReportPdf={generateReportPdf}
          onLoadReportError={onLoadReportError}
        />
      )}

      {loadingReport && isClassReport && (
        <ClassReport
          currentClassroom={currentClassroom}
          generateReportPdf={generateReportPdf}
          onLoadReportError={onLoadReportError}
        />
      )}
    </>
  );
};

export default GenerateReport;
