import classNames from './UserData.module.scss';
import classes from 'classnames';
import commonUtils from '@/utils/common';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useCallback, useMemo, useRef, useState } from 'react';
import { SkillTypes } from '@/types/skill';
import { UserState } from '@/types/user';
import UseLevel from '@/hooks/UseLevel';
import UseSkills from '@/hooks/UseSkills';
import { StudentProgressBySkill, StudentsSkillsProgress } from '@/types/progress';
import UseThemeColors from '@/hooks/UseThemeColors';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import UserAvatar from '@/components/UserAvatar/UserAvatar';
import DetailSeperator from '@/components/DetailSeperator/DetailSeperator';
import DataContainer from './DataContainer/DataContainer';
import EditContainer from './EditContainer/EditContainer';
import DeleteStudent from './DeleteStudent/DeleteStudent';
import RevertUser, { RevertsData } from './RevertUser/RevertUser';
import UnitData from './UnitData/UnitData';
import GoBackBtn from '@/components/GoBackBtn/GoBackBtn';

import pencil from '@/assets/svg/pencil.svg';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import Assessments from './Assessments/Assessments';
//TODO: Need to understand if it's needed
// import downLoadSvg from '../../../assets/svg/download.svg';
import { StudentProgress } from '@/types/progress';
import GenerateReport from './GenerateReport/GenerateReport';
import { ReportTypes } from '../../../types/generate-report';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';

interface UserDataProps {
  isLoadingProgress?: boolean;
  showEdit?: boolean;
  showUnitData?: boolean;
  selectedStudent?: UserState | null;
  showAssessments?: boolean;
  selectedUnit?: {
    value: SkillTypes;
    progress: StudentProgressBySkill;
  } | null;
  emitShowUnitData?: (unitId: number) => void;
  emitHideUnitData?: () => void;
  emitShowAssessments: () => void;
  emitHideAssessment: () => void;
  emitShowEdit?: () => void;
  emitSaveEdit?: (firstName: string, lastName: string, language: string) => Promise<void>;
  emitCloseEdit?: () => void;
  // level?: number;
  firstName?: string;
  lastName?: string;
  // grade?: string,
  className?: string;
  // interfaceLanguage?: string,
  // nativeLanguage?: string,
  // streak?: string,
  // skillsOverview?: string,
  // studentProgress?: StudentProgress,
  // organizationName?: string,
  // currentLevelByskill?: string
}

const UserData = ({
  isLoadingProgress,
  level,
  firstName,
  lastName,
  email,
  grade,
  className,
  interfaceLanguage,
  nativeLanguage,
  streak,
  unitsProgress,
  studentProgress,
  showUnitData,
  selectedUnit,
  selectedStudent,
  showAssessments,
  organizationName,
  showEdit,
  currentLevelByskill,
  emitShowUnitData,
  emitHideUnitData,
  emitShowAssessments,
  emitHideAssessment,
  emitShowEdit,
  emitCloseEdit,
  emitSaveEdit,
  emitStudentDelete,
  isStudentDeleting,
  showStudentDeleteError,
  setShowStudentDeleteError,
  userStatus,
}: any) => {
  const { t } = useTranslation();
  const [revertsData, setRevertsData] = useState<RevertsData | null>(null);
  const [showDeleteStudent, setShowDeleteStudent] = useState<boolean>(false);
  const { color } = UseThemeColors();
  const { getSkillName } = UseSkills();
  const { getLevelText } = UseLevel();
  const appInterface = useAppSelector(interfaceLanguageSelector);
  console.log(appInterface?.direction);
  const levelText = getLevelText(level);

  const percentage = 0;

  const isUniversityGrade = grade.toLowerCase() === 'university';
  const isNoneGrade = grade.toLowerCase() === 'none';

  let gradeValue = null;

  if (!isNoneGrade) {
    if (isUniversityGrade) {
      gradeValue = t('university');
    } else {
      gradeValue = isNaN(+grade) || Number.isInteger(+grade) ? t(`grade_${grade}`) : grade;
    }
  }

  const openRevertsDataPopup = useCallback((revertsData: RevertsData) => {
    setRevertsData(revertsData);
  }, []);

  const closeRevertsDataPopup = useCallback(() => {
    setRevertsData(null);
  }, []);

  const openDeleteStudentModal = useCallback(() => {
    setShowStudentDeleteError(false);
    setShowDeleteStudent(true);
  }, [setShowStudentDeleteError]);

  const closeDeleteStudentModal = useCallback(() => {
    setShowDeleteStudent(false);
  }, []);

  const top = showUnitData ? (
    <GoBackBtn className={classNames.goBackBtn} emitClick={() => emitHideUnitData?.()} />
  ) : false ? ( //todo need to return this to showAssessments instead of false
    <>
      <GoBackBtn emitClick={emitHideAssessment} />
    </>
  ) : (
    <>
      {level && !showEdit && (
        <div className={classNames.reportBtn}>
          <GenerateReport currentStudent={selectedStudent} reportType={ReportTypes.student} />
        </div>
      )}
      <div className={classNames.profile}>
        <UserAvatar
          className={classNames.avatar}
          userFirstName={firstName ?? ''}
          allowEdit={false}
        />
        <h2>{`${firstName} ${lastName}`}</h2>
        <div className={classNames.details}>
          {grade && <span>{`${gradeValue} ${t('grade')}`}</span>}
          {className ? (
            <>
              <DetailSeperator className={classNames.seperator} />
              <span>{className}</span>
            </>
          ) : null}
        </div>
      </div>
      {!showEdit && interfaceLanguage && nativeLanguage && (
        <button
          className={classes(classNames.edit, {
            [classNames.editActive]: showEdit,
            [classNames.editRtl]: appInterface?.direction === 'rtl',
          })}
          onClick={emitShowEdit}
        >
          <SVG src={pencil} />
        </button>
      )}
    </>
  );

  const body = showEdit ? (
    <EditContainer
      interfaceLanguage={interfaceLanguage}
      nativeLanguage={nativeLanguage}
      // currentLevelByskill={currentLevelByskill}
      firstName={firstName}
      lastName={lastName}
      email={email}
      emitSaveEdit={emitSaveEdit}
      emitCloseEdit={emitCloseEdit}
      isLoadingProgress={isLoadingProgress}
      openRevertsDataPopup={openRevertsDataPopup}
      emitDeleteStudent={openDeleteStudentModal}
    />
  ) : showUnitData && selectedUnit ? (
    <UnitData
      unitId={selectedUnit.unitId}
      studentId={selectedStudent.id}
      unitName={selectedUnit.unitName}
      completionPercentage={selectedUnit.progress}
      studentName={`${firstName} ${lastName}`}
      level={level}
      completedTopics={selectedUnit.completedTopics}
      totalTopics={selectedUnit.totalTopics}
      completedActivities={selectedUnit.completedActivities}
      totalActivities={selectedUnit.totalActivites}
      achievedStars={selectedUnit.achievedStars}
      totalStars={selectedUnit.totalStars}
    />
  ) : false ? ( //todo need to return this to showAssessments instead of false
    <Assessments student={selectedStudent} />
  ) : studentProgress ? (
    <DataContainer
      schoolName={organizationName ?? t('notAvailable')}
      level={level?.toString() ?? ''}
      levelText={levelText ?? ''}
      language={t(interfaceLanguage) ?? t('notAvailable')}
      progress={studentProgress}
      streak={streak}
      unitsProgress={unitsProgress}
      emitShowUnitData={emitShowUnitData}
      emitShowAssessments={emitShowAssessments}
      selectedStudent={selectedStudent}
      userStatus={userStatus}
    />
  ) : null;

  const content =
    isLoadingProgress && !showEdit && !revertsData ? (
      <div className='h-full w-full flex items-center justify-center'>
        <AppLoaderCircle />
      </div>
    ) : (
      <>
        <div className={classNames.top}>{top}</div>
        <div className={classNames.body}>{body}</div>
        {revertsData && (
          <RevertUser
            revertsData={revertsData}
            onClose={closeRevertsDataPopup}
            onReverts={emitSaveEdit}
          />
        )}
      </>
    );

  return (
    <>
      <div className={classNames.userData}>{content}</div>
      <DeleteStudent
        show={showDeleteStudent}
        onClose={closeDeleteStudentModal}
        onDelete={emitStudentDelete}
        isStudentDeleting={isStudentDeleting}
        showStudentDeleteError={showStudentDeleteError}
        setShowStudentDeleteError={setShowStudentDeleteError}
      />
    </>
  );
};

export default UserData;
