import { ContactUsValues } from '@/types/contact-us';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useAppSelector } from '@/store';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';
import { useDrawer } from '@/context/DrawerContext';
import { userService } from '@/services';

const UseContactUs = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector(authSelector);
  const drawer = useDrawer();

  const [isLoading, setIsLoading] = useState(false);
  const [showMessageSent, setShowMessageSent] = useState(false);
  const [resetForm, setResetForm] = useState(false);

  const InitialContactUsValues = {
    subject: '',
    email: '',
    phone: '',
    message: '',
    shouldReceiveUpdates: false,
  };

  const ContactUsSchema = Yup.object().shape({
    subject: Yup.string().required(t('fieldIsMandatory').toString()),
    email: Yup.string().email().required(t('fieldIsMandatory').toString()),
    phone: Yup.string().matches(/^[0-9]+$/, t('onlyNumbersAllowed').toString()),
    message: Yup.string().required(t('fieldIsMandatory').toString()),
    shouldReceiveUpdates: Yup.boolean(),
  });

  const OnContactUsSubmitted = async (values: ContactUsValues) => {
    setIsLoading(true);

    const { subject, email, phone, message, shouldReceiveUpdates } = values;

    try {
      const info = {
        userId: user?.metadata.id,
        subject,
        email,
        phone,
        description: message,
        cfMarketingAgree: shouldReceiveUpdates,
      };

      const response = (await userService.sendContactInfo(user, info))?.data.data;

      if (response) {
        document.body.style.overflowY = 'hidden';
        window.scrollTo({
          top: 0,
        });
        setShowMessageSent(true);
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const onCloseMessageSent = () => {
    setShowMessageSent(false);
    drawer.closeContactUsDrawer();
    setResetForm(true);
    document.body.style.overflowY = '';
  };

  return {
    isLoading,
    InitialContactUsValues,
    ContactUsSchema,
    showMessageSent,
    resetForm,
    onCloseMessageSent,
    OnContactUsSubmitted,
  };
};

export default UseContactUs;
