import React from 'react';
import classNames from './NoClasses.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';

import hat from '@/assets/svg/hat-unfill.svg';
import { useTranslation } from 'react-i18next';
import TheButton from '../TheButton/TheButton';
import AppLoaderCircle from '../AppLoaderCircle';

interface NoClassesProps {
  className?: string;
  loading?: boolean;
  emitCreateClass: () => void;
  setIsClassEdit?: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoClasses = ({ className, emitCreateClass, loading, setIsClassEdit }: NoClassesProps) => {
  const { t } = useTranslation();

  const createClassText = t('createClass');

  const onCreateClass = () => {
    emitCreateClass();
    if (setIsClassEdit) {
      setIsClassEdit(true);
    }
  };

  return (
    <div className={classes(classNames.noClasses, className)}>
      {loading ? (
        <>
          <AppLoaderCircle className='m-auto' />
        </>
      ) : (
        <>
          <SVG className={classNames.hat} src={hat} />
          <span className={classNames.createClassText}>{createClassText}</span>
          <h2>{`${t('createYourFirstClass')} ${t('andInviteYourStudents')}!`}</h2>
          <div className={classNames.description}>{t('createYourFirstClassDescription')}</div>
          <TheButton
            className={classNames.createClassBtn}
            text={createClassText}
            shouldRecolor={false}
            emitOnClick={onCreateClass}
          />
        </>
      )}
    </div>
  );
};

export default NoClasses;
