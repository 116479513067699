import config from '@/config';
import {
  AdminOrganizationDetails,
  CreateGroupDetails,
  CreateUserDetails,
  CreateStudentData,
  RegistrationQueryData,
} from '@/types/register';
import {
  CreateOrganizationResponse,
  CreateTeacherResponse,
  OrganizationDetailsResponse,
  RegisteredTeachersResponse,
  ValidateAdminCodeResponse,
  CreateGroupResponse,
  CreateAdminResponse,
  CreateCsvStudentsResponse,
  GroupStudentsResponse,
} from '@/types/response';
import { UserState } from '@/types/user';
import axiosInstance from '@/utils/api-new';

// BASE REGISTRATION ENDPOINT //
const REGISTRATION_ENDPOINT = `${config.apiVersion}`;

const getRegistrationQueryData = (): RegistrationQueryData => {
  const registrationDataJson = localStorage.getItem('register-registration_data');
  const registrationData: RegistrationQueryData = registrationDataJson
    ? JSON.parse(registrationDataJson)
    : null;
  return registrationData;
};

export const registerService = {
  /* GET ORGANIZATION DETAILS FOR TEACHER - FIRST CALL ON FIRST STEP OF TEACHER FLOW */
  getOrganizationDetails: async (user?: UserState | null) => {
    const { dealId, organizationId }: RegistrationQueryData = user
      ? {
          dealId: user.metadata?.organizationDetails?.dealId || '',
          organizationId: user.metadata?.organizationDetails?.organizationId || '',
        }
      : getRegistrationQueryData();

    const resp: OrganizationDetailsResponse | null = await axiosInstance.get(
      `${REGISTRATION_ENDPOINT}/admin/organization/${dealId}/${organizationId}`
    );

    return resp;
  },
  /* VALIDATE THE ADMIN CODE IN FIRST STEP */
  validateAdminCode: async (code: string) => {
    const { dealId }: RegistrationQueryData = getRegistrationQueryData();
    const resp: ValidateAdminCodeResponse | null = await axiosInstance.post(
      `${REGISTRATION_ENDPOINT}/admin/validate-code`,
      {
        verificationCode: code,
        dealId: dealId,
      }
    );

    return resp;
  },
  /* GET REGISTERED TEACHERS UNDER ADMIN ACCOUNT */
  getRegisteredTeachers: async (user: UserState | null) => {
    const resp: RegisteredTeachersResponse | null = await axiosInstance.get(
      `${REGISTRATION_ENDPOINT}/admin/registered-teachers/${user?.metadata.id}`
    );

    return resp;
  },
  /* 
    CREATE THE ADMIN USER
  */
  createAdmin: async (adminUser: CreateUserDetails) => {
    const { dealId }: RegistrationQueryData = getRegistrationQueryData();
    const resp: CreateAdminResponse | null = await axiosInstance.post(
      `${REGISTRATION_ENDPOINT}/admin/create-admin/${adminUser.userId}/${dealId}`,
      {
        firstName: adminUser.firstName,
        lastName: adminUser.lastName,
        email: adminUser.email,
      }
    );

    return resp;
  },
  /* 
    CREATE ADMIN'S ORGANIZATION
  */
  createOrganization: async (userId: string, organizationDetails: AdminOrganizationDetails) => {
    const { dealId }: RegistrationQueryData = getRegistrationQueryData();
    const resp: CreateOrganizationResponse | null = await axiosInstance.post(
      `${REGISTRATION_ENDPOINT}/admin/create-organization`,
      {
        dealId: dealId,
        adminId: userId,
        organizationName: organizationDetails.organizationName,
        state: organizationDetails.state,
        cityOrDistrict: organizationDetails.cityOrDistrict,
        country: organizationDetails.country,
      }
    );

    return resp;
  },
  /* 
    CREATE A CLASS + GRADE (GROUP)
  */
  createGroup: async (user: UserState | null, groupDetails: CreateGroupDetails) => {
    const resp: CreateGroupResponse | null = await axiosInstance.post(
      `${REGISTRATION_ENDPOINT}/teacher/create-group`,
      {
        organizationId: user?.metadata?.organizationDetails?.organizationId || '',
        teacherId: user?.metadata.id,
        groupName: groupDetails.groupName,
        grade: groupDetails.grade,
        allowIndividualAdvancement: groupDetails.allowIndividualAdvancement,
      }
    );

    return resp;
  },

  getGroupStudents: async (teacherId: string, groupId: string) => {
    const resp: GroupStudentsResponse | null = await axiosInstance.get(
      `${REGISTRATION_ENDPOINT}/teacher/students/${teacherId}/${groupId}`
    );

    return resp;
  },
  /* 
    CREATE THE TEACHER USER
    CALL THIS WHEN YOU FINISHED CALLING COGNITO CREATE ACCOUNT
    YOU MUST SEND THE COGNITO USER ID HERE
  */
  createTeacher: async (userDetails: CreateUserDetails) => {
    const { organizationId }: RegistrationQueryData = getRegistrationQueryData();

    const resp: CreateTeacherResponse | null = await axiosInstance.post(
      `${REGISTRATION_ENDPOINT}/admin/create-teacher/${userDetails.userId}`,
      {
        organizationId: organizationId,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
      }
    );

    return resp;
  },

  /* 
    CREATE STUDENTS FROM CSV FILE
  */
  createCsvStudents: async (
    user: UserState | null,
    groupId: string | undefined,
    students: CreateStudentData[]
  ) => {
    const resp: CreateCsvStudentsResponse | null = await axiosInstance.post(
      `${REGISTRATION_ENDPOINT}/teacher/create-students-csv/${user?.metadata.id}`,
      {
        organizationId: user?.metadata.organizationDetails.organizationId,
        groupId,
        students,
        dealId: user?.metadata?.organizationDetails?.dealId,
      }
    );

    return resp;
  },
};
