import classNames from './UserStreak.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { Streak } from '@/types/user';

import SectionHeader from '../SectionHeader/SectionHeader';

import checked from '@/assets/images/checked.png';
import unchecked from '@/assets/images/unchecked.png';
import { useMemo } from 'react';

interface UserStreakProps {
  className?: string;
  showTitle?: boolean;
  streak: Streak[];
  title?: string;
}

const UserStreak = ({ className, showTitle = true, streak, title }: UserStreakProps) => {
  const { t } = useTranslation();

  const modifiedStreak = useMemo(() => {
    return [...streak].sort((a, b) => a.order - b.order);
  }, [streak]);

  return (
    <div className={classes(classNames.userStreak, className)}>
      {showTitle && title && (
        <SectionHeader title={title} subTitle={t('userStreak.subTitle') ?? ''} />
      )}
      <div className={classNames.week}>
        {modifiedStreak.map((streak, index) => (
          <div key={index} className={classNames.day}>
            <img src={streak.eventStatus ? checked : unchecked} alt='day-check' />
            <span>{t(`${streak.day.toLowerCase()}Short`)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserStreak;
