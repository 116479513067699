export const TRANSLATIONS_PT = {
  CodeMismatchException: 'Código inválido',
  CommunicationProblemModalDescription:
    'Nossos servidores não estão respondendo. Por favor, tente novamente.<br /> Se o problema persistir, entre em contato conosco pelo e-mail info@eb.com',
  CommunicationProblemModalTitle: 'Ops! Tivemos um problema técnico.',
  ExpiredCodeException: 'Código inválido',
  IAlreadyHaveAnAccount: 'Eu já tenho uma conta',
  LimitExceededException: 'Limite de tentativas excedido. Tente novamente mais tarde.',
  UserNotConfirmedException:
    'Sua conta não foi verificada. Digite o código de verificação enviado para o seu e-mail.',
  accountVerifiedCanLoginNow: 'Sua conta foi verificada, você pode fazer login agora',
  account_settings: 'Configurações da conta',
  achievmentsSection: {
    feedback: 'Você mostrou algumas habilidades extraordinárias!',
    greatJob: 'Ótimo trabalho!',
    in: 'Em',
    weeklyAchievement: 'Conquista da semana',
  },
  activities: 'Atividades',
  addClass: 'Criar turma',
  addNewClass: 'Adicionar nova turma',
  advanceToTheNextLevel: 'avançar para o próximo nível.',
  advanced: 'Avançado',
  again: 'novamente',
  all: 'Todos',
  allDone: 'Concluído!',
  allLevels: 'Todos os níveis',
  allowIndividualAdvancement: 'Permitir avanço para o próximo nível',
  almostFinish: 'Quase lá!',
  almostHalfWayThere: 'Você está quase na metade do caminho!',
  almostMadeIt: 'Quase conseguiu',
  almostThere: 'Você está quase lá...',
  almost_made_it: 'Quase conseguiu',
  amazingJob: 'Ótimo trabalho!',
  amazing_job: 'Ótimo trabalho!',
  anErrorOccurred: 'Ocorreu um erro',
  anUnauthorizedRequestErrorOccurred: 'Ocorreu um erro de solicitação não autorizado.',
  andBeginYour: 'e comece sua jornada de aprendizagem de inglês.',
  andInviteYourStudents: 'e convide seus alunos',
  andThenInvite: 'e depois convide seus alunos para participarem dela',
  andYouCanImproveYouScores: 'e melhorar suas pontuações.',
  and_got_all_the_stars: 'e recebeu todas as estrelas',
  answerNotAccepted: 'Resposta não aceita',
  answeredIncorrectly: 'respondeu incorretamente.',
  appName: 'Hello Britannica',
  appTitle: 'Britannica',
  app_banner: 'Banner de aplicativo',
  ar: 'árabe',
  areYouReadyForTheQuiz: 'Acha que está preparado(a) para o jogo?',
  areYouSure: 'Tem certeza?',
  asSoonAsTheyStart:
    'Assim que eles começarem a jornada de aprendizado, você poderá ver o progresso deles aqui.',
  assessment: 'Avaliação',
  assessments: 'Avaliações',
  assignments: 'Atividades',
  atLeastOneNumber: 'Contém pelo menos 1 número',
  atLeastOneSpecial: 'Contém pelo menos 1 caractere especial',
  atLeastOneUppercase: 'Contém pelo menos 1 letra maiúscula',
  averageStudentLevel: 'Nível médio do aluno',
  avgAttempts: 'Média de tentativas',
  avgClassScores: 'Média de pontuações da turma',
  avgFailureRate: 'Índice geral de reprovação',
  avgGameScores: 'Média de pontuações nos jogos',
  avgLevel: 'Nível médio',
  avgLevel_mobile: 'Nível médio',
  avgPassRate: 'Índice geral de aprovação',
  avgProgress: 'Progresso médio',
  avgProgress_mobile: 'Progresso médio',
  avgQuizzes: 'Média de quizzes concluídos por aluno',
  avgScore: 'Pontuação média',
  avgScore_mobile: 'Pontuação média',
  avgTimeSpent: 'Média de tempo gasto',
  back: 'Voltar',
  backHome: 'Voltar à página inicial',
  backToHomePage: 'Voltar à página inicial',
  backToTopics: 'Voltar aos tópicos',
  back_home: 'Voltar à página inicial',
  badgesSection: {
    link: 'Ver tudo',
    title: 'Minhas medalhas recentes',
  },
  britannicaEducationWith: 'Britannica Education com',
  byClickingOn:
    'Ao clicar no nome do aluno, você verá o desempenho dele em cada habilidade e com que frequência utiliza o aplicativo. Você também pode decidir qual idioma de interface ele deve usar.',
  canDo: 'Can Do',
  cancel: 'Cancelar',
  cancelAnyway: 'Cancelar mesmo assim',
  changeLevel: 'Alterar nível',
  change_password: 'Alterar senha',
  characters: 'Caracteres ',
  checkInSpamMessage: 'Não recebeu? Verifique sua <0>caixa de spam</0>.',
  checkInSpamMessageTeacher: 'spanish_only_do_not_touch',
  choose: 'Selecionar',
  chooseAClass: 'Selecionar turma',
  chooseAvatar: 'Selecionar avatar',
  chooseDate: 'Selecionar data',
  chooseGrade: 'Selecionar ano/série',
  chooseTheCorrectAnswer: 'Selecione a resposta correta para completar a frase:',
  chooseYourFavoriteAvatar: 'Selecione seu avatar e cor favoritos',
  city: 'Cidade ou município',
  class: 'Turma',
  classActivity: 'Atividade da turma',
  classAvg: 'Média da turma',
  classCreated: 'A turma foi criada com sucesso!',
  classCreatedDescription:
    'Seus alunos receberão um e-mail com instruções para redefinir suas senhas. Depois dessa etapa, poderão iniciar suas jornadas de aprendizado de inglês.',
  className: 'Nome da turma',
  classReport: 'Relatório da turma',
  classes: 'Turmas',
  clickOnAStudent: 'Clique em um aluno',
  clickToCopy: 'Clique para copiar o link do convite',
  clickToTryAgain: 'Clique para tentar novamente',
  clickToWatchAssessments: 'Clique para ver as avaliações dos alunos!',
  coins: 'Moedas',
  coinsInstructions: 'Você pode usá-las para obter ajuda enquanto joga.',
  coinsWillBeLost: 'e todas as moedas que você ganhou serão perdidas',
  comingSoon: 'Em<br/>breve',
  complete: 'Completo',
  completed: 'Concluído',
  completedActivities: 'Total de atividades concluídas neste nível',
  confirmPassword: 'Confirmar senha',
  contactOurTeam: 'Em caso de dúvidas, entre em',
  contactOurTeamWithQuestions:
    'Entre em contato com nossa equipe em caso de dúvidas ou outros assuntos.',
  contactUs: 'Fale conosco',
  continue: 'Continuar',
  continueCreating: 'Continuar a criar',
  continueOn: 'Continuar',
  continueOnWeb: 'Continuar na web',
  continueQuiz: 'Continuar o jogo',
  continueToQuestions: 'Continuar em perguntas',
  continue_level_test: 'Continuar teste de nivelamento',
  copy: 'Copiar',
  copyInvitationLink: 'Copiar link do convite',
  copyLink: 'Copiar link',
  copyLinkOnly: 'Copiar somente o link',
  country: 'País',
  create: 'Criar',
  createClass: 'Criar turma',
  createNewClass: 'Criar nova turma',
  createYourClass: 'Criar a turma e convidar os alunos',
  createYourFirstClass: 'Criar sua primeira turma',
  createYourFirstClassDescription:
    'Configure a turma e, em seguida, faça upload da lista de alunos que você gostaria de matricular nesta turma. Depois convide os alunos para participar.',
  currentLevel: 'Nível atual',
  current_password: 'Senha atual',
  customYourLearning: 'Em breve, poderemos personalizar sua experiência de aprendizado!',
  dashboard: 'Painel de controle',
  date: 'Data',
  dealLimitReachedMessageDescription:
    'Entre em contato com o gestor escolar para obter orientações. <br/><br/>Código do erro: LR9D2F7H',
  dealLimitReachedMessageTitle: 'O limite de usuários da escola foi atingido.',
  dearSchoolAdmin: 'Caro administrador da escola, o senhor foi',
  dearStudent: 'Caro aluno, você foi convidado por ',
  dearTeacher: 'Caro professor, você foi convidado a participar de',
  dearTeacherLevel: 'Caro professor,',
  dearTeacherPleaseJoin:
    'Caro professor, cadastre-se em {{schoolName}} para poder criar suas turmas e começar a usar a plataforma de aprendizado de inglês da Britannica.',
  deleteClass: 'Excluir turma',
  dictionary: 'Dicionário',
  didYouMean: 'Você quis dizer',
  didntQuiteHearThat: 'Não ouvi bem.',
  discoverAWorld:
    'Descubra um mundo de possibilidades para um aprendizado dinâmico e com ferramentas poderosas para aprimorar suas habilidades em língua inglesa.',
  doingGreat: 'Você está indo muito bem!',
  done: 'Concluído',
  dontKnow: 'Não sei',
  downloadAndView: 'Fazer download e visualizar',
  downloadPDF: 'Fazer download do PDF',
  editClass: 'Editar turma',
  editClassInviteNewStudent: 'Convidar novo aluno',
  editClassInvitedStudentsText:
    'Uma vez que os alunos alterem a senha temporária, eles se tornarão ativos.',
  editClassInvitedStudentsTitle: 'Alunos convidados para essa turma',
  editClassStudentFullClassDescription: 'Sua turma possui o número máximo<br/>de alunos permitido.',
  editClassStudentFullClassTitle: 'Essa turma está lotada.',
  editClassStudentNewStudentAddedDescription:
    'Foi enviado aos alunos um e-mail com instruções sobre como redefinir a senha. Depois de fazer login na plataforma, eles receberão um teste de nivelamento e serão designados para o nível apropriado. A partir daí, poderão iniciar sua jornada de aprendizado.',
  editClassStudentNewStudentAddedTitle: 'foi convidado!',
  editClassStudentStatus: 'Status',
  editClassStudentStatusActive: 'Ativo',
  editClassStudentStatusInactive: 'Inativo',
  email: 'E-mail',
  emailAlreadyExists: 'Já existe uma conta com o e-mail fornecido',
  emailExample: 'exemplo@gmail.com',
  emailIsValidReceiveVerificationCode:
    'Se a conta de e-mail for válida, você receberá um código de verificação para continuar o processo de redefinição de senha.',
  emailVerification: 'Verificação de e-mail',
  empowerEnrichAndInspire: 'capacitar, enriquecer e nos inspirar',
  en: 'Inglês',
  endOfLevelAssessment: 'Teste de conclusão de nível',
  english: 'Inglês',
  englishLearningJourney: 'jornada de aprendizado de inglês com o Hello Britannica.',
  englishLearningJourneyMorfix: 'Jornada de aprendizado de inglês com o Morfixschool 2.0.',
  englishSkillsAssessment: 'Avaliação de habilidades em inglês',
  englishSkillsAssessments: 'Avaliações das habilidades em inglês',
  englishSkillsProgress: 'Progresso das habilidades de inglês',
  enterCodeHere: 'Digite o código aqui',
  enterNewPassword: 'Insira a nova senha',
  enterVerificationCode: 'Digite o código de verificação',
  enterVerificationCodeAndNewPassword: 'Digite o código de verificação e a nova senha',
  enterVerificationCodeHelpText: 'Insira o código fornecido pela instituição de ensino.',
  es: 'Espanhol',
  exampleEmail: 'exemplo@gmail.com',
  exampleSentences: 'Exemplos de frases',
  examples: 'exemplos',
  excellent: 'Excelente',
  exit: 'Sair',
  exploreEngaging:
    'Explore sequências didáticas envolventes e atividades divertidas que tornarão o aprendizado de inglês agradável e eficaz.',
  extra: 'Extra',
  extraCoins: 'Moedas extras',
  failed_quiz_message_again:
    'Você ainda não concluiu esse jogo, portanto não receberá moedas extras nem estrelas.',
  failed_quiz_message_first:
    'Você não foi aprovado nessa atividade, portanto não receberá estrelas.',
  failed_quiz_message_passed:
    'Você não foi aprovado. Não se preocupe, sua pontuação anterior foi salva.',
  failureRate: 'Índice individual de reprovação',
  fewMoreSeconds: 'Mais alguns segundos',
  fieldIsMandatory: 'Este campo é obrigatório',
  filter: 'Filtro',
  finalAssessment: 'Avaliação final',
  finalEnglishLevelTest: 'Teste final de nível de inglês',
  finishActivity: 'Concluir atividade',
  finishGameText:
    'Você concluiu seu curso no Hello Britannica. <br/>Todas as suas atividades permanecerão desbloqueadas para que você possa voltar e praticar',
  finishToLevelUp: 'Termine para subir de nível!',
  finishUnitSuccessText:
    'Você completou a unidade {{completedUnitName}}. Você está pronto para começar {{nextUnitName}}. Lembre-se: você sempre pode retornar a essa unidade para rever seu conteúdo.',
  firstname: 'Nome',
  focus: 'Foco!',
  for: 'para',
  forgotPassword: 'Esqueci a senha',
  foundation: 'Base',
  friday: 'Sexta-feira',
  fridayShort: 'Sex',
  from: 'De',
  fullVersionAvilableOnlyOnDesktop: 'Versão completa disponível apenas no desktop',
  generalLevel: 'Nível geral',
  generateClassReport: 'Gerar relatório de turma',
  generateReport: 'Gerar relatório',
  getApp: 'Baixar o aplicativo',
  getFullExperience: 'Obtenha a experiência completa com o aplicativo Hello Britannica',
  getStarted: 'Iniciar',
  gettingStarted: 'Introdução',
  giveFeedback: 'Fale conosco',
  goAheadAndShareThisLinkWithYourStudentsNow: 'Compartilhe este link com seus alunos agora.',
  goAheadJoinMyClass: 'Olá. Participe da minha turma usando este link',
  goBackToSkills: 'Voltar para habilidades',
  goToLevelTest: 'Ir para o teste de nivelamento',
  goToSkill: 'Ir para {{skill}}',
  go_back_btn: 'Botão Voltar',
  good: 'Bom',
  goodAfternoon: 'Boa tarde',
  goodEvening: 'Boa noite',
  goodJob: 'Bom trabalho!',
  goodLuck: 'Boa sorte!',
  goodMorning: 'Bom dia',
  goodNight: 'Boa noite',
  gotAllStars: 'e recebeu todas as estrelas',
  gotThis: 'Entendi!',
  grade: 'Ano/série',
  grade_1: '1º ano (EFAI)',
  grade_10: '1ª série (EM)',
  grade_10th: '1ª série (EM)',
  grade_11: '2ª série (EM)',
  grade_11th: '2ª série (EM)',
  grade_12: '3ª série (EM)',
  grade_12th: '3ª série (EM)',
  grade_1st: '1º ano (EFAI)',
  grade_2: '2º ano (EFAI)',
  grade_2nd: '2º ano (EFAI)',
  grade_3: '3º ano (EFAI)',
  grade_3rd: '3º ano (EFAI)',
  grade_4: '4º ano (EFAI)',
  grade_4th: '4º ano (EFAI)',
  grade_5: '5º ano (EFAI)',
  grade_5th: '5º ano (EFAI)',
  grade_6: '6º ano (EFAF)',
  grade_6th: '6º ano (EFAF)',
  grade_7: '7º ano (EFAF)',
  grade_7th: '7º ano (EFAF)',
  grade_8: '8º ano (EFAF)',
  grade_8th: '8º ano (EFAF)',
  grade_9: '9º ano (EFAF)',
  grade_9th: '9º ano (EFAF)',
  grade_none: 'Nenhum',
  grade_university: 'Ensino Superior',
  grammar_tip: 'Dica de gramática',
  great: 'Ótimo!',
  greatJob: 'Ótimo trabalho!',
  he: 'hebraico',
  hello: 'Olá',
  helloBritannica: 'Hello Britannica',
  helloWorld: 'Olá, mundo!',
  help: 'Ajuda',
  hi: 'Oi',
  hints: 'Dicas',
  hints_options: {
    add_more_time: 'Adicionar tempo no relógio',
    hear_the_english_word: 'Ouça a palavra em inglês',
    remove_a_letter: 'Remover uma letra',
    remove_one_answer: 'Remover uma resposta',
    reveal_a_pair: 'Revelar um par',
    see_the_translation: 'Ver tradução',
    see_the_word: 'Ver palavra',
    show_a_letter: 'Mostrar uma letra',
  },
  homepage: 'Página inicial',
  howDidYouDo: 'Como você se saiu?',
  howsYourEnglish: 'Como está seu inglês?',
  iAgree: 'Concordo',
  iAgreeTo: 'Eu concordo com',
  iAmReady: 'Estou pronto!',
  iDidntUnderstand: 'Não entendi. Tente novamente.',
  iDoNotAgree: 'Não concordo',
  id: 'Identificação',
  illDoItLater: 'Farei mais tarde',
  improveYourRecentGames: 'Melhore seus jogos recentes',
  improveYourRecentVocabularyQuizzes: 'Melhore seus jogos de vocabulário recentes',
  inAllGames: 'Em todos os jogos',
  in_order_to_proceed_ask_teacher:
    'Para passar para o próximo nível, seu professor deve lhe conceder permissão.',
  in_order_to_start: 'Para iniciar sua jornada de aprendizado, precisamos',
  incorrect: 'Incorreto!',
  incorrect_answer: 'incorreto.',
  initialLevelSetTest: 'Teste inicial de nivelamento',
  initialLevelTest: 'Teste inicial de nivelamento',
  instructionalMaterial: 'Material instrucional',
  interfaceLanguage: 'Idioma da interface',
  interface_language: 'Idioma da interface',
  intermediate: 'Intermediário',
  invalidCode: 'Código inválido',
  invalidEmail: 'E-mail inválido',
  invalidField: 'Campo inválido',
  invalidFirstname: 'Nome inválido',
  invalidLastname: 'Sobrenome inválido',
  invalidPassword: 'Senha inválida',
  invalidUsernameOrPassword: 'Nome de usuário ou senha inválidos',
  invitationLink: 'Link de convite',
  invitationLinkWontChange: 'Não se preocupe, o link de convite não será alterado',
  inviteYourStudents: 'Convide seus alunos',
  inviteYourTeachers: 'Convide seus professores para se juntarem ao Hello Britannica.',
  invitedToJoin: 'convidado a participar do Hello Britannica.',
  invitedToJoinMorfix: 'convidado a se juntar ao Morfixschool 2.0.',
  isNotEnglish: 'Você deve responder esta atividade em inglês. Tente novamente.',
  itLooksLikeYouReallyKnowTheMaterial:
    'Parece que você realmente conhece o material e está avançando bem. Concentre-se em concluir todas as habilidades e continue progredindo.',
  itLooksLikeYourLevel:
    'Parece que o nível {{level}} é o mais apropriado. Continue praticando e você continuará melhorando!',
  itLooksLikeYourLevelMax:
    'Bom trabalho! O nível {{level}} é o mais adequado para você. Vamos começar.',
  itsTime: 'É hora de escolher se você está pronto para o jogo ou não,',
  joinHere: 'Registre-se aqui:',
  joinNow: 'Cadastre-se agora',
  keepGoing: 'Continuar',
  keepPlaying: 'Continue jogando',
  keepPracticing: 'Continue praticando',
  keepUpTheGoodWork: 'Continue com o bom trabalho',
  know: 'conheço',
  know_your_level: 'saiba seu nível de inglês.',
  language: 'Idioma',
  language_of_study: 'Idioma de estudo',
  languages: {
    ar: 'Árabe',
    en: 'Inglês',
    es: 'Espanhol',
    he: 'Hebraico',
    pt: 'Português',
  },
  lastUncorrectPronounce: 'Pula para a próxima pergunta.',
  lastUnitCongratulation:
    'Você completou o nível <strong> {{currentLevel.name}} </strong> <br/> e está a caminho do nível <strong> {{nextLevel.name}}</strong>.',
  lastname: 'Sobrenome',
  leave_level_test_and_sign_out: 'Sair do teste de nivelamento?',
  letsAssess:
    'Vamos verificar seu nível atual de inglês com um breve quiz para que possamos oferecer a melhor experiência de aprendizado. Pode levar de 5 a 15 minutos com base no seu desempenho. Preparado?',
  letsBegin: 'Vamos começar',
  letsFinish: 'Vamos encerrar',
  letsGo: 'Vamos lá',
  letsGoFinalAssessment: 'Vamos lá',
  letsKeepLearning: 'Vamos continuar aprendendo!',
  letsSee: 'Vamos ver o quanto você conhece seu vocabulário.',
  letsSeeIfYouReady: 'Vamos ver se você está pronto para',
  level: 'Nível',
  levelAchievement: 'Conquista de nível',
  levelPerSkill: 'Nível por habilidade',
  levelTest: 'Teste de nivelamento',
  levelTestIncomplete: 'Teste de nivelamento incompleto',
  linkCopied: 'Link copiado!',
  listen_tip: 'Ouvir dica',
  listeningTapToFinish: 'Ouvindo',
  loading_spinner: 'Carregando animação',
  loginFromDifferent: 'Faça login com uma conta diferente',
  logout: 'Sair',
  looksLikeYouOnARoll: 'Você está indo bem',
  melingoCalculateYourLevel: 'Estamos calculando seu nível...',
  memoryGameTitle: 'Vire as cartas para encontrar seis correspondências entre textos e imagens.',
  menu_button: 'Botão Menu',
  message: 'Mensagem',
  monday: 'Segunda-feira',
  mondayShort: 'Seg',
  moreTime: 'mais tempo',
  moreTimes: 'mais vezes',
  mustFirstCreateClass:
    'Para que seus alunos comecem a usar a plataforma, primeiro você deve criar uma turma e depois convidá-los a participar.',
  myProfile: 'Meu perfil',
  myProgress: 'Progresso do aluno',
  myRecentTranslations: 'Minhas traduções recentes',
  needHelp: 'Precisa de ajuda?',
  newClass: 'Nova turma',
  new_password: 'Nova senha',
  new_password_verification: 'Digite novamente a nova senha',
  next: 'Avançar',
  nextQuestion: 'Próxima pergunta',
  nextQuiz: 'Próxima atividade',
  nextUpInYourLearningPath: 'A seguir em sua trilha de aprendizado',
  nextWord: 'Próxima palavra',
  next_button: 'Botão Avançar',
  niceEffort: 'Bom trabalho,',
  noCoins: 'Sem moedas',
  noDataToDisplay: 'Não há dados para exibição.',
  noExtraCoins: 'Sem moedas extras',
  noExtraCoinsThisTime: 'portanto, não há moedas extras dessa vez',
  noResultsFor: 'Nenhum resultado para {{word}}',
  none: 'Nenhum',
  notAvailable: 'N/D',
  notBad: 'Nada mal',
  notMadeIt: 'Parece que você não conseguiu dessa vez. Você pode',
  notMadeItInTime: 'Parece que você não conseguiu desta vez. Vamos passar para a próxima pergunta!',
  ofThisSite: 'deste site.',
  ok: 'Ok',
  okLetsBegin: 'Ok, vamos começar!',
  okLetsGo: 'Ok, vamos lá!',
  oneMoreMistake: 'Um erro a mais encerrará o jogo,',
  onlyNumbersAllowed: 'Somente números são permitidos',
  oops: 'Opa!',
  oopsDots: 'Ops...',
  orClickHereToSendAgain: 'Ou <0> clique aqui </0> para reenviar o código.',
  orClickHereToSendAgainTeacher: '<0>Não recebeu? Reenviar código</0>',
  outOf: 'de',
  overallProgress: 'Progresso geral',
  passRate: 'Índice individual de aprovação',
  password: 'Senha',
  passwordMinimumLength: 'Tamanho mínimo de 8 caracteres',
  password_changed_successfully: 'Senha alterada com sucesso',
  passwordsDontMatch: 'As senhas não são correspondentes',
  passwords_must_match: 'As senhas devem ser iguais',
  pause_game: 'Pausar jogo',
  paussed: 'Pausado',
  perGame: 'Por jogo',
  perLevel: 'Por nível',
  perSkill: 'Por habilidade',
  phone: 'Telefone',
  phraseOfTheDay: 'Frase do dia',
  phrasesFor: 'Frases para',
  pictureOfTheDay: 'Imagem do dia',
  play_sound: 'Reproduzir som',
  pleaseChooseYourOwnPassword: 'Escolha sua própria senha',
  pleaseCompleteTheFollowingTest:
    'Faça o teste a seguir. Não tenha pressa, leia as instruções e dê o seu melhor.',
  pleaseContact:
    'Entre em contato diretamente com {{studentName}} e informe que é necessário sair e entrar novamente para visualizar as alterações.',
  pleaseContactYourSchoolManagerForGuidance:
    'Entre em contato com o gestor da sua escola para receber orientações.',
  pleaseEnter: 'Por favor, digite',
  pleaseEnterTheFollowing: 'Insira as seguintes informações para dar início à sua',
  pleaseEnterYourDetails: 'Insira seus dados',
  pleaseSelectYourAccount: 'Selecione sua conta ou faça login em outra conta',
  pleaseTakeAMoment: 'Reserve um momento para registrar sua escola.',
  pleaseTryAgain: 'Tente novamente.',
  pleaseTryAgainLaterWeApologize:
    'Tente novamente mais tarde. Pedimos desculpas pelo inconveniente.',
  pleaseWriteOnlyLetters: 'Escreva apenas letras',
  powerFulWords: 'nos capacitar, enriquecer e inspirar',
  practice: 'Praticar',
  practiceAgain: 'Praticar novamente',
  practiceProperPronunciation: 'Pratique a pronúncia correta.',
  practiceYourVocabulary: 'Pratique seu vocabulário',
  practice_makes_perfect: 'A prática leva à perfeição!',
  preferences: 'Preferências',
  premiumSection: {
    description:
      'Aqui você pode ver informações importantes ou recursos do administrador da escola ou da Britannica.',
    title: 'Uma mensagem para os professores',
    unlock: 'Desbloqueie Premium',
  },
  preparationAudioToImage:
    'Escuta só! Neste divertido jogo, você ouvirá áudios curtos e selecionará uma imagem como resposta.',
  preparationAudioToImageMobile:
    'Escuta só! Neste divertido jogo, você ouvirá áudios curtos e selecionará uma imagem como resposta.',
  preparationClosedSpelling:
    'Hora de soletrar! Observe a imagem e use as letras para soletrar o que você vê. É um jogo divertido para melhorar suas habilidades de ortografia!',
  preparationClosedSpellingMobile:
    'Hora de soletrar! Observe a imagem e use as letras para soletrar o que você vê. É um jogo divertido para melhorar suas habilidades de ortografia!',
  preparationDescriptionFirstPart:
    'Escuta só! Neste divertido jogo, você ouvirá áudios curtos e selecionará uma imagem como resposta.',
  preparationDescriptionFirstPartMobile:
    'Escuta só! Neste divertido jogo, você ouvirá áudios curtos e selecionará uma imagem como resposta.',
  preparationDescriptionSecondtPart: 'É como um quebra-cabeça que ajuda você a aprender inglês!',
  preparationGrammar:
    'Preparado? Complete as frases selecionando a opção gramaticalmente correta. Vamos praticar a gramática da língua inglesa!',
  preparationGrammarMobile:
    'Preparado? Complete as frases selecionando a opção gramaticalmente correta. Vamos praticar a gramática da língua inglesa!',
  preparationImageToText:
    'Prepare-se! Neste divertido jogo, você observará uma imagem e selecionará a resposta adequada. Aprimore suas habilidades de inglês com imagens!',
  preparationImageToTextMobile:
    'Prepare-se! Neste divertido jogo, você observará uma imagem e selecionará a resposta adequada. Aprimore suas habilidades de inglês com imagens!',
  preparationListen:
    'Escuta só! Neste divertido jogo, você ouvirá um áudio curto e selecionará a resposta adequada.',
  preparationListenMobile:
    'Escuta só! Neste divertido jogo, você ouvirá um áudio curto e selecionará a resposta adequada.',
  preparationMemory:
    'Desafie sua memória! Combine os cartões de palavras com cartões de imagem adequados. É um exercício lúdico para reforçar seu vocabulário de inglês!',
  preparationMemoryMobile:
    'Desafie sua memória! Combine os cartões de palavras com os cartões de imagem adequados. É um exercício lúdico para reforçar seu vocabulário em inglês!',
  preparationNaturalConversation:
    'Liga o som! Ouça o áudio e selecione a resposta certa, depois pratique pronunciá-la. É como se fosse uma conversa de verdade para aprimorar suas habilidades de expressão oral!',
  preparationNaturalConversationMobile:
    'Liga o som! Ouça o áudio e selecione a resposta correta, depois pratique pronunciá-la. É como se fosse uma conversa de verdade para melhorar suas habilidades de expressão oral!',
  preparationOpenWriting:
    'Mostre suas habilidades de escrita! Descreva a imagem e obtenha uma nota com base em sua redação. É uma forma recreativa de melhorar a forma como você usa o inglês!',
  preparationOpenWritingMobile:
    'Mostre suas habilidades de escrita! Descreva a imagem e obtenha uma nota com base em sua redação. É uma forma recreativa de melhorar a forma como você usa o inglês!',
  preparationRead:
    'Vamos lá! Leia o texto com atenção e responda às perguntas a seguir. É uma ótima maneira de auxiliar sua compreensão do inglês!',
  preparationReadMobile:
    'Vamos lá! Leia o texto com atenção e responda às perguntas a seguir. É uma ótima maneira de auxiliar sua compreensão do inglês!',
  preparationSecondtPartGrammar:
    'Não se preocupe, você receberá dicas ao longo do caminho para ajudar.',
  preparationSecondtPartImageToText: 'Boa sorte!',
  preparationSecondtPartSpeak: 'Boa sorte!',
  preparationSecondtPartTextToImage: 'Boa sorte!',
  preparationSecondtPartVocabulary:
    'Vamos ver se você conhece bem o seu vocabulário. <b>Boa sorte! </b>',
  preparationSpeaking:
    'Pratique a pronúncia correta. Usando o microfone, fale devagar e com clareza e certifique-se de que não haja ruído de fundo. Boa sorte!',
  preparationSpeakingMobile:
    'Pratique a pronúncia correta. Usando o microfone, fale devagar e com clareza e verifique se não há ruído de fundo.',
  preparationTextToImage:
    'Vamos nessa! Neste emocionante jogo, você lerá o enunciado e escolherá a imagem correspondente entre quatro opções. É uma ótima maneira de vincular palavras a imagens!',
  preparationTextToImageMobile:
    'Vamos nessa! Neste emocionante jogo, você lerá o enunciado e selecionará a imagem correspondente entre quatro opções. É uma ótima maneira de vincular palavras a imagens!',
  preparationTextToText:
    'Aprimore seu vocabulário e leitura selecionando a palavra que complete adequadamente as frases a seguir.',
  preparationTextToTextMobile:
    'Aprimore sua leitura, vocabulário e gramática selecionando a resposta correta para completar as perguntas a seguir.',
  preparationVocabulary:
    'Pratique e amplie seu vocabulário identificando palavras e frases em inglês  e, em seguida, selecionando a tradução correta.',
  preparationVocabularyMobile:
    'Pratique e amplie seu vocabulário identificando palavras e frases em inglês e, em seguida, selecionando a tradução correta.',
  preparetionAudioToImage:
    'Escuta só! Neste divertido jogo, você ouvirá áudios curtos e selecionará uma imagem como resposta. É como um quebra-cabeça que ajudará você a aprender inglês!',
  preparetionClosedSpelling:
    'Hora de soletrar! Observe a imagem e use as letras para soletrar o que vê. É um jogo divertido para melhorar suas habilidades de ortografia!',
  preparetionDescriptionFirstPart:
    'Escuta só! Neste divertido jogo, você ouvirá áudios curtos e selecionará uma imagem como resposta. É como um quebra-cabeça que ajuda você a aprender inglês!',
  preparetionDescriptionSecondtPart: 'É como um quebra-cabeça que ajuda a aprender inglês!',
  preparetionGrammar:
    'Preparado? Complete as frases selecionando a opção gramaticalmente correta. Vamos praticar sua gramática de língua inglesa!',
  preparetionImageToText:
    'Prepare-se! Neste divertido jogo, você observará uma imagem e selecionará a resposta adequada. Aprimore suas habilidades de inglês com imagens!',
  preparetionListen:
    'Escuta só! Neste divertido jogo, você ouvirá um áudio curto e selecionará a resposta correspondente.',
  preparetionMemory:
    'Desafie sua memória! Combine os cartões de palavras com cartões de imagem adequados. É um exercício lúdico para reforçar seu vocabulário de inglês!',
  preparetionNaturalConversation:
    'Liga o som! Ouça o áudio e selecione a resposta correta, depois pratique pronunciá-la. É como se fosse uma conversa de verdade para melhorar suas habilidades de expressão oral!',
  preparetionOpenWriting:
    'Mostre suas habilidades de escrita! Descreva a imagem e obtenha uma nota com base em sua redação. É uma forma recreativa de melhorar a forma como você usa o inglês!',
  preparetionRead:
    'Vamos lá! Leia o texto com atenção e responda às perguntas a seguir. É uma ótima maneira de auxiliar sua compreensão do inglês!',
  preparetionSpeaking:
    'Pratique a pronúncia correta. Usando o microfone, fale devagar e com clareza e certifique-se de que não haja ruído de fundo. Boa sorte!',
  preparetionTextToImage:
    'Vamos nessa! Neste emocionante jogo, você lerá o enunciado e selecionará a imagem correspondente entre quatro opções. É uma ótima maneira de vincular palavras a imagens!',
  preparetionTextToText:
    'Melhore seu vocabulário e sua leitura selecionando a palavra que complete adequadamente as frases.',
  preparetionVocabulary:
    'Pratique e amplie seu vocabulário identificando palavras e frases em inglês e, em seguida, selecionando a tradução correta.',
  previous_button: 'Botão anterior',
  processing: 'Processando',
  proficiency: 'Proficiência',
  profile: 'Perfil',
  progress: 'Progresso',
  pt: 'Português',
  qs: 'Perguntas',
  question: 'Pergunta',
  questions: 'Perguntas',
  questionsAndConcerns: 'dúvidas e considerações.',
  questionsLeft: 'número de perguntas restantes',
  quiz: 'Atividade',
  quizzes: 'Quizzes',
  quizzesCompleted: 'Jogos concluídos',
  quizzesSection: {
    comingUpQuizzesTitle: 'Próximos jogos',
    lastQuizzesSubTitle: 'Melhorar de 1/2 estrelas para 3 estrelas',
    lastQuizzesTitle: 'Melhore seus jogos recentes',
  },
  ran_out_of_time: 'Parece que seu tempo acabou novamente. Esta pergunta será marcada como',
  rateYourself: 'Avalie-se',
  readMore: 'Leia mais',
  readTheText: 'Leia o texto e responda às perguntas',
  readWithImageStartQuizInstructions: 'Leia o texto e responda às perguntas a seguir',
  read_tip: 'Ler dica',
  readyToChooseYourAvatar: 'Pronto para escolher seu avatar?',
  receiveTipsAndUpdates:
    'Eu gostaria de receber periodicamente dicas e atualizações de produtos da Britannica',
  receiveTipsAndUpdatesMorfix:
    'Eu gostaria de receber periodicamente dicas e atualizações de produtos do Morfixschool 2.0',
  recentLogin: 'Login recente',
  refresh: 'Atualizar',
  registrationDealLimitReachedMessageDescription:
    'Entre em contato com o gestor escolar para obter orientações. <br/><br/>Código de erro: LR9D2F7H',
  registrationDealLimitReachedMessageTitle: 'Limite de matrículas por escola atingido 😕',
  rememberThis: 'Lembra disso?',
  remind_teacher_to_move_up: 'Lembre ao seu professor para subir você de nível.',
  reports: {
    activitiesCompletedInLevel: 'Atividades concluídas no nível {0}',
    attemptsAndHints: '📍 Tentativas e Dicas',
    avgActivitiesCompleted: 'Média de atividades concluídas',
    avgAttempts: '📍 Média de tentativas',
    avgAttemptsPerActivity: 'Média de tentativas por atividade',
    avgFailureRate: 'Índice geral de reprovação',
    avgHintsPerActivity: 'Média de dicas por atividade',
    avgPassRate: 'Índice geral de aprovação',
    avgPerWeek: 'Média geral por semana',
    avgSkillScores: '💯 Média geral de pontuação das habilidades',
    avgStreakLength: '📆 Média geral de frequência',
    avgStreakPerWeek: 'Média geral de frequência por semana',
    avgStudentProgress: '🚀 Média geral de progresso dos alunos',
    avgTimeSpent: '⏱️ Média geral de tempo gasto',
    continuousEngagement: 'Empenho contínuo',
    daysPerWeek: '{0} dias por semana',
    engagement: '💪🏼 Empenho',
    failureRate: 'Índice individual de reprovação',
    inCurrentLevel: 'No nível atual',
    longestStreak: 'Maior frequência',
    onActivities: 'Em atividades',
    overallProgressInLevel: 'Progresso geral no nível {0}',
    passRate: 'Índice individual de aprovação',
    perActivity: 'Por atividade',
    perLevel: 'Por nível',
    previous7Days: 'Últimos 7 dias',
    progress: '🚀 ️Progresso',
    remark: '*estes dados se relacionam a <b>{0}</b> alunos ativos de {1} alunos dessa turma.',
    streakLast7Days: 'Frequência dos últimos 7 dias',
    streakLength: '📆  Frequência individual total',
    timeSpentOnActivites: 'Tempo gasto nas atividades',
    total: 'Total',
    totalHintsUsed: 'Total de dicas usadas',
    totalInPrev7Days: 'Total nos últimos 7 dias',
    totalStudents: 'Total de alunos',
  },
  requirements: 'Requisitos',
  resendVerificationCode: 'Não recebeu o código? Enviar código novamente',
  resultForTheTranslation: 'resultado para a tradução de',
  results: 'Resultados',
  resultsForTheTranslation: 'resultados para a tradução de',
  resume: 'Retomar',
  review: 'Revisar',
  reward: 'Recompensa',
  saturday: 'Sábado',
  saturdayShort: 'Sáb',
  save: 'Salvar',
  saveChanges: 'Salvar alterações?',
  schoolName: 'Nome da escola',
  score: 'Pontuação',
  searchForAStudent: 'Pesquisar aluno',
  searchForStudent: 'Pesquisar aluno',
  selectSkill: 'Selecionar habilidade',
  selfPracticeProgress: 'Progresso da prática do aluno',
  selfPracticeStreak: 'Frequência de prática do aluno',
  send: 'Enviar',
  sendNow: 'Enviar agora',
  settings: {
    about_me: 'Sobre mim',
    about_the_product: 'Sobre o produto',
    add_students: {
      add_student: 'Adicionar novo aluno',
      add_students: 'Adicionar novos alunos',
      add_students_manually: 'Adicionar manualmente',
      button_next: 'Próximo',
      click_to_browse: 'clique aqui para pesquisar',
      create_students: 'Os alunos a seguir receberão um e-mail de convite',
      download: 'Fazer download',
      download_sample: 'de um modelo de arquivo Excel (xlsx)',
      drag_here: 'Arraste os arquivos e solte-os aqui ou',
      email_will_be_send: 'Um e-mail com uma senha temporária será enviado para o aluno',
      enroll_students: 'Inscrever alunos',
      enrollment_unsuccessful: '⚠️ Matrícula não concluída!',
      enrollment_unsuccessful_description:
        'Os seguintes alunos não puderam se matricular porque seus e-mails já foram usados:',
      errors: {
        bad_formatting:
          'O arquivo não possui o formato correto. <br/> Verifique seus dados e faça as correções necessárias',
        email_in_use: 'Este endereço de e-mail já foi utilizado',
        email_incorrect: 'Insira um e-mail válido.',
        file_empty: 'O arquivo que você está tentando enviar está vazio',
        file_too_large: 'O arquivo é muito grande. O tamanho máximo permitido é de 256KB.',
        first_name_incorrect: 'Nome contém caracteres inválidos.',
        first_name_missing: 'É necessário inserir o nome.',
        last_name_incorrect: 'Sobrenome contém caracteres inválidos.',
        last_name_missing: 'É necessário inserir o sobrenome.',
        limit_reached:
          'O número máximo de alunos por turma é de 50. <br/> Remova e faça upload de um novo arquivo.',
        limit_reached_description: 'Sua turma contém o número máximo permitido de alunos.',
        limit_reached_title: 'A turma está cheia.',
        max_number_of_students:
          'O número máximo de alunos por turma é 50. <br/> Remova e faça upload de um novo arquivo.',
        students_not_registered: 'Alguns alunos não foram inscritos',
        wrong_format:
          'O formato do arquivo está incorreto. <br/> Certifique-se de que foi selecionado um arquivo Excel (xlsx).',
      },
      invite_students: 'Convidar alunos',
      partial_success: '⚠️ Sucesso parcial',
      partial_success_description:
        '{{addedStudents}}de {{totalStudents}} alunos se matricularam na turma. <br/>Eles receberão um e-mail com uma senha temporária.',
      preview: 'Lista de alunos',
      students_preview: 'Pré-visualização',
      success_description:
        'Seus alunos receberão um e-mail contendo instruções sobre como redefinir suas senhas. Quando terminarem, eles poderão dar início às suas jornadas de aprendizado de inglês.',
      success_title: 'A turma foi criada com sucesso!',
      upload_attention_text:
        '⚠️ Sua turma contém{{curent}}//{{limit}}alunos. <br/>Você pode matricular mais{{certified}} alunos.',
      upload_attention_text_new_class: '⚠️ Máx. de {{limit}}alunos por turma',
      upload_description:
        'Quando o arquivo for carregado, seus alunos serão inscritos e receberão uma senha temporária em seus e-mails e, então, poderão fazer login no Hello Britannica.',
      upload_file: 'Carregue o arquivo de Excel com a lista de alunos',
      upload_instruction: 'Faça download e visualize o modelo.',
      upload_successful: 'Seu arquivo foi carregado com sucesso',
    },
    change_password: 'Alterar senha',
    class: 'Minha turma',
    current_level: 'Nível atual',
    delete_student: {
      areYouSure: 'Tem certeza?',
      cancel: 'Cancelar',
      choose: 'Selecione',
      delete: 'Excluir',
      deleteStudent: 'Excluir aluno',
      deletingFailed: 'Falha ao excluir.',
      deletingFailedDescription: '',
      description:
        'A exclusão desse aluno o removerá permanentemente da plataforma. Esse aluno não poderá mais fazer login e todos os dados de progresso serão perdidos. Forneça um motivo:',
      reason: 'Motivo',
      reasons: {
        aClericalError: 'Erro administrativo',
        other: 'Outro',
        requestedToBeRemoved: 'O aluno ou pai solicitou a remoção',
      },
      why: 'Por quê?',
    },
    grade: 'Ano/Série',
    home: 'Página inicial',
    language: 'Idioma',
    logout: 'Sair',
    my_classes: 'Minhas turmas',
    my_games: 'Meus jogos',
    my_students: 'Meus alunos',
    my_tutor: 'Meu assistente',
    native_language: 'Minha língua materna',
    privacy_policy: 'Política de privacidade',
    profile: 'Perfil',
    school_id: 'Minha escola',
    security: 'Segurança',
    sound: 'Som',
    title: 'Configurações',
    username: 'Meu nome',
  },
  showMore: 'mostrar mais',
  signIn: 'Fazer login',
  signYouIn: 'Fazendo o seu login...',
  sign_out: 'Sair',
  sincerely: 'Atenciosamente, {{name}}',
  sixDigits: '6 dígitos',
  sixDigitsAtLeast: 'O código deve ter pelo menos 6 dígitos',
  skills: {
    grammar: 'Gramática',
    listen: 'Compreensão auditiva',
    read: 'Leitura',
    speak: 'Expressão oral',
    spelling: 'Ortografia',
    vocabulary: 'Vocabulário',
  },
  skillsTestTryAgain:
    'Você pode tentar novamente agora ou voltar para os jogos anteriores desse nível e praticar mais.',
  skip: 'Pular',
  somewhatAgree: 'Concordo parcialmente',
  sorryThisLinkIsNoLongerValid: 'Desculpe, o link não é mais válido',
  sortBy: 'Ordenar por:',
  speakNow: 'Fale agora',
  speak_tip: 'Dica de pronúncia',
  spelling_tip: 'Dica de ortografia',
  stars: 'Estrelas',
  start: 'Iniciar',
  startActivity: 'Iniciar atividade',
  startLevelTest: 'Iniciar teste de nivelamento',
  startNextQuiz: 'Iniciar o próximo quiz',
  startQuiz: 'Iniciar jogo',
  startTheNextLevel: 'Iniciar próximo nível',
  startTheNextQuiz: 'Iniciar próximo jogo',
  startingReward: 'Recompensa inicial',
  state: 'Estado',
  studentActivity: 'Atividade do aluno',
  studentInstructions: 'Instruções para estudantes',
  studentLevels: 'Níveis de alunos',
  studentName: 'Nome do aluno',
  studentReport: 'Relatório do aluno',
  students: 'Alunos',
  studentsAreNotPermittedToAdvanceToTheNextLevel:
    'Os alunos não têm permissão para avançar para o próximo nível. Se você decidir alterar o nível do aluno com base no desempenho dele, poderá fazê-lo entrando no perfil do aluno e alterando manualmente o nível para cada habilidade.',
  studentsSuccessfullyEnrolled: 'Os alunos foram matriculados com sucesso!',
  study_language: 'Idioma de estudo',
  subject: 'Assunto',
  submit: 'Enviar',
  suggested_languages: 'Idiomas sugeridos',
  sunday: 'Domingo',
  sundayShort: 'Dom',
  synonyms: 'Sinônimos',
  tapToSpeak: 'Toque para falar',
  tapToTranslate: 'Toque para traduzir...',
  teacherEndLevelTest:
    'Você e seus alunos iniciarão as habilidades de expressão oral e ortografia no nível iniciante. Isso fará com que o nível geral pareça um pouco mais baixo. Você pode ajustar manualmente o nível dos alunos em cada habilidade acessando o perfil deles em seu painel, ao passo que é possível acessar todos os jogos em todos os níveis.',
  teacherInstructions: 'Instruções para professores',
  teacherLetsBeginn: 'Vamos começar!',
  teacherName: 'Nome do professor',
  teachers: 'Professores',
  teachersThatHaveRegistered: 'Professores que se inscreveram',
  termsAndConditions: 'Termos e condições',
  testYourEnglish: 'Teste seu nível de inglês',
  thankYou: 'Obrigado!',
  thanksForSubmission: 'Obrigado por enviar sua resposta!',
  thatsTheWay: 'É isso aí!',
  thats_the_way: 'É isso aí!',
  theGamesIn: 'os jogos em',
  theWordWasntPronouncedCorrectly: 'A palavra não foi pronunciada corretamente.',
  theWordsWerentPronouncedCorrectly: 'As palavras não foram pronunciadas corretamente.',
  thereAre: 'Existem',
  thereIs: 'Há',
  thisStudentHasNot:
    'Este aluno ainda não concluiu o teste de nivelamento. Assim que ele iniciar a jornada, você poderá acompanhar seu progresso aqui.',
  thisTestIsDesignedToAssessYourStudentsInTheirGeneralEnglishLevel:
    'Quando os alunos tiverem concluído todas as atividades de habilidade em seu nível, eles poderão receber um teste de conclusão de nível que avaliará sua proficiência em tudo o que aprenderam. Agende o teste selecionando uma data no campo a seguir.',
  thisTestWillHelpYouSeeHowMuchEnglishYouHaveLearnedSoFar:
    'Este teste irá ajudar a avaliar o seu progresso de aprendizado de inglês até o momento. Ele inclui gramática, vocabulário, leitura, compreensão auditiva e expressão oral. Após o teste, você pode continuar jogando e aprendendo.',
  thisTopicWillHelpMe: 'Este tópico vai me ajudar',
  thisWillHelpYouUnderstandTheMaterialBetter: 'Isso ajudará você a entender melhor o material',
  thursday: 'Quinta-feira',
  thursdayShort: 'Qui ',
  thwWordYouEntered:
    'A palavra que você digitou não está no dicionário. Tente pesquisar uma palavra diferente',
  timeIsUp: 'Tempo esgotado!',
  tip: 'Dica!',
  tipsAndTricks: 'Dicas e sugestões',
  to: 'Para',
  toImproveYourVocabulary: 'Para melhorar seu vocabulário,',
  toJoin: 'para se juntar',
  toNextQuestion: 'para a próxima pergunta',
  toViewItsOverallLearningProgress: 'para visualizar seu progresso geral de aprendizado',
  toViewTheirIndividualProgress: 'para visualizar seu progresso individual',
  toViewTheirOverallProgress: 'para visualizar seu progresso geral',
  todayQuestion: 'Pergunta de hoje',
  topicProgress: 'Progresso do tópico',
  topics: 'Tópicos',
  totalActivities: 'Total de atividades',
  totalCoins: 'Total de moedas',
  totalHintsUsed: 'Total de dicas usadas',
  totalQuizzes: 'Total de jogos',
  totalWords: 'Total de palavras',
  trasnlateAWordOrPhrase: 'Traduza uma palavra ou frase em inglês ou em {{nativeLanguage}}...',
  tries: 'tentativas',
  tryAgain: 'Tente novamente',
  tryAgainBtn: 'Tente mais uma vez',
  tryAgainLater: 'Tente novamente mais tarde!',
  tryAgainOneMore: 'Tente novamente ou siga em frente',
  tryAgainOrKeepGoing: 'Tente novamente ou continue',
  tryBetter: 'Tente se esforçar mais na próxima vez',
  tuesday: 'Terça-feira',
  tuesdayShort: 'Ter ',
  tutorBack: '< Voltar',
  tutorBannerTitle:
    'Evolua seu aprendizado para o próximo nível! Com base em sua destreza com jogos, reunimos algumas sugestões para você melhorar seu conhecimento em inglês e desafiá-lo a ter sucesso.',
  tutorChalangeTitle: 'Faça pausas breves nos estudos para aprimorar o foco e a memória. ',
  tutorChallenge: 'Quero um desafio',
  tutorChooseAnswer: 'Selecione a resposta correta para completar a frase:',
  tutorClickToPractice: 'Clique para praticar!',
  tutorContinue: 'Continuar sem praticar',
  tutorContinueQuiz: 'Continuar quiz',
  tutorCorrectAnswer: 'Mostrar a resposta correta',
  tutorCorrectAnswerIs: 'A resposta correta é "{{answer}}".',
  tutorCorrectAnswerIsShort: 'A resposta correta é <strong>{{answer}}</strong>',
  tutorCorrectBody: 'Você está pegando o jeito!',
  tutorCorrectSentence: 'A resposta correta é...',
  tutorCorrectTitle: 'Correto!',
  tutorEmptyDashboard:
    'Ops! Está meio vazio aqui... Nossa equipe está trabalhando para fornecer mais conteúdo!',
  tutorEndChatOption: 'Fechar chat e voltar',
  tutorExampleOption: 'Preciso de mais exemplos',
  tutorExerciseOption: 'Vamos fazer exercícios',
  tutorExitNo: 'Continuar',
  tutorExitText:
    'Observe que fechar o Assistente irá excluir o chat atual. Não se preocupe. Você sempre pode iniciar um novo chat.',
  tutorExitTitle: 'Prática em andamento',
  tutorExitYes: 'Finalizar prática',
  tutorExplainMore: 'Explorar com Assistente',
  tutorFeedback: 'Gostaria de fornecer feedback?',
  tutorGetSession: 'Faça uma sessão personalizada',
  tutorGetSessionTitle:
    'Faça uma sessão personalizada para que futuramente seja mais fácil e divertido',
  tutorGiveExample: 'Dê-me mais exemplos',
  tutorGotIt: 'Entendi',
  tutorHowHelp: 'Como posso ajudar?',
  tutorInCorrectBody: 'Sua resposta, "{{answer}}" está incorreta.',
  tutorInCorrectTitle: 'Ops. Incorreto.',
  tutorKeepGoing: 'Muito bem! Faça pausas breves nos estudos para aprimorar o foco e a memória.',
  tutorLetMeExplain: 'Deixe-me explicar...',
  tutorLetsPractice: 'Vamos praticar antes de continuar?',
  tutorLetsPracticeOption: 'Vamos praticar!',
  tutorLetsStart: 'Vamos começar!',
  tutorMore: 'É recomendado praticar',
  tutorMoreExplanation: 'Mais explicação',
  tutorMoreHelp: 'Mais ajuda',
  tutorMoreTips: 'Mais dicas',
  tutorNeedHint: 'Preciso de uma dica',
  tutorNewtag: 'Novo',
  tutorNoAndContinue: 'Não, continue',
  tutorNoMoreHelp: 'Não, preciso de mais ajuda',
  tutorNotification: 'Notificação de quiz',
  tutorNotificationBack: 'Voltar',
  tutorNotificationBody:
    'Desativar a opção para receber explicações após cada resposta incorreta até o final do quiz?',
  tutorNotificationMuted: 'As notificações dos quizzes foram silenciadas até o final do quiz ',
  tutorNotificationOff: 'Sim, desativar',
  tutorPractice: 'Praticar para se aperfeiçoar',
  tutorPracticeIsRecommended: 'É recomendado praticar',
  tutorPracticeWithMe: 'Pratique comigo',
  tutorPracticeWithMeTitle: 'Pratique comigo para aprimorar suas habilidades de gramática.',
  tutorPrivate: 'Assistente Particular',
  tutorPrivateTutorTip: 'Dica do Assistente Particular',
  tutorShifting:
    'Estou preparando o jogo {{quizName}}. Quase lá... aguarde apenas mais alguns segundos.',
  tutorSkill: 'Habilidade',
  tutorStartedAt: 'Chat iniciado em {{timeStart}}',
  tutorTryBetterTitle: 'Tente se esforçar mais na próxima vez',
  tutorTurnOff: 'Desativar',
  tutorUser: 'Usuário',
  tutorWasClear: 'Ficou claro?',
  tutorWasHelpful: 'Isso te ajudou?',
  tutorWelcomeTitle: 'Bem-vindo(a) ao Assistente Particular',
  tutorYesAndContinue: 'Sim, continuar',
  tutorYesGoBack: 'Sim, voltar para o quiz',
  tutor_quizzes_not_taken: 'Junte-se a mim para praticar mais!',
  tutor_quizzes_taken: 'Transforme seus pontos fracos em pontos fortes',
  uncorrectPronounce: 'Tente novamente ou pule para a próxima pergunta.',
  understandingYourEnglishLevel: 'Estamos começando a entender seu nível de inglês.',
  unfortunately:
    'Infelizmente, você não passou, portanto não receberá nenhuma moeda por esse jogo.',
  university: 'Universidade',
  update: 'Atualização',
  uploadCsvErrorModalDescription:
    'Talvez sua conexão com a internet esteja com problemas.<br />Tente novamente.',
  uploadCsvErrorModalTitle: 'Upload falhou',
  uponRegistrationStudentsWillReceiveAnInitialLevelTest:
    'Quando os alunos fizerem o login pela primeira vez, eles receberão um teste de nivelamento. Depois de concluído, sua trilha de aprendizagem individual será definida com base no nível de proficiência em cada habilidade. Se o teste estiver desativado, o aluno começará no nível 1.',
  useTheCoins: 'Use as moedas que você ganhou para obter dicas',
  userDataSuccessfullyUpdated: 'Os detalhes foram atualizados com êxito',
  userStreak: {
    subTitle: 'Pratique todos os dias para se tornar um MESTRE',
    title: 'Mantenha a frequência!',
  },
  username: 'Nome de usuário',
  usingYourMicrophone:
    'Usando seu microfone, fale devagar e com clareza e certifique-se de que não haja barulho de fundo.',
  validation: {
    code_required: 'O código é necessário',
    confirm_password_required: 'É necessário confirmar a senha',
    email_required: 'O e-mail é necessário',
    password_required: 'A senha é necessária',
    username_required: 'O nome de usuário é necessário',
  },
  validationCodeInvalid: 'Código de validação inválido',
  verificationCode: 'Código de verificação',
  verificationCodeSent: 'Um código de verificação foi enviado para',
  viewAll: 'Ver tudo',
  viewAnswerChoices: 'Exibir opções de resposta',
  viewDetails: 'Ver detalhes',
  viewPerformance: 'Visualizar desempenho',
  viewProfile: 'Ver perfil',
  vocabularyMistakes: 'Erros de vocabulário',
  vocabularyPractice: 'Prática de vocabulário',
  vocabulary_tip: 'Dica de vocabulário',
  wayToGo: 'Muito bem!',
  weAreAnalyzing: 'Estamos analisando sua redação. Aguarde alguns segundos...',
  weAreSorry: 'Desculpe',
  weCouldNotProduce: 'Não foi possível produzir o relatório no momento.',
  weRecommendThatYouGoBackToYourPreviousGames:
    'Recomendamos que você volte aos jogos e jogue-os novamente.',
  weStillDontHaveAll:
    'Ainda não temos todas as informações necessárias ou há uma falha temporária.',
  weWantYourFeedback: 'Olá, {{firstname}}, queremos o seu feedback!',
  we_want_to_know: 'Queremos saber seu nível',
  wednesday: 'Quarta-feira',
  wednesdayShort: 'Qua',
  welcome: 'Bem-vindo',
  welcomeBack: 'Bem-vindo de volta',
  welcomeConfetti: 'Bem-vindo!',
  welcomeTo: 'Bem-vindo a',
  wellDone: 'Muito bem',
  whatDidYouLearnAtThisLevel: 'O que você aprendeu neste nível?',
  whenStudentsWillHaveCompletedAParticularSkill:
    'Quando os alunos concluírem todas as atividades de habilidades no nível designado, eles serão avaliados com um teste relacionado ao que aprenderam. Uma pontuação de 80% ou mais será considerada como proficiência. Se um aluno não passar no teste, será solicitado que o faça novamente.',
  whenYourStudentsReceiveAPassingGrade:
    'Quando os alunos alcançarem um índice mínimo de aprovação na avaliação das habilidades em inglês, eles poderão avançar para o próximo nível da habilidade em questão. A desativação desse recurso impedirá que os alunos avancem para o próximo nível.',
  with_a_score_of: 'com uma pontuação de',
  wordCount: 'Número de palavras: {{wordCount}}',
  wordOfTheDay: 'Palavra do dia',
  words: 'Aprenda inglês',
  wordsDictionary: 'Palavras',
  wordsList: 'Lista de palavras',
  workHard: 'Dê o seu melhor para obter todas as estrelas.',
  work_hard_to_get_all_stars: 'Dê o seu melhor para obter todas as estrelas',
  wow: 'Uau',
  wow_score_all_stars:
    'Uau! Você obteve uma pontuação de <b>{{score}}%</b> e ganhou todas as estrelas.',
  wow_you_got_a_score_of:
    'Uau! Você obteve uma pontuação de <b>{{score}}%</b> dessa vez. Tente novamente para ganhar mais estrelas.',
  writeClassNameHere: 'Escreva o nome da turma aqui',
  writeHere: 'Escreva aqui',
  wrongAnswer: 'Resposta errada!',
  youAlmostThere: 'Você está quase lá...',
  youAreDoingGreatLetsKeepGoing: 'Você está indo bem! <br/>Vamos continuar.',
  youAreOnYourWay: 'Você está no caminho certo!',
  youCanCreateMore: 'Você pode criar mais de uma turma',
  youCanPractice:
    'você pode praticar palavras por meio de jogos ou usando cartões de memorização e uma lista.',
  youCanTryAssessment:
    'Agora, você tem a oportunidade de realizar a avaliação do nível {{level}}, a mesma que seus alunos realizarão antes de avançarem para o próximo nível.',
  youCompletedTheTest: 'Você concluiu o teste',
  youCompletedThisActivity: 'Você concluiu a atividade e pode seguir para a próxima.',
  youDidNotPass: 'Você não passou',
  youGot: 'Você tem',
  youHaveCompleted: 'Você concluiu o tópico!',
  youMayNowShare: 'Agora você pode compartilhar o link com seus',
  youPassedAll: 'Você passou em todos os',
  youPassedAllTheGamesIn: 'Você passou em todos os jogos do',
  youPassedTheQuiz:
    'Você concluiu a atividade com uma pontuação de <b>{{score}}%</b>! Bom trabalho!',
  youPracticed: 'Você praticou. Você estudou.',
  youStill: 'Você ainda não passou neste jogo, portanto não há moedas extras nem estrelas.',
  youWillLose: 'Você vai perder',
  you_can_now_practice_level: 'Agora você pode praticar o nível {{level}}',
  you_did_it: 'Você conseguiu!',
  you_got_a_score_of: 'Você obteve uma pontuação de {{score}}',
  you_got_a_score_try_again:
    'Você obteve uma pontuação de <b>{{score}}%</b> dessa vez. Tente novamente para ganhar mais estrelas.',
  you_got_no_extra_stars: 'Você tirou <b>{{score}}%</b> novamente.',
  you_passed_assessment: 'Você foi aprovado na avaliação',
  you_passed_quiz: 'Você passou no jogo',
  your: 'Suas',
  yourChangesWillAffectTheGeneralLevel: 'Suas alterações afetarão o nível geral',
  yourClasses: 'Suas turmas',
  yourMessageHasBeenSentSuccessfully:
    'Sua mensagem foi enviada com sucesso. Obrigado por compartilhar seu valioso feedback conosco!',
  yourMessageHere: 'Sua mensagem aqui',
  yourPasswordHere: 'Sua senha aqui',
  yourPhoneHere: 'Seu telefone aqui',
  yourSchoolHasBeenCreated: 'Sua escola foi criada com sucesso',
  yourStartingLevel: 'Seu nível inicial',
  yourStreak: 'Sua frequência',
  yourStudentQuiz:
    'Este é o quiz que seus alunos realizarão quando entrarem no aplicativo pela primeira vez. Sinta-se à vontade para respondê-lo agora ou mais tarde.',
  yourStudentsHaveNotJoined: 'Seus alunos ainda não ingressaram na turma',
  yourStudentsHaveNotJoinedYet: 'Seus alunos ainda não ingressaram na turma',
  yourStudentsInThisClassWillNotTakeTheInitialLevelTest:
    'Os alunos dessa turma não farão o teste inicial de nivelamento após o cadastro. Todos começarão no nível 1 em todas as habilidades de inglês.',
  yourStudentsWillNotReceiveAFinalExam: 'Seus alunos não receberão um exame final.',
  yourSubjectHere: 'Seu assunto aqui',
};
