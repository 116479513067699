import TheButton from '@/components/TheButton/TheButton';
import classNames from './PopUpOpenWrittingAIReview.module.scss';
import PopUpOpenWrittingError from './components/PopUpOpenError/PopUpOpenWrittingError';
import PopUpOpenWrittingSuccess from './components/PopUpOpenSuccess/PopUpOpenWrittingSuccess';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import lamp from '@/assets/svg/lamp.svg';

//FIXME: Need to change any for needed type
const PopUpOpenWrittingAIReview = ({ emitOnNextQuestion, emitOnRetry, emitOnQuit }: any) => {
  const { t } = useTranslation();
  const {
    currentQuestionIndex,
    quizQuestionResultData,
    gameQuestions,
    isOpenWritingAnswerInEnglish,
  } = useAppSelector(gameSelector);

  const isVulgar =
    quizQuestionResultData[currentQuestionIndex]?.openWritingResult?.scoreResponse
      ?.containsVulgarLanguage;

  const isGibberish =
    quizQuestionResultData[currentQuestionIndex]?.openWritingResult?.scoreResponse
      ?.containsGibberish;

  const showIsNotEnglishError =
    gameQuestions?.[currentQuestionIndex]?.newData?.data?.metadata?.isResponseInEnglish &&
    !isOpenWritingAnswerInEnglish;

  if (
    quizQuestionResultData[currentQuestionIndex]?.openWritingResult?.scoreResponse?.noGrading &&
    !isVulgar &&
    !isGibberish &&
    !showIsNotEnglishError
  ) {
    return (
      <div className={classes('container', classNames.popUpOpenWritting)}>
        <PopUpOpenWrittingSuccess />
        <div className={classNames.buttons} style={{ marginTop: '20px' }}>
          <TheButton text={t('next')} emitOnClick={emitOnNextQuestion} />
        </div>
      </div>
    );
  }

  const content =
    quizQuestionResultData[currentQuestionIndex]?.openWritingResult?.scoreResponse?.aIGradingReview;

  return (
    <div className={classes('container', classNames.popUpOpenWritting)}>
      {isVulgar || isGibberish || showIsNotEnglishError ? (
        <PopUpOpenWrittingError
          isVulgar={isVulgar}
          isGibberish={isGibberish}
          isNotEnglish={showIsNotEnglishError}
        />
      ) : (
        <>
          <div className={classNames.title}>
            <span>{t('thankYou')}</span>
          </div>
          <div className={classNames.details}>
            <div className={classNames.detailsTitle}>
              <SVG src={lamp} />
              <span>{t('review')}</span>
            </div>

            <p>{content}</p>
          </div>
        </>
      )}

      <div className={classNames.buttons}>
        <TheButton
          className={'flex-1'}
          text={t(isVulgar || isGibberish || showIsNotEnglishError ? 'tryAgain' : 'next')}
          emitOnClick={() =>
            isVulgar || isGibberish || showIsNotEnglishError
              ? emitOnRetry?.()
              : emitOnNextQuestion?.()
          }
        />
      </div>
    </div>
  );
};

export default PopUpOpenWrittingAIReview;
