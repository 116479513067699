import { createAsyncThunk } from '@reduxjs/toolkit';
import { skillService } from '@/services';
import { RootState } from '@/types/common';
import { SkillTypes } from '@/types/skill';
import commonUtils from '@/utils/common';
import { Topic } from '@/types/topic';
import { Question } from '@/types/question';

const endOfSkillThunks = {
  fetchEndOfSkillTest: createAsyncThunk(
    'skill/fetchEndOfSkillTest',
    async (topic: Topic, { getState }) => {
      try {
        const state = getState() as RootState;
        const user = state.auth.user;
        const selectedType = state.game.selectedType;
        const currentSkillLevel = state.auth.user?.progress?.userLevel;
        if (user && selectedType && currentSkillLevel) {
          const questions = await skillService.fetchEndOfSkillTest(
            user,
            selectedType,
            currentSkillLevel
          );

          if (selectedType === SkillTypes.Read) {
            if (questions && questions.questions) {
              const questionsMap = questions.questions as Record<number, Question[]>;

              Object.keys(questionsMap).forEach(key => {
                questionsMap[+key] = questionsMap[key as any].map((question: any) => {
                  const options = [...question.distractors.split(','), question.answer];

                  return {
                    ...question,
                    options: commonUtils.shuffleArray(options ?? []),
                  };
                });
              });

              questions.questions = questionsMap;
            }
          }
          return { questions, topic };
        }
        return null;
      } catch (error) {
        throw error;
      }
    }
  ),
};

export default endOfSkillThunks;
