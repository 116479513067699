import classNames from './LoaderModal.module.scss';
import { Transition } from 'react-transition-group';
import AppModal from '@/components/AppModal';
import AppLoaderCircle from '@/components/AppLoaderCircle';

interface LoaderModalProps {
  show: boolean;
}

const LoaderModal = ({ show }: LoaderModalProps) => {
  return (
    <Transition
      in={show}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => {
        return (
          <>
            <AppModal className={classNames.appModal}>
              <AppLoaderCircle className={classNames.loader} />
            </AppModal>
          </>
        );
      }}
    />
  );
};

export default LoaderModal;
