import classNames from './LoginViewContent.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useEffect, useState } from 'react';

import TheButton from '@/components/TheButton/TheButton';
import LoginForm from '@/components/LoginForm/LoginForm';
import QuickLogin from '@/components/QuickLogin/QuickLogin';

import leftArrow from '@/assets/svg/left-arrow.svg';
import welcome from '@/assets/svg/login_banner.svg';
import welcomeDesktop from '@/assets/svg/welcome-desktop.svg';
import logo from '@/assets/svg/logo-big.svg';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import UseLogin from '@/pages/login/UseLogin';
import { LoginStep } from '@/types/login';

const LoginViewContent = () => {
  const { t } = useTranslation();
  const { isFromRegistration } = useAppSelector(authSelector);
  const useLoginData = UseLogin();

  const { isMobile } = UseScreenSize();

  const [showForm, setShowForm] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const isFirstTime = true;

  const onQuickLogin = () => {};

  useEffect(() => {
    if (isFromRegistration) {
      setShowForm(true);
    }
  }, []);

  const name = 'Sarah Jonas';
  const level = 'Foundation';
  const grade = 9;

  const bottom = isFirstTime ? (
    <TheButton
      className={classNames.newNav}
      shouldRecolor={false}
      text={t('getStarted')}
      emitOnClick={() => setShowForm(true)}
    />
  ) : (
    <div className='flex flex-col gap-6 w-full'>
      <QuickLogin name={name} level={level} grade={grade} emitQuickLogin={onQuickLogin} />
      <button className={classNames.oldNav} onClick={() => setShowForm(true)}>
        {t('loginFromDifferent')}
      </button>
    </div>
  );

  const text = (
    <div className={classNames.text}>
      <SVG className={classNames.logo} src={logo} />
      <p>{!isFirstTime && isMobile ? t('pleaseSelectYourAccount') : t('exploreEngaging')}</p>
    </div>
  );

  const loginForm = (
    <LoginForm useLoginData={useLoginData} hideWelcome={() => setShowWelcome(false)} />
  );

  let content = (
    <div className={classNames.inner}>
      {showWelcome && (
        <div className={classNames.innerWelcome}>
          <SVG className={classNames.welcomeImg} src={welcomeDesktop} />
          {text}
        </div>
      )}
      {loginForm}
    </div>
  );

  if (isMobile) {
    content =
      showForm || useLoginData.currentLoginStep?.type !== LoginStep.LOGIN ? (
        <>
          <div className={classNames.backBtn}>
            <button
              onClick={() => {
                useLoginData.restartLogin();
                setShowWelcome(true);
                setShowForm(false);
              }}
            >
              <SVG src={leftArrow} />
            </button>
          </div>
          {loginForm}
        </>
      ) : (
        <>
          <div className={classNames.mobileInner}>
            <SVG className={classNames.welcomeImg} src={welcome} />
            {text}
            {bottom}
          </div>
        </>
      );
  }

  return (
    <div
      className={classes(classNames.loginViewContent, {
        [classNames.loginViewContentMobile]: isMobile,
      })}
    >
      {content}
    </div>
  );
};

export default LoginViewContent;
