import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { authSelector } from '@/store/reducers/auth';
import commonUtils from '@/utils/common';
import { SkillTypes } from '@/types/skill';
import { TopicsDrawerHeader, TopicsProgress, TopicsSlider } from './components';
import classNames from './TopicsSideDrawer.module.scss';
import classes from 'classnames';
import { useDrawer } from '@/context/DrawerContext';
import UseScreenSize from '@/hooks/UseScreenSize';
import SVG from 'react-inlinesvg';
import menu24 from '@/assets/svg/menu24x24.svg';
import { useTranslation } from 'react-i18next';
import GoBackBtn from '@/components/GoBackBtn/GoBackBtn';

interface TopicsSideDrawerProps {
  topicId: number;
}

const TopicsSideDrawer = ({ topicId }: TopicsSideDrawerProps) => {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const { isMobileSmall } = UseScreenSize();
  const { selectedTopic } = useAppSelector(gameSelector);
  const { user } = useAppSelector(authSelector);
  const isTeacher = user?.metadata.role === 'teacher';
  const totalActivities = selectedTopic?.activitiesInTopic || 0;

  const completedActivities = commonUtils.calculateComplitedActivities(
    user?.progress?.topic?.activitiesOverviewPerTopic[topicId]
  );
  const completedStars = commonUtils.calculateAchievedStars(
    user?.progress?.topic?.activitiesOverviewPerTopic[topicId]
  );

  const mockSkill = SkillTypes.Listen;
  const percentage = +commonUtils.calcPercentage(completedActivities, totalActivities) ?? 0;

  return (
    <div
      className={classes(classNames.topicsDrawer, {
        [classNames.topicsDrawerMobile]: isMobileSmall,
      })}
    >
      {isMobileSmall && (
        <div className={classNames.topicsHeaderMobile}>
          <GoBackBtn showText={false} showIcon={true} emitClick={drawer.closeTopicsDrawer} />
          <p>{selectedTopic?.name || 'N/A'}</p>
          <button
            onClick={drawer.openMobileDrawer}
            className={classNames.menuIcon}
            aria-label={t('menu_button').toString()}
          >
            <SVG src={menu24} />
          </button>
        </div>
      )}

      <div className={classNames.topicsDrawerHeader}>
        <TopicsDrawerHeader />
        {!isTeacher && (
          <TopicsProgress
            percentage={percentage}
            totalActivities={totalActivities}
            completedActivities={completedActivities}
            totalStars={totalActivities * 3}
            completedStars={completedStars}
          />
        )}
      </div>
      <TopicsSlider skill={mockSkill} />
    </div>
  );
};

export default TopicsSideDrawer;
