import android from '@/assets/svg/avatars/android.svg';
import bicycle from '@/assets/svg/avatars/bicycle.svg';
import bird from '@/assets/svg/avatars/bird.svg';
import boy_1 from '@/assets/svg/avatars/boy_1.svg';
import boy_2 from '@/assets/svg/avatars/boy_2.svg';
import boy_3 from '@/assets/svg/avatars/boy_3.svg';
import burger from '@/assets/svg/avatars/burger.svg';
import car from '@/assets/svg/avatars/car.svg';
import cat from '@/assets/svg/avatars/cat.svg';
import dog from '@/assets/svg/avatars/dog.svg';
import girl_1 from '@/assets/svg/avatars/girl_1.svg';
import girl_2 from '@/assets/svg/avatars/girl_2.svg';
import girl_3 from '@/assets/svg/avatars/girl_3.svg';
import helmet from '@/assets/svg/avatars/helmet.svg';
import hotdog from '@/assets/svg/avatars/hotdog.svg';
import masks from '@/assets/svg/avatars/masks.svg';
import pig from '@/assets/svg/avatars/pig.svg';
import rocket from '@/assets/svg/avatars/rocket.svg';
import rugby from '@/assets/svg/avatars/rugby.svg';
import scooter from '@/assets/svg/avatars/scooter.svg';
import skull from '@/assets/svg/avatars/skull.svg';
import ufo from '@/assets/svg/avatars/ufo.svg';
import vr from '@/assets/svg/avatars/vr.svg';
import winter from '@/assets/svg/avatars/winter.svg';

const avatarImages = {
  android,
  bicycle,
  bird,
  boy_1,
  boy_2,
  boy_3,
  burger,
  car,
  cat,
  dog,
  girl_1,
  girl_2,
  girl_3,
  helmet,
  hotdog,
  masks,
  pig,
  rocket,
  rugby,
  scooter,
  skull,
  ufo,
  vr,
  winter,
};

export default avatarImages;
