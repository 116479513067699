import { useTranslation } from 'react-i18next';
import classNames from './LevelTestLoading.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import UseScreenSize from '@/hooks/UseScreenSize';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import LevelTestLogOut from '../LevelTestLogOut/LevelTestLogOut';

import cup from '@/assets/svg/level-test-cup.svg';
import cupMobile from '@/assets/svg/level-test-cup-mobile.svg';
import logoutIcon from '@/assets/svg/logout.svg';
import { useState } from 'react';

const LevelTestLoading = () => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = UseScreenSize();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const title = <h2>{t('allDone')}</h2>;

  const description = (
    <div className={classNames.description}>
      <span className={classNames.youAlmostThere}>{t('youAlmostThere')}</span>
      <span className={classNames.fewMoreSeconds}>{t('fewMoreSeconds')}</span>
    </div>
  );

  const melingoCalculateYourLevel = (
    <span className={classNames.calculationText}>{t('melingoCalculateYourLevel')}</span>
  );

  return (
    <div
      className={classes(classNames.levelTestLoading, {
        [classNames.levelTestLoadingMobile]: isMobile,
      })}
    >
      <LevelTestLogOut showLogoutModal={showLogoutModal} setShowLogoutModal={setShowLogoutModal} />
      <div className={classNames.signOut}>
        <button
          onClick={() => {
            setShowLogoutModal(true);
          }}
        >
          {isMobile ? <SVG src={logoutIcon} /> : t('exit')}
        </button>
      </div>
      <div className={classNames.body}>
        {isMobile && title}
        <SVG src={isMobile ? cupMobile : cup} />
        {isDesktop && title}
        {isMobile ? description : melingoCalculateYourLevel}
      </div>
      <div className={classNames.bottom}>
        <AppLoaderCircle className={classNames.loader} />
        {isMobile ? melingoCalculateYourLevel : description}
      </div>
    </div>
  );
};

export default LevelTestLoading;
