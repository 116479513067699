export enum EventNames {
  Admin_User_Created = 'Admin_User_Created',
  Teacher_User_Created = 'Teacher_User_Created',
  Student_User_Created = 'Student_User_Created',
  Login = 'Login',
  Logout = 'Logout',
  Leveltest_result_1 = 'Leveltest_result_1',
  Leveltest_result_2 = 'Leveltest_result_2',
  Leveltest_result_3 = 'Leveltest_result_3',
  Leveltest_result_4 = 'Leveltest_result_4',
  Leveltest_result_5 = 'Leveltest_result_5',
  GameStart = 'GameStart',
  GameComplete = 'GameComplete',
  HintUsed = 'HintUsed',
}

export type UserPropertiesGA = {
  user_organization_name: string;
  user_native_language: string;
  user_interface_language: string;
  user_level: number;
  user_group_name: string;
  user_grammar_level?: number;
  user_spelling_level?: number;
  user_listen_level?: number;
  user_speak_level?: number;
  user_read_level?: number;
  user_vocabulary_level?: number;
  user_role?: string;
  ell_admin_user?: 1;
  ell_teacher_user?: 1;
  ell_student_user?: 1;
};
