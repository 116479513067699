import { useAppDispatch, useAppSelector } from '@/store';
import { authActions } from '@/store/reducers/auth';
import { gameActions } from '@/store/reducers/game';
import { levelTestActions, levelTestSelector } from '@/store/reducers/level-test';
import levelTestThunks from '@/store/thunks/level-test';
import { useNavigate } from 'react-router-dom';

const useInitialLevelTestGame = () => {
  const { activity } = useAppSelector(levelTestSelector);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const startInitialLevelTestGame = async (questionIndex: number) => {
    if (shouldFetchNewQuestions(questionIndex, activity.questions)) {
      dispatch(levelTestActions.setIsLevelTestLoading(true));
      const result = await dispatch(levelTestThunks.postLevelTestResult()).unwrap();

      if (result.userLevelTestState === 'LEVEL_TEST_COMPLETED') {
        handleLevelTestCompletion(result);
      } else if (result.levelTestQuestionsByActivityResponse.questions) {
        loadNewQuestions();
      }
    } else {
      dispatch(levelTestActions.setQuestionIndex(questionIndex));
    }
  };

  const shouldFetchNewQuestions = (questionIndex: number, questions: any[] | undefined) => {
    return questions && questionIndex > questions.length - 1;
  };

  const handleLevelTestCompletion = (result: any) => {
    dispatch(authActions.setProgress(result.userData.progress));
    dispatch(authActions.setUserCoins(100));
    dispatch(levelTestActions.reset());
    dispatch(gameActions.resetGame());
    navigate('/dashboard/level-test');
  };

  const loadNewQuestions = () => {
    dispatch(levelTestActions.reset());
    dispatch(levelTestActions.setIsLevelTestLoading(false));
  };

  return { startInitialLevelTestGame };
};

export default useInitialLevelTestGame;
