import { RootState } from '@/types/common';
import {
  GenerateReportProps,
  StudentReportContent,
  ClassReportContent,
} from '@/types/generate-report';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import reportThunks from '../thunks/report';

const initialState: GenerateReportProps = {
  studentReport: null,
  classReport: null,
};

const report = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: {
    [reportThunks.getStudentReport.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<{ data: StudentReportContent }>
    ) => {
      state.studentReport = payload.data;
    },
    [reportThunks.getClassReport.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<{ data: ClassReportContent }>
    ) => {
      state.classReport = payload.data;
    },
  },
});

export const reportActions = report.actions;

export const reportSelector = (state: RootState) => state.report;

export default report.reducer;
