import { RootState } from '@/types/common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { progressService, skillService } from '@/services';
import { appSettingsActions } from '../reducers/settings';
import apiSource from '@/utils/apiSource';
import { SkillTypes } from '@/types/skill';

const questionsThunk = {
  refreshTopicQuizQuestions: createAsyncThunk(
    'refreshTopicQuizQuestions',
    async (_, { getState, dispatch }) => {
      try {
        const state = getState() as RootState;
        const { selectedType, selectedTopic, selectedQuiz } = state.game;
        const user = state.auth.user;
        const level = user?.progress?.userLevel || 1;
        const { selectedUnit } = state.units;

        if (!user || !selectedTopic || !selectedType || !selectedQuiz) {
          return;
        }
        //FIXME: NEED to remove selectedType === SkillTypes.Listen!!! Solution for DEMO
        const questionsResponse = await skillService.fetchGameActivity(
          user,
          level,
          selectedUnit.id || 1,
          selectedTopic.id,
          selectedQuiz.id
        );
        // selectedType === SkillTypes.Listen
        //   ? await skillService.fetchGameActivity()
        //   : await skillService.fetchQuestions(
        //       selectedType,
        //       user,
        //       selectedTopic.id,
        //       selectedQuiz.id
        //     );
        //FIXME: NEED to remove selectedType === SkillTypes.Listen!!! Solution for DEMO
        const questions = questionsResponse?.questions?.sort((q1: any, q2: any) => {
          if (!q1?.newData || !q2?.newData) {
            return 0;
          }

          return q1.newData.order < q2.newData.order
            ? -1
            : q1.newData.order > q2.newData.order
              ? 1
              : 0;
        });
        // (selectedType === SkillTypes.Listen
        //   ? questionsResponse?.data?.data?.questions
        //   : questionsResponse?.data?.questions) ?? [];

        const progress =
          (
            await progressService.fetchQuestionsProgress(
              selectedType,
              user,
              selectedTopic.id,
              selectedQuiz.id
            )
          )?.data?.data?.questionsAnswers ?? ({} as any);

        for (const question of questions) {
          const questionProgress = progress[question.questionId];

          if (questionProgress) {
            question.userData = questionProgress;
          }

          if (question.imagePath != null) {
            const response = await apiSource.get(question.imagePath);
            question.imageBase64 = response.data as string;
          }
        }

        dispatch(
          appSettingsActions.appendQuestions({
            topicId: selectedTopic.id,
            quizId: selectedQuiz.id,
            questions: questions,
          })
        );

        return questions;
      } catch (error) {
        throw error;
      }
    }
  ),
};

export default questionsThunk;
