import React, { useCallback, useMemo } from 'react';
import classNames from './MyClassesView.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { UseMyClassesInterface } from './UseMyClasses';
import { TeacherGroup } from '@/types/user';
import GradesFilter from '@/components/GradesFilter/GradesFilter';
import Dropdown, { DropdownOption } from '@/components/Dropdown/Dropdown';
import StudentsContent from '@/components/StudentsData/StudentsContent';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import CommunicationProblemModal from '@/components/CommunicationProblemModal/CommunicationProblemModal';

import Navbar from '@/components/Navbar/Navbar';

const MyClassesViewMobile = ({ myClasses }: { myClasses: UseMyClassesInterface }) => {
  const { t } = useTranslation();

  const {
    selectedFilter,
    selectedFilterText,
    allClassrooms,
    filterdClassrooms,
    classConfig,
    onCreateClass,
    createOrSaveClass,
    onFilter,
    onSelectClass,
    showClassCreatedModal,
    onCloseClassCreatedModal,
    showCopyModal,
    setShowCopyModal,
    onCloseCopyModal,
    onCloseCancelModal,
    onShowCopyModal,
    showCancelModal,
    setShowCancelModal,
    onContinue,
    onShowCancelModal,
    user,
    loadingGroupDetails,
    isSavingGroup,
    isCsvLoading,
    isCsvSending,
    setIsCsvLoading,
    csvFilesAdded,
    setCsvFilesAdded,
    showUploadCsvModal,
    showStudentsTableModal,
    onCloseUploadCsvModal,
    onShowStudentsTableModal,
    onBackStudentsTableModal,
    studentsData,
    setStudentsData,
    onCsvStudentsCreate,
    disableSubmitButton,
    setDisableSubmitButton,
    showNewStudentInput,
    setShowNewStudentInput,
    currentClassroom,
    isLoading,
    fetchDataError,
    refreshStudentsData,
    studentsProgress,
    statistics,
    selectedStudent,
    isClassEdit,
    setIsClassEdit,
    searchValue,
    setSearchValue,
    onShowStudentModal,
    onHideStudentModal,
    isStudentDataLoading,
    showStudentDataModal,
    selectedStudentData,
    showStudentEditModal,
    onShowStudentEditModal,
    onCloseStudentEditModal,
    editStudent,
    showUnitData,
    onShowUnitData,
    onHideUnitData,
    selectedUnit,
    onStudentDelete,
    isStudentDeleting,
    showStudentDeleteError,
    setShowStudentDeleteError,
    classStudentsExtraDataResponse,
  } = myClasses;

  const fetchDataErrorModal = (
    <CommunicationProblemModal show={!!fetchDataError} onRefresh={refreshStudentsData} />
  );

  const classSelectorOptions: DropdownOption[] = useMemo(() => {
    return filterdClassrooms.map(classroom => {
      return {
        label: classroom.name,
        value: classroom.id,
        data: classroom,
      };
    });
  }, [filterdClassrooms]);

  const onSelectClassHandler = useCallback(
    (value: any, option: DropdownOption) => {
      onSelectClass(option.data as TeacherGroup);
    },
    [onSelectClass]
  );

  const viewMode =
    isLoading || loadingGroupDetails
      ? 'lode'
      : allClassrooms.length === 0
        ? 'noClasses'
        : 'progress';

  const content =
    viewMode === 'lode' ? (
      <AppLoaderCircle className='mx-auto' />
    ) : viewMode === 'noClasses' ? (
      <></>
    ) : (
      currentClassroom && (
        <StudentsContent
          interfaceLanguage={undefined}
          onHideDataModal={onHideStudentModal}
          onShowDataModal={onShowStudentModal}
          searchValue={searchValue}
          selectedStudent={selectedStudent}
          selectedStudentData={selectedStudentData}
          setSearchValue={setSearchValue}
          showStudentDataModal={showStudentDataModal}
          showAssessments={false}
          statistics={statistics}
          students={studentsProgress}
          studentsExtraData={classStudentsExtraDataResponse}
          currentClassroom={currentClassroom}
          isStudentDataLoading={isStudentDataLoading}
          onCloseEdit={onCloseStudentEditModal}
          onHideUnitData={onHideUnitData}
          onSaveEdit={editStudent}
          onShowEdit={onShowStudentEditModal}
          onShowUnitData={onShowUnitData}
          onShowAssessments={() => {}}
          onHideAssessment={() => {}}
          selectedUnit={selectedUnit}
          showEdit={showStudentEditModal}
          showUnitData={showUnitData}
          setIsClassEdit={setIsClassEdit}
          onStudentDelete={onStudentDelete}
          isStudentDeleting={isStudentDeleting}
          showStudentDeleteError={showStudentDeleteError}
          setShowStudentDeleteError={setShowStudentDeleteError}
        />
      )
    );

  return (
    <div className={classes(classNames.myClassesView, classNames.myClassesMobileView)}>
      {fetchDataErrorModal}
      <Navbar className={classNames.navbar}>
        <div id='my_classes_header' className={classNames.header}>
          <h2>{t('settings.my_classes')}</h2>
          <div className={classNames.filters}>
            <GradesFilter
              selectedFilter={selectedFilter}
              selectedFilterText={selectedFilterText}
              size='s'
              emitFilter={onFilter}
            />
            <Dropdown
              label={`${t('class')}:`}
              value={classConfig?.id}
              options={classSelectorOptions}
              size='s'
              onChange={onSelectClassHandler}
            />
          </div>
        </div>
      </Navbar>
      <div className={classes(classNames.viewBlock, classNames[`viewBlock_${viewMode}`])}>
        {content}
      </div>
    </div>
  );
};

export default React.memo(MyClassesViewMobile);
