import React from 'react';
import { createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const LocaleContext = createContext<null>(null);
export const rtlLangs = ['ar', 'he'];
export const LocaleContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const direction = rtlLangs.includes(i18n.language) ? 'rtl' : 'ltr';

    document.querySelector('html')?.setAttribute('dir', direction);
  }, [i18n.language]);

  return <LocaleContext.Provider value={null}>{children}</LocaleContext.Provider>;
};
