import { SkillColors, SkillHints, SkillImages, SkillTypes } from '@/types/skill';
import UseThemeColors from './UseThemeColors';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import commonUtils from '@/utils/common';

import medalGrammar from '@/assets/svg/medal-grammar.svg';
import medalSpeak from '@/assets/svg/medal-speak.svg';
import medalListen from '@/assets/svg/medal-listen.svg';
import medalRead from '@/assets/svg/medal-read.svg';
import medalSpelling from '@/assets/svg/medal-spelling.svg';
import medalVocabulary from '@/assets/svg/medal-vocabulary.svg';
import UseLocalLang from './UseLocalLang';
import { HintsType } from '@/types/game';
import grammarBg from '@/assets/svg/map/grammar_bg.svg';
import spellingBg from '@/assets/svg/map/spelling_bg.svg';
import vocabularyBg from '@/assets/svg/map/vocabulary_bg.svg';
import readBg from '@/assets/svg/map/read_bg.svg';
import listenBg from '@/assets/svg/map/listen_bg.svg';
import speakBg from '@/assets/svg/map/speak_bg.svg';

const UseSkills = () => {
  const { color } = UseThemeColors();
  const { skillSettings } = useAppSelector(authSelector);
  const localLang = UseLocalLang();

  const skillsAssets: Record<SkillTypes, Omit<SkillImages & SkillColors & SkillHints, 'type'>> = {
    [SkillTypes.Speak]: {
      containerBackgroundColor: color('blue-light4'),
      modalBorderColor: color('blue-dark6'),
      modalInnerBorderColor: color('blue-dark5'),
      medal: medalSpeak,
      hints: [HintsType.ADD_TIME],
      backgroundImage: speakBg,
    },
    [SkillTypes.Listen]: {
      containerBackgroundColor: color('pink-light2'),
      modalBorderColor: color('pink-regular'),
      modalInnerBorderColor: color('pink-dark3'),
      medal: medalListen,
      hints: [HintsType.ADD_TIME, HintsType.REMOVE_ONE_ANSWER, HintsType.SEE_ENGLISH_WORD],
      backgroundImage: listenBg,
    },
    [SkillTypes.Read]: {
      containerBackgroundColor: color('purple-light2'),
      modalBorderColor: color('purple-regular3'),
      modalInnerBorderColor: color('purple-regular2'),
      medal: medalRead,
      hints: [HintsType.ADD_TIME, HintsType.REMOVE_ONE_ANSWER],
      backgroundImage: readBg,
    },
    [SkillTypes.Grammar]: {
      containerBackgroundColor: color('brown-light2'),
      modalBorderColor: color('orange-dark2'),
      modalInnerBorderColor: color('orange-dark'),
      medal: medalGrammar,
      hints: [HintsType.REMOVE_ONE_ANSWER, HintsType.ADD_TIME],
      backgroundImage: grammarBg,
    },
    [SkillTypes.Spelling]: {
      containerBackgroundColor: color('green-light2'),
      modalBorderColor: color('green-dark3'),
      modalInnerBorderColor: color('green-dark2'),
      medal: medalSpelling,
      hints: [
        HintsType.SHOW_LETTER,
        HintsType.SEE_TRANSLATION,
        HintsType.REMOVE_LETTER,
        HintsType.HEAR_ENGLISH_WORD,
      ],
      backgroundImage: spellingBg,
    },
    [SkillTypes.Vocabulary]: {
      containerBackgroundColor: color('blue-light6'),
      modalBorderColor: color('blue-dark4'),
      modalInnerBorderColor: color('blue-dark'),
      medal: medalVocabulary,
      hints: [HintsType.ADD_TIME, HintsType.REMOVE_ONE_ANSWER],
      backgroundImage: vocabularyBg,
    },
  };

  const getSkillName = (type: SkillTypes) => {
    const skillSetting = skillSettings.find(i => i.name?.toLowerCase() === type);

    return skillSetting ? commonUtils.getName(skillSetting, localLang) : '';
  };

  return { skillsAssets, getSkillName };
};

export default UseSkills;
