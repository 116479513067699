import { TeacherGroup } from '@/types/user';
import { GroupStudentProgress } from '@/types/progress';

export enum COLORS {
  grammar = '#ff9f4c',
  read = '#973d9d',
  listen = '#e11560',
  vocabulary = '#145c99',
  spelling = '#2bae19',
  speak = '#05abf4',
}

export type GenerateReportProps = {
  reportType?: ReportTypes;
  currentClassroom?: TeacherGroup;
  currentStudent?: GroupStudentProgress | null;
  studentReport?: StudentReportContent | null;
  classReport?: ClassReportContent | null;
};

export enum ReportTypes {
  class = 'class',
  student = 'student',
}

export type StudentLevelsValueObject = {
  startingLevel: number;
  currentLevel: number;
};
export type ClassLevelsValueObject = { groupCurrentAverageLevel: number };

export type Skills = {
  grammar: number;
  read: number;
  listen: number;
  vocabulary: number;
  spelling: number;
  speak: number;
};

export type ReportContent = StudentReportContent | ClassReportContent;

export type StudentReportContent = {
  userId: string;
  organizationId: string;
  groupId: string;
  userLevel: number;
  overallProgressInLevel: number;
  passRate: number;
  failureRate: number;
  engagement: {
    total: number;
    totalInPrev7Days: number;
    averagePerWeek: number;
  };
  progress: {
    totalActivitiesCompletedInLevel: number;
    totalActivitiesCompletedInTheLast7Days: number;
    averageActivitysPerWeek: number;
  };
  attemptsAndHints: {
    totalHintsUsed: number;
    averageHintsPerActivity: number;
    averageAttemptsPerActivity: number;
  };
  streakLength: {
    longestStreak: number;
    strakLast7Days: number;
    averageStreakPerWeek: number;
  };
  avgSkillsScore: {
    skillId: number;
    skillScore: number;
  }[];
};

export type ClassReportContent = {
  numberOfStudents: number;
  reportDate: string;
  averageActivitiesCompleted: number;
  passRate: number;
  failureRate: number;
  averageSudentsPrograssPerLevel: {
    level: number;
    averageSudentsPrograss: number;
  }[];
  averageTimeSpentPerLevels: {
    level: number;
    averageTimeSpent: number;
  }[];
  averageStreakLengthPerLevel: {
    level: number;
    averageStreakLength: number;
  }[];
  averageActivityAttemptsPerLevel: {
    level: number;
    averageActivityAttempts: number;
  }[];
  averageSkillScore: {
    skillId: number;
    averageScore: number;
  }[];
};
