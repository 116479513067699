import commonUtils from '@/utils/common';
import classNames from './QuizImageIndicator.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { unitSelector } from '@/store/reducers/unit';

interface QuizImageIndicatorProps {
  className?: string;
  starsClassName?: string;
  skillClassName?: string;
  shouldDisplayStars?: boolean;
}

const QuizImageIndicator = ({
  className,
  starsClassName,
  skillClassName,
  shouldDisplayStars = true,
}: QuizImageIndicatorProps) => {
  const { selectedUnit } = useAppSelector(unitSelector);
  const { selectedQuiz, isInEndOfSkillTest, gameType } = useAppSelector(gameSelector);
  const achieivedStars = selectedQuiz?.userData?.cumulativeAchievedStars ?? 0;

  const starsObj =
    selectedQuiz && selectedQuiz.userData?.attempts && achieivedStars > 0
      ? commonUtils.getStarImage(achieivedStars)
      : null;

  let src = '';
  if (selectedQuiz || isInEndOfSkillTest) {
    src = commonUtils.activityImage(gameType, selectedUnit?.order || 1);
  }

  return (
    <div className={classes(classNames.quizImageIndicator, className)}>
      {shouldDisplayStars && starsObj && (
        <img className={classes(classNames.stars, starsClassName)} src={starsObj.img} alt='stars' />
      )}
      <SVG className={classes(classNames.skill, skillClassName)} src={src} />
    </div>
  );
};

export default QuizImageIndicator;
