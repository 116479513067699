import UseScreenSize from '@/hooks/UseScreenSize';
import QuizViewMobile from './QuizViewMobile';
import QuizViewDesktop from './QuizViewDesktop';
import LoaderModal from '@/components/LoaderModal/LoaderModal';
import UseQuiz from './UseQuiz';
import { useEffect } from 'react';
import { useAppDispatch } from '@/store';
import { gameActions } from '@/store/reducers/game';

const QuizView = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = UseScreenSize();
  const quizData = UseQuiz();
  const { selectedQuiz, savingQuizProgressData } = quizData;

  useEffect(() => {
    if (selectedQuiz?.id) {
      dispatch(gameActions.setRevealAPairDisable(false));
    }
  }, [selectedQuiz]);

  return (
    <>
      {isMobile ? <QuizViewMobile quizData={quizData} /> : <QuizViewDesktop quizData={quizData} />}
      <LoaderModal show={savingQuizProgressData} />
    </>
  );
};

export default QuizView;
