import { useAppDispatch, useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { authSelector } from '@/store/reducers/auth';
import { levelTestActions } from '@/store/reducers/level-test';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import commonUtils from '@/utils/common';
import UseSkill from '../skill/UseSkill';
import UseDictionary from '@/hooks/UseDictionary';
import useFetchUnitsWithTopics from '@/hooks/UseUnits';
import UseScreenSize from '@/hooks/UseScreenSize';
import UnitsRoadmapSection from '@/components/UnitsRoadmapSection/UnitsRoadmapSection';
import DictionaryDesktop from '@/components/Dictionary/DictionaryDesktop/DictionaryDesktop';
import DictionaryDesktopComponents from '@/components/Dictionary/DictionaryDesktopComponents/DictionaryDesktopComponents';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import { useSearchParams } from 'react-router-dom';
import UserUtils from '@/utils/user';
import GoToLevelTestDesktop from '@/components/GoToLevelTestDesktop/GoToLevelTestDesktop';
import FinalAssessmentStartPopUp from '@/components/FinalAssessmentStartPopUp/FinalAssessmentStartPopUp';
import WelcomeBanner from '@/components/WelcomeBanner';
import ProgressSection from '@/components/ProgressSection/ProgressSection';
import AppBanner from '@/components/AppBanner';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const MainViewDesktop = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isMobile } = UseScreenSize();

  const {
    searchValue,
    selectedWord,
    recentTranslations,
    autoCompleteResults,
    suggestions,
    cards,
    phrases,
    pictureOfTheDay,
    options,
    question,
    quizzes,
    isSearching,
    onInputChange,
    onClear,
    onSelectOption,
    onSearch,
    onValueSearch,
    onBack,
  } = UseDictionary();

  const { topics, groupedTopics, isLoading: skillsLoading } = UseSkill(true);

  const { selectedType } = useAppSelector(gameSelector);
  const { skillSettings, displayDictionary, user, levels } = useAppSelector(authSelector);
  //FIXME: Need to put level number + add functionality for teacher to switch between levels
  // const getCurentLevel = () => {
  //   const curentLevel = localStorage.getItem('teacher-level');
  //   if (curentLevel === null) return user?.progress?.userLevel;
  //   return +curentLevel;
  // };
  const [selectedLevel, setSelectedLevel] = useState(() => {
    const savedLevel = localStorage.getItem('selectedLevel');
    return savedLevel ? parseInt(savedLevel, 10) : 1;
  });

  const dropdownOptions = levels
    .filter(item => item.id !== 10)
    .map(item => ({
      label: item.name,
      value: item.id,
    }));

  if (isMobile) {
    dropdownOptions.push({
      label: t('levelTest').toString(),
      value: -1,
    });
  }

  const setLevel = useCallback(
    (level: number) => {
      if (level === -1) {
        dispatch(levelTestActions.setActivity(null));
        navigate('/dashboard/level-test');
        return;
      }

      setSelectedLevel(level);
    },
    [navigate, dispatch]
  );

  const [searchParams] = useSearchParams();
  const isTeacher = UserUtils.isTeacher(user);
  const mapRef = useRef<null | HTMLDivElement>(null);
  const currentLevel = useMemo(() => {
    return isTeacher ? selectedLevel : user?.progress.userLevel ?? 1;
  }, [isTeacher, selectedLevel, user?.progress?.userLevel]);

  const { units, isLoading, error } = useFetchUnitsWithTopics(currentLevel);

  useEffect(() => {
    if (!selectedType && skillSettings.length) {
      const firstSkill = skillSettings[0];
      const type = commonUtils.skillByName(firstSkill.name ?? '');

      dispatch(
        gameActions.setSelectedSkill({
          type: type,
          skill: firstSkill,
        })
      );
    }

    if (searchParams.get('skill')) {
      setTimeout(() => {
        executeScroll();
      }, 150);
    }
  }, []);

  const dictionaryComponents = (
    <DictionaryDesktopComponents
      selectedWord={selectedWord}
      cards={cards}
      phrases={phrases}
      question={question}
      pictureOfTheDay={pictureOfTheDay}
      options={options}
      quizzes={quizzes}
      emitBack={onBack}
      onValueSearch={onValueSearch}
    />
  );

  const executeScroll = () => {
    if (mapRef.current !== null) {
      mapRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const handleScrollToMap = () => {
    setTimeout(() => {
      const element = mapRef.current?.lastChild?.firstChild?.firstChild?.firstChild;
      const toFocus = element as HTMLDivElement;
      if (toFocus !== null) {
        toFocus.focus();
      }
    }, 400);
    executeScroll();
  };

  const mainViewComponents = (
    <>
      <UnitsRoadmapSection units={units} mapRef={mapRef} loading={isLoading} />
      {isTeacher && !isMobile && <GoToLevelTestDesktop />}
      {isMobile && (
        <>
          <div style={{ height: '48px' }}></div>
          <AppBanner />
        </>
      )}
    </>
  );

  const content = (
    <>
      <FinalAssessmentStartPopUp />
      {displayDictionary ? (
        <DictionaryDesktop
          searchValue={searchValue}
          recentTranslations={recentTranslations}
          results={autoCompleteResults}
          suggestions={suggestions}
          onInputChange={onInputChange}
          onClear={onClear}
          onSelectOption={onSelectOption}
          onSearch={onSearch}
        />
      ) : (
        <>
          <WelcomeBanner
            selectedLevel={selectedLevel}
            setSelectedLevel={setLevel}
            dropdownOptions={dropdownOptions}
          />
          {!isTeacher && <ProgressSection />}
        </>
      )}
      {isSearching ? (
        <AppLoaderCircle className='mx-auto' />
      ) : selectedWord ? (
        dictionaryComponents
      ) : (
        mainViewComponents
      )}
    </>
  );

  return content;
};

export default MainViewDesktop;
