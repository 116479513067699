import { cognitoService } from '@/services/cognito';
import axios, { type AxiosError } from 'axios';
import commonUtils from './common';
import store from '@/store';
import { authActions } from '@/store/reducers/auth';

const apiSource = axios.create();

apiSource.interceptors.request.use(async config => {
  try {
    if (
      !!localStorage.getItem('token') &&
      commonUtils.shouldTokenRefresh(localStorage.getItem('token') ?? '')
    ) {
      const newToken = await cognitoService.refreshUserSession();
      store.dispatch(authActions.setToken(newToken));
    }

    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  } catch (error) {
    console.error('Error in apiSource request interceptor:', error);
    return Promise.reject(error);
  }
});

export default apiSource;
