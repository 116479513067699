import { toast } from 'react-toastify';
import { LocalizedType } from '@/types/common';
import { ActivityTypes, SkillTypes } from '@/types/skill';
import { Topic } from '@/types/topic';
import { Quiz } from '@/types/quiz';
import config from '@/config';
import { Question } from '@/types/question';

import avatarImages from './avatarImages';

import speak from '@/assets/svg/skills/speak.svg';
import listen from '@/assets/svg/skills/listen.svg';
import read from '@/assets/svg/skills/read.svg';
import grammar from '@/assets/svg/skills/grammar.svg';
import spelling from '@/assets/svg/skills/spelling.svg';
import vocabulary from '@/assets/svg/skills/vocabulary.svg';
import naturalConversation from '@/assets/svg/skills/naturalConversation.svg';
import openWriting from '@/assets/svg/skills/openWriting.svg';
import memory from '@/assets/svg/skills/memory.svg';
import imageToText from '@/assets/svg/skills/imageToText.svg';
import textToImage from '@/assets/svg/skills/textToImage.svg';
import textToText from '@/assets/svg/skills/textToText.svg';
import unitImages from '@/assets/svg/skills';

import stars1 from '@/assets/images/stars-1.png';
import stars2 from '@/assets/images/stars-2.png';
import stars3 from '@/assets/images/stars-3.png';
import stars0 from '@/assets/images/stars-0.png';

import speakIcon from '@/assets/svg/speak-icon.svg';
import listenIcon from '@/assets/svg/listen-icon.svg';
import readIcon from '@/assets/svg/read-icon.svg';
import spellingIcon from '@/assets/svg/spelling-icon.svg';
import grammarIcon from '@/assets/svg/grammar-icon.svg';
import vocabularyIcon from '@/assets/svg/vocabulary-icon.svg';

import diamondBlue from '@/assets/svg/diamond-blue.svg';
import diamondGold from '@/assets/svg/diamond-gold.svg';
import diamondSilver from '@/assets/svg/diamond-silver.svg';

import magnifyingGlass from '@/assets/svg/magnifying-glass.svg';
import brain from '@/assets/svg/brain.svg';
import flag from '@/assets/svg/flag-red.svg';

import en from '@/assets/svg/langs/en.svg';
import he from '@/assets/svg/langs/he.svg';
import pt from '@/assets/svg/langs/pt.svg';
import es from '@/assets/svg/langs/es.svg';
import { GameTypes, NewGameTypes } from '@/types/game';

export const skillsColors = {
  [SkillTypes.Speak]: '#05abf4',
  [SkillTypes.Listen]: '#e11560',
  [SkillTypes.Read]: '#973d9d',
  [SkillTypes.Spelling]: '#2bae19',
  [SkillTypes.Grammar]: '#ff9f4c',
  [SkillTypes.Vocabulary]: '#145c99',
};

const badges = [
  {
    type: SkillTypes.Grammar,
    color: 'orange-regular',
    backgroundColor: 'orange-light',
  },
  {
    type: SkillTypes.Speak,
    color: 'blue-regular',
    backgroundColor: 'blue-light',
  },
  {
    type: SkillTypes.Spelling,
    color: 'green-regular',
    backgroundColor: 'green-light',
  },
  {
    type: SkillTypes.Read,
    color: 'purple-regular',
    backgroundColor: 'purple-light',
  },
  {
    type: SkillTypes.Listen,
    color: 'pink-regular',
    backgroundColor: 'pink-light',
  },
  {
    type: SkillTypes.Vocabulary,
    color: 'blue-light9',
    backgroundColor: 'blue-dark',
  },
];

const progressColors = [
  {
    type: SkillTypes.Speak,
    trailColor: 'blue-light4',
    pathColor: 'blue-regular',
  },
  {
    type: SkillTypes.Listen,
    trailColor: 'pink-light2',
    pathColor: 'pink-regular',
  },
  {
    type: SkillTypes.Read,
    trailColor: 'purple-light2',
    pathColor: 'purple-regular',
  },
  {
    type: SkillTypes.Spelling,
    trailColor: 'green-light2',
    pathColor: 'green-regular',
  },
  {
    type: SkillTypes.Grammar,
    trailColor: 'orange-light2',
    pathColor: 'orange-regular',
  },
  {
    type: SkillTypes.Vocabulary,
    trailColor: 'blue-light9',
    pathColor: 'blue-dark',
  },
];

const newProgressColors = [
  {
    id: 1,
    trailColor: 'orange-light2',
    pathColor: 'orange-regular',
  },
  {
    id: 2,
    trailColor: 'green-light2',
    pathColor: 'green-regular',
  },
  {
    id: 3,
    trailColor: 'pink-light2',
    pathColor: 'pink-regular',
  },
  {
    id: 4,
    trailColor: 'blue-light9',
    pathColor: 'blue-regular',
  },
  {
    id: 5,
    trailColor: 'purple-light2',
    pathColor: 'purple-regular',
  },
  {
    id: 6,
    trailColor: 'blue-light2',
    pathColor: 'blue-dark',
  },
];

const achievements = [
  {
    level: 1,
    img: diamondBlue,
  },
  {
    level: 2,
    img: diamondGold,
  },
  {
    level: 3,
    img: diamondSilver,
  },
  {
    level: 4,
    img: diamondSilver,
  },
  {
    level: 5,
    img: diamondSilver,
  },
];

const levelTestQuizzes = [
  {
    id: 1,
    img: magnifyingGlass,
    name: 'letsBegin',
  },
  {
    id: 2,
    img: brain,
    name: 'keepGoing',
  },
  {
    id: 3,
    img: flag,
    name: 'letsFinish',
  },
];

const skillIcons = {
  speak: speakIcon,
  listen: listenIcon,
  read: readIcon,
  grammar: grammarIcon,
  spelling: spellingIcon,
  vocabulary: vocabularyIcon,
};

const skillImages = {
  speak,
  listen,
  read,
  grammar,
  spelling,
  vocabulary,
  openWriting,
  naturalConversation,
  memory,
  imageToText,
  textToImage,
  textToText,
};

const starsImages = [
  {
    amount: 3,
    img: stars3,
  },
  {
    amount: 2,
    img: stars2,
  },
  {
    amount: 1,
    img: stars1,
  },
  {
    amount: 0,
    img: stars0,
  },
];

const avatarBackgroundColors = ['#64CDFC', '#FC649C', '#F264FC', '#FCAA64', '#5BE548', '#64B6FC'];

const languageIcons = [
  {
    lang: 'en',
    icon: en,
  },
  {
    lang: 'he',
    icon: he,
  },
  {
    lang: 'pt',
    icon: pt,
  },
  {
    lang: 'es',
    icon: es,
  },
];

const commonUtils = {
  sleep: (ms: number) => {
    return new Promise(r => setTimeout(r, ms));
  },
  isMobileDevice: () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    );
  },
  isSafari: () => {
    return (
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') === 0 &&
      !commonUtils.isChrome()
    );
  },
  isChrome: () => {
    return (
      (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) ||
      (/iPhone|iPod/i.test(navigator.userAgent) && /CriOS/i.test(navigator.userAgent))
    );
  },
  isIOS: () => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  },
  showToast: (message: string, bottom?: string) => {
    toast(message, {
      position: 'bottom-center',
      style: {
        bottom,
      },
      autoClose: 4000,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  },
  calcPercentage: (actual: number, total: number) => {
    const percent = (100 * actual) / total;

    return isNaN(percent) ? 0 : Math.round(percent);
  },
  roundNumber: (num: number, decimalPlaces: number = 0) => {
    const decimal = Math.pow(10, decimalPlaces);
    return Math.round(num * decimal) / decimal;
  },
  isSimilar: (sourceString: string, incomingString: string) => {
    if (!incomingString) {
      return false;
    }

    const sourceWords = sourceString.replace(/[^\w\s]+/g, '').split(' ');
    const incomingWords = incomingString.replace(/[^\w\s]+/g, '').split(' ');

    let correctOrderCount = 0;

    for (let i = 0; i < incomingWords.length; i++) {
      if (sourceWords[i] === incomingWords[i]) {
        correctOrderCount++;
      }
    }

    return sourceWords.length - correctOrderCount <= 1;
  },
  isSimilarPhrases: (sourceString: string, incomingString: string) => {
    if (!incomingString) {
      return false;
    }

    let pattern = sourceString
      .toLowerCase()
      .split('/')
      .map(p =>
        p
          .replace(/[^\w\s]+/g, '')
          .replace(/[\s]+/g, ' ')
          .replace(/[_]+/g, '(.)+')
          .trim()
      )
      .map(p => `(${p})`)
      .join('|');
    pattern = `^${pattern}$`;
    const regExp = new RegExp(pattern);

    const incomingWords = incomingString
      .toLowerCase()
      .replace(/[^\w\s]+/g, '')
      .replace(/[_]+/g, ' ')
      .replace(/[\s]+/g, ' ')
      .trim();

    return regExp.test(incomingWords);
  },
  groupBySize: <T>(items: T[], size: number) => {
    const nestedArrays = [];

    for (let i = 0; i < items.length; i += size) {
      const subArray = items.slice(i, i + size);
      nestedArrays.push(subArray);
    }

    return [...nestedArrays];
  },
  shuffleArray: <T>(array: T[]) => {
    const newArray = [...array];

    for (let i = newArray.length - 1; i > 0; i--) {
      const randomIndex = Math.floor(Math.random() * (i + 1));

      [newArray[i], newArray[randomIndex]] = [newArray[randomIndex], newArray[i]];
    }

    return newArray;
  },
  getNumberWithSuffix: (number: number) => {
    let suffix;

    if (number % 10 === 1 && number % 100 !== 11) {
      suffix = 'st';
    } else if (number % 10 === 2 && number % 100 !== 12) {
      suffix = 'nd';
    } else if (number % 10 === 3 && number % 100 !== 13) {
      suffix = 'rd';
    } else {
      suffix = 'th';
    }

    return number + suffix;
  },
  getName: (model: LocalizedType, isLocal = false) => {
    if (isLocal) {
      const nameLocal = model.nameLocal;

      return nameLocal != null && nameLocal.length > 0 ? nameLocal : model.name;
    }

    return model.name;
  },
  getQuizInstructions: (quiz: Quiz, isLocal = false) => {
    if (isLocal && quiz) {
      const instructionsLocal = quiz.instructionsLocal;

      return instructionsLocal != null && instructionsLocal.length > 0
        ? instructionsLocal
        : quiz.instructions;
    }

    return quiz.instructions;
  },
  getQuestionInstructions: (question: Question, quiz?: Quiz | null) => {
    if (question?.instructions) {
      return question.instructions;
    }

    if (quiz) {
      if (typeof quiz.isInstructionsInLocalLanguage === 'boolean') {
        return quiz.isInstructionsInLocalLanguage ? quiz.instructionsLocal : quiz.instructions;
      }

      return quiz.instructions;
    }

    return '';
  },
  getQuestionTip: (question: Question, isLocal = false): string => {
    const newData = question?.newData?.data;
    if (!newData) return '';

    const { questionDataLocal, questionData } = newData;

    if (isLocal && questionDataLocal?.tipLocal) {
      return questionDataLocal.tipLocal || questionData?.tip || '';
    }

    return questionData?.tip || '';
  },
  getAchievement: (level: number) => {
    return achievements.find(i => i.level === level);
  },
  getBadge: (type: SkillTypes) => {
    return badges.find(i => i.type === type);
  },
  getProgressColors: (type: SkillTypes) => {
    return progressColors.find(i => i.type === type);
  },
  getNewProgressColors: (id: number) => {
    return newProgressColors.find(i => i.id === id);
  },
  skillIcon: (type: SkillTypes) => {
    return skillIcons[type] as any;
  },
  skillImage: (type: SkillTypes | ActivityTypes) => {
    return skillImages[type];
  },
  unitImage: (type: any, unitOrder?: number) => {
    return unitImages[`${type}${unitOrder}`];
  },
  activityImage: (type: NewGameTypes | GameTypes | null, unitOrder: number) => {
    const defaultData = commonUtils.unitImage(ActivityTypes.Grammar);

    switch (type) {
      case NewGameTypes.OpenWriting:
        return commonUtils.unitImage(ActivityTypes.OpenWriting, unitOrder);
      case NewGameTypes.Memory:
        return commonUtils.unitImage(ActivityTypes.Memory, unitOrder);
      case NewGameTypes.AudioToImage:
        return commonUtils.unitImage(ActivityTypes.Grammar, unitOrder);
      case NewGameTypes.NaturalConversation:
        return commonUtils.unitImage(ActivityTypes.NaturalConversation, unitOrder);
      case NewGameTypes.ImageToText:
        return commonUtils.unitImage(ActivityTypes.ImageToText, unitOrder);
      case NewGameTypes.TextToImage:
        return commonUtils.unitImage(ActivityTypes.TextToImage, unitOrder);
      case NewGameTypes.TextToText:
        return commonUtils.unitImage(ActivityTypes.TextToText, unitOrder);
      case NewGameTypes.Read:
        return commonUtils.unitImage(ActivityTypes.Read, unitOrder);
      case NewGameTypes.Grammar:
        return commonUtils.unitImage(ActivityTypes.TextToText, unitOrder);
      case NewGameTypes.Vocabulary:
      case GameTypes.Practice:
        return commonUtils.unitImage(ActivityTypes.Vocabulary, unitOrder);
      case GameTypes.ClosedSpelling:
        return commonUtils.unitImage(ActivityTypes.Spelling, unitOrder);
      case NewGameTypes.Listen:
        return commonUtils.unitImage(ActivityTypes.Listen, unitOrder);
      case GameTypes.Pronunciation:
        return commonUtils.unitImage(ActivityTypes.Speak, unitOrder);

      default:
        return defaultData;
    }
  },
  getLevelTestQuiz: (id: number) => {
    return levelTestQuizzes.find(quiz => quiz.id === id);
  },
  getStarImage: (amount: number) => {
    return starsImages.find(star => star.amount === amount);
  },
  getAvatarImages: () => {
    return avatarImages;
  },
  getAvatarImage: (key: string) => {
    return Object.entries(avatarImages).find(entry => entry[0] === key)?.[1];
  },
  getAvatarBackgroundColors: () => {
    return avatarBackgroundColors;
  },
  getLanguageIcon: (lang: string) => {
    return languageIcons.find(i => i.lang === lang)?.icon ?? '';
  },
  fixGradeSymbol: (grade: string) => {
    if (isNaN(+grade)) {
      return parseInt(grade).toString();
    }

    return grade;
  },
  getAllGrades: () => {
    return Array.from({ length: 12 }, (_, index) => index + 1).map(grade =>
      commonUtils.getNumberWithSuffix(grade)
    );
  },
  skillByName: (name: string): SkillTypes => {
    return SkillTypes[name as keyof typeof SkillTypes];
  },
  totalTopicStars: (topic: Topic) => {
    return config.starsPerQuiz * topic.quizzesCount;
  },
  //FIXME: Need to remove + remove in all application. Is outdated
  firstUncompletedTopic: (topics: Topic[]) => {
    return topics.findIndex(topic => !commonUtils.isTopicCompleted(topic));
  },
  isTopicComplited: (allTopicProgress: any, topicOrder: number, topicId: number): boolean => {
    if (allTopicProgress === undefined) {
      return topicOrder === 1;
    }

    const isTopicStarted = allTopicProgress[topicId];

    if (isTopicStarted) {
      return true;
    }

    const completedTopicsKey = Object.keys(allTopicProgress);
    const completedTopicsLength = completedTopicsKey.length;
    const lastCompletedTopic = allTopicProgress[completedTopicsKey[completedTopicsLength - 1]];

    if (lastCompletedTopic?.isAllActivitiesCompleted) {
      return topicOrder === completedTopicsLength + 1;
    }

    return false;
  },
  //FIXME: Need to remove + remove in all application. Is outdated
  isTopicLocked: (topic: Topic, topics: Topic[]) => {
    if (topic === undefined) {
      return false;
    }
    const topicInTopics = topics.findIndex(item => item.id === topic.id);

    return (
      !commonUtils.isTopicCompleted(topic) &&
      topicInTopics !== commonUtils.firstUncompletedTopic(topics)
    );
  },
  //FIXME: Need to remove + remove in all application. Is outdated
  isTopicCompleted: (topic: Topic) => {
    return (topic.userData?.quizzesCompleted ?? 0) >= topic.quizzesCount;
  },
  quizzesCompleted: (quizzes: Quiz[]) => {
    return quizzes.filter(quiz => commonUtils.isQuizCompleted(quiz)).length;
  },
  topicsCompleted: (topics: Topic[]) => {
    return topics.filter(topic => commonUtils.isTopicCompleted(topic)).length;
  },
  quizzesAmount: (topics: Topic[]) => {
    return topics.reduce((acc, topic) => acc + topic.quizzesCount, 0);
  },
  quizzesCompletedCount: (topics: Topic[]) => {
    return topics.reduce((acc, topic) => acc + (topic.userData?.quizzesCompleted ?? 0), 0);
  },
  totalStars: (topic: Topic | null) => {
    return (topic?.quizzesCount ?? 0) * config.starsPerQuiz;
  },
  isActivityComplited: (topicProgress: any, selectedTopicId: number = 0, activityIndex: number) => {
    if (topicProgress === undefined) {
      return activityIndex === 0;
    }

    if (topicProgress[selectedTopicId] === undefined) {
      return activityIndex === 0;
    }

    const completedActivities = topicProgress[selectedTopicId].completedActivitiesCount;

    return activityIndex <= completedActivities;
  },
  //FIXME: Need to remove + remove from all application
  isQuizLocked: (quiz: Quiz, quizzes: Quiz[]) => {
    const quizInQuizzes = quizzes.findIndex(item => item.id === quiz.id);

    return (
      !commonUtils.isQuizCompleted(quiz) &&
      quizInQuizzes !== commonUtils.firstUncompletedQuiz(quizzes)
    );
  },
  isQuizCompleted: (quiz: Quiz) => {
    return quiz.userData?.quizPassed;
  },
  shouldDisplayDictionary: (nativeLanguage: string) => {
    return nativeLanguage !== 'pt';
  },
  firstUncompletedQuiz: (quizzes: Quiz[]) => {
    return quizzes.findIndex(quiz => !commonUtils.isQuizCompleted(quiz));
  },
  sortByKey: (arr: any, key: string) => {
    return arr.sort((a: any, b: any) => a[key] - b[key]);
  },
  generateRandomId: (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  },
  formatText: (text: string) => {
    const italicRegex = /#i#(.*?)#\/i#|\<i\>(.*?)\<\/i\>/g;
    const boldRegex = /#b#(.*?)#\/b#|\<b\>(.*?)\<\/b\>/g;
    const boldRegex2 = /#b#(.*?)#b#/g;

    return text
      .replace(italicRegex, (_, match1, match2) => {
        return `<span style="font-style: italic">${match1 || match2}</span>`;
      })
      .replace(boldRegex, (_, match1, match2) => {
        return `<span style="font-weight: bold">${match1 || match2}</span>`;
      })
      .replace(boldRegex2, (_, match1, match2) => {
        return `<span style="font-weight: bold">${match1 || match2}</span>`;
      });
  },
  formatText_OLD: (text: string) => {
    const parts = text.split(' ');

    return parts
      .map(part => {
        const italicRegex = /#i#(.*?)#\/i#(.*)|\<i\>(.*?)\<\/i\>/;
        const boldRegex = /#b#(.*?)#\/b#(.*)|\<b\>(.*?)\<\/b\>/;

        const italicMatch = part.match(italicRegex);
        const boldMatch = part.match(boldRegex);

        const italicWord = italicMatch?.[1] || italicMatch?.[3];
        const boldWord = boldMatch?.[1] || boldMatch?.[3];

        const italicPunctuation = italicMatch?.[2] || '';
        const boldPunctuation = boldMatch?.[2] || '';

        if (italicWord) {
          return `<span style="font-style: italic">${italicWord}</span>${italicPunctuation}`;
        } else if (boldWord) {
          return `<span style="font-weight: bold">${boldWord}</span>${boldPunctuation}`;
        }

        return part;
      })
      .join(' ');
  },
  formatNumberWithCommas: (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  formatString: (template: string, ...args: any[]) => {
    return template.replace(/{([0-9]+)}/g, function (match, index) {
      return typeof args[index] === 'undefined' ? match : args[index];
    });
  },
  shouldTokenRefresh: (token: string) => {
    try {
      if (token === '') {
        return true;
      }
      // Decode the token to get the payload
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const payload = JSON.parse(atob(base64));

      // Check if the token has an expiration time
      if (!payload.exp) {
        console.error('Token does not contain expiration information.');
        return false;
      }

      // Get the current time in seconds since the Unix epoch
      const currentTime = Math.floor(Date.now() / 1000);

      // Check if the token expires in less than 100 seconds
      return payload.exp - currentTime < 150;
    } catch (e) {
      console.error('Error decoding token:', e);
      return false;
    }
  },
  findLastIndex: <T>(array: T[], predicate: any) => {
    for (let i = array.length - 1; i >= 0; i--) {
      if (predicate(array[i])) {
        return i;
      }
    }
    return -1;
  },
  parseTime(str: string = '') {
    return parseInt(str.replace(/[^0-9]/g, ''), 10);
  },
  calculateAchievedStars(activitiesOverviewPerTopic: any) {
    let result = 0;
    if (typeof activitiesOverviewPerTopic !== 'object') {
      return result;
    }

    const activityKeys = Object.keys(activitiesOverviewPerTopic);
    if (!activityKeys.length) {
      return result;
    } else {
      activityKeys.forEach(activity => {
        result += activitiesOverviewPerTopic[activity]?.cumulativeAchievedStars || 0;
      });

      return result;
    }
  },
  calculateComplitedActivities(activitiesOverviewPerTopic: any) {
    let result = 0;
    if (typeof activitiesOverviewPerTopic !== 'object') {
      return result;
    }

    const activityKeys = Object.keys(activitiesOverviewPerTopic);
    if (!activityKeys.length) {
      return result;
    } else {
      activityKeys.forEach(activity => {
        if (activitiesOverviewPerTopic[activity]?.cumulativeAchievedStars) {
          ++result;
        }
      });

      return result;
    }
  },
  getRandomInt: (from: number, to: number) => {
    return Math.floor(Math.random() * (to - from + 1)) + from;
  },
  getAndRemoveRandomItem: (array: any[]) => {
    const randomIndex = commonUtils.getRandomInt(0, array.length - 1);
    const item = array[randomIndex];
    array.splice(randomIndex, 1);
    return item;
  },
};

export default commonUtils;
