import React from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';

import MyProfileDesktop from '@/components/MyProfile/MyProfileDesktop';
import { useNavigate } from 'react-router-dom';
import MyProfileMobile from '@/components/MyProfile/MyProfileMobile';

const Profile = () => {
  const navigate = useNavigate();
  const { isMobile } = UseScreenSize();

  const onHideProfile = () => {
    navigate('/dashboard/games');
  };

  if (isMobile) {
    return <MyProfileMobile />;
  }

  return <MyProfileDesktop emitHideProfile={onHideProfile} />;
};

export default React.memo(Profile);
