import { SettingItem, SettingItemsObject } from '@/types/common';
import classes from 'classnames';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SettingItems from './SettingItems';
import { useDrawer } from '@/context/DrawerContext';
import { useAppDispatch, useAppSelector } from '@/store';
import { authSelector, authActions, interfaceLanguageSelector } from '@/store/reducers/auth';
import userThunks from '@/store/thunks/user';
import UseLanguages from '@/hooks/UseLanguages';
import UseScreenSize from '@/hooks/UseScreenSize';
import UseLevel from '@/hooks/UseLevel';
import { appSettingsActions } from '@/store/reducers/settings';
import cls from 'classnames';
import SVG from 'react-inlinesvg';

import leftArrow from '@/assets/svg/left-arrow.svg';
import close from '@/assets/svg/close.svg';
import { gameActions } from '@/store/reducers/game';
import UsePasswordChange from './UsePasswordChange';
import AppInput from '@/components/AppInput/AppInput';
import TheButton from '../TheButton/TheButton';
import AppLoaderCircle from '../AppLoaderCircle';
import UserUtils from '@/utils/user';
import { teacherActions } from '@/store/reducers/teacher';

const AppSettings = () => {
  const drawer = useDrawer();
  const { user } = useAppSelector(authSelector);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const useLangs = UseLanguages();
  const { isMobile, isDesktop } = UseScreenSize();
  const { getLevelText } = UseLevel();

  const isStudent = UserUtils.isStudent(user);

  const { isLoading, hasPasswordUpdated, changePasswordFields, onChangePasswordSubmit } =
    UsePasswordChange();

  const currentSettingRef = useRef<SettingItem | null>(null);

  const [settingHistory, setSettingHistory] = useState<SettingItem[]>([]);

  const isSecuritySettingSelected =
    settingHistory[settingHistory.length - 1]?.title === 'settings.security';

  const _setCurrentSetting = (item: SettingItem) => {
    if (currentSettingRef.current) {
      const current = currentSettingRef.current as SettingItem;

      setSettingHistory(prevHistory => [...prevHistory, current]);
    }

    currentSettingRef.current = item;
  };

  const aboutMeItems = () => {
    let progress = [
      {
        title: 'settings.current_level',
        trailingChild: `<h1>${user?.progress?.userLevel} | ${getLevelText(user?.progress?.userLevel)}</h1>`,
      },
    ] as SettingItem[];

    if (user?.metadata.organizationDetails.grade) {
      progress = [
        {
          title: 'settings.grade',
          trailingText: user?.metadata.organizationDetails.grade,
          showCondition: () => !UserUtils.isTeacher(user),
        },
        ...progress,
      ];
    }

    let items: {} = {
      profile: [
        {
          title: 'settings.username',
          trailingText: `${user?.metadata?.firstName} ${user?.metadata.lastName}`,
        },
        {
          title: 'settings.school_id',
          trailingText: user?.metadata.organizationDetails.organizationName,
        },
        {
          title: 'settings.class',
          trailingText: user?.metadata?.organizationDetails?.groupName || '',
          showCondition: () => !UserUtils.isTeacher(user),
        },
        {
          title: 'settings.native_language',
          trailingText: t(
            `languages.${user?.metadata.languagePreferences.nativeLanguage}`
          ) as string,
        },
      ],
    };

    if (isStudent) {
      items = { ...items, progress };
    }

    return {
      title: 'settings.about_me',
      icon: 'user',
      onTap: () => _setCurrentSetting(aboutMeItems()),
      items,
    };
  };

  const changePasswordItems = (): SettingItem => {
    return {
      title: '',
      isForm: true,
      fields: changePasswordFields,
      onSubmit: onChangePasswordSubmit,
    };
  };

  const securityItems = () => {
    return {
      title: 'settings.security',
      onTap: () => {
        _setCurrentSetting(securityItems());
      },
      items: {
        password: [
          {
            title: 'change_password',
            onTap: () => {
              _setCurrentSetting(changePasswordItems());
            },
          },
        ],
      },
    };
  };

  // User language settings
  const learningLanguage = useLangs.getLanguageByCode(
    user?.metadata.languagePreferences.learningLanguage ?? 'en'
  );

  const localLanguages = () => {
    return learningLanguage?.localLanguages.filter(lang => {
      return (
        lang.code == user?.metadata.languagePreferences.learningLanguage ||
        lang.code == user?.metadata.languagePreferences.nativeLanguage
      );
    });
  };

  const interfaceLanguageItems = (): SettingItem => {
    return {
      title: 'interface_language',
      items: {
        suggested_languages: localLanguages()?.map(lang => {
          return {
            title: `languages.${lang.code}`,
            icon: lang.code,
            isFlag: true,
            trailingChild:
              lang.code === user?.metadata.languagePreferences.interfaceLanguage
                ? '<span class="settings-checkmark"><svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><title/><g id="checkmark"><line class="cls-1" x1="3" x2="12" y1="16" y2="25"/><line class="cls-1" x1="12" x2="29" y1="25" y2="7"/></g></svg></span>'
                : '<span></span>',
            onTap: () => {
              dispatch(userThunks.setLanguageSettings(lang.code));
              dispatch(authActions.setInterfaceLanguage(lang.code));
              drawer.closeDrawer();
            },
          };
        }) as SettingItem[],
      },
    };
  };

  const languageItems = () => {
    return {
      title: 'settings.language',
      icon: 'globe',
      onTap: () => {
        _setCurrentSetting(languageItems());
      },
      items: {
        language_of_study: [
          {
            icon: user?.metadata.languagePreferences.learningLanguage,
            isFlag: true,
            title: `languages.${user?.metadata.languagePreferences.learningLanguage}`,
          },
        ],
        interface_language: [
          {
            icon: user?.metadata.languagePreferences.interfaceLanguage,
            isFlag: true,
            title: `languages.${user?.metadata.languagePreferences.interfaceLanguage}`,
            onTap: () => {
              _setCurrentSetting(interfaceLanguageItems());
            },
            items: {},
          },
        ],
      },
    };
  };

  const settingItems = (): SettingItemsObject => {
    return {
      account_settings: [
        aboutMeItems(),
        securityItems(),
        // { title: 'settings.security', icon: 'security', onTap: () => {} },
      ],
      preferences: [languageItems()],
      logout: [
        {
          title: 'settings.logout',
          icon: 'logout',
          hideOnMobile: true,
          onTap: async () => {
            localStorage.removeItem('userEmail');
            dispatch(userThunks.signOut());
            dispatch(authActions.signOut());
            dispatch(appSettingsActions.clearAppSettings());
            dispatch(teacherActions.setStudents([]));
            dispatch(gameActions.resetGame());
          },
        },
      ],
    };
  };

  const defaultSettingItem = () => {
    return {
      title: 'settings.title',
      items: settingItems(),
    };
  };

  const items = () => currentSettingRef.current?.items ?? {};

  const goBackSetting = () => {
    setSettingHistory(prevHistory => {
      const updatedHistory = [...prevHistory];
      const previousSetting = updatedHistory.pop();

      if (previousSetting) {
        currentSettingRef.current = previousSetting;
      } else {
        currentSettingRef.current = defaultSettingItem();
      }

      return updatedHistory;
    });
  };

  useEffect(() => {
    currentSettingRef.current = defaultSettingItem();
    setSettingHistory([]);
  }, [drawer.isDrawerOpen]);

  useEffect(() => {
    if (hasPasswordUpdated) {
      drawer.closeDrawer();
    }
  }, [hasPasswordUpdated]);

  const content =
    currentSettingRef.current?.isForm &&
    currentSettingRef.current.onSubmit &&
    currentSettingRef.current.fields ? (
      <form className='setting-item-form' onSubmit={currentSettingRef.current.onSubmit}>
        {currentSettingRef.current.fields.map(field => (
          <AppInput
            key={field.id}
            className={classes('setting-item-input', {
              'setting-item-input-error': field.error,
            })}
            inputWrapperClassName='setting-item-input-wrapper'
            {...field}
          />
        ))}
        <TheButton className='mt-auto' text={t('save')} type='submit' shouldRecolor={false} />
      </form>
    ) : (
      Object.keys(items()).map((section, index) => {
        const _items = items()[section].filter(item => !item.hideOnMobile);

        if (!_items.length && isMobile) {
          return <div key={index}></div>;
        }

        return <SettingItems key={index} section={section} items={items()[section]} />;
      })
    );

  return (
    <div className='app-settings-wrap'>
      {isLoading ? (
        <AppLoaderCircle className='m-auto' />
      ) : (
        <>
          {isMobile && (
            <div className='app-settings-close'>
              <button onClick={() => drawer.closeDrawer()}></button>
            </div>
          )}
          {isDesktop && isSecuritySettingSelected && (
            <button className='app-settings-security-close' onClick={drawer.closeDrawer}>
              <SVG src={close} />
            </button>
          )}
          {user && (
            <>
              {settingHistory.length > 0 && !isSecuritySettingSelected && (
                <a
                  onClick={goBackSetting}
                  className={cls('app-settings-back', {
                    [`app-settings-back-${interfaceLanguage?.direction}`]: true,
                  })}
                >
                  <SVG src={leftArrow} />
                  {t('back')}
                </a>
              )}
              {currentSettingRef.current && (
                <>
                  <h2>{t(currentSettingRef.current.title)}</h2>
                  {content}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(AppSettings);
