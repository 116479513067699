import { useTranslation } from 'react-i18next';
import classNames from './AddNewStudentForm.module.scss';
import classes from 'classnames';
import { useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import React, { useState, ChangeEvent, useCallback, useEffect } from 'react';
import { registerService } from '@/services/register';
import ValidateStudent from '@/utils/studentDataValidationUtils';
import { CreateCsvStudentsResponse } from '@/types/response';

import TheButton from '../TheButton/TheButton';
import AppInput from '@/components/AppInput/AppInput';
import AppLoaderCircle from '../AppLoaderCircle';
import UploadCsvErrorModal from '@/components/UploadCsvErrorModal/UploadCsvErrorModal';

import { StudentData, StudentValidationError } from '@/types/student-data';
import { useDispatch } from 'react-redux';

interface AddNewStudentFormProps {
  groupId: string;
  isOpen: boolean;
  emitCancel?: () => void;
  emitStudentAdded?: (studentName: string) => void;
}

const AddNewStudentForm = ({
  groupId,
  isOpen,
  emitCancel,
  emitStudentAdded,
}: AddNewStudentFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAppSelector(authSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [newStudent, setNewStudent] = useState<StudentData>({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [validationErrors, setValidationErrors] = useState<StudentValidationError[]>([]);
  const [showAddStudentError, setShowAddStudentError] = useState<boolean>(false);
  const [showInputErrors, setShowInputErrors] = useState<boolean>(false);
  const [showApiErrors, setShowApiErrors] = useState<boolean>(false);

  const showErrors = showInputErrors || showApiErrors;

  useEffect(() => {
    if (isOpen) {
      setNewStudent({ firstName: '', lastName: '', email: '' });
      setValidationErrors([]);
      setShowInputErrors(false);
      setShowApiErrors(false);
    }
  }, [isOpen]);

  const onAddStudent = async () => {
    const errors = ValidateStudent(newStudent);
    if (errors.length) {
      setValidationErrors(errors);
      setShowInputErrors(true);
      return;
    }

    setShowInputErrors(false);
    setIsLoading(true);
    //TODO: Temp fix, should be reworked with teacher dashboard
    try {
      const createCsvStudentsResponse = await registerService.createCsvStudents(user, groupId, [
        newStudent,
      ]);
      dispatch(
        authActions.updatedClassroomStudentsAmount({
          id: groupId,
          amount: createCsvStudentsResponse?.data.data.registeredStudents.length ?? 0,
        })
      );
      processStudentResponse(createCsvStudentsResponse);
    } catch (error) {
      console.error('Error adding student:', error);
      setShowAddStudentError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const processStudentResponse = (response: CreateCsvStudentsResponse | null) => {
    const unregisteredStudents =
      response?.data?.data?.unregisteredStudents.map(student => ({
        ...student,
        isRegistered: false,
      })) || [];

    const registeredStudents =
      response?.data?.data?.registeredStudents.map(student => ({
        ...student,
        isRegistered: true,
      })) || [];

    if (unregisteredStudents.length > 0) {
      setValidationErrors([{ field: 'email', message: 'email_in_use' }]);
      setShowApiErrors(true);
    } else if (registeredStudents.length > 0) {
      setValidationErrors([]);
      resetStudentForm();
    }
  };

  const resetStudentForm = () => {
    const studentName = `${newStudent.firstName} ${newStudent.lastName}`;
    setNewStudent({ firstName: '', lastName: '', email: '' });
    emitStudentAdded?.(studentName);
  };

  const onClose = () => {
    setNewStudent({ firstName: '', lastName: '', email: '' });
    setValidationErrors([]);
    emitCancel?.();
  };

  const shouldDisableBtn = false;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewStudent(prevStudent => ({
      ...prevStudent,
      [name]: value,
    }));
    setShowApiErrors(false);
  };

  const findErrorMessage = useCallback(
    (field: string): string | undefined => {
      const error = validationErrors.find(error => error.field === field);
      return error ? error.message : undefined;
    },
    [validationErrors]
  );

  const handleBlur = (event: ChangeEvent<HTMLInputElement>): void => {
    const errors = ValidateStudent(newStudent);
    setValidationErrors(errors);
  };

  const confirmBtn = (
    <TheButton
      className={classNames.saveBtn}
      text={t('send')}
      disabled={shouldDisableBtn}
      shouldRecolor={false}
      emitOnClick={() => onAddStudent()}
    />
  );

  if (isLoading) {
    return <AppLoaderCircle className={classNames.loader} />;
  }

  return (
    <>
      <div className={classes(classNames.addNewStudentForm, classNames.addNewStudentFormDrawer)}>
        <div className={classNames.body}>
          <div className={classNames.inputs}>
            {['firstName', 'lastName', 'email'].map((field, index) => (
              <React.Fragment key={index}>
                <AppInput
                  className={`${classNames.input}`}
                  inputWrapperClassName={`${classNames.inputWrapperClassName} ${findErrorMessage(field) && showErrors ? classNames.inputWrapperError : ''}`}
                  id={field}
                  name={field}
                  label={t(field.toLowerCase()) ?? ''}
                  value={newStudent[field]}
                  emitChange={handleInputChange}
                  emitBlur={handleBlur}
                />
              </React.Fragment>
            ))}
          </div>
          <div className={classNames.errorSection}>
            {validationErrors.length > 0 &&
              showErrors &&
              validationErrors.map((error, index) => (
                <span key={index}>{t(`settings.add_students.errors.${error.message}`)}</span>
              ))}
          </div>
          <div className={classNames.controls}>
            <TheButton plain text={t('cancel')} emitOnClick={onClose} />
            {confirmBtn}
          </div>
        </div>
      </div>
      <UploadCsvErrorModal
        show={showAddStudentError}
        onClose={() => {
          setShowAddStudentError(false);
        }}
      />
    </>
  );
};

export default AddNewStudentForm;
