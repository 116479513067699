import { ROUTE_NAMES } from '@/router';
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export type CurrentRouteContext = {
  currentRoute: ROUTE_NAMES;
};

export const CurrentRouteContext = createContext<CurrentRouteContext | null>(null);

export const CurrentRouteProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState<ROUTE_NAMES>(ROUTE_NAMES.GAMES);

  const locationMapping: {
    [key: string]: ROUTE_NAMES;
  } = {
    '/login': ROUTE_NAMES.LOGIN,
    '/registration': ROUTE_NAMES.REGISTRATION,
    '/onboarding': ROUTE_NAMES.ONBOARDING,
    '/not-found': ROUTE_NAMES.NOT_FOUND,
    '/dashboard/games': ROUTE_NAMES.GAMES,
    '/dashboard/level-test': ROUTE_NAMES.LEVEL_TEST,
    '/dashboard/skill': ROUTE_NAMES.SKILL,
    '/dashboard/quiz': ROUTE_NAMES.QUIZ,
    '/dashboard/my-students': ROUTE_NAMES.MY_STUDENTS,
    '/dashboard/my-classes': ROUTE_NAMES.MY_CLASSES,
    '/dashboard/profile': ROUTE_NAMES.PROFILE,
  };

  useEffect(() => {
    Object.keys(locationMapping).forEach((path: string) => {
      if (location.pathname.includes(path)) {
        setCurrentRoute(locationMapping[path]);
      }
    });
  }, [location.pathname]);

  return (
    <CurrentRouteContext.Provider value={{ currentRoute }}>{children}</CurrentRouteContext.Provider>
  );
};
