import classNames from './MyProfile.module.scss';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
//TODO: Need to understand if it's needed
// import { SkillTypes } from '@/types/skill';
// import { Topic } from '@/types/topic';
import UseMyProfile from '@/hooks/UseMyProfile';

import Navbar from '../Navbar/Navbar';
import UserInfo from '../UserInfo/UserInfo';
//TODO: Need to understand if it's needed
// import ProfileInfo from '../ProfileInfo/ProfileInfo';
import UserStreak from '../UserStreak/UserStreak';
//TODO: Need to understand if it's needed
// import AchievementsCarousel from './AchievementsCarousel/AchievementsCarousel';
// import ProfileProgress from '../ProfileProgress/ProfileProgress';
// import BadgesSection from '../BadgesSection/BadgesSection';
import QuizzesSection from '../QuizzesSection/QuizzesSection';
import ProfileSkillsProgress from '../ProfileSkillsProgress/ProfileSkillsProgress';
import ProfileCard from './ProfileCard/ProfileCard';
import UseCurrentRoute from '@/hooks/UseCurrentRoute';
import { ROUTE_NAMES } from '@/router';
import MyProgress from './MyProgress/MyProgress';
import AppLoaderCircle from '../AppLoaderCircle';
import { useState } from 'react';
import StudentDataModal from '../StudentData/StudentDataModal';
import { unitSelector } from '@/store/reducers/unit';
import Badge from '@/components/UserInfo/Badge/Badge';
import blueStar from '@/assets/svg/star.svg';
import coin from '@/assets/svg/coin.svg';

const MyProfileMobile = () => {
  const { user, userProgress } = useAppSelector(authSelector);
  // //TODO: Need to understand if it's needed: allActual, allTotal, levelAchievements,
  // const { skillsOverview } = UseMyProfile();
  const currentRoute = UseCurrentRoute();

  const { units, topics } = useAppSelector(unitSelector);

  const [showModal, setShowModal] = useState(false);
  const [unitId, setUnitId] = useState(0);

  const handleProgressCardClick = (id: number) => {
    setUnitId(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const { isLoading, unitsProgress, streak } = UseMyProfile();

  return (
    <div className={classNames.myProfileMobile}>
      <Navbar showArrowIcon={currentRoute === ROUTE_NAMES.PROFILE} className={classNames.navbar}>
        <div className={classNames.header}>
          {user && (
            <>
              <UserInfo
                fullname={`${user.metadata.firstName} ${user.metadata.lastName}`}
                profileImage={user.metadata.userSettings.avatar?.avatarName}
                backgroundColor={user.metadata.userSettings.avatar?.avatarColor}
                notificationsAmount={3}
                level={user.progress?.userLevel}
                grade={user?.metadata.organizationDetails.grade.toString()}
              />
              <div className={classNames.badges}>
                <Badge type='stars' image={blueStar} amount={user?.progress?.achievedStars} />
                <Badge type='coins' image={coin} amount={user.progress?.coins} />
              </div>
            </>
          )}
        </div>
      </Navbar>
      {isLoading && (
        <div className={classNames.myProfileMobileLoading}>
          <AppLoaderCircle />
        </div>
      )}
      {!isLoading && (
        <div className={classNames.myProfileMobileContent}>
          <ProfileCard
            className={classNames.profileCard}
            classroom={user?.metadata.organizationDetails?.groupName}
            level={user?.progress?.userLevel}
            grade={user?.metadata.organizationDetails.grade}
            school={user?.metadata.organizationDetails.organizationName}
            country={user?.metadata.organizationDetails.country}
            streak={streak}
          />
          <MyProgress
            className={classNames.myProgress}
            unitsProgress={unitsProgress}
            onProgressCardClick={handleProgressCardClick}
          />
        </div>
      )}
    </div>
  );
};

export default MyProfileMobile;
