import { useCallback, useEffect, useState } from 'react';
import classes from 'classnames';
import classNames from './MTextarea.module.scss';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import { useTranslation } from 'react-i18next';

type MTextareaProps = {
  value: string;
  isLoading: boolean;
  onChangeValue: (text: string) => void;
};

const MTextarea = ({ value = '', isLoading = false, onChangeValue }: MTextareaProps) => {
  const { t } = useTranslation();
  const [wordCount, setContent] = useState(0);

  const setFormattedContent = useCallback(
    (text: string) => {
      const words = text
        //Replace all kind of line breaks with normal "space"
        .replace(/(\r\n|\n|\r)/gm, ' ')
        .split(' ')
        .filter(Boolean);
      setContent(words.length);
      onChangeValue(text);
    },
    [setContent]
  );

  useEffect(() => {
    if (value === '') {
      setContent(0);
    }
  }, [value]);

  return (
    <div className={classes(classNames.MTextArea)}>
      {isLoading ? (
        <div className={classNames.isLoading}>
          <AppLoaderCircle />
          <p>{t('weAreAnalyzing')}</p>
        </div>
      ) : (
        <>
          <textarea onChange={event => setFormattedContent(event.target.value)} value={value} />
          <p>{t('wordCount', { wordCount })}</p>
        </>
      )}
    </div>
  );
};

export default MTextarea;
