import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { NewGameTypes } from '@/types/game';
import classes from 'classnames';
import SVG from 'react-inlinesvg';

import { MultiChoiceOptionStatusProps } from './types';

import correct from '@/assets/svg/correct-circle.svg';
import wrong from '@/assets/svg/wrong-circle.svg';

import classNames from '../MultiChoiceOptions.module.scss';
import { unitSelector } from '@/store/reducers/unit';

const MultiChoiceOptionStatus = (props: MultiChoiceOptionStatusProps) => {
  const { isOptionSelected, isOptionCorrect, isDictionary, alphabet } = props;
  const { selectedType, gameQuestions, gameType, isLevelTest } = useAppSelector(gameSelector);
  const { selectedUnit } = useAppSelector(unitSelector);
  //FIXME: NEED TO REPLACE WITH CORRECT GAMETYPE
  const TEMP_GAMETYPE = (gameQuestions[0]?.gameType as any) || 8;

  const selectedGameType = isLevelTest ? 'leveltest' : selectedUnit?.order || 1;

  //FIXME: This area is for new game types with new API. Need to make in correct way
  if (TEMP_GAMETYPE === NewGameTypes.AudioToImage || TEMP_GAMETYPE === NewGameTypes.TextToImage) {
    return (
      <>
        {!isOptionSelected ? (
          isOptionCorrect && <SVG src={correct} className={classNames.answerStatusImg} />
        ) : (
          <div className={classes(classNames.answerStatus, classNames.answerStatusImgWrapper)}>
            <SVG src={isOptionCorrect ? correct : wrong} className={classNames.answerStatusImg} />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {!isOptionSelected ? (
        <span
          className={classes(classNames.alphabet, {
            [classNames[`alphabet-${selectedGameType}`]]: !isDictionary,
            [classNames.alphabetAnswer]: isOptionCorrect,
          })}
        >
          {alphabet}
        </span>
      ) : (
        <div className={classNames.answerStatus}>
          <SVG src={isOptionCorrect ? correct : wrong} />
        </div>
      )}
    </>
  );
};

export default MultiChoiceOptionStatus;
