import classNames from '../ProgressSection.module.scss';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { ProgressIcon } from './ProgressIcon';

type ProgressInfoProps = {
  level: number;
  icon: string;
  percentage: number;
};

export const ProgressInfo = ({ level, icon, percentage }: ProgressInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className={classNames.progressSection}>
      <ProgressIcon level={level} />
      <div className={classNames.progressSection}>
        <div className={classNames.progressInfo}>
          <p className={classNames.progressLabel}>
            <SVG className={classNames.badgeSmall} src={icon} />
            <span>{t('completedActivities')}</span>
            <span className={classNames.percentage}>{percentage}%</span>
          </p>
          <div className={classNames.progressBar}>
            <div className={classNames.progress} style={{ width: `${percentage}%` }}>
              <svg
                className={classNames.progressStripes}
                xmlns='http://www.w3.org/2000/svg'
                width='100%'
                height='100%'
                preserveAspectRatio='none'
              >
                <defs>
                  <pattern
                    id='stripe-pattern'
                    width='4'
                    height='4'
                    patternUnits='userSpaceOnUse'
                    patternTransform='rotate(45)'
                  >
                    <line x1='0' y1='0' x2='0' y2='4' stroke='#2BAE19' strokeWidth='2' />
                  </pattern>
                </defs>
                <rect width='100%' height='100%' fill='url(#stripe-pattern)' />
              </svg>
            </div>
            <div className={classNames.progressCap} style={{ width: `${percentage}%` }}>
              <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' fill='none'>
                <defs>
                  <pattern
                    id='stripe-pattern'
                    width='10'
                    height='10'
                    patternUnits='userSpaceOnUse'
                    patternTransform='rotate(45)'
                  >
                    <line x1='0' y1='0' x2='0' y2='10' stroke='#2BAE19' strokeWidth='4' />
                  </pattern>
                </defs>
                <rect width='100%' height='100%' fill='url(#stripe-pattern)' />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
