import React, { useEffect, useState, useMemo, useCallback } from 'react';
import classNames from './EditContainer.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import commonUtils from '@/utils/common';
import { validateFirstName, validateLastName } from '@/utils/studentDataValidationUtils';

import TheButton from '@/components/TheButton/TheButton';
import Dropdown, { DropdownOption } from '@/components/Dropdown/Dropdown';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import Field from './field';
import TextField from './textField';
import { RevertsData } from '../RevertUser/RevertUser';
import deleteIcon from '@/assets/svg/delete.svg';

interface EditContainerProps {
  interfaceLanguage: string;
  nativeLanguage: string;
  firstName: string;
  lastName: string;
  email: string;
  emitSaveEdit?: (firstName: string, lastName: string, language: string) => Promise<void>;
  emitCloseEdit?: () => void;
  emitDeleteStudent?: () => void;
  openRevertsDataPopup: (revertsData: RevertsData) => void;
  isLoadingProgress: boolean;
}

const EditContainer = ({
  interfaceLanguage,
  nativeLanguage,
  firstName,
  lastName,
  email,
  emitSaveEdit,
  emitCloseEdit,
  openRevertsDataPopup,
  emitDeleteStudent,
  isLoadingProgress,
}: EditContainerProps) => {
  const { t } = useTranslation();
  const [revertsDataState, setRevertsDataState] = useState<RevertsData>();
  const [interfaceLanguageState, setInterfaceLanguageState] = useState<string>('');
  const [firstNameState, setFirstNameState] = useState<string>('');
  const [lastNameState, setLastNameState] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [saveError, setSaveError] = useState<string>('');

  useEffect(() => {
    const revertsData = {
      interfaceLanguage: interfaceLanguage ?? '',
      firstName: firstName ?? '',
      lastName: lastName ?? '',
    };
    setInterfaceLanguageState(revertsData.interfaceLanguage);
    setFirstNameState(revertsData.firstName);
    setLastNameState(revertsData.lastName);
    setRevertsDataState(revertsData);
  }, []);

  useEffect(() => {
    const error = validateFirstName(firstNameState);
    setFirstNameError(error ? error.message : '');
  }, [firstNameState]);

  useEffect(() => {
    const error = validateLastName(lastNameState);
    setLastNameError(error ? error.message : '');
  }, [lastNameState]);

  useEffect(() => {
    setSaveError('');
  }, [firstNameState, lastNameState, interfaceLanguageState]);

  const onSave = useCallback(() => {
    setSaveError('');
    emitSaveEdit?.(firstNameState, lastNameState, interfaceLanguageState)
      .then(() => {
        emitCloseEdit?.();
        if (revertsDataState) {
          openRevertsDataPopup(revertsDataState);
        }
      })
      .catch(() => {
        setSaveError('anErrorOccurred');
      });
  }, [
    emitSaveEdit,
    emitCloseEdit,
    openRevertsDataPopup,
    revertsDataState,
    firstNameState,
    lastNameState,
    interfaceLanguageState,
  ]);

  const onDeleteStudent = useCallback(() => {
    emitDeleteStudent?.();
  }, [emitDeleteStudent]);

  const languageDropdownOptions: DropdownOption[] = useMemo(() => {
    return ['en', nativeLanguage].map(language => {
      return {
        value: language,
        label: (
          <div className={classNames.languageOption}>
            <SVG src={commonUtils.getLanguageIcon(language)} />
            <span>{t(language)}</span>
          </div>
        ),
      };
    });
  }, [nativeLanguage, t]);

  return (
    <div className={classNames.editContainer}>
      <div className={classes(classNames.section, classNames.grid)}>
        <Field label={t('firstname').toString()}>
          <TextField value={firstNameState} error={firstNameError} onChange={setFirstNameState} />
        </Field>
        <Field label={t('lastname').toString()}>
          <TextField value={lastNameState} error={lastNameError} onChange={setLastNameState} />
        </Field>
        {email && (
          <Field label={t('email').toString()} className={classNames.gridCell2}>
            <TextField value={email} disabled={true} />
          </Field>
        )}
      </div>

      <div className={classNames.sectionTitle}>{t('language')}</div>
      <div className={classNames.section}>
        <div className={classNames.selects}>
          <Field label={t('interfaceLanguage').toString()}>
            <Dropdown
              value={interfaceLanguageState}
              options={languageDropdownOptions}
              onChange={language => {
                setInterfaceLanguageState(language);
              }}
              type='field'
            />
          </Field>
        </div>
      </div>

      <div className={classNames.errorSection}>
        {firstNameError && <span>{t(`settings.add_students.errors.${firstNameError}`)}</span>}
        {lastNameError && <span>{t(`settings.add_students.errors.${lastNameError}`)}</span>}
        {saveError && <span>{t(saveError)}</span>}
      </div>

      <div className={classNames.buttons}>
        <button className={classNames.deleteButton} onClick={onDeleteStudent}>
          <SVG src={deleteIcon} />
          <span>{t('settings.delete_student.deleteStudent')}</span>
        </button>

        {isLoadingProgress ? (
          <div className={classNames.loader}>
            <AppLoaderCircle />
          </div>
        ) : (
          <TheButton
            className={classNames.saveButton}
            text={t('save')}
            shouldRecolor={false}
            emitOnClick={onSave}
            disabled={
              !(firstNameState && lastNameState && interfaceLanguageState) || isLoadingProgress
            }
          />
        )}
      </div>
    </div>
  );
};

export default EditContainer;
