import classes from 'classnames';
import classNames from './SkillTopic.module.scss';
import { Road, Topic } from '@/types/topic';
import SVG from 'react-inlinesvg';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { SkillTypes } from '@/types/skill';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';
import flag from '@/assets/svg/red_flag.svg';

import TopicInfo from './TopicInfo/TopicInfo';
import UserUtils from '@/utils/user';

interface SkillTopicProps {
  topic: Topic;
  road?: Road;
  isLocked: boolean;
  isComplete: boolean;
  index: number;
  emitOpenModal: (topicId: number) => void;
}

const SkillTopic = ({
  topic,
  isLocked,
  isComplete,
  road,
  index,
  emitOpenModal,
}: SkillTopicProps) => {
  const { selectedType } = useAppSelector(gameSelector);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const { user } = useAppSelector(authSelector);

  const isVocabulary = selectedType === SkillTypes.Vocabulary;

  const isTeacher = UserUtils.isTeacher(user);

  const roadClasses = classes(classNames.roadImg, {
    [`road-${selectedType}`]: true,
    [`road-${selectedType}-locked`]: isLocked && !isVocabulary && !isTeacher,
  });

  return (
    <div
      className={classes(
        classNames.skillTopic,
        [classNames[`skillTopic-${interfaceLanguage?.direction}`]],
        classNames[`road-${road?.order}`],
        [classNames[`road-${road?.order}-${interfaceLanguage?.direction}`]]
      )}
    >
      <SVG className={roadClasses} src={road?.img ?? ''} />
      {topic.id === 0 ? (
        <SVG src={flag} className={classes(classNames.topicInfo, classNames.flag)} />
      ) : (
        <TopicInfo
          className={classes(classNames.topicInfo, {
            [classNames.completed]: isComplete,
            [classNames.locked]: isLocked && !isVocabulary && !isTeacher,
          })}
          topic={topic}
          isLocked={isLocked && !isVocabulary && !isTeacher}
          isComplete={isComplete}
          index={index}
          emitOpenModal={emitOpenModal}
        />
      )}
    </div>
  );
};

export default SkillTopic;
