import classNames from './QuizzesList.module.scss';
import classes from 'classnames';
import { SkillTypes } from '@/types/skill';
import commonUtils from '@/utils/common';
import UseThemeColors from '@/hooks/UseThemeColors';
import { useAppDispatch, useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { appSettingsSelector } from '@/store/reducers/settings';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { QuizStarter } from '@/types/progress';

import QuizItem from '../QuizzesSection/QuizItem/QuizItem';
import SectionHeader from '../SectionHeader/SectionHeader';

import medalGold from '@/assets/svg/medal-gold.svg';
import quizzesThunks from '@/store/thunks/quizzes';
import UseScreenSize from '@/hooks/UseScreenSize';

interface QuizzesListProps {
  className?: string;
  title: string;
  quizzesList: QuizStarter[];
}

const QuizzesList = ({ className, title, quizzesList }: QuizzesListProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { color } = UseThemeColors();

  const { skillSettings } = useAppSelector(authSelector);
  const { topics, quizzes } = useAppSelector(appSettingsSelector);
  const { selectedTopic } = useAppSelector(gameSelector);
  const { isMobile } = UseScreenSize();

  const [selectedQuizId, setSelectedQuizId] = useState<number | null>(null);

  const onNextQuiz = async (type: SkillTypes | null) => {
    if (type) {
      if (type === SkillTypes.Vocabulary) {
        if (isMobile) {
          navigate('/dashboard/skill/vocabulary');
        } else {
          const skill = skillSettings.find(i => i.name?.toLocaleLowerCase() === type) ?? null;

          dispatch(gameActions.setSelectedSkill({ type, skill }));
          navigate(`/dashboard/games?skill=${skill?.name?.toLowerCase()}`);
        }

        return;
      }

      const quizByType = quizzesList.find(i => i.skill === type) ?? null;
      const skill = skillSettings.find(i => i.name?.toLocaleLowerCase() === type) ?? null;
      const topic = topics[type].find(i => i.id === quizByType?.topicId) ?? null;

      dispatch(gameActions.setSelectedSkill({ type, skill }));
      dispatch(gameActions.setSelectedTopic(topic));

      const topicQuizzes = topic
        ? quizzes[topic.id] ?? (await dispatch(quizzesThunks.refreshTopicQuizzes() as any).unwrap())
        : [];

      const selectedQuiz = topic
        ? topicQuizzes.find(i => i.id === quizByType?.quizId) ?? null
        : null;

      dispatch(gameActions.setSelectedQuiz(selectedQuiz));
      navigate('/dashboard/quiz');
    } else {
      return;
    }
  };

  // const onStartQuiz = async (
  //   type: SkillTypes,
  //   topicId: number,
  //   quizId: number
  // ) => {
  // const skill =
  //   skillSettings.find((i) => i.name?.toLocaleLowerCase() === type) ?? null;
  // const topic = topics[type].find((i) => i.id === topicId) ?? null;
  // console.log(topic);
  // dispatch(gameActions.setSelectedSkill({ type, skill }));
  // dispatch(gameActions.setSelectedTopic(topic));
  // setSelectedQuizId(quizId);
  // };

  const refreshTopicQuizzes = async () => {
    await dispatch(quizzesThunks.refreshTopicQuizzes()).unwrap();
  };
  useEffect(() => {
    if (selectedTopic && quizzes[selectedTopic.id] && Number.isInteger(selectedQuizId)) {
      const selectedQuiz = quizzes[selectedTopic.id].find(i => i.id === selectedQuizId) ?? null;

      dispatch(gameActions.setSelectedQuiz(selectedQuiz));

      setSelectedQuizId(null);
      navigate('/dashboard/quiz');
    }
  }, []);

  useEffect(() => {
    refreshTopicQuizzes();
  }, []);

  const quizzesElements = [...quizzesList]
    .sort((curr, next) => curr.order - next.order)
    .map((quiz, index) => {
      const { achievedStars, order, quizId, quizName, skill, topicId, topicName } = quiz;
      const badge = commonUtils.getBadge(skill);

      const userScore = achievedStars ?? 0;

      return (
        <QuizItem
          key={index}
          medalImg={medalGold}
          completedLevelsAmount={userScore}
          order={order}
          topic={topicName}
          title={quizName}
          skill={skill}
          shouldDisplayBadge={Boolean(userScore)}
          badgeColor={color(badge?.color ?? '') ?? ''}
          badgeBackgroundColor={color(badge?.backgroundColor ?? '') ?? ''}
          emitStartQuiz={() => onNextQuiz(skill)}
        />
      );
    });

  return (
    <div className={classes(classNames.quizzesList, className)}>
      <SectionHeader title={title} />
      <div className='flex flex-col gap-4 mt-6'>{quizzesElements}</div>
    </div>
  );
};

export default QuizzesList;
