import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { useState } from 'react';
import { authSelector } from '@/store/reducers/auth';
import { Quiz } from '@/types/quiz';
import commonUtils from '@/utils/common';
import UseScreenSize from './UseScreenSize';
import { appSettingsSelector } from '@/store/reducers/settings';
import quizzesThunks from '@/store/thunks/quizzes';
import { TopicTypes } from '@/types/topic';

const UseTopicQuizzes = () => {
  const dispatch = useAppDispatch();
  const { isMobileSmall } = UseScreenSize();
  const quizzesPerPage = isMobileSmall ? 8 : 9;
  const { selectedTopic, selectedType } = useAppSelector(gameSelector);
  const { user } = useAppSelector(authSelector);
  const { quizzes } = useAppSelector(appSettingsSelector);
  const [loadingQuizzes, setLoadingQuizzes] = useState(false);
  const [quizzesGroups, setQuizzesGroups] = useState<Quiz[][] | []>([]);
  const [sortedQuizzes, setSortedQuizzes] = useState<Quiz[] | []>([]);

  const fetchTopicMetadata = async () => {
    if (!selectedTopic || !user || !selectedType) {
      return;
    }

    setQuizzesGroups([]);
    setLoadingQuizzes(true);

    try {
      const topicQuizzes =
        quizzes[selectedTopic.id] ?? (await dispatch(quizzesThunks.refreshTopicQuizzes()).unwrap());

      const _sortedQuizzes = commonUtils.sortByKey([...topicQuizzes], 'order');
      setSortedQuizzes(_sortedQuizzes);
      setQuizzesGroups(commonUtils.groupBySize(_sortedQuizzes, quizzesPerPage));

      setLoadingQuizzes(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedTopic?.topicType !== TopicTypes.EndOfSkillTest) {
      fetchTopicMetadata();
    }
  }, [selectedTopic, quizzes]);

  return { quizzesGroups, sortedQuizzes, quizzesPerPage, loadingQuizzes };
};

export default UseTopicQuizzes;
