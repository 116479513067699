import ReactDOM from 'react-dom';
import classes from 'classnames';

export interface BackdropProps {
  className?: string;
  style?: {};
  emitClose?: () => void;
}

const AppBackdropPortal = ({ className, style, emitClose }: BackdropProps) => {
  return (
    <div className={classes('app-backdrop', className)} style={style} onClick={emitClose}></div>
  );
};

const AppBackdrop = ({ className, style, emitClose }: BackdropProps) => {
  const backdropPortalElement = document.getElementById('backdrop_portal') as Element;

  const backdropPortal = ReactDOM.createPortal(
    <AppBackdropPortal className={className} style={style} emitClose={emitClose} />,
    backdropPortalElement
  );

  return <>{backdropPortal}</>;
};

export default AppBackdrop;
