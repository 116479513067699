import classes from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from './UnitsRoadmapCarousel.module.scss';
import { Topic, UnitAssets } from '@/types/units';
import SVG from 'react-inlinesvg';
import UseUnitsAssets from '@/hooks/UseUnitsAssets';
import { useAppDispatch, useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';

import TopicInfo from '@/components/TopicInfo/TopicInfo';
import AppLoaderCircle from '@/components/AppLoaderCircle';

import previousArrow from '@/assets/svg/carousel-previous-arrow.svg';
import nextArrow from '@/assets/svg/carousel-next-arrow.svg';
import flag from '@/assets/svg/red_flag.svg';
import { useTranslation } from 'react-i18next';
import { useDrawer } from '@/context/DrawerContext';
import { unitActions, unitSelector } from '@/store/reducers/unit';
import UserUtils from '@/utils/user';
import commonUtils from '@/utils/common';
import UseScreenSize from '@/hooks/UseScreenSize';

const scrollTemp = 10;

interface UnitsRoadmapCarouselProps {
  unitId: number;
  unitOrder: number;
  topics: Topic[];
  loading: boolean;
  groupedTopics: Topic[][];
  emitOpenModal: (topicId: number) => void;
}

const UnitsRoadmapCarousel = ({
  unitId,
  unitOrder,
  emitOpenModal,
  topics,
  loading,
  groupedTopics,
}: UnitsRoadmapCarouselProps) => {
  const drawer = useDrawer();
  const dispatch = useAppDispatch();
  const { isMobile } = UseScreenSize();
  const { unitsAssets } = UseUnitsAssets();
  const { selectedType } = useAppSelector(gameSelector);
  const { user } = useAppSelector(authSelector);
  const { selectedUnit } = useAppSelector(unitSelector);
  const isTeacher = UserUtils.isTeacher(user);
  const { t } = useTranslation();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const unitAssets: UnitAssets = unitsAssets[selectedUnit?.order || 1];

  const [containerWidth, setContainerWidth] = useState(0);
  const [showPreviousButton, setShowPreviousButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [scrollStep, setScrollStep] = useState(0);

  const containerRef = useRef<HTMLDivElement>();
  const contresizeObserverRef = useRef<ResizeObserver>();

  useEffect(() => {
    if (containerRef.current) {
      setNavigationButtons();
      containerRef.current.scrollTo({
        left: 0,
        behavior: 'auto',
      });
    }
  }, [selectedType, unitId]);

  useEffect(() => {
    setNavigationButtons();
  }, [topics, loading]);

  useEffect(() => {
    let timer: any = null;
    let canScroll = scrollStep !== 0;
    const scrollDeltaTemp = scrollTemp * scrollStep;
    const scrollStert = containerRef?.current?.scrollLeft || 0;
    let scrollDelta = 0;

    const scroll = () => {
      if (!canScroll) {
        return;
      }

      scrollDelta += scrollDeltaTemp;

      if (containerRef.current) {
        containerRef.current.scrollLeft = scrollStert + scrollDelta;
      }

      /*
      console.log(
        `eeeeeeeeeeee scrollStert: ${scrollStert}, scrollDeltaTemp: ${scrollDeltaTemp}, scrollDelta: ${scrollDelta}, scrollLeft: ${containerRef?.current?.scrollLeft}`
      );
      */
    };

    const clearScrolling = () => {
      setScrollStep(0);
    };

    if (canScroll) {
      scroll();
      timer = setInterval(scroll, 1);
      document.addEventListener('mouseup', clearScrolling);
      document.addEventListener('mouseout', clearScrolling);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
      timer = null;

      if (canScroll) {
        document.removeEventListener('mouseup', clearScrolling);
        document.removeEventListener('mouseout', clearScrolling);
      }
      canScroll = false;
    };
  }, [scrollStep]);

  const setNavigationButtons = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    setShowPreviousButton(containerRef.current.scrollLeft > 0);
    setShowNextButton(
      containerRef.current.scrollLeft <
        containerRef.current.scrollWidth - containerRef.current.clientWidth
    );
  }, []);

  const topicsElements = topics.map((topic: any, topicIndex) => {
    const allTopicProgress = user?.progress?.unit?.topicsOverviewPerUnit?.[selectedUnit?.id || 1];

    const topicOrder = topics.find((element: any) => element.id === topic.id)?.order || 0;

    const isComplitedTopic =
      user?.progress?.unit?.topicsOverviewPerUnit?.[selectedUnit.id]?.[topic.id]
        ?.isAllActivitiesCompleted;

    const isLocked =
      !commonUtils.isTopicComplited(allTopicProgress, topicOrder, topic.id) && !isTeacher;

    return (
      <div
        tabIndex={topicIndex + 1}
        key={topic.id}
        className={classNames.topic}
        onKeyDown={e => {
          if (e.key === 'Enter' && !isLocked) {
            emitOpenModal(topic.id);
          }
        }}
      >
        {topic.id === 0 ? (
          <SVG src={flag} className={classes(classNames.topicInfo, classNames.flag)} />
        ) : (
          <TopicInfo
            className={classes(
              classNames.topicInfo,
              !isLocked ? 'cursor-pointer' : classNames.topicInfoLocked
            )}
            topic={topic}
            unitId={unitId}
            isLocked={isLocked}
            isComplete={isComplitedTopic}
            index={topicIndex}
            emitOpenModal={() => {
              const _topic = topics.find(topic => topic.id === topic.id);

              //FIXME: Temporary solution for setSelectedTopic structure
              const mock = {
                id: topic.id,
                nameLocal: null,
                name: topic.name,
                order: 1,
                quizzesCount: 1,
                topicType: 1,
                activitiesInTopic: topic.activitiesInTopic,
                userData: {
                  receivedStars: 8,
                  quizzesCompleted: 3,
                },
              };

              if (_topic) {
                dispatch(gameActions.setSelectedTopic(mock));
                dispatch(
                  unitActions.setSelectedTopic({
                    id: topic?.id,
                    name: topic?.name,
                    cEFRDescriptor: topic?.cEFRDescriptor,
                    canDoStatement: topic?.canDoStatement,
                  })
                );
              }

              drawer.openTopicsDrawer(topic.id);
            }}
          />
        )}
      </div>
    );
  });

  const pathProgressData = useMemo(() => {
    if (topics.length === 0) {
      return {
        '--pathProgress': 0,
        '--pathProgressMobile': 0,
      };
    }

    const allTopicProgress = user?.progress?.unit?.topicsOverviewPerUnit?.[selectedUnit?.id || 1];
    let firstLocked = topics.findIndex(
      topic => !commonUtils.isTopicComplited(allTopicProgress, topic.order, topic.id)
    );

    if (firstLocked < 0 || isTeacher) {
      firstLocked = topics.length - 1;
    } else if (firstLocked > 0) {
      firstLocked--;
    }

    const pcSteps = [238, 238];
    const mobileSteps = [85, 87, 102, 75, 100, 92, 116, 87, 91, 77];
    let pathProgress = 0;
    let pathProgressMobile = 5;

    for (let i = 0; i <= firstLocked; i++) {
      pathProgress += pcSteps[i % pcSteps.length];
      pathProgressMobile += mobileSteps[i % mobileSteps.length];
    }

    if (firstLocked === topics.length - 1) {
      pathProgress += containerWidth;
      pathProgressMobile += containerWidth;
    }

    return {
      '--pathProgress': pathProgress,
      '--pathProgressMobile': pathProgressMobile,
    };
  }, [topics, containerWidth, isTeacher, user, selectedUnit]);

  const setContainerRef = useCallback((ref: any) => {
    if (contresizeObserverRef.current && containerRef.current) {
      try {
        contresizeObserverRef.current.unobserve(containerRef.current);
      } catch {}
    }

    if (!contresizeObserverRef.current) {
      contresizeObserverRef.current = new ResizeObserver(entries => {
        for (const entry of entries) {
          setContainerWidth(Math.floor(entry.contentRect.width));
          setNavigationButtons();
        }
      });
    }

    containerRef.current = ref;

    if (ref) {
      setNavigationButtons();
      contresizeObserverRef.current.observe(ref);
    }
  }, []);

  return (
    <div
      className={classes(
        classNames.roadmapCarousel,
        classNames[`roadmapCarousel-${interfaceLanguage?.direction}`],
        {
          [classNames.isMobile]: isMobile,
        }
      )}
      style={
        {
          '--containerWidth': containerWidth,
          '--backgroundImage': `url('${unitAssets?.backgroundImage}')`,
          '--backgroundImageMobile': `url('${unitAssets?.backgroundImageMobile}')`,
          '--pathImage': `url('${unitAssets?.pathImage}')`,
          '--pathImageMobile': `url('${unitAssets?.pathImageMobile}')`,
          '--pathOnImage': `url('${unitAssets?.pathOnImage}')`,
          '--pathOnImageMobile': `url('${unitAssets?.pathOnImageMobile}')`,
          '--modalBorderColor': unitAssets?.modalBorderColor,
          ...pathProgressData,
        } as any
      }
    >
      {showPreviousButton && (
        <button className={classNames.previous} onMouseDown={() => setScrollStep(-1)}>
          <SVG src={previousArrow} />
        </button>
      )}
      <div
        ref={setContainerRef}
        className={classNames.carouselContainer}
        onScroll={setNavigationButtons}
      >
        <div className={classNames.topicsContent}>
          {!loading && <div className={classNames.topicsPath}>{topicsElements}</div>}
          {loading && <AppLoaderCircle className={classNames.loader} />}
        </div>
      </div>
      {showNextButton && (
        <button className={classNames.next} onMouseDown={() => setScrollStep(1)}>
          <SVG src={nextArrow} />
        </button>
      )}
    </div>
  );
};

export default React.memo(UnitsRoadmapCarousel);
