import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CurrentRouteProvider } from './context/CurrentRouteContext';
import UseCheckAuth from './hooks/UseCheckAuth';
import { useDrawer } from './context/DrawerContext';
import { authActions, authSelector, interfaceLanguageSelector } from './store/reducers/auth';
import { RemoveTrailingSlash } from './hooks/RemoveTrailingSlash';
import { useAppDispatch, useAppSelector } from './store';

import { ToastContainer } from 'react-toastify';
import Drawer from 'react-modern-drawer';
import AppSettings from './components/Settings/Settings';
import MobileDrawer from './components/Settings/MobileDrawer';
import ContactUsDrawer from './components/ContactUsDrawer/ContactUsDrawer';
import WelcomeConfetti from './components/WelcomeConfetti';
import AppDownloadRecommandation from './components/AppDownloadRecommandation/AppDownloadRecommandation';
import AddNewClass from './components/AddNewClass/AddNewClass';
import EditClass from './components/EditClass/EditClass';
import AppLoader from './components/AppLoader';
import commonUtils from './utils/common';
import ReactGA from 'react-ga4';
import { UserPropertiesGA } from './types/analytics';
import AddNewStudent from './components/AddNewStudent/AddNewStudent';
import TopicsSideDrawer from './pages/dashboard/topics-drawer/TopicsSideDrawer';

const queryClient = new QueryClient();

const App = () => {
  // Initialize app authentication check
  const dispatch = useAppDispatch();
  const {
    user,
    appLoaded,
    showWelcomeScreen,
    loggedIn,
    nativeLanguage,
    showAppRecommandationScreen,
  } = useAppSelector(authSelector);
  UseCheckAuth();
  const drawer = useDrawer();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  useEffect(() => {
    if (user && user.progress?.userLevel) {
      let userProperties: UserPropertiesGA = {
        user_organization_name: user.metadata.organizationDetails.organizationId,
        user_native_language: user.metadata.languagePreferences.nativeLanguage,
        user_interface_language: user.metadata.languagePreferences.interfaceLanguage,
        user_level: user?.progress?.userLevel || 0,
        user_group_name: user.metadata.organizationDetails.groupId,
        // user_grammar_level: user.currentLevelByskill?.grammar,
        // user_spelling_level: user.currentLevelByskill?.spelling,
        // user_listen_level: user.currentLevelByskill?.listen,
        // user_speak_level: user.currentLevelByskill?.speak,
        // user_read_level: user.currentLevelByskill?.read,
        // user_vocabulary_level: user.currentLevelByskill?.vocabulary,
        user_role: user?.metadata?.role,
      };

      switch (user?.metadata?.role) {
        case 'admin':
          userProperties = {
            ...userProperties,
            ell_admin_user: 1,
          };
          break;
        case 'teacher':
          userProperties = {
            ...userProperties,
            ell_teacher_user: 1,
          };
          break;
        case 'student':
          userProperties = {
            ...userProperties,
            ell_student_user: 1,
          };
          break;
        default:
          break;
      }

      ReactGA.gtag('set', 'user_properties', userProperties);
    }
  }, [user?.id]);

  useEffect(() => {
    if (interfaceLanguage) {
      document.body.style.direction = interfaceLanguage.direction;
    }
  }, [interfaceLanguage]);

  useEffect(() => {
    if (nativeLanguage) {
      const shouldDisplayDictionary = commonUtils.shouldDisplayDictionary(nativeLanguage);

      dispatch(authActions.setDictionaryDisplay(false));
      // dispatch(authActions.setDictionaryDisplay(shouldDisplayDictionary));
    }
  }, [nativeLanguage]);

  let content = <div className='App'>{appLoaded ? <Outlet /> : <AppLoader />}</div>;

  if (loggedIn) {
    if (showWelcomeScreen) {
      content = <WelcomeConfetti />;
    } else if (showAppRecommandationScreen) {
      content = <AppDownloadRecommandation />;
    }
  }

  return (
    <QueryClientProvider client={queryClient}>
      <CurrentRouteProvider>
        <RemoveTrailingSlash />
        {content}
        <ToastContainer />
        {user && (
          <>
            <Drawer
              open={drawer.isDrawerOpen}
              onClose={drawer.closeDrawer}
              direction={'right'}
              className='app-settings app-drawer'
            >
              <AppSettings />
            </Drawer>
            <Drawer
              open={drawer.isTopicsOpen}
              onClose={drawer.closeTopicsDrawer}
              direction={'right'}
              className='app-settings app-drawer'
            >
              <TopicsSideDrawer topicId={drawer.topicId} />
            </Drawer>
            <Drawer
              open={drawer.isMobileDrawerOpen}
              onClose={drawer.closeMobileDrawer}
              direction={'right'}
              className='app-mobile-drawer app-settings app-drawer'
            >
              <MobileDrawer />
            </Drawer>
            <Drawer
              open={drawer.isContactUsDrawerOpen}
              onClose={drawer.closeContactUsDrawer}
              direction={interfaceLanguage?.direction === 'rtl' ? 'left' : 'right'}
              className='app-drawer'
            >
              <ContactUsDrawer />
            </Drawer>

            <Drawer
              open={drawer.isAddClassDrawerOpen}
              onClose={drawer.closeAddClass}
              direction={interfaceLanguage?.direction === 'rtl' ? 'left' : 'right'}
              className='app-drawer'
            >
              <AddNewClass />
            </Drawer>

            <Drawer
              open={drawer.isEditClassDrawerOpen}
              onClose={drawer.closeEditClass}
              direction={interfaceLanguage?.direction === 'rtl' ? 'left' : 'right'}
              className='app-drawer'
            >
              <EditClass id={drawer.classId} />
            </Drawer>
            <Drawer
              open={drawer.isAddNewStudentDrawerOpen}
              onClose={drawer.closeAddStudent}
              direction={'right'}
              className='app-drawer'
            >
              <AddNewStudent groupId={drawer.groupId} isOpen={drawer.isAddNewStudentDrawerOpen} />
            </Drawer>
          </>
        )}
      </CurrentRouteProvider>
    </QueryClientProvider>
  );
};

export default App;
