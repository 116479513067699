import { useAppDispatch, useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import UserUtils from '@/utils/user';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AdminOnboarding from '@/components/AdminOnboarding/AdminOnboarding';
import TeacherOnboarding from '@/components/TeacherOnboarding/TeacherOnboarding';
import RegistrationView from '@/pages/registration/RegistrationView';

const OnboardingView = () => {
  const { user, loggedIn } = useAppSelector(authSelector);
  const navigate = useNavigate();

  const isStudent = UserUtils.isStudent(user);
  const isAdmin = UserUtils.isAdmin(user);

  useEffect(() => {
    if (isStudent) {
      const to = loggedIn ? '/dashboard/games' : '/login';
      return navigate(to);
    }
  }, []);

  if (isAdmin && !user?.metadata?.organizationDetails?.adminSettings?.isCreateOrgComplete) {
    return <RegistrationView />;
  }

  const content = isAdmin ? <AdminOnboarding /> : <TeacherOnboarding shouldNavigate />;

  return <div className='onboarding-view w-full h-full'>{content}</div>;
};

export default OnboardingView;
