import { StatesByCountryResponse, CitiesByStateResponse } from '@/types/response';
import { CommonService } from '@/types/services';
import config from '@/config';
import axios from 'axios';

export const commonService: CommonService = {
  getStatesByCountry: async countryCode => {
    const resp: StatesByCountryResponse | null = await axios.get(
      `https://api.countrystatecity.in/v1/countries/${countryCode}/states`,
      {
        headers: {
          'X-CSCAPI-KEY': config.countryStateCityAPIKey,
        },
      }
    );

    return resp;
  },
  getCitiessByState: async (countryCode, stateCode) => {
    const resp: CitiesByStateResponse | null = await axios.get(
      `https://api.countrystatecity.in/v1/countries/${countryCode}/states/${stateCode}/cities`,
      {
        headers: {
          'X-CSCAPI-KEY': config.countryStateCityAPIKey,
        },
      }
    );

    return resp;
  },
};
