import config from '@/config';
import { UnitsResponse } from '@/types/response';
import { UnitService } from '@/types/services';
import axiosInstanceNew from '@/utils/api-new';
import UserUtils from '@/utils/user';
import { UserState } from '@/types/user';

export const unitService: UnitService = {
  fetchStudentProfile: async (studentId?: string | null) => {
    const resp: UnitsResponse = await axiosInstanceNew.get(
      `${config.apiVersion}/user-data/profile/${studentId}`
    );

    return resp;
  },
  fetchSummary: async (user: UserState | null, level: number | null) => {
    const resp: UnitsResponse = await axiosInstanceNew.get(
      `${config.apiVersion}/${UserUtils.fromToLanguage(user)}/game-content/summary/${level ?? 1}`
    );

    return resp;
  },
  fetchUnits: async (user: UserState | null, level: number | null) => {
    const resp: UnitsResponse = await axiosInstanceNew.get(
      `${config.apiVersion}/${UserUtils.fromToLanguage(user)}/game-content/${level ?? 1}`
    );

    return resp;
  },
  fetchUnitTopics: async (user: UserState | null, level: number | null, unitId: number | null) => {
    const resp: UnitsResponse = await axiosInstanceNew.get(
      `${config.apiVersion}/${UserUtils.fromToLanguage(user)}/game-content/${level ?? 1}/${unitId ?? 1}`
    );

    return resp;
  },
};
