import { AppInputProps } from '@/types/common';
import classNames from './AppInput.module.scss';
import classes from 'classnames';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';
import SVG from 'react-inlinesvg';
import { CircleFlag } from 'react-circle-flags';

import questionMark from '@/assets/svg/question-mark-circle.svg';
import close from '@/assets/svg/close.svg';
import eye from '@/assets/svg/eye.svg';
import eyeSlash from '@/assets/svg/eye_slash.svg';
import helpBtn from '@/assets/svg/help_btn.svg';

const AppInput = ({
  label,
  type,
  value,
  id,
  name,
  placeholder,
  error,
  pattern,
  className,
  allowClear = false,
  showPasswordRequirements,
  inputWrapperClassName,
  passwordRequirementsClassName,
  isRequired,
  isDisabled,
  img,
  countryFlagImg,
  maxLength,
  minLength,
  hideValue,
  emitChange,
  emitBlur,
  emitKeyPress,
  emitClick,
  emitClear,
  shouldTranslate,
  helpText,
  helperPosition = 'input',
}: AppInputProps) => {
  const { t } = useTranslation();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  const [passwordType, setPasswordType] = useState('password');
  const [showHelpText, setShowHelpText] = useState(false);

  const isPassword = type === 'password';
  const inputType = isPassword ? passwordType : type ?? 'text';

  useEffect(() => {
    window.addEventListener('click', () => {
      if (!showHelpText) {
        return;
      }

      setShowHelpText(false);
    });

    return () => {
      window.removeEventListener('click', () => {});
    };
  }, [showHelpText]);

  const onToggleTypes = () => {
    setPasswordType(prevState => (prevState === 'password' ? 'text' : 'password'));
  };

  const onToggleHelpText = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowHelpText(prevState => !prevState);
  };

  const passwordRequirementsList = (
    <>
      <div className={classNames.requirement}>
        <SVG src={questionMark} />
        <span>{t('passwordMinimumLength')}</span>
      </div>
      <div className={classNames.requirement}>
        <SVG src={questionMark} />
        <span>{t('atLeastOneNumber')}</span>
      </div>
      <div className={classNames.requirement}>
        <SVG src={questionMark} />
        <span>{t('atLeastOneUppercase')}</span>
      </div>
      <div className={classNames.requirement}>
        <SVG src={questionMark} />
        <span>{t('atLeastOneSpecial')}</span>
      </div>
    </>
  );

  const passwordHelp = (
    <div className={classes(classNames.passwordRequirements, passwordRequirementsClassName)}>
      <span className={classNames.title}>{`${t('requirements')}:`}</span>
      {passwordRequirementsList}
    </div>
  );

  const passwordError = (
    <div
      className={classes(
        classNames.passwordRequirements,
        classNames.passwordRequirementsError,
        passwordRequirementsClassName
      )}
    >
      {passwordRequirementsList}
    </div>
  );

  let inputImg;

  if (img) {
    inputImg = <SVG src={img ?? ''} />;
  } else if (countryFlagImg) {
    inputImg = <CircleFlag countryCode={countryFlagImg} />;
  }

  const inputRenderer = isDisabled ? (
    <>
      {inputImg}
      <span>{hideValue ? placeholder : value}</span>
    </>
  ) : (
    <input
      type={inputType}
      id={id}
      name={name}
      value={value}
      placeholder={shouldTranslate ? t(placeholder ?? '').toString() : placeholder}
      minLength={minLength}
      maxLength={maxLength}
      onChange={emitChange}
      onKeyDown={emitKeyPress}
      onBlur={emitBlur}
      onClick={emitClick}
    />
  );

  const btnImg = isPassword ? (passwordType === 'text' ? eyeSlash : eye) : close;

  const classesNames = classes(classNames.appInput, className);

  if (type === 'checkbox') {
    return (
      <div className={classesNames}>
        <input
          className='cursor-pointer'
          id={id}
          name={name}
          type='checkbox'
          value={value}
          checked={value === 'true'}
          onChange={emitChange}
        />
      </div>
    );
  }

  let errorElement = null;

  if (error) {
    errorElement =
      isPassword && showPasswordRequirements ? (
        passwordError
      ) : (
        <span className={classNames.error}>{error}</span>
      );
  }

  const helper = (helpText || showPasswordRequirements) && (
    <div className={classes(classNames.helpText, classNames[`helperPosition-${helperPosition}`])}>
      <div className={classNames.helpIcon} onClick={onToggleHelpText}>
        <SVG src={helpBtn} />
      </div>
      <div
        className={classes(classNames.helpContent, {
          [classNames.helpContentShow]: showHelpText,
        })}
      >
        {helpText || passwordHelp}
      </div>
    </div>
  );

  const labelRenderer = label && (
    <div className={classNames.labelWrapper}>
      <label htmlFor={id}>
        {isRequired ? `*${shouldTranslate ? t(label) : label}` : shouldTranslate ? t(label) : label}
      </label>
      {helperPosition === 'label' && helper}
    </div>
  );

  return (
    <div className={classesNames}>
      {labelRenderer}
      <div
        className={classes(classNames.inputWrapper, inputWrapperClassName, {
          [classNames.inputWrapperDisabled]: isDisabled,
        })}
      >
        {inputRenderer}
        {!isDisabled && value && name && (allowClear || isPassword) && (
          <SVG
            fill='#526779'
            width={20}
            className={classNames.btn}
            src={btnImg}
            onClick={event => (isPassword ? onToggleTypes() : emitClear?.(event, name))}
          />
        )}
        {!value && helperPosition === 'input' && helper}
      </div>
      {errorElement}
    </div>
  );
};

export default AppInput;
