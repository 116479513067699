import { useTranslation } from 'react-i18next';
import classNames from './RecentTranslations.module.scss';

interface RecentTranslationsProps {
  translations?: string[];
  emitClick: (translation: string) => void;
}

const RecentTranslations = ({ translations, emitClick }: RecentTranslationsProps) => {
  const { t } = useTranslation();

  const translationsElements = translations
    ?.sort((curr, next) => curr.localeCompare(next, undefined, { sensitivity: 'base' }))
    .map((translation, index) => (
      <div key={index} className={classNames.translation} onClick={() => emitClick(translation)}>
        <span>{translation}</span>
      </div>
    ));

  return (
    <div className={classNames.recentTranslations}>
      <h2>{t('myRecentTranslations')}</h2>
      <div className={classNames.translations}>{translationsElements}</div>
    </div>
  );
};

export default RecentTranslations;
