import React, { useState } from 'react';
import classNames from './AddNewStudent.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDrawer } from '@/context/DrawerContext';
import SectionSeperator from '../SectionSeperator/SectionSeperator';
import AddNewStudentForm from '../AddNewStudentForm/AddNewStudentForm';
import { Transition } from 'react-transition-group';
import AppBackdrop from '@/components/AppBackdrop';
import AppModal from '@/components/AppModal';
import TheButton from '@/components/TheButton/TheButton';

interface AddNewStudentProps {
  groupId: string;
  isOpen: boolean;
}

const AddNewStudent = ({ groupId, isOpen }: AddNewStudentProps) => {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const [addedStudent, setAddedStudent] = useState<string>('');

  const onStudentAdded = (name: string) => {
    setAddedStudent(name);
    drawer.closeAddStudent();
  };

  const onCloseStudentAddedModal = () => {
    setAddedStudent('');
  };

  const newStudentAddedModal = (
    <Transition
      in={!!addedStudent}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => {
        return (
          <>
            <AppBackdrop className={classes(classNames.backdrop, `backdrop-${state}`)} />
            <AppModal>
              <div className={classes(classNames.modal, `fade-${state}`)}>
                <h2>
                  {addedStudent} <br />
                  {t('editClassStudentNewStudentAddedTitle')}
                </h2>
                <span
                  className={classNames.description}
                  dangerouslySetInnerHTML={{
                    __html: t('editClassStudentNewStudentAddedDescription').toString(),
                  }}
                />
                <TheButton
                  className={classNames.btn}
                  text={t('done')}
                  shouldRecolor={false}
                  emitOnClick={onCloseStudentAddedModal}
                />
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );

  return (
    <div className={classNames.addNewClassDrawer}>
      {newStudentAddedModal}
      <div className={classNames.top}>
        <h1>{t('settings.add_students.add_student')}</h1>
        <h2>{t('settings.add_students.email_will_be_send')}</h2>
      </div>
      <SectionSeperator className={classNames.seperator} />
      <div className={classNames.body}>
        <AddNewStudentForm
          groupId={groupId}
          emitCancel={drawer.closeAddStudent}
          emitStudentAdded={onStudentAdded}
          isOpen={isOpen}
        />
      </div>
    </div>
  );
};

export default AddNewStudent;
