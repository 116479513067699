import { SettingItem } from '@/types/common';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';
import SVG from 'react-inlinesvg';

import rightArrow from '@/assets/svg/right-arrow.svg';

const SettingItems = ({ items, section }: { items?: SettingItem[]; section: string }) => {
  const { t } = useTranslation();
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);

  return (
    <div className='setting-items-wrap'>
      <h2>{t(section)}</h2>
      <div className='setting-items-tiles'>
        {items
          ?.filter(item => item.showCondition?.() || !item.showCondition)
          .map((item, index) => {
            const { trailingChild, onTap } = item;

            const hasClick = onTap && !trailingChild;

            return (
              <a
                key={index}
                onClick={() => {
                  if (item.onTap) {
                    item.onTap();
                  }
                }}
                className={cls('setting-item', {
                  [`setting-item-${interfaceLanguage?.direction}`]: true,
                })}
              >
                {item.icon && !item.isFlag && (
                  <span className={cls('setting-item-icon', item.icon)} />
                )}

                {item.icon && item.isFlag && (
                  <img className='setting-item-flag' src={`/langs/${item.icon}.svg`} alt='' />
                )}

                <span>{t(item.title)}</span>

                {item.trailingText && (
                  <span
                    className={cls({
                      [`setting-item-trailing-text-${interfaceLanguage?.direction}`]: true,
                    })}
                  >
                    {item.trailingText}
                  </span>
                )}

                {item.trailingChild && (
                  <span
                    className={cls({
                      [`setting-item-trailing-text-${interfaceLanguage?.direction}`]: true,
                    })}
                    dangerouslySetInnerHTML={{ __html: item.trailingChild }}
                  ></span>
                )}
                {hasClick && <SVG className='setting-item-arrow' src={rightArrow} />}
              </a>
            );
          })}
      </div>
    </div>
  );
};

export default SettingItems;
