import classNames from './NotFoundContent.module.scss';
import SVG from 'react-inlinesvg';

import logo from '@/assets/svg/logo_new.svg';

interface NotFoundContentProps {
  title: string;
  description: string;
}

const NotFoundContent = ({ title, description }: NotFoundContentProps) => {
  return (
    <div className={classNames.notFoundContent}>
      <SVG src={logo} />
      <h2>{title}</h2>
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

export default NotFoundContent;
