import classNames from './EditContainer.module.scss';
import classes from 'classnames';

interface FieldProps {
  label?: string;
  children?: any;
  className?: string;
}

const Field = ({ label, children, className }: FieldProps) => {
  return (
    <div className={classes(classNames.field, className)}>
      <div className={classNames.fieldLabel}>{label}</div>
      <div className={classNames.fieldContent}>{children}</div>
    </div>
  );
};

export default Field;
