import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { GameStatus, GameTypes, NewGameTypes, PopUpTypes } from '@/types/game';
import commonUtils from '@/utils/common';
import { Transition, TransitionStatus } from 'react-transition-group';
import classes from 'classnames';
import classNames from './QuizView.module.scss';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';
import UseScreenSize from '@/hooks/UseScreenSize';
import UseDictionary from '@/hooks/UseDictionary';
import { SkillTypes } from '@/types/skill';
import UseLocalLang from '@/hooks/UseLocalLang';
import levelTestThunks from '@/store/thunks/level-test';

import AppModal from '@/components/AppModal';
import QuizPageControls from '@/components/QuizPage/QuizPageControls/QuizPageControls';
import QuizDetails from '@/components/QuizPage/QuizDetails/QuizDetails';
import QuizQuestion from '@/components/QuizPage/QuizQuestion/QuizQuestion';
import QuizPageIndicators from '@/components/QuizPage/QuizPageIndicators/QuizPageIndicators';
import QuizPopUp from '@/components/QuizPage/QuizPopUp/QuizPopUp';
import AppBackdrop from '@/components/AppBackdrop';
import QuizPreparation from '@/components/QuizPage/QuizPreparation/QuizPreparation';
import QuizLanguageToggler from '@/components/QuizPage/QuizLanguageToggler/QuizLanguageToggler';
import WordsList from '@/components/QuizPage/QuizPopUp/PopUpWordsList/WordsList/WordsList';
import AppLoader from '@/components/AppLoader';
//TODO: Need to understand if it's needed
//import DictionaryMobile from '@/components/Dictionary/DictionaryMobile/DictionaryMobile';
import AppBanner from '@/components/AppBanner';
import { gameSelector, gameActions } from '@/store/reducers/game';
import LevelTestLogoutComponent from '../level-test/LevelTestLogoutComponent';
import { TopicTypes } from '@/types/topic';
import EndOfSkillTestPreparation from '@/components/QuizPage/QuizPreparation/EndOfSkillTestPreparation/EndOfSkillTestPreparation';
import FinalAssessmentPreparation from '@/components/FinalAssessmentPreparation/FinalAssessmentPreparation';
import ReadPreparation from '@/components/QuizPage/QuizPreparation/ReadPreparation/ReadPreparation';
import { useDispatch } from 'react-redux';
import { levelTestSelector } from '@/store/reducers/level-test';
import hatIcon from '@/assets/svg/level-test-hat-mobile.svg';
import { UseQuizInterface } from './UseQuiz';
import { GameDetailsBlock } from '@/components/QuizPage/QuizPreparation/ListenPreparation/components/GameDetailsBlock';

const QuizViewMobile = ({ quizData }: { quizData: UseQuizInterface }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    loadQuizProgress,
    currentQuestion,
    selectedTopic,
    selectedQuiz,
    selectedSkill,
    selectedType,
    gameStatus,
    currentQuestionIndex,
    currentReadQuestionIndex,
    gameQuestions,
    gameTypeReal,
    coins,
    currentTime,
    currentCoins,
    shouldDisplayPopUp,
    popUpType,
    popUpProps,
    isMuted,
    allowRetry,
    showPreQuiz,
    onMute,
    onPause,
    onStartQuiz,
    onPractice,
    onDisableRetry,
    onClosePopUp,
    onAknowledge,
    onSwipe,
    toggleCheckAnswer,
    goToNextQuestion,
    currentQuestionTempData,
    setCurrentQuestionTempData,
  } = quizData;

  const {
    //TODO: Need to understand if it's needed
    // searchValue,
    selectedWord,
    // cards,
    // phrases,
    // pictureOfTheDay,
    // options,
    // question,
    // isSearching,
    // onInputChange,
    // onSearch,
    // onClear,
    // onValueSearch,
  } = UseDictionary();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentQuestion?.gameType) {
      return;
    }

    if (
      gameTypeReal === GameTypes.Practice &&
      currentQuestion.gameType === NewGameTypes.Vocabulary
    ) {
      return;
    }

    dispatch(gameActions.setGameType(currentQuestion.gameType));
  }, [currentQuestion]);

  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const { activity, questionIndex } = useAppSelector(levelTestSelector);

  const { largeWidth, windowWidth, isDesktop, isMobile } = UseScreenSize();
  const isInEndOfSkillTest = selectedTopic?.topicType === TopicTypes.EndOfSkillTest;
  // const isLevelTestEnd = activity?.questions?.length === currentQuestionIndex;

  const onAnswer = (userAnswer: string) => {
    toggleCheckAnswer(userAnswer);
  };

  const setClasseByPopType = useCallback(
    (state: TransitionStatus) => {
      return popUpType === PopUpTypes.WordsList
        ? `slide-horizontal-${interfaceLanguage?.direction}-${state}`
        : `fade-${state}`;
    },
    [popUpType]
  );

  const onGoBack = () => {
    return navigate('/dashboard/games');
  };

  const isPractice = gameTypeReal === GameTypes.Practice;

  const { isLevelTest, isFinalAssessment } = useAppSelector(gameSelector);
  //FIXME: NEED TO REPLACE WITH CORRECT GAMETYPE
  const TEMP_GAMETYPE = gameQuestions[0]?.gameType as any;
  const isRead = TEMP_GAMETYPE === NewGameTypes.Read;
  const isSpeak = selectedType === SkillTypes.Speak;
  const localLang = UseLocalLang();

  const quizQuestion = (
    <>
      <QuizQuestion
        loading={loadQuizProgress}
        currentQuestion={isLevelTest ? activity?.questions[questionIndex] : currentQuestion}
        allowRetry={allowRetry}
        emitOnAnswer={onAnswer}
        emitOnRetry={onDisableRetry}
        emitOnNextQuestion={goToNextQuestion}
        emitOnSwipe={onSwipe}
        emitOnAknowledge={onAknowledge}
        currentQuestionTempData={currentQuestionTempData}
        setCurrentQuestionTempData={setCurrentQuestionTempData}
      />
      {isMobile && !isPractice && (
        <QuizPageIndicators
          earnedCoins={coins}
          timesUp={!loadQuizProgress && currentTime <= 5}
          coins={currentCoins}
          timer={currentTime ?? 0}
          selectedType={selectedType}
        />
      )}
    </>
  );

  const quizControls = (
    <QuizPageControls
      actual={currentQuestionIndex + 1 ?? 0}
      total={gameQuestions.length}
      onMute={onMute}
      onPause={onPause}
      isMuted={isMuted}
      isPaused={gameStatus === GameStatus.PAUSED}
      loading={loadQuizProgress}
    />
  );

  const popUpComponent = (
    <Transition
      in={Boolean(shouldDisplayPopUp && popUpType && popUpProps)}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => {
        return (
          <>
            <AppBackdrop
              className={classes(classNames.backdrop, `backdrop-${state}`, {
                [classNames.backdropWhite]:
                  popUpType === PopUpTypes.Focus || popUpType === PopUpTypes.KeepGoing,
              })}
              emitClose={() => {
                if (popUpProps?.canClickOutside === false) {
                  return;
                }

                if (popUpType !== PopUpTypes.Summary && popUpType !== PopUpTypes.TimeIsUp) {
                  onClosePopUp();
                  return;
                }
              }}
            />
            <AppModal>
              <QuizPopUp
                {...popUpProps}
                className={classes(classNames.modal, setClasseByPopType(state))}
                type={popUpType}
                tip={commonUtils.getQuestionTip(currentQuestion ?? {}, localLang) ?? ''}
              />
            </AppModal>
          </>
        );
      }}
    />
  );

  const levelTestLogoutComponent = (
    <LevelTestLogoutComponent className={classNames.levelTestSignOut} />
  );

  if (loadQuizProgress) {
    return <AppLoader />;
  }

  let content = (
    <div className={`flex flex-1 justify-between gap-8 ${isMobile ? 'px-0' : 'px-11'}`}>
      <div className={classes(classNames.quizMobileContent)}>
        {quizControls}
        {!isFinalAssessment && (
          <div className={classNames.detailsAndIndicators}>
            {isDesktop ? (
              <QuizDetails
                selectedType={selectedType}
                selectedSkill={selectedSkill}
                selectedTopic={selectedTopic}
                selectedQuiz={selectedQuiz}
                shouldDisplayImage={true}
              />
            ) : (
              <GameDetailsBlock
                selectedTopic={selectedTopic}
                selectedQuiz={selectedQuiz}
                currentQuestion={currentQuestion}
              />
            )}

            {isPractice ? (
              <QuizLanguageToggler />
            ) : (
              !isInEndOfSkillTest &&
              isDesktop && (
                <QuizPageIndicators
                  earnedCoins={coins}
                  timesUp={!loadQuizProgress && currentTime <= 5}
                  coins={currentCoins}
                  timer={currentTime ?? 0}
                  selectedType={selectedType}
                />
              )
            )}
          </div>
        )}
        {quizQuestion}
      </div>
      {popUpComponent}
      {isPractice && largeWidth <= windowWidth && <WordsList emitOnAknowledge={onAknowledge} />}
    </div>
  );

  //FIXME: Need to remove, old functionality
  // const showPreparation =
  //   (!isInEndOfSkillTest && (showPreQuiz || isRead)) ||
  //   (isInEndOfSkillTest && !showPreQuiz && isRead);

  //FIXME: Need to use correct condition based on new functionality
  const showPreparation = showPreQuiz;

  if (showPreparation) {
    content = (
      <>
        <div
          className={`${isMobile ? 'px-0' : 'px-11 py-3'} flex-1 overflow-x-hidden overflow-y-auto`}
        >
          {isLevelTest && levelTestLogoutComponent}
          <QuizPreparation
            currentQuestion={
              isLevelTest ? gameQuestions[currentReadQuestionIndex] : currentQuestion
            }
            currentQuestionIndex={isLevelTest ? currentReadQuestionIndex : currentQuestionIndex}
            gameQuestions={gameQuestions}
            loading={loadQuizProgress}
            selectedQuiz={selectedQuiz}
            selectedSkill={selectedSkill}
            selectedTopic={selectedTopic}
            selectedType={selectedType}
            coins={coins}
            currentCoins={currentCoins}
            currentTime={currentTime}
            isPaused={gameStatus === GameStatus.PAUSED}
            emitOnStartQuiz={onStartQuiz}
            emitGoBack={onGoBack}
            emitOnPractice={onPractice}
            emitPause={onPause}
          />
        </div>
        {popUpComponent}
      </>
    );
  }

  if (showPreQuiz) {
    if (isInEndOfSkillTest) {
      content = (
        <>
          <div
            className={`${isMobile ? 'px-0' : 'px-11 py-3'} flex-1 overflow-x-hidden overflow-y-auto`}
          >
            <EndOfSkillTestPreparation
              selectedTopic={selectedTopic}
              selectedQuiz={selectedQuiz}
              selectedSkill={selectedSkill}
              selectedType={selectedType}
              emitOnStartQuiz={onStartQuiz}
            />
          </div>
          {popUpComponent}
        </>
      );
    } else if (isFinalAssessment && !isRead) {
      content = (
        <>
          <div
            className={`${isMobile ? 'px-0' : 'px-11 py-3'} flex-1 overflow-x-hidden overflow-y-auto`}
          >
            <FinalAssessmentPreparation />
          </div>
          {popUpComponent}
        </>
      );
    }
  }

  if (isLevelTest && !isRead) {
    content = (
      <>
        {isLevelTest && levelTestLogoutComponent}
        <div className={classNames.instructionsContainer}>
          <h2 className={classNames.testYourEnglishText}>{t('testYourEnglish')}</h2>
          <h2>{t('chooseTheCorrectAnswer')}</h2>
        </div>
        <div className={classNames.hatImage}>
          <SVG src={hatIcon} />
        </div>
        {quizQuestion}
        {popUpComponent}
      </>
    );
  }
  //TODO: Need to understand if it's needed
  // const dictionaryComponents = (
  //   <DictionaryMobile
  //     selectedWord={selectedWord}
  //     cards={cards}
  //     phrases={phrases}
  //     question={question}
  //     pictureOfTheDay={pictureOfTheDay}
  //     options={options}
  //     emitBack={onClear}
  //     onValueSearch={onValueSearch}
  //   />
  // );

  //FIXME: Need to try to put it in content variable (starts from line 191)
  if (isRead && !showPreparation) {
    content = (
      <>
        <ReadPreparation
          currentQuestion={isLevelTest ? gameQuestions[currentReadQuestionIndex] : currentQuestion}
          currentQuestionIndex={isLevelTest ? currentReadQuestionIndex : currentQuestionIndex}
          gameQuestions={gameQuestions}
          loading={loadQuizProgress}
          selectedQuiz={selectedQuiz}
          selectedSkill={selectedSkill}
          selectedTopic={selectedTopic}
          selectedType={selectedType}
          coins={coins}
          currentCoins={currentCoins}
          currentTime={currentTime}
          isPaused={gameStatus === GameStatus.PAUSED}
          emitOnStartQuiz={onStartQuiz}
          emitGoBack={onGoBack}
          emitPause={onPause}
        />
        {popUpComponent}
      </>
    );
  }

  return (
    <div
      className={classes(classNames.quizMobile, {
        'flex-1': isSpeak && showPreQuiz,
        'h-full': !(isSpeak && showPreQuiz),
        [classNames.quizMobileLevelTest]: isLevelTest,
        [classNames.quizMobileDictionary]: selectedWord,
        [classNames.quizMobileFinalAssessment]: isFinalAssessment,
      })}
    >
      <div className={classNames.content}>{content}</div>
      {!isPractice && !showPreparation && (
        <AppBanner className={classNames.appBannerMobile} isFixed={true} />
      )}
    </div>
  );
};

export default QuizViewMobile;
