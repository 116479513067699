import classes from 'classnames';
import classNames from './OpenWriting.module.scss';
import SVG from 'react-inlinesvg';
import DOMPurify from 'dompurify';
import { useAppDispatch, useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { authSelector } from '@/store/reducers/auth';
import TheButton from '@/components/TheButton/TheButton';
import { useTranslation } from 'react-i18next';
import MTextarea from './MTextarea/MTextarea';
import React, { useMemo, useState } from 'react';
import { skillService } from '@/services';
import { unitSelector } from '@/store/reducers/unit';
import commonUtils from '@/utils/common';
import UseScreenSize from '@/hooks/UseScreenSize';
import closeIcon from '@/assets/svg/close.svg';

const OpenWriting = () => {
  const { t } = useTranslation();
  const { currentQuestionIndex, gameQuestions, selectedQuiz, selectedTopic } =
    useAppSelector(gameSelector);
  const { user } = useAppSelector(authSelector);
  const { selectedUnit } = useAppSelector(unitSelector);
  const dispatch = useAppDispatch();
  const { isDesktop, isMobile } = UseScreenSize();

  const [answer, setAnswer] = useState<string>('');
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [isShowMobileAction, setIsShowMobileAction] = useState<boolean>(false);

  const toggleShowMobileAction = () => setIsShowMobileAction(!isShowMobileAction);

  const showMobileAction = isDesktop || (isMobile && isShowMobileAction);

  const shouldSendResponce =
    gameQuestions[currentQuestionIndex]?.newData?.data?.metadata?.displayScore;

  const questiontext = gameQuestions[currentQuestionIndex]?.newData?.data?.metadata
    ?.displayQuestionInNativeLanguage
    ? {
        title: gameQuestions[currentQuestionIndex]?.newData?.data?.questionDataLocal?.textLocal,
        instruction: '',
      }
    : {
        title: gameQuestions[currentQuestionIndex]?.newData?.data?.questionData?.question?.text,
        instruction: '',
      };
  questiontext.instruction =
    commonUtils.getQuestionInstructions(gameQuestions[currentQuestionIndex], selectedQuiz) || '';

  const emitOnClick = async () => {
    setisLoading(true);
    dispatch(gameActions.setPauseTimer(true));

    skillService
      .sendOpenWritingAnswer(user, {
        unitId: selectedUnit.id,
        topicId: selectedTopic?.id,
        activityId: selectedQuiz?.id,
        questionId: gameQuestions[currentQuestionIndex]?.questionId,
        userResponse: answer,
      })
      .then(result => {
        dispatch(gameActions.setPauseTimer(false));
        dispatch(
          gameActions.handlePickedAnswerOpenWriting(
            shouldSendResponce
              ? {
                  answer,
                  aIGradingReview: result.data.aIGradingReview,
                  containsGibberish: result.data.containsGibberish,
                  containsVulgarLanguage: result.data.containsVulgarLanguage,
                  isEnglish: result.data.isEnglish,
                  grammarScore: result.data.grammarScore,
                  vocabularyScore: result.data.vocabularyScore,
                  spellingScore: result.data.spellingScore,
                  overallScore: result.data.overallScore,
                  //Property, that checks if grading is needed
                  noGrading: false,
                }
              : {
                  answer,
                  aIGradingReview: '',
                  containsGibberish: result.data.containsGibberish,
                  containsVulgarLanguage: result.data.containsVulgarLanguage,
                  isEnglish: result.data.isEnglish,
                  grammarScore: 0,
                  vocabularyScore: 0,
                  spellingScore: 0,
                  overallScore: 0,
                  noGrading: true,
                }
          )
        );
      })
      .catch(error => {
        dispatch(gameActions.setPauseTimer(false));
        dispatch(
          gameActions.handlePickedAnswerOpenWriting({
            answer,
            aIGradingReview: '',
            containsGibberish: false,
            containsVulgarLanguage: false,
            isEnglish: true,
            grammarScore: 0,
            vocabularyScore: 0,
            spellingScore: 0,
            overallScore: 0,
            //Property, that checks if grading is needed
            noGrading: true,
          })
        );
      })
      .finally(() => {
        setisLoading(false);
        setAnswer('');
        toggleShowMobileAction();
      });
  };

  const questionDescription = useMemo(() => {
    if (questiontext.instruction) {
      return commonUtils.formatText(questiontext.instruction);
    }

    return t('notAvailable');
  }, [questiontext]);

  const currentQuestion = gameQuestions[currentQuestionIndex];
  const image = currentQuestion?.newData?.data?.questionData?.image;
  const imageCredit = (image && currentQuestion?.imagesCredits?.[image]) || '';
  const imageAlt = (image && currentQuestion?.imagesAlt?.[image]) || '';

  return (
    <div className={classNames.openWriting}>
      <div className={classNames.openWritingTitle}>
        <p className={classNames.questionText}>{questiontext.title || t('notAvailable')}</p>
        {isDesktop && (
          <p
            className={classNames.questionDescription}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(questionDescription) }}
          />
        )}
      </div>
      <div className={classNames.openWritingWrapper}>
        <div className={classes(classNames.openWritingBlock, classNames.questionBlock)}>
          <div className={classNames.closedSpellingImage}>
            <img src={image} alt={imageAlt} />
            <p>{imageCredit}</p>
          </div>
        </div>
        {!isShowMobileAction && isMobile && (
          <p className={classNames.showActionText} onClick={toggleShowMobileAction}>
            Write Your Answer
          </p>
        )}
        {showMobileAction && (
          <div
            className={classes(classNames.openWritingBlock, classNames.answerBlock, {
              [classNames.answerBlockMobile]: isMobile,
            })}
          >
            {isMobile && (
              <button
                className={classNames.openWritingActionClose}
                onClick={toggleShowMobileAction}
              >
                <SVG src={closeIcon} />
              </button>
            )}
            <MTextarea
              value={answer}
              isLoading={isLoading}
              onChangeValue={text => setAnswer(text)}
            />
            <div className={classNames.openWritingAction}>
              <TheButton
                className={isMobile ? 'w-full' : ''}
                text={t('submit')}
                shouldRecolor={true}
                disabled={!answer || isLoading}
                emitOnClick={emitOnClick}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OpenWriting;
