import UseScreenSize from '@/hooks/UseScreenSize';
import UseThemeColors from '@/hooks/UseThemeColors';
import React from 'react';

const PageWrap = ({ children }: { children: React.ReactNode }) => {
  const { color } = UseThemeColors();
  const { isMobile } = UseScreenSize();

  return (
    <div
      style={{
        backgroundColor: color('pageBackground'),
      }}
      className={`w-full flex flex-1 flex-col ${isMobile ? 'gap-4 pt-14' : 'gap-10'} p-3 `}
    >
      {children}
    </div>
  );
};

export default PageWrap;
