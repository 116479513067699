import { useAppSelector } from '@/store';
import { isLangLocalSelector } from '@/store/reducers/auth';

const UseLocalLang = () => {
  const localLang = useAppSelector(isLangLocalSelector);

  return localLang;
};

export default UseLocalLang;
