import classNames from './ClassEditor.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import commonUtils from '@/utils/common';
import { ClassConfig, TeacherGroup } from '@/types/user';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import UseThemeColors from '@/hooks/UseThemeColors';
import { useAppSelector } from '@/store';
import { teacherDashboardSelector } from '@/store/reducers/teacher-dashboard';
import { GroupStudentsResponse } from '@/types/response';
import { useDrawer } from '@/context/DrawerContext';
import { registerService } from '@/services/register';

import Detail from '../UserInfo/Detail/Detail';
import DetailSeperator from '../DetailSeperator/DetailSeperator';
import TheButton from '../TheButton/TheButton';
import AppInput from '../AppInput/AppInput';
import Switch from 'react-switch';
import SectionSeperator from '../SectionSeperator/SectionSeperator';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom';
import { authSelector } from '@/store/reducers/auth';

import upArrow from '@/assets/svg/up-arrow.svg';
import downArrow from '@/assets/svg/down-arrow.svg';
import pencilBlank from '@/assets/svg/pencilBlank.svg';
import pencilBlack from '@/assets/svg/pencilBlack.svg';
import copyIcon from '@/assets/svg/copy.svg';
import errorIcon from '@/assets/svg/error.svg';
import closeIcon from '@/assets/svg/close.svg';
import threeStudentsIcon from '@/assets/svg/three_students2.svg';
import deleteIcon from '@/assets/svg/delete.svg';
import AppLoaderCircle from '../AppLoaderCircle';

import { Transition } from 'react-transition-group';
import AppBackdrop from '@/components/AppBackdrop';
import AppModal from '@/components/AppModal';
import DealLimitReachedMessage from '@/components/DealLimitReachedMessage/DealLimitReachedMessage';
import LoaderModal from '@/components/LoaderModal/LoaderModal';
import DeleteClass from './DeleteClass/DeleteClass';

interface ClassEditorProps {
  className?: string;
  loading?: boolean;
  classConfig: ClassConfig | null;
  currentClassroom: TeacherGroup | null;
  showCopyModal: boolean;
  setShowCopyModal: (val: boolean) => void;
  onShowCopyModal: () => void;
  showCancelModal: boolean;
  setShowCancelModal: (val: boolean) => void;
  onShowCancelModal: () => void;
  createOrSaveClass: (newConfig: ClassConfig) => Promise<void>;
  setIsClassEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  onShowUploadCsvModal?: (isNewGroup?: boolean) => void;
  isClassDeleting: boolean;
  showClassDeleteError: boolean;
  setShowClassDeleteError: React.Dispatch<React.SetStateAction<boolean>>;
  onClassDelete: (reason?: string) => void;
}

const ClassEditor = ({
  className,
  classConfig,
  currentClassroom,
  loading,
  createOrSaveClass,
  showCopyModal,
  onShowCopyModal,
  setShowCopyModal,
  showCancelModal,
  setShowCancelModal,
  onShowCancelModal,
  setIsClassEdit,
  onShowUploadCsvModal,
  isClassDeleting,
  showClassDeleteError,
  setShowClassDeleteError,
  onClassDelete,
}: ClassEditorProps) => {
  const { t } = useTranslation();
  const drawer = useDrawer();

  const navigate = useNavigate();
  const { color } = UseThemeColors();
  const { selectedClassId } = useAppSelector(teacherDashboardSelector);
  const { user } = useAppSelector(authSelector);
  const [classNameValue, setClassNameValue] = useState('');
  const [gradeValue, setGradeValue] = useState('');
  const [invitationLink, setInvitationLink] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const schoolGrades = Array.from({ length: 12 }, (_, i) => {
    return {
      name: t(`grade_${commonUtils.fixGradeSymbol((i + 1).toString())}`),
      value: (i + 1).toString(),
    };
  });

  const namedGrades = [
    { name: t('university'), value: 'university' },
    { name: t('none'), value: 'none' },
  ];

  const grades = [...schoolGrades, ...namedGrades];

  const [shouldInitLevelTest, setShouldInitLevelTest] = useState(false);
  const [allowIndividualAdvancement, setAllowIndividualAdvancement] = useState(false);
  const [allowFinalAssessment, setAllowFinalAssessment] = useState(false);
  const [finalAssessmentDate, setFinalAssessmentDate] = useState<Dayjs | null>(null);

  const [classNameValueError, setClassNameValueError] = useState('');
  const [gradeValueError, setGradeValueError] = useState('');
  const [finalAssessmentDateError, setFinalAssessmentDateError] = useState('');

  const [showFullClassModal, setShowFullClassModal] = useState<boolean>(false);
  const [showDealLimitReachedModal, setShowDealLimitReachedModal] = useState<boolean>(false);
  const [isOrganizationDetailsLoading, setIsOrganizationDetailsLoading] = useState<boolean>(false);
  const isComponentActive = useRef<boolean>(false);

  const dateFormat = 'DD MMM YYYY';

  useEffect(() => {
    isComponentActive.current = true;

    return () => {
      isComponentActive.current = false;
    };
  }, []);

  useEffect(() => {
    onReset();
  }, [classConfig]);

  useEffect(() => {
    window.addEventListener('click', () => {
      if (!showDropdown) {
        return;
      }

      onCloseDropdown();
    });

    return () => {
      window.removeEventListener('click', () => {});
    };
  }, [showDropdown]);

  const onReset = () => {
    setClassNameValue(classConfig?.name ?? '');
    setGradeValue(classConfig?.grade ?? '1');
    setInvitationLink(classConfig?.invitationLinkForStudents ?? '');
    setShouldInitLevelTest(classConfig?.levelTest || true);
    setAllowIndividualAdvancement(classConfig?.individualAdvancement ?? true);
    setAllowFinalAssessment(classConfig?.finalAssessment ?? true);
    setFinalAssessmentDate(
      classConfig?.finalAssessmentDate ? dayjs(classConfig?.finalAssessmentDate) : dayjs()
    );

    setClassNameValueError('');
    setGradeValueError('');
    setFinalAssessmentDateError('');
  };

  const onPickDate = (newValue: Dayjs | null) => {
    setFinalAssessmentDate(newValue);
    setFinalAssessmentDateError('');
  };

  const onToggleDropdown = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowDropdown(prevState => !prevState);
  };

  const onCloseDropdown = () => {
    setShowDropdown(false);
  };

  const onSelectedOption = (e: React.MouseEvent<HTMLElement>, option: string) => {
    console.log(option);
    e.stopPropagation();
    onCloseDropdown();
    setGradeValue(option);
    setGradeValueError('');
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  const onCopy = () => {
    onShowCopyModal();
    navigator.clipboard.writeText(invitationLink);
  };

  const onCancel = () => {
    // if (user?.teacherGroups?.length === 0) {
    if (user?.metadata.role === 'teacher') {
      onShowCancelModal();
    } else {
      scrollToTop();
      navigate('/dashboard/my-students');
    }
  };

  const onNext = () => {
    // scrollToTop();

    const differenceInDays = finalAssessmentDate?.diff(dayjs(), 'day') ?? 0;

    const isClassNameValid = classNameValue.trim();
    const isGradeValid = gradeValue.trim();
    const isDateValid = differenceInDays >= 0;

    const isValid = isClassNameValid && isGradeValid && isDateValid;

    if (!isClassNameValid) {
      setClassNameValueError(t('invalidClassName') ?? ' ');
    }

    if (!isGradeValid) {
      setGradeValueError(t('invalidGrade') ?? ' ');
    }

    if (!isDateValid && allowFinalAssessment) {
      setFinalAssessmentDateError(t('invalidDate') ?? ' ');
    }

    if (!isValid) {
      return;
    }

    // dispatch(teacherDashboardActions.onReset());
    // onReset();
    createOrSaveClass({
      name: classNameValue,
      grade: gradeValue,
      levelTest: shouldInitLevelTest,
      individualAdvancement: allowIndividualAdvancement,
      finalAssessment: allowFinalAssessment,
      finalAssessmentDate: dayjs(finalAssessmentDate),
    }).then(() => {
      if (setIsClassEdit && classConfig?.id) {
        setIsClassEdit(false);
      }
    });
  };

  const openAddStudent = async () => {
    if (isOrganizationDetailsLoading) {
      return;
    }
    setIsOrganizationDetailsLoading(true);

    let isAllowedToRegister: boolean = false;

    try {
      const organizationDetails = (await registerService.getOrganizationDetails(user))?.data.data;
      isAllowedToRegister = !!organizationDetails?.isAllowedToRegister;
    } catch (error) {}

    if (!isComponentActive.current) {
      return;
    }

    setIsOrganizationDetailsLoading(false);

    if (!isAllowedToRegister) {
      setShowDealLimitReachedModal(true);
    } else if ((currentClassroom?.totalStudents || 0) < 50) {
      //drawer.openAddStudent(classConfig?.id || '');
      onShowUploadCsvModal?.(false);
    } else {
      setShowFullClassModal(true);
    }
  };

  const onCloseFullClassModal = useCallback(() => {
    setShowFullClassModal(false);
  }, []);

  const onCloseDealLimitReachedModal = useCallback(() => {
    setShowDealLimitReachedModal(false);
  }, []);

  const studentsAmount = 0;
  // const studentsAmount = 42;

  const gradeOptions = grades.map(grade => {
    const isSelected = grade.value === gradeValue;

    return (
      <div
        key={grade.name}
        className={classNames.grade}
        onClick={event => onSelectedOption(event, grade.value)}
      >
        {grade.name}
      </div>
    );
  });

  const gradesDropdown = showDropdown && (
    <div className={classNames.gradesDropdown}>{gradeOptions}</div>
  );

  const isUniversityGrade = gradeValue === 'university';
  const isNoneGrade = gradeValue === 'none';

  const gradesSelect = (
    <div
      className={classes(classNames.gradesSelectContainer, {
        [classNames.gradesSelectContainerError]: gradeValueError,
      })}
    >
      <span className={classNames.label}>{t('grade')}</span>
      <div className={classes(classNames.gradesSelect)} onClick={onToggleDropdown}>
        <span className={!gradeValue ? 'opacity-40' : ''}>
          {gradeValue
            ? isUniversityGrade || isNoneGrade
              ? t(gradeValue)
              : t(`grade_${gradeValue}`)
            : t('choose')}
        </span>
        <SVG src={showDropdown ? upArrow : downArrow} />
      </div>
      {gradesDropdown}
    </div>
  );

  const fullClassModal = (
    <Transition
      in={showFullClassModal}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={state => {
        return (
          <>
            <AppBackdrop className={classes(classNames.backdrop, `backdrop-${state}`)} />
            <AppModal>
              <div className={classes(classNames.modal, `fade-${state}`)}>
                <button className={classNames.closeBtn} onClick={onCloseFullClassModal}>
                  <SVG src={closeIcon} />
                </button>
                <SVG src={errorIcon} className={classNames.icon} />
                <h2>{t('editClassStudentFullClassTitle')}</h2>
                <span
                  className={classNames.description}
                  dangerouslySetInnerHTML={{
                    __html: t('editClassStudentFullClassDescription').toString(),
                  }}
                />
              </div>
            </AppModal>
          </>
        );
      }}
    />
  );

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const onOpenDeleteModal = useCallback(() => {
    setShowClassDeleteError(false);
    setShowDeleteModal(true);
  }, []);

  const onCloseDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const onDelete = useCallback(() => {
    onClassDelete();
  }, []);

  return (
    <div className={classes(classNames.classEditor, className)}>
      {fullClassModal}
      <DeleteClass
        show={showDeleteModal}
        isClassDeleting={isClassDeleting}
        showClassDeleteError={showClassDeleteError}
        onClose={onCloseDeleteModal}
        onDelete={onDelete}
        studentsAmount={(currentClassroom?.totalStudents && currentClassroom.totalStudents) || 0}
        setShowClassDeleteError={setShowClassDeleteError}
      />
      <DealLimitReachedMessage
        show={showDealLimitReachedModal}
        onClose={onCloseDealLimitReachedModal}
      />
      {isOrganizationDetailsLoading && <LoaderModal show={true} />}
      <div className={classes(classNames.classEditorInner)}>
        <div className={classNames.header}>
          <div className={classNames.texts}>
            <h2>{classNameValue || t('className')}</h2>
            <div className={classNames.details}>
              {gradeValue && (
                <Detail
                  className={classNames.detail}
                  topic={t('grade')}
                  value={
                    isUniversityGrade || isNoneGrade ? t(gradeValue) : t(`grade_${gradeValue}`)
                  }
                />
              )}
              {(!!currentClassroom?.totalStudents || currentClassroom?.totalStudents === 0) &&
                classConfig?.id && (
                  <>
                    <DetailSeperator />
                    <Detail
                      className={classNames.detail}
                      topic={t('students')}
                      value={`${currentClassroom.totalStudents}/50`}
                    />
                  </>
                )}

              {studentsAmount > 0 && (
                <>
                  <DetailSeperator />
                  <Detail
                    className={classNames.detail}
                    topic={t('students')}
                    value={studentsAmount}
                  />
                </>
              )}
            </div>
          </div>
          {classConfig?.id && (
            <button
              onClick={() => {
                scrollToTop();
                if (setIsClassEdit) {
                  setIsClassEdit(false);
                }
              }}
              className={classNames.editIcon}
            >
              <SVG src={pencilBlack} />
            </button>
          )}
        </div>
        <SectionSeperator className='mt-6 mb-8' />
        <div className={classNames.form}>
          <div className={classes(classNames.fieldsGroup, 'flex flex-col gap-y-6')}>
            <div className={classNames.nameAndGradeFields}>
              <AppInput
                className={classes(classNames.field, {
                  [classNames.classNameFieldError]: classNameValueError,
                })}
                id='class_name'
                name='className'
                label={t('className') ?? ''}
                placeholder={t('writeHere') ?? ''}
                value={classNameValue}
                emitChange={({ target }) => {
                  setClassNameValue(target.value);
                  setClassNameValueError('');
                }}
              />
              {gradesSelect}
            </div>
            {selectedClassId && invitationLink && (
              <>
                {/* <StudentsPicker /> */}
                <div className={classNames.invitationLinkField}>
                  <span className={classNames.label}>{t('invitationLink')}</span>
                  <button onClick={onCopy}>
                    <span>{invitationLink}</span>
                    <SVG src={copyIcon} />
                  </button>
                </div>
              </>
            )}
          </div>
          {classConfig?.id && classConfig?.id !== undefined && (
            <div className={classNames.tools}>
              <button onClick={openAddStudent}>
                <span>
                  <SVG src={threeStudentsIcon} />
                </span>
                <span>{t('editClassInviteNewStudent')}</span>
              </button>
            </div>
          )}
          <div className={classNames.bottom}>
            <div className={classNames.buttons}>
              <TheButton
                className={classNames.cancelBtn}
                text={t('cancel')}
                plain
                emitOnClick={onCancel}
              />
              {loading ? (
                <div className={classNames.loading}>
                  <AppLoaderCircle />
                </div>
              ) : (
                <TheButton
                  className={classNames.nextBtn}
                  text={classConfig?.id ? t('save') : t('next')}
                  shouldRecolor={false}
                  emitOnClick={onNext}
                />
              )}
            </div>
            {classConfig?.id && (
              <button className={classNames.deleteBtn} onClick={onOpenDeleteModal}>
                <SVG src={deleteIcon} />
                <span>{t('deleteClass')}</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassEditor;
