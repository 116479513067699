import config from '@/config';
//TODO: Need to understand if it's needed
import { GameTypes, NewGameTypes } from '@/types/game'; //EndQuizBody
import {
  ClassProgressResponse,
  StudentProgressResponse,
  PostQuizProgressResponse,
  QuizzesProgressResponse,
  SkillsProgressResponse,
  TopicsProgressResponse,
  ProfileProgressResponse,
  StudentProgressBySkillResponse,
  ImprovableRecentQuizzesResponse,
  ComingUpQuizzesResponse,
} from '@/types/response'; //LevelTestResultsResponse
import { ProgressService } from '@/types/services';
import { SkillTypes } from '@/types/skill';
import { UserState } from '@/types/user';
import axiosInstance from '@/utils/api';
import axiosInstanceNew from '@/utils/api-new';
import UserUtils from '@/utils/user';

export const progressService: ProgressService = {
  fetchTopicsProgress: async (skill: SkillTypes, user: UserState | null) => {
    const resp: TopicsProgressResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/progress/topics/${user?.metadata.id}/${skill}/1`
    );

    return resp;
  },
  fetchQuizProgress: async (skill: SkillTypes, user: UserState | null, topicId: number) => {
    const resp: QuizzesProgressResponse | null = await axiosInstanceNew.get(
      `${config.apiVersion}/user-progress/${user?.metadata.id}`
    );
    return resp;
    //FIXME: Old endpoint, should be removed
    // const resp: QuizzesProgressResponse | null = await axiosInstance.get(
    //   `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
    //     user
    //   )}/progress/quizzes/${user?.metadata.id}/${skill}/${topicId}`
    // );
    // return resp;
  },
  fetchQuestionsProgress: async (
    skill: SkillTypes,
    user: UserState | null,
    topicId: number,
    quizId: number
  ) => {
    //FIXME: Old endpoint, should be removed
    // const resp: QuizzesProgressResponse | null = await axiosInstance.get(
    //   `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
    //     user
    //   )}/progress/questions/${user?.metadata.id}/${skill}/${topicId}/${quizId}`
    // );
    const resp: QuizzesProgressResponse | null = await axiosInstanceNew.get(
      `${config.apiVersion}/user-progress/${user?.metadata.id}/${quizId}`
    );

    return resp;
  },
  //TODO: Need to understand if it's needed
  // sendLevelTestData: async (user: UserState | null, progress: EndQuizBody) => {
  //   const resp: LevelTestResultsResponse | null = await axiosInstance.post(
  //     `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
  //       user
  //     )}/calculate-user-level/${user?.metadata.id}`,
  //     progress
  //   );

  //   return resp;
  // },
  sendQuizProgressData: async (
    user: UserState | null,
    skillName: string,
    topicId: number,
    quizId: number,
    gameType: GameTypes | NewGameTypes,
    progress: any
  ) => {
    // FIXME: Old endpoint, need to be removed
    // const inPractice = gameType === GameTypes.Practice;

    // const skillLevel = UserUtils.levelByLanguage(user, skillName);
    // const resp: PostQuizProgressResponse | null = await axiosInstance.post(
    //   `learn/${config.apiVersion}/${UserUtils.fromToLanguage(user)}/progress/${
    //     inPractice ? 'practice' : 'game'
    //   }/${user?.metadata.id}/${skillLevel}/${skillName.toLowerCase()}/${topicId}/${quizId}`,
    //   progress
    // );

    // return resp;

    const resp: PostQuizProgressResponse | null = await axiosInstanceNew.post(
      `${config.apiVersion}/user-progress/${user?.metadata.id}`,
      progress
    );
    return resp;
  },
  fetchClassProgress: async (user: UserState | null, groupId: string) => {
    const fromToLanguage = UserUtils.fromToLanguage(user);

    const resp: ClassProgressResponse | null = await axiosInstanceNew.get(
      `${config.apiVersion}/Teacher-view/students/${fromToLanguage}/${user?.metadata.id}/${groupId}`
    );

    return resp;
  },
  fetchStudentProgress: async (user: UserState | null, studentId: string, level: number) => {
    const resp: StudentProgressResponse | null = await axiosInstanceNew.get(
      `${config.apiVersion}/teacher-view/student/overview/${user?.metadata.id}/${studentId}?level=${level}`
    );

    return resp;
  },
  fetchStudentProgressBySkill: async (
    user: UserState | null,
    studentId: string,
    studentLevel: number,
    skill: SkillTypes,
    selectedStudent: UserState | null
  ) => {
    const resp: StudentProgressBySkillResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/teacher/dashboard/class-progress/${
        user?.metadata.id
      }/student-scores-in-skill/${studentId}/${skill}/1`
    );

    return resp;
  },
  fetchProfileProgress: async (user: UserState | null) => {
    const resp: ProfileProgressResponse | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(user)}/user/profile/${user?.metadata.id}`
    );

    return resp;
  },
  fetchBestScoreOfTheWeek: async (user: UserState | null) => {
    const resp: any | null = await axiosInstance.get(
      `learn/${config.apiVersion}/${UserUtils.fromToLanguage(
        user
      )}/user/learning-hub/get-best-score-of-the-week/${user?.metadata.id}`
    );

    return resp;
  },
  sendCanDoAnswer: async (user: UserState | null, data: any) => {
    const resp: PostQuizProgressResponse | null = await axiosInstanceNew.post(
      `${config.apiVersion}/user-progress/can-do-answer/${user?.metadata.id}`,
      data
    );
    return resp;
  },
};
