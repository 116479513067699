import { Topic, TopicTypes } from '@/types/topic';
import classNames from './SkillTopics.module.scss';

import QuizzesModal from '../QuizzesModal/QuizzesModal';
import Roadmap from '@/components/Roadmap/Roadmap';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import UseSkills from '@/hooks/UseSkills';
import useQuizzesModal from '@/hooks/UseQuizzesModal';

import useEndOfSkillTest from '@/hooks/UseEndOfSkillTest';

interface TopicsProps {
  loading: boolean;
  topics: Topic[];
  groupedTopics: Topic[][];
}

const SkillTopics = ({ topics, groupedTopics }: TopicsProps) => {
  const { skillsAssets } = UseSkills();
  const { selectedType } = useAppSelector(gameSelector);
  const skillAssets = selectedType && skillsAssets[selectedType];
  const { shouldDisplayModal, onCloseModal, onOpenModal } = useQuizzesModal();
  const { handleStartEndOfSkillTest } = useEndOfSkillTest();

  return (
    <div
      style={{
        backgroundColor: skillAssets?.containerBackgroundColor,
        height: '100vh',
      }}
      className={classNames.skillTopics}
    >
      <QuizzesModal shouldDisplayModal={shouldDisplayModal} onCloseModal={onCloseModal} />
      {topics.length && (
        <Roadmap
          groupedTopics={groupedTopics}
          topics={topics}
          emitOpenModal={topic =>
            topic.topicType === TopicTypes.EndOfSkillTest
              ? handleStartEndOfSkillTest(topic)
              : onOpenModal(topic.id)
          }
        />
      )}
    </div>
  );
};

export default SkillTopics;
