import { RootState } from '@/types/common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { userService } from '@/services';
import { BaseGroupDetails, EditGroupDetails } from '@/types/register';
import { registerService } from '@/services/register';
import { authActions } from '../reducers/auth';

const teacherDashboardThunks = {
  getGroupDetails: createAsyncThunk('getGroupDetails', async (groupId: string, { getState }) => {
    try {
      const state = getState() as RootState;
      const user = state.auth.user;

      const groupDetails = await userService.getGroup(user, groupId);

      return {
        groupData: groupDetails?.data?.data ?? null,
        groupId: groupId,
      };
    } catch (error) {
      console.log(error);

      throw error;
    }
  }),
  updateGroupDetails: createAsyncThunk(
    'updateGroupDetails',
    async (
      { groupId, groupDetails }: { groupId: string; groupDetails: BaseGroupDetails },
      { getState, dispatch }
    ) => {
      try {
        const state = getState() as RootState;
        const user = state.auth.user;

        const groupUpdate = await userService.editGroup(user, groupId, groupDetails);

        const updateSucceed = groupUpdate?.data?.data ?? false;

        if (!updateSucceed) {
          throw new Error('Failed to update group details');
        }

        // Add new group to user metadata
        const groupDetailsUpdate: EditGroupDetails = {
          groupId: groupId,
          groupName: groupDetails.groupName.trim(),
          grade: groupDetails.grade.trim(),
        };

        dispatch(authActions.updateTeacherClass(groupDetailsUpdate));

        return {
          groupData: groupDetails,
          groupId: groupId,
        };
      } catch (error) {
        console.log(error);

        throw error;
      }
    }
  ),
  createGroup: createAsyncThunk(
    'createGroup',
    async (groupDetails: BaseGroupDetails, { getState, dispatch }) => {
      try {
        const state = getState() as RootState;
        const user = state.auth.user;

        const teacherGroup = await registerService.createGroup(user, groupDetails);

        const createSucceed = teacherGroup?.data?.data ?? false;

        if (!createSucceed) {
          throw new Error('Failed to update group details');
        }

        // Add new group to user metadata

        dispatch(
          authActions.addNewClass({
            id: createSucceed.groupId,
            name: groupDetails.groupName,
            grade: groupDetails.grade,
            invitationLinkForStudents: '',
            groupLevel: '',
          })
        );

        return {
          groupData: groupDetails,
          invitationLink: '',
          groupId: createSucceed.groupId,
        };
      } catch (error) {
        console.log(error);

        throw error;
      }
    }
  ),
};

export default teacherDashboardThunks;
