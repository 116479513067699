import { useAppDispatch, useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import userThunks from '@/store/thunks/user';
import { useState } from 'react';

const UseUserProgress = () => {
  const dispatch = useAppDispatch();
  const { userProgress, loggedIn } = useAppSelector(authSelector);

  const [loadingProgress, setLoadingProgress] = useState(false);

  const loadUserProgress = async () => {
    if (!loggedIn) {
      return;
    }

    if (!userProgress && !loadingProgress) {
      setLoadingProgress(true);
      try {
        await dispatch(userThunks.userInfo(3)).unwrap();

        // if some of the progress objects is empty, call the skip level test endpoint to reset all levels on skills to 1
        // const isProgressEmpty = Object.values(progress?.data ?? {}).some(v => v === null);
        // TODO: level test check
        // if (isProgressEmpty) {
        //   await dispatch(userThunks.userSkipLevelTest()).unwrap();
        //   setLoadingProgress(false);
        //   loadUserProgress();

        //   return;
        // }
      } catch (e) {
        console.log(e);
      }
      setLoadingProgress(false);
    }
  };

  return { loadUserProgress, loadingProgress };
};

export default UseUserProgress;
