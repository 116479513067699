import classNames from './DetailSeperator.module.scss';
import classes from 'classnames';

interface DetailSeperatorProps {
  className?: string;
}

const DetailSeperator = ({ className }: DetailSeperatorProps) => {
  return <div className={classes(classNames.detailSeperator, className)}></div>;
};

export default DetailSeperator;
