import React, { useState } from 'react';
import classes from 'classnames';
import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { InitialLoginProps, LoginValues } from '@/types/login';
import AppInput from '../AppInput/AppInput';
import TheButton from '../TheButton/TheButton';
import AppLoaderCircle from '../AppLoaderCircle';

type FormSubmitValues = {
  email: string;
  password: string;
};

const InitialLogin = ({ emitSubmit, goToResetPassword, loading, styles }: InitialLoginProps) => {
  const { t } = useTranslation();
  const [showInputErrors, setShowInputErrors] = useState<boolean>(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required(t('validation.email_required').toString()),
    password: Yup.string().required(t('validation.password_required').toString()),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values: FormSubmitValues, formikHelpers: FormikHelpers<FormSubmitValues>) => {
      setShowInputErrors(false);

      if (emitSubmit) {
        emitSubmit(values, formikHelpers as FormikHelpers<LoginValues>);
      }
    },
  });

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setShowInputErrors(true);
    formik.handleSubmit(event);
  };

  const onClear = (event: React.MouseEvent<SVGElement, MouseEvent>, name: string) => {
    formik.setFieldValue(name, '');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    formik.setFieldValue(
      name,
      value
        .replace(/\s/g, '')
        .replace(/\u202B|\u202C/g, '')
        .split('')
        .join('')
    );
  };
  return (
    <>
      <form onSubmit={onFormSubmit}>
        <AppInput
          className={styles.formField}
          inputWrapperClassName={classes(styles.appInputWrapper, {
            [styles.appInputWrapperError]: showInputErrors && !!formik.errors.email,
          })}
          id='email'
          name='email'
          label={t('email') ?? ''}
          placeholder={t('email') ?? ''}
          value={formik.values?.email?.trim()}
          error={showInputErrors ? formik.errors.email : ''}
          allowClear={true}
          emitChange={handleChange}
          emitBlur={formik.handleBlur}
          emitClear={onClear}
        />
        <AppInput
          className={styles.formField}
          inputWrapperClassName={classes(styles.appInputWrapper, {
            [styles.appInputWrapperError]: showInputErrors && !!formik.errors.password,
          })}
          id='password'
          name='password'
          type='password'
          label={t('password') ?? ''}
          placeholder={t('password') ?? ''}
          value={formik.values?.password?.trim()}
          error={showInputErrors ? formik.errors.password : ''}
          allowClear={true}
          emitChange={handleChange}
          emitBlur={formik.handleBlur}
          emitClear={onClear}
        />

        {!loading && (
          <>
            <TheButton
              type='submit'
              //disabled={formik.isSubmitting || !formik.isValid}
              className={styles.submit}
              text={t('signIn')}
              shouldRecolor={false}
            />
            <a
              className={classes('text-center cursor-pointer', styles.forgotPasswordBtn)}
              onClick={goToResetPassword}
            >
              {t('forgotPassword')}
            </a>
          </>
        )}
        {loading && (
          <div className={styles.loadingWrapper}>
            <AppLoaderCircle />
            <div className='mt-3'>{t('signYouIn')}</div>
          </div>
        )}
      </form>
    </>
  );
};

export default InitialLogin;
