import { useMemo } from 'react';
import { SkillTypes } from '@/types/skill';
import classNames from './SkillPageHeader.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import commonUtils from '@/utils/common';
import SVG from 'react-inlinesvg';

import Skeleton from 'react-loading-skeleton';
import DetailSeperator from '@/components/DetailSeperator/DetailSeperator';

import trophy from '@/assets/svg/trophy.svg';
import UseLocalLang from '@/hooks/UseLocalLang';

interface SkillPageHeaderProps {
  type: SkillTypes;
  topicsAmount: number;
  topicsCompleted: number;
  quizzesAmount: number;
  quizzesCompleted: number;
  loading: boolean;
}

const SkillPageHeader = ({
  type,
  topicsAmount,
  topicsCompleted,
  quizzesAmount,
  quizzesCompleted,
  loading,
}: SkillPageHeaderProps) => {
  const { t } = useTranslation();
  const localLang = UseLocalLang();

  const { selectedSkill } = useAppSelector(gameSelector);

  const progress = useMemo(
    () => commonUtils.calcPercentage(topicsCompleted, topicsAmount),
    [loading]
  );

  return (
    <div className={classNames.skillPageHeader}>
      <h2>{selectedSkill ? commonUtils.getName(selectedSkill, localLang) : t('notAvailable')}</h2>
      <div className={classNames.text}>
        {loading ? (
          <Skeleton width='250px' containerClassName={classNames.textSkeleton} />
        ) : (
          <>
            <SVG className={classNames.trophyImg} src={trophy} />
            <span>
              {topicsCompleted}/{topicsAmount}
            </span>
            <DetailSeperator />
            <span>
              {quizzesCompleted}/{quizzesAmount} {t('quizzes')}
            </span>
          </>
        )}
      </div>

      {loading ? (
        <Skeleton width='100%' height='6px' containerClassName={classNames.progressBarSkeleton} />
      ) : (
        <div className={classNames.progressBar}>
          <div
            className={classNames.progressBarInner}
            style={{
              backgroundColor: selectedSkill?.progressBackground ?? '',
              width: `${progress}%`,
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default SkillPageHeader;
