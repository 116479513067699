import classes from 'classnames';
import { useFormik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import classNames from './LoginForm.module.scss';
import { LoginValues } from '@/types/login';
import TheButton from '../TheButton/TheButton';
import { LoginStepProps } from '@/types/login';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import commonUtils from '@/utils/common';

type FormSubmitValues = {
  avatarName: string;
  avatarColor: string;
};

const AvatarUpdate = ({ loading, styles, emitSubmit }: LoginStepProps) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(authSelector);

  const avatarImages = commonUtils.getAvatarImages();
  const backgroundColors = commonUtils.getAvatarBackgroundColors();

  const initialAvatarName = user?.metadata.userSettings.avatar?.avatarName || '';
  const initialAvatarColor = user?.metadata.userSettings.avatar?.avatarColor || backgroundColors[0];

  const formik = useFormik({
    initialValues: {
      avatarName: initialAvatarName,
      avatarColor: initialAvatarColor,
    },
    onSubmit: (values: FormSubmitValues, formikHelpers: FormikHelpers<FormSubmitValues>) => {
      if (emitSubmit) {
        emitSubmit(values, formikHelpers as FormikHelpers<LoginValues>);
      }
    },
  });

  const onSelectImg = (img: string) => {
    formik.setFieldValue('avatarName', img);
  };

  const onSelectBackgroundColor = (backgroundColor: string) => {
    formik.setFieldValue('avatarColor', backgroundColor);
  };

  const handleSkip = () => {
    if (emitSubmit) {
      emitSubmit({ avatarName: '', avatarColor: '' }, {} as FormikHelpers<LoginValues>);
    }
  };

  const imageOptions = Object.entries(avatarImages).map(([key, value]) => (
    <div
      key={key}
      className={classes(classNames.avatarImg, {
        [classNames.avatarImgSelected]: key === formik.values.avatarName,
      })}
      style={{ backgroundColor: formik.values.avatarColor }}
      onClick={() => onSelectImg(key)}
    >
      <SVG src={value} />
    </div>
  ));

  const backgroundColorOptions = backgroundColors.map(backgroundColor => (
    <button
      key={backgroundColor}
      type='button' // Prevent form submission
      className={classes(classNames.backgroundColor, {
        [classNames.backgroundColorSelected]: backgroundColor === formik.values.avatarColor,
      })}
      style={{ backgroundColor }}
      onClick={() => onSelectBackgroundColor(backgroundColor)}
    />
  ));

  const secondButton = !user?.metadata.userSettings.avatar && (
    <TheButton text={t('skip')} emitOnClick={handleSkip} plain />
  );

  return (
    <div className={classNames.avatarSelection}>
      <div className={styles.header}>
        <h2>{t('letsGo')}</h2>
        <span>{t('readyToChooseYourAvatar')}</span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames.body}>
          <div className={classNames.backgroundColors}>{backgroundColorOptions}</div>
          <div className={classNames.avatarImages}>{imageOptions}</div>
        </div>
        <div className={classNames.bottom}>
          <TheButton
            className={classes({
              [classNames.updateBtn]: user?.metadata.userSettings.avatar,
            })}
            text={t('chooseAvatar')}
            disabled={!formik.values.avatarName}
            shouldRecolor={false}
            type='submit'
          />
        </div>
        <div className={classNames.bottom}>{secondButton}</div>
      </form>
    </div>
  );
};

export default AvatarUpdate;
