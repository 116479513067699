import React from 'react';
import { gameSelector } from '@/store/reducers/game';
import QuizImageIndicator from '../QuizImageIndicator/QuizImageIndicator';
import classNames from './QuizContent.module.scss';
import classes from 'classnames';
import { useAppSelector } from '@/store';

interface QuizContentProps {
  className?: string;
  children: React.ReactNode;
  shouldDisplayImageIndicator?: boolean;
  shouldDisplayStars?: boolean;
}

const QuizContent = ({
  className,
  children,
  //FIXME: NEED to remove + remove from parents components
  shouldDisplayImageIndicator = true,
  shouldDisplayStars = true,
}: QuizContentProps) => {
  const { isLevelTest, isFinalAssessment } = useAppSelector(gameSelector);

  return (
    <div
      className={classes(classNames.quizContent, className, {
        [classNames.quizContentLevelTest]: isLevelTest,
      })}
    >
      {children}
    </div>
  );
};

export default QuizContent;
