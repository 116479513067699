// import { progressService } from '@/services';
import { unitService } from '@/services/unit';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { UnitsProgress, UserStreak } from '@/types/progress';
import { useEffect, useState } from 'react';

const UseMyProfile = () => {
  const { user } = useAppSelector(authSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [unitsProgress, setUnitsProgress] = useState<UnitsProgress | null>(null);
  const [streak, setStreak] = useState<UserStreak | null>(null);

  const fetchStudentProfileProgress = async () => {
    if (user?.id) {
      setIsLoading(true);
      try {
        const response = (await unitService.fetchStudentProfile(user?.id))?.data;
        if (response) {
          setUnitsProgress(response.data.progress.unitsProgress);
          setStreak(response.data.progress.userStreak);
        }
      } catch (error) {
        console.log(error);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!user?.progress?.userLevel) {
      return;
    }
    fetchStudentProfileProgress();
  }, [user?.progress?.userLevel]);

  return {
    isLoading,
    unitsProgress,
    streak,
  };
};

export default UseMyProfile;
