import commonUtils from '@/utils/common';
import { useTranslation } from 'react-i18next';

interface Result {
  title: string;
  content: string;
}

/**
 * The function `generateResultContent` determines the result message based on the number of correct
 * answers in relation to specified levels and total questions.
 * @param {number} [correctAnswers=0] - The `correctAnswers` parameter represents the number of
 * questions answered correctly by the user. It is a number that defaults to 0 if not provided.
 * @param {number} totalQuestions - The `totalQuestions` parameter represents the total number of
 * questions in the activity or test. It is used in the `generateResultContent` function to determine
 * the outcome based on the number of correct answers provided (`correctAnswers`).
 * @param {any} messages - The `messages` parameter in the `generateResultContent` function seems to
 * contain information related to the messaging or feedback system for the user's performance in an
 * activity. It includes properties like `minimumLevel` and `allowLevel`.
 * @returns An object with a `title` and `content` property is being returned based on the conditions
 * of the `correctAnswers` compared to `minimumLevel`, `allowLevel`, and `totalQuestions`.
 */
const generateResultContent = (
  correctAnswers: number = 0,
  totalQuestions: number,
  messages: any,
  totalScore?: number | null
) => {
  const { t } = useTranslation();
  const { minimumLevel, allowLevel } = messages;
  const score = totalScore ?? commonUtils.roundNumber((correctAnswers * 100) / totalQuestions, 0);

  let title = t('tryBetter');
  let content = t('failed_quiz_message_first');

  if (score === 100) {
    title = t('thats_the_way');
    content = t('wow_score_all_stars', { score });
  } else if (score >= 80) {
    title = t('amazing_job');
    content = t('youPassedTheQuiz', { score });
  } else if (score >= 60) {
    title = t('you_did_it');
    content = t('wow_you_got_a_score_of', { score });
  }

  return { title, content };
};

/**
 * The function `getResultContent` calculates and generates result content based on the total number of
 * questions and correct answers provided.
 * @param {number} [totalQuestions=0] - The `totalQuestions` parameter represents the total number of
 * questions in a quiz or test. It is a number that defaults to 0 if not provided.
 * @param {number} [correctAnswers=0] - The `correctAnswers` parameter represents the number of
 * questions answered correctly.
 * @returns The `getResultContent` function is returning the result content based on the total number
 * of questions and the number of correct answers provided as arguments. It calculates the minimum
 * level and allow level based on the total number of questions and then generates the result content
 * using the `generateResultContent` function with the correct answers, total questions, and messages
 * as parameters.
 */
export const getResultContent = (
  totalQuestions: number = 0,
  correctAnswers: number = 0,
  totalScore?: number | null
): Result => {
  const messages = {
    minimumLevel: totalQuestions / 2,
    allowLevel: (totalQuestions / 100) * 80,
  };

  return generateResultContent(correctAnswers, totalQuestions, messages, totalScore);
};

/**
 * The function `getResultAfterFirstTry` determines a result message based on the number of correct
 * answers, cumulative correct answers, and total questions answered.
 * @param {number} [correctAnswers=0] - The `correctAnswers` parameter represents the number of correct
 * answers given in a quiz or test. It is used to calculate the result based on the performance of the
 * user.
 * @param {number} [cumulativeCorrectAnswers=0] - The `cumulativeCorrectAnswers` parameter represents
 * the total number of correct answers accumulated over multiple attempts or sessions. It is used to
 * compare the current `correctAnswers` with the overall progress or performance of the user.
 * @param {number} [totalQuestions=0] - The `totalQuestions` parameter represents the total number of
 * questions in the quiz or test. It is used to calculate the minimum level required to pass the test,
 * which is half of the total questions. This value is then used to determine the result based on the
 * number of correct answers provided in the `
 * @returns The function `getResultAfterFirstTry` returns an object with `title` and `content`
 * properties, which represent the result message based on the input parameters `correctAnswers`,
 * `cumulativeCorrectAnswers`, and `totalQuestions`.
 */
export const getResultAfterFirstTry = (
  correctAnswers: number = 0,
  cumulativeCorrectAnswers: number = 0,
  totalQuestions: number = 0,
  totalScore?: number | null
): Result => {
  const { t } = useTranslation();
  const minimumLevel = totalQuestions / 2;
  const score = totalScore ?? commonUtils.roundNumber((correctAnswers * 100) / totalQuestions, 0);
  let title = '';
  let content = '';

  if (totalScore) {
    if (score === 100) {
      title = t('thats_the_way');
      content = t('wow_score_all_stars', { score });
    } else if (score >= 80) {
      title = t('amazing_job');
      content = t('youPassedTheQuiz', { score });
    } else if (score >= 60) {
      title = t('you_did_it');
      content = t('wow_you_got_a_score_of', { score });
    } else {
      title = t('tryBetter');
      content = t('failed_quiz_message_first');
    }
  } else {
    // TODO: this section should be reworked as the logic is not always correct
    if (correctAnswers <= minimumLevel) {
      title = t('tryBetter');
      content = t('failed_quiz_message_passed');
    } else if (correctAnswers < cumulativeCorrectAnswers) {
      title = t('almostMadeIt');
      content = t('you_got_a_score_try_again', { score });
    } else if (correctAnswers === cumulativeCorrectAnswers) {
      title = t('almostMadeIt');
      content = t('you_got_no_extra_stars', { score });
    } else if (correctAnswers < totalQuestions) {
      title = t('goodJob');
      content = t('you_got_a_score_try_again', { score });
    } else {
      title = t('thats_the_way');
      content = t('wow_score_all_stars', { score });
    }
  }

  return { title, content };
};
