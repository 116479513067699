import { useContext } from 'react';

import { CurrentRouteContext } from '@/context/CurrentRouteContext';

const UseCurrentRoute = () => {
  const routeContext = useContext(CurrentRouteContext);

  return routeContext?.currentRoute;
};

export default UseCurrentRoute;
